import API from 'api/api';
import { CONTACTS_LIMIT, CONTACT_TYPES } from 'config/constants';

export default ({ query, offset, cancelToken }) => {
  return API.searchContacts(CONTACT_TYPES.CLIENT, query, offset, CONTACTS_LIMIT, cancelToken)
    .then(({ data }) => ({
      newItems: data,
      newHasMore: data.length === CONTACTS_LIMIT,
    }))
    .catch(console.error);
}; 
