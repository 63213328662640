import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { ADDITIONAL_SESSION_FILTERS } from 'config/constants';
import { updateComparedId, updateAdditionalTitleFilters } from 'redux/ducks/sessions';
import {
  selectPropertyOfActiveSession,
  selectCanIEditSession,
  selectActiveClientTabs,
  selectDefaultSession,
} from 'redux/selectors/selectors';
import API from 'api/api';
import { classModifier } from 'utils';

import './ProfilesCompareZone.scss';
import ProfileCard from 'containers/ProfileCard/ProfileCard';
import RecentlyViewedProfilesList from './components/RecentlyViewedProfilesList/RecentlyViewedProfilesList';
import MentionedProfilesList from './components/MentionedProfilesList/MentionedProfilesList';

const ProfilesCompareZone = ({ ids = [], ...props }) => {
  const [wrapperClassName, setWrapperClassName] = useState('profile-compare-zone');
  const [mentionedProfilesState, setMentionedProfilesState] = useState([]);

  useEffect(() => {
    API.getMentionedProfiles(props.activeClientTabs)
      .then(({ data }) => {
        setMentionedProfilesState(Object.values(data))
      })
      .catch(console.error);
  }, [props.activeClientTabs])

  const isProfileAlreadyExist = useRef();
  const isUpdateHostProfile = useRef();

  const onDragOver = e => {
    const id = props.currentDraggableId;
    if (!id) return;

    const wrap = e.currentTarget.getBoundingClientRect();

    const xInWrap = e.clientX - wrap.left;
    const leftPart = wrap.width / 2;

    const selectedProfile = xInWrap > leftPart
      ? 'right'
      : 'left';

    const clName = 'profile-compare-zone';

    let newClassName = `${clName} ${clName}--drag-enter-${selectedProfile}`;

    if (isProfileAlreadyExist.current) {
      newClassName += ` ${clName}--drag-enter-already-exist`;
    }

    if (wrapperClassName !== newClassName) {
      setWrapperClassName(newClassName);

      isUpdateHostProfile.current = selectedProfile === 'left';
    }

    e.preventDefault()
  };

  const onDragEnter = e => {
    const id = props.currentDraggableId;

    if (!id) return;

    const profileAlreadyExist = ~ids.indexOf(id);

    if (profileAlreadyExist) {
      isProfileAlreadyExist.current = true;
    }
    else {
      isProfileAlreadyExist.current = false;
    }
  };

  const onDragLeave = e => {
    const elClass = e.target.className;

    if (typeof (elClass) === 'string') {
      if (~elClass.indexOf('profile-compare-zone')) setWrapperClassName('profile-compare-zone');
    };
  };

  const onDrop = e => {
    const id = e.dataTransfer.getData('id');

    if (!id) return;

    setWrapperClassName('profile-compare-zone');
    props.updateComparedId(
      props.currentDraggableId,
      props.activeSession,
      props.canIEditSession,
      isUpdateHostProfile.current
    );

    if (props.activeSession === 0) {
      props.updateAdditionalTitleFilters(
        ADDITIONAL_SESSION_FILTERS.search,
        '',
        props.activeSession,
        props.additionalFilters,
        props.canIEditSession,
        true,
      );
    }
  };

  const isDefaultSession = props.activeSession === props.defaultSessionId;

  return (
    <div
      className={wrapperClassName}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {ids?.map((id) => (
        <ProfileCard
          id={id}
          hostProfileId={ids[0]}
          key={id}
          comparedIds={ids}
        />
      ))}

      {ids.length < 2 && (
        <div 
          className={classModifier('profile-compare-zone__profiles-box', [
            !!ids.length && 'open-host',
          ])}
        >
          <RecentlyViewedProfilesList
            isOpenHost={!ids.length}
            profilesIds={props.recentlyViewedIds}
            hostProfile={props.hostRecentlyProfile}
            isDefaultSession={isDefaultSession}
          />
          {isDefaultSession && (ids.length === 1 || !ids.length) && (
            <div 
              className={classModifier('profile-compare-zone__mentioned-box', [
                !!ids.length && 'open-host',
              ])}
            >
              {mentionedProfilesState.length ? (
                mentionedProfilesState.map((mentionedProfiles) => (
                  <MentionedProfilesList
                    key={mentionedProfiles.caller.id}
                    profilesItems={mentionedProfiles.girls}
                    caller={mentionedProfiles.caller}
                    />
                    ))
                    ) : (
                      <h2>Escorts mentioned in communication (0)</h2>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ids: selectPropertyOfActiveSession(state, 'comparedIds'),
  currentDraggableId: state.divaGirls.currentDraggableId,
  activeSession: state.sessions.activeSession,
  canIEditSession: selectCanIEditSession(state),
  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession] &&
    selectPropertyOfActiveSession(state, 'additionalFilters')),
  recentlyViewedIds: selectPropertyOfActiveSession(state, 'recentlyViewedIds'),
  hostRecentlyProfile: selectPropertyOfActiveSession(state, 'comparedIds')[0],
  activeClientTabs: selectActiveClientTabs(state),
  defaultSessionId: selectDefaultSession(state).id,
});

const mapDispatchToProps = {
  updateComparedId,
  updateAdditionalTitleFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilesCompareZone);