import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { deleteGirlChatTabInBuffer } from 'redux/ducks/girlChats';
import { selectCanIEditSession } from 'redux/selectors/selectors';
import { removeBufferedId } from 'redux/ducks/sessions';


const BufferChatTab = ({ idx, contact, activeGirlChatId, bufferTabContacts }) => {
  const tabRef = useRef();

  const activeSession = useSelector(state => state.sessions.activeSession);
  const canIEditSession = useSelector(state => selectCanIEditSession(state));

  const dispatch = useDispatch();

  const isActiveTab = contact.id === activeGirlChatId;

  useEffect(() => {
    if(isActiveTab) {
      tabRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [activeGirlChatId]);
  
  const handleSelectChat = (contact) => {
    if(activeGirlChatId !== contact.id) {
      dispatch(updateActiveContact(contact));
    }
  };

  const handleCloseChat = (e) => {
    e.stopPropagation();

    dispatch(deleteGirlChatTabInBuffer(contact.id));

    dispatch(removeBufferedId(contact.diva_default_id || contact.diva_id, activeSession, canIEditSession));

    if(isActiveTab) {
      const activeIndex = !!idx ? 0 : 1;
      dispatch(updateActiveContact(bufferTabContacts[activeIndex]));
    }
  };

  return (
    <li 
      ref={tabRef}
      onClick={() => handleSelectChat(contact)} 
      className={classModifier('chat-buffer-tabs__item', isActiveTab && 'active')}
    >
      {contact.fn}
      <span onClick={handleCloseChat} className="chat-buffer-tabs__remove-img">&#10005;</span>
    </li>
  )
}

const BufferChatTabs = () => {
  const bufferTabContacts = useSelector(state => state.girlChats.bufferTabContacts);
  const activeGirlChatId = useSelector(state => state.girlChats.active);

  const dispatch = useDispatch();

  const handleClickTabScrollBtn = (k) => {
    if(bufferTabContacts.length > 1) {
      const activeTabIdx = bufferTabContacts.map(i => i.id).indexOf(activeGirlChatId);
      const lastIdx = bufferTabContacts.length - 1;

      if(activeTabIdx >= 0) {
        if(activeTabIdx + k > lastIdx) {
          dispatch(updateActiveContact(bufferTabContacts[0]));
          return;
        }

        if(activeTabIdx + k < 0) {
          dispatch(updateActiveContact(bufferTabContacts[lastIdx]));
          return;
        }

        dispatch(updateActiveContact(bufferTabContacts[activeTabIdx + k]));
      }
    }
  };

  return (
    <div className='chat-buffer-tabs-wrapper'>
      <button 
        type='button'
        onClick={() => handleClickTabScrollBtn(-1)}
        className='chat-buffer-tabs__btn'
      >
        <ICONS.angleQuote />
      </button>
      <ul className='chat-buffer-tabs'>
        {bufferTabContacts?.map((contact, idx) => {
          return (
            <BufferChatTab 
              idx={idx}
              key={contact.id} 
              contact={contact} 
              activeGirlChatId={activeGirlChatId}
              bufferTabContacts={bufferTabContacts}
            />
          )
        })}
      </ul>
      <button 
        type='button'
        onClick={() => handleClickTabScrollBtn(1)}
        className={classModifier('chat-buffer-tabs__btn', 'right')}
      >
        <ICONS.angleQuote />
      </button>
    </div>
  )
}

export default BufferChatTabs;