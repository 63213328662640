import { CONTACT_TYPES } from 'config/constants';
import { classModifier, getContactAvatar, showContactName } from 'utils';

import './MailContactItem.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const MailContactItem = (props) => {
  const { item, style, handleEmailClick } = props;

  const getContactModifier = () => {
    let color;

    switch (item && item.type) {
      case CONTACT_TYPES.CLIENT:
        color = 'client';
        break;
      case CONTACT_TYPES.GIRL:
        color = 'escort';
        break;
      case CONTACT_TYPES.AGENT:
        color = 'agent';
        break;
    }

    return color;
  }

  return (
    <div 
      className="mail-contact-item"
      style={style && { ...style, height: style.height - 8 }}
    >
      <div
        className={classModifier(
          'mail-contact-item__img-wrap',
          getContactModifier()
        )}
      >
        <LazyLoadImage src={getContactAvatar(item)} alt="ava" className="mail-contact-item__img" />
      </div>

      <div
        className="mail-contact-item__info"
        onClick={() => handleEmailClick && handleEmailClick(item.email)}
      >
        <div
          className={classModifier(
            'mail-contact-item__name',
            getContactModifier()
          )}
        >
          {showContactName(item.fn, 'mail-contact-item__ex')}
        </div>
        <div className="mail-contact-item__email">
          {item.email}
        </div>
      </div>
    </div>
  )
}

export default MailContactItem;
