import { useEffect, useState } from 'react' 
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';

import './BookingTooltipAddFrom.scss';
import List from 'components/List/List';
import BookingTooltipAddFromItem from './BookingTooltipAddFromItem';
import ICONS from 'assets/icons';

const LIMIT = 10;


const bookingEditAddFormPlacement = {
  name: 'computeStyles',

  fn: ({ state }) => {
    return {
      ...state,
      styles: {
        ...state.styles,
        popper: {
          ...state.styles.popper,
          position: 'absolute',
          top: -220
        }
      }
    };
  },
}

const popperTooltipPlacementModifiactor = {
  name: 'computeStyles',

  fn: ({ state }) => {
    return {
      ...state,
      styles: {
        ...state.styles,
        popper: {
          ...state.styles.popper,
          position: 'absolute',
          right: 10,
          top: 30
        }
      }
    };
  },
}
const BookingTooltipAddFrom = (props) => {
    const { callerId, onSelect, isBookingEdit } = props;

    const [pending, setPending] = useState(true);
    const [messages, setMessages] = useState([]);
    const [isAddFromOpen, setIsAddFromOpen] = useState(false);

    const {
        setTriggerRef,
        setTooltipRef,
        getTooltipProps,
    } = usePopperTooltip({
        trigger: 'click',
        visible: isAddFromOpen,
        onVisibleChange: setIsAddFromOpen,
        closeOnOutsideClick: true,
        placement: 'right'
    }, {
        modifiers: isBookingEdit ? [popperTooltipPlacementModifiactor] : [bookingEditAddFormPlacement]
    });

    useEffect(() => {
        try {
            API.getMessagesByCaller(callerId)
              .then(({data}) => {
                  setMessages(data);
              })
        } catch {
            ((error) => console.error(error))
        } finally {
					setPending(false);
        }
    }, [callerId])

    const loadMore = async offset => {
        const {data} = await API.getMessagesByCaller(callerId, LIMIT, offset)
        setMessages(prev => [...prev, ...data]);
    };

    return (
        <div className='booking-tooltip-add-from'>
            <button 
                type='button' 
                className="booking-tooltip-add-from__btn"
                ref={setTriggerRef}
            >
                <ICONS.plusCircle />
                Add from 
            </button>

			{isAddFromOpen && (
                <div
                    ref={setTooltipRef}
                    className='booking-tooltip-add-from__list-wrap'
                    {...getTooltipProps()}
                >
                    <List
                        list={messages}
                        classPrefix='booking-tooltip-add-from'
                        listItemKey={"id"}
                        listLoadPending={pending}
                        loadMore={loadMore}
                        limit={LIMIT}
                        absentLabel="No messages from contact"
                        scrollbarProps={{ 
                            renderThumbVertical: props => <div {...props} style={{ backgroundColor: '#E6EBF1', width: '4px', borderRadius: '2px' }} className='scrollbars-thumb' />
                        }}
                        >
                        <BookingTooltipAddFromItem onSelect={onSelect} onClose={() => setIsAddFromOpen(false)}/>
                    </List>
                </div>
            )}
        </div>
    )
}

export default BookingTooltipAddFrom;
