import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from '../../../api/api';
import {
  classModifier,
  getContactAvatar,
  isEmptyObj,
} from 'utils';
import {
  selectOperatorActiveSessionsCountByOperatorId,
  selectActivePrivateOperatorRoom,
  selectPrivateOperatorRoom,
} from 'redux/selectors/selectors';
import {
  PUBLIC_CHAT_TABS,
  updateActiveChat,
  updateActiveRoom,
} from 'redux/ducks/roomChats';
import { TOOLTIP_THEMES } from 'config/constants';
import { ALL_FILTER } from "redux/ducks/operators";
import { callTo } from 'redux/ducks/calls';

import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Spinner from 'components/UI/Spinner/Spinner';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import Menu from 'components/Menu/Menu';

const renderMode = (modeType) => {
  const modeTypeFilterArray = [];
  modeType.agent && modeTypeFilterArray.push("Ag.");
  modeType.client && modeTypeFilterArray.push("Cl.");
  modeType.girl && modeTypeFilterArray.push("Esc.");
  modeType.service && modeTypeFilterArray.push("Sr.");

  return modeTypeFilterArray.join(' / ');
}

const SidebarOperatorItem = React.forwardRef((props, ref) => {
  const {
    item,
    operatorFilterIsOpen,
  } = props;

  const [menuIsActive, setMenuIsActive] = useState(false);

  const dispatch = useDispatch();

  const currentUserSip = useSelector((state) => state.user.sip)
  const activeOperatorsTab = useSelector((state) => state.roomChats.active);
  const isPrivateRoomActive = useSelector((state) => selectActivePrivateOperatorRoom(state, item.id));
  const privateRoom = useSelector((state) => selectPrivateOperatorRoom(state, item.id));
  const operatorActiveSessionsCount = useSelector(
    (state) => selectOperatorActiveSessionsCountByOperatorId(state, item.id)
  );

  const handleClick = () => {
    if (activeOperatorsTab !== PUBLIC_CHAT_TABS.rooms.id) {
      dispatch(updateActiveChat(PUBLIC_CHAT_TABS.rooms.id));
    }

    if (!privateRoom) {
      API.saveRoom(item.alias || item.username, [item.id])
        .then(({ data }) => {
          dispatch(updateActiveRoom(data));
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      if (!isPrivateRoomActive) {
        // dispatch(updateActiveRoom(privateRoom.id));
        document.getElementById(`operator-chat-room-${privateRoom.id}`)?.click();
      }
    }
  };

  const renderMenu = () => {
    const menu = [];

    const addNewMenuItem = (action, content) => menu.push({ action, content });

    addNewMenuItem(handleClick, "Open private chat");

    if (item.sip && currentUserSip) {
      addNewMenuItem(() => dispatch(callTo(item.sip)), `Call ${item.username}`);
    }

    return menu;
  };

  const tooltipText = () => {
    return (
      <>
        Call = {renderMode(item.mode.call)}
        <br />
        SMS = {renderMode(item.mode.sms)}
      </>
    );
  };

  const getOperatorMode = () => {
    const {is_call_active, is_sms_active} = item.mode;

    if (is_call_active && is_sms_active) {
      return "full"
    } else if (is_call_active && !is_sms_active) {
      return "phone"
    } else if (!is_call_active && is_sms_active) {
      return "sms"
    } else {
      return "off"
    }
  };

  const isTooltipShowed = (
    (props.activeFilter && props.activeFilter !== ALL_FILTER) &&
    item.mode && !Array.isArray(item.mode)
  )

  if (isEmptyObj(item)) {
    return (
      <div className="operators-list-item operators-list-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  return (
    <Tooltip
      className="operators-list-item__tooltip"
      theme={TOOLTIP_THEMES.DARK}
      text={tooltipText}
      extraCondition={isTooltipShowed && !menuIsActive && !operatorFilterIsOpen}
      ref={ref}
    >
      <div className={classModifier('operators-list-item', isPrivateRoomActive && 'active')}>
        <div className="operators-list-item__img-wrap">
          <LazyLoadImage src={getContactAvatar(item)} alt="ava" className="operators-list-item__img" />

          <span className={classModifier('operators-list-item__status', [item.status && `${item.status}`])}></span>
        </div>

        <div className="operators-list-item__content-wrap">
          <div className="operators-list-item__content">
            <div className="operators-list-item__info">
              <p className="operators-list-item__name">
                {item.username}
              </p>

              <div className="operators-list-item__sessions-indicator">
                <ICONS.puzzle className="operators-list-item__puzzle-icon"/>

                <span>{operatorActiveSessionsCount}</span>
              </div>
            </div>
            {!!item.sip &&
              <p className="operators-list-item__tel">
                {item.sip}
              </p>
            }
          </div>

          <div className="operators-list-item__menu-wrapper">
            {!!item.mode &&
              <p className={classModifier("operators-list-item__mode", [`${getOperatorMode()}`])}>
                {getOperatorMode()}
              </p>
            }

            <Menu
              iconSize={14}
              menuItems={renderMenu()}
              icon={ICONS.burgerMenu}
              dropWrapperProps={{
                placement: 'bottom-end',
                offsetY: 16,
              }}
              setExtraState={setMenuIsActive}
            />
          </div>
        </div>
      </div>
    </Tooltip>
  );
});

export default SidebarOperatorItem;
