import React from 'react';

import './TypingIndicator.scss';

const TypingIndicator = () => (
  <div className="typing-indicator">
    <span />
    <span />
    <span />
  </div>
);

export default TypingIndicator;
