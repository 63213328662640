export default (entities, newEntities, key = 'id') => {
  const updatedEntities = {};

  if (Array.isArray(newEntities)) {
		newEntities.forEach((item) => {
      const isInEntities = entities[item[key]];

      if (isInEntities) {
        updatedEntities[item[key]] = { ...entities[item[key]], ...item };
      } else {
        updatedEntities[item[key]] = item;
      }
    })
  } else {
    for (const itemKey in newEntities) {
      const isInEntities = entities[itemKey];
  
      if (isInEntities) {
        updatedEntities[itemKey] = { ...entities[itemKey], ...newEntities[itemKey] }
      } else {
        updatedEntities[itemKey] = newEntities[itemKey]
      }
    }
  }


  return { ...entities, ...updatedEntities }
}
