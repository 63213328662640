import { classModifier } from 'utils';
import ICONS from 'assets/icons';

const BookingSpecialRequestItem = ({item, onDelete}) => {
    const text = item.text || item.message;

    return (
        <li className='booking-special-request__list-item'>
            <ICONS.comments  className={classModifier('booking-special-request__btn-icon', 'request')}/>
            <button
                type='button'
                onClick={() => onDelete(item)}
                className={classModifier('booking-special-request__btn', 'delete')}
            >
                <ICONS.close  className={classModifier('booking-special-request__btn-icon', 'delete')}/>
            </button>
    
            <div className='booking-special-request__list-item-body'>{text}</div>
        </li>
)}

export default BookingSpecialRequestItem;