import React from 'react';
import { classModifier } from 'utils';

const AvailableNowFilter = props => {
  const {
    updateActiveFilters,
    activeSession,
    activeFilters,
    canIEditSession,
    isActive
  } = props;

  const toggleAvailableNow = () => {
    updateActiveFilters('availableNow', activeSession, activeFilters, canIEditSession);
  }

  return (
    <li
      className={classModifier('gallery-sidebar-filters__item', isActive && 'active')}
      onClick={toggleAvailableNow}
    >
      Available now
    </li>
  );
};

export default AvailableNowFilter;