import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

import ICONS from 'assets/icons';
import { selectUserTimezone } from 'redux/selectors/selectors';
import { classModifier, getDateByTimezoneOffset, convertTime12To24 } from 'utils';
import { useDidUpdate } from 'hooks';

import './DateTimePicker.scss';
import Calendar from 'components/Calendar/Calendar';
import TimeSelect from 'components/TimeSelect/TimeSelect';

const customRanges = {
  ['this week']: 'this week',
  ['this month']: 'this month',
  period: 'period',
}

const CalendarErrorMsg = ({ onClose }) => {
  const msgRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (msgRef.current && !msgRef.current.contains(event.target)) {
        onClose(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [msgRef]);

  return (
    <div ref={msgRef} className='calendar-error-msg'>
      <span className='calendar-error-msg__msg'>Wrong time format</span>
      <ICONS.warn />
    </div>
  )
};

const DateTimePicker = (props) => {
  const {
    timeIntervals = 15,
    onSelectDate,
    onCancel,
    initDate,
    userHour12,
    range,
    isTimeActive = true,
    displayRangeMenu = false,
    customRange,
    setCustomRange,
    ignoreTimezone,
  } = props;

  const initMaxDate = props.maxDate ? new Date(props.maxDate) : null;
  const initMinDate = props.minDate ? new Date(props.minDate) : null;

  const userTimezone = useSelector(selectUserTimezone);

  const [date, setDate] = useState(
    Array.isArray(initDate) ? [
      new Date(getDateByTimezoneOffset(userTimezone, initDate[0]).setHours(0, 0, 0)),
      new Date(getDateByTimezoneOffset(userTimezone, initDate[1]).setHours(24, 59, 59)),
    ] : ignoreTimezone ? new Date(initDate) : getDateByTimezoneOffset(userTimezone, initDate)
  );
  const startDate = useRef(Array.isArray(initDate) ? new Date(initDate[0]) : new Date(initDate));

  const [timeOption, setTimeOption] = useState(null);
  const [isVisibleErrorMsg, setIsVisibleErrorMsg] = useState(false);
  const [maxDate, setMaxDate] = useState(initMaxDate);
  const [minDate, setMinDate] = useState(initMinDate);
  const [isChanged, setIsChanged] = useState(false);

  const calendarId = useMemo(() => {
    if (!customRange || customRange === 'period') {
      return 0;
    }

    if (customRange === 'this week') {
      return 1;
    }

    if (customRange === 'this month') {
      return 2;
    }
  }, [customRange])

  const isDoneDisabled = useMemo(() => {
    if (!isChanged) {
      return true;
    }

    if (!date) {
      return true;
    }

    if (isTimeActive && !timeOption) {
      return true;
    }

    if (maxDate && minDate) {
      return true;
    }

    return false;
  }, [date, isTimeActive, timeOption, maxDate, minDate])

  const onChange = (option, stateAmPm) => {
    setIsChanged(true);

    if (option.__isNew__) {
      const handleValue = userHour12
        ? option.value.match(/^(1[0-2]|0?[1-9]):?([0-5]\d)(\s*(a|A|p|P)(m|M))?$/)
        : option.value.match(/^([01]\d|2[0-3]):?([0-5]\d)$/);

      if (!handleValue) {
        setIsVisibleErrorMsg(true);
        return;
      }

      const [_, hours, minutes, amPm = ''] = handleValue;
      const formattedTime = `${hours}:${minutes}`;
      const meridiem = amPm ? /(a|A|p|P)(m|M)/.exec(amPm)[0].toUpperCase() : '';
      const gettedFullTime = `${formattedTime} ${meridiem || stateAmPm}`;

      const formattedFullOption = {
        ...option,
        label: formattedTime,
        meridiem: meridiem || stateAmPm,
        value: userHour12 ? convertTime12To24(gettedFullTime) : formattedTime,
      };

      setTimeOption(formattedFullOption);
    } else {
      setTimeOption({
        ...option,
        value: userHour12 ? convertTime12To24(`${option.value} ${stateAmPm}`) : option.value
      });
    }
  };

  const getDateWithTime = useCallback((date, time) => {
    const newDate = new Date(date);
    const [hours, minutes] = time.split(':');

    newDate.setHours(hours, minutes, 0);

    return ignoreTimezone ? new Date(newDate).getTime() : newDate - (userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000;
  }, [userTimezone]);

  const handleSelectDate = () => {
    if (isTimeActive) {
      onSelectDate(getDateWithTime(date, timeOption.value))
    } else {
      if (Array.isArray(date)) {
        const dates = [getDateWithTime(date[0], '00:00'), getDateWithTime(date[1], '00:00')];
        onSelectDate(dates);
        return;
      }
      onSelectDate(getDateWithTime(date, '00:00'));
    }
  };

  const getMinTime = () => {
    if (!minDate) return;

    const d = new Date(minDate);
    const isCurrentDay = new Date(date).getDate() === d.getDate();

    if (!isCurrentDay) return;

    const hours = String(d.getHours()).length === 1
      ? '0' + d.getHours()
      : d.getHours();
    const mins = String(d.getMinutes()).length === 1
      ? '0' + d.getMinutes()
      : d.getMinutes();

    return hours + ':' + mins;
  };

  const handleDateChange = (date) => {
    if (Array.isArray(date) && date[0].getDate() === date[1].getDate()) {
      return true;
    }

    if (range) {
      setMaxDate(initMaxDate);
      setMinDate(initMinDate);
    }


    setDate(date);
  };

  const handleDayClick = (date) => {
    if (range) {
      const updatedMaxDate = new Date(date);
      const updatedMinDate = new Date(date);

      updatedMaxDate.setDate(updatedMaxDate.getDate() + (range - 1));
      updatedMinDate.setDate(updatedMinDate.getDate() - (range - 1));

      setMaxDate(updatedMaxDate);
      setMinDate(updatedMinDate);
    }

    startDate.current = date;
    setDate(null);
    setIsChanged(true);

    if (date.length > 1 && !customRange && setCustomRange) {
      setCustomRange('period')
    }

    if (customRange && customRange !== 'period' && setCustomRange) {
      setCustomRange(null);
    }
  };

  useEffect(() => {
    if (!Array.isArray(initDate) && setCustomRange) {
      setCustomRange(null);
    }
  }, [initDate])

  useDidUpdate(() => {
    if (!customRange || customRange === customRanges['period'] || range) {
      setDate(startDate.current);
    }

    if (customRange === customRanges['this week'] && !range) {
      setDate([moment().startOf('isoWeek').toDate(), moment().endOf('isoWeek').toDate()]);
      setIsChanged(true);
    }

    if (customRange === customRanges['this month'] && !range) {
      setDate([moment().startOf('month').toDate(), moment().endOf('month').toDate()]);
      setIsChanged(true);
    }
  }, [customRange, range]);

  return (
    <div className='date-time-picker'>
      {displayRangeMenu && (
        <header className="date-time-picker__header">
          <menu>
            {Object.keys(customRanges).map((range) => (
              <button
                key={range}
                className={classModifier("date-time-picker__period", customRange === range && 'active')}
                onClick={() => setCustomRange((prev) => prev !== range ? range : null)}
              >
                {range}
              </button>
            ))}
          </menu>

          <p>* Or select date on calendar to view that date stats</p>
        </header>
      )}
      <div className='date-time-picker__main-container'>
        {isVisibleErrorMsg && <CalendarErrorMsg onClose={setIsVisibleErrorMsg} />}

        <Calendar
          key={calendarId}
          value={date}
          maxDate={maxDate}
          minDate={minDate}
          tileDisabled={({ date }) => {
            if (minDate && maxDate) {
              return ![minDate.getDate(), maxDate.getDate()].includes(date.getDate())
            }

            return false;
          }}

          onChange={handleDateChange}
          onClickDay={handleDayClick}
          isSelectRange={Boolean(range || customRange)}
        />

        {isTimeActive && (
          <TimeSelect
            currentOption={timeOption}
            onChange={onChange}
            timeIntervals={timeIntervals}
            minTime={getMinTime()}
            initDate={initDate}
          />
        )}

        <div className="date-time-picker__actions">
          <button
            className="date-time-picker__cancel"
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>

          <button
            className="date-time-picker__submit"
            onClick={handleSelectDate}
            disabled={isDoneDisabled}
            type="button"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

const mSTP = state => ({
  userHour12: state.user.hour12
})

export default connect(mSTP)(DateTimePicker);
