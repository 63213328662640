import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { getContactsById } from 'redux/ducks/contacts';
import {
  hangupCallOnHold,
  unholdCall,
  acceptTransferedCall,
  acceptIncomingQueueCall,
  putCallOnHold,
  createTransferredCall,
  createWarmTransferCall,
  CALL_STATUSES,
  CALL_TYPES,
} from 'redux/ducks/calls';
import { webrtcAcceptIncomingCall, onHoldActiveConference } from 'redux/ducks/webrtc';
import { removeMissedCallForAll, removeMissedCall } from 'redux/ducks/missedCalls';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { updateActiveChat } from 'redux/ducks/roomChats';
import { playMedia, openModal, MODAL_TYPES, closeModal } from 'redux/ducks/activeWindows';
import { selectActiveWebrtcConference, selectContactFromEntities, selectRoomIdByOperatorId } from 'redux/selectors/selectors';
import API from 'api/api';
import { getContactAvatar, isEmptyObj, classModifier, getDefaultField } from 'utils';
import { useDidMount } from 'hooks';
import { CONTACT_TYPES } from 'config/constants';

import './CallItem.scss';
import CallTimer from './CallTimer';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import CallTransferForm from './CallTransferForm';
import Menu from 'components/Menu/Menu';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import Spinner from '../UI/Spinner/Spinner';
import ICONS from 'assets/icons';


const CallItem = (props) => {
  const {
    call,
    connectedCaller,
    mainTimer,
    queueTimer,
    onTransfer,
    inQueue,
    onHold,
    missed,
    isActiveCall,
    activeFullScreen,
    chatId,
    activeCall,
    noMicro = false,
  } = props;

  const [transferCall, setTransferCall] = useState({
    type: null,
    isPersonal: false,
    showForm: false
  });

  const [menu, setMenu] = useState([]);

  const isOperator = connectedCaller.type === CONTACT_TYPES.OPERATOR;
  const isSoftPhoneCall = !!call.isSoftPhone;

  useDidMount(() => {
    if ((!connectedCaller || !connectedCaller.id) && call.caller) {
      props.getContactsById(call.caller.id, call.caller.type);
    }
  });

  useEffect(() => {
    if (isActiveCall && isOperator) {
      const confirm = function (e) {
        e.returnValue = "You have active call";
      };

      window.addEventListener("beforeunload", confirm);

      return () => {
        window.removeEventListener("beforeunload", confirm);
      };
    };
  }, [connectedCaller, isActiveCall]);

  useEffect(() => {
    if (!isEmptyObj(connectedCaller) 
      // && !isEmptyObj(props.connection)
    ) {
      setMenu(generateMenu());
    }
  }, [connectedCaller, props.connection, activeCall.isMute]);

  const hangUp = (e) => {
    e.preventDefault();

    if (isOperator) {
      if (call.status === 'ringing') {
        API.dismissWebrtcCall(call.id)
          .catch(console.error);
      }
      else {
        API.hangUpWebrtcCall(call.id)
          .catch(console.error);
      }
    }
    else {
      if (onHold) {
        return hangupCallOnHold(call);
      }
      // killConnection(props.connection);
    }
  };

  const pickUp = (e) => {
    e.preventDefault();

    if (props.activeConference) {
      props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
    };

    if (isOperator) {
      props.webrtcAcceptIncomingCall(call.id, connectedCaller.id);
    }
    else if (onHold) {
      // props.device.connect({
      //   interactionId: call.id,
      //   action: 'unhold',
      // });

      props.unholdCall(call);
    }
    else if (inQueue && call.callBegin) {
      // props.device.connect({
      //   interactionId: call.id,
      //   action: 'incoming_queue'
      // });

      props.acceptIncomingQueueCall(call);
    }
    else if (onTransfer) {
      props.acceptTransferedCall(call);
    }
    else if (missed) {
      const tel = getDefaultField(connectedCaller.tels, 'tel');

      props.callTo(tel);
      props.removeMissedCallForAll(call.id, connectedCaller.type);
    }
    else {
      // acceptConnection(props.connection);
    }
  };

  const generateMenu = () => {
    const menu = [];

    const menuTemplate = {
      hangUp: {
        name: 'Drop The Call',
        action: hangUp,
      },
      sendMsg: {
        name: 'Send Message',
        action: () => {
          if (missed) {
            // props.showVoicemails(false);
          }
          isOperator
            ? props.updateActiveChat(chatId)
            : props.updateActiveContact(connectedCaller);
        }
      },
      redirect: {
        name: 'Redirect With Message',
        action: () => toggleTransferForm('cold', true)
      },
      parkingCall: {
        name: 'Call Parking',
        action: () => props.createTransferredCall(props.call) //TODO: remove dispatch
      },
      putOnHold: {
        name: 'Put On Hold',
        action: () => props.putCallOnHold(call)
      },
      warmTransfer: {
        name: 'Warm transfer',
        action: () => toggleTransferForm('warm')
      },
    };

    if (call.caller) {
      menu.push(menuTemplate.sendMsg);
    }

    if (isActiveCall && call.caller) { // transfer not allowable for calls between dispatchers
      menu.push(
        // menuTemplate.putOnHold,
        menuTemplate.parkingCall,
        // menuTemplate.redirect,
        menuTemplate.warmTransfer,
      );
    }
    else if (onHold || inQueue) {
      menu.push(menuTemplate.hangUp);
    }
    if (activeFullScreen) {
      menu.splice(0);

      menu.push(
        menuTemplate.redirect,
        // menuTemplate.toggleMuteCall,
        menuTemplate.warmTransfer,
      );
    }
    return menu;
  };

  const toggleFullScreenCall = () => {
    const {
      id: callerId,
      type: chatType,
    } = connectedCaller;

    props.openModal(MODAL_TYPES.fullScreenCall, { callerId, chatType });
  };

  const toggleTransferForm = (type = null, isPersonal = false) => {
    setTransferCall(prevTransferCall => ({
      showForm: !prevTransferCall.showForm,
      type,
      isPersonal
    }));
  };

  const onVoicemailPlay = (audio, voicemail) => {
    props.playMedia(audio);

    if (voicemail.listened) {
      return;
    }

    API.markVoicemailAsListened(voicemail.id);
  };


  const renderActions = () => (
    <div className="call-item__btns">
      {!isOperator &&
        <>
          <button
            className="call-item__btn"
            onClick={() => props.putCallOnHold(call)}
          >
            <ICONS.pause fill="#00A6AD" width={18} height={18} />
          </button>

          <button
            className="call-item__btn"
            onClick={() => toggleTransferForm('cold')}
          >
            <ICONS.transferCalls fill="#00A6AD" width={20} height={20} />
          </button>
        </>
      }

      <button
        onClick={hangUp}
        className="call-item__btn call-item__btn--hang-up"
      >
        <ICONS.phoneDown width={20} height={20} fill="#F8737F" />
      </button>

      {transferCall.showForm &&
        <div className="call-item__transfer-form">
          <CallTransferForm
            activeFullScreen
            transferType={transferCall.type}
            call={call}
            onClose={toggleTransferForm}
            createTransferredCall={props.createTransferredCall}
            createWarmTransferCall={props.createWarmTransferCall}
            closeModal={props.closeModal}
          />
        </div>
      }
    </div>
  );

  // const renderSubBtn = () => {
  //   if (missed) {
  //     return
  //     //  (
  //     // <button
  //     //   onClick={() => props.removeMissedCall(call.id)}
  //     //   className="call-item__btn call-item__btn--remove">
  //     // </button>
  //     // )
  //   }
  //   else if (onTransfer) {
  //     return renderTransferDescription();
  //   }
  //   return (
  //     <button
  //       onClick={hangUp}
  //       disabled={onTransfer || inQueue}
  //       className="call-item__btn call-item__btn--finish">
  //       <SvgIcon icon="phone" width="20px" height="20px" fill="#F8737F" />
  //     </button>
  //   )
  // }

  // const renderTransferDescription = () => (
  //   <Fragment>
  //     <button className="call-item__btn call-item__btn--desc">
  //       <SvgIcon icon="info" width="16" height="16" fill="#000" />
  //     </button>

  //     <div className="call-item__desc">
  //       <Scrollbars
  //         hideTracksWhenNotNeeded
  //         autoHeight
  //         autoHeightMin={20}
  //         autoHeightMax={42} >

  //         <div><b>from:</b> {call.queueData.sender.username}</div>

  //         {call.queueData.description &&
  //           <Fragment><b>desc:</b> {call.queueData.description}</Fragment>
  //         }

  //       </Scrollbars>
  //     </div>
  //   </Fragment>
  // );


  const renderMainBtn = () => {
    if (activeFullScreen || isActiveCall) { return; }

    if (isActiveCall) {
      return (
        <button
          onClick={hangUp}
          // disabled={onTransfer || inQueue}
          className="call-item__btn call-item__btn--finish">
          <ICONS.phoneSquare className="calls-info-section__icon-phone" />
        </button>
      );

    }
    else {
      return (
        <>
          {call.voicemail &&
            <button
              className="call-item__btn call-item__btn--voice"
              disabled={activeCall.status === 'in-progress' && (onHold || onTransfer || inQueue || missed)}
              onClick={pickUp}>
              <svg
                height="16px"
                width="16px"
                viewBox="1 -28 512 511"
                xmlns="http://www.w3.org/2000/svg"
                fill={call.voicemail.listened ? "#000" : "#0092F2"}>
                <path d="M457 1H55C25 1 0 25 0 56v305c0 30 25 55 55 55h155l35 36a15 15 0 0 0 22 0l35-36h155c30 0 55-25 55-55V56c0-31-25-55-55-55zm25 360c0 14-11 25-25 25H296c-4 0-8 2-10 4l-30 30-30-30c-2-2-6-4-10-4H55c-14 0-25-11-25-25V56c0-14 11-26 25-26h402c14 0 25 12 25 26zm0 0" />
                <path d="M359 136a72 72 0 0 0-58 115h-90a72 72 0 1 0-59 30h207a72 72 0 0 0 0-145zm-249 72a42 42 0 1 1 85 0 42 42 0 0 1-85 0zm249 43a42 42 0 1 1 0-85 42 42 0 0 1 0 85zm0 0" />
              </svg>
            </button>
          }
          {inQueue && 
          <button
            className="call-item__btn call-item__btn--call"
            disabled={(activeCall.status === 'in-progress'
              && (onHold || onTransfer || inQueue || missed))
              || noMicro
              || (isOperator && !isEmptyObj(activeCall))}
            onClick={pickUp}>
            <ICONS.phoneSquare className="calls-info-section__icon-phone"/>
          </button>
          }
        </>
      );
    }

  };

  const renderCallTime = () => {
    if (!inQueue) return (
      <div className={queueTimer ? "call-item__timers call-item__timers--pause" : "call-item__timers"}>
        {mainTimer && 
          <CallTimer
            className={isActiveCall ? "call-item__timer--active" : ""}
            timer={mainTimer} />
        }

        {queueTimer &&
          <CallTimer
            className={isActiveCall ? "call-item__timer--active-hold" : "call-item__timer--hold"}
            timer={queueTimer} />
        }
      </div>
    )
  }

  const getContactName = () => {
    const name = connectedCaller?.short_name || connectedCaller?.fn || connectedCaller?.username;

    if (missed || connectedCaller && name) {
      return name;
    }
    return "Unknown contact";
  };

  const alarmClass = () => {
    if (onHold) {
      return queueTimer && queueTimer.counter >= 10000
        ? "alarm"
        : "";
    }
    else if (onTransfer) {
      return "transfer";
    }
    else {
      return false;
    }
  };

  const getTransferOperatorsById = (id) => { 
    if (id === props.call.queueData.sender?.id) {
      return (
        <div className="call-item__arrow-transfer">
          <span className="call-item__sender-name">
            {call.queueData.sender?.username || 'You'}
          </span>

          <span className="call-item__arrow-rigth">
            <ICONS.arrowCircle fill="#00A562" width={13} height={13} />
          </span>

          <span className="call-item__recipient-name">
            {call.queueData.recipient?.username || 'Operator'}
          </span>
        </div>
      )
      
    } else { 
      return (
        <div className="call-item__arrow-transfer">
          <span className="call-item__recipient-name">
            {call.queueData.recipient?.username || 'Operator'}
          </span>

          <span className="call-item__arrow-rigth">
            <ICONS.arrowCircle fill="#00A562" width={13} height={13} />
          </span>

          <span className="call-item__sender-name">
            {call.queueData.sender?.username || 'You'}
          </span>
        </div>
      )
    }
  }

  const isOutgoingRinging =
    activeCall.type === CALL_TYPES.outgoing &&
    [CALL_STATUSES.newCall, CALL_STATUSES.initiated, CALL_STATUSES.ringing].includes(activeCall.status);

  return (
    <Fragment>
      <div className={classModifier("call-item", [
        alarmClass() && alarmClass(),
        isActiveCall && "active",
        inQueue && "incoming",
      ])}>
        <div className="call-item__main">
          <div 
            className={classModifier("call-item__info", [
              isActiveCall && "active", 
              isOutgoingRinging && 'outgoing-ringing',
            ])}
          >
            <div className="call-item__content-wrap">
              <div
                onClick={isOperator ? null : () => toggleFullScreenCall()}
                className="call-item__img-wrap"
              >
                <LazyLoadImage src={getContactAvatar(connectedCaller)} alt="ava" className="call-item__img" />
              </div>

              <p className="call-item__number" onClick={isOperator ? null : () => toggleFullScreenCall()} >
                {getContactName()}

                {onTransfer && getTransferOperatorsById(props.call.user?.id)}

                {onHold || inQueue || isActiveCall && <span>{props.call.channel}</span>}

                {isOperator && <b className="call-item__operator">Operator</b>}
              </p>
            </div>

            {onHold && call.isMute && (
              <span className="call-item__hold-muted-icon">
                <ICONS.snowflake fill='#00A562' width={18} height={20}/>
              </span>
            )}

            {isOutgoingRinging 
              ? <span className="call-item__outgoing-msg">Outgoing..</span>
              : renderCallTime()
            }
          </div>

          {!isOperator && !isActiveCall && !isSoftPhoneCall &&
            <Menu
              iconSize={14}
              menuItems={menu}
              icon={ICONS.burgerMenu}
            />
          }
        </div>

        {renderMainBtn()}
      </div>

      {call.voicemail &&
        <AudioPlayer
          onPlay={(audio) => onVoicemailPlay(audio, call.voicemail)}
          src={call.voicemail.url} />
      }

      {isActiveCall && !isSoftPhoneCall && renderActions()}
    </Fragment >
  );
};


const mapStateToProps = (state, ownProps) => ({
  connectedCaller: selectContactFromEntities(state, ownProps.caller.id, ownProps.caller.type) || {},
  activeCall: state.calls.activeCall,
  mainTimer: state.timers[ownProps.call.id],
  queueTimer: state.timers['queue_' + ownProps.call.id],
  chatId: selectRoomIdByOperatorId(state, ownProps.caller.id),
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  unholdCall,
  acceptTransferedCall,
  acceptIncomingQueueCall,
  putCallOnHold,
  createTransferredCall,
  updateActiveContact,
  removeMissedCall,
  onHoldActiveConference,
  removeMissedCallForAll,
  getContactsById,
  updateActiveChat,
  playMedia,
  createWarmTransferCall,
  openModal,
  closeModal,
  webrtcAcceptIncomingCall,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallItem);
