import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";
import { Droppable } from 'react-beautiful-dnd';

import { useDidMount } from '../../../../../hooks';
import { DIVA_GIRLS_LIMIT } from 'redux/ducks/divaGirls';

import Spinner from '../../../../../components/UI/Spinner/Spinner';
import SearchByFiltersItem from "./SearchByFiltersItem";

export const NOT_FOUND_TEXT = 'Filters not Found';
export const FILTERS_ERROR = 'Error, please reload the page';

const FiltersList = props => {
  const {
    filters, // loaded filters by filterType
    setFilters,
    activeFilters, // saves user filters by filterType
    filterType = '',
    droppableId,
    search,
    setSearch,
    getFilters,
    expectedSearchListLength,
    isMaxSearchPage,
    addFilter,
    setFiltersCount,
    setAddedFromSearchCount,
  } = props;

  const [pending, setPending] = useState(true);

  const listRef = useRef();
  const expectedListLength = useRef(DIVA_GIRLS_LIMIT);
  const isLoading = useRef(true);
  const isMaxPage = useRef(false);

  useDidMount(() => {
    const initComponent = () => {
      getFilters({ filterType })
        .then(({ entities, result: ids, count }) => {
          setFiltersCount(count);

          setFilters(prevFilters => ({
            entities: { ...prevFilters.entities, ...entities},
            ids,
          }));
        })
        .catch(() => {
          setFiltersCount(activeFilters.length);

          setFilters(prevFilters => ({
            entities: prevFilters.entities,
            ids: [FILTERS_ERROR],
          }));
        })
        .finally(() => {
          isLoading.current = false;
          setPending(false);
        })
    };

    initComponent();
  });

  useEffect(() => {
      setAddedFromSearchCount(search.filterIds.filter(id => activeFilters.includes(id)).length);
  }, [activeFilters, search]);

  useEffect(() => { // load more filters if it need
    const allowedFilters = filters.ids.filter(id => !activeFilters.includes(id));

    if (!isLoading.current && !isMaxPage.current && !isMaxSearchPage.current && allowedFilters.length < DIVA_GIRLS_LIMIT) {
      loadMore();
    }
  }, [filters, search]);

  const loadMore = useCallback(async () => {
    const isSearch = !!search.query;

    const canLoadMore = isSearch
      ? expectedSearchListLength.current === search.filterIds.length
      : expectedListLength.current === filters.ids.length;

    if (!canLoadMore) {
      return;
    }

    const offset = isSearch
      ? search.filterIds.length
      : filters.ids.length;

    const params = {
      part: offset / DIVA_GIRLS_LIMIT + 1,
      search: search.query || '',
      filterType,
    };

    const {
      entities,
      result: ids,
      isMaxPage: isItMaxPage,
    } = await getFilters(params);

    if (isSearch) {
      isMaxSearchPage.current = isItMaxPage;
      expectedSearchListLength.current = search.filterIds.length + DIVA_GIRLS_LIMIT;
    }
    else {
      expectedListLength.current = filters.ids.length + DIVA_GIRLS_LIMIT;
      isMaxPage.current = isItMaxPage;
    }

    if (search.query) {
      setFilters(prevFilters => ({
        ...prevFilters,
        entities: { ...prevFilters.entities, ...entities },
      }));
      setSearch(prevSearch => ({
        ...prevSearch,
        filterIds: [...prevSearch.filterIds, ...ids],
      }))
    }
    else {
      setFilters(prevFilters => ({
        entities: { ...prevFilters.entities, ...entities },
        ids: [...prevFilters.ids, ...ids],
      }));
    }
  }, [filters, search]);

  const handleScroll = (e) => {
    if (isMaxPage.current || isMaxSearchPage.current) return;

    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const scrollBottom = scrollHeight - scrollTop - clientHeight;

    if (scrollBottom === 0) {
      loadMore();
    }
  };

  const listForView = useMemo(() => search.query
    ? search.filterIds?.filter(id => !activeFilters.includes(id))
    : (filters.ids || []).filter(id => !activeFilters.includes(id)),
  [search, filters, activeFilters]);

    return (
      pending
      ? <Spinner spinnerSize={90} isCentered />
      : <div className="filters-editor__list-wrap">
        <Droppable droppableId={droppableId}>
          {(provided) => (
            <div
              className="filters-editor__services"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Scrollbars
                onScroll={handleScroll}
                autoHide
                ref={listRef}
                renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
              >
                <ul className="filters-editor__list">
                  {listForView.map((filterId, index) =>
                    (filterId === NOT_FOUND_TEXT || filterId === FILTERS_ERROR)
                      ? <div key={index} className='filters-editor__search-error'>{filterId}</div>
                      : (
                        <SearchByFiltersItem
                          key={filterId}
                          id={filterId}
                          index={index}
                          name={filters.entities[filterId].name}
                          filterType={filterType}
                          addFilter={addFilter}
                        />
                      )
                  )}
                </ul>
              </Scrollbars>

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
};

export default FiltersList;
