import axios from 'axios';

import API from 'api/api';
import { normalize } from 'utils/normalizeContacts';
import { addToEntitiesIfMissing, isEmptyObj } from 'utils'
import { selectActiveGirlByDiva, selectDivaActiveGirlsFromChats, selectProfilesByIds } from 'redux/selectors/selectors';


export const DIVA_SORT_PARAMS = {
  'expensive': 'Expensive first',
  'cheapest': 'Cheapest first',
  'youngest': 'Youngest first',
  'oldest': 'Oldest first',
  'tallest': 'Tallest first',
  'shortest': 'Shortest first',
  'busty': 'Busty first',
  'slimmest': 'Slimmest first',
  'chubby': 'Chubby first',
  'newest': 'Newest first',
}

export const DIVA_PROFILE_FILTER_NAMES = {
  age: 'filter-age_group',
  height: 'filter-height_group',
  breast: 'filter-breast_group',
  priceFrom: 'filter-price-from',
  priceTo: 'filter-price-to',
  ethnicity: 'filter-ethnicity',
  hair: 'filter-hair',
  location: 'filter-location',
}

export const GET_DIVA_GIRLS = 'GET_DIVA_GIRLS';
export const UPDATE_DIVA_GIRL = "UPDATE_DIVA_GIRL";
export const GET_FILTERS_DIVA = 'GET_FILTERS_DIVA';
export const ADD_DIVA_GIRLS_TO_ENTITIES = "ADD_DIVA_GIRLS_TO_ENTITIES";

export const GET_DIVA_GIRL_REVIEWS = 'GET_DIVA_GIRL_REVIEWS';
export const UPDATE_DIVA_GIRL_REVIEW = 'UPDATE_DIVA_GIRL_REVIEW';
export const DELETE_DIVA_GIRL_REVIEW = 'DELETE_DIVA_GIRL_REVIEW';

export const DIVA_GIRLS_LIMIT = 20;
export const DIVA_GIRLS_IMG_HOST = 'https://media.divaescort.com/images/media/';
export const DIVA_GIRLS_IMG_HOST_PREVIEW = 'https://media.divaescort.com/images/preview/';
export const DIVA_GIRLS_VIDEO_HOST = 'https://media.divaescort.com/images/original/';
export const DIVA_GIRLS_LINK = 'https://www.divaescort.com/girls/';
export const DIVA_GIRLS_ADMIN_PROFILE_DETAILS = 'https://admin.divaescort.com/profiles/details/item-id-';
export const DIVA_GIRLS_ADMIN_AVAILABILITY = 'https://admin.divaescort.com/profiles/availability/item-id-';

export const UPDATE_CURRENT_DRAGGABLE_ID = 'UPDATE_CURRENT_DRAGGABLE_ID';

export const UPDATE_FILTER_DIVA_GIRLS_API_PARAMS = 'UPDATE_FILTER_DIVA_GIRLS_API_PARAMS';

export const CLEAR_LOAD_PROFILES_PENDING_ON_ERROR = 'CLEAR_LOAD_PROFILES_PENDING_ON_ERROR';

export const UPDATE_BUFFER_ZONE_SAME_SERVICES = 'UPDATE_BUFFER_ZONE_SAME_SERVICES';
export const UPDATE_BUFFER_ZONE_SAME_PARAMS = 'UPDATE_BUFFER_ZONE_SAME_PARAMS';

export const GET_ADDITIONAL_FILTERS_DIVA = 'GET_ADDITIONAL_FILTERS_DIVA';

export const SET_PENDING = 'SET_PENDING';

const formatDivaGirls = (data) => data.map((item) => ({
  ...item,
  agent_id: Number(item.agent_id),
  exclusive: Boolean(Number(item.exclusive)),
  featured: Boolean(Number(item.featured)),
  has_tatoo: Boolean(Number(item.has_tatoo)),
  height: Number(item.height),
  id: Number(item.id),
  id_new: Number(item.id_new),
  is_archived: Boolean(Number(item.is_archived)),
  is_available_now: Boolean(Number(item.is_available_now)),
  is_available_today: Boolean(Number(item.is_available_today)),
  is_avoid: Boolean(Number(item.is_avoid)),
  is_banned: Boolean(Number(item.is_banned)),
  is_breast_natural: Boolean(Number(item.is_breast_natural)),
  is_debt: Boolean(Number(item.is_debt)),
  is_for_member: Boolean(Number(item.is_for_member)),
  is_mistress: Boolean(Number(item.is_mistress)),
  is_nojobs: Boolean(Number(item.is_nojobs)),
  is_not_recommended: Boolean(Number(item.is_not_recommended)),
  is_on_holiday: Boolean(Number(item.is_on_holiday)),
  is_published: Boolean(Number(item.is_published)),
  is_smoker: Boolean(Number(item.is_smoker)),
  is_top: Boolean(Number(item.is_top)),
  notesCount: Number(item.notesCount),
  offer: Boolean(Number(item.offer)),
  recommended: Boolean(Number(item.recommended)),
  special_proposal_price: Number(item.special_proposal_price),
}))

export const getActiveDivaGirls =
  ({
    params = {},
    additionalFilters = {},
    cutIds,
    quickBooking = false,
    isGallery = false,
    isGalleryListLoadMore = false,
    isGetFromState,
  }) => (dispatch, getState) => {
    const state = getState();

    if (!(params['filter-ids'] instanceof Array)) {
      const profile = selectProfilesByIds(state, [params['filter-ids']])[0];

      if (profile && isGetFromState) {
        return Promise.resolve({
          girls: formatDivaGirls([profile]),
          activeGirl: selectActiveGirlByDiva(state),
          activeGirlsFromChats: selectDivaActiveGirlsFromChats(state),
        })
      }
    }

    return API.getActiveDivaGirls(params)
      .then((res) => {
        if (isGalleryListLoadMore && Array.isArray(res.data)) return;

        if (quickBooking && Array.isArray(res.data)) {
          return {
            girls: [],
            activeGirl: {},
            activeGirlsFromChats: [],
          }
        }

        const { part, parts } = res.data.pagination;

        // the server sends data with a chaotic format and
        // a boolean value can be written as a string with a number (is_available_now: "0")
        // so we need to format it manually
        const formattedDivaGirls = formatDivaGirls(res.data.result);

        dispatch({
          type: GET_DIVA_GIRLS,
          payload: {
            ...normalize(formattedDivaGirls),
            isGallery: isGallery,
            ...(isGallery
              ? {
                count: res.data.pagination.items,
                isFiltered:
                  !!params["filterby"] ||
                  !!params["same-params"] ||
                  !isEmptyObj(additionalFilters),
                cutIds,
                isMaxGalleryPart: part >= parts,
              }
              : {}),
          },
        });

        const state = getState();

        const quickBookingResult = {
          girls: formattedDivaGirls,
          activeGirl: selectActiveGirlByDiva(state),
          activeGirlsFromChats: selectDivaActiveGirlsFromChats(state),
        }

        if (quickBooking) return res.data ? quickBookingResult : undefined;

        if (part >= parts) return "max-part"; // If achieved max part
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          dispatch({ type: CLEAR_LOAD_PROFILES_PENDING_ON_ERROR });
        }

        if (err && err.response && err.response.status === 404) {
          return "max-part";
        }
      });
  };

export const getDivaGirlReviews = (girlId) => dispatch => {
  const params = {
    'filter-profileid': girlId,
    'opt-with-review': 1
  };

  return API.getActiveDivaGirls(params)
    .then(res => {
      const { reviews, id } = res.data.result[0];

      dispatch({
        type: GET_DIVA_GIRL_REVIEWS,
        payload: {
          reviews,
          id
        }
      });
    })
    .catch(console.error);
};

export const updateDivaGirlReview = (girlId, review) => dispatch => {
  dispatch({
    type: UPDATE_DIVA_GIRL_REVIEW,
    payload: { girlId, review }
  });
};

export const deleteDivaGirlReview = (girlId, reviewId) => dispatch => {
  dispatch({
    type: DELETE_DIVA_GIRL_REVIEW,
    payload: { girlId, reviewId }
  });
};

export const getDivaFilters = (params = {}) => dispatch => {
  return API.getDivaFilters(params)
    .then(res => {
      const { entities, result } = normalize(res.data.result, 'key');

      dispatch({
        type: GET_FILTERS_DIVA,
        payload: entities
      });
    })
    .catch(console.error);
};

export const getAdditionalFiltersFromDiva = () => dispatch => {
  return API.getAdditionalFiltersFromDiva()
    .then(({ data }) => {
      dispatch({
        type: GET_ADDITIONAL_FILTERS_DIVA,
        payload: data
      });
    })
    .catch(console.error);
};

export const updateCurrentDraggableId = (id) => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_DRAGGABLE_ID,
    payload: id
  });
};

export const updateFilterDivaGirlsApiParams = (newParams) => dispatch => {
  return dispatch({
    type: UPDATE_FILTER_DIVA_GIRLS_API_PARAMS,
    payload: newParams
  });
};


export const updateBufferZoneSameServices = (comparedIds = []) => dispatch => {
  dispatch({
    type: UPDATE_BUFFER_ZONE_SAME_SERVICES,
    payload: comparedIds,
  });
  dispatch({
    type: UPDATE_BUFFER_ZONE_SAME_PARAMS,
    payload: comparedIds,
  });
};

export const addDivaGirlsToEntities = (girls) => dispatch => {
  dispatch({
    type: ADD_DIVA_GIRLS_TO_ENTITIES,
    payload: girls
  })
}

export const updateDivaGirl = (profile) => dispatch => {
  dispatch({
    type: UPDATE_DIVA_GIRL,
    payload: profile
  });
};

const initialState = {
  entities: {},
  ids: [],
  currentDraggableId: null,
  filters: {},
  divaAdditionalFilters: null, // List of additional filters from diva
  serviceFiltersIds: [],
  paramsFiltersIds: [],
  auxiliaryIds: [],
  filterDivaGirlsApiParams: null,
  loadProfilesPending: false,
  isMaxGalleryPart: false,
  count: null,
  bufferZoneSameServices: [],
  bufferZoneSameParams: {
    age: false,
    breast_size: false,
    dress: false,
    hair: false,
    height: false,
    nationality: false,
    language: [],
    location: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DIVA_GIRLS: {
      const unique = (id, i, self) => self.indexOf(id) === i;

      for (let entityId in payload.entities) {
        for (let serviceId in payload.entities[entityId].services) {
          payload.entities[entityId].services[serviceId].id = serviceId
        }
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        ...(payload.isGallery ? {
          ids: payload.cutIds
            ? payload.result
            : [
              ...state.ids,
              ...payload.result
            ].filter(unique),
          auxiliaryIds: payload.isFiltered
            ? payload.cutIds
              ? payload.result
              : [
                ...state.auxiliaryIds,
                ...payload.result
              ].filter(unique)
            : [],
          loadProfilesPending: false,
          count: payload.count,
          isMaxGalleryPart: payload.isMaxGalleryPart,
        } : {}),
      };
    }
    case GET_DIVA_GIRL_REVIEWS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {
            ...state.entities[payload.id],
            reviews: payload.reviews
          }
        }
      }
    }
    case UPDATE_DIVA_GIRL_REVIEW: {
      let divaReviews = [...state.entities[payload.girlId].reviews];
      const editedDivaReviewIndex = divaReviews.findIndex(review => review.id == payload.review.id);

      if (!!divaReviews.length && editedDivaReviewIndex !== -1) {
        divaReviews[editedDivaReviewIndex] = payload.review;
      }
      else {
        return state
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.girlId]: {
            ...state.entities[payload.girlId],
            reviews: divaReviews,
          }
        }
      }
    }
    case DELETE_DIVA_GIRL_REVIEW: {
      let divaReviews = state.entities[payload.girlId].reviews;
      const updatedDivaReviews = divaReviews.filter(review => review.id != payload.reviewId);

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.girlId]: {
            ...state.entities[payload.girlId],
            reviews: updatedDivaReviews,
          }
        }
      }
    }
    case GET_FILTERS_DIVA: {
      const servicesFiltersIds = [];
      const otherParamsFiltersIds = [];

      for (const key in payload) {
        if (payload[key].filterType) {
          if (payload[key].filterType === 'services') {
            servicesFiltersIds.push(key)
          }
          else if (payload[key].filterType === 'other-params') {
            otherParamsFiltersIds.push(key)
          }
        }
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload
        },
        serviceFiltersIds: [
          ...state.serviceFiltersIds,
          ...servicesFiltersIds
        ],
        paramsFiltersIds: [
          ...state.paramsFiltersIds,
          ...otherParamsFiltersIds
        ],
      }
    }
    case GET_ADDITIONAL_FILTERS_DIVA: {
      return {
        ...state,
        divaAdditionalFilters: payload
      }
    }
    case UPDATE_CURRENT_DRAGGABLE_ID: {
      return {
        ...state,
        currentDraggableId: payload
      }
    }
    case UPDATE_FILTER_DIVA_GIRLS_API_PARAMS: {
      return {
        ...state,
        filterDivaGirlsApiParams: JSON.stringify(payload) === JSON.stringify(state.filterDivaGirlsApiParams)
          ? state.filterDivaGirlsApiParams
          : payload,
        auxiliaryIds: [],
        loadProfilesPending: payload === null
          ? false
          : true,
      }
    }
    case CLEAR_LOAD_PROFILES_PENDING_ON_ERROR: {
      return {
        ...state,
        loadProfilesPending: false,
        ids: [],
        auxiliaryIds: [],
        count: 0
      }
    }
    case UPDATE_BUFFER_ZONE_SAME_SERVICES: {
      if (payload.length <= 1) {
        return {
          ...state,
          bufferZoneSameServices: []
        };
      };

      const allServices = [];
      const sameServices = [];

      payload.forEach(id => allServices.push(...state.entities[id].services.map(service => service.name)));

      const uniqueServices = allServices.filter((service, i, self) => self.indexOf(service) === i);

      uniqueServices.forEach(service => {
        let count = 0;
        let i = allServices.length;

        while (i--) {
          if (allServices[i] === service) {
            count++;
          }
        };

        if (count === payload.length) {
          sameServices.push(service);
        }
      });

      if (JSON.stringify(sameServices.sort()) === JSON.stringify(state.bufferZoneSameServices.sort())) {
        return state;
      }

      return {
        ...state,
        bufferZoneSameServices: sameServices
      }
    }
    case UPDATE_BUFFER_ZONE_SAME_PARAMS: {
      if (payload.length <= 1) {
        return {
          ...state,
          bufferZoneSameParams: {},
        };
      };

      const sameParams = {
        age: null,
        breast_size: null,
        dress: null,
        hair: null,
        height: null,
        nationality: null,
      };

      payload.forEach(profileId => {
        const profile = state.entities[profileId];
        const params = Object.keys(sameParams);

        params.forEach(param => {
          if (sameParams[param] === null) {
            sameParams[param] = profile[param];
          }
          else {
            sameParams[param] = !!sameParams[param] && sameParams[param] === profile[param]
              ? profile[param]
              : false;
          }
        });
      });

      const allLanguages = [];
      const allLocations = [];
      sameParams.location = [];
      sameParams.language = [];

      payload.forEach(profileId => {
        const profile = state.entities[profileId];

        allLanguages.push(...profile.language);
        allLocations.push(...profile.location);
      });

      const uniqueLanguages = allLanguages.filter((language, i, self) => self.indexOf(language) === i);
      const uniqueLocations = allLocations.filter((location, i, self) => self.indexOf(location) === i);

      uniqueLanguages.forEach(language => {
        let count = 0;
        let i = allLanguages.length;

        while (i--) {
          if (allLanguages[i] === language) {
            count++;
          }
        }

        if (count === payload.length) {
          sameParams.language.push(language);
        }
      });

      uniqueLocations.forEach(location => {
        let count = 0;
        let i = allLocations.length;

        while (i--) {
          if (allLocations[i] === location) {
            count++;
          }
        }

        if (count === payload.length) {
          sameParams.location.push(location);
        }
      });

      if (JSON.stringify(state.bufferZoneSameParams) === JSON.stringify(sameParams)) {
        return state;
      }

      return {
        ...state,
        bufferZoneSameParams: sameParams,
      }
    }
    case ADD_DIVA_GIRLS_TO_ENTITIES: {
      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, payload, 'id'),
      };
    }

    case UPDATE_DIVA_GIRL: {
      if (!state.entities[payload.id]) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: payload
        }
      };
    }

    default:
      return state;
  }
}
