import { LS } from 'utils';

export default (field, value) => {
  const storedData = LS.getItem("telegramServers");

  if (storedData) {
    const data = JSON.parse(storedData);
    const foundItem = data.find(item => item[field] === value);
    return foundItem || null;
  }

  return null;
};
