import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';

import Portal from './Portal';

/* 
  Note: the usePopper hook intentionally takes the DOM node, not refs, 
  in order to be able to update when the nodes change. 
  A callback ref is used here to permit this behaviour, 
  and useState is an appropriate way to implement this.
*/

const DropWrapper = (props) => {
  const {
    disablePortal,
    isActive,
    setIsActive,
    offsetX = 0,
    offsetY = 5,
    placement = 'bottom',
    popperModifiers = [],
    popperOptions = [],
    referenceElement,
  } = props;

  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      { name: 'offset', options: { offset: [offsetX, offsetY] } },
      { name: 'preventOverflow', options: { padding: 10 } },
      ...popperModifiers
    ],
    ...popperOptions,
  });
  
  // Handle referenceElement click
  useEffect(() => {
    const handler = (e) => {
      e.stopPropagation();
      if (!popperElement?.contains(e.target)) {
        setIsActive(state => !state);
      }
    };

    if (referenceElement) {
      referenceElement.addEventListener('click', handler);

      return () => {
        referenceElement.removeEventListener("click", handler);
      }
    }
  }, [referenceElement, popperElement]);

  // Handle outside click
  useEffect(() => {
    const handler = (e) => {
      if (popperElement && 
        !popperElement.contains(e.target) && 
        !referenceElement.contains(e.target)
      ) {
        setIsActive(false);
      }
    };
    
    document.addEventListener("click", handler);

    return () => {
      document.removeEventListener("click", handler);
    };
  }, [referenceElement, popperElement]);

  return !isActive ? null : (
    <Portal disablePortal={disablePortal}>
      <div
        ref={setPopperElement}
        style={{ ...styles.popper, zIndex: 100 }}
        {...attributes.popper}
      >
        {props.children}
      </div>
    </Portal>
  )
};

export default React.memo(DropWrapper);
