import _ from 'lodash';

export default (obj, getter) => {
  const result = [obj];
  let newObj = obj;

  while (_.isObject(getter(newObj))) {
    newObj = getter(newObj);
    result.push(newObj);
  }

  return result;
}
