import { BOOKING_FILTERS_NAMES } from 'components/FilteredBookings'

import './CalendarTable.scss';
import CalendarTableBookings from './components/CalendarTableBookings';
import {
  BookingFilters,
  BookingFiltersList,
  BookingSelectFilters,
  BookingStatusFilters,
  BookingTag,
} from 'components/FilteredBookings';


const MainFilters = ({ getters }) => {
  const { getFilterProps, getStatusFilterProps } = getters;

  return (
    <div className="calendar-table__main-filters">
      <BookingFilters
        className="calendar-table__filters"
        {...getFilterProps()}
      >
        <BookingFilters.prebooking />
        <BookingFilters.outcall />
        <BookingFilters.duo />
      </BookingFilters>

      <BookingStatusFilters {...getStatusFilterProps()}>
        <BookingStatusFilters.todo />
        <BookingStatusFilters.confirmed />
        <BookingStatusFilters.done />
        <BookingStatusFilters.cancelled />
        <BookingStatusFilters.tosort />
      </BookingStatusFilters>

      <BookingTag
        {...getFilterProps({
          name: BOOKING_FILTERS_NAMES.finished,
          className: "calendar-table__booking-tag",
          coloredLabel: true
        })}
      />
    </div>
  )
}

const SecondaryFilters = ({ getters }) => {
  const { getFilterProps, getSelectProps } = getters;

  return (
    <BookingSelectFilters
      {...getSelectProps()}
      classModifier="calendar-table-select-filter"
      className="calendar-table__select-filters"
    >
      <BookingSelectFilters.girl />
      <BookingSelectFilters.client />
      <BookingSelectFilters.operator />

      <div className="calendar-table__agents">
        <BookingFilters.agent {...getFilterProps()} />
        <BookingSelectFilters.agent />
      </div>
    </BookingSelectFilters>
  )
}

const CalendarTableHeader = ({ getters }) => {
	const { getFiltersContainerProps } = getters;

  return (
    <BookingFiltersList
      className="calendar-table__header"
      {...getFiltersContainerProps()}
    >
      <MainFilters getters={getters} />
      <SecondaryFilters getters={getters} />
    </BookingFiltersList>
  )
}

const CalendarTable = (props) => {
  const {
    date,
    getters,
    getFilterProps,
    actualDate,
    selectedBookings,
    setSelectedBookings
  } = props;

  const { getSortOptionsProps } = getters;

  return (
    <div className="calendar-table">
      <CalendarTableHeader getters={getters} />
      <CalendarTableBookings
        date={date}
        actualDate={actualDate}
        getters={getters}
        getFilterProps={getFilterProps}
        selectedBookings={selectedBookings}
        setSelectedBookings={setSelectedBookings}
        {...getSortOptionsProps()}
      />
    </div>
  );
};

export default CalendarTable;
