import React from 'react';

import { getDateByTimezoneOffset } from 'utils';


const ContactBlockInfo = ({ activeRecipient, userTimezone }) => {
  const formatBlockedDate = date => (
    getDateByTimezoneOffset(userTimezone, date)
      .toLocaleString(
        'en-US',
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
      )
  );

  return (
    <div className="chat-header__block-info">
      This user was blocked by <span> {activeRecipient.blocked.user.username}</span> <br/>
      at {formatBlockedDate(activeRecipient.blocked.date_created)} <br/>
      <span>because of:</span> {activeRecipient.blocked.reason}
    </div >
  );
}

export default ContactBlockInfo;