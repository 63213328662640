import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { toggleContactBlock } from 'redux/ducks/contacts';
import { selectContactById } from 'redux/selectors/selectors';

import './BlockForm.scss';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';

const BlockForm = (props) => {
  const {
    activeRecipient,
  } = props;

  const [serverErrors, setServerErrors] = useState({});

  const onEnterPress = (e, submit) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      submit();
    }
  };

  const onSubmit = (values) => {
    props.toggleContactBlock(activeRecipient, values.reason)
      .then(res => {
        if (res && res.isFailed) {
          setServerErrors(res.msg);
        }
        else {
          props.closeModal();
        }
      });
  };

  const contactType = activeRecipient.type === 1 ? 'client' : 'girl';

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        reason: '',
      }}
      validate={validate}
    >
      {({ handleSubmit, valid }) => (
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => { onEnterPress(e, handleSubmit) }}
          className='block-form'
        >
          <h2 className='block-form__title'>
            Adding {contactType} <span>{activeRecipient.short_name || activeRecipient.fn}</span> to <b>BLACKLIST</b>
          </h2>

          <Field name="reason">
            {({ input, ...fieldProps }) => (
              <FormTextTextarea
                {...fieldProps}
                autoComplete="off"
                className='block-form-input'
                getErrors={serverErrors}
                name='reason'
                placeholderText='Describe the reason...'
                setServerErrors={setServerErrors}
                type='textarea'
                asyncValidation
                input={{
                  ...input, onChange: (e) => {
                    input.onChange(e);
                  }
                }}
              />
            )}
          </Field>

          <button
            className='block-form__btn'
            type='submit'
            disabled={!valid}
          >
            Submit
          </button>
        </form>
      )}
    </Form>
  );
}

const validate = ({ reason }) => {
  const errors = {};
  const getIsFieldEmpty = (name) => !name || !name.trim().length;

  if (getIsFieldEmpty(reason)) {
    errors.reason = 'Reason is required';
  }
  else if (reason.length < 10) {
    errors.reason = 'Reason is too short, please add more information.';
  }
  else if (reason.length > 255) {
    errors.reason = 'Reason is too long';
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => ({
  activeRecipient: selectContactById(state, ownProps.contact) || {}
});

const mapDispatchToProps = {
  toggleContactBlock,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockForm);
