import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import "./GorizontalList.scss";
import TypingIndicator from 'components/UI/TypingIndicator/TypingIndicator';
import { classModifier } from 'utils';
import ICONS from 'assets/icons';
import { useDidMount, useToggle } from 'hooks';

const GorizontalList = (props) => {
  const {
    classPrefix,
    list,
    listLoadPending,
    listItemKey = 'id',
    useIndexAsItemKey,
    absentLabel,
    minLength,
    children
  } = props;

  const [isRevealed, toggleIsRevealed] = useToggle(false);
  const filteredList = isRevealed ? list : list.slice(0, minLength);

  const getItemKey = (item, index) => {
    if (useIndexAsItemKey) {
      return index;
    }
    return item?.[listItemKey] || item;
  }

  useEffect(() => {
    toggleIsRevealed(false);
  }, [list])

  return (
    <div className={
      classNames("gorizontal-list", classModifier(classPrefix, isRevealed && 'revealed'))
    }>
      {listLoadPending
        ? (
          <div className={classNames("gorizontal-list__pending", classPrefix + "__pending")}>
            <TypingIndicator />
          </div>
        )
        : filteredList.length
          ? filteredList.map((item, index) => {
              const newProps = {
                key: getItemKey(item, index),
                item,
                className: classPrefix + "__item",
              };

              return typeof children === 'function'
                ? children(newProps)
                : React.cloneElement(props.children, newProps);
            })
          : <strong>{absentLabel}</strong>
      }
      {list.length > minLength && (
        <button
          className={
            classNames(
              classModifier("gorizontal-list__more-button", isRevealed && 'revealed'),
              classModifier(classPrefix + "__more-button", isRevealed && 'revealed')
            )
          }
          onClick={toggleIsRevealed}
        >
          {!isRevealed ? `+${list.length - minLength}` : <ICONS.close />}
        </button>
      )}
    </div>
  )
}

export default GorizontalList;
