import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { PROFILE_BOOKING_DATE_CONFIG } from 'config/dates-сonfig';
import { classModifier } from 'utils';
import "./BookingClientItem.scss";

const detailsItems = [
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
  BOOKING_DETAILS_ITEMS_NAMES.REQUESTS,
  BOOKING_DETAILS_ITEMS_NAMES.AGENTS
]

const BookingClientItem = (props) => {
  const {
    Date,
    Escort,
    Details,
    ConfirmationStatus,
    Markers,
    statusColor,
    isDeleted,
  } = props;

  return (
    <li
      className={classModifier('booking-client-item', isDeleted && 'deleted')}
    >
      <div className="booking-client-item__column">
        <div
          className="booking-client-item__color-line"
          style={{ backgroundColor: statusColor }}
        ></div>

        <Date
          className="booking-client-item__date"
          config={PROFILE_BOOKING_DATE_CONFIG}
        />

        <Escort
          className="booking-client-item__escort"
          isAvatarShowed
          hideAvatarsWhenDuoBookingExist
          namesAlignment='vertical'
        />
      </div>

      <div className="booking-client-item__column">
        <Details
          className="booking-client-item__details"
          items={detailsItems}
          namesAlignment='column'
        />
      </div>

      <div className={classModifier("booking-client-item__column", "align-right")}>
        <ConfirmationStatus className="booking-client-item__confirmation-status" />
        <Markers className="booking-client-item__markers" />
      </div>
    </li>
  )
}

export default BookingClientItem;
