import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import ICONS from 'assets/icons';
import { getContactsById } from 'redux/ducks/contacts';
import { selectLockedOperator } from 'redux/selectors/selectors';
import { classModifier } from 'utils';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { usePrevious } from 'hooks';
import { BOOKING_STATUS_FILTERS } from 'components/FilteredBookings/config/constants';
import { BASE_DATE_CONFIG } from 'config/dates-сonfig';

import "./BookingConfinedItem.scss";
import Portal from 'components/Portal';
import DateTime from 'components/DateTime';

const BookingListItem = ({
  modificator,
  item,
  isSelectedByClient,
  setIsSelectedByClient,
  isSelectedByGirl,
  setIsSelectedByGirl,
  onDeleteAction,
  getCallbackRef = () => null,
  preventEditModal,
}) => {
  const bookedDivaSoloProfile = useSelector(state => state.divaGirls.entities[item?.profile_id]);
  const operatorName = useSelector((state) => selectLockedOperator(state, item.usersIds[0]));
  const activeClientChat = useSelector((state) => state.clientChats.active);
  const activeGirlChat = useSelector((state) => state.girlChats.active);
  const bookingStatusColor
    = Object.values(BOOKING_STATUS_FILTERS)
      .find((status) => status.label === item.confirmation_status).color;

  const [isClientNameOvercrowded, setIsClientNameOvercrowded] = useState(false);
  const [isGirlNameOvercrowded, setIsGirlNameOvercrowded] = useState(false);
  const dispatch = useDispatch();

  const prevActiveClientChat = usePrevious(activeClientChat);
  const prevActiveGirlChat = usePrevious(activeGirlChat);

  const girlName = item?.profile_name || item?.profileName || bookedDivaSoloProfile?.name;
  const callerId = item.caller_id || item.callerId;
  const girlContactId = item.girl_caller_id;

  const {
    getTooltipProps: getRequirementsTooltipProps,
    setTooltipRef: setRequirementsTooltipRef,
    setTriggerRef: setRequirementsTriggerRef,
    visible: requirementVisible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: 'top',
  })

  const {
    getTooltipProps: getClientNameTooltipProps,
    setTooltipRef: setClientNameTooltipRef,
    setTriggerRef: setClientNameTriggerRef,
    visible: clientNameVisible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: 'top',
  })

  const {
    getTooltipProps: getGirlNameTooltipProps,
    setTooltipRef: setGirlNameTooltipRef,
    setTriggerRef: setGirlNameTriggerRef,
    visible: girlNameVisible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: 'top',
  })

  const reqsFromSmallObj = item.requirementsTitles?.map((req, idx) => ({ title: req, color: item.requirementsColors?.[idx] })).reverse() || [];
  const reqsFromFullObj = item.requirements?.filter((requirement) => !requirement.is_checked);

  const uncheckedRequirements = !!reqsFromFullObj?.length
    ? reqsFromFullObj 
    : reqsFromSmallObj;

  const getBookingTime = () => {
    const getRangeDate = (range) => (
      <DateTime
        date={item.date}
        config={BASE_DATE_CONFIG}
        range={range}
      />
    )

    switch (item.duration) {
      case '30 minutes':
        return getRangeDate(30);
      case '45 minutes':
        return getRangeDate(45);
      case '1 hour':
        return getRangeDate(60);
      case 'Extra hour':
        return 'Extra hour';
      case '90 minutes':
        return getRangeDate(90);
      case '2 hours':
        return getRangeDate(120);
      case '3 hours':
        return getRangeDate(180);
      case 'Dinner date':
        return 'Dinner date';
      case '12 hours':
        return getRangeDate(360);
      case 'Overnight':
        return 'Overnight';
      default:
        'incorrect time';
    }
  }

  const handleOpenEditBooking = () => {
    if (preventEditModal) {
      return;
    }
    dispatch(openModal(MODAL_TYPES.bookingEditForm, { 
      booking: item,
      profileId: item.profile_id,
      callerId,
    })).then(({ isDeleted }) => {
      if (isDeleted && onDeleteAction) {
        onDeleteAction();
      }
    });
  };

  const handleOpenChat = (event, id, setIsSelected) => {
    event.stopPropagation();
    
    dispatch(getContactsById(id))
      .then((contact) => {
        if (contact) {
          dispatch(updateActiveContact(contact))
          setIsSelected(item.id);
        }
      })
  }
  const getDuration = (duration) => {
    const [counts, dimensions] = duration.split(' ');

    if (dimensions === 'minutes') {
      return counts + ' ' + 'min';
    }

    return duration;
  }

  useEffect(() => {
    setIsSelectedByClient && prevActiveClientChat !== activeClientChat && setIsSelectedByClient(null);
    setIsSelectedByGirl && prevActiveGirlChat !== activeGirlChat && setIsSelectedByGirl(null);
  }, [activeClientChat, activeGirlChat])

  const onClickByGirlName = (event, girlContactId, setIsSelectedByGirl) => {
    event.stopPropagation();

    if(!girlContactId) {
      API.getCallerIdByProfileDivaId(item.profile_id).then(({ data }) => {
        if(data){
          handleOpenChat(event, data.id, setIsSelectedByGirl);
        }
      }).catch(console.error);
    } else {
      handleOpenChat(event, girlContactId, setIsSelectedByGirl);
    }
  }

  return (
    <div
      className={classModifier("bookings-list-item", [
          [isSelectedByClient, isSelectedByGirl].includes(item.id) && "selected",
          modificator
        ]
      )}
      onClick={handleOpenEditBooking}
      data-isanimate="false"
      style={{
        borderColor: bookingStatusColor,
        backgroundColor: bookingStatusColor,
        cursor: preventEditModal ? 'default': 'pointer'
      }}
      ref={getCallbackRef(item.id)}
    >
      <div
        className="bookings-list-item__header"
        style={{ borderColor: bookingStatusColor }}
      >

        <div className="bookings-list-item__header-info">
          <div className="bookings-list-item__info-icons">
            {!!item.is_prebooking && 
              <ICONS.p className="bookings-list-item__icon-pre-booking"/>
            }
          </div>

          <div className="bookings-list-item__requirements">
            {
              !!uncheckedRequirements.length && (
                <>
                  <div
                    className="bookings-list-item__requirements-marks-wrapper"
                  >
                    <div
                      className="bookings-list-item__requirements-marks"
                      ref={setRequirementsTriggerRef}
                    >
                      {uncheckedRequirements.map(({ color }) => (
                        <mark
                          key={color}
                          className="bookings-list-item__requirement-mark"
                          style={{ backgroundColor: color }}
                        />
                      ))}
                    </div>
                  </div>

                  {requirementVisible && (
                    <Portal>
                      <div
                        className="popup"
                        ref={setRequirementsTooltipRef}
                        {...getRequirementsTooltipProps()}
                      >
                        <ul className="bookings-list-item__requirements-list">
                          {uncheckedRequirements.map((requirement) => (
                            <li key={requirement.title}>
                              {requirement.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Portal>
                  )}
                </>
              )
            }
          </div>
        </div>
        <div className="bookings-list-item__time">
          <div className="bookings-list-item__timeline">
            {getBookingTime()}
          </div>
          
          <span className="bookings-list-item__operator-name">
            {operatorName}
          </span>
        </div>
      </div>

      <div
        className="bookings-list-item__content"
        style={{ borderColor: bookingStatusColor }}
      >
        <p className="bookings-list-item__info">
          {getDuration(item.duration)} / <span className="bookings-list-item__type">{item.type}</span> / £{item.rate}
        </p>

        <p
          ref={(node) => {
            node && node.offsetWidth < node.scrollWidth && setIsGirlNameOvercrowded(true);
            node && setGirlNameTriggerRef(node)
          }}
          className="bookings-list-item__girl-name"
        >
          <span
            {...(setIsSelectedByGirl ? { onClick: (event) => {
              onClickByGirlName(event, girlContactId, setIsSelectedByGirl)
            } } : {})}
          >
            {girlName}
          </span>
        </p>

        {girlNameVisible && isGirlNameOvercrowded &&
          <Portal>
            <div
              className="popup"
              ref={setGirlNameTooltipRef}
              {...getGirlNameTooltipProps()}
            >
              {girlName}
            </div>
          </Portal>
        }

        <p
          ref={(node) => {
            node && node.offsetWidth < node.scrollWidth && setIsClientNameOvercrowded(true);
            node && setClientNameTriggerRef(node)
          }}
          className="bookings-list-item__client-name"
          >
            <span
              {...(setIsSelectedByClient ? { onClick: (event) => handleOpenChat(event, callerId, setIsSelectedByClient) } : {})}
            >
              {item.caller.fn}
            </span>
        </p>

        {clientNameVisible && isClientNameOvercrowded &&
          <Portal>
            <div
              className="popup"
              ref={setClientNameTooltipRef}
              {...getClientNameTooltipProps()}
            >
              { item.caller.fn }
            </div>
          </Portal>
        }
      </div>
    </div>
  );
}

export default BookingListItem;
