import React, { useEffect, useState } from 'react'
import './ConflictingBookingsModal.scss'
import List from 'components/List/List';
import BookingListItem from 'components/BookingConfinedItem/BookingConfinedItem';
import API from 'api/api';
import Button from 'components/Button/Button';
import { MODAL_TYPES, closeModal } from 'redux/ducks/activeWindows';
import { useDispatch } from 'react-redux';

const ConflictingBookingsModal = ({ conflictBookings, values, submitForm }) => {
  const [bookingsState, setBookingsState] = useState([])
  const [isPending, setIsPending] = useState(false)

  const dispatch = useDispatch();

  useEffect(async () => {
    setIsPending(true)
    const { data: conflictBookingsData } = await API.getBookingsByIds(conflictBookings)
    setIsPending(false)
    return setBookingsState(conflictBookingsData);
  }, [])

  return (
    <div className="conflicting-bookings-modal" >
      <List
        classPrefix="bookings-list"
        list={bookingsState}
        listLoadPending={isPending}
      >
        <BookingListItem modificator="g-tab" preventEditModal />
      </List>
      <Button
        className="booking-form__btn booking-form__btn--submit"
        disabled={isPending}
        onClick={() => {
          dispatch(closeModal(MODAL_TYPES.conflictingBookingsModal))
          submitForm({ values, forceBooking: true })
        }}
      >
        Create booking anyway
      </Button>
    </div>
  );
}

export default ConflictingBookingsModal;