import React, { useState, Fragment, useRef, useEffect } from "react";

import formatTime from "utils/formatTime";
import { useToggle, usePrevious } from "hooks";
import { classModifier } from "utils";

import "./AudioPlayer.scss";
import Spinner from "../UI/Spinner/Spinner";
import Slider from "./Slider";
import ICONS from 'assets/icons';


const AudioPlayer = ({ src, blob, blobDuration, spinnerSize = 25, children, ...props }) => {
  const [pause, setPause] = useToggle(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(props.volume || 100);
  const [duration, setDuration] = useState(() => blobDuration ? Math.floor(blobDuration / 1000) : 0);

  const [thumbWasMoved, setThumWasbMoved] = useState(); // flag for render slider after change values
  const [volumeThumbWasMoved, setVolumeThumWasbMoved] = useState(); // flag for render slider after change values

  const audioRef = useRef({});

  const prevBlob = usePrevious(blob);

  const className = props.className || "player";

  useEffect(() => {
    (props.volume || props.volume === 0) && setVolume(props.volume);
    audioRef.current.volume = volume / 100;
  }, [volume, props.volume]);

  useEffect(() => {
    if (blob && blob !== prevBlob) {
      setPause(true);
    }
  }, [blob])

  const moveThumb = () => setThumWasbMoved(!thumbWasMoved);
  const moveVolumeThumb = () => setVolumeThumWasbMoved(!volumeThumbWasMoved);

  const onInitAudio = () => {
    if (audioRef.current.duration === Infinity && blobDuration) { // Chrome Bug
      return setDuration(Math.floor(blobDuration / 1000)); //        https://bugs.chromium.org/p/chromium/issues/detail?id=642012
    }

    if(audioRef.current.duration > 0 && audioRef.current.duration < 1) {
      return setDuration(1)
    }

    setDuration(Math.floor(audioRef.current.duration));
  };

  const onTimeChange = () => {
    const currentAudioTime = Math.floor(audioRef.current.currentTime);

    if (currentAudioTime !== currentTime) {
      setCurrentTime(currentAudioTime);
      moveThumb();
    }
  };

  const onTimeChangeManually = (newTime) => {
    const newTimeFloor = Math.floor(newTime);

    if (newTimeFloor !== currentTime) {
      audioRef.current.currentTime = newTimeFloor;
      setCurrentTime(newTimeFloor);
      moveThumb();
    }
  };

  const onPlay = (e) => {
    if (props.onPlay) {
      props.onPlay(e.target);
    }
  };

  const onEndAudio = () => {
    setCurrentTime(0);
    moveThumb();
  };

  const toggleMute = () => {
    setVolume(volume ? 0 : 100);
    moveVolumeThumb();
  };

  const togglePause = () => {
    audioRef.current[pause ? 'play' : 'pause']();

    setPause();
  };

  if (props.isMinimized) {
    return (
      <div className="voicemail">
        <div className="voicemail__box">
          <div className="voicemail__text">
            voicemail
            <div className="voicemail__time">
              ({formatTime(duration * 1000)})
            </div>
          </div>

          <ICONS.phoneVoicemail className='voicemail__icon' />
        </div>
      </div>
    )
  }

  const handleTogglePause = (event) => {
    event.stopPropagation();
    togglePause();
  }

  return (
    <>
      <div className={classModifier(className, [!pause ? 'active' : 'not-active'])}>
        {duration === 0 &&
          <div className={`${className}__spinner`}>
            <Spinner spinnerSize={spinnerSize}/>
          </div>
        }

        {duration > 0 &&
          <Fragment>
            <div
              className={classModifier(
                `${className}__control`,
                [props.isModernControl && 'modern']
              )}
              onClickCapture={handleTogglePause}
            >
              {pause ? (
                <ICONS.play className={classModifier(`${className}__control-icon`, 'play')} />
              ) : (
                <ICONS.pause className={classModifier(`${className}__control-icon`, 'pause')} />
              )}
            </div>
            
            {!props.hideTimePosition &&
              <div className={`${className}__time`}>
                {formatTime(currentTime * 1000)}
              </div>
            }

            <div className={`${className}__control-panel`}>
              <Slider
                className={className}
                onUpdate={onTimeChangeManually}
                countOfSteps={duration}
                currentStep={currentTime}
                thumbWasMoved={thumbWasMoved}
                path={children}
                isModernThumb={props.isModernControl}
              />

              <div className={`${className}__time`}>
                {formatTime(props.hideTimePosition && currentTime > 0 ? currentTime * 1000 : duration * 1000)}
              </div>
            </div>
            
            {!props.hideVolumeControl && (
              <div className={`${className}__control ${className}__control--volume`}>
                {volume > 0
                  ? <svg
                    height="65%"
                    fill="#808080"
                    viewBox="0 0 459 459"
                    onClick={toggleMute}
                  >
                    <path d="M0,153v153h102l127.5,127.5v-408L102,153H0z M344.25,229.5c0-45.9-25.5-84.15-63.75-102v204    C318.75,313.65,344.25,275.4,344.25,229.5z M280.5,5.1v53.55C354.45,81.6,408,147.899,408,229.5S354.45,377.4,280.5,400.35V453.9    C382.5,430.949,459,339.15,459,229.5C459,119.85,382.5,28.049,280.5,5.1z" />
                  </svg>
                  : <svg
                    height="65%"
                    fill="#808080"
                    viewBox="0 0 461.55 461.55"
                    onClick={toggleMute}
                  >
                    <path d="M345.525,229.5c0-45.9-25.5-84.15-63.75-102v56.1l63.75,63.75C345.525,239.7,345.525,234.6,345.525,229.5z M409.275,229.5    c0,22.95-5.1,45.9-12.75,66.3l38.25,38.25c17.85-30.6,25.5-68.85,25.5-107.1c0-109.65-76.5-201.45-178.5-224.4V56.1    C355.725,81.6,409.275,147.9,409.275,229.5z M34.425,0L1.275,33.15L121.125,153H1.275v153h102l127.5,127.5V262.65L340.425,372.3    c-17.851,12.75-35.7,22.95-58.65,30.601v53.55c35.7-7.65,66.3-22.95,94.35-45.9l51,51l33.15-33.149l-229.5-229.5L34.425,0z     M230.775,25.5l-53.55,53.55l53.55,53.55V25.5z" />
                  </svg>
                }
                <Slider
                  className={className}
                  onUpdate={setVolume}
                  countOfSteps={100}
                  currentStep={volume}
                  thumbWasMoved={volumeThumbWasMoved}
                />
              </div>
            )}
          </Fragment>
        }
      </div>

      <audio
        id={props.id}
        controls
        ref={audioRef}
        onLoadedMetadata={onInitAudio}
        onTimeUpdate={onTimeChange}
        onEnded={onEndAudio}
        className='player__audio-source'
        src={blob}
        onPlay={onPlay}
        onPause={() => {
          setPause(true);
          props.onPause && props.onPause();
        }}
      >
        {!blob && <source src={src} type={props.mimeType || 'audio/webm'} />}
      </audio>
    </>
  );
};

export default AudioPlayer;
