import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { useToggle } from "hooks";

import API from "api/api";
import Toggle from "components/Toggle/Toggle";
import Spinner from "components/UI/Spinner/Spinner";
import ICONS from "assets/icons";
import { classModifier } from "utils";
import Highlighter from 'react-highlight-words';


const ProfileReview = ({ review, highlightedText, ...props }) => {
  const [reviewText, setReviewText] = useState(review.text);
  const [serverError, setServerError] = useState('');

  const [isPublished, togglePublished] = useToggle(review.is_published);
  const [isEdit, toggleEdit] = useToggle(false);
  const [pending, togglePending] = useToggle(false);

  const isDidMount = useRef(true);
  const textareaRef = useRef('');

  useEffect(() => {
    setReviewText(review.text);
  }, [review.text]);

  useLayoutEffect(() => {
    if (isDidMount.current) {
      isDidMount.current = false;
    }

    textareaHeightAutosize();
  },[reviewText]);

  const textareaHeightAutosize = () => {
    textareaRef.current.style.height = '';
    textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
  };

  const ratingData = {
    rating: +review.rating,
    votes: false
  };

  const onEdit = (reviewId, text, isPublished) => {
    togglePending(true);

    API.editProfileReview(reviewId, text, isPublished)
      .then(() => {
        let updatedDivaReview = { ...review };

        if (text) updatedDivaReview.text = text;
        if (isPublished !== null) updatedDivaReview.is_published = isPublished;

        props.updateReview(updatedDivaReview);
      })
      .catch(() => {
        setServerError("Something went wrong, please reload the page and try again");

        if (text) setReviewText(review.text);
        if (isPublished !== null) togglePublished(!isPublished)
      })
      .finally(() => togglePending(false))
      
  };

  const formatDate = (date) => {
    const padTo2Digits = (num) => num.toString().padStart(2, '0');
    
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  return (
    <div className="profile-reviews__item">
      <div className="profile-reviews__title">
        <div className="profile-reviews__info-wrap">
          <div className="profile-reviews__name">{review.author}</div>
          <div className="profile-reviews__info">
            Left on {formatDate(new Date(review.created_at))}
          </div>

          <div className="profile-reviews__review-rating">
            <span className="profile-reviews__review-rating-value">
              {ratingData.rating}
            </span>
          </div>
        </div>

        <div className="profile-reviews__actions">
          <div className="profile-reviews__actions--show">
            <span className="profile-reviews__actions-status">
              {isPublished ? 'Visible' : 'Invisible'}
            </span>
            <Toggle
              isActive={isPublished}
              onToggle={() => {
                setServerError('');
                togglePublished(!isPublished);
                onEdit(review.id, null, +!isPublished);
              }}
            />
          </div>

          <div className="profile-reviews__actions--edit">
            {isEdit 
              ? <div>
                  <button
                    className={classModifier("profile-reviews__btn", "save")} 
                    title="Save"
                    onClick={() => {
                      toggleEdit(false);
                      if (reviewText !== review.text){
                        onEdit(review.id, reviewText, null);
                      }
                    }}
                  >
                    <ICONS.check />
                  </button>

                  <button
                    title="Cancel" 
                    className={classModifier("profile-reviews__btn", "cancel")} 
                    onClick={() => toggleEdit(false)}
                  >
                    <ICONS.close />
                  </button>
                </div>

              : <button
                  title="Edit"
                  className={classModifier("profile-reviews__btn", "edit")} 
                  onClick={() => {
                    setServerError('');
                    toggleEdit(true);
                  }}
                >
                  <ICONS.pencil />
                </button>
            }
          </div>

          <button 
            title="Delete" 
            className={classModifier("profile-reviews__btn", "delete")} 
            onClick={() => props.deleteReview(review.id)}
          >
            <ICONS.trash />
          </button>

        </div>
      </div>
      {
        !isEdit && highlightedText
          ? (
            <div
              className={classModifier('profile-reviews__text', ['value', 'disabled'])}
              ref={textareaRef}
            >
              <Highlighter
                highlightClassName="profile-reviews__highlight"
                searchWords={[highlightedText]}
                autoEscape={true}
                textToHighlight={reviewText}
              />
            </div>
          )
          : (
            <textarea
              className={classModifier('profile-reviews__text', [
                'value',
                isEdit ? 'active' : 'disabled'
              ])}
              value={reviewText}
              ref={textareaRef}
              disabled={!isEdit}
              onChange={e => setReviewText(e.target.value)}
            />
          )
      }

      {pending &&
        <div className="profile-reviews__pending profile-reviews__pending--item">
          <Spinner spinnerSize="30px"/>
        </div>
      }
      {serverError &&
        <div className="profile-reviews__error">
          {serverError}
        </div>
      }
    </div>
  );
};

export default ProfileReview;
