import classNames from 'classnames';

import { classModifier } from 'utils';
import getBookingStates from 'components/BookingItemNew/helpers/getBookingStates';

import "./BookingMarkers.scss";
import Tooltip from 'components/UI/Tooltip/Tooltip';

const BookingMarkers = ({
  className,
  booking,
  items = getBookingStates(booking)(),
}) => (
  <div className={classNames(className, 'booking-markers')}>
    {items.map((markerName, i) => (
      <Tooltip
        key={i}
        text={markerName}
      >
        <div className={classModifier("booking-markers__item", markerName)}></div>
      </Tooltip>
    ))}
  </div>
)

export default BookingMarkers;
