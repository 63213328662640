import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LS } from 'utils';

import "allotment/dist/style.css";

const SplitPanesSizeLS = {
  getAll(userId) {
    const sizes = LS.getItem('splitPanesSize', userId);

    return (sizes) || {};
  },
  getOne(paneId, userId) {
    const sizes = this.getAll(userId);

    return sizes[paneId] ? sizes[paneId] : null;
  },
  update(paneId, newValue, userId) {
    const sizes = this.getAll(userId);

    sizes[paneId] = newValue;

    LS.setItem('splitPanesSize', sizes, userId);
  },
}

const useSplitPaneSizes = (id, initialSizes) => {
  const userId = useSelector((state) => state.user.id);
  const defaultPanesSizes = SplitPanesSizeLS.getOne(id, userId) || initialSizes;

  const onDragFinished = useCallback((newSize) => {
    id && SplitPanesSizeLS.update(id, newSize, userId);
  }, []);

  return {
    defaultSizes: defaultPanesSizes,
    onDragEnd: onDragFinished,
  }
}

export default useSplitPaneSizes;
