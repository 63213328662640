import React, { useEffect } from 'react';
import { classModifier } from 'utils';
import components from 'containers/Dialogs/components';

import { MODAL_TYPES } from 'redux/ducks/activeWindows';

import './Modal.scss';


const modalTypesWithoutClosingOnOutsideClick = [
  MODAL_TYPES.bookingEditForm,
  MODAL_TYPES.bookingForm,
]


const Modal = (props) => {

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      document.removeEventListener("keydown", handleKeyDown, false);
    }
  }, [props.closeModal, props.isLastModal, props.notCloseOnEscape, props.children])

  const handleKeyDown = (e) => {
    if (!props.closeModal) return;
    if (props.notCloseOnEscape) return;

    if (e.code === "Escape" && props.isLastModal) {
      props.children.length > 1 ? props.closeChildModal() : props.closeModal();
    }
  }

  const handleMouseDown = (e) => {
    if (!props.closeModal) return;
    if (props.notCloseOutside) return;
    if (modalTypesWithoutClosingOnOutsideClick.includes(props.modalType)) return;

    if (e.target !== e.currentTarget) {
      return;
    }

    props.closeModal();
  }

  const activeClass = props.isOpen
    ? 'modal-container open'
    : 'modal-container open out';
    
  const renderComponent = (modal) => {
    if (!modal?.type) return <></>;

    const Component = components[modal.type];

    return (
      <Component
        key={modal.type}
        closeModal={props.closeModal}
        closeChildModal={props.closeChildModal}
        dispatchCloseEvent={props.dispatchCloseEvent}
        isModal
        {...props.commonProps}
        {...modal.props}
      />
    )
  }

  return (
    <div
      className={activeClass}
      style={props.zIndex ? { zIndex: props.zIndex } : null}
    >
      <div
        className={classModifier('modal-background', [
          !!props.backgroundClassModifier && props.backgroundClassModifier,
        ])}
        onMouseDown={handleMouseDown}
      >
        <article className={classModifier("modal-content", [
          !!props.backgroundClassModifier && props.backgroundClassModifier,
        ])}>
          {/* <Scrollbars > */}
            {props.children.map(renderComponent)}
          {/* </Scrollbars> */}
        </article>
      </div>
    </div>
  )
}

export default Modal;
