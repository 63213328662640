import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getContactsById } from '../../redux/ducks/contacts';
import { selectContactById } from '../../redux/selectors/selectors';

import AdrBookContactForm from './components/AdrBookContactForm/AdrBookContactForm';


const AdrBookContactFormWrap = (props) => {
  const {
    active,
    activeContact,
    isNewContactCreation,
    getContactsById,
    callbacksRef,
    setUndoData,
    setEdited,
  } = props;

  useEffect(() => {
    if (!activeContact?.date_created) {
      getContactsById(active);
    }
  }, [active, activeContact]);

  return (
    <div className="adr-book-contact-form-wrap">
      {((active && !!activeContact?.date_created) || isNewContactCreation) &&
        <AdrBookContactForm
          active={active}
          setUndoData={setUndoData}
          callbacksRef={callbacksRef}
          isNewContactCreation={isNewContactCreation}
          setEdited={setEdited}
        />
      }
    </div>
  )
};

const mapStateToProps = (state) => ({
  active: state.addressBook.active,
  activeContact: selectContactById(state, state.addressBook.active),
  isNewContactCreation: state.addressBook.isNewContactCreation,
});

const mapDispatchToProps = {
  getContactsById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookContactFormWrap);
