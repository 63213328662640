import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { classModifier, isEmptyObj } from 'utils';
import { updateTagsFilter } from 'redux/ducks/addressBook';

import './AdrBookTags.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import AdrBookTag from 'containers/AdrBookTags/AdrBookTag';

const AdrBookTags = (props) => {
  const {
    tags: { ids, entities, pending, active, allContactsCount },
    contactType,
    tagsScrollIsBottom, 
    setTagsScrollIsBottom,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const isActiveTagsFilter = !isEmptyObj(active);
  const tagsListRef = useRef(null);

  useEffect(() => {
    if (isLoading && !pending) {
      setIsLoading(false);
    }
  }, [isLoading, pending]);

  const clearTagsFilter = () => {
    if (isActiveTagsFilter) {
      props.updateTagsFilter();
    }
  }

  const renderSpinner = () => (
    <div className="adr-book-tags__spinner-wrap">
      <Spinner spinnerSize='30px' />
    </div>
  );

  const handleScroll = () => {
    const list = tagsListRef.current;
    if (list.scrollTop && list.scrollTop + list.clientHeight >= (list.scrollHeight - 20)) {
      setTagsScrollIsBottom(true);
    } else {
      tagsScrollIsBottom && setTagsScrollIsBottom(false);
    }
  }

  return (
    <div className="adr-book-tags">
      <header className="adr-book-tags__title">
        Filter by tag
      </header>

      {isLoading
        ? renderSpinner()
        : (
          <ul 
            className="adr-book-tags__list"
            ref={tagsListRef}
            onScroll={handleScroll}
          >
            <li className="adr-book-tags__list-item">
              <button
                className={classModifier("adr-book-tags__tag", [
                  !isActiveTagsFilter && "active"
                ])}
                onClick={clearTagsFilter}
                style={{ ...(pending ? { cursor: 'wait' } : {}) }}
              >
                All Contacts 
                <span className="adr-book-tags__tag-count">
                  &nbsp;({allContactsCount || 0})
                </span>
              </button>
            </li>

            {!!ids.length && ids.map(id => (
              <AdrBookTag
                key={id}
                tag={entities[id]}
                updateTagsFilter={props.updateTagsFilter}
                contactType={contactType}
              />
            ))}
          </ul>
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({
  tags: state.addressBook.tags,
  contactsPending: state.addressBook.contactsPending,
  includeUnsaved: state.addressBook.includeUnsaved,
  contactType: state.addressBook.contactType,
  totalClientsCount: state.contacts.clients.totalCount,
  totalGirlsCount: state.contacts.girls.totalCount,
});

const mapDispatchToProps = {
  updateTagsFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookTags);
