import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { INTERACTION_TYPES } from 'config/constants';
import { removeBufferedId } from 'redux/ducks/sessions';
import { CONTACT_ITEM_CONFIG } from 'config/dates-сonfig';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { deleteGirlChatTabInBuffer } from 'redux/ducks/girlChats';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { selectCanIEditSession, selectRelatedOperator } from 'redux/selectors/selectors';
import { classModifier, getContactAvatar, parseMsgText, showContactName } from 'utils';

import DateTime from 'components/DateTime';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import SystemMsg from 'containers/Chat/components/ChatTimeline/interactions/SystemMsg/SystemMsg';


const BufferContactItem = (props) => {
  const {
    idx,
    contact,
    isActive,
    bufferTabContacts
  } = props;

  const userId = useSelector(state => state.user.id);
  const userHour12 = useSelector(state => state.user.hour12);
  const activeSession = useSelector(state => state.sessions.activeSession);
  const canIEditSession = useSelector(state => selectCanIEditSession(state));
  const relatedOperator = useSelector(state => selectRelatedOperator(state, props.id));

  const dispatch = useDispatch();

  const { INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_EMAIL } = INTERACTION_TYPES;

  const isLastMsgFormTelegram = !!contact.interactionObject && (
    contact.interactionObject.type === 9 || contact.interactionObject.type === 10
  );

  const renderLastInteraction = () => {
    let lastInteraction = '';

    const interactionObject = props.searchSource === 'msgs' && !!contact.interactionObject_searched && !!props.search
      ? contact.interactionObject_searched
      : contact.interactionObject;

    switch (interactionObject.type) {
      case 1: {
        lastInteraction = 'Incoming call';
        break;
      }
      case 2: {
        lastInteraction = 'Outgoing call';
        break;
      }
      case 3: {
        lastInteraction = interactionObject.message;
      }
      case 4: {
        if (interactionObject.user_id === userId) {
          lastInteraction = 'You: ' + interactionObject.message;
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 8: {
        // // we don't need parse msg on attachment if it's system msg
        return <SystemMsg
          interaction={interactionObject}
          returnOnlyMsgString={true}
          userHour12={userHour12}
        />
      }
      case 9: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Incoming voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Incoming media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Incoming video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Incoming image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 10: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Outgoing voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Outgoing media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Outgoing video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Outgoing image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 12: {
        lastInteraction = 'Incoming email'
        break;
      }
      default:
        lastInteraction = interactionObject.message;
    }

    return parseMsgText(lastInteraction, true);
  };

  const renderRelatedOperator = () => {
    if (!contact.relatedUserId && !contact.lastOperator) {
      return null;
    }

    let name;

    if (contact.relatedUserId === userId) {
      name = 'You'
    } else if (!contact.relatedUserId && contact.lastOperator?.id === userId) {
      name = 'released by you';
    } else if (relatedOperator) {
      name = relatedOperator.username;
    } else {
      name = `released by ${contact.lastOperator?.username}`;
    }

    return <span className="contact-item__related">{name}</span>;
  };

  const showUnreadCount = () => {
    const isRelatedUser = !!contact.relatedUserId && contact.relatedUserId !== props.userId;

    if (contact.unreadCount > 0) {
      return (
        <span className={
          contact.withImportantUnread
            ? 'contact-item__count contact-item__count--important'
            : classModifier('contact-item__count', isRelatedUser && 'related')
        }>
          {contact.unreadCount >= 100 ? '99+' : contact.unreadCount}
        </span>
      )
    }
  };

  const handleContactClick = () => dispatch(updateActiveContact(contact));
  
  const handleDelete = (e) => {
    e.stopPropagation();

    dispatch(removeBufferedId(contact.diva_default_id || contact.diva_id, activeSession, canIEditSession));

    dispatch(deleteGirlChatTabInBuffer(contact.id));

    if(isActive) {
      const activeIndex = !!idx ? 0 : 1;
      dispatch(updateActiveContact(bufferTabContacts[activeIndex]));
    }
  };

  const handleOpenContactEditorModal = (e) => {
    e.stopPropagation();

    dispatch(openModal(MODAL_TYPES.contactCard, { contact: contact.id }));
  };

  return (
    <div
      id={props.id}
      className={classModifier('contact-item', [
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        isActive && 'active-in-buffer',
        !!contact.unreadCount && 'unread-msgs',
        'girl'
      ])}
      onClick={handleContactClick}
    >
      <div
        className={`contact-item__img-wrap ${contact.blocked ? "blocked" : ""} ${contact.is_active_chat ? "contact-item__img-wrap--active" : ""
          }`}
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="contact-item__img" />

        {isLastMsgFormTelegram &&
          <ICONS.telegram className="contact-item__telegram-icon" />
        }
      </div>

      <div className="contact-item__content-wrap">
        <div className="contact-item__info">
          <h4 className="contact-item__name" onClick={handleOpenContactEditorModal}>

            {showContactName(contact.fn, 'contact-item__ex')}

            {contact.type === 2 &&
              <>
                {!!contact.rates &&
                  <span className="contact-item__rates">
                    {contact.rates}
                  </span>
                }

                {!!contact.rating &&
                  <span className="chat-header__rating">
                    ({contact.rating})
                  </span>
                }
              </>
            }
          </h4>

          <DateTime
            className="contact-item__time"
            date={contact.last_interaction}
            config={CONTACT_ITEM_CONFIG}
            relative={!!contact.statusUpdateDate}
          />
        </div>

        <div className="contact-item__bottom">
          {contact.draft_message &&
            <ICONS.pencil className="contact-item__draft-icon" title="draft" />
          }

          <div
            className={`contact-item__last-msg ${contact.interactionObject
              ? ([INCOMING_MSG, INCOMING_CALL, INCOMING_MSG_TELEGRAM, INCOMING_EMAIL].includes(contact.interactionObject.type)
                && 'contact-item__last-msg--operator')
              : ''
              }`
            }
          >
            {(contact.interactionObject || contact.interactionObject_searched) &&
              renderLastInteraction()
            }
          </div>

          {renderRelatedOperator()}

          {contact.isScheduledMsg &&
            <ICONS.checkbox className="contact-item__schedule-msg-icon" />
          }
        </div>

        {showUnreadCount()}

      </div>

      <button onClick={handleDelete} className='contact-item__delete-buffer-btn'>
        <ICONS.plusCircle className='contact-item__delete-buffer-icon' />
      </button>
    </div >
  );
};

export default BufferContactItem;