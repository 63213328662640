import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier, showContactName } from 'utils';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';

import './QuickBookingGirlsListItem.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import { selectActiveGirlByDiva, selectActiveSessionByContactId, selectAgentContactById, selectDivaActiveGirlsFromChats } from 'redux/selectors/selectors';
import { DIVA_GIRLS_IMG_HOST_PREVIEW } from 'redux/ducks/divaGirls';
import girlImg from 'assets/images/avatars/girl.png';


const QuickBookingGirlsListItemByDiva = ({
  item,
  query,
  onClick,
}) => {
  const divaId = item.id || item.id_new;

  const agentName = useSelector(state => selectAgentContactById(state, item.id))?.fn
  const activeGirl = useSelector(selectActiveGirlByDiva);
  const activeGirlsFromChats = useSelector(selectDivaActiveGirlsFromChats);
  const activeRecipient = useSelector(state => state.contacts.entities[state.clientChats.active])
  const contactSessionId = useSelector((state) => selectActiveSessionByContactId(state, activeRecipient?.id))

  const activeGirlId = activeGirl?.id;
  const lastGirlId = activeGirlsFromChats[activeGirlsFromChats.length - 1]?.id;
  const penultimateGirlId = activeGirlsFromChats[activeGirlsFromChats.length - 2]?.id;

  const [isPending, setIsPending] = useState(false);
  const dispatch = useDispatch();
  const isNotForGirl = useMemo(() => !!divaId && activeRecipient?.not_for_clients
    ?.map(i => i.diva_default_id || null)
    .includes(divaId), [activeRecipient]);

  const defaultOnClick = (selectedContact, activeRecipient, contactSessionId) => {
    if (onClick) {
      return onClick(selectedContact);
    }

    if (selectedContact.id) {
      dispatch(openModal(MODAL_TYPES.bookingForm, {
        initialProfile: selectedContact,
        initialActiveSessionContact: activeRecipient,
        activeSessionId: contactSessionId,
      }));
      dispatch(closeModal(MODAL_TYPES.searchList));
    }
  }

  const handleBookingFormOpen = () => {
    setIsPending(true);
    defaultOnClick(item, activeRecipient, contactSessionId);
  }

  const getItemStatusColor = () => {
    let styleValue = '';

    if (item.is_on_holiday) {
      styleValue = '#000000';
    }

    if (item.is_available_today) {
      styleValue = '#29F18E';
    }

    if (!item.is_available_today) {
      styleValue = '#f8737f';
    }

    return styleValue;
  }

  const isNeedHorizontalLine =
    !query &&
    (activeGirlId === item.id ||
      lastGirlId === item.id ||
      (activeGirlId === lastGirlId && penultimateGirlId === item.id));

  const getContactDetails = () => {
    if (!item.prices?.[0]) {
      return "";
    }

    const { prices, age, height, dress, breast_size, nationality } = item;

    const pricesStr = `￡ ${prices[0].incall || "-"} / ${
      prices[2].incall || "-"
    } / ${prices[2].outcall || "-"}`;

    return `${pricesStr} ${" / " + (age || "-")} ${" / " + (height || "")} ${
      " / " + (dress || "")
    } ${" / " + (breast_size || "")}${" / " + (nationality || "")}${
      " / " + (agentName || "")
    }`;
  };

  const imgPath = item?.avatar ? DIVA_GIRLS_IMG_HOST_PREVIEW + item.avatar : girlImg;

  return (
    <li
      className={classModifier('quick-booking-item', [
        !divaId && 'no-diva-default-id',
        isNeedHorizontalLine && 'horizontal-line',
        isPending && 'is-pending',
      ])}
      onClick={divaId && handleBookingFormOpen}
    >
      {isPending && <Spinner className="quick-booking-item__spinner"/>}
      <div
        className={classModifier('quick-booking-item__content', [
          !query && activeGirlId === item.id && !onClick && 'active-contact',
          !query && item.agent_id && !onClick && 'agent',
        ])}
      >
        <div className="quick-booking-item__img-wrap">
          <LazyLoadImage src={imgPath} />
        </div>

        <div className="quick-booking-item__info">
          <div className="quick-booking-item__info-top">
            {isNotForGirl 
              ? <div className='quick-booking-item__icon-wrapper'><ICONS.stop /></div>
              : <div className="quick-booking-item__status-wrapper">
                  <span
                    className="quick-booking-item__status-point"
                    style={{ backgroundColor: getItemStatusColor() }}
                  />
                </div>
            }
            <span className="quick-booking-item__name">
              {showContactName(item.name, "chat-header__ex")}
            </span>
          </div>
          <div className="quick-booking-item__girl-info">
            <span>
              {getContactDetails()}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default QuickBookingGirlsListItemByDiva;
