import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import { usePrevious, useToggle } from 'hooks';
import { selectRecentTab } from 'redux/selectors/selectors';

import ChatTab from './ChatTab';
// import DropWrapper from "../../../../components/DropWrapper/DropWrapper";
import SvgIcon from 'components/SvgIcon/SvgIcon';
import DropWrapper from 'components/DropWrapper';
import Overflow from 'rc-overflow';

const ChatTabs = (props) => {
  const {
    tabs,
    primaryTabs,
    moreTabs,
    recentTabs,
  } = props;

  const [isOpenMoreTabs, toggleOpenMoreTabs] = useToggle(false);
  const [visibleTabsLength, setVisibleTabsLength] = useState(0);
  const [isDragging, toggleDragging] = useToggle(false);

  const [animationClass, setAnimationClass] = useState('');

  const [referenceElement, setReferenceElement] = useState(null);

  const prevIsUnreadInMore = usePrevious(props.isUnreadInMore);

  useEffect(() => {
    if (prevIsUnreadInMore !== props.isUnreadInMore && props.isUnreadInMore) {
      setAnimationClass('shake-me');
    }

    if (prevIsUnreadInMore !== props.isUnreadInMore && !props.isUnreadInMore) {
      setAnimationClass('');
    }
  }, [props.isUnreadInMore]);

  useEffect(() => {
    if (isOpenMoreTabs) {
      toggleOpenMoreTabs(false);
    }
  }, [props.activeRecipient]);

  const onOpenRecentTab = () => {
    if (recentTabs && !recentTabs.length) return;

    props.onOpenRecentTab();
    props.updateActiveContact(props.recentTab)
  }

  const onDragEnd = (result) => {
    toggleDragging();

    if (result.destination === null) return;

    const source = result.source.index;
    const destination = result.destination.index > visibleTabsLength ? visibleTabsLength : result.destination.index;

    if (destination === source) return;

    const newList = [...tabs];

    if (destination < source) { // Is Backward Movement
      newList.splice(destination, 0, tabs[source]);
      newList.splice(source + 1, 1);
    }
    else { // Is Forward Movement
      newList.splice(destination + 1, 0, tabs[source]);
      newList.splice(source, 1);
    }

    props.setTabs(newList);
  };

  const getShowMoreBtnClassName = () => {
    let className = 'chat__show-more-contacts';

    if (props.isUnreadInMore) {
      className += ' chat__show-more-contacts--have-notread';
    }

    return className;
  };

  const TabRenderer = (tab) => (
    <ChatTab
      key={tab}
      tab={tab}
      index={tabs.indexOf(tab)}
      type={props.type}
      activeRecipient={props.activeRecipient}
      updateActiveContact={(chatId) => props.updateActiveContact(chatId)}
      removeContactTab={props.removeContactTab}
      isTabsDraggable={props.isTabsDraggable}
      withUnfixedTab={props.withUnfixedTab}
      unfixedTab={props.unfixedTab}
      fixTab={props.fixTab}
    />
  )

  const MoreTabRenderer = (tabs) => {
    const { top, left } = referenceElement?.getBoundingClientRect() || {};

    return (
      <>
        <button
          ref={setReferenceElement}
          data-animationclass={animationClass}
          className={getShowMoreBtnClassName()}
          onClick={isOpenMoreTabs ? null : toggleOpenMoreTabs}
          style={isDragging ? { position: 'fixed', top, left } : {}}
        >
          <span>
            {tabs.length}
          </span>

          <svg width='8' height='8' fill='#0092F2'
            className='chat__show-more-contacts-icon'
            viewBox="0 0 371 371"
          >
            <path d="M293 25L269 0 78 185l191 186 24-25-165-161z" />
          </svg>
        </button>

        <DropWrapper
          isActive={isOpenMoreTabs}
          setIsActive={toggleOpenMoreTabs}
          referenceElement={referenceElement}
          placement="bottom-end"
          offsetY={0}
        >
          <ul className="chat__contacts-list--more">
            {tabs.map(tab => (
              <ChatTab
                tab={tab}
                key={tab}
                type={props.type}
                activeRecipient={props.activeRecipient}
                updateActiveContact={(chatId) => props.updateActiveContact(chatId, true)}
                removeContactTab={props.removeContactTab}
                isMoreTab
                withUnfixedTab={props.withUnfixedTab}
                unfixedTab={props.unfixedTab}
                fixTab={props.fixTab}
              />
            ))}
          </ul>
        </DropWrapper>
      </>
    )
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={toggleDragging}>
        <Droppable
          droppableId="1"
          direction='horizontal'
        >
          {(provided) => (
            <div
              className="chat__contacts-list"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <ul
                id="tabs-list"
                className="chat__contacts-list"
                ref={props.innerRef}
              >
                <Overflow
                  className="chat__tabs-container"
                  data={tabs}
                  renderItem={TabRenderer}
                  renderRest={MoreTabRenderer}
                  maxCount="responsive"
                  onVisibleChange={setVisibleTabsLength}
                />

                {provided.placeholder}
              </ul>
              
              {props.isShowUndoTabs && !!recentTabs.length &&
                <button
                  className='chat-restore chat__restore'
                  onClick={onOpenRecentTab}
                >
                  <SvgIcon //TODO v2 undo
                    icon={faUndo}
                    type="fa"
                    width="16"
                    height="16"
                  />
                </button>
              }
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  recentTab: selectRecentTab(state, ownProps.type, ownProps.recentTabs[ownProps.recentTabs.length - 1]),
});

export default connect(mapStateToProps)(ChatTabs);
