import { useDispatch, useSelector } from 'react-redux';

import { MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, updatePrimaryMode, updateSecondaryMode } from 'redux/ducks/map';

import ICONS from 'assets/icons';


const BackToFullMapButton = () => {
  const { prevPrimaryMode } = useSelector(state => state.map);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updatePrimaryMode(MAP_PRIMARY_MODES.ALL_ESCORTS));
    dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.TARGET_ESCORT, false))
  }

  if (!prevPrimaryMode) return null;

  return (
    <button
      className='girls-map__back-btn'
      onClick={handleClick}
    >
      <ICONS.arrow />
      Back to full map
    </button>
  )
}

export default BackToFullMapButton;
