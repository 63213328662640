import React from 'react'
import { useDispatch } from 'react-redux';

import { changeActivePinnedMessage } from 'redux/ducks/clientChats';
import { getContactAvatar } from 'utils';
import { CHAT_TYPES } from 'config/constants';

import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import PinnedMsgsAttachments from './PinnedMsgsAttachments';
import PinnedMsgsText from './PinnedMsgsText';

const PinnedMsgItem = (props) => {
  const {
    item,
    index,
    contact,
    unpinMsg,
    setMenuOpen,
    activeRecipientType,
  } = props;

  const dispatch = useDispatch();

  const getContext = (item) => {
    props.type === CHAT_TYPES.ROOM
      ? props.getMessageContext(item.id, props.chatId)
      : props.getMessageContext(item.id, null)
  };

  return (
    <li
      key={item.id} 
      className="pinned-msgs__item"
      onClickCapture={(event) => {
        event.stopPropagation();
        getContext(item);
        setMenuOpen(false);
        dispatch(changeActivePinnedMessage(item, activeRecipientType));
      }}
    >
      <div className="pinned-msgs__avatar-wrapper">
        <LazyLoadImage
          src={getContactAvatar(contact)}
          className="pinned-msgs__avatar"
        />
      </div>

      <div className="pinned-msgs__content">
        <div
          className="pinned-msgs__text" 
          onClick={(e) => 
            e.preventDefault()
          }
        > 
          #{index + 1}&nbsp;{<PinnedMsgsText interaction={item} />}
        </div>
        
        {!!item.attachments &&
          <PinnedMsgsAttachments 
            attachments={item.attachments} 
            isMenuOpen={props.isMenuOpen}
          />
        }
      </div>

      <button
        className="pinned-msgs__btn-delete"
        onClick={(e) => {
          e.stopPropagation()
          unpinMsg(item.id, activeRecipientType)
        }}
      >
        <ICONS.plusCircle className="pinned-msgs__icon-unpin" />
      </button>
    </li>
  );
}

export default PinnedMsgItem;
