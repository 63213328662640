import React from 'react';

import './CustomScrollbarThumb.scss';

const CustomScrollbarThumb = props =>
  <div
    {...props}
    className="scrollbars-thumb"
    style={props.style} />;

export default CustomScrollbarThumb;
