import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MAP_SECONDARY_MODES, updateSecondaryMode, updateSecondaryModeIds } from 'redux/ducks/map';

import ICONS from 'assets/icons';


const SelectEscortsButton = () => {
  const selectMode = useSelector(state => state.map.secondaryMode[MAP_SECONDARY_MODES.SELECT_ESCORTS]);
  
  const dispatch = useDispatch();

  const handleClick = () => dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.SELECT_ESCORTS));

  useEffect(() => {
    if (selectMode) {
      dispatch(updateSecondaryModeIds(MAP_SECONDARY_MODES.SELECT_ESCORTS, []));
    }
  }, [selectMode]);

  return (
    <>
      {selectMode && "Click markers to select/deselect"}

      <button
        className="girls-map__toggle-btn"
        onClick={handleClick}
      >
        {selectMode ? 'Exit Selection' : 'Select Escorts'}
        <ICONS.check className="girls-map__toggle-btn-icon" />
      </button>
    </>
  )
}

export default SelectEscortsButton;
