import { getDateByTimezoneOffset } from 'utils';

const bookingColors = {
  pink: '#f0a8d2',
  purple: '#b380ff',
  green: '#32bf45',
  yellowGreen: '#99c23a',
  orange: '#f5aa42',
  red: '#f54242',
  white: '#fff',
}

const bookingDurations = {
  '30 minutes': 30 * 60 * 1000,
  '45 minutes': 45 * 60 * 1000,
  '1 hour': 60 * 60 * 1000,
  '90 minutes': 90 * 60 * 1000,
  '2 hours': 2 * 60 * 60 * 1000,
  '3 hours': 3 * 60 * 60 * 1000,
  'Overnight': 12 * 60 * 60 * 1000,
};

const bookingStages = {
  sixHours: 6 * 60 * 60 * 1000,
  twoHours: 2 * 60 * 60 * 1000,
  oneHour: 60 * 60 * 1000,
}

export default (booking, userTimezone) => {
  if (!booking) return bookingColors.white;

  const dateByTimezone = getDateByTimezoneOffset(userTimezone);
  const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
  const dateDiff = bookingDateByTimezone - dateByTimezone;

  // if unknown or empty duration
  if (!bookingDurations[booking.duration]) return bookingColors.white;
  // if booking finished
  if (dateDiff + bookingDurations[booking.duration] < 0) return bookingColors.white;

  let bookingColor = bookingColors.pink;

  if (dateDiff >= bookingStages.sixHours && booking.is_prebooking) {
    bookingColor = bookingColors.purple;
  }
  else if (dateDiff >= bookingStages.sixHours) {
    bookingColor = bookingColors.green;
  }
  else if (dateDiff <= bookingStages.sixHours && dateDiff >= bookingStages.twoHours) {
    bookingColor = bookingColors.yellowGreen;
  }
  else if (dateDiff <= bookingStages.twoHours && dateDiff >= bookingStages.oneHour) {
    bookingColor = bookingColors.orange;
  }
  else if (dateDiff <= bookingStages.oneHour && dateDiff >= 0) {
    bookingColor = bookingColors.red;
  }

  return bookingColor;
}
