import LS from './localStorageAPI';

export default {
  getAll(userId) {
    const drafts = LS.getItem('drafts', userId);

    return (drafts) || {};
  },
  getOne(contact, userId) {
    const id = contact.type
      ? [contact.id] + '_' + [contact.type]
      : [contact.id] + '_' + [contact.chatType];
    const drafts = this.getAll(userId);

    return drafts[id] ? drafts[id] : '';
  },
  update(newDrafts, userId) {
    LS.setItem('drafts', newDrafts, userId);
  },
}