import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { WebmasterNotification } from '../components/WebmasterNotification';
import { SessionRequestNotification } from '../components/SessionRequestNotification';
import { SessionNotification } from '../components/SessionNotification';
import { MailNotification } from '../components/MailNotification';


export const notificationProps = {
  [NOTIFICATION_TYPES.sessionUsingReminder]: {
    getText: () => "Don't forget to use sessions",
    iconType: "puzzle",
  },

  [NOTIFICATION_TYPES.webmaster]: {
    getText: () => "You have a pending webmaster task",
    iconType: "checkbox",
    CustomNotification: WebmasterNotification,
  },

  [NOTIFICATION_TYPES.task]: {
    getText: () => "You have a pending task",
    iconType: "checkbox",
  },

  [NOTIFICATION_TYPES.messageReminder]: {
    getText: ({ message }) => <>You have reminder regarding this {message}</>,
    iconType: "comments",
  },

  [NOTIFICATION_TYPES.addTelegramChannel]: {
    getText: ({ caller }) => <>Added Telegram channel to {caller.name}</>,
    iconType: "telegram",
  },
  
  [NOTIFICATION_TYPES.changeContactNumber]: {
    getText: ({ caller }) => <>Way of comunication with {caller.name} has changed</>,
    iconType: "adrBookInvert",
  },

  [NOTIFICATION_TYPES.missedClientCall]: {
    getText: ({ caller: { client } }) => <>Everybody missed call from {client.name}</>,
    iconType: "incCall",
  },

  [NOTIFICATION_TYPES.missedSessionClientCall]: {
    getText: ({ caller: { client } }) => <>You missed session call with {client.name}</>,
    iconType: "incCall",
  },

  [NOTIFICATION_TYPES.nightBotReject]: {
    getText: ({ caller: { client } }) => <>Client {client.name} is waiting for your response</>,
    iconType: "robot",
  },

  [NOTIFICATION_TYPES.longSession]: {
    getText: ({ caller: { client } }) => <>Your session with {client.name} is longer than 2 hours</>,
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.undeliveredMessage]: {
    getText: ({ message }) => <>This message not delivered: {message}</>,
    iconType: "comments",
  },

  [NOTIFICATION_TYPES.newRelatedMessage]: {
    getText: ({ caller, message }) => <>New message: {message} from {caller.name}</>,
    iconType: "comments",
  },

  [NOTIFICATION_TYPES.partnerHasntReactTo]: {
    getText: ({ caller, message }) => <>Your partners hasn't reacted to {message} from {caller.name}</>,
    iconType: "comments",
  },

  [NOTIFICATION_TYPES.changeOperatorStatus]: {
    getText: ({ operator, notification }) => (
      <>Status of the operator {operator} has changed to {notification.status}</>
    ),
    iconType: "users2",
  },

  [NOTIFICATION_TYPES.sessionView]: {
    getText: ({ operator, caller: { client } }) => (
      <>Your partner {operator} is viewing your session with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.sessionChanged]: {
    getText: ({ operator, caller: { client } }) => (
      <>Operator {operator} is making changes to your session with the {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.sessionProposed]: {
    getText: ({ operator, caller: { client } }) => (
      <>Your partner {operator} made propose in your session with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.sessionAssistanceDelete]: {
    getText: ({ operator, caller: { client } }) => (
      <>Your partner {operator} removed you from session with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.sessionAssistanceResponse]: {
    getText: ({ operator, caller: { client }, notification }) => (
      notification.data.isAccept
        ? <>Your partner {operator} accepted assistance request with {client.name}</>
        : <>Your partner {operator} rejected assistance request with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.sessionTransferResponse]: {
    getText: ({ operator, caller: { client }, notification }) => (
      notification.data.isAccept
        ? <>Your partner {operator} accepted transfer request with {client.name}</>
        : <>Your partner {operator} rejected transfer request with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.sessionAssistanceRequest]: {
    getText: ({ operator, caller: { client } }) => (
      <>Your partner {operator} is asking for assistant in session with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionRequestNotification,
  },

  [NOTIFICATION_TYPES.sessionTransferRequest]: {
    getText: ({ operator, caller: { client } }) => (
      <>Your partner {operator} wants transfer to you a session with {client.name}</>
    ),
    iconType: "puzzle",
    CustomNotification: SessionRequestNotification,
  },

  [NOTIFICATION_TYPES.clientTextedInSession]: {
    getText: ({ caller: { client }, message }) => <>{client.name} in session texted: {message}</>,
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.profileTextedInSession]: {
    getText: ({ caller: { girl }, message }) => <>{girl.name} in session texted: {message}</>,
    iconType: "puzzle",
    CustomNotification: SessionNotification,
  },

  [NOTIFICATION_TYPES.newMail]: {
    getText: ({ email, caller }) => <>New {email} from {caller.name}</>,
    iconType: "mail",
    CustomNotification: MailNotification,
  },

  [NOTIFICATION_TYPES.profilesBuffer]: {
    getText: ({ caller: { girl, client } }) => (
      <>{girl.name} in buffer has become off today, see this {client.name}</>
    ),
    iconType: "buffer",
    getRenderedAvatar: (callers) => callers.girl.avatar
  },

  [NOTIFICATION_TYPES.mention]: {
    getText: ({ chat }) => {
      if (chat?.props?.notification?.data?.attachments?.chatUpdate?.edit?.title) {
        const contactName = chat?.props?.notification?.data?.fn;
        return <>Chat with contact {contactName}: {chat?.props?.notification?.data?.attachments?.chatUpdate?.edit?.title} </>
      };
      return <>You have a mention in {chat} chat</>
    },
    iconType: "peopleConference",
  },

  [NOTIFICATION_TYPES.newMessageReminder]: {
    getText: () => 'New message reminder was set',
    iconType: "comments",
  }
}
