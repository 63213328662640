import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ICONS from 'assets/icons';
import API from 'api/api';
import { getContactsById } from 'redux/ducks/contacts';
import { deleteWidgetReminder } from 'redux/ducks/notifications';

import { Notification } from 'components/Notification';
import List from 'components/List/List';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';


const NotificationsWidget = (props) => {
  const {
    onClose,
    list,
    handleGetContactReminders,
    callerIdsOfReminders
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactsById(callerIdsOfReminders))
  }, [])

  const NotificationItem = useCallback((props) => {
    const readAction = () => {
      return API.deleteReminderFromWidget(props.item.id)
        .then(() => {
          dispatch(deleteWidgetReminder(props.item));
        })
    }

    return (
      <Notification
        // className='notifications-widget__item'
        readAction={readAction}
        {...props}
      />
    )
  }, []);

  return (
    <div className='notifications-widget'>
      <div className='notifications-widget__header'>
        <ICONS.bellFill className='notifications-widget__icon' />
        <span className='notifications-widget__title'>
          Important Notifications
        </span>

        <button type='button' onClick={onClose} className='notifications-widget__btn'>
          <ICONS.closeWindow className='notifications-widget__icon' />
        </button>
      </div>

      <List
        classPrefix='notifications-widget'
        scrollInitialPosition='top'
        limit={20}
        list={list}
        loadMore={handleGetContactReminders}
        scrollbarProps={{
          renderTrackVertical: CustomScrollbarTrack,
        }}
      >
        <NotificationItem />
      </List>
    </div>
  )
}

export default NotificationsWidget;
