import React from 'react';
import { connect } from 'react-redux';

import { playMedia } from 'redux/ducks/activeWindows';

import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import MediaWaves from '../ChatMessageInput/components/MediaWaves/MediaWaves';


const AttachmentVoiceMessage = ({ voiceMsg, className = '', ...props }) => {
  return (
    <div className={`${className} ${!props.showTitle ? "attachment-voice-msg" : ""}`}>
      <AudioPlayer
        src={voiceMsg.url}
        blobDuration={voiceMsg.duration}
        mimeType={voiceMsg.blob ? voiceMsg.blob.type : null}
        onPlay={props.playMedia}
        hideTimePosition
      >
        <MediaWaves stopped />
      </AudioPlayer>
    </div>
  );
}

const mapDispatchToProps = {
  playMedia
};

export default connect(null, mapDispatchToProps)(AttachmentVoiceMessage);
