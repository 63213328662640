import React from 'react'

import { classModifier, getDefaultField } from 'utils';

const DialpadActiveChatsItem = ({ setQuery, contactTypes, item, setContact }) => {
  if (!item.tels?.length || !item.fn) {
    return null
  }

  return (
    <li
      onClick={() => {
        setContact(item);
        setQuery(getDefaultField(item.tels, 'tel'));
      }}
      className="dialpad-list__item"
    >
      <p
        className={classModifier('dialpad-list__contact-name',[contactTypes[item.type]])}
      >
        {item.fn}
      </p>
    </li>
  );
}

export default DialpadActiveChatsItem;