import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import ICONS from 'assets/icons';
import { GIRLS_LIMIT } from 'redux/ducks/contacts';
import { classModifier, getContactAvatar } from 'utils';
import { useDidUpdate } from 'hooks';
import { CONTACT_TYPES } from 'config/constants';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';

import './FeedbackContactField.scss';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import SearchListEscortItem from 'components/SearchList/components/SearchListEscortItem/SearchListEscortItem';
import SearchListDivaItem from 'components/SearchList/components/SearchListDivaItem/SearchListDivaItem';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

const PAGE_SIZE = 25;
const SOURCE_TYPES = {
  operator: 'operator',
  client: 'client',
  escort: 'escort',
  diva: 'diva',
};

const FeedbackContactField = ({
  isBooking,
  isEditBooking,
  bookingDivaId,
  isProfileEdit,
  setDuoFieldOpen,
  setMeetingType,
  isSetProfileToFormState,
  input: { value: contact, onChange, onBlur },
  meta: { touched, error },
  fieldType = 'client',      // 'client', 'escort', 'source', 'diva'
  isDisabled,
}) => {
  const [isSourceDropdownOpen, setIsSourceDropdownOpen] = useState(false);

  const isSource = fieldType === 'source';
  const isOperatorContact = contact.type === CONTACT_TYPES.OPERATOR;

  // === Hooks

  const dispatch = useDispatch();

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    offset: [0, 0],
    visible: isSourceDropdownOpen,
    onVisibleChange: setIsSourceDropdownOpen,
  });

  useDidUpdate(() => {
    if (!isSourceDropdownOpen) {
      onBlur();
    }
  }, [isSourceDropdownOpen]);

  // === Markup functions

  const labelText = () => {
    switch (fieldType) {
      case 'client':
        return 'Client';
      case 'escort':
        return 'Escort';
      case 'source':
        return 'Source';
      case 'diva':
        return;
    }
  };

  const fetchDataRequests = {
    operator: (params) => API.getOperatorsForFeedback({ ...params, limit: PAGE_SIZE }),
    client: ({ offset, query, cancelToken }) => API.searchContacts(1, query, offset, PAGE_SIZE, cancelToken),
    escort: (params) => API.getEscortsForFeedback({ ...params, limit: PAGE_SIZE }),
    diva: ({ offset, query, cancelToken, part }) => API.getActiveDivaGirls({ limit: GIRLS_LIMIT, part, offset, search: query, cancelToken }),
  };

  const fetchData = (type) => {
    const requestFunction = fetchDataRequests[type];

    // params contain offset, query, cancelToken
    return (params) => requestFunction(params)
      .then(({ data }) => ({
        newItems: type === SOURCE_TYPES.diva ? data.result : data, // data.result - for diva
        newHasMore: data.result?.length === PAGE_SIZE || data.length === PAGE_SIZE,
      }));
  };

  const handleChoose = contact => {
    onChange(contact);
    dispatch(closeModal(MODAL_TYPES.SearchList));
  };

  const handleChooseContact = contact => {
    isSetProfileToFormState && onChange(contact);
    setDuoFieldOpen && setDuoFieldOpen(true);
    setMeetingType && setMeetingType(contact);
    dispatch(closeModal(MODAL_TYPES.SearchList));
  }

  const subMenuItemsActions = {
    operator: () => dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchData(SOURCE_TYPES.operator),
      itemComponent: SearchListContactItem,
      onChoose: handleChoose,
    })),
    client: () => dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchData(SOURCE_TYPES.client),
      itemComponent: SearchListContactItem,
      onChoose: handleChoose,
    })),
    escort: () => dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchData(SOURCE_TYPES.escort),
      itemComponent: SearchListEscortItem,
      onChoose: handleChoose,
    })),
    diva: () => dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchData(SOURCE_TYPES.diva),
      itemComponent: SearchListDivaItem,
      onChoose: (selectedContact) => {
        for (let serviceId in selectedContact.services) {
          selectedContact.services[serviceId].id = serviceId
        }

        handleChooseContact(selectedContact)
      },
      itemProps: { bookingDivaId }
    })),
  };

  const generateSubMenuItems = () => Object.keys(SOURCE_TYPES)
    .filter(item => item !== SOURCE_TYPES.diva)
    .map((key) => ({
      content: key,
      action: subMenuItemsActions[key],
    }));

  const handleClick = () => {
    if (isDisabled) return;

    if (!isSource) {
      subMenuItemsActions[fieldType]();
    }
  };

  const feedbackButton = () => {
    return contact 
      ? <div className='feedback-contact-field__contact'>
          <div className='feedback-contact-field__avatar-thumb'>
            <img src={getContactAvatar(contact)} alt="avatar" />
          </div>

          <div className='feedback-contact-field__text-info'>
            <p className='feedback-contact-field__name'>{!isOperatorContact ? contact.fn : contact.username}</p>

            {!isOperatorContact && contact.tels.length > 0 &&
              <p className='feedback-contact-field__phone'>
                {contact.tels[0].tel}
              </p>
            }
          </div>
        </div>
      : <div className='feedback-contact-field__no-contact'>
          <p className='feedback-contact-field__placeholder'>Name</p>

          {!isSource &&
            <ICONS.adrBook className='feedback-contact-field__adr-book-icon' />
          }

          {isSource &&
            <ICONS.chevron className='feedback-contact-field__drop-menu-icon' />
          }
        </div>
  }

  const bookingButton = () => {
    return isProfileEdit 
      ? <button 
          type='button'
          className={classModifier('booking-form__btn', 'edit')}
          disabled={isDisabled}
        >
          <ICONS.pencil className={classModifier('booking-form__btn-icon', 'edit')} />
        </button>
      :  <button 
          type='button'  
          className={classModifier('booking-form__btn', 'add-duo')}
          disabled={isDisabled}
        >
          Add DUO
          <ICONS.plusCircle className='booking-form__btn-icon'/>
        </button>
  }

  // === Calculated props

  const tooltipProps = getTooltipProps({
    className: 'feedback-contact-field__tooltip',
  });

  return (
    <div className='feedback-contact-field'>
      {labelText() && 
        <p className='feedback-contact-field__label'>
          {labelText()}
        </p>
      }

      <div
        className='feedback-contact-field__button'
        ref={setTriggerRef}
        onClick={handleClick}
      >
        {(isBooking || isEditBooking) ? bookingButton() : feedbackButton()}
      </div>

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }
  
      {isSourceDropdownOpen && isSource &&
        <div ref={setTooltipRef} {...tooltipProps}>
          <DropdownMenu
            items={generateSubMenuItems()}
            closeDropdownMenu={() =>  setIsSourceDropdownOpen(false)}
          />
        </div>
      }
    </div>
  );
};

export default FeedbackContactField;
