import React, { useMemo, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { markMessageAsUnread, setMessageReminder, deleteTelegramMsgRequest } from 'redux/ducks/clientChats';
import { pinClientMsg } from 'redux/ducks/clientChats';
import { getDateByTimezoneOffset, parseMsgText } from 'utils';
import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { ADR_BOOK_FILTERS, INTERACTION_TYPES } from 'config/constants';
import API from 'api/api';

import InteractionMenu from './InteractionMenu';
import ICONS from 'assets/icons';
import { selectContactById } from 'redux/selectors/selectors';

const deleteMsgConfirmText = "Are you sure you want to delete this message?";

const ContactInteractionMenu = (props) => {
  const {
    interaction,
    activeChannel,
    activeRecipientType,
    arrayContactPinnedMsgs,
    isWebmasterMsg,
    chatSource,
    isTelegramGroupMsg = false,
  } = props;

  const caller = useSelector((state) => selectContactById(state, interaction.caller_id));

  const {
    INCOMING_MSG_TELEGRAM,
    INCOMING_MMS,
    INCOMING_MSG,
    INCOMING_MSG_WHATSAPP,
    INCOMING_MSG_IPHONE_SMS,
    INCOMING_MSG_IPHONE_IMESSAGE,
  } = INTERACTION_TYPES;
  const { GIRL } = ADR_BOOK_FILTERS;

  const isIncomingTelegramGorupMsg = !interaction.out && isTelegramGroupMsg;
  const isIncommingMessage = [
    INCOMING_MMS,
    INCOMING_MSG,
    INCOMING_MSG_TELEGRAM,
    INCOMING_MSG_WHATSAPP,
    INCOMING_MSG_IPHONE_SMS,
    INCOMING_MSG_IPHONE_IMESSAGE,
  ].includes(interaction.type);

  const remindWithTooltip = (time) => {
    return props.setMessageReminder(activeRecipientType, interaction, time)
  }

  const isMsgAlreadyPinned = arrayContactPinnedMsgs.some(el => el.id === interaction.id);
  const interactionContactType = interaction.contact?.type || interaction.caller?.type;

  const dispatch = useDispatch();

  const handleOpenNewTasksModal = () => (
    dispatch(openModal(MODAL_TYPES.addNewTasksOrRemaindersModal, {
      isCreate: true,
      item: { interaction, interaction_id: interaction.id, isTelegramGroupMsg },
      isWebmaster: true,
      caller
    }))
  )

  const items = useMemo(() => {
    const itemsArray = [];

    if ((
      isIncommingMessage
      && interactionContactType === GIRL
      && !isWebmasterMsg
      && chatSource !== 'system_msgs'
    ) || (isIncomingTelegramGorupMsg && !isWebmasterMsg)
    ) {
      itemsArray.push({
        icon: <ICONS.w className='ii-menu__icon' />,
        tooltipOnHover: 'Add message to webmaster tasks',
        action: handleOpenNewTasksModal,
      })
    }

    itemsArray.push({
      icon: <ICONS.copy className='ii-menu__icon' />,
      action: async () => window.navigator.clipboard.writeText(parseMsgText(interaction.body, true)),
      tooltipOnHover: 'Copy text',
    })

    if (!isMsgAlreadyPinned && arrayContactPinnedMsgs.length < 3 && !isTelegramGroupMsg) {
      itemsArray.push({
        icon: <ICONS.pin className='ii-menu__icon' />,
        tooltipOnHover: 'Pin message',
        action: () => props.pinClientMsg(interaction, interaction.caller_id, activeRecipientType),
      })
    }

    if (!isMsgAlreadyPinned && arrayContactPinnedMsgs.length === 3 && !isTelegramGroupMsg) {
      itemsArray.push({
        icon: <ICONS.pin className='ii-menu__icon ii-menu__icon--limit-pin-msg' />,
        tooltipOnHover: 'Maximum of pinned messages',
        classMods: 'limit-pin-msg'
      })
    }

    if (interaction.type === 4 && !props.search && !!interaction.isRead) {
      itemsArray.push({
        icon: <ICONS.mail className='ii-menu__icon' />,
        tooltipOnHover: 'Unread message',
        // action: () => props.markMessageAsUnread(interaction, activeRecipientType),
        // tooltipAfterAction: 'Success unread'
      })
    }

    if (!isTelegramGroupMsg) {
      itemsArray.push({
        icon: <ICONS.bell className='ii-menu__icon' />,
        tooltipOnHover: 'Remind ',
        // action: () => props.setMessageReminder(activeRecipientType, interaction, new Date().getTime() + 5 * 60 * 1000),
        subMenu: [
          {
            title: '15 mins',
            action: () => props.setMessageReminder(activeRecipientType, interaction, new Date().getTime() + 15 * 60 * 1000)
          },
          {
            title: '30 mins',
            action: () => props.setMessageReminder(activeRecipientType, interaction, new Date().getTime() + 30 * 60 * 1000)
          },
          {
            title: '45 mins',
            action: () => props.setMessageReminder(activeRecipientType, interaction, new Date().getTime() + 45 * 60 * 1000)
          },
          {
            title: 'In hour',
            action: () => props.setMessageReminder(activeRecipientType, interaction, new Date().getTime() + 3600 * 1000)
          },
          // {
          //   title: 'In 3 hours',
          //   action: () => props.setMessageReminder(activeRecipientType, interaction, new Date().getTime() + 3600 * 3000)
          // },
          {
            title: 'Custom',
            action: async () => props.openModal(
              MODAL_TYPES.timePicker,
              {
                onSelectDate: date => {
                  remindWithTooltip(date);
                  props.closeModal();
                },
                minDate: +getDateByTimezoneOffset(props.userTimezone) + 900000,
              }
            )
          },
        ]
      });
    }

    if (activeChannel === 'telegram' || activeChannel === 'whatsapp' || activeChannel === 'telegram_client') {
      itemsArray.push({
        icon: <ICONS.reply className='ii-menu__icon' />,
        tooltipOnHover: 'Reply',
        action: async () => props.replyMsg(interaction),
      })

      if (interaction.is_deletable) {
        itemsArray.push({
          icon: <ICONS.trash className='ii-menu__icon' />,
          tooltipOnHover: 'Remove',
          classMods: "danger",
          action: async () => {
            if (window.confirm(deleteMsgConfirmText)) {
              props.deleteTelegramMsgRequest(interaction.id);
            }
          },
        })
      }
    }

    return itemsArray;
  }, [activeChannel, arrayContactPinnedMsgs, isWebmasterMsg]);

  return (
    <InteractionMenu
      isOpen={props.isOpen}
      menuItems={items}
      closeMenu={props.closeMenu}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  arrayContactPinnedMsgs: (
    ownProps.activeRecipientType === 1
      ? state.clientChats.pinnedMsgs
      : state.girlChats.pinnedMsgs
  ),
  chatSource: state.girlChats.chatSource,
});

const mapDispatchToProps = {
  markMessageAsUnread,
  setMessageReminder,
  pinClientMsg,
  openModal,
  closeModal,
  deleteTelegramMsgRequest,
};


export default connect(mapStateToProps, mapDispatchToProps)(ContactInteractionMenu);
