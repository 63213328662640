import API from "api/api";
import { normalize } from "utils/normalizeContacts";

const reducerName = 'mailContactSearch/';

export const GET_CONTACT_LIST_PENDING = reducerName + 'GET_CONTACT_PENDING';
export const GET_CONTACT_LIST = reducerName + 'GET_CONTACT_LIST';
export const UPDATE_CONTACT_LIST = reducerName + 'UPDATE_CONTACT_LIST';

export const FILTER_CONTACT_LIST = reducerName + 'FILTER_CONTACTS_LIST';
export const UPDATE_FILTERED_CONTACT_LIST = reducerName + 'UPDATE_FILTERED_CONTACTS_LIST';

export const UPDATE_SEARCH = reducerName + 'UPDATE_SEARCH';

export const getContactsForSearchModal = (config) => dispatch => {
  if (!config.part) {
    dispatch({
      type: GET_CONTACT_LIST_PENDING
    });
  }

  return API.getMinifiedContactsWithOneEmail(config)
    .then(({ data }) => {
      dispatch({
        type: config.part ? UPDATE_CONTACT_LIST : GET_CONTACT_LIST,
        payload: {
          ...normalize(data, 'emailId'),
        }
      });
    })
    .catch(console.error);
}

export const filterContactsForSearchModal = (config) => dispatch => {
  if (!config.part) {
    dispatch({
      type: GET_CONTACT_LIST_PENDING
    });
  }

  return API.getMinifiedContactsWithOneEmail(config)
    .then(({ data }) => {
      dispatch({
        type: config.part ? UPDATE_FILTERED_CONTACT_LIST : FILTER_CONTACT_LIST,
        payload: {
          ...normalize(data, 'emailId'),
        }
      });
    })
    .catch(console.error);
}

export const updateSearch = (query) => dispatch => {
  dispatch({
    type: UPDATE_SEARCH,
    payload: query
  });
}

export const initialState = {
  contactsPending: false,
  entities: {},
  ids: [],

  search: '',
  auxiliaryIds: [],
}

export const mailContactSearchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONTACT_LIST_PENDING: {
      return {
        ...state,
        contactsPending: true,
      }
    }

    case GET_CONTACT_LIST: {
      return {
        ...state,
        contactsPending: false,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        ids: payload.result,
        auxiliaryIds: []
      }
    }

    case UPDATE_CONTACT_LIST: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        ids: [...state.ids, ...payload.result],
      }
    }

    case FILTER_CONTACT_LIST: {
      return {
        ...state,
        contactsPending: false,
        entities: { 
          ...state.entities, 
          ...payload.entities 
        },
        auxiliaryIds: payload.result,
        ids: [],
      }
    }

    case UPDATE_FILTERED_CONTACT_LIST: {
      return {
        ...state,
        entities: { 
          ...state.entities, 
          ...payload.entities 
        },
        auxiliaryIds: [...state.auxiliaryIds, ...payload.result],
      }
    }

    case UPDATE_SEARCH: {
      return {
        ...state,
        search: payload
      }
    }

  default:
    return state
  }
}
