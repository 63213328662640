import classNames from 'classnames';

import { classModifier } from 'utils';
import { BOOKING_STATUSES } from 'config/constants';

import './BookingStatus.scss'

const BookingStatus = ({ className, booking }) => {
  const statusLabel = Object.values(BOOKING_STATUSES)[booking.status].label;

  return (
    <span className={classNames(className, classModifier('booking-status1', statusLabel.replace(/ /g, '-')))}>
      {statusLabel}
    </span>
  )
}

export default BookingStatus;
