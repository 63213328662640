import { useDispatch, useSelector } from 'react-redux'
import { useContext } from 'react'

import API from 'api/api'
import { MAP_GIRL_FILTERS, updateActiveGirlIds, updateFilter, updateGirlsCoordinatesData, updateMapState, updatePending } from 'redux/ducks/map'
import { useDidMount } from 'hooks'
import { selectGirlsIdsFromActiveChats } from 'redux/selectors/selectors'
import { GirlsMapModalContext } from '../../GirlsMapModal'

import AllEscortsButton from '../ControlElements/AllEscortsButton'
import ClientLocationInput from '../ControlElements/ClientLocationInput'
import ClientRadiusSelect from '../ControlElements/ClientRadusSelect'
import SelectEscortsButton from '../ControlElements/SelectEscortsButton'
import SendEscortsButton from '../ControlElements/SendEscortsButton'


const DefaultControls = {};

DefaultControls.Header = () => {
  const profileIdsOfActiveGirlChats = useSelector(selectGirlsIdsFromActiveChats);

  const dispatch = useDispatch();

  useDidMount(() => {
    dispatch(updateActiveGirlIds(profileIdsOfActiveGirlChats));
    dispatch(updateFilter(MAP_GIRL_FILTERS.CHAT_ESCORTS));
  })

  return (
    <div className="girls-map__header">
      <div className="girls-map__description">
        Showing escorts with which you are having open conversation in chats.
      </div>

      <AllEscortsButton />
    </div>
  )
}

DefaultControls.Footer = () => (
  <div className="girls-map__footer">
    <div className="girls-map__footer-side">
      <ClientLocationInput />
      <ClientRadiusSelect />
    </div>

    <div className="girls-map__footer-side">
      <SelectEscortsButton />
      <SendEscortsButton />
    </div>
  </div>
)

export default DefaultControls;
