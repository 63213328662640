import { classModifier } from 'utils';

import { BOOKING_DETAILS_ITEMS_NAMES, BOOKING_MARKERS } from 'components/BookingItemNew/config/constants';
import { ATTACHMENT_BOOKING_DATE_CONFIG } from 'config/dates-сonfig';

import "./BookingClientPinnedItem.scss";

const MARKER_ITEMS = [BOOKING_MARKERS.BOOKING, BOOKING_MARKERS.PREBOOKING];
const DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.DATE,
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.RATE
]

const BookingClientPinnedItem = (props) => {
  const {
    Escort,
    Details,
    Markers,
    handleOpenBookingModal,
    getBookingStates,
    isDeleted,
  } = props;

  const status = getBookingStates(MARKER_ITEMS);

  return (
    <div
      className={classModifier("booking-client-pinned-item", [status, isDeleted && 'deleted'])}
      onClick={handleOpenBookingModal}
    >
      {isDeleted && <div className="booking-client-pinned-item__deleted-status">Deleted</div>}

      <Escort
        classPrefix="booking-client-pinned-item-escort"
        isAvatarShowed
        namesAlignment="gorizontal"
        extraText={
          <Details
            className="booking-client-pinned-item__details"
            items={DETAILS_ITEMS}
            dateConfig={ATTACHMENT_BOOKING_DATE_CONFIG}
          />
        }
      />

      <Markers className="booking-client-pinned-item__markers" items={[status]} />
    </div>
  )
};

export default BookingClientPinnedItem;
