export default (contactName, className, fn) => {
  if (fn?.startsWith('EX ')) {
    return <><span className={className}>EX&nbsp;</span>{contactName}</>
  }

  if (contactName?.startsWith('EX ')) {
    return <><span className={className}>EX&nbsp;</span>{contactName.slice(3)}</>
  }

  return contactName;
}