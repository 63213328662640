import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars-2';

import "./ProfileReviews.scss";

import { closeModal } from 'redux/ducks/activeWindows';
import { deleteDivaGirlReview } from "redux/ducks/divaGirls";

import ProfileReview from "./components/ProfileReview";

import API from "api/api";
import SearchInput from "components/SearchInput/SearchInput";
import Spinner from "components/UI/Spinner/Spinner";
import { useDidMount, useToggle } from "hooks";
import ICONS from "assets/icons";

const sortOptions = [
  {label: "Newest", value: "ctime=desc"},
  {label: "Oldest", value: "ctime=asc"},
  {label: "Most rating", value: "rating=desc"},
  {label: "Less rating", value: "rating=asc"},
];


const ProfileReviews = ({ id, profile = {}, closeModal, ...props }) => {
  const [reviews, setReviews] = useState(null);
  const [filteredReviews, setFilteredReviews] = useState(null);
  const [query, setQuery] = useState('');
  const [pending, setPending] = useState(false);
  const [reviewsCount, setReviewsCount] = useState(0);

  const [isSorted, toggleSorted] = useToggle(false);
  const [isSearched, toggleIsSearched] = useToggle(false);

  useDidMount(() => {
    setPending(true);
    sortBy(sortOptions[0], true);
  });

  useEffect(() => {
    if(profile) {
      setReviewsCount(+profile.reviewsCount);
    }
  }, [profile]);

  const currentReviews = (!isSorted && !query)
    ? reviews
    : filteredReviews;

  const sortBy = (sort, isDidMount = false) => {
    setQuery('');

    if (!sort.value) return toggleSorted(false);

    setPending(true);
    
    API.sortProfileReviews(id, sort.value)
      .then(({ data }) => {
        if (isDidMount) {
          setReviews(data)
        }
        setFilteredReviews(data);
        toggleSorted(true);
      })
      .catch(err => console.error(err))
      .finally(() => setPending(false));
  };

  const startSearch = useCallback((query) => {
    toggleSorted(false);
    setPending(true);
    
    API.searchProfileReviews(id, query)
      .then(({ data }) => {
        setFilteredReviews(data);
        toggleIsSearched(true);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setPending(false);
      });
  }, [query]);

  const stopSearch = useCallback(() => {
    setQuery('');
    toggleIsSearched(false);
  }, []);

  const deleteReview = useCallback((reviewId) => {
    if (window.confirm("Remove review?")) {
      setPending(true);

      API.deleteProfileReview(reviewId)
        .then(() => {
          // if (!isSorted && !query) {
          //   setReviews((prevState) => {
          //     return prevState.filter(review => review.id != reviewId);
          //   });
          // }
          // else {
            setFilteredReviews((prevState) => {
              return prevState.filter(filteredReview => filteredReview.id != reviewId);
            });
            setReviews((prevState) => {
              return prevState.filter(review => review.id != reviewId);
            });

            setReviewsCount(prevState => prevState - 1);
            props.handleDeleteReview && props.handleDeleteReview();
          // }

          // props.deleteDivaGirlReview(id, reviewId);
        })
        .catch(err => console.error(err))
        .finally(() => setPending(false));
    }
  }, []);

  const updateReview = useCallback((updatedReview) => {
    setReviews((prevState) => {
      return prevState.map((review) => {
        if (review.id == updatedReview.id) {
          return updatedReview;
        } else {
          return review;
        }
      })
    });
  }, []);

  return (
    <div className="profile-reviews">
      <div className="profile-reviews__header">
        <span className="profile-reviews__rating">
          <ICONS.star className="profile-reviews__rating-icon" />
          {profile.rating?.rating}
          <span className="profile-reviews__count">          
            ( {currentReviews?.length || 0} ) Reviews/feedbacks
          </span>
        </span>

        {/* <span className="profile-reviews__sort">
          <Select
            className="react-select"
            classNamePrefix="react-select"
            options={sortOptions}
            defaultValue={sortOptions[0]}
            getOptionValue={option => option.value}
            getOptionLabel={option => option.label}
            onChange={sortBy}
          />
        </span> */}

        <div className="profile-reviews__search">
          <SearchInput
            showClearBtn
            showSearchBtn
            query={query}
            setQuery={setQuery}
            stopSearch={stopSearch}
            startSearch={startSearch}
            placeHolder="search review/feedback"
            inputClassName="profile-reviews__input"
            inputWrapClassName="profile-reviews__search-wrapper"
          />
        </div>

        <span className="profile-reviews__close" onClick={() => closeModal()}>
          <ICONS.close />(Esc)
        </span>
      </div>

      <div className="profile-reviews__scrollbar-wrap">
        {pending 
          ?
            <div className="profile-reviews__pending">
              <Spinner spinnerSize="70px"/>
            </div>

          : <Scrollbars 
              renderThumbVertical={() => <div className="profile-reviews__scrollbar-thumb" />}
              renderTrackVertical={() => <div className="profile-reviews__scrollbar-track" />}
            >
              {!!profile && currentReviews?.map((review, i) => (
                <ProfileReview
                  review={review}
                  key={review.id}
                  deleteReview={deleteReview}
                  updateReview={updateReview}
                  profileId={id}
                  highlightedText={isSearched && query}
                />
              ))}
            </Scrollbars>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile: state.divaGirls.entities[ownProps.id]
});

const mapDispatchToProps = ({
  closeModal,
  deleteDivaGirlReview,
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileReviews);
