import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { usePopperTooltip } from 'react-popper-tooltip';

import API from 'api/api';
import { CARD_TABS } from 'config/constants';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import ICONS from 'assets/icons';
import Tooltip from '../../../../components/UI/Tooltip/Tooltip';
import Portal from 'components/Portal';

const newEmailBtnOption = { value: '+ Add New Email' };

const ContactEmails = ({ activeRecipient, ...props }) => {
  const { emails = [] } = activeRecipient;
  const defaultEmail = emails[0];
  const classPrefix = 'react-select';

  const [selectedEmail, setSelectedEmail] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (emails.length > 0) {
      setSelectedEmail({ id: defaultEmail.id, value: defaultEmail.email });
    }
  }, [activeRecipient]);

  const openEditContactForm = () => {
    return dispatch(openModal(MODAL_TYPES.contactCard, {
      contact: activeRecipient.id,
      defaultTab: CARD_TABS.EDIT,
      isAddEmailFieldOnMount: true,
    }));
  };

  const onChangeHandler = (option) => {
    if (option.value === selectedEmail.value) return;

    if (option.value === newEmailBtnOption.value) {
      openEditContactForm();
      return;
    }

    API.changeDefaultContactEmail(option.id)
      .then(() => setSelectedEmail(option))
      .catch(console.error);
  }

  const filterOption = (option) => {
    return emails.length > 1
      ? true
      : option.value !== selectedEmail.value;
  }

  const options = useMemo(() => [
    ...emails.map(email => ({ id: email.id, value: email.email, label: email.labelName })),
    newEmailBtnOption
  ], [emails]);

  if (emails.length === 0) {
    return (
      <Tooltip
        text='Add New Email'
        visibleWhenIsOverflowed
      >
        {(setTriggerRef) => (
          <div className="chat-header__select-wrap" ref={setTriggerRef}>
            <button
              className="chat-header__add-new-btn"
              onClick={openEditContactForm}
            >
              + Add New Email
            </button>
          </div>
        )}
      </Tooltip>
    )
  }

  const SingleValue = (props) => {
    const { children, selectProps: { menuIsOpen } } = props;

    return (
      <Tooltip
        text={children}
        visibleWhenIsOverflowed
        extraCondition={!menuIsOpen}
      >
        {(setTriggerRef) => (
          <components.SingleValue innerProps={{ ref: setTriggerRef }} {...props}>
          	{children}
          </components.SingleValue>
        )}
      </Tooltip>
    )
  }

  return (
    <div className="chat-header__select-wrap">
      <button className="chat-header__select-btn chat-header__select-btn--email">
        <ICONS.mail className="chat-header__select-icon"/>
      </button>
  
      <Select
        className={classPrefix}
        classNamePrefix={classPrefix}
        options={options}
        value={selectedEmail}
        isSearchable={false}
        getOptionLabel={option => option.value}
        getOptionValue={option => option.value}
        onChange={onChangeHandler}
        filterOption={filterOption}
        components={{
          IndicatorSeparator: null,
          SingleValue,
        }}
      />
    </div>
  )
}

export default ContactEmails;
