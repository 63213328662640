import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";

import API from "api/api";
import { updateProfileFilters } from 'redux/ducks/sessions';
import { getAdditionalFiltersFromDiva } from "redux/ducks/divaGirls";

const SelectFilterItem = ({ 
  idx, 
  group, 
  additionalFilters, 
  activeSession, 
  filter, 
  canIEditSession, 
  selectedValues,
  setSelectedValues,
  ...props 
  }) => {

  const getOptions = values => values?.map(value => ({ label: value, value }));
  const getValue = (selectedValues) =>  selectedValues ? getOptions(selectedValues) : null;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(getValue(selectedValues[filter.name]));

  const selectRef = useRef(null);

  useEffect(() => {
    if((additionalFilters[filter.fieldLabel] || []).length !== (selectedValues[filter.name] || []).length) {
      selectRef.current.clearValue();
    }
  }, [additionalFilters[filter.fieldLabel]]);

  const selectValue = useCallback((name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const updateProfileFilters = (selectedValues) => {
    props.updateProfileFilters(selectedValues, activeSession, additionalFilters, canIEditSession);
  };

  const toggleSelectIsOpen = (name) => {
    setIsOpen(true);

    if(!isOpen) {
      setIsLoading(true);

      API.getFilterFieldOptions(name, { selectedValues, part: 1, limit: 20 })
        .then(({ data }) => {
          setOptions(getOptions(data));
        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  };
  
  const isNeedOptionSearch = ['location', 'services', 'nationality', 'language'].includes(filter.name);

  return (
    <div className="gallery-header-filters__filter">
      <span className="gallery-header-filters__filter-title">{filter.label}</span>
      <div onClick={() => toggleSelectIsOpen(filter.optionName)}>
        <ReactSelect
          ref={selectRef}
          placeholder="select"
          className="react-select"
          classNamePrefix="react-select"
          isMulti
          isClearable
          menuIsOpen={isOpen}
          onBlur={() => {
            setIsOpen(false);
            setOptions([]);
          }}
          isLoading={isLoading}
          value={value}
          options={options}
          isSearchable={isNeedOptionSearch} 
          onChange={(options) => {
            selectValue(filter.name, options.map((option) => option.value));
            setValue(options);

            updateProfileFilters({
              ...selectedValues, 
              [filter.name]: options.map((option) => option.value) 
            });
          }}
        />
      </div>
    </div>
  )
}

const mSTP = state => ({
  additionalFilters: state.sessions.entities[state.sessions.activeSession].additionalFilters
})

const mDTP = {
  updateProfileFilters,
  getAdditionalFiltersFromDiva,
}

export default connect(mSTP, mDTP)(React.memo(SelectFilterItem));