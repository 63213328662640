export default (date1, date2) => {
  // Create a new Moment object with the date from moment1 and the time from moment2
  const mergedMoment = date1.clone().set({
    hour: date2.get('hour'),
    minute: date2.get('minute'),
    second: date2.get('second'),
    millisecond: date2.get('millisecond')
  }).valueOf();

  return mergedMoment;
}
