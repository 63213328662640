import React, { useEffect, useState } from 'react';

import './FloatingMenu.scss';
import Portal from 'components/Portal';
import { Popper } from 'react-popper';


const getRefferenceElement = (event) => ({
  getBoundingClientRect: () => ({
    top: event.clientY,
    left: event.clientX,
    right: event.clientX,
    bottom: event.clientY,
    width: 0,
    height: 0,
  }),
})


const FloatingMenu = (props) => {
  const {
    items,
    children,
    trigger,
  } = props;

  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  
  const openMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setReferenceElement(getRefferenceElement(event)); // set point for anchoring the menu
    setIsFloatingMenuVisible(true);
    props.setIsFloatingMenuVisible && props.setIsFloatingMenuVisible(true);
  }

  const closeMenu = () => {
    setIsFloatingMenuVisible(false);
    props.setIsFloatingMenuVisible && props.setIsFloatingMenuVisible(false)
  };

  const handleContextMenu = (event) => {
    if (trigger !== 'rightClick') return;
    
    openMenu(event);
  };

  const handleClick = (event) => {
    if (trigger !== 'click') return;
    
    openMenu(event);
  };

  const handleClickMenu = (event, action) => {
    action(event);
    setIsFloatingMenuVisible(false);
    props.setIsFloatingMenuVisible && props.setIsFloatingMenuVisible(false);
  }

  const anchorProps = trigger === 'rightClick' ? { onContextMenu: handleContextMenu } : { onClick: handleClick };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    window.addEventListener('scroll', closeMenu, { capture: true });

    return () => {
      document.removeEventListener('mousedown', closeMenu);
      window.removeEventListener('scroll', closeMenu, { capture: true });
    }
  }, [])

  return (
    <>
      {children instanceof Function
        ? children(setTriggerRef)
        : (React.cloneElement(React.Children.only(children), anchorProps))
      }

      {isFloatingMenuVisible && (
        <Portal>
          <Popper
            referenceElement={referenceElement}
            placement='right-start'
          >
            {({ ref, style, placement }) => (
              <ul
                className="popup-menu"
                ref={ref}
                style={style}
                data-placement={placement}
              >
                {items.map((item, index) => (
                  <li
                    key={index}
                    className="popup-menu__item"
                    onMouseDown={(event) => handleClickMenu(event, item.action)}
                  >
                    {item.text}
                  </li>
                ))}
              </ul>
            )}
          </Popper>
        </Portal>
      )}
    </>
  )
}

export default FloatingMenu;
