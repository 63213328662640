import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { updateClientRadius } from 'redux/ducks/map';
import { SELECT_THEMES } from 'config/constants';
import { RADIUS_OPTIONS } from '../../config/config';

import Select from 'components/UI/Select/Select';


const ClientRadiusSelect = () => {
  const { clientData, location } = useSelector(state => state.map);
  
  const dispatch = useDispatch();
  
  const handleChangeRadius = (option) => {
    dispatch(updateClientRadius(option));
  }
  
  const handleCreateOption = (inputValue) => {
    const newLabel = inputValue.match(/\d+/g) + ' miles';
    const newValue = { value: inputValue, label: newLabel };

    handleChangeRadius(newValue);
  }

  return (
    <div className="girls-map__filter-wrap">
      <label
        className="girls-map__filter-label"
        htmlFor="radius"
      >
        Radius
      </label>

      <Select
        className={classNames("react-select", "girls-map__radius-select")}
        value={clientData.radius.value ? clientData.radius : null}
        type={SELECT_THEMES.LIGHT}
        options={RADIUS_OPTIONS}
        onChange={handleChangeRadius}
        isDisabled={!clientData.latitude || !clientData.longitude || !location}
        creatable
        isValidNewOption={(input) => Number(input) < 10}
        onCreateOption={handleCreateOption}
      />
    </div>
  )
}

export default ClientRadiusSelect;
