import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';

import './BookingFiltersList.scss'
import ICONS from 'assets/icons';

export const BookingFiltersList = ({
  className,
  children,
  onReset,
  isUpdated,
  bookingsCount,
  counterPosition,
  disabled
}) => {
  const [actualBookingCount, setActualBookingCount] = useState(bookingsCount || 0);

  useEffect(() => {
    if (disabled || bookingsCount === undefined) return;

    setActualBookingCount(bookingsCount);
  }, [disabled, bookingsCount])

  return (
    <div className={classNames("booking-filters-list-new", className)}>
      {children}
      
      <div className={classModifier("booking-filters-list-new__counter-box", [counterPosition, isUpdated && 'is-updated'])}>
        <p className="booking-filters-list-new__counter">
          {actualBookingCount}
        </p>

        {isUpdated && (
          <button
            className="booking-filters-list-new__clear-btn"
            onClick={onReset}
            disabled={disabled}
          >
            <ICONS.close className="booking-filters-list-new__clear-btn-icon"/>
          </button>
        )}
      </div>
    </div>
  )
}
