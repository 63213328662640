import classNames from 'classnames';
import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { CALENDAR_TABLE_BOOKING_DATE_CONFIG } from 'config/dates-сonfig';
import { classModifier } from 'utils';

import "./BookingTableItem.scss";

const BOOKING_DETAILS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const BookingTableItem = (props) => {
  const {
    Date,
    Escort,
    Client,
    Details,
    Markers,
    Operator,
    Notes,
    Requirements,
    checkbox,
    className,
    statusColor,
    handleOpenBookingModal,
    innerRef,
    isDeleted,
    style = {}
  } = props;

  const getTableColClasses = (name) => {
    return classNames(
      classModifier("booking-table-item__table-col", name),
      classModifier(`${className}__table-col`, name)
    )
  }

  return (
    <li
      className={classModifier('booking-table-item', isDeleted && 'deleted')}
      onClick={handleOpenBookingModal}
      style={style}
      ref={innerRef}
    >
      <div className={getTableColClasses('checkbox')}>
        {checkbox}
      </div>

      <div className={getTableColClasses('date')}>
        <Date
          className="booking-table-item__date"
          config={CALENDAR_TABLE_BOOKING_DATE_CONFIG}
          isRanged
        />
      </div>

      <div className={getTableColClasses('escort')}>
        <Escort className="booking-table-item__escort" namesAlignment='vertical' />
      </div>

      <div className={getTableColClasses('client')}>
        <Client className="booking-table-item__client" />
      </div>

      <div className={getTableColClasses('status')}>
        <div
          className="booking-table-item__status"
          style={{ backgroundColor: statusColor }}
        ></div>
      </div>

      <div className={getTableColClasses('details')}>
        <Details items={BOOKING_DETAILS} />
      </div>

      <div className={getTableColClasses('markers')}>
        <Markers />
      </div>

      <div className={getTableColClasses('requirements')}>
        <Requirements />
      </div>

      <div className={getTableColClasses('operator')}>
        <Operator />
      </div>

      <div className={getTableColClasses('notes')}>
        <Notes className="booking-table-item__notes" />
      </div>
    </li>
  );
}

export default BookingTableItem;
