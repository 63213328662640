import React from 'react';
import { connect } from 'react-redux';

import { getContactAvatar } from 'utils';
import { closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const NotForContactModalItem = (props) => {

  const {
    item,
    callerId,
    className,
    closeModal,
    saveContact
  } = props;

  const contactInfo = item.tels[0]?.tel || item.emails[0]?.email || '(no info)';

  const handleClick = () => {
    const profileItem = {
      caller_id: callerId,
      caller_ignore_id: item.id,
      ignoreName: item.fn,
      reason: '_'
    };

    saveContact(profileItem);
    closeModal(MODAL_TYPES.notForContact);
  };
  
  return (
    <li className={className} onClick={handleClick}>
      <LazyLoadImage src={getContactAvatar(item)} alt="ava" className={className + "-img"} />
      
      <div className={className + "-info"}>
        <span>{item.fn}</span>
        <span>{contactInfo}</span>
      </div>
    </li>
  )
}

const mapDispatchToProps = {
  closeModal
}

export default connect(null, mapDispatchToProps)(NotForContactModalItem);