import React from 'react';
import ReactDOM from 'react-dom';

const Title = ({ title, coords }) => {
  return (
    ReactDOM.createPortal(
      <span
        style={{
          backgroundColor: '#333',
          // whiteSpace: 'nowrap',
          fontSize: 14,
          border: 'solid 1px #fff',
          color: '#fff',
          padding: '4px 8px',
          position: 'fixed',
          left: (coords.x + 10) + 'px',
          top: (coords.y + 10) + 'px',
          zIndex: 99999999999999,
        }}
      >
        {title}
      </span>,
      document.getElementById('dialogs-root')
    )
  )
}

export default Title