import { DIVA_GIRLS_IMG_HOST } from 'redux/ducks/divaGirls';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import ExpandedContactBlock from 'components/UI/ExpandedContactBlock/ExpandedContactBlock';


const getGirlWithPhoto = (girl) => ({
  ...girl,
  photo: DIVA_GIRLS_IMG_HOST + girl.avatar,
  isProfile: true,
})

const AttachmentDivaGirls = ({ girls }) => {
  return (
    <article className="attachment-girls">
      {girls.map(girl => {
        const girlWithPhoto = getGirlWithPhoto(girl)

        return (
          <Tooltip
            className="attachment-girls__tooltip"
            text={<ExpandedContactBlock item={girlWithPhoto} />}
          >
            <figure>
              <LazyLoadImage src={girlWithPhoto.photo} alt="avatar" />
              <figcaption>{girl.name.split(' ')[0]}</figcaption>
            </figure>
          </Tooltip>
        )
      })}
    </article>
  )
}

export default AttachmentDivaGirls;
