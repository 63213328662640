import React, { useEffect, useState } from 'react';

import API from 'api/api';

import './PrebookingsList.scss';
import List from 'components/List/List';
import Spinner from 'components/UI/Spinner/Spinner';
import BookingItem from 'components/BookingItem/BookingItem';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import BookingEscortScheduleItem from 'components/BookingItem/BookingEscortScheduleItem';


const PrebookingsList = ({ escortNames, escortIds, clientName, callerId, exceptUuid }) => {
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClientSchedule, setIsClientSchedule] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const params = [
      isClientSchedule ? [callerId] : [],
      !isClientSchedule ? escortIds : [],
      exceptUuid
    ];

    API.getFutureBookings(...params)
      .then(res => {
        setBookings(res.data);
      })
      .catch(console.error)
      .finally(() => setIsLoading(false))
  }, [isClientSchedule]);

  const handleToggleChange = () => {
    setIsClientSchedule(!isClientSchedule);
  };

  return (
    <>
      <div className='prebookings-list__booking-details-wrap'>
        <h4 className='prebookings-list__booking-details-title'>
          {bookings.length} prebooking{bookings.length > 1 ? 's ' : ' '} 
          with: {isClientSchedule ? clientName : escortNames}
        </h4>

        {isLoading && 
          <div className='prebookings-list__spinner-wrap'>
            <Spinner spinnerSize={22} />
          </div>
        }

        <div className='prebookings-list__toggle-wrap'>
          Client`s schedule
          <ToggleSwitch onChange={handleToggleChange}/>
          Escort`s schedule
        </div>
      </div>
      
      {!isLoading && !!bookings?.length && 
        <List
          list={bookings}
          scrollInitialPosition="top"
          classPrefix="prebookings-list"
          scrollbarProps={{
            autoHide: true,
            renderTrackVertical: CustomScrollbarTrack,
            renderThumbVertical: CustomScrollbarThumb,
          }}
        >
          {isClientSchedule 
            ? <BookingItem	
                isPrebookingsList
                classNameModifier='profiles-booked'
              /> 
            : <BookingEscortScheduleItem classNameModifier='profiles-booked'/> 
          }
        </List>
      }
    </>
  )
}

export default PrebookingsList;