import React, { useCallback, useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";
import _ from 'lodash';

import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';

const CustomScrollbars = ({ onScroll, customOnScroll, forwardedRef, style, children }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const refSetter = useCallback(scrollbarsRef => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    }
    else {
      forwardedRef(null);
    }
  }, []);

  const resetScrolling = _.debounce(() => setIsScrolling(false), 2000);

  const handleScroll = (e) => {
    setIsScrolling(true);
    onScroll(e);
    customOnScroll(e);
    resetScrolling();
  }

  return (
    <Scrollbars
      className={isScrolling ? 'scrolled-container' : 'not-scrolled-container'}
      ref={refSetter}
      style={{ ...style, overflow: "hidden" }}
      onScroll={handleScroll}
      renderThumbVertical={CustomScrollbarThumb}
      renderTrackVertical={CustomScrollbarTrack}
    // renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
    >
      {children}
    </Scrollbars>
  )
};

export default CustomScrollbars;
