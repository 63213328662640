import ICONS from 'assets/icons';
import Button from 'components/Button/Button';
import { BUTTON_THEMES } from 'config/constants';
import { DRAFT_STATUSES } from 'redux/ducks/mail';
import { classModifier } from 'utils';

const MailHeaderComposeActions = ({ pending, handleDeleteDraft, draftStatus }) => {
  const somePending = Object.values(pending).includes(true);

  const getDraftStatusLabel = () => {
    if (draftStatus === DRAFT_STATUSES.savedNotice || draftStatus === DRAFT_STATUSES.saved) {
      return 'Saved';
    }

    if (draftStatus === DRAFT_STATUSES.pending) {
      return 'Saving...';
    }

    return 'Save Draft';
  }

  const onSubmit = () => {
    document
      .getElementById('mailComposerForm')
      .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  return (
    <>
      <Button
        type='submit'
        title='Send'
        theme={BUTTON_THEMES.inversion}
        className={classModifier('mail-header__action-btn', 'send')}
        disabled={somePending}
        pending={pending.sendMsg}
        onClick={onSubmit}
        icon={<ICONS.arrow />}
      >
        Send
      </Button>

      <Button
        className={classModifier('mail-header__action-btn', 'save')}
        disabled={draftStatus !== DRAFT_STATUSES.notSaved}
        icon={<ICONS.file />}
      >
        {getDraftStatusLabel()}
      </Button>

      <Button
        type="button"
        className={classModifier("mail-header__action-btn", 'discord')}
        title="Discard"
        onClick={() => handleDeleteDraft()}
        disabled={somePending}
        icon={<ICONS.trash />}
      >
        Discord
      </Button>
    </>
  );
};

export default MailHeaderComposeActions;
