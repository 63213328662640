import { useEffect } from 'react';

import { classModifier } from 'utils';

import './BookingGTabItem.scss';
import { useSelector } from 'react-redux';
import { usePrevious } from 'hooks';


const BookingGTabItem = (props) => {
  const {
    Date,
    Operator,
    Requirements,
    Details,
    Client,
    Escort,
    Markers,
    statusColor,
    handleOpenBookingModal,
    oppenedChatCallerId,
    setOppenedChatCallerId,
    isSameCaller,
    isDeleted,
  } = props;

  const updateOpennedChat = (event) => {
    setOppenedChatCallerId(event.detail);
  }

  useEffect(() => {
    window.addEventListener('openChat', updateOpennedChat);

    return () => {
      window.removeEventListener('openChat', updateOpennedChat);
    }
  }, [])

  return (
    <li
      className={classModifier('booking-g-tab-item', [
        isSameCaller(oppenedChatCallerId) && 'chat-opened',
        isDeleted && 'deleted',
      ])}
      onClick={handleOpenBookingModal}
      style={{ backgroundColor: statusColor }}
    >
      <div className='booking-g-tab-item__header'>
        <div className='booking-g-tab-item__row'>
          <Date isRanged />
          <Operator />
        </div>

        <div className='booking-g-tab-item__row'>
          <Markers />
          <Requirements />
        </div>
      </div>

      <div className='booking-g-tab-item__body'>
        <Details className='booking-g-tab-item__details' />
        <Escort className='booking-g-tab-item__escort' shouldOpenChat />
        <Client className='booking-g-tab-item__client' shouldOpenChat />
      </div>
    </li>
  )
}

export default BookingGTabItem;
