import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ICONS from 'assets/icons';

const TypingOperators = props => {
  // const { operatorsIds } = props;

  return <>
    <ICONS.pencil title="someone is typing" width="10" height="10"/>
    {/* <b>
      {(operatorsIds.length > 1
        ? operatorsIds.length
        : operatorsIds.map(id => (props.operators[id]).username)
      )}
    </b> */}
  </>;
};

// TypingOperators.propTypes = {
//   operatorsIds: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.number)
//   ])
// };

const mapStateToProps = state => ({
  // operators: state.operators.entities,
});

export default connect(mapStateToProps)(TypingOperators);