import moment from 'moment';

import getDateByTimezoneOffset from './getDateByTimezoneOffset';

const checkTimeRange = (before, after, userTimezone) => {
  const beforeTime = getDateByTimezoneOffset(userTimezone, moment(before, 'HH:mm').valueOf());
  const afterTime = getDateByTimezoneOffset(userTimezone, moment(after, 'HH:mm').valueOf());

  return moment(getDateByTimezoneOffset(userTimezone)).isBetween(beforeTime, afterTime);
}

export default checkTimeRange;
