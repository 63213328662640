import hash from 'object-hash';

export default (filters) => {
	// const hashOptions = {
  //   algorithm: 'md5',
  //   excludeKeys: (key) => key === 'previousFiltersKey'
  // }

  // return hash(filters, hashOptions);

  return JSON.stringify(_.pickBy(filters, _.identity));
}
