import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './AvailabilityList.scss';

import API from 'api/api';
import { classModifier } from 'utils';
import { PUBLIC_CHAT_TABS } from 'redux/ducks/roomChats';
import { addContactsToEntities } from 'redux/ducks/contacts';
import { setRoomPending, addAvailableGirls, addOffTodayGirls } from 'redux/ducks/rooms';
import {
  CONTACT_TYPES,
  CHAT_TYPES,
} from 'config/constants';
import { startOperatorsSearch, stopOperatorsSearch } from 'redux/ducks/rooms';

import Spinner from 'components/UI/Spinner/Spinner';
import Scrollbars from 'react-custom-scrollbars-2';
import PublicChatGirlsItem from '../components/PublicChatGirlsItem';
import ChatSources from 'containers/Chat/components/ChatSources/ChatSources';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';

const AvailabilityList = (props) => {
  const {availability} = props;

  const [changedByOperatorIds, setChangedByOperatorIds] = useState([]);

  const dispatch = useDispatch();

  const pending = useSelector(state => state.rooms.pending);
  const availabilityIds = useSelector((state) =>
    availability === PUBLIC_CHAT_TABS.available.id
      ? state.rooms.availableIds
      : state.rooms.offTodayIds
  );
  const activeRecipient = useSelector((state) => state.rooms.entities[state.roomChats.active]);
  const search = useSelector((state) => state.rooms.search);

  const isAvailableTab = availability === 'available';

  const request = async (search) => {
    try {
      const isOffToday = availability === PUBLIC_CHAT_TABS['off_today'].id;

      const response = await API.getGirlsWithStatus(
        isOffToday ? "off" : PUBLIC_CHAT_TABS.available.id,
        search
      );

      // if item.changed_by === 2 - contact status was changed manually by operator
      setChangedByOperatorIds(response.data.filter(item => item.changed_by !== 2).map(item => item.caller_id));
      dispatch(addContactsToEntities(response.data.map((item) => item.caller)));
      
      isOffToday
        ? dispatch(addOffTodayGirls(response.data))
        : dispatch(addAvailableGirls(response.data));
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    dispatch(setRoomPending());

    request(search);
  }, [search]);

  const renderChatTimeline = () => {
    if (pending) {
      return (
        <div className="availability-list__pending">
          <Spinner spinnerSize="30px"/>
        </div>
      )
    };
  
    if (!availabilityIds.length) {
      return (
        <span className="availability-list__no-items">
          no items
        </span>
      )
    };

    return (
      <ul className="availability-list__list">
        <Scrollbars
          autoHide
          renderThumbVertical={CustomScrollbarThumb}
          renderTrackVertical={CustomScrollbarTrack}
        >
          {availabilityIds.map(id => (
            <li
              className="availability-list__item"
              key={id}
            >
              <PublicChatGirlsItem
                id={id}
                isFromListOfGirlsWithStatus
                isSimpleView
                type={CONTACT_TYPES.GIRL}
                isChangedStatusByOperator={!changedByOperatorIds.includes(id)}
              />
            </li>
          ))}
        </Scrollbars>
      </ul>
    )
  }

  const titleAvailabilityList = {available: 'Available', off_today: "Off/Busy"}

  return (
    <div className="availability-list">
      <div className={classModifier("availability-list__header", isAvailableTab && 'is-available')}>
        <div className="availability-list__header-top">
          <h3 className="availability-list__title">
            {titleAvailabilityList[availability]} ({availabilityIds.length})
          </h3>

          {isAvailableTab && 
            <CopyToClipboardButton
              className='availability-list__copy-link-btn'
              value={'https://www.divaescort.com/girls/available-now'}
              title='Copy link to available gallery'
            />
          }
        </div>
      </div>

      <ChatSources
        type={CHAT_TYPES.ROOM}
        activeRecipient={activeRecipient}
        search={search}
        startMessageSearch={(query) => dispatch(startOperatorsSearch(query))}
        stopMessageSearch={() => dispatch(stopOperatorsSearch())}
      />
      
      {renderChatTimeline()}
    </div>
  );
};

export default AvailabilityList;
