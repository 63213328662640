import List from 'components/List/List'
import { useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteAllMailingMessages, getAllMailingMessages } from 'redux/ducks/mailing';

import './MailingHistory.scss';
import MailingHistoryItem from '../MailingHistoryItem/MailingHistoryItem';

const MailingHistory = memo(({ formRef, choosenGroups, actualUsersCount }) => {
  const messages = useSelector((state) => state.mailing.messages);
  const loadingMessage = useSelector((state) => state.mailing.loadingMessage);
  const pending = useSelector((state) => state.mailing.pending)
  const sendingPending = useSelector((state) => state.mailing.sendingPending);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMailingMessages());

    return () => {
      dispatch(deleteAllMailingMessages());
    }
  }, [])

  const loadMore = useCallback((offset) => {
    dispatch(getAllMailingMessages(offset));
  }, []);

  return (
    <List
      limit={20}
      list={sendingPending ? [...messages, loadingMessage] : messages}
      classPrefix='mailing-log'
      listLoadPending={pending}
      absentLabel="No messages"
      scrollInitialPosition='bottom'
      listItem={MailingHistoryItem}
      loadMore={loadMore}
    >
      <MailingHistoryItem
        formRef={formRef}
        choosenGroups={choosenGroups}
        actualUsersCount={actualUsersCount}
        statusOk={!sendingPending}
      />
    </List>
  )
})

export default MailingHistory;
