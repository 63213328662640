import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'redux/ducks/activeWindows';
import { selectPropertyOfActiveSession, selectUserTimezone } from 'redux/selectors/selectors';

import './ProfilesBooked.scss';
import ICONS from 'assets/icons';
import List from 'components/List/List';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import BookingItem from 'components/BookingItem/BookingItem';


const ProfilesBooked = props => {
  const dispatch = useDispatch()
  const bookedIds = useSelector(state => selectPropertyOfActiveSession(state, 'bookedIds'));
  const userHour12 = useSelector((state) => state.user.hour12);
  const userTimezone = useSelector((state) => selectUserTimezone(state));

  useEffect(() => {
    if (!bookedIds || !bookedIds.length) {
      dispatch(closeModal());
    }
  }, [bookedIds]);

  return (
    <div className="booked-zone">
      <div className="booked-zone__header">
        <p className="booked-zone__text">
          Booked {bookedIds.length}
        </p>
        <button className="booked-zone__btn-esc">
          Esc
          <ICONS.close 
            className="booked-zone__icon-close" 
            onClick={() => dispatch(closeModal())}
          />
        </button>
      </div>

      <List
        list={bookedIds}
        scrollInitialPosition="top"
        classPrefix="booked-zone"
        scrollbarProps={{
          autoHide: true,
          renderTrackVertical: CustomScrollbarTrack,
          renderThumbVertical: CustomScrollbarThumb,
        }}
      >
        <BookingItem	
          isSalesSession
          userHour12={userHour12}
          userTimezone={userTimezone}
          sessionId={props.sessionId}
          classNameModifier='profiles-booked'
        />
      </List>
    </div>
  );
};

export default ProfilesBooked;
