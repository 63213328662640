import { CONTACT_TYPES, EMAIL_REGEX, NUMBER_REGEX } from 'config/constants';
import NotForTag from '../components/NotForTag/NotForTag';
import SeeNotesTag from '../components/SeeNotesTag/SeeNotesTag';

export const SPECIAL_TAG_KEYS = {
  SEE_NOTES: 'see-notes',
  NOT_FOR: 'not-for',
  DEBTPRICE: 'debt-price',
  DEBT: 'debt',
  TEMPORARY: 'temporary',
  MEMBER: 'member',
  OK: 'ok!',
  REVIEW_GUY: 'review-guy',
  BLACK_LIST: 'blacklist',
  TIME_WASTER: 'time-waster',
  BLACK_GUY: 'black-guy',
}

const SPECIAL_TAG_IDS_FOR_DEV = {
  [CONTACT_TYPES.CLIENT]: {
    [SPECIAL_TAG_KEYS.SEE_NOTES]: 379,
    [SPECIAL_TAG_KEYS.NOT_FOR]: 444,
    [SPECIAL_TAG_KEYS.TEMPORARY]: 386,
    [SPECIAL_TAG_KEYS.MEMBER]: 354,
    [SPECIAL_TAG_KEYS.DEBT]: -1,
    [SPECIAL_TAG_KEYS.BLACK_LIST]: 299,
    [SPECIAL_TAG_KEYS.BLACK_GUY]: 455,
    [SPECIAL_TAG_KEYS.OK]: 454,
    [SPECIAL_TAG_KEYS.TIME_WASTER]: 403,
    [SPECIAL_TAG_KEYS.REVIEW_GUY]: 456,
  },
  [CONTACT_TYPES.GIRL]: {
    [SPECIAL_TAG_KEYS.NOT_FOR]: 186,
    [SPECIAL_TAG_KEYS.SEE_NOTES]: 245,
    [SPECIAL_TAG_KEYS.TEMPORARY]: 252,
    [SPECIAL_TAG_KEYS.DEBT]: -1,
  }
}

const SPECIAL_TAG_IDS_FOR_PROD = {
  [CONTACT_TYPES.CLIENT]: {
    [SPECIAL_TAG_KEYS.SEE_NOTES]: 513,
    [SPECIAL_TAG_KEYS.NOT_FOR]: 502,
    [SPECIAL_TAG_KEYS.TEMPORARY]: 519,
    [SPECIAL_TAG_KEYS.MEMBER]: 496,
    [SPECIAL_TAG_KEYS.DEBT]: -1,
    [SPECIAL_TAG_KEYS.BLACK_LIST]: 480,
    [SPECIAL_TAG_KEYS.BLACK_GUY]: 479,
    [SPECIAL_TAG_KEYS.OK]: 504,
    [SPECIAL_TAG_KEYS.TIME_WASTER]: 520,
    [SPECIAL_TAG_KEYS.REVIEW_GUY]: 507,
  },
  [CONTACT_TYPES.GIRL]: {
    [SPECIAL_TAG_KEYS.NOT_FOR]: 543,
    [SPECIAL_TAG_KEYS.TEMPORARY]: 549,
    [SPECIAL_TAG_KEYS.DEBT]: -1,

  }
}

export const SPECIAL_TAG_IDS = process.env.NODE_ENV === 'production' ? SPECIAL_TAG_IDS_FOR_PROD : SPECIAL_TAG_IDS_FOR_DEV;

export const CUSTOM_TAGS_COMPONENTS = {
  [SPECIAL_TAG_KEYS.SEE_NOTES]: SeeNotesTag,
  [SPECIAL_TAG_KEYS.NOT_FOR]: NotForTag,
}

export const HIDDEN_TAGS = [SPECIAL_TAG_KEYS.DEBT]

export const DYNAMIC_TAGS = { // tags that are created based on the contact data
  [SPECIAL_TAG_KEYS.SEE_NOTES]: {
    condition: (contact) => /see notes/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.MEMBER]: {
    condition: (contact) => /member/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.OK]: {
    condition: (contact) => /ok!/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.REVIEW_GUY]: {
    condition: (contact) => /review guy/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.BLACK_LIST]: {
    condition: (contact) => /blacklist/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.TIME_WASTER]: {
    condition: (contact) => /time waster/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.BLACK_GUY]: {
    condition: (contact) => /black guy/i.test(contact?.fn),
  },
  [SPECIAL_TAG_KEYS.DEBTPRICE]: {
    condition: (contact) => contact?.debt,
    title: (contact) => `debt ${contact.debt}`,
    isImportant: true,
  },
  [SPECIAL_TAG_KEYS.TEMPORARY]: {
    condition: (contact) => (
      (contact.fn.match(NUMBER_REGEX) || contact.fn.match(EMAIL_REGEX)) && !contact.description && !contact.category
    ),
  },
  [SPECIAL_TAG_KEYS.NOT_FOR]: {
    condition: (contact) => contact?.not_for_clients && contact.not_for_clients.length,
    isCapitalized: true
  }
}
