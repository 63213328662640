import { useDispatch } from 'react-redux';

import { CENTER_COORDINATES, DEFAULT_ZOOM, MAP_GIRL_FILTERS, MAP_PRIMARY_MODES, updateCenter, updateFilter, updatePrimaryMode, updateZoom } from 'redux/ducks/map';

import ICONS from 'assets/icons';


const AllEscortsButton = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(updatePrimaryMode(MAP_PRIMARY_MODES.ALL_ESCORTS))
    dispatch(updateFilter(MAP_GIRL_FILTERS.CHAT_ESCORTS));
    dispatch(updateZoom(DEFAULT_ZOOM));
    dispatch(updateCenter(CENTER_COORDINATES));
  }

  return (
    <button
      className="girls-map__toggle-btn"
      onClick={handleClick}
    >
      Show All Escorts
      <ICONS.puzzle className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default AllEscortsButton;
