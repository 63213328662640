import React, { useState, useEffect } from 'react';

import ICONS from 'assets/icons';
import { usePrevious } from 'hooks';
import { classModifier } from 'utils';
import { ALL_FILTER } from 'redux/ducks/contacts';
import { INPUT_THEMES, MAIN_CLIENT_FILTERS, MAIN_GIRLS_FILTERS } from 'config/constants';
import useActiveOperators from 'hooks/useActiveOperators';

import './SidebarContactsFilters.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import SidebarContactsSelectFilter from './SidebarContactsSelectFilter';

const classPrefix = 'sidebar-contacts-filters';

const convertOperatorToFilterObject = (operator) => ({
  name: String(operator.id),
  value: () => operator.alias || operator.username
})

const MainFilter = ({
  modificator,
  topCounter = 0,
  bottomCounter = 0,
  isActive,
  onClick,
  children
}) => (
  <button
    className={classModifier(`${classPrefix}__main-filter`, [
      isActive && 'active',
      modificator,
      isNaN(+modificator) ? 'main' : 'operator'
    ])}
    onClick={onClick}
    disabled={isActive}
  >
    <span className={`${classPrefix}__main-filter-content`}>
      {children}
    </span>
    
    {Boolean(topCounter || bottomCounter) && (
      <div className={`${classPrefix}__main-filter-counters`}>
        {!!topCounter && (
          <mark
            className={classModifier(`${classPrefix}__main-filter-counter`, 'all')}
          >
            {topCounter}
          </mark>
        )}
    
        {!!bottomCounter && (
          <mark
            className={classModifier(`${classPrefix}__main-filter-counter`, 'unread')}
          >
            {bottomCounter > 99 ? '99+' : bottomCounter}
          </mark>
        )}
      </div>
    )}
  </button>
)

const SidebarContactsFilters = ({
  initialQuery,
  activeTab,
  activeSelectFilter,
  searchSource,
  filtersCounters = {},
  ...props
}) => {
  const [query, setQuery] = useState(initialQuery || '');
  const activeOperatorsNames = useActiveOperators(filtersCounters, convertOperatorToFilterObject)
  
  const prevActiveTab = usePrevious(activeTab);
  const isContactsSearch = searchSource === 'contacts';
  
  let mainFilters;
  let secondaryFilters;
  let activeFilter;

  if (activeTab === 'clients') {
    mainFilters = MAIN_CLIENT_FILTERS;
    secondaryFilters = activeOperatorsNames;
    activeFilter = props.clientsMainActiveFilter;
  } else {
    mainFilters = MAIN_GIRLS_FILTERS;
    secondaryFilters = [];
    activeFilter = props.girlsMainActiveFilter;
  }

  useEffect(() => {
    if ((query && activeSelectFilter !== ALL_FILTER) || (activeTab !== prevActiveTab)) {
      setQuery(initialQuery);
    }
  }, [activeTab, activeSelectFilter]);

  return (
    <>
      <div className={`${classPrefix}__main-filters-wrap`}>
        {
          [mainFilters, secondaryFilters].map((filters, i) => (
            <div
              key={i}
              className={classModifier(`${classPrefix}__filters-wrap`, i === 1 && 'operators')}
            >
              {filters.map((filter) => {
                const topCounter = filtersCounters[filter.name]?.topCounter;
                const bottomCounter = filtersCounters[filter.name]?.bottomCounter;

                return (
                  <MainFilter
                    key={filter.name}
                    modificator={filter.name}
                    topCounter={topCounter?.length ?? topCounter}
                    bottomCounter={bottomCounter?.length ?? bottomCounter}
                    isActive={activeFilter === filter.name}
                    onClick={() => props.toggleMainFilter(activeTab, filter.name)}
                  >
                    <filter.value />
                  </MainFilter>
                )
              })}
            </div>
          ))
        }
      </div>

      <SearchInput
        theme={INPUT_THEMES.inversion}
        inputWrapClassName={`${classPrefix}__input-wrap`}
        inputClassName={`${classPrefix}__input`}
        startSearch={props.startSearch}
        stopSearch={props.stopSearch}
        showSearchBtn
        showClearBtn
        query={query}
        iconSize="medium"
        setQuery={setQuery}
      >
        {(searchClearButtons) => (
          <>
            <button
              className={`${classPrefix}__toogle-btn-container`}
              onClick={() => props.toggleSearchSource(activeTab)}
            >
              <div className={`${classPrefix}__toggle-btn-icon-wrap`}>
                {isContactsSearch
                  ? <ICONS.comments className={`${classPrefix}__toggle-btn-icon`} />
                  : <ICONS.users className={`${classPrefix}__toggle-btn-icon`} />
                }
              </div>
            
              <div className={classModifier(`${classPrefix}__toggle-btn-icon-wrap`, 'active')}>
                {isContactsSearch
                  ? <ICONS.users className={`${classPrefix}__toggle-btn-icon`} />
                  : <ICONS.comments className={`${classPrefix}__toggle-btn-icon`} />
                }
              </div>
            </button>

            {searchClearButtons}
          </>
        )}
      </SearchInput>

      <SidebarContactsSelectFilter
        isQuery={!!query.length}
        setQuery={setQuery}
        filters={props.filters}
        contactType={props.contactType}
        defaultFilter={activeSelectFilter}
      />
    </>
  );
}

export default React.memo(SidebarContactsFilters);
