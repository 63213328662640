import React from 'react';
import { connect } from 'react-redux';

import ConferenceItem from 'components/ConferenceItem/ConferenceItem';


const ActiveConference = ({ activeConference, ...props }) => {
  if (!activeConference.id) {
    return null; // no activeConference
  }

  return (
    <ConferenceItem
      active
      conference={activeConference} />
  );
};

const mapStateToProps = state => ({
  activeConference: state.conferences.activeConference,
  profileId: state.user.id
});

export default connect(mapStateToProps)(ActiveConference);