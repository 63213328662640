import React, { useMemo } from 'react';

import uuid from 'react-tabs/lib/helpers/uuid';

import "./GirlsList.scss";
import Scrollbars from 'react-custom-scrollbars-2';
import Spinner from 'components/UI/Spinner/Spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExpandedContactBlock, { height as contactHeight } from '../ExpandedContactBlock/ExpandedContactBlock';
import classNames from 'classnames';


const GirlsList = (props) => {
  const {
    className,
    items,
    title,
    isLoading,
    hasMore,
    fetchNextPage,
    onItemClick,
  } = props;

  const scrollbarsViewId = useMemo(() => uuid(), []);
  const itemsLength = items?.length || 0;
  const itemsLengthForHeightCalculation = itemsLength < 3 ? itemsLength : 3;
  const itemsGap = 10;

  const totalHeight = itemsLengthForHeightCalculation * contactHeight + itemsGap * (itemsLengthForHeightCalculation - 1);

  const scrollbarsRenderProps = {
    renderView: props => <div {...props} id={scrollbarsViewId} />,
    renderTrackVertical: props => <div {...props} className="track-vertical" />,
    renderThumbVertical: props => <div {...props} className="thumb-vertical" />,
  }

  const bottomSpinner = (
    <Spinner
      spinnerSize={36}
      className='search-list__bottom-spinner'
    />
  );

  return (
    <div className={classNames(className, "girls-list")}>
      <h6 className="girls-list__title">{title}</h6>

      {isLoading 
        ? <div className="girls-list__spinner-wrapper"><Spinner spinnerSize={35}/></div>
        : <ul className="girls-list__contacts-container" style={{ height: totalHeight }}>
            <Scrollbars {...scrollbarsRenderProps}>
              <InfiniteScroll
                className='girls-list__infinite-scroll'
                scrollableTarget={scrollbarsViewId}
                dataLength={itemsLength}
                next={fetchNextPage}
                hasMore={hasMore}
                loader={bottomSpinner}
                style={{ gap: itemsGap }}
              >
                {items?.map((item, id) => (
                  <ExpandedContactBlock
                    className="girls-list__contact"
                    key={id}
                    item={item}
                    onClick={onItemClick}
                  />
                ))}
              </InfiniteScroll>
            </Scrollbars>
          </ul>
      }
    </div>
  )
};

export default React.memo(GirlsList);
