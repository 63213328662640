import { useSessionState } from 'hooks/useSessionState';
import { getNotificationProps } from 'utils/getNotiifcationProps';

import { NotificationBlock } from 'components/UI/NotificationBlock/NotificationBlock';


export const SessionNotification = ({ extraActionAfterOpen, extraButtons, ...props }) => {
  const sessionId = getNotificationProps(props.item, 'sessionId');
  const sessionState = useSessionState(sessionId);

  return (
    <NotificationBlock
      isDeprecated={sessionState.status === 'closed'}
      deprecatedLabel="SESSION DELETED"
      {...props}
    >
			<NotificationBlock.Body
        isAvatarRendered={props.isAvatarRendered}
        getRenderedAvatar={props.getRenderedAvatar}
      >
				{props.getText}
      </NotificationBlock.Body>

      <NotificationBlock.Footer iconType={props.iconType}>
        {extraButtons}
      </NotificationBlock.Footer>
    </NotificationBlock>
  )
};
