import { useDispatch, useSelector } from 'react-redux';
import React, { memo } from 'react';

import { updateRevealedMode } from 'redux/ducks/bookings';

import "./CalendarWeekView.scss";
import CalendarWeekViewList from './components/CalendarWeeViewList/CalendarWeekViewList';
import ICONS from 'assets/icons';
import {
  BookingFiltersList,
  BookingStatusFilters,
  BookingFilters,
  BookingSelectFilters
} from 'components/FilteredBookings';

const CalendarWeekViewHeader = ({ getters }) => {
  const {
    getFiltersContainerProps,
    getStatusFilterProps,
    getFilterProps,
    getSelectProps,
  } = getters;

  const isAllRevealed = useSelector((state) => state.bookings.revealed);

  const dispatch = useDispatch();

  return (
    <BookingFiltersList
      className="calendar-week__header"
      {...getFiltersContainerProps()}
    >
      <button className="calendar-week__reveal-btn" onClick={() => dispatch(updateRevealedMode())}>
        {isAllRevealed
          ? <ICONS.nineDashes width={29} height={29} />
          : <ICONS.nineDots width={29} height={29} />
        }
      </button>

      <BookingStatusFilters {...getStatusFilterProps()}>
        <BookingStatusFilters.todo />
        <BookingStatusFilters.confirmed />
        <BookingStatusFilters.done />
        <BookingStatusFilters.cancelled />
        <BookingStatusFilters.tosort />
      </BookingStatusFilters>

      <BookingFilters
        className="calendar-week__filters"
        {...getFilterProps()}
      >
        <BookingFilters.prebooking />
        <BookingFilters.outcall />
        <BookingFilters.duo />
        <BookingFilters.agent />
      </BookingFilters>

      <BookingSelectFilters.agent
        className="calendar-week__select-filter"
        {...getSelectProps()}
      />
    </BookingFiltersList>
  )
}

const CalendarWeekView = ({
  getFilteredBookings,
  date,
  getters,
  loadFilteredBookings,
  actualDate,
  getExtraBookingsState,
}) => {
  const {
    getDateChangerProps,
    getBookingsState,
    getPending,
    getBookingsCount,
  } = getters;

  return (
    <div className="calendar-week">
      <CalendarWeekViewHeader getters={getters} />

      <CalendarWeekViewList
        dates={date}
        getPending={getPending}
        getBookingsState={getBookingsState}
        loadMoreBookings={getFilteredBookings}
        getBookingsCount={getBookingsCount}
        getters={getters}
        loadFilteredBookings={loadFilteredBookings}
        actualDate={actualDate}
        getExtraBookingsState={getExtraBookingsState}
        {...getDateChangerProps()}
      />
    </div>
  );
}

export default memo(CalendarWeekView);
