import React, { useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ICONS from 'assets/icons';
import { selectObjectOperators } from 'redux/selectors/selectors';
import { classModifier, convertNumberToSeconds } from 'utils';
import { useWillUnmount, useDidUpdate } from 'hooks';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import { getSessionById } from 'redux/ducks/sessions';

import './SystemMsg.scss';
import DateTime from 'components/DateTime';
import Spinner from 'components/UI/Spinner/Spinner';
import AttachmentEmail from '../../../ChatAttachments/AttachmentEmail';
import { BookingItemNew } from 'components/BookingItemNew';

const SystemMsg = (props) => {
  const {
    userId,
    booking,
    interaction,
    subjectOperator,
    objectOperators,
    updateBookingsPending,
    returnOnlyMsgString = false,
  } = props;

  const chatUpdate = interaction.attachments && interaction.attachments.chatUpdate;

  const isBooking = chatUpdate && (
    chatUpdate.action === "newBooking" || chatUpdate.action === 'newDuoBooking' ||
    chatUpdate.action === "futureBookingActive"
  );

  let isUnmount = useRef(false);

  useDidUpdate(() => {
    if (
      chatUpdate &&
      chatUpdate.sessionId &&
      (chatUpdate.action === 'futureBookingActive' || chatUpdate.action === 'newBooking' || chatUpdate.action === 'newDuoBooking')
    ) {
      props.getSessionById(chatUpdate.sessionId)
        // .then(res => setSession(res.data))
        .catch(console.error)
    }
  }, [interaction]);

  useWillUnmount(() => isUnmount.current = true);

  const convertCallDuration = (duration) => {
    if (duration === null || duration === 0) return '';

    const timeString = convertNumberToSeconds(+duration)
      .split(':')
      .filter((item, index) => (index === 0 && item !== '00') || index > 0)
      .join(':');

    if (timeString[0] === '0') return `(${timeString.slice(1)})`;

    return `(${timeString})`;
  };

  const renderSystemMsg = () => {
    // types 15 and 16 are softhphones
    if (interaction.type === 1 || interaction.type === 15) {  // incoming call
      const interactionText = interaction.type === 15
        ? interaction.body
        : 'Incoming Call';

      return {
        timeAgo: interaction.dateCreated,
        text: `${interactionText} ${convertCallDuration(interaction.duration)}`,
        icon: <ICONS.incCall className={classModifier('system-msg__icon', 'inc-call')} />,
      };
    }

    if (interaction.type === 2 || interaction.type === 16) {  // outgoing call
      return {
        timeAgo: interaction.dateCreated,
        text: `Outgoing ${interaction.type === 16 && 'IP'} Call ${convertCallDuration(interaction.duration)}`,
        icon: <ICONS.outCall className={classModifier('system-msg__icon', 'out-call')} />,
      };
    }

    if (!interaction.attachments) {
      return { text: interaction.body };
    }

    switch (chatUpdate.action) {
      case 'edit': {
        const selectEdited = () => {
          let stringEditedMeta = null;

          if (chatUpdate.edit.title) {
            stringEditedMeta = `room title to ${chatUpdate.edit.title}.`;
          }
          if (chatUpdate.edit.photo) {
            if (stringEditedMeta) {
              stringEditedMeta = stringEditedMeta + ' Room photo was updated.';
            }
            else {
              stringEditedMeta = 'room photo';
            }
          }
          return stringEditedMeta;
        };

        return { text: `${(subjectOperator && subjectOperator.username) || `You`} changed ${selectEdited()}` };
      }

      case 'removeUsers': {
        const operatorsStr = objectOperators.map(operator => operator.username).join(', ');

        return {
          text: `${(subjectOperator?.username) || `You`} removed  ${(operatorsStr) || 'You'} from room.`
        };
      }

      case 'leave': {
        const nameLeaved = (subjectOperator && subjectOperator.username) || 'You';

        return { text: `${nameLeaved} leaved chat room.` };
      }

      case 'addUser': {
        const addedOperators = objectOperators
          .map(operator => {
            if (operator === undefined) {
              return 'You';
            }
            return operator.username;
          })
          .join(', ');

        return {
          text: `Users ${addedOperators} was added to chat by ${(subjectOperator && subjectOperator.username) || `You`} `
        };
      }

      case 'chatCreated': {
        return { text: `${(subjectOperator && subjectOperator.username) || `You`} create chat.` };
      }

      case 'updateNumber': {
        const chatSource = chatUpdate.edit.title.split(' ').pop();

        let icon;
        if (chatSource === 'Telegram') {
          icon = <ICONS.telegram className={classModifier('system-msg__icon', 'telegram')} />
        } else if (chatSource === 'SMS') {
          icon = <ICONS.comments className={classModifier('system-msg__icon', 'sms')} />
        } else if (chatSource === 'Whatsapp') {
          icon = <ICONS.whatsapp className={classModifier('system-msg__icon', 'whatsapp')} />
        } else if (chatSource === 'Iphone') {
          icon = <ICONS.apple className={classModifier('system-msg__icon', 'iphone')} />
        }

        let msg = ''
        if ((subjectOperator === undefined) && interaction.attachments.chatUpdate.userId) {
          msg = `You ${chatUpdate.edit.title}`
        }
        else if (subjectOperator && subjectOperator.username) {
          msg = `${subjectOperator.username} ${chatUpdate.edit.title}`
        }
        else {
          msg = `${chatUpdate.edit.title}`;
        }

        return {
          text: msg,
          icon,
        }
      }
      case 'updateEmail': {
        let msg = ''
        if ((subjectOperator === undefined) && interaction.attachments.chatUpdate.userId) {
          msg = `You ${chatUpdate.edit.title}`
        }
        else if (subjectOperator && subjectOperator.username) {
          msg = `${subjectOperator.username} ${chatUpdate.edit.title}`
        }
        else {
          msg = `${chatUpdate.edit.title}`;
        }

        return {
          text: msg,
        }
      }
      case 'session_delete':
      case 'session_start':
      case 'session_close': {
        // const isSessionStart = chatUpdate.action === 'session_start';

        const getActionTitle = () => {
          switch (chatUpdate.action) {
            case 'session_start':
              return 'Started'
            case 'session_close':
              return 'Closed'
            case 'session_delete':
              return 'Removed'
            default:
              return 'unknown action';
          }
        }
        let operatorName = '';

        if (!subjectOperator && !chatUpdate.userId) {
          operatorName = 'Unknown operator';
        }
        else if (!subjectOperator) {
          operatorName = 'You';
        }
        else {
          operatorName = subjectOperator.username;
        }

        return {
          text: 'Session ' + getActionTitle() + ` by ${operatorName}`,
          timeAgo: interaction.dateCreated,
          icon: <ICONS.puzzle className={classModifier('system-msg__icon', `${chatUpdate.action.slice(8)}-session`)} />
        }
      }
      case "session_assistance":
      case "session_assistance_delete":
      case "session_transfer": {
        const [who, whom] = objectOperators.map((operator, index) => {
          if (!operator) {
            return +chatUpdate.usersIds[index] === userId
              ? 'You'
              : 'DELETED_OPERATOR';
          }
          return operator.username;
        });

        const getMsg = () => {
          switch (chatUpdate.action) {
            case "session_assistance":
              return `${who} added ${whom} to session`;
            case "session_assistance_delete":
              return `${who} deleted ${whom} from session`;
            case "session_transfer":
              return `${who} transferred session to ${whom}`;
          }
        }

        return {
          text: getMsg(),
          timeAgo: interaction.dateCreated,
          icon: <ICONS.puzzle className={classModifier('system-msg__icon', 'transfer-session')} />
        }
      }

      case "newBooking":
      case "newDuoBooking": {
        if (returnOnlyMsgString) {
          return { text: `New booking was created by ${(subjectOperator && subjectOperator.username) || `You`}` }
        }
        const attachment = (
          <BookingItemNew
            type={BOOKING_ITEM_VIEWS.ATTACHMENT}
            item={booking}
          />
        )
        const icon = (
          <ICONS.booking
            className={classModifier('system-msg__icon', booking ? 'booking' : 'booking-removed')}
          />
        )

        return {
          icon,
          timeAgo: booking?.date_created,
          attachment: booking ? attachment : null,
          text: booking ? 'Booked' : 'Booking was removed',
        }
      }

      // case "futureBookingActive": {
      //   if (returnOnlyMsgString) {
      //     return { text: "Future booking is available" }
      //   }
      //   const attachment = (
      //     <AttachmentBooking
      //       chatUpdate={chatUpdate}
      //       subjectOperator={subjectOperator}
      //       sessionId={chatUpdate.sessionId}
      //       booking={booking}
      //       isFutureBookingAvailable
      //     />
      //   )

      //   return { attachment, timeAgo: booking?.date_created };
      // }

      case "newReminder": {
        return { text: `${(subjectOperator && subjectOperator.username) || `You`} create new msgs reminder` };
      }

      case "newMail": {
        const { mail } = chatUpdate;

        return {
          timeAgo: mail.created_at,
          attachment: <AttachmentEmail email={mail} />,
          icon: <ICONS.mailLines className={classModifier('system-msg__icon', 'mail')} />,
        }
      }

      // case "incoming_glossary_message": {
      //   return {
      //     text: "auto processed activity",
      //     timeAgo: interaction.dateCreated,
      //     icon: <ICONS.messageWarn className={classModifier('system-msg__icon', 'glossary')} />,
      //   };
      // }

      case "finished": {
        return {
          text: "Finished",
          timeAgo: interaction.dateCreated,
          icon: <div className={classModifier('system-msg__icon', 'finished')}></div>
        }
      }

      case "started": {
        return {
          text: "Started",
          timeAgo: interaction.dateCreated,
          icon: <div className={classModifier('system-msg__icon', 'started')}></div>
        }
      }

      case "updateLock": {
        return {
          text: chatUpdate.edit.title,
        }
      }

      case "bookingFromBot": {
        return {
          text: chatUpdate.edit.title,
        }
      }

      default:
        return { text: interaction.body };
    }
  };

  const sysMsg = renderSystemMsg();

  if (returnOnlyMsgString) {
    return renderSystemMsg();
  }

  return (
    <div className="system-msg">
      {(updateBookingsPending && isBooking && !booking) || (booking && !booking.group_girls)
        ? (
          <div className='system-msg__booking-pending'>
            <Spinner spinnerSize={30} />
          </div>
        ) : (
          <>
            {(sysMsg.text || sysMsg.timeAgo) &&
              <div className={classModifier('system-msg__title', sysMsg.attachment && 'with-attachment')}>
                {(sysMsg.text || sysMsg.timeAgo) &&
                  <p className="system-msg__text">
                    {sysMsg.timeAgo &&
                      <DateTime
                        className='system-msg__timeago'
                        date={sysMsg.timeAgo}
                        relative
                      />
                    }

                    {sysMsg.text}
                  </p>
                }

                {sysMsg.icon}
              </div>
            }

            {sysMsg.attachment &&
              <div className="system-msg__attachment">{sysMsg.attachment}</div>
            }
          </>
        )
      }
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const interactionBooking = ownProps.interaction.attachments?.chatUpdate.bookingId || [];
  const bookingIds = Array.isArray(interactionBooking) ? interactionBooking : [interactionBooking];

  return {
    userId: state.user.id,
    booking: state.bookings.entities[bookingIds[0]],
    sessionOperator: state.operators.entities[state.sessions.entities[ownProps.sessionId]?.usersIds[0]],
    updateBookingsPending: state.bookings.updateBookingsPending,
    subjectOperator: ownProps.interaction.attachments
      ? state.operators.entities[ownProps.interaction.attachments.chatUpdate.userId]
      : null,
    objectOperators: ownProps.interaction.attachments
      ? selectObjectOperators(state, ownProps.interaction.attachments.chatUpdate.usersIds)
      : null,
  };
};

const mapDispatchToProps = {
  getSessionById,
};

SystemMsg.propTypes = {
  interaction: PropTypes.object,
  subjectOperator: PropTypes.object,
  objectOperators: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemMsg);

