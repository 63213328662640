import React from 'react';

import { IMAGE_FORMAT_REGEX, VIDEO_FORMAT_REGEX } from 'config/constants';
import ICONS from 'assets/icons';

import './MailAttachment.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import logo from 'assets/images/logo-commd-110.png'

const MailAttachment = ({
  url,
  fileName,
  fileExtension,
  loading,
  onDelete,
  disableDelete,
}) => {

  if (loading) {
    return (
      <div className="mail-attachment__spinner">
        <Spinner spinnerSize={20} />
      </div>
    );
  }

  return (
    <li className="mail-attachment">
      {IMAGE_FORMAT_REGEX.test(fileExtension) ? (
        <LazyLoadImage
          src={url}
          data-img-src={url}
          data-attach-img
          className="mail-attachment__preview"
          alt="msg img"
          spinnerSize={20}
        />
      ) : VIDEO_FORMAT_REGEX.test(fileExtension) ? (
        <VideoThumbnail
          spinnerSize={40}
          src={url}
          hideDuration
          className="mail-attachment__preview"
        />
      ) : (
        <img
          className="mail-attachment__preview"
          src={logo}
          alt="CommD Logo"
        />
      )}

      <div className="mail-attachment__info">
        <p className="mail-attachment__name" title={fileName}>
          {fileName}
        </p>

        <button
          className="mail-attachment__remove"
          onClick={onDelete}
          type="button"
          disabled={disableDelete}
        >
          <ICONS.close />
        </button>
      </div>
    </li>
  );
}

export default MailAttachment;
