import { memo, useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Overflow from 'rc-overflow';
import { v4 } from 'uuid';

import { useDeepEffect } from 'hooks';
import { TOOLTIP_THEMES } from 'config/constants';

import './OverflowedTags.scss';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import TagBlock from 'components/UI/TagBlock/TagBlock';

const classPrefix = 'overflowed-tags';
const gap = 5;
const maxExpandBtnWidth = 35;

const getSortedTags = (tagList) => {
  return tagList.sort((item1, item2) => {
    return item1.title.length - item2.title.length;
  })
}

const getRows = (parentElem, items, rowsLimit) => {
  const parentStyles = getComputedStyle(parentElem);
  const parentWidth = parentElem.clientWidth -
    parseInt(parentStyles.paddingRight) - 
    parseInt(parentStyles.paddingLeft);

  const tagsWidth = items.map(tag => {
    const span = document.createElement('span');
    span.setAttribute('class', `${classPrefix}__tag`);
    span.setAttribute('style', 'position: absolute; left: -999px; top: -999px');
    span.innerHTML = tag.title;

    document.body.appendChild(span);

    const style = getComputedStyle(span);
    const width = +parseFloat(style.width);

    span.parentNode.removeChild(span);

    return width;
  })

  const rows = {};
  let currentRow = 1;
  let currentRowWidth = 0;

  // Calculating tags per row
  for (let i = 0; i < tagsWidth.length; i++) {
    const tagWidth = tagsWidth[i];
    const isLastIndex = i === tagsWidth.length - 1;

    const addToArrayIfExist = () => {
      rows[currentRow] = rows[currentRow]
          ? [...rows[currentRow], items[i]]
          : [items[i]]
    }

    if (currentRow === rowsLimit) {
      if (currentRowWidth + tagWidth + gap + maxExpandBtnWidth <= parentWidth) {
        currentRowWidth += tagWidth + gap;
        addToArrayIfExist();
      }
      else {
        if (isLastIndex && currentRowWidth + tagWidth <= parentWidth) {
          addToArrayIfExist();
        }
        break;
      }
    }
    else {
      if (currentRowWidth + tagWidth <= parentWidth) {
        currentRowWidth += tagWidth + gap;
        addToArrayIfExist();
      }
      else {
        currentRow++;
        currentRowWidth = tagWidth + gap;
        rows[currentRow] = [items[i]]
      }
    }
  }

  return Object.values(rows);
}

const OverflowedTags = (props) => {
  const {
    className,
    tags = [],
    tagRenderer: TagRenderer,
    noTagLabel: NoTagLabel,
    responsive = false,
    rowsLimit = 2,
  } = props;


  const [rows, setRows] = useState([]);
  const [sortedTags, setSortedTags] = useState([]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
  
  const headerTagsRef = useRef();

  
  useDeepEffect(() => {
    const handleTags = () => {
      const sortedTags = getSortedTags(tags);
      const rows = getRows(headerTagsRef.current, sortedTags, rowsLimit);
      const hiddenTagsCount = sortedTags.length - rows.flat().length;
      
      setRows(rows);
      setSortedTags(sortedTags);
      setHiddenTagsCount(hiddenTagsCount);
    }
    
    !responsive && headerTagsRef.current && handleTags();
  }, [tags]);
  
  if (!tags) return null;

  const OverflowRenderer = ({ items }) => {
    const tagRenderer = useCallback((props) => (
      <TagRenderer key={v4()} {...props} className={`${classPrefix}__overflowed-tag`}/>
    ), []);

    const OverflowedTags = () => (
      <div className={`${classPrefix}__tags-tooltip`}>
        {
          items.map(tagRenderer)
        }
      </div>
    )

    return (
      <Tooltip
        className={`${classPrefix}__popup`}
        theme={TOOLTIP_THEMES.MAIN}
        text={OverflowedTags}
        interactive
      >
        <TagBlock className={`${classPrefix}__hidden-count`}>
          {items.length > 99 ? '+99' : `+${items.length}`}
        </TagBlock>
      </Tooltip>
    );
  };

  const memoizedTagRenderer = useMemo(() => TagRenderer, []);

  if (!tags.length) return <NoTagLabel />

  return (
    <div className={classNames(classPrefix, className)} ref={headerTagsRef}>
      {!responsive ? rows.map((row, idx) => (
        <div key={idx} className={`${classPrefix}__row`} style={{ gap: `${gap}px` }}>
          {row.map(tag => <TagRenderer key={tag.id} {...tag} />)}

          {(idx === rows.length - 1) && hiddenTagsCount > 0 && (
            <OverflowRenderer items={sortedTags.slice(sortedTags.length - hiddenTagsCount)} />
          )}
        </div>
      )) : (
        <Overflow
          className={`${classPrefix}__tag-container`}
          data={tags}
          renderItem={memoizedTagRenderer}
          renderRest={(items) => <OverflowRenderer items={items} />}
          maxCount='responsive'
        />
      )}
    </div>
  )

}

export default memo(OverflowedTags);

