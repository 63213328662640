import React, { useMemo } from 'react';

import { classModifier } from 'utils';
import ICONS from 'assets/icons';
import getMapCoordinatesFromLink from 'utils/getMapCoordinatesFromLink';
import isMapLink from 'utils/isMapLink';

import './ChatAttachments.scss';
import AttachmentMessage from './AttachmentMessage';
import AttachmentContact from './AttachmentContact';
import AttachmentImagesAndVideos from '../../../../components/AttachmentImagesAndVideos/AttachmentImagesAndVideos';
import AttachmentLinks from './AttachmentLinks';
import AttachmentVoiceMessage from './AttachmentVoiceMessage';
import AttachmentSession from './AttachmentSession';
import AttachmentDivaGirls from './AttachmentDivaGirls';
import AttachemntMapPreview from './AttachmentMapPreview';

const InteractionAttachments = ({ attachments, profileId }) => {
  const currImgs = useMemo(() =>
    attachments.images ? attachments.images.map(img => img.src) : [],
    []);

  const getAttachmentLinksPreview = () => {
    if (attachments.girlsInfo) {
      return <AttachmentDivaGirls girls={attachments.girlsInfo} />
    }

    if (attachments.links?.length === 1) {
      const firstLink = attachments.links[0];
      const mapCoordinates = getMapCoordinatesFromLink(attachments.links[0]);

      if (isMapLink(firstLink.site_title) && mapCoordinates) {
        return (
          <AttachemntMapPreview
            route={mapCoordinates.route}
            location={mapCoordinates.location}
            siteTitle={attachments.links[0].site_title}
          />
        );
      } else {
        return (
          <AttachmentLinks
            links={attachments.links}
            siteTitle={attachments.links[0].site_title}
          />
        )
      }
    }
  }

  const download = (e, imgUrlsList) => {
    e.preventDefault();

    imgUrlsList.forEach(src => {
      fetch(src)
        .then(response => {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            const imageName = src.split('/')
              .reverse()[0]
              .split('.')[0];

            a.href = url;
            a.download = `${imageName}.jpg`;
            a.click();
          });
        })
        .catch(console.error);
    })
  }

  return (
    <div className={`interaction__attachments ${classModifier('attachments', [
      attachments.sharedMsg && 'shared-msg'
    ])}`}>
      {currImgs.length > 1 &&
        <button
          className={classModifier("attachment-img__btn", "download-all")}
          title="download all"
          onClick={(e) => download(e, currImgs)}
        >
          <ICONS.arrow className="attachment-img__svg" />
        </button>
      }

      {attachments.voiceMsg &&
        <AttachmentVoiceMessage
          showTitle
          className="attachments__voice-message"
          voiceMsg={attachments.voiceMsg} />
      }

      {attachments.sharedMsg &&
        <AttachmentMessage
          className="attachments__msg"
          showTitle
          interaction={attachments.sharedMsg}
          profileId={profileId}
        />
      }

      {attachments.sharedCaller &&
        <AttachmentContact
          className="attachments__contact"
          contactId={attachments.sharedCaller.id}
          contactFromMsg={attachments.sharedCaller}
          showTitle
          showActions />
      }

      {(attachments.images || attachments.videos) &&
        <AttachmentImagesAndVideos
          media={[
            ...Object.values(attachments.images || {}).map(({ url }) => url),
            ...Object.values(attachments.videos || {}).map(({ url }) => url),
          ]}
          imgHeight={90}
          secondaryButton={({ src }) => (
            <button
              className={classModifier("attachment-img__btn", "download")}
              onClick={(e) => download(e, [src])}
              title="download"
            >
              <ICONS.arrow />
            </button>
          )}
        />
      }
      
      {getAttachmentLinksPreview()}

      {attachments.sharedSalesSession &&
        <AttachmentSession
          showTitle
          id={attachments.sharedSalesSession.id}
        />
      }
    </div>
  );
}


export default InteractionAttachments;
