import API from 'api/api';
import { CONTACTS_LIMIT, CONTACT_TYPES } from 'config/constants';

export default ({ query, offset, cancelToken }) => {
  return API.searchContacts(CONTACT_TYPES.GIRL, query, offset, CONTACTS_LIMIT, cancelToken)
    .then(({ data }) => {
      const filteredContacts = data.filter((contact) => contact.diva_default_id);

      return {
        newItems: filteredContacts,
        newHasMore: data.length === CONTACTS_LIMIT,
      }}
    )
    .catch(console.error);
}
