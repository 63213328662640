import React from 'react';

import './AwaySystemModal.scss';
import API from 'api/api';
import AsyncButton from 'components/AsyncButton/AsyncButton';

const AwaySystemModal = (props) => {
  const handleSubmit = () => {
    return API.changeBusyStatus('online')
      .then(() => props.closeModal())
      .catch(err => console.error(err));
  }

  return (
    <div className="away-modal">
      <h2 className="away-modal__title">You have been inactive for a while.</h2>

      <AsyncButton
        onClick={handleSubmit}
        className="away-modal__btn"
        spinnerSize={16}
      >
        I'm back
      </AsyncButton>
    </div>
  );
}

export default AwaySystemModal;
