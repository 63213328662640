import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { clearDelayedActions } from 'redux/ducks/mail';
import { enterOnTheMailPage, exitOnTheMailPage } from 'redux/ducks/operators';

import './MailPage.scss';
import MailHeader from 'containers/MailHeader/MailHeader';
import MailSidebar from 'containers/MailSidebar/MailSidebar';
import MailMain from 'containers/MailMain/MailMain';
import { useOpenCommModuleModal, useSetSocketPageStatus } from 'hooks';
import { SOCKET_PAGES_TITLES } from 'config/constants';

const MailPage = () => {
  const mailId = useSelector((state) => state.mail.defaultMailForUser?.id);

  const dispatch = useDispatch();

  useOpenCommModuleModal();
  useSetSocketPageStatus(SOCKET_PAGES_TITLES.mail);

  useEffect(() => {
    mailId && dispatch(enterOnTheMailPage(mailId));

    return () => {
      mailId && dispatch(exitOnTheMailPage(mailId));
      dispatch(clearDelayedActions());
    }
  }, [mailId])

  return (
    <div className="mail-page">
      <MailHeader />

      <div className="mail-page__bottom-wrap">
        <MailSidebar />

        <MailMain />
      </div>
    </div>
  )
}

export default MailPage;
