import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';

import './SalesSidebar.scss';

import Sessions from 'containers/Sessions/Sessions';
import ICONS from 'assets/icons';
import SessionsRequests from './components/SessionsRequests/SessionsRequests';
import OperatorsList from 'components/OperatorsList/OperatorsList';
// import SalesOperatorsList from './components/SalesOperatorsList/SalesOperatorsList';

const SalesSidebar = () => {
  return (
    <aside className="sales-sidebar">
      <div className="sales-sidebar__requests">
        <SessionsRequests />
      </div>

      <div className="sales-sidebar__sessions">
        {/* <Sessions type={{ isDefault: true }} /> */}

        <Sessions
          type={{ isMine: true, property: 'ids' }} 
          title="My Sessions"
          icon={ICONS.puzzle}
        />

        <Sessions
          type={{ isPartners: true, property: 'partnersIds' }}
          title="Partner Sessions"
        />

        <Sessions
          type={{ isAssistance: true, property: 'assistanceIds' }}
          title="Assistance Sessions"
        />
      </div>

      <div className='sales-sidebar__completed-sessions'>
        <Sessions
          type={{ property: 'completedIds' }}
          title="Completed Sessions"
          hidden
        />
      </div>
      
      {/* <Sessions type={{ isAssistance: true, property: 'assistanceIds' }} />
      <Sessions type={{ isPartners: true, property: 'partnersIds' }} />
      <Sessions type={{ isHistory: true, property: 'visitedHistoryIds' }} /> */}

      <div className="sales-sidebar__operators">
        <OperatorsList/>
      </div>
    </aside>
  );
}

export default SalesSidebar;
