
export const CHANGE_TYPING_STATUS = 'CHANGE_TYPING_STATUS';

export const debouncedChangeTypingStatus = (() => {
  let timerList = {};

  return (data, isNowDelete, target = 'chats') => dispatch => {
    const timerId = Object.values(data).join('_');

    if (timerList[timerId]) {
      clearTimeout(timerList[timerId]);
    }

    if (isNowDelete) {
      dispatch(changeTypingStatus(data, false, target));
      return;
    }

    const functionCall = (data => () =>
      dispatch(changeTypingStatus(data, false, target))
    )(data);

    timerList[timerId] = setTimeout(functionCall, 4000);
  };
})()


export const changeTypingStatus = (data, isTyping, target = 'chats') => dispatch => {
  dispatch({
    type: CHANGE_TYPING_STATUS,
    payload: { data, isTyping, target },
  });
};


const initialState = {
  chats: {},
  mails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_TYPING_STATUS: {
      const { target, data, isTyping } = action.payload;
      const { userId, typing, text, ...restData } = data;
      const fieldName = Object.values(restData).join('_');
      const typingChat = state[target][fieldName] || [];

      if (isTyping) {
        const isInTypingArray = !!typingChat
          ? typingChat
            .some(id => id === userId)
          : false

        if (isInTypingArray) return state;

        const updatedTypingIds = [...typingChat, userId];

        return {
          ...state,
          [target]: {
            ...state[target],
            [fieldName]: updatedTypingIds
          }
        }
      }
      else {
        if (!typingChat) return state;

        const updatedTypingOperatorsInChat = typingChat.filter(id => id !== userId)

        if (!updatedTypingOperatorsInChat.length) {
          const updatedTypingStatuses = { ...state[target] };

          delete updatedTypingStatuses[fieldName]

          return {
            ...state,
            [target]: updatedTypingStatuses,
          };
        }

        return {
          ...state,
          [target]: {
            ...state.typingOperators,
            [fieldName]: updatedTypingOperatorsInChat
          }
        }
      }
    }
    default:
      return state;
  }
}
