import React from 'react';

import ICONS from 'assets/icons';
import './OverlappedBookingsMsg.scss';
import { useDispatch } from 'react-redux';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

const OverlappedBookingsMsg = ({ conflictBookings, values, submitForm }) => {
  const dispatch = useDispatch()
  const handleOpenConflictsBookings = () => {
    dispatch(openModal(MODAL_TYPES.conflictingBookingsModal, {
      conflictBookings: conflictBookings,
      values: values,
      submitForm: submitForm,
    }))
  }
  return (
    <div className='overlapped-booking-msg__warning-msg-wrap'>
      <div className='overlapped-booking-msg__warning-msg'>
        It is not possible to create a booking.
        {/* You have selected unavailable dates which interlace other bookings. 
        Please check interlaced booking schedule and correct the date/time. */}
        <span 
          className='overlapped-booking-msg__warning-msg-link' 
          onClick={handleOpenConflictsBookings}
        > 
          {" "}View an existing booking
        </span>
      </div>
      <div className='overlapped-booking-msg__warning-msg-icon-wrap'>
        <ICONS.warn className='overlapped-booking-msg__warning-msg-icon'/>
      </div>
    </div>
  )
}

export default OverlappedBookingsMsg;