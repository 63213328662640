import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  Fragment
} from 'react';
import { connect } from "react-redux";

import { usePrevious, useDidUpdate } from 'hooks';
import { selectRecentTabs, selectTabsWithUnread } from "redux/selectors/selectors";
import { setClientTabs } from "redux/ducks/clientChats";
import { setGirlsTabs } from "redux/ducks/girlChats";
import { setChatTabs } from "redux/ducks/roomChats";
import { updateRecentTabs } from 'redux/ducks/contacts';
import { CHAT_TYPES } from 'config/constants';
import { LS } from 'utils';

const AdaptiveTabs = props => {
  const {
    userId,
    type,
    tabs,
    children,
    recentTabs,
    updateRecentTabs,
  } = props;
  const LSKeys = (() => {
    const keys = {};

    if (type === CHAT_TYPES.CLIENT) {
      keys.recentTabs = 'clientChatsRecentTabs';
      keys.unfixedTab = 'unfixedClientTab';
    }
    else if (type === CHAT_TYPES.GIRL) {
      keys.recentTabs = 'girlChatsRecentTabs';
      keys.unfixedTab = 'unfixedGirlTab';
    }
    else {
      keys.recentTabs = 'roomChatsRecentTabs';
      keys.unfixedTab = 'unfixedChatTab';
    }

    return keys;
  })();

  const [actualTabs, setActualTabs] = useState(tabs);

  useLayoutEffect(() => {
    setActualTabs(tabs);
  }, [tabs]);

  useDidUpdate(() => {
    LS.setItem(LSKeys.recentTabs, recentTabs, userId);
  }, [recentTabs])

  const removeContactTab = (tab) => {
    const all = [...recentTabs.all];
    const visible = [...recentTabs.visible];
    const unfixedTab = LS.getItem(LSKeys.unfixedTab, userId);

    if (tab !== unfixedTab && tab !== 'new_chat') {
      if (all.includes(tab)) { // remove tab from list to push it in list end
        const tabIndex = all.findIndex(rTab => rTab === tab);

        all.splice(tabIndex, 1);
      }
      if (visible.includes(tab)) {  // remove tab from list to push it in list end
        const tabIndex = visible.findIndex(rTab => rTab === tab);

        visible.splice(tabIndex, 1);
      }

      visible.push(tab);
      all.push(tab);

      if (all.length > 10) {
        const removedTab = all[0];
        const index = visible.findIndex(tab => tab === removedTab);

        all.splice(0, 1);

        if (index !== -1) {
          visible.splice(index, 1);
        }
      }

      updateRecentTabs({ all, visible }, type);
    }

    children.props.removeContactTab(tab, type);
  }

  const onOpenRecentTab = () => {
    const visible = [...recentTabs.visible];
    const tab = visible[visible.length - 1];
    const all = recentTabs.all.filter(t => t !== tab);

    visible.splice(visible.length - 1, 1);

    updateRecentTabs({ all, visible }, type);
  }

  const isArrContainsElFromAnotherArr = (arr1, arr2) => {
    let contains = false;
    let i = arr1.length;

    while (i--) {
      if (~arr2.indexOf(arr1[i])) {
        contains = true;
        break;
      };
    };

    return contains;
  }

  const getMethodSetTabs = () => {
    switch (type) {
      case CHAT_TYPES.CLIENT:
        return props.setClientTabs;
      case CHAT_TYPES.ROOM:
        return props.setChatTabs;
      case CHAT_TYPES.GIRL:
        return props.setGirlsTabs;
    };
  }

  return (
    <Fragment>
      {React.cloneElement(children, {
        ...children.props,
        tabs: actualTabs,
        setTabs: getMethodSetTabs(),
        removeContactTab: children.props.removeContactTab ? removeContactTab : null,
        onOpenRecentTab,
        recentTabs: isArrContainsElFromAnotherArr(recentTabs.visible, tabs)
          ? recentTabs.visible.filter(tab => !tabs.includes(tab))
          : recentTabs.visible,
        type,
      })}
    </Fragment>
  )
};

const mapStateToProps = (state, ownProps) => ({
  userId: state.user.id,
  unreadTabs: selectTabsWithUnread(state, ownProps.tabs, ownProps.type),
  recentTabs: selectRecentTabs(state, ownProps.type),
});

const mapDispatchToProps = {
  setClientTabs,
  setGirlsTabs,
  setChatTabs,
  updateRecentTabs,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdaptiveTabs);
