import React from 'react';

import classNames from 'classnames';
import { BOOKING_CALENDAR_LABEL_DATE_CONFIG } from 'config/dates-сonfig';
import { BOOKING_TIME_FILTERS } from 'components/FilteredBookings/config/constants';

import './BookingTimeFilters.scss';
import DateTime from 'components/DateTime';
import moment from 'moment';

export const BookingTimeFilters = ({ className, children, onClick }) => (
  <div className={classNames(className, "time-filter")}>
    {React.Children.map(children, (child) => React.cloneElement(child, { onClick }))}
  </div>
)

Object.entries(BOOKING_TIME_FILTERS).forEach(([filterName, { label, value }]) => {
  const Component = ({ onClick }) => {
    return (
      <button
        className="time-filter__item"
        onClick={() => onClick(value)}
      >
        {label !== 'current' ? (
          <DateTime
            date={moment(label, 'hh:mm')}
            config={BOOKING_CALENDAR_LABEL_DATE_CONFIG}
            ignoreTimezone
          />
        ) : label}
      </button>
    )
  }

  BookingTimeFilters[filterName] = Component;
})
