import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';

import './FormTextarea.scss';

const FormTextTextarea = (props) => {
  const {
    inputProps = {},
    inputRef,
    autosize,
    autoFocus,
    className,
    serverErrors,
    isDefineLinks,
    inputClassName,
    isErrorRelative,
    errorInputClassName,
    extraButtons,
    extraContent,
    onAdditionalChange,
    isDisabled,
    asyncValidation = false,
    theme = 'dark-background',
    input: finalFormInputProps = {},
    meta: { error, touched, active, submitFailed } = {},
  } = props;

  const [isShowTextarea, setIsShowTextarea] = useState(false);

  const textareaRef = useRef(null);
  const isSelectedRef = useRef(null);

  const isForDefineLinks = isDefineLinks && !isShowTextarea;

  const inputHeightAutosize = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.style.height = '';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
    else {
      textareaRef.current.style.height = '';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    autosize && inputHeightAutosize();
  }, [finalFormInputProps.value, inputProps.value])

  useLayoutEffect(() => {
    if(isShowTextarea) {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.value.length;
      textareaRef.current.selectionEnd = textareaRef.current.value.length;
    };
  }, [isShowTextarea]);

  useLayoutEffect(() => {
    if (autoFocus && finalFormInputProps.value && !isSelectedRef.current) {
      isSelectedRef.current = true;
      textareaRef.current.select();
    }
  }, [autoFocus, finalFormInputProps.value])

  const renderError = (errorMsg) => (
    <div className={classModifier('form-text-content__error form-textarea__error',
      isErrorRelative && 'relative')} >
      {errorMsg}
    </div>
  )

  const isClientError = error && touched && !active && (submitFailed || asyncValidation);
  const isServerError = serverErrors && serverErrors[finalFormInputProps.name];
  const errorInputClass = (isClientError || isServerError)
    ? classNames(errorInputClassName, 'input--error')
    : '';

  const textareaProps = {
    ...{
      ...finalFormInputProps,
      onChange: (e) => {
        onAdditionalChange && onAdditionalChange();
        finalFormInputProps.onChange(e);
      }
    },
    autoFocus,
    ref: inputRef ? inputRef : textareaRef,
    className: classNames('form-textarea__input', inputClassName),
    onBlur: (e) => {
      finalFormInputProps?.onBlur?.(e);
      isShowTextarea && setIsShowTextarea(false);
    },
    ...(props.onBlur ? { onBlur: props.onBlur } : {}),
    ...inputProps
  };

  const renderFormattedValue = () => {
    const links = textareaProps.value.match(/(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim) || '';

    const formattedValue = textareaProps.value.split(' ').map((textValue, idx) => {
      return links.includes(textValue) 
        ? <a key={idx} href={textValue} onClick={e => e.stopPropagation()} target="_blank">{textValue} </a>
        : <Fragment key={idx}>{`${textValue} `}</Fragment>;
    });

    return formattedValue;
  };

  const inputFocus = () => {
    if(textareaProps.ref && textareaProps.ref.current) {
      textareaProps.ref.current.focus();
    }
  }
  
  return (
    <div className={classNames(
      'form-text-content',
      classModifier('form-textarea', [isDisabled && 'disabled', theme]),
      className,
      errorInputClass
    )}
      onClick={inputFocus}
    >
      <div className="form-textarea__input-wrapper">
        {isForDefineLinks || isDisabled
          ? <div
              className={classNames('form-textarea__input', inputClassName)}
              onClick={() => !isDisabled && setIsShowTextarea(true)}
            >
              {renderFormattedValue()}
            </div>
          : <textarea {...textareaProps} /> 
        }
        
        <div
          className="form-textarea__extra-content"
          onClick={(event) => event.stopPropagation()}
        >
          {extraContent}
        </div>
      </div>

      {extraButtons}
			
      {isClientError
        ? renderError(error)
        : isServerError
          ? renderError(serverErrors[finalFormInputProps.name])
          : null
      }
    </div>
  ) }

export default FormTextTextarea;
