import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { MAP_GIRL_FILTERS, updateFilter } from 'redux/ducks/map';
import { classModifier } from 'utils';
import { selectIsMapFilterSelected } from 'redux/selectors/selectors';


const ShowAvailableButton = () => {
  const isAvailableFilterEnabled = useSelector(
    (state) => selectIsMapFilterSelected(state, MAP_GIRL_FILTERS.AVAILABLE_ESCORTS)
  
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateFilter(MAP_GIRL_FILTERS.AVAILABLE_ESCORTS));
  }

  return (
    <button
      className={classModifier('girls-map__toggle-btn', isAvailableFilterEnabled && 'active')}
      onClick={handleClick}
    >
      Available Now
      <ICONS.check className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default ShowAvailableButton;
