import React, { Fragment, useState } from 'react';
import { Field } from 'react-final-form';

import { useDidUpdate } from 'hooks';
import { classModifier } from 'utils';

import BookingServiceFieldItem from './BookingServiceFieldItem/BookingServiceFieldItem';

const ServicesList = ({
  fields,
  localFieldName,
  setBookingEditProps,
  isBookingEditorPanel,
  clearServices,
  isCollapsed = false,
}) => {
  const [isHasRequest, setIsHasRequest] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredServices, setFilteredServices] = useState(fields?.value || fields)

  const onChange = (e, fieldName) => {
    setBookingEditProps(prevState => {
      const checked = e.target.checked;
      const services = prevState[localFieldName].services.map(item => item.name === fieldName ? { ...item, checked } : item);
      return { ...prevState, [localFieldName]: { ...prevState[localFieldName], services } }
    });
  }

  useDidUpdate(() => {
    setFilteredServices(() => (fields.value || fields || []).filter((service) => {
      return service.name.toLowerCase().includes(query.toLowerCase());
    }))
  }, [query, fields.value]);

  useDidUpdate(() => {
    isBookingEditorPanel && setFilteredServices(fields);
  }, [fields])

  return (
    <>
      <div className={classModifier('booking-form__fields-wrapper', 'services')}>
        {isCollapsed && (
          <div className="booking-form__fields-request fields-request">
            <label className='fields-request__item'>
              <input
                name="request"
                type="radio"
                onChange={() => setIsHasRequest(true)}
                checked={isHasRequest}
              />
              <div className={classModifier('fields-request__radio', isHasRequest ? 'selected' : 'not-selected')}></div>

              Has request
            </label>

            <label className='fields-request__item'>
              <input
                name="request"
                type="radio"
                onChange={() => { setIsHasRequest(false); clearServices() }}
                checked={!isHasRequest}
              />
              <div className={classModifier('fields-request__radio', !isHasRequest ? 'selected' : 'not-selected')}></div>

              Has no request
            </label>
          </div>
        )}

        {isHasRequest && (
          <SearchInput
            inputWrapClassName="booking-form__services-input-wrap"
            inputClassName="booking-form__services-input"
            placeholder='Search service'
            hideSearchIcon
            hideClearButton
            setQuery={setQuery}
          />
        )}

        {(isHasRequest || !isCollapsed) && (
          <>
            <h3 className='booking-form__fields-wrapper-title'>
              Services
            </h3>

            <ul className='services-list'>
              {filteredServices?.map((service) => {
                const { price, checked: isChecked, name: fieldName, id } = service;

                return (
                  <Fragment key={id}>
                    {isBookingEditorPanel
                      ? <BookingServiceFieldItem
                        price={price}
                        fieldName={fieldName}
                        isChecked={isChecked}
                        input={{
                          type: 'checkbox',
                          checked: !!isChecked,
                          onChange: (e) => onChange(e, fieldName)
                        }}
                      />
                      : <Field
                        name={`${fields.name}[${id}].checked`}
                        type="checkbox"
                        price={price}
                        fieldName={fieldName}
                        isChecked={isChecked}
                        component={ServiceFieldItem}
                      />
                    }
                  </Fragment>
                )
              })}
            </ul>
          </>
        )}
      </div>
    </>
  )
};

export default React.memo(ServicesList);
