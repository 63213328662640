import { useContext } from 'react';

import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { notificationProps } from '../config/config';
import { LoadingContactsPengingContext } from 'containers/Chat/PublicChat/PublicChatNotificationsList/PublicChatNotificationsList';

import { Caller } from '../entities/Caller/Caller';
import { Message } from '../entities/Message/Message';
import { Email } from '../entities/Email/Email';
import { Chat } from '../entities/Chat/Chat';
import { Operator } from '../entities/Operator/Operator';
import { NotificationBlock } from 'components/UI/NotificationBlock/NotificationBlock';
import { getNotificationProps } from 'utils/getNotiifcationProps';

// additional props for each component
export const entities = {
  caller: {
    client: {
      name: Caller.Client.Name,
      avatar: Caller.Client.Avatar
    },
    girl: {
      name: Caller.Girl.Name,
      avatar: Caller.Girl.Avatar,
    },
    name: Caller.Name,
    avatar: Caller.Avatar,
  },
  chat: Chat,
  operator: Operator,
  message: Message,
  email: Email,
  defaultAvatar: Caller.Avatar,
}

export const CommonNotification = (props) => {
  const { item } = props;
  const pendingIds = useContext(LoadingContactsPengingContext);
  const callerId = getNotificationProps(item, 'callerId');

	let type;

  if (item.web_master_id) { // TODO: Ask back for creation equal type for remainders and notifications
    type = NOTIFICATION_TYPES.webmaster;
  } else if (item.message_id) {
    type = NOTIFICATION_TYPES.messageReminder;
  } else if (item.task_id) {
    type = NOTIFICATION_TYPES.task;
  } else if (item.mail_conversation_id) {
    type = NOTIFICATION_TYPES.newMail;
  } else {
    type = item.type;
  }

  const {
    CustomNotification,
    ...restNotificationProps
  } = notificationProps[type];

  const pending = pendingIds?.includes(callerId[0]);

  const enhancedProps = {
    ...props,
    ...restNotificationProps,
    entities,
    type,
    pending,
  };

  if (CustomNotification) {
    return <CustomNotification {...enhancedProps} />;
  } else {
    return <NotificationBlock {...enhancedProps} />;
  }
}
