const convertContactTypesToLabels = (type) => {
  switch (type) {
    case 2: {
      return 'All Escorts'
    };
    case 3: {
      return 'All Agents'
    };
    case 5: {
      return 'Agents + Receptions'
    };
  }
}

export default convertContactTypesToLabels;
