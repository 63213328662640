import React from 'react';
import cn from 'classnames';

import './Button.scss';
import { classModifier } from 'utils';
import Spinner from 'components/UI/Spinner/Spinner';
import { BUTTON_THEMES } from 'config/constants';
import TypingIndicator from 'components/UI/TypingIndicator/TypingIndicator';

const Button = ({
  children,
  className,
  theme = BUTTON_THEMES.primary,
  pending,
  icon,
  innerRef,
  component: Component,
  ...rest
}) => {
  const props = {
    className: cn(className, classModifier('button', BUTTON_THEMES[theme] && theme)),
    ...rest,
  }

  const Wrapper = ({ children }) => {
    if (Component) {
      return (
        <Component ref={innerRef} {...props}>
          {children}
        </Component>
      )
    } else {
      return (
        <button {...props}>
          {children}
        </button>
      )
    }
  }

  return (
    <Wrapper>
      {pending && !icon && children
        ? <TypingIndicator />
        : children}
      {pending && icon
        ? <Spinner spinnerSize={16} className='button__icon' />
        : icon}
    </Wrapper>
  )
}

export default Button;
