import React, { useEffect, useLayoutEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { selectModalCount } from 'redux/selectors/selectors';

import './GalleryCarousel.scss';

export const GalleryCarouselItem = ({ children, width, classPrefix }) => {
  return (
    <div className={`${classPrefix}__carousel-item`} style={{ width: width }}>
      {children}
    </div>
  );
};

const GalleryCarousel = ({ children, classPrefix, activeSlide }) => {
  const [activeIndex, setActiveIndex] = useState();
  const [key, setKey] = useState(uuid);

  const modalCount = useSelector(state => selectModalCount(state));

  useEffect(() => {
    setActiveIndex(activeSlide)
  }, [children, activeSlide])

  useLayoutEffect(() => {
    setKey(uuid);
  }, [children])

  const onKeyDown = (e) => {
    e.preventDefault();

    if (e.key === 'ArrowRight') {
      updateIndex('next');
    }
    else if (e.key === 'ArrowLeft') {
      updateIndex('prev');
    }
  }

  useEffect(() => {
    if (modalCount > 1) {
      document.removeEventListener('keydown', onKeyDown);
    } else {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    }
  }, [modalCount]);

  const updateIndex = (action) => {
    const lastSlideIndex = React.Children.count(children) - 1;

    if (action === 'prev') {
      setActiveIndex(prevActiveSlide =>
        prevActiveSlide === 0
          ? lastSlideIndex
          : prevActiveSlide - 1
      );
    }
    else {
      setActiveIndex(prevActiveSlide =>
        prevActiveSlide === lastSlideIndex
          ? 0
          : prevActiveSlide + 1
      );
    }
  };

  return (
    <div
      key={key}
      className={`${classPrefix}__carousel`}
    >
      <div
        className={`${classPrefix}__carousel-inner`}
        style={{ transform: `translateX(calc(-${activeIndex * 100}% - ${activeIndex * 31}px))` }}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { width: '100%' });
        })}
      </div>

      {children.length > 1 && (
        <>
          <button
            className={classModifier(`${classPrefix}__carousel-controller`, 'prev')}
            onClick={() => updateIndex('prev')}
          >
            <ICONS.chevron
              className={classModifier(`${classPrefix}__carousel-controller-icon`, 'prev')}
            />
          </button>

          <button
            className={classModifier(`${classPrefix}__carousel-controller`, 'next')}
            onClick={() => updateIndex('next')}
          >
            <ICONS.chevron
              className={classModifier(`${classPrefix}__carousel-controller-icon`, 'next')}
            />
          </button>
        </>
      )}
    </div>
  );
};

export default GalleryCarousel;
