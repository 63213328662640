import { classModifier, getContactAvatar } from 'utils';
import { CONTACT_TYPES } from 'config/constants';

import './SearchListContactItem.scss';
import classNames from 'classnames';

const SearchListContactItem = ({ item: contact, onClick, ignoredId, className, disabled }) => {
  const isOperator = contact.type === CONTACT_TYPES.OPERATOR;
  const isContactDisabled = contact.id === ignoredId;
  const mailOrPhone = !isOperator && (contact.emails[0]?.email || contact.tels[0]?.tel);

  return (
    <div
      className={classNames(
        className,
        classModifier('search-list-client-item', [isContactDisabled && 'disabled'])
      )}
      onClick={() => !isContactDisabled && onClick(contact)}
      disabled={disabled}
    >
      <div className='search-list-client-item__avatar-thumb'>
        <img src={getContactAvatar(contact)} alt="avatar" />
      </div>
      <div className='search-list-client-item__info'>
        <p className='search-list-client-item__name'>{!isOperator ? contact.fn : contact.username}</p>

        {!isOperator && mailOrPhone &&
          <p className='search-list-client-item__mail-or-phone'>
            {mailOrPhone}
          </p>
        }
      </div>
    </div>
  );
};

export default SearchListContactItem;
