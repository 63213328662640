import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars-2';

import API from 'api/api';
import { setActiveMailChat, clearActiveMailChat, setUnreadEmailsCount } from 'redux/ducks/mail';
import { useDidMount, useToggle, useDidUpdate, useWillUnmount, usePrevious } from 'hooks';
import { capitalizeFirstLetter } from 'utils';
import { TITLE } from 'config/constants';

import './MailChat.scss';
import MailChatItem from './MailChatItem/MailChatItem';
import Spinner from 'components/UI/Spinner/Spinner';
import ICONS from 'assets/icons';

const MailChat = ({ params }) => {
  const messages = useSelector(state => state.mail.activeMailChatMessages)?.filter(msg => !msg.isDraft);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  
  const prevListLength = usePrevious(messages.length);

  const [isEmailNotFounded, setIsEmailNotFounded] = useState(false);

  const [pending, togglePending] = useToggle(true);

  const { id: mailChatId } = params;

  useDidMount(() => {
    API.getMailChatMessages(Number(mailChatId))
      .then(({ data: { messages, unreadCount, unreadSpamCount }}) => {
        const subject = !!messages[0].subject ? messages[0].subject : 'no subject'
        document.title = `${capitalizeFirstLetter(subject)} | ${TITLE}`;

        dispatch(setActiveMailChat(Number(mailChatId), messages));
        dispatch(setUnreadEmailsCount({ unreadCount, unreadSpamCount }));
      })
      .catch((err) => {
        console.error(err);
        
        if (err.response.data.code === 404) {
          setIsEmailNotFounded(true);
        }
      })
      .finally(() => togglePending(false));
  })

  useWillUnmount(() => {
    dispatch(clearActiveMailChat());
  })

  useDidUpdate(() => {
    // If the last message was deleted - return
    if (prevListLength && !messages.length) {
      handleReturnBtnClick();
    }
  }, [messages]);

  const handleReturnBtnClick = () => navigate(-1);

  if (isEmailNotFounded) {
    return (
      <div className="mail-chat mail-chat--not-found">
        <div className="mail-chat__header">
          <button
            className="mail-chat__return-btn"
            type="button"
            onClick={handleReturnBtnClick}
          >
            <ICONS.arrow className="mail-chat__return-btn-icon"/>
            Back
          </button>
        </div>

        <div className="mail-chat__main">
          <h2 className="mail-chat__not-founded">Email not found :(</h2>
        </div>
      </div>
    )
  }

  if (pending) {
    return (
      <div className="mail-chat mail-chat--centered">
        <Spinner spinnerSize='80px' />
      </div>
    )
  }

  return (
    <div className="mail-chat">
      <div className="mail-chat__header">
        <button
          className="mail-chat__return-btn"
          type="button"
          onClick={handleReturnBtnClick}
        >
          <ICONS.arrow className="mail-chat__return-btn-icon"/>
          Back
        </button>
      </div>

      <div className="mail-chat__main">
        <Scrollbars
          autoHide
          autoHideTimeout={800}
        >
          {messages.map((messageItem, index, list) => (
            <div className="mail-chat__item" key={messageItem.id}>
              <MailChatItem
                className="mail-chat__message"
                index={index}
                item={messageItem}
                isLastItem={list.length - 1 === index}
              />
            </div>
          ))}
        </Scrollbars>
      </div>
    </div>
  )
}

export default MailChat;