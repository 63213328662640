import { classModifier } from 'utils';
import ReactTimeAgo from 'react-timeago';

import './BookingTooltipAddFrom.scss';
import ICONS from 'assets/icons';

const BookingTooltipAddFromItem = (props) => {
    const {
        item,
        onSelect,
        onClose
    } = props;

    const itemClickHandler = (item) => {
        onSelect(item);
        onClose();
    }

    const isFromTelegram = Number(item.type) === 9;
    const isFromWhatsapp = Number(item.type) === 18;

    return (
        <div className='booking-tooltip-add-from__item' onClick={() => itemClickHandler(item)}>
            <div className='booking-tooltip-add-from__item-header'>
                {isFromTelegram &&
                    <ICONS.telegram className={classModifier('booking-tooltip-add-from__icon', 'telegram')}/>
                }

                {isFromWhatsapp &&
                    <ICONS.whatsapp className={classModifier('booking-tooltip-add-from__icon', 'whatsapp')}/>
                }
                
                <span className='booking-tooltip-add-from__item-name'>
                    {item.fn}
                </span>
                <ReactTimeAgo
                    className={'booking-tooltip-add-from__item-time'}
                    minPeriod={60}
                    date={item.date_created}
                />
            </div>
            <div className='booking-tooltip-add-from__item-body'>
               {item.message}
            </div>
        </div>
    )
}

export default BookingTooltipAddFromItem;