import { usePopperTooltip } from 'react-popper-tooltip';
import { useCallback, useEffect, useState } from 'react';

import { CALENDAR_CHOOSE_DATE_CONFIG } from 'config/dates-сonfig';
import { useToggle } from 'hooks';
import classNames from 'classnames';

import './DateTimeChanger.scss';
import DateTime from 'components/DateTime';
import ICONS from 'assets/icons';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';

export const DateTimeChanger = ({ className, withIcon, width = 218, ...props }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useToggle(false);
  const [customRange, setCustomRange] = useState(null);

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: setIsCalendarOpen,
  });

  const handleSelectDate = useCallback((date) => {
    props.setDate(date);
    setIsCalendarOpen();
  }, [])

  return (
    <>
      <div className={classNames("date-time-changer", className)}>
        <button
          className="date-time-changer__prev-btn"
          onClick={props.changeDateToPrev}
        >
          <ICONS.angleQuote
            className="date-time-changer__prev-icon"
          />
        </button>

        <button
          ref={setTriggerRef}
          className="date-time-changer__container"
          disabled={props.disabled}
          style={{ width }}
        >
          <DateTime
            date={props.date}
            separator={props.range === 2 ? '/' : '-'}
            config={CALENDAR_CHOOSE_DATE_CONFIG}
            nonReduceEqualDate
            ignoreTimezone
          />

          {withIcon &&
            <ICONS.calendar className="date-time-changer__calendar-icon" />
          }
        </button>

        <button
          className="date-time-changer__next-btn"
          onClick={props.changeDateToNext}
        >
          <ICONS.angleQuote
            className="date-time-changer__next-icon"
          />
        </button>
      </div>

      {isCalendarOpen && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'date-time-changer__tooltip' })}
        >
          <DateTimePicker
            initDate={props.date}
            isTimeActive={false}
            range={props.range}
            displayRangeMenu={!props.range}
            customRange={customRange}
            setCustomRange={setCustomRange}
            onSelectDate={handleSelectDate}
            onCancel={setIsCalendarOpen}
            ignoreTimezone
          />
        </div>
      )}
    </>
  )
}
