import React, { useEffect, useLayoutEffect, useState } from 'react';
import Loadable from 'react-loadable';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';

import AsyncComponentLoader from 'components/AsyncComponentLoader/AsyncComponentLoader';
import { classModifier } from 'utils';

export const LoadEmojiPicker = Loadable({
  loader: () => import(/* webpackChunkName: "EmojiPickerChunk"*/ './EmojiPicker'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['EmojiPickerChunk']
});

const EmojiPickerBtn = ({
  onSelect,
  isShow,
  onClick,
  isHiddenBtn,
  isOpenFromParent,
  onClose
}) => {

  const [isVisible, setIsVisible] = useState(false);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    placement: 'top',
    visible: isVisible,
    onVisibleChange: setIsVisible
  })

  useLayoutEffect(() => {
    if (tooltipRef) {
      tooltipRef.style.zIndex = 2;
    }

    return () => {
      if (tooltipRef) {
        onClick();
      }
    }
  }, [tooltipRef])

  useEffect(() => {
    if(!isVisible) {
      onClose && onClose();
    }
  }, [isVisible]);

  useEffect(() => {
    if(isOpenFromParent) {
      setIsVisible(isShow);
    }
  }, [isOpenFromParent]);

  return <>
    <button
      type='button'
      className={classModifier("chat-input__btn", isHiddenBtn && 'is-hidden')}
      ref={setTriggerRef}
      onMouseOver={LoadEmojiPicker.preload}
      onClick={onClick}
    >
      <ICONS.smile className={classModifier("chat-input__btn-icon", isShow && 'active')} />
    </button>

    {visible && isShow &&
      <div className='chat-input__modal-wrap' ref={setTooltipRef} {...getTooltipProps()} >
        <LoadEmojiPicker
          style={{ position: 'initial' }}
          showPreview={false}
          onSelect={onSelect}
          native={true}
        />
      </div>
    }
  </>
}


export default EmojiPickerBtn;
