import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getBookingsByCallerId } from 'redux/ducks/bookings';
import { useToggle } from 'hooks';
import { getDateByTimezoneOffset } from 'utils';

import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import BookingItemNew from 'components/BookingItemNew/BookingItemNew';

import './ExistedBookingsList.scss';
import GorizontalList from 'components/UI/GorizontalList/GorizontalList';

const ExistedBookingsList = ({ clientId, userTimezone }) => {
  const [bookings, setBookings] = useState([]);
  const [pending, setPending] = useToggle(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setPending(true);

    dispatch(getBookingsByCallerId(clientId, true, Date.parse(getDateByTimezoneOffset(userTimezone))))
      .then(setBookings)
      .finally(() => setPending(false))
  }, [clientId])

  return (
    <div className="bookings-list-by-form">
      <GorizontalList
        classPrefix="bookings-list-by-form-list"
        list={bookings}
        listLoadPending={pending}
        absentLabel="(No unfinished bookings)"
        minLength={4}
      >
        <BookingItemNew type={BOOKING_ITEM_VIEWS.Form}/>
      </GorizontalList>
    </div>
  )
}

export default memo(ExistedBookingsList);
