import { ROOM } from 'config/constants';
import { CONFERENCE } from 'redux/ducks/conferences';

import allImg from 'assets/images/avatars/all.png';
import avatarDefaultImg from 'assets/images/avatars/avatarDefault.png';
import detectiveImg from 'assets/images/avatars/detective.png';
import divaLogoImg from 'assets/images/avatars/diva-logo.jpg';
import girlImg from 'assets/images/avatars/girl.png';
import hotelImg from 'assets/images/avatars/hotel.png';
import roomImg from 'assets/images/avatars/room.png';
import savedImg from 'assets/images/avatars/saved.png';

const getRoomAva = (room, isFullSizeImg) => {
  let defaultAvatar;

  switch (room && room.chatType) {
    case 'room':
      defaultAvatar = roomImg;
      break;
    case 'general':
      defaultAvatar = allImg;
      break;
    case 'saved':
      defaultAvatar = savedImg;
      break;
    case 'girls':
      defaultAvatar = divaLogoImg;
      break;
    default:
      defaultAvatar = avatarDefaultImg;
  }

  if (!room.photo) {
    return defaultAvatar;
  }
  else {
    return isFullSizeImg
      ? room.photo.src
      : room.photo.min
  }
};

const getContactAva = (contact, isFullSizeImg) => {
  let defaultAvatar;

  switch (contact && contact.type) {
    case 9:
      defaultAvatar = avatarDefaultImg;
      break;
    case 2:
      defaultAvatar = girlImg;
      break;
    case 3:
      defaultAvatar = detectiveImg;
      break;
    case 4:
      defaultAvatar = hotelImg;
      break;
    default:
      defaultAvatar = avatarDefaultImg;
      break;
  }

  if (!contact || !contact.photo) {
    return defaultAvatar;
  }
  else {
    if (typeof contact.photo === 'string' && !isFullSizeImg) {
      return contact.photo;
    }

    return isFullSizeImg
      ? contact.photo.src
      : contact.photo.min
  }
};

export default (contact, contactType, isFullSizeImg) => {
  if (contactType === ROOM) {
    return getRoomAva(contact, isFullSizeImg);
  }
  else if (!contact) {
    return getContactAva(contact, isFullSizeImg);
  }
  else if (contact.type === CONFERENCE) {
    return allImg;
  }
  else {
    return getContactAva(contact, isFullSizeImg);
  }
};
