import classNames from 'classnames';
import Tooltip from 'components/UI/Tooltip/Tooltip';

import "./BookingNotes.scss"

const BookingNotes = ({ className, booking }) => (
  <Tooltip text={booking.notes} visibleWhenIsOverflowed>
    <span className={classNames(className, "booking-notes")}>
      {booking.notes}
    </span>
  </Tooltip>
)

export default BookingNotes;
