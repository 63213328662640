import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { useToggle } from 'hooks';
import { classModifier } from 'utils';

import '../MentionedOrRecentlyViewedProfilesList.scss';
import MentionedOrRecentlyViewedProfile from '../MentionedOrRecentlyViewedItem/MentionedOrRecentlyViewedProfile';
import ICONS from 'assets/icons';

const RecentlyViewedProfilesList = ({
  profilesIds,
  hostProfile,
  isOpenHost,
  isDefaultSession,
}) => {
  const [openProfileList, setProfileList] = useToggle(false);

  const profilesCount = ~profilesIds.indexOf(hostProfile)
    ? profilesIds.length - 1
    : profilesIds.length;

  return (
    <div
      className={classModifier('recently-viewed-profiles', [
        isDefaultSession && !isOpenHost && openProfileList && 'open',
        !isDefaultSession && 'not-default'
      ])}
    >
      <div className="recently-viewed-profiles__title-box">
        <div 
          className={classModifier('recently-viewed-profiles__title', [
            !!profilesCount && 'click',
          ])}
          onClick={() => !!profilesCount && setProfileList()}
        >
          <h2>Recently Viewed Profiles ({profilesCount})</h2>
          {!!profilesCount && (
            <div className="recently-viewed-profiles__icon-wrapper">
              <ICONS.chevron
                className={classModifier('recently-viewed-profiles__chevron', [
                  openProfileList && 'rotated',
                ])}
              />
            </div>
          )}
        </div>
      </div>

      {openProfileList && (
        <ul className="recently-viewed-profiles__items">
          <Scrollbars
            autoHide
            renderThumbVertical={() => (
              <div className="recently-viewed-profiles__scrollbar-thumb" />
            )}
          >
            {profilesIds.map((profile) => {
              return (
                profile !== hostProfile && (
                  <MentionedOrRecentlyViewedProfile
                    id={profile}
                    key={profile}
                    hostProfile={hostProfile}
                    mentioned={false}
                  />
                )
              );
            })}
          </Scrollbars>
        </ul>
      )}
    </div>
  );
};

export default RecentlyViewedProfilesList;