import { useLocation, useNavigate } from 'react-router-dom';

import { NotificationBlock } from 'components/UI/NotificationBlock/NotificationBlock';


export const MailNotification = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isMailPage = location.pathname.includes('mail');

  const handleOpenNotification = () => {
    const pathToMail = `/mail/inbox/email/${props.item.data.mail.conversationId}`;

    if (isMailPage) {
      navigate(pathToMail);
    } else {
      window.open(pathToMail, '_blank');
    }
  }

  return (
    <NotificationBlock {...props}>
			<NotificationBlock.Body>
				{props.getText}
      </NotificationBlock.Body>

      <NotificationBlock.Footer iconType={props.iconType}>
        <button onClick={handleOpenNotification}>
          Open
        </button>
      </NotificationBlock.Footer>
    </NotificationBlock>
  )
}
