import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import ReCAPTCHA from "react-google-recaptcha";

import API from 'api/api';
import { getCookie, capitalizeFirstLetter, CS, LS } from 'utils';
import { useDidMount, useToggle } from 'hooks';
import { ROUTES } from 'config/constants';

import './LoginPage.scss';
import AsyncButton from 'components/AsyncButton/AsyncButton';
import FormTextField from 'components/FormTextField/FormTextField';
import { resetChatSearchInputs } from 'redux/ducks/contacts';
import { resetAddrSearchInput } from 'redux/ducks/addressBook';

const isHideRecaptcha = true;
// const currentHostname = window?.location?.hostname;
// const isHideRecaptcha = process.env.NODE_ENV === 'development' ||
//   currentHostname.includes('comdiva.local') ||
//   currentHostname.includes('commdiva.abcwdl.com');

const LoginPage = (props) => {
  const userId = useSelector(state => state.user.id);
  const [loginPending, setLoginPending] = useState(false);

  const [isRecaptchaValid, toggleRecaptchaValid] = useToggle(isHideRecaptcha);
  const [serverErrors, setServerErrors] = useState({});

  const inputRef = useRef();
  const recaptchaRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useDidMount(() => {
    const initApp = async () => {
      await API.getIsIAmLoggedIn()
        .then(() => {
          localStorage.isLoggedIn = 1;

          if (sessionStorage.getItem('lastInteractionPage') === ROUTES.sales) {
            navigate(ROUTES.sales, { replace: true });
          } else if (sessionStorage.getItem('lastInteractionPage') === ROUTES.adrBook) {
            navigate(ROUTES.adrBook, { replace: true });
          } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('mail')) {
            navigate(ROUTES.mail, { replace: true });
          } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('calendar')) {
            navigate(ROUTES.calendar, { replace: true });
          } else {
            navigate(ROUTES.main, { replace: true });
          }
        })
        .catch((err) => {
          console.error(err);
          inputRef.current && inputRef.current.focus();
        });
    };

    if (process.env.NODE_ENV === 'development') {
      if (localStorage.isLoggedIn) {
        if (sessionStorage.getItem('lastInteractionPage') === ROUTES.sales) {
          navigate(ROUTES.sales, { replace: true });
        } else if (sessionStorage.getItem('lastInteractionPage') === ROUTES.adrBook) {
          navigate(ROUTES.adrBook, { replace: true });
        } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('mail')) {
          navigate(ROUTES.mail, { replace: true });
        } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('calendar')) {
          navigate(ROUTES.calendar, { replace: true });
        } else {
          navigate(ROUTES.main, { replace: true });
        }
      }
      else {
        initApp();
      }
    }
    else if (!!getCookie('isAuthenticated')) {
      if (sessionStorage.getItem('lastInteractionPage') === ROUTES.sales) {
        navigate(ROUTES.sales, { replace: true });
      } else if (sessionStorage.getItem('lastInteractionPage') === ROUTES.adrBook) {
        navigate(ROUTES.adrBook, { replace: true });
      } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('mail')) {
        navigate(ROUTES.mail, { replace: true });
      } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('calendar')) {
        navigate(ROUTES.calendar, { replace: true });
      } else {
        navigate(ROUTES.main, { replace: true });
      }
    }
    else {
      inputRef.current && inputRef.current.focus();
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      let isLoggedIn;

      if (process.env.NODE_ENV === 'development') {
        isLoggedIn = localStorage.isLoggedIn;
      } else {
        isLoggedIn = !!getCookie('isAuthenticated');
      }

      if (!!isLoggedIn) {
        if (sessionStorage.getItem('lastInteractionPage') === ROUTES.sales) {
          navigate(ROUTES.sales, { replace: true });
        } else if (sessionStorage.getItem('lastInteractionPage') === ROUTES.adrBook) {
          navigate(ROUTES.adrBook, { replace: true });
        } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('mail')) {
          navigate(ROUTES.mail, { replace: true });
        } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('calendar')) {
          navigate(ROUTES.calendar, { replace: true });
        } else {
          navigate(ROUTES.main, { replace: true });
        }
      }
    }, 2500);


    return () => clearInterval(interval);
  }, []);

  const resetContactSearches = () => {
    LS.deleteItem('clientsSearch', userId);
    LS.deleteItem('girlsSearch', userId);
    LS.deleteItem('adrBookSearch', userId);
    dispatch(resetChatSearchInputs());
    dispatch(resetAddrSearchInput());
  }

  const loginUser = (values) => {
    setLoginPending(true);
    const ipPhoneId = CS.getCookie('selectedIpPhoneId');
    const computer = CS.getCookie('computer');

    return API.loginUser({ ...values, ipPhoneId, computer })
      .then(res => {
        if (res.data.success === 1) {
          props.setIsLoggedIn(true)

          // navigate(ROUTES.twoFactor, {
          //   replace: true,
          //   state: {
          //     username: values.username
          //   }
          // })

					resetContactSearches();

          if (sessionStorage.getItem('lastInteractionPage') === ROUTES.sales) {
            navigate(ROUTES.sales, { replace: true });
          } else if (sessionStorage.getItem('lastInteractionPage') === ROUTES.adrBook) {
            navigate(ROUTES.adrBook, { replace: true });
          } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('mail')) {
            navigate(ROUTES.mail, { replace: true });
          } else if (sessionStorage.getItem('lastInteractionPage')?.split('/').includes('calendar')) {
            navigate(ROUTES.calendar, { replace: true });
          } else {
            navigate(ROUTES.main, { replace: true });
          }
        }
      })
      .catch(err => {
        setLoginPending(false);
        setServerErrors(JSON.parse(err.response?.data.message));
      })
  };

  const onSubmit = (values) => {
    return loginUser(values, props.history)
      .then(res => {
        if (res === 'wrong_credentials' && !isHideRecaptcha) {
          recaptchaRef.current.reset();
          toggleRecaptchaValid(false);
        }
      });
  };

  return (
    <div className="login">
      <h1 className="login__title">Good Morning!</h1>
      <h2 className="login__subtitle">Please Sign In to Your Dashboard</h2>

      <Form
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ handleSubmit, submitting }) => (
          <form
            className="login__form"
            onSubmit={handleSubmit}
          >
            <Field
              name="username"
              label="Username"
              className="login__form-field"
              labelClassName="login__label"
              inputWrapClassName="login__input-wrap"
              inputClassName="login__input"
              component={FormTextField}
              serverErrors={serverErrors}
              inputProps={{
                tabIndex: 1,
                ref: inputRef,
                autoFocus: true,
              }}
            />

            <div className="login__form-field-wrap">
              <Field
                type="password"
                name="password"
                label="Password"
                className="login__form-field login__form-field--password"
                labelClassName="login__label"
                inputWrapClassName="login__input-wrap"
                inputClassName="login__input"
                component={FormTextField}
                serverErrors={serverErrors}
                inputProps={{ tabIndex: 2 }}
              />

              <button type="button" className="login__form-field-btn">
                Forgot Password
              </button>
            </div>

            {/* <div className='login__form-field login__form-field--checkbox'>
              <Label className="login__checkbox-label" label="Keep me signed in">
                {id => (
                  <Field
                    name="keep-signed-in"
                    type="checkbox"
                    component={Checkbox}
                    inputProps={{ id, tabIndex: 3 }}
                  />
                )}
              </Label>
            </div> */}

            {!isHideRecaptcha &&
              <div className="login__recaptcha">
                <ReCAPTCHA
                  sitekey='6LcTJLUUAAAAAJ9lgSjRKy0ElVzqt_KxDvroGTdF'
                  onChange={() => toggleRecaptchaValid()}
                  ref={recaptchaRef}
                />
              </div>
            }

            {serverErrors.general &&
              <div className="login__general-error">{capitalizeFirstLetter(serverErrors.general)}</div>
            }

            <AsyncButton
              type="submit"
              className="btn btn--primary login__submit-btn"
              tabIndex={4}
              pending={loginPending}
              disabled={submitting || !isRecaptchaValid || loginPending}
            >
              Sign in
            </AsyncButton>
          </form>
        )}
      </Form>
    </div>
  );
};


const validate = ({ username, password }) => {
  const getIsFieldEmpty = (name) => !name || !name.trim().length;

  const errors = {};

  const isUsernameEmpty = getIsFieldEmpty(username);
  const isPassEmpty = getIsFieldEmpty(password);

  if (isPassEmpty) errors.password = 'Enter the password';
  if (isUsernameEmpty) errors.username = 'Enter username';

  return errors;
};

export default LoginPage;
