import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectOperatorById } from 'redux/selectors/selectors';

import './Operator.scss';
import { getNotificationProps } from 'utils/getNotiifcationProps';

export const Operator = ({ notification, className }) => {
  const operatorId = getNotificationProps(notification, 'operatorId');

  const operator = useSelector((state) => selectOperatorById(state, operatorId));

  if (!operator) {
    return '[NOT FOUND]';
  }

  return (
    <mark className={classNames("operator-name", className)}>
      {operator?.username || '[DELETED]'}
    </mark>
  )
}
