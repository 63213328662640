import { useState } from 'react';
import { useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { selectCanIEditSession } from 'redux/selectors/selectors';

import './GalleryHeader.scss';
import ICONS from 'assets/icons';
import GalleryHeaderSortSelect from './GalleryHeaderSortSelect';
import GalleryHeaderFilters from './GalleryHeaderFilters/GalleryHeaderFilters';

const GalleryHeader = ({ isShowFilters, setIsShowFilters }) => {
  const canIEditSession = useSelector(state => selectCanIEditSession(state));
  const divaGirlCount = useSelector(state => state.divaGirls.count);

  return (
    <div className="gallery-header">
      <div className="gallery-header__main">
        <span className="gallery-header__best-matching-count">
          Matching Profiles: {divaGirlCount || 0}
        </span>

        <button
          className={classModifier("gallery-header__show-filters-btn", [
            isShowFilters && "filters-opened",
          ])}
          onClick={() => setIsShowFilters(prev => !prev)}
        >
          <ICONS.filter className="gallery-header__show-filters-btn-icon" />

          {isShowFilters ? "Hide Filters" : "Show Filters"}
        </button>

        <div className="gallery-header__sort-select">
          <GalleryHeaderSortSelect isSessionEditable={canIEditSession} />
        </div>
      </div>

      {isShowFilters &&
        <div className="gallery-header__filters">
          <GalleryHeaderFilters isSessionEditable={canIEditSession} />
        </div>
      }
    </div>
  )
}

export default GalleryHeader;
