import React, { createContext, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';

import { BOOKING_STATUS_FILTERS } from 'components/FilteredBookings/config/constants';
import { classModifier } from 'utils';

import './BookingStatusFilters.scss';

const BookingStatusFiltersContext = createContext(null);

export const BookingStatusFilters = ({ getterProps, className, children }) => (
  <BookingStatusFiltersContext.Provider value={{ getterProps }}>
    <div className={classNames("booking-status-filters", className)}>
      { children }
    </div>
  </BookingStatusFiltersContext.Provider>
)

Object.entries(BOOKING_STATUS_FILTERS).forEach(([filterName, { label, color }]) => {
  const Component = ({ getterProps }) => {
    const contextProps = useContext(BookingStatusFiltersContext);
    const { active, statusCount, ...props }
    	= (contextProps?.getterProps || getterProps)({ name: filterName });

    const [actualStatusCount, setActualStatusCount] = useState(statusCount || 0);

    useEffect(() => {
			if (props.disabled || statusCount === undefined) return;

      setActualStatusCount(statusCount);
    }, [props.disabled, statusCount])

    return (
      <button
        type="button"
        className={classModifier(
          'booking-status-filters__item',
          [active && 'active', label]
        )}
        {...props}
      >
        <div className="booking-status-filters__counter">
          ({actualStatusCount > 99 ? '99+' : actualStatusCount})
        </div>

        <div className="booking-status-filters__label">
          {label}

          <div
            style={{ backgroundColor: color }}
            className={classModifier("booking-status-filters__dot", filterName)}
          />
        </div>
      </button>
    )
  }

  BookingStatusFilters[filterName] = Component;
})
