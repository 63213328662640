import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNotificationProps } from 'utils/getNotiifcationProps';

export const Email = ({ notification, className }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const conversationId = getNotificationProps(notification, 'conversationId');

  const isMailPage = location.pathname === '/mail';

  const openMail = () => {
    if (!isMailPage) {
      navigate(`/mail/inbox/email/${conversationId}`)
    }
  }

  return (
    <mark
      className={classNames(className, 'email-label')}
      onClick={openMail}
    >
      email
    </mark>
  )
}
