import React, { useState, useMemo, useCallback } from 'react';

import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';

import './ProfileCardPhotos.scss';
import Carousel from 'components/Carousel/Carousel';
import CarouselNavigation from 'components/CarouselNavigation/CarouselNavigation';
import { useSelector } from 'react-redux';

const ProfileCardPhotos = ({ media, ...props }) => {
  const {
    id,
    agentName
  } = props;

  const profile = useSelector(state => state.divaGirls.entities[id]);
  const [activeSlide, setActiveSlide] = useState(0);

  const onShowSlider = e => {
    if (props.isBufferZone || !images.length) return;

    if (typeof (e.target.className) !== 'string') { // if click on svg
      return onShowSlider({
        target: e.target.parentNode
      });
    }

    const modalProps = {
      media: images,
      defaultActiveSlide: activeSlide,
    }

    return e.target.dataset.image
      ? props.openModal(MODAL_TYPES.profileCarousel, modalProps)
      : null;
  }


  const [images, privateImages] = useMemo(() => {
    let filtered = [];
    let unfiltered = [];

    for (let item of media) {
      if (!item.is_video && !item.is_private) {
        filtered.push(item);
      } else {
        unfiltered.push(item);
      }
    }

    return [filtered, unfiltered]
  }, [media])

  const sortedMedia = useMemo(() => (
    media.sort((prev, next) => Number(next.is_video) - Number(prev.is_video))
  ), [media])

  const openGallery = useCallback((isPrivate) => {
    const modalProps = {
      profile,
      state: { activeSlide: 0 },
      media: sortedMedia,
      isPrivate
    }

    return props.openModal(MODAL_TYPES.photoGallery, modalProps)
  })

  return (
    <div className="profile-card-photos">
      <div
        className={classModifier('profile-card-photos__image-wrap', [
          props.isAvailable && 'available',
          props.isArchived && 'archived',
        ])}
        onClick={onShowSlider}
      >
        <Carousel
          draggable={false}
          media={images}
          classNamePrefix="profile-card-photos"
          iconWidth="32"
          iconHeight="32"
          activeSlide={activeSlide}
        />

        {images.length > 1 &&
          <CarouselNavigation
            mediaLength={images.length}
            classNamePrefix="profile-card-photos"
            setActiveSlide={setActiveSlide}
          />
        }

        <div className="profiles__tags-agent-wrap">
          {agentName &&
            <div className="profiles__agent">
              {agentName}
            </div>
          }
        </div>

      </div>

      <div className="profile-card-photos__desc">
        <button
          className="profile-card-photos__profile-gallery"
          onClick={() => openGallery(false)}
        >
          Photos {images.length}
        </button>

        {Boolean(privateImages.length) && <button
          className='profile-card-photos__profile-gallery'
          onClick={() => openGallery(true)}
        >
          Private gallery {privateImages.length}
        </button>}
      </div>
    </div>
  );
};

export default React.memo(ProfileCardPhotos);
