import React from 'react'

import { classModifier, getContactAvatar, parseMsgText } from 'utils';

import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import PinnedMsgsAttachments from './PinnedMsgsAttachments';

const PinnedMsgItemRolledUp = ({ item, setMenuOpen, unpinMsg, contact, activeRecipientType, ...props }) => {
  const indicator = () => (
    props.pinnedMsgs.map((pinnedMsg) => (
      <div
        key={pinnedMsg.id}
        className={classModifier('pinned-msgs__indicator-round', [
          pinnedMsg.id === item.id && 'active'
        ])}
      ></div>
    ))
  )

  const numberMsg = props.pinnedMsgs.findIndex((el) => el.id === item.id);

  return (
    <>
      <div className="pinned-msgs__indicator">
        {indicator()}
      </div>

      <div className="pinned-msgs__rolled-up-item">
        <div className="pinned-msgs__message" onClick={() => setMenuOpen(true)}>
          <div className="pinned-msgs__avatar-wrapper">
            <LazyLoadImage
              src={getContactAvatar(contact)}
              className="pinned-msgs__avatar"
            />
          </div>

          <div
            className={classModifier("pinned-msgs__content", [
              item?.attachments?.voiceMsg && "voice-msg"
            ])}
          >
            <div className="pinned-msgs__text-wrapper">
              <p className={classModifier('pinned-msgs__text', [
                item?.attachments?.voiceMsg?.url && 'voicemail'
              ])}>
                #{numberMsg + 1}&nbsp;{parseMsgText(item.body, true)}
              </p>
            </div>

            {!!item.attachments &&
              <PinnedMsgsAttachments
                className="pinned-msgs__attachments"
                attachments={item.attachments}
                isMenuOpen={props.isMenuOpen}
              />
            }
          </div>
        </div>

        <div className="pinned-msgs__btn-container pinned-msgs__btn-container--closed">
          <button
            className="pinned-msgs__btn-icon"
            onClick={() => {
              unpinMsg(item.id, activeRecipientType);
            }}
          >
            <ICONS.plusCircle className="pinned-msgs__icon-unpin" />
          </button>

          <button
            className="pinned-msgs__btn-icon"
            onClick={() => setMenuOpen(true)}
          >
            <ICONS.pin className="pinned-msgs__icon-pin" />
          </button>
        </div>
      </div>
    </>
  );
}

export default PinnedMsgItemRolledUp;
