import React, { useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";

import './CopyToClipboardButton.scss'

import { useToggle } from 'hooks';
import {
  classModifier,
  textLineSplitter,
} from 'utils';
import ICONS from 'assets/icons';
import Icon from 'components/Icon/Icon';
import classNames from 'classnames';


const CopyToClipboardButton = ({ className, value, title, svgWidth, svgHeight, disabled }) => {
  const [showCheckIcon, toggleCheckIcon] = useToggle(false);
  
  const inputCopyRef = useRef();

  return (
    <span className={classNames("clipboard-btn", className)}>
      <input
        className="clipboard-btn__input"
        ref={inputCopyRef}
        readOnly="readOnly"
        value={textLineSplitter(value) || ''}
      />

      <button
        className="clipboard-btn__btn"
        type="button"
        title={title ? title : "Copy"}
        disabled={disabled}
        onClick={(e) => {
          toggleCheckIcon(true);
          window.navigator.clipboard.writeText(value) // copy with line break
          setTimeout(() => {
            toggleCheckIcon(false)
          }, 700);
        }}
      >

        {showCheckIcon 
          ? <ICONS.check className='clipboard-btn__icon clipboard-btn__icon--check' />
          : <ICONS.copy className='clipboard-btn__icon clipboard-btn__icon--copy' />
        }
      </button>
    </span>
  );
};

export default CopyToClipboardButton;
