import ReactDOM from 'react-dom';

const dialogsElement = document.getElementById('dialogs-root');

const Portal = ({ disablePortal, ...props }) => (
	disablePortal
		? <>{props.children}</>
		: ReactDOM.createPortal(
			<>
				{props.children}
			</>,
			dialogsElement,
		)
);

export default Portal;