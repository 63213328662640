import React, { useLayoutEffect, useRef, useState } from 'react';

import { useDidMount, useWillUnmount } from '../../hooks';


const SelectMenu = ({ autoposition, parentRef, ...props }) => {
  const [listStyle, setListStyle] = useState(null);

  const listRef = useRef(null);

  useLayoutEffect(() => {
    if (!autoposition) return;

    const { height: ownHeight } = listRef.current.getBoundingClientRect();
    const { top: parentTop, bottom: parentBottom, height: parentHeight } =
      parentRef.current.getBoundingClientRect();

    // Top initial (position relatively viewport)
    let top = parentBottom - 1;
    // Move higher if does not fit at the bottom
    const bottom = top + ownHeight;
    const bottomOffset = bottom - (document.documentElement.clientHeight - 5);
    top = bottomOffset > 0 ? top - Math.max(bottomOffset, parentHeight) : top;
    // Leave at very top if does not fit on high
    top = top < 5 ? 5 : top;

    // Set top relatively parent component (position absolute)
    setListStyle({ top: top - parentTop });
  }, []);

  useDidMount(() => {
    document.addEventListener('click', props.closeMenu);
    document.addEventListener('keydown', preventArrowKeys);
  });

  useWillUnmount(() => {
    document.removeEventListener('click', props.closeMenu);
    document.removeEventListener('keydown', preventArrowKeys);
  });

  const preventArrowKeys = (e) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
    }
  }

  const updateFocused = (index, value) => {
    if (value === props.focusedValue) return;

    props.setFocused({ index, value })
  }

  const updateAndClose = (e, option) => {
    props.updateInputValue(e, option);
    props.closeMenu(e);
  }

  return (
    <ul
      className={`${props.classPrefix}__list`}
      ref={listRef}
      style={listStyle}
    >
      {props.options.map((option, index) => {
        const optionValue = props.getOptionValue(option);

        return (
          <li
            className={`${props.classPrefix}__item`}
            key={option.id || index}
            onMouseEnter={() => updateFocused(index, optionValue)}
            // pass all object in order for onInputChange can get access for all option properties
            onClick={(e) => updateAndClose(e, option)} >

            <props.SelectOption
              value={optionValue}
              option={option}
              isActive={props.defaultValue === optionValue}
              isFocused={props.focusedValue === optionValue}
              classPrefix={props.classPrefix}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default SelectMenu;