import React, { useState, useEffect, useCallback } from 'react';

import useThunkReducer from 'hooks/useThunkReducer';
import {
  initialState,
  updateSearch,
  mailContactSearchReducer,
  getContactsForSearchModal,
  filterContactsForSearchModal,
} from './MailContactSearch.reducer';
import { MAIL_SEARCH_CONTACTS_LIMIT } from 'config/constants';

import './MailContactSearch.scss';
import SearchInput from 'components/SearchInput/SearchInput';
import MailContactList from './components/MailContactList/MailContactList';

const MailContactSearch = ({ onClick }) => {
  const [query, setQuery] = useState('');

  const [state, dispatchUseReducer] = useThunkReducer(
    mailContactSearchReducer,
    initialState,
    'mail-contact-search'
  );

  const {
    ids,
    search,
    entities,
    auxiliaryIds,
    contactsPending,
  } = state;

  const isFiltered = !!search;

  const getContacts = (search, part = null) => {
    if (!isFiltered) {
      dispatchUseReducer(getContactsForSearchModal({ part }));
    }
    else {
      dispatchUseReducer(filterContactsForSearchModal({ search, part }));
    }
  }

  useEffect(() => {
    getContacts(search);
  }, [search]);

  const updateList = useCallback((part) => {
    getContacts(search, part);
  }, [search]);

  const filterBySearch = useCallback((query) => {
    dispatchUseReducer(updateSearch(query));
  }, [])

  return (
    <div className="mail-contact-list-wrap">
      <div className="mail-contact-list-wrap__header">
        <div className="mail-contact-list-wrap__label-input-wrap">
          <label
            className="mail-contact-list-wrap__search-label"
            htmlFor="search-contacts"
          >
            Search
          </label>
          <SearchInput
            maxLength="100"
            placeholder="Search Contacts"
            inputWrapClassName="mail-contact-list-wrap__search-input-wrap"
            inputClassName="mail-contact-list-wrap__search-input"
            id="search-contacts"
            query={query}
            showClearBtn
            setQuery={setQuery}
            stopSearch={filterBySearch}
            startSearch={filterBySearch}
          />
        </div>
      </div>

      <div className="mail-contact-list-wrap__list">
        <MailContactList
          ids={isFiltered ? auxiliaryIds : ids}
          entities={entities}
          loadMore={updateList}
          limit={MAIL_SEARCH_CONTACTS_LIMIT}
          onClick={onClick}
          pending={contactsPending}
          mode={`${search}`}
        />
      </div>
    </div>
  )
}

export default MailContactSearch;
