import { useCancelToken } from "hooks";

import "./Gallery.scss";
import GalleryList from "./components/GalleryList/GalleryList";
import GallerySidebar from "./components/GallerySidebar/GallerySidebar";
import GalleryHeader from './components/GalleryHeader/GalleryHeader'
import { useState } from 'react';


const Gallery = () => {
  // To cancel axios http request
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  const [isShowFilters, setIsShowFilters] = useState(false);

  return (
    <section className="gallery">
      <GallerySidebar 
        newCancelToken={newCancelToken} 
        cancelPrevRequest={cancelPrevRequest}
      />

      <GalleryHeader
        isShowFilters={isShowFilters}
        setIsShowFilters={setIsShowFilters}
      />

      <GalleryList
        isShowFilters={isShowFilters}
        newCancelToken={newCancelToken}
        cancelPrevRequest={cancelPrevRequest}
      />
    </section>
  );
};

export default Gallery;
