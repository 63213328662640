import { convertToAudio, LS } from "utils";

import { SET_USER_INFO } from "./user";

export const UPDATE_USER_LOCAL_SETTINGS = 'UPDATE_USER_LOCAL_SETTINGS';
export const UPDATE_NOTIFICATIONS_VOLUME = 'UPDATE_NOTIFICATIONS_VOLUME';
export const UPDATE_TAG_COLORS = 'UPDATE_TAG_COLORS';
export const SET_RINGTONES = 'SET_RINGTONES';

export const NOTIFICATIONS_VOLUME_TYPE = {
  ALL: 'notificationsVolume',
  CALL: 'call',
  SMS: 'sms',
  BOOKING: 'booking',
  CLIENT_SMS: 'clientSms'
};

export const setRingtones = (ringtones) => dispatch => {
  return dispatch({
    type: SET_RINGTONES,
    payload: ringtones
  })
}

export const updateUserLocalSettings = (settings, userId) => dispatch => {
  LS.updateUserStorage(settings, userId);

  return dispatch({
    type: UPDATE_USER_LOCAL_SETTINGS,
    payload: settings
  })
}

export const updateNotificationsVolume = (volume, type, userId) => ({
  type: UPDATE_NOTIFICATIONS_VOLUME,
  payload: {
    type,
    volume,
    userId,
  }
});

export const updateTagColors = (tags) => ({
  type: UPDATE_TAG_COLORS,
  payload: tags
});

const initialState = {
  agent: {},
  client: {},
  girl: {},
  service: {},
  operator: {},
  tagColors: {},
  callers: {},
  callBeep: null,
  showUserModesAtLogin: false,
  volume: {
    call: 100,
    sms: 100,
    clientSms: 100,
    booking: 100,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        pending: false,
        showUserModesAtLogin: !!action.payload['showUserModesAtLogin']
      };
    }

    case SET_RINGTONES: {
      const ringtones = {};

      if (typeof action.payload === 'object' && action.payload !== null) {
        Object.entries(action.payload).forEach(([ name, ringtone ]) => {
          if (name === 'callers') {
            ringtones[name] = {};

            Object.entries(ringtone).map(([ callerId, callerRingtone ]) => {
              ringtones[name][callerId] = {
                id: callerRingtone.id,
                caller_id: callerRingtone.id,
                type: callerRingtone.caller_type,
                fn: callerRingtone.name,
                ...convertURLsToAudio(callerRingtone),
              };
            })

            return;
          }

          if (name === 'callBeep') {
            ringtones[name] = convertToAudio(ringtone, true);
            return;
          }

          ringtones[name] = convertURLsToAudio(ringtone);
        });
      }

      return {
        ...state,
        ...ringtones,
        pending: false,
      };
    }

    case UPDATE_USER_LOCAL_SETTINGS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case UPDATE_NOTIFICATIONS_VOLUME: {
      //set initial volume from old value
      if (action.payload.type === NOTIFICATIONS_VOLUME_TYPE.ALL && typeof action.payload.volume === 'number') {
        return {
          ...state,
          volume: {
            call: action.payload.volume,
            sms: action.payload.volume,
            booking: action.payload.volume,
          },
        };
      }
      //set initial volume
      else if (action.payload.type === NOTIFICATIONS_VOLUME_TYPE.ALL) {
        return {
          ...state,
          volume: action.payload.volume,
        };
      }

      // update volume
      const updatedVolume = {
        ...state.volume,
        [action.payload.type]: action.payload.volume,
      };

      LS.setItem(NOTIFICATIONS_VOLUME_TYPE.ALL, updatedVolume, action.payload.userId);

      return {
        ...state,
        volume: updatedVolume,
      };
    }

    case UPDATE_TAG_COLORS: {
      return {
        ...state,
        tagColors: action.payload.reduce((acc, tag) => ({ ...acc, [tag.id]: tag.color }), {}),
      }
    }

    default:
      return state;
  }
};

const convertURLsToAudio = (ringtonesObj) => {
  const ringtones = {
    sms: {
      ...ringtonesObj.sms,
      sms: convertToAudio(ringtonesObj.sms, false),
    },
    call: {
      ...ringtonesObj.call,
      call: convertToAudio(ringtonesObj.call, true),
    },
    booking: {
      ...ringtonesObj.booking,
      booking: convertToAudio(ringtonesObj.booking, true),
    },
    // important: convertToAudio('./media/sounds/important-msg.mp3', false),
  };

  return ringtones;
};
