import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from '../../../../../../api/api';
import { LIMIT } from 'redux/ducks/clientChats';
import { useDidMount, useWillUnmount } from 'hooks';
import { clearSessionsHistory, getSessionsHistory } from 'redux/ducks/sessions';

import './ContactRequestsTab.scss';
import List from 'components/List/List';
import Spinner from "../../../../../../components/UI/Spinner/Spinner";
import ContactRequestsTabItem from './ContactRequestsTabItem';

const ContactRequestsTab = ({ id, isSortByNewest }) => {
  const dispatch = useDispatch();
  const [requests, setRequests] = useState([]);
  const [pending, setPending] = useState(true);

  useDidMount(() => {
    if (!id) {
      setPending(false);
      return;
    }

    dispatch(getSessionsHistory('callers', id));

    API.getContactRequests(id, undefined, isSortByNewest)
      .then(({ data }) => setRequests(data))
      .catch(console.error)
      .finally(() => setPending(false));
  });

  useWillUnmount(() => {
    dispatch(clearSessionsHistory())
  });

  const loadMore = useCallback((offset) => {
    API.getContactRequests(id, offset, isSortByNewest)
      .then(({ data }) => setRequests(prev => setRequests([...prev, ...data])));
  }, []);

  return (
    <div className="contact-requests">
      {pending 
        ? (
          <div className="contact-requests__spinner-wrap">
            <Spinner spinnerSize={30} />
          </div>
        ):(
          <List
            list={requests}
            limit={LIMIT}
            scrollInitialPosition='top'
            classPrefix='contact-requests'
            loadMore={loadMore}
            scrollbarProps={{
              renderTrackVertical: props => <div {...props} style={{ width: '4px' }} className="track-vertical" />,
              renderThumbVertical: props => <div {...props} className="scrollbars-thumb" />
            }}
          >
            <ContactRequestsTabItem />
          </List>
        )
      }
    </div>
  );
};

export default ContactRequestsTab;
