import React from 'react';

import { classModifier } from 'utils';
import useIntervalCounter from 'hooks/useCounter';

const numberOfWaves = 75;
const minHeight = 2;
const maxHeight = 10;

const waveHighs = Array.from({ length: numberOfWaves }, () =>
  Math.floor(Math.random() * (maxHeight - minHeight) + minHeight)
);

const MediaWaves = React.forwardRef((props, ref) => {
  const counter = props.stopped ? 0 : useIntervalCounter(numberOfWaves, 500);

  const getHeighs = (i) => {
    return waveHighs[i - counter + 1 > 0 ? i - counter : numberOfWaves - counter + i];
  };

  return (
    <div
      ref={ref}
      className='voice-message__waves-container'
    >
      <div className={classModifier('voice-message__waves-wrapper')} >
        {Array.from({ length: numberOfWaves }, (_, i) => (
          <div style={{
            height: getHeighs(i),
          }} />
        ))}
      </div>
    </div>
  )
})

export default MediaWaves;
