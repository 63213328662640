import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { useDidMount } from 'hooks';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { selectUserTimezone } from 'redux/selectors/selectors';
import { addNewArrayGirlsToState } from 'redux/ducks/contacts';
import { closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { getUnfinishedBookingsByCallerId } from 'redux/ducks/bookings';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';

import './BookingsListModal.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import { BookingItemNew } from 'components/BookingItemNew';

const BookingsListModal = ({
  contactId,
  userHour12,
  girlDivaId,
  userTimezone,
  activeRecipient,
  onQuickBookingClick,
  unfinishedBookings,
  ...props
}) => {
  const [bookings, setBookings] = useState(null);

  const dispatch = useDispatch();

  useDidMount(() => {
    props.getUnfinishedBookingsByCallerId(contactId)
      .then((gettedBookings) => {
        setBookings(gettedBookings);
      })
      .catch(console.error)
  });

  const handleBookingModal = () => {
    dispatch(closeModal(MODAL_TYPES.bookingsList));
    onQuickBookingClick();
  };

  return (
    <div className='bookings-list-modal'>
      <div className='bookings-list-modal__header'>
        <span className='bookings-list-modal__prebookings-counter'>Prebookings - {bookings?.length}</span>
        <button
          className='bookings-list-modal__btn'
          onClick={handleBookingModal}
        >
          New Booking
        </button>
      </div>

      <ul className='bookings-list-modal__main'>
        {bookings
          ? bookings.map((booking => (
            <BookingItemNew
              key={booking.id}
              item={booking}
              type={BOOKING_ITEM_VIEWS.SESSION}
            />
          )))
          : <Spinner spinnerSize={50} className='bookings-list-modal__spinner' />
        }
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userHour12: state.user.hour12,
  userTimezone: selectUserTimezone(state),
  // unfinishedBookings: Object.values(state.bookings.entities).filter(booking => booking.status === 2 && booking.callerId === contactId),
  activeRecipient: state.contacts.entities[state.clientChats.active],
});

const mapDispatchToProps = {
  addNewArrayGirlsToState,
  updateActiveContact,
  getUnfinishedBookingsByCallerId,
};


export default connect(mapStateToProps, mapDispatchToProps)(BookingsListModal);
