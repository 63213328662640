import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import { selectModalCount } from 'redux/selectors/selectors';
import TagBlock from 'components/UI/TagBlock/TagBlock';
import Portal from 'components/Portal';
import SeeNotesPopup from 'components/SeeNotesPopup/SeeNotesPopup';
import 'components/SeeNotesPopup/SeeNotesPopup.scss'

const SeeNotesTag = ({
  caller,
  children,
  ...props
}) => {
  const modalCount = useSelector((state) => selectModalCount(state));
  const isModalOpened = !!modalCount;

  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    placement: 'bottom',
    visible: isVisiblePopup,
  });

  const clearTimeoutId = () => {
    if(timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeoutId();
    }
  }, [timeoutId]);

  const showPopup = () => {
    clearTimeoutId();
    setIsVisiblePopup(true);
  }

  const hidePopup = () => {
    const id = setTimeout(() => {
      setIsVisiblePopup(false);
    }, 200);

    setTimeoutId(id);
  }

  return (
    <div className='see-notes-popup__tag-wrapper' ref={setTriggerRef} onMouseEnter={showPopup} onMouseLeave={hidePopup}>
      <TagBlock {...props}>
        {children}
      </TagBlock>

      {isVisiblePopup && caller && !isModalOpened &&
        <Portal>
          <div 
            {...getTooltipProps({ className: "see-notes-popup__wrapper" })}
            ref={setTooltipRef}
            onMouseEnter={showPopup}
            onMouseLeave={hidePopup}
          >
            <SeeNotesPopup 
              activeRecipient={caller}
            />
          </div>
        </Portal>
      }
    </div>
  )
}

export default SeeNotesTag;
