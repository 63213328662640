import { BOOKING_MARKERS } from '../config/constants'

export default (booking) => (markers) => {
  return markers.filter((marker) => {
    switch (marker) {
      case BOOKING_MARKERS.BOOKING:
        return !booking.is_prebooking;
      case BOOKING_MARKERS.PREBOOKING:
        return booking.is_prebooking;
    }
  })
}
