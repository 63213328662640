import React, { useState, useRef } from 'react';
import { connect, useDispatch } from "react-redux";

import API from 'api/api';
import { sendMessage } from "redux/ducks/clientChats";
import { CHAT_TYPES } from 'config/constants';
import { playMedia } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';

import ICONS from 'assets/icons';
import CallTimer from "components/CallItem/CallTimer";
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import MediaWaves from './MediaWaves/MediaWaves';

const basicRecoderObject = {
    record: false,
    recorder: null,
    mediaStream: null,
    messageObj: null,
}

const VoiceMessageRecorder = (props) => {
  const [message, setMessage] = useState(null);

  const [recorder, setRecorder] = useState(basicRecoderObject);

  const blobDuration = useRef();
  const isImmediatelySend = useRef();

  const dispatch = useDispatch();
  const waveRef = useRef();
  

  const setBlobDuration = (duration) => {
    blobDuration.current = duration;
  };

  const setIsImmediatelySend = (flag) => {
    isImmediatelySend.current = flag;
  };


  const startRecord = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const mimeType = MediaRecorder.isTypeSupported('audio/webm') && 'audio/webm';
        
        const mediaRecorder = new MediaRecorder(
          stream,
          mimeType
            ? { mimeType }
            : null
        );

        const audioChunks = [];

        mediaRecorder.start();

        mediaRecorder.addEventListener("dataavailable", event => {
          audioChunks.push(event.data);
        });
        
        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(audioChunks, {
            type: 'audio/webm'
          });

          const duration = blobDuration.current;

          const messageObj = {
            text: '',
            voiceMsg: {
              blob: audioBlob,
              duration: duration
            },
            shortcuts: '',
            sharedMsgId: '',
            sharedVoiceMsg: '',
            sharedContactId: '',
            channel: props.activeRecipient.default_channel,
          };

          setMessage(messageObj);

          if (isImmediatelySend.current) {
            setIsImmediatelySend(false);
            props.chatType === CHAT_TYPES.ROOM
              ? API.sendMessageToChat(messageObj, props.activeRecipient.id)
              : props.sendMessage(messageObj, props.activeRecipient);
          }
          else {
            props.updateVoiceMsg(audioBlob, duration);
          }
        });

        setRecorder({
          record: true,
          recorder: mediaRecorder,
          mediaStream: stream
        });
      })
      .catch(err => console.dir(err));
  };

  const stopRecord = (sendRightNow) => {
    if (blobDuration.current > 1000) {
      if (sendRightNow === true) {
        setIsImmediatelySend(true);
      }
  
      recorder.mediaStream.getTracks()[0].stop();
      recorder.recorder.stop();
    }
  };

  const onTick = tick => {
    setBlobDuration(tick);

    if (tick >= 600000) {
      stopRecord();
    }
  };

  const clearVoiceMessage = () => {
    setRecorder(basicRecoderObject);
    setMessage(null);
    props.updateVoiceMsg();
  }

  const getHandleButton = () => {
    if (message) {
      return (
        <button
          className="chat-input__send-btn"
          onClick={props.handleSubmit}
        >
          <ICONS.arrow className="chat-input__send-btn-icon" />
        </button>
      )
    }

    if (recorder.record) {
      return (
        <button
          type='button'
          className={classModifier('chat-input__btn-record', 'active')}
          onClick={stopRecord}
        >
          <ICONS.stopBagel className={classModifier('chat-input__btn-record-icon', 'active')} />
        </button>
      )
    }

    return (
      <button
        type='button'
        className='chat-input__btn-record'
        onClick={startRecord}
      >
        <ICONS.bagel className='chat-input__btn-record-icon' />
      </button>
    )
  }

  const renderWavesDom = (wavesRef) => (nodeElement) => {
    nodeElement && wavesRef && nodeElement.replaceWith(wavesRef)
  };

  return (
    <div className='chat-input__record-container'>
      {getHandleButton()}

      {recorder.record && message &&
        <div className={classModifier('voice-message', 'extra-button')}>
          <AudioPlayer
            className="voice-message-slider"
            src={URL.createObjectURL(message.voiceMsg.blob)}
            blobDuration={message.voiceMsg.duration}
            mimeType={message.voiceMsg.blob?.type}
            onPlay={dispatch(playMedia())}
            hideTimePosition
          >
            <div ref={renderWavesDom(waveRef.current)} />
          </AudioPlayer>

          <button
            className='voice-message__record-button-close'
            onClick={clearVoiceMessage}
          >
            <ICONS.close className='voice-message__record-button-close-icon'/>
          </button>
        </div>
      }

      {recorder.record && !message &&
        <div className='voice-message'>
          <div className='voice-message__indicator'></div>

          <MediaWaves ref={waveRef} />

          <CallTimer
            className="voice-message__time"
            timer={new Date()}
            onUpdate={onTick}
            onStop={setBlobDuration}
          />
        </div>
      }

      {!recorder.record &&
        <span className='chat-input__placeholder'>click to start recording</span>
      }
    </div>
  );
};

const mapDispatchToProps = {
  sendMessage: sendMessage,
};

export default connect(null, mapDispatchToProps)(VoiceMessageRecorder);
