import React from 'react';


const Loading = ({ error, timedOut, retry, pastDelay }) => {
  if (error) {
    return <div style={{ position: 'absolute' }}>Error! <button onClick={retry}>Retry</button></div>;
  } else if (timedOut) {
    return <div> style={{ position: 'absolute' }}Taking a long time... <button onClick={retry}>Retry</button></div>;
  } else if (pastDelay) {
    return <div style={{ position: 'absolute' }}>Loading...</div>;
  } else {
    return null;
  }
}

export default Loading;