import { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { usePopperTooltip } from 'react-popper-tooltip';

import { usePrevious } from 'hooks';
import { SELECT_THEMES } from 'config/constants';

import ICONS from 'assets/icons';
import PhoneLabelSelect from 'components/PhoneLabelSelect/PhoneLabelSelect';
import Portal from 'components/Portal';
import Select from 'components/UI/Select/Select';


const Option = ({ data, innerProps, innerRef, editLabelsModalRef, ...props }) => {
  const [isEditLabelsModalOpen, setIsEditLabelsModalOpen] = useState(false);
  const [isAlreadyEditLabelsModalClosed, setIsAlreadyEditLabelsModalClosed] = useState(data.id === undefined);
  const previousIsEditLabelsModalOpen = usePrevious(isEditLabelsModalOpen);
  const optionRef = useRef();

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    trigger: "click",
    placement: 'auto',
    closeOnOutsideClick: false,
    visible: isEditLabelsModalOpen,
    onVisibleChange: setIsEditLabelsModalOpen
  })

  const handleClose = (event) => {
    const isClickOutsideEditLabelsModal = editLabelsModalRef.current && !editLabelsModalRef.current.contains(event.target);
    const isClickInsideOption = optionRef.current?.textContent === event.target?.textContent
      && !event.target.classList.contains('select__control')
      && !event.target.classList.contains('select__value-container');

    if ((isAlreadyEditLabelsModalClosed && event.target.textContent !== 'Edit tags')
      // || isClickInsideOption
      || isClickOutsideEditLabelsModal) {
      props.closeMenu();
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClose);

    return () => {
      document.removeEventListener('click', handleClose);
    }
  }, [isAlreadyEditLabelsModalClosed])

  useEffect(() => {
    if (previousIsEditLabelsModalOpen && !isEditLabelsModalOpen) {
      setIsAlreadyEditLabelsModalClosed(true);
    }

    if (isAlreadyEditLabelsModalClosed && isEditLabelsModalOpen) {
      setIsAlreadyEditLabelsModalClosed(false);
    }
  }, [isEditLabelsModalOpen, isAlreadyEditLabelsModalClosed])

  const closeDropdownMenu = () => {
    setIsEditLabelsModalOpen(false);
  }

  const optionProps = data.value === 'special'
    ? { innerRef: setTriggerRef, ...props }
    : { innerProps: { ...innerProps, ref: optionRef }, innerRef, ...props }

  return (
    <>
      <components.Option {...optionProps} />

      {isEditLabelsModalOpen && (
        <Portal>
          <PhoneLabelSelect
            ref={setTooltipRef}
            getTooltipProps={getTooltipProps}
            phoneLabels={props.options}
            setPhoneLabels={props.setOptions}
            editLabelsModalRef={editLabelsModalRef}
            closeDropdownMenu={closeDropdownMenu}
            onChange={props.onChange}
          />
        </Portal>
      )}
    </>
  )
}


const PhoneNumbersTag = ({ options, ...props }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const previousMenuIsOpen = usePrevious(menuIsOpen);
  const editLabelsModalRef = useRef();

  const optionsWithEditTags = [
    { label: 'Edit tags', value: 'special', id: 0, rightIcon: <ICONS.pencil /> },
    ...options,
  ];

  const OptionsProps = {
    options,
    setOptions: props.setOptions,
    menuIsOpen,
    onChange: props.onChange,
    editLabelsModalRef,
    closeMenu: () => setMenuIsOpen(false),
  }

  const handleClose = () => {
    if (editLabelsModalRef.current) return;

    setMenuIsOpen(false);
  }

  useEffect(() => {
    if (previousMenuIsOpen && !menuIsOpen) {
      props.setOptions((prevState) => {
        return prevState.map((option, index) => {
          if (!option.id) {
            return { ...option, id: index }
          } else {
            return option
          }
        })
      })
    }
  }, [menuIsOpen])

  return (
    <Select
      type={SELECT_THEMES.DARK}
      options={optionsWithEditTags}
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={handleClose}
      isSearchable={false}
      components={{
        Option: (props) => <Option {...props} {...OptionsProps} />,
      }}
      {...props}
    />
  )
}

export default PhoneNumbersTag;
