/*
*** Popper computeStyles modifier
*** which centers the popup relative to the viewport

*** See docs
*** https://github.com/floating-ui/floating-ui/issues/642
*/

const popperViewportCenter = {
  name: 'computeStyles',

  fn: ({ state }) => {
    return {
      ...state,
      styles: {
        ...state.styles,
        popper: {
          ...state.styles.popper,
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        }
      }
    };
  },
}

export default popperViewportCenter;
