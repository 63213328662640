import { useState } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';
import { usePopperTooltip } from 'react-popper-tooltip';

import './FormTextInput.scss';

const FormTextInput = (props) => {
  const {
    inputProps = {},
    isShowEX,
    className,
    serverErrors,
    inputClassName,
    isErrorRelative,
    errorInputClassName,
    input: finalFormInputProps,
    submitFailedNotRequiredForError,
    meta: { error, touched, active, submitFailed },
    showTooltipWhenOvercrowded,
  } = props;
  const [isNotFitted, setIsNotFitted] = useState(false);
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: 'top',
    closeOnTriggerHidden: true,
  })
  
  const isSubmitFailed = submitFailedNotRequiredForError ? true : submitFailed;
  const { value } = props.input;

  const inputCallbackRef = (node) => {
    if (node?.offsetWidth && node?.scrollWidth && showTooltipWhenOvercrowded) {
      if (node.scrollWidth > node.offsetWidth) {
        setIsNotFitted(true);
      } else {
        setIsNotFitted(false);
      }
    }
  };

  const renderError = (errorMsg) => (
    <div className={classModifier('form-text-content__error', isErrorRelative && 'relative')}>
      {errorMsg}
    </div>
  );

  const isClientError = error && touched && !active && isSubmitFailed;
  const isServerError = serverErrors && serverErrors[finalFormInputProps.name];
  const errorInputClass = (isClientError || isServerError)
    ? classNames(errorInputClassName, 'input--error')
    : '';

  const startWithEx = isShowEX && (value || '').toString().startsWith('EX ');

  return (
    <div
      className={classNames('form-text-content', className)}
      ref={setTriggerRef}
    >
      <input
        {...finalFormInputProps}
        ref={inputCallbackRef}
        type={finalFormInputProps.type || 'text'}
        className={classNames('form-text-content__input', 'input', inputClassName, errorInputClass)}
        {...inputProps}
      />

      {showTooltipWhenOvercrowded && isNotFitted && visible && !active &&
        <div
          className="popup"
          ref={setTooltipRef}
          {...getTooltipProps()}
        >
          { value }
        </div>
      }

      {startWithEx &&
        <div className='form-text-content__ex'>
          EX
        </div>
      }

      {isClientError
        ? renderError(error)
        : isServerError
          ? renderError(serverErrors[finalFormInputProps.name])
          : null
      }
    </div>
  )
}

export default FormTextInput;
