import React, { useState, useRef, useEffect, Fragment, useImperativeHandle } from 'react';

import './AudioPlayer.scss';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';

const maxCountOfSteps = 100; // 100%

const Slider = React.forwardRef((props, ref) => {
  const {
    zoom,
    onUpdate,
    isVertical,
    countOfSteps,
    thumbWasMoved, // flag for render the slider after change values in parent component
    isActive,
    isShowZoomBtns,
    updateThumbFromParentRef
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [countInStep, setCountInStep] = useState(null); // how much steps in step (in proportion). Example: 2 step of 5 It's 20/100
  const [clientChoords, setClientChoords] = useState({
    start: 0,
    end: 0
  });

  const sliderRef = useRef({});
  const thumbRef = useRef({});

  const className = props.className || "player";

  useEffect(() => {
    isActive && ref?.current.focus();
  })

  useEffect(() => { // Init
    setCurrentStep(props.currentStep);
    setCountInStep(maxCountOfSteps / countOfSteps);

    const { 
      x: start,
      width,
    } = sliderRef.current.getBoundingClientRect();

    setClientChoords({
      start,
      end: start + width
    });
  }, []);
  
  useEffect(() => { // Main slider
    if (thumbWasMoved !== undefined) {
      let currStep = currentStep;

      if (currStep / countInStep !== props.currentStep - 1) { // not normal. seconds in player not equal slider step
        setCurrentStep((props.currentStep - 1) * countInStep);
        currStep = (props.currentStep - 1) * countInStep;
      }

      if (currStep !== countInStep && currStep / countInStep === countOfSteps) {
        return setCurrentStep(0);
      }
      
      setCurrentStep((currStep / countInStep * countInStep) + countInStep);
    }
  }, [!!thumbWasMoved && thumbWasMoved, countInStep]);

  const onMouseDown = e => {
    e.stopPropagation();
    updateThumbCoords(isVertical ? e.clientY : e.clientX);

    ref?.current.focus();

    document.addEventListener("mouseup", onMouseUp, { capture: true });
    document.addEventListener("mousemove", onMouseMove, { capture: true });
  };

  const onMouseUp = e => {
    e.stopPropagation();
    document.removeEventListener("mouseup", onMouseUp, { capture: true });
    document.removeEventListener("mousemove", onMouseMove, { capture: true });
  };
  
  const onMouseMove = e => {
    e.stopPropagation();
    updateThumbCoords(isVertical ? e.clientY : e.clientX);
  };

  const getThumbChoordFromValue = volume => {
    /*
      dif = end - start

      x - volume %
      dif  - 100 %

      x = dif * volume / 100
      result =  x + start
    */

    const difference = clientChoords.end - clientChoords.start;
    const result = (difference * volume / 100) + clientChoords.start;
    return result;
  }

  useImperativeHandle(updateThumbFromParentRef, () => ({
    muteVolume: () => {
      const choordFromCurrentVolume = getThumbChoordFromValue(0);
      updateThumbCoords(choordFromCurrentVolume);
    }, 
  }));

  const updateThumbCoords = (clientChoord) => {
    const { 
      top: sliderTop,
      left: sliderLeft,
      width: sliderWidth,
      height: sliderHeight,
    } = sliderRef.current.getBoundingClientRect();
    const { width: thumbWidth, height: thumbHeight } = thumbRef.current.getBoundingClientRect();

    const sliderWidthOrHeight = isVertical ? sliderHeight : sliderWidth;
    const thumbWidthOrHeight = isVertical ? thumbHeight : thumbWidth;;
    const rightEdge = sliderWidthOrHeight - thumbWidthOrHeight;

    const currentLeftOrTop = (isVertical ? sliderTop : sliderLeft) + (thumbWidthOrHeight / 2);
    const shift = clientChoord - currentLeftOrTop;

    const sizeOfStep = rightEdge / maxCountOfSteps; // in pixels

    if (clientChoord < currentLeftOrTop) {
      setCurrentStep(0);
      onUpdate(0);

      return;
    }
    else if (shift > rightEdge) {
      setCurrentStep(maxCountOfSteps);
      onUpdate(countOfSteps);

      return;
    }

    const newCurrentStepInPercentage = shift / sizeOfStep; // For Slider
    const newCurrentStepInSeconds = newCurrentStepInPercentage / countInStep; // For Player

    setCurrentStep(newCurrentStepInPercentage);
    onUpdate(newCurrentStepInSeconds);
  };

  const getLeftOffsetFor = (forWhat) => {
    const leftInPercentage = currentStep;

    const thumbWidth = thumbRef.current.offsetWidth;
    const substractWidth = forWhat === 'left-path' // left-path It's line that moves behind thumb
      ? 0
      : thumbWidth * (currentStep / 100);

    return `calc(${leftInPercentage}% - ${substractWidth}px)`;
  }

  const setZoomByClick = step => {
    onUpdate(zoom - 1 + step);
  }

  return (
    <Fragment>
      <div
        className={`${className}__slider`}
        onMouseDownCapture={onMouseDown}
        onClickCapture={e => e.stopPropagation()}
        ref={sliderRef}
      >
        {props.path}

        <div className={classModifier(`${className}__slider-path`, [props.isModernThumb && "modern"])}>
          <div
            className={classModifier(`${className}__slider-left-path`, [props.isModernThumb && "modern"])}
            style={{
              width: getLeftOffsetFor("left-path")
            }}
          />

          <div
            className={classModifier(`${className}__thumb`, [props.isModernThumb && "modern"])}
            ref={thumbRef}
            style={{
              left: getLeftOffsetFor("thumb")
            }}
          />
        </div>
      </div>

      {isShowZoomBtns && 
        <div className={`${className}__slider-zoom-btns`}>
          <button type='button' onClick={() => setZoomByClick(0.2)} className={`${className}__slider-zoom-btn`}>
            <ICONS.plusCircle />
          </button>
          <button type='button' onClick={() => setZoomByClick(-0.2)} className={`${className}__slider-zoom-btn`}>
            <ICONS.stop />
          </button>
        </div>
      }
    
    </Fragment>
    );
});

export default Slider;
