import { useSelector } from 'react-redux';
import { selectUserTimezone } from 'redux/selectors/selectors';
import checkTimeRange from 'utils/checkTimeRange';

const useActiveOperators = (ids, selector) => {
  const operators = useSelector((state) => state.operators.entities);
  const userTimezone = useSelector(selectUserTimezone);

  const activeOperatorsIds = Object.keys(ids).filter((id) => {
    const isOperatorOfflineAfterWork
      = checkTimeRange('01:00', '09:00', userTimezone) && operators[id]?.status === 'offline';

    return !isOperatorOfflineAfterWork && operators[id];
  });

  return activeOperatorsIds
    .map(id => operators[id])
    .map(selector);
}

export default useActiveOperators;
