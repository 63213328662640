import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons'
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';

import './ChatSendingMessage.scss';
import { clearForwardedMessage } from 'redux/ducks/mailing';

const ChatSendingMessage = memo(({ inputProps, handleSubmit, onKeyDown, hideSendButton, ...props }) => {
  const forwardedMessage = useSelector((state) => state.mailing.forwardedMessage);

  const dispatch = useDispatch();

  const onAdditionalChange = useCallback(() => {
    if (forwardedMessage) {
      dispatch(clearForwardedMessage());
    }
  }, [forwardedMessage])
  
  const handleKeyDown = (e) => {
    onKeyDown && onKeyDown(e);

    if (e.key === 'Enter' && (e.metaKey || e.shiftKey) && !props.isScheduledMsgsSource) {
      e.preventDefault();
      handleSubmit?.(e);
    }
  }

  const defaultExtraButton = (
    <button
      type="submit"
      className="sending-message-form__send-btn"
    >
      <ICONS.arrow />
    </button>
  );

  return (
    <FormTextTextarea
      className="sending-message-form"
      inputClassName="sending-message-form__input"
      onAdditionalChange={onAdditionalChange}
      autosize
      extraButtons={!hideSendButton && defaultExtraButton}
      inputProps={{
        ...inputProps,
        onKeyDown: handleKeyDown,
        rows: 1,
      }}
      {...props}
    />
  )
});

export default ChatSendingMessage;
