import React from 'react';

import './ModalImage.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const ModalImage = ({ src, isRound }) => (
  <LazyLoadImage
    alt={'profile'}
    src={src}
    className={`modal-img ${isRound ? "modal-img--round" : ""}`}
  />
)


export default ModalImage;
