import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import API from 'api/api';
import { useWillUnmount } from 'hooks';
import { classModifier } from 'utils';

import { selectPrivateOperatorRoom } from 'redux/selectors/selectors';
import './RoomCreationForm.scss';
import RoomCreationFormItem from './RoomCreationFormItem';
import AsyncButton from 'components/AsyncButton/AsyncButton';
import FormTextInput from 'components/FormTextInput/FormTextInput';

const RoomCreationForm = (props) => {
  const {
    userId,
    editingRoom,
    clearEditingRoom,
  } = props;
  
  const [isPending, setIsPending] = useState(false);
  const [isServerErrors, setIsServerErrors] = useState(false);
  const [participantIds, setParticipantIds] = useState(editingRoom?.usersIds || []);
  
  const operatorIds = useSelector(state => state.operators.ids);
  const operatorEntities = useSelector(state => state.operators.entities);
  
  const isEditingPrivateRoom = editingRoom ? editingRoom.usersIds.length === 2 : false;
  const privateRoomFound = useSelector((state) => selectPrivateOperatorRoom(state, participantIds[editingRoom ? 1 : 0])); 
  const [isRoomExistWarn, setRoomExistWarn] = useState(false);

  useWillUnmount(() => {
    clearEditingRoom();
  })

  const onAddParticipant = (operatorId) => {
    if (participantIds.includes(operatorId)) {
      setParticipantIds(prev => prev.filter(id => id !== operatorId));
    }
    else {
      setParticipantIds(prev => [...prev, operatorId]);
    }
  }

  useEffect(() => {
      if(!isEditingPrivateRoom && (participantIds.length === (editingRoom ? 2 : 1)) && privateRoomFound){
        setRoomExistWarn(true);
      } else {
        setRoomExistWarn(false);
      }
  
  }, [participantIds])

  const onSubmitHandler = (values) => {
    setIsPending(true);
    setIsServerErrors(false);

    (editingRoom
      ? API.updateRoom({
          chatId: editingRoom.id,
          roomName: values.roomName,
          usersIds: participantIds,
        })
      : API.saveRoom(values.roomName, participantIds)
    )
      .then(onCancel)
      .catch((err) => {
        setIsPending(false);
        setIsServerErrors(true);
        console.error(err);
      });
  }

  const onCancel = () => {
    props.closeForm();
    editingRoom && clearEditingRoom();
  }

  return (
    <Form
      onSubmit={onSubmitHandler}
      initialValues={{ roomName: editingRoom?.chatTitle || ''}}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="room-creation-form">
          <Field
            name="roomName"
            component={FormTextInput}
            className="room-creation-form__input-wrap"
            inputClassName="room-creation-form__input"
          />

          <h4 className="room-creation-form__list-title">
            {isEditingPrivateRoom ? "Participants" : "Add participants"}
          </h4>

          <ul className="room-creation-form__list">
            {operatorIds.map(id => (
              <RoomCreationFormItem
                key={id}
                userId={userId}
                operator={operatorEntities[id]}
                editingRoom={editingRoom}
                onClick={onAddParticipant}
                participantIds={participantIds}
                isEditingPrivateRoom={isEditingPrivateRoom}
              />
            ))}
          </ul>

          {isServerErrors || isRoomExistWarn &&
            <div className="room-creation-form__server-error-wrap">
              <p className="room-creation-form__server-error">
                {isRoomExistWarn ? "Such group already exist" : "Something went wrong"}
              </p>
            </div>
          }

          <div className="room-creation-form__actions">
            <button 
              type="button"
              className={`btn ${classModifier('room-creation-form__btn', 'cancel')}`}
              onClick={onCancel}
            >
              Cancel
            </button>

            
            <AsyncButton 
              className={`btn btn--primary ${classModifier('room-creation-form__btn', 'submit')}`}
              pending={isPending}
              disabled={isRoomExistWarn}
            >
              Done
            </AsyncButton>
            
          </div>
        </form>
      )}
    />
  )
};

export default RoomCreationForm;

const validate = ({ roomName }) => {
  const errors = {};

  if (!roomName || !roomName.trim().length) {
    errors.roomName = 'Enter name';
  }

  return errors;
}