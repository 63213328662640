import React from 'react';

import { DIVA_GIRLS_VIDEO_HOST } from 'redux/ducks/divaGirls';

import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';

const GalleryVideo = (props) => {
  const {
    item: video,
    preview,
    isAutoFocus,
    isActive,
  } = props;

  const src = (video.startsWith('http') || video.startsWith('blob')) ? video : DIVA_GIRLS_VIDEO_HOST + video;

  return preview
    ? (
      <VideoThumbnail
        isActive={isActive}
        src={src}
        onClick={props.onClick}
        hideDuration
      />
    ) : (
      <VideoPlayer
        isActive={isActive}
        src={src}
        onPlay={props.playMedia}
        isAutoFocus={isAutoFocus}
      />
    )
}

export default GalleryVideo;
