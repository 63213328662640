import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";

import {
  selectSessionIdsForGallery,
  selectPropertyOfActiveSession,
} from "redux/selectors/selectors";
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';
import { updateProfileFiltersState } from 'redux/ducks/sessions';
import { classModifier } from 'utils';

import './GalleryList.scss';
import Spinner from "components/UI/Spinner/Spinner";
import GalleryProfileCard from "../GalleryProfileCard/GalleryProfileCard";
import CustomScrollbarThumb from "components/UI/CustomScrollbarThumb/CustomScrollbarThumb";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";
import { usePrevious } from '../../../../hooks';

const GalleryList = ({
  ids,
  apiParams,
  loadProfilesPending,
  additionalFilters,
  newCancelToken,
  cancelPrevRequest,
  activeFilters,
  getActiveDivaGirls,
  activeSession,
  isShowFilters,
  isMaxGalleryPart,
  ...props
}) => {
  const [part, setPart] = useState(2);
  const [limit, setLimit] = useState(20);

  const galleryListRef = useRef(null);
  const profileListRef = useRef(null);

  useEffect(() => {
    if (apiParams) {
      const filterBy = apiParams.filterby;
      const isActiveFilters = !!activeFilters
        .filter((filter) => filter !== 'availableNow' && filter !== 'bestmatch')
        .length;
      const sameParams = apiParams['same-params']?.split('bestmatch')[0]; // bestmatch - Is Used For Receiving Host Profile Similarity Percentage
      const isAllProfiles = !sameParams && !filterBy && !isActiveFilters;

      if (isAllProfiles && loadProfilesPending && !Array.isArray(apiParams)) {
        cancelPrevRequest();

        getActiveDivaGirls({
          params: { ...apiParams, cancelToken: newCancelToken(), limit: limit },
          additionalFilters: additionalFilters,
          cutIds: true,
          isGallery: true,
        });
      }
    }
  }, [apiParams, loadProfilesPending]);

  useEffect(() => {
    if(!Object.values(additionalFilters).flat().length) {
      props.updateProfileFiltersState({ 'city': ['London'] }, activeSession, additionalFilters);
    }
  }, []);

  useEffect(() => {
    if (apiParams) {
      setPart(2);
    }
  }, [apiParams]);

  useEffect(() => {
    const loadMoreProfilesIfItNeed = (limit) => {
      if (ids.length < limit && !loadProfilesPending && !!profileListRef.current) {
        const profilesWrapHeight = galleryListRef.current.scrollHeight;
        const profilesHeight = profileListRef.current.scrollHeight;

        if (profilesWrapHeight > profilesHeight) {
          if (part === 'max-part' || !!isMaxGalleryPart) return;

          const params = {
            ...apiParams,
            part: Math.ceil(ids.length / limit + 1),
            limit: limit,
          }

          getActiveDivaGirls({ params, additionalFilters, isGallery: true, isGalleryListLoadMore: true })
            .then(res => {
              setPart(prev => res === 'max-part' ? res : prev + 1);
            });
        }
      }
    };

    const handleResize = () => {
      const limit = calculateCardsLimit();

      setLimit(limit);
      loadMoreProfilesIfItNeed(limit);
    };

    setLimit(calculateCardsLimit());
    loadMoreProfilesIfItNeed(calculateCardsLimit());

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [ids.length, apiParams, loadProfilesPending, isShowFilters]);

  const calculateCardsLimit = () => {
    // calculate the limit of cards that will fit in the gallery
    const galleryWidth = galleryListRef.current.offsetWidth;
    const galleryHeight = galleryListRef.current.offsetHeight;
    const cardWidth = 160;
    const cardHeight = 285;
    const marginHorizontal = 20;
    const marginVertical = 15;

    const cardsPerRow = Math.floor(galleryWidth / (cardWidth + marginHorizontal));
    const rows = Math.floor(galleryHeight / (cardHeight + marginVertical)) + 1;

    return Math.floor((cardsPerRow * rows) * 1.25);
  };

  const onScroll = e => {
    if (part === 'max-part' || !!isMaxGalleryPart || apiParams === null) return;

    const scrollHeightErrorOnPageScaling = 1.5;
    const list = e.target;
    const scrollBottom = list.scrollHeight - list.clientHeight - list.scrollTop;

    if (
      scrollBottom <= scrollHeightErrorOnPageScaling && 
      ids.length >= limit
    ) {
      getActiveDivaGirls({ params: { ...apiParams, part, limit: limit }, additionalFilters, isGallery: true })
        .then(res => {
          setPart(res === 'max-part' ? res : part + 1);
        });
    }
  }

  return (
    <div
      className={classModifier('gallery-list', [
        (!ids.length || loadProfilesPending) && 'centered',
      ])}
      ref={galleryListRef}
    >
      {loadProfilesPending ? (
        <Spinner spinnerSize={75} />
      ) : !ids.length ? (
        <p className="gallery-list__no-profiles">No profiles</p>
      ) : (
        <Scrollbars
          onScroll={onScroll}
          renderThumbVertical={CustomScrollbarThumb}
          renderTrackVertical={CustomScrollbarTrack}
        >
          <ul className="gallery-list__profile-list" ref={profileListRef}>
            {ids?.map(id => (
              <GalleryProfileCard key={id} id={id} />
            ))}
          </ul>
        </Scrollbars>
      )}
    </div>
  );
};

const mSTP = state => ({
  ids: selectSessionIdsForGallery(state),
  apiParams: state.divaGirls.filterDivaGirlsApiParams,
  loadProfilesPending: state.divaGirls.loadProfilesPending,
  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession]
    && selectPropertyOfActiveSession(state, 'additionalFilters')),
  activeFilters: (!!state.sessions.entities[state.sessions.activeSession]
    && selectPropertyOfActiveSession(state, 'activeFilters')),
  activeSession: state.sessions.activeSession,
  isMaxGalleryPart: state.divaGirls.isMaxGalleryPart,
});

const mDTP = {
  getActiveDivaGirls,
  updateProfileFiltersState,
}

export default connect(mSTP, mDTP)(GalleryList);
