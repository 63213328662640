import { useEffect, useState } from 'react';
import { usePrevious } from '../../hooks';

// Use when we need animate element before he will unmount
const DelayedComponent = ({ isMount, ...props }) => {
  const [shouldRender, setShouldRender] = useState(isMount);
  const prevIsMount = usePrevious(isMount);

  useEffect(() => {
    let timer;

    if (!isMount && prevIsMount) {
      timer = setTimeout(() => {
        setShouldRender(false);
      }, props.delayUnmountTime || 500);
    }
    else if (!prevIsMount && isMount) {
      setShouldRender(true)
    }

    return () => {
      clearTimeout(timer);
    }
  }, [shouldRender, isMount])

  return shouldRender
    ? props.children
    : null;
}

export default DelayedComponent;