import { convertDateFromUTC } from "utils";

export const TIMER_START = 'TIMER_START';
export const TIMER_TICK = 'TIMER_TICK';
export const TIMER_STOP = 'TIMER_STOP';

let timerList = {};



export const timerStart = (callId, initValue) => dispatch => {
  // if this timer already exist
  if (timerList[callId]) return;

  timerList[callId] = true;

  const initDate = initValue
    ? convertDateFromUTC(initValue)
    : new Date();

  const timer = {
    [callId]: initDate
  }

  dispatch({
    type: TIMER_START,
    payload: timer
  })
}

export const timerStop = (callId) => dispatch => {
  delete timerList[callId];

  dispatch({
    type: TIMER_STOP,
    payload: callId
  });
}

const initialState = {
  // 'conf_1': new Date()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TIMER_START: {
      return {
        ...state,
        ...action.payload
      };
    }
    case TIMER_STOP: {
      const updatedState = { ...state };

      delete updatedState[action.payload];

      return updatedState;
    }
    default:
      return state;
  }
};
