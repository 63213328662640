import API from 'api/api';
import { addToEntitiesIfMissing } from 'utils';

const GET_ALL_MAILING_MESSAGES = 'GET_ALL_MAILING_MESSAGES';
const SET_PENDING = 'SET_PENDING';
const SET_SENDING_PENDING = 'SET_SENDING_PENDING';
const ADD_MESSAGE = 'ADD_MESSAGE';
const ADD_LOADING_MESSAGE = 'ADD_LOADING_MESSAGE';
const DELETE_ALL_MAILING_MESSAGES = 'DELETE_ALL_MAILING_MESSAGES';
const DELETE_LOADING_MESSAGE = 'DELETE_LOADING_MESSAGE';
const CHANGE_LOADING_STATUS = 'CHANGE_LOADING_STATUS';
const SET_FORWARDED_MESSAGE = 'SET_FORWARDED_MESSAGE';
const CLEAR_FORWARDED_MESSAGE = 'CLEAR_FORWARDED_MESSAGE';

const getInitialLoadingMessage = (callers, toGroup) => {
  const callersByIds = addToEntitiesIfMissing({}, callers);
  let filteredGroup = toGroup;

  if (toGroup.includes(3) && toGroup.includes(5)) {
    filteredGroup = toGroup.filter((group) => group !== 3);
  }

  const initialSendedStatus = [
    ...filteredGroup.map((type) => ({ groupId: type, status: 'pending' })),
    ...callers.map((caller) => ({ callerId: caller.id, status: null }))
  ]

  return {
    id: 0,
    callers: callersByIds,
    statuses: initialSendedStatus,
    date: new Date(),
  }
}

export const getAllMailingMessages = (offset) => (dispatch) => {
  dispatch(setPending(true));

  return API.getMailingHistory(offset)
    .then(({ data }) => {
      dispatch({ type: GET_ALL_MAILING_MESSAGES, payload: { data }});
    })
    .finally(() => {
      dispatch(setPending(false));
    })
}

export const setforwardedMessage = (id) => ({ type: SET_FORWARDED_MESSAGE, payload: { id }});

export const deleteAllMailingMessages = () => ({ type: DELETE_ALL_MAILING_MESSAGES });

export const addMessage = (message) => ({ type: ADD_MESSAGE, payload: { message }});
export const addLoadingMessage
  = (loadingMessage, groupContacts, toGroup) => ({ type: ADD_LOADING_MESSAGE, payload: { loadingMessage, groupContacts, toGroup }});
export const deleteLoadingMessage = () => ({ type: DELETE_LOADING_MESSAGE });
export const setSendingPending = (sendingPending) => ({ type: SET_SENDING_PENDING, payload: { sendingPending }});
export const setPending = (pending) => ({ type: SET_PENDING, payload: { pending }});
export const changeLoadingStatus = (id, status, messageId) => ({ type: CHANGE_LOADING_STATUS, payload: { id, status, messageId }});
export const clearForwardedMessage = () => ({ type: CLEAR_FORWARDED_MESSAGE });

export const addMailingMessage = (message, callers, toGroup) => (dispatch) => {
  const loadingMessage = getInitialLoadingMessage(callers, toGroup);
  const filteredCallers = callers.filter((caller) => caller.type !== 'group');
  const ids = filteredCallers.map(caller => caller.id);

  dispatch(addLoadingMessage(loadingMessage));
  dispatch(setSendingPending(true));

  return API.sendMailing(message, ids, toGroup)
    .then(res => {
      dispatch(addMessage(res.data));
    })
    .finally(() => {
      dispatch(deleteLoadingMessage());
      dispatch(setSendingPending(false));
    })
}

const initialState = {
  sendingPending: false,
  pending: true,
  messages: [],
  loadingMessage: null,
  forwardedMessage: null,
  sendedLabels: {},
}

export default (state = initialState, { type, payload }) => {
  switch(type) {
    case SET_PENDING:
      const { pending } = payload;
      return {
        ...state,
        pending,
      }
    case SET_SENDING_PENDING:
      const { sendingPending } = payload;
      return {
        ...state,
        sendingPending,
      }

    case GET_ALL_MAILING_MESSAGES:
      const { data } = payload;

      return {
        ...state,
        messages: [
          ...data
            .reverse()
            .map((item) => {
              let filteredStatuses = item.statuses;

              if (item.statuses.find(status => status.groupId === 3) && item.statuses.find(status => status.groupId === 5)) {
                filteredStatuses = item.statuses.filter((status) => status.groupId !== 3);
              }

              return {
                ...item,
                callers: addToEntitiesIfMissing({}, item.callers),
                statuses: filteredStatuses,
              };
            }),
          ...state.messages,
        ]
      }
      
    case ADD_MESSAGE:
      const { message } = payload;
      const callersByIds = addToEntitiesIfMissing({}, message.callers);

      return {
        ...state,
        messages: [
          ...state.messages,
          {
            ...message,
            callers: callersByIds
          },
        ]
      }

    case ADD_LOADING_MESSAGE:
      const { loadingMessage } = payload;

      return {
        ...state,
        loadingMessage,
        forwardedMessage: null,
      }

    case CHANGE_LOADING_STATUS:
      const { id, status, messageId } = payload;

      if (!state.loadingMessage) {

        const index = state.messages.findIndex(message => message.interactions_ids.includes(messageId));

        return {
          ...state,
          messages: [
            ...state.messages.map((message, i) => {
              if (i !== index) {
                return message;
              }

              return {
                ...message,
                statuses: {
                  ...message.statuses,
                  [id]: status,
                }
              }
            }),
          ]
        }
      }

      return {
        ...state,
        loadingMessage: {
          ...state.loadingMessage,
          statuses: state.loadingMessage.statuses.map((statusObj) => {
            if (statusObj.callerId !== id) {
              return statusObj;
            }

            return {
              ...statusObj,
              status,
            }
          })
          // statuses: {
          //   ...state.loadingMessage.statuses,
          //   [id]: status,
          // }
        }
      }

    case DELETE_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: null,
      }

    case DELETE_ALL_MAILING_MESSAGES:
      return {
        ...state,
        messages: [],
        forwardedMessage: null,
      }

    case SET_FORWARDED_MESSAGE:
      const { forwardedMessage } = state;

      return {
        ...state,
        forwardedMessage: forwardedMessage === payload.id ? null : payload.id,
      }

    case CLEAR_FORWARDED_MESSAGE:
      return {
        ...state,
        forwardedMessage: null,
      }

    default:
      return state;
  }
}

