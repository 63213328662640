import List from 'components/List/List';
import { useDidUpdate } from 'hooks';
import { useRef } from 'react';
import { classModifier, getContactAvatar } from 'utils';
import './MentionMenu.scss';

const MentionMenu = ({
  message,
  setMessage,
  mention,
  setMention,
  messageInputRef,
  lastWord,
  mentionInitialState,
  setActiveMentionOperator,
  currentSymbol,
}) => {
  const activeMentionRef = useRef(null);

  const expandMention = (activeMention) => {
    const mentionPosition = message.lastIndexOf(lastWord.current);

    const mentionShortcut = message.slice(0, mentionPosition);

    const afterMention = message
      .slice(mentionPosition)
      .replace(lastWord.current, currentSymbol + (activeMention.username || mention.active));

    const updatedMsg = mentionShortcut + afterMention;

    setMessage(updatedMsg);
    setMention(mentionInitialState);
    setActiveMentionOperator(activeMention)

    messageInputRef.current.focus();
  };

  useDidUpdate(() => {
    if (mention.active && activeMentionRef.current) {
      activeMentionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
    }
  }, [mention])

  return (
    <div className={classModifier("mention-list", mention.show && 'visible')}>
      <List
        classPrefix="mention-list"
        list={mention.options}
        autoHide
      >
        {({ item }) => (
          <li
            className={classModifier("mention-list__item", [mention.active === item.username && 'active'])}
            key={item.username}
            ref={mention.active === item.username ? activeMentionRef : null}
            onClick={() => expandMention(item)}
          >
            <div className="mention-list__ava-wrap">
              <span
                className={classModifier("mention-list__status", item.status)}
              >
              </span>

              <img src={getContactAvatar(item, item.type)} alt="operator mini ava" className="mention-list__ava" />
            </div>

            <div className="mention-list__content">
              <span className="mention-list__name">{item.username}</span>
              <span className="mention-list__phone">{item.phone}</span>
            </div>
          </li>
        )}
      </List>
    </div>
  )
}

export default MentionMenu;
