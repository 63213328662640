import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import './FeedbackTextareaField.scss';

const FeedbackTextareaField = ({
  input,
  meta: { touched, error },
}) => {
  const inputId = useMemo(() => uuid(), []);

  return (
    <div className='feedback-textarea-field'>
      <label
        className='feedback-textarea-field__label'
        htmlFor={inputId}
      >
        Feedback
      </label>

      <div className='feedback-textarea-field__textarea-wrap'>
        <textarea
          {...input}
          id={inputId}
        />
      </div>

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }
    </div>
  )
}

export default FeedbackTextareaField;
