import { classModifier } from 'utils';

import './Toggle.scss';

const Toggle = (props) => {
  const {
    isActive,
    onToggle,
    isDisabled,
  } = props;

  return (
    <div
      className={classModifier('toggle', [
        isActive ? 'true' : 'false',
        isDisabled && 'disabled',
      ])}
      onClick={() => isDisabled ? null : onToggle()}
    >
      <div
        className={classModifier('toggle__indicator', [
          isActive ? 'true' : 'false',
        ])}
      />
    </div>
  );
};

export default Toggle;