import React, { memo, useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { v4 as uuid } from 'uuid';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import PhoneNumbersFieldItem from './PhoneNumbersFieldItem';

const PhoneNumbersField = ({
  values,
  saveContact,
  classPrefix, 
  phoneLabels,
  setPhoneLabels,
  serverError, 
  setServerError,
  isInAdrBook, 
  isEditedTelLabel,
  isNewContactCreation = true, 
  isCustomDropDown = false,
}) => {

  const addNewField = useCallback((fields) => {
    if (fields.length >= 10) return;

    fields.push({ 
      default: +!fields.length, 
      labelName: 'default',
      uuid: uuid()
    });
  }, [phoneLabels]);

  return (
    <FieldArray name="tels">
      {({ fields, meta: fieldArrayMeta }) => (
        <div className={`${classPrefix}__phone-numbers`}>
          {fields.length > 0 &&
            <div className={classModifier(`${classPrefix}__group`, [
              'tels',
              !isNewContactCreation && 
                fieldArrayMeta.initial?.some((value, idx) => fields.value[0]?.id && !fields.value[idx]?.id) && 
                'edited'
            ])}>
              {fields.map((tel, index) => (
                <PhoneNumbersFieldItem 
                  tel={tel}
                  key={index}
                  index={index}
                  values={values}
                  fields={fields}
                  serverError={serverError}
                  saveContact={saveContact}
                  isInAdrBook={isInAdrBook}
                  phoneLabels={phoneLabels}
                  setPhoneLabels={setPhoneLabels}
                  classPrefix={classPrefix}
                  fieldArrayMeta={fieldArrayMeta}
                  setServerError={setServerError}
                  isEditedTelLabel={isEditedTelLabel}
                  isNewContactCreation={isNewContactCreation}
                  isCustomDropDown={isCustomDropDown}
                />
              ))}
            </div>
          }

          <button
            type="button"
            onClick={() => addNewField(fields, 'tel')}
            className={classModifier(`${classPrefix}__add-field-btn`, "tel-add")}
          >
            <ICONS.plusCircle className={`${classPrefix}__btn-add-icon`}/>
          </button>
        </div>
      )
      }
    </FieldArray>
  )
}

export default memo(PhoneNumbersField);
