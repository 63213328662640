import React, { useEffect, useState } from 'react';

import './CountdownTimer.scss';


const CountdownTimer = props => {
  const {
    classPrefix = '',
    countdownSeconds,
    onTimerEnd,
  } = props;

  const [seconds, setSeconds] = useState(countdownSeconds);

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setTimeout(() => setSeconds(prevSeconds => prevSeconds -1), 1000);
    };

    if (seconds === 0) {
      clearTimeout(timer);

      onTimerEnd && onTimerEnd();
    };

    return () => {
      clearTimeout(timer)
    }
  }, [seconds]);


  return (
    <div className={`${classPrefix} countdown-timer`}>
      <span className="countdown-timer__value">
        {seconds}
      </span>

      <div
        className="countdown-timer__body"
        data-anim="base timer"
        style={{ animationDelay: `${countdownSeconds / 2}s` }}
      >

        <div
          className="countdown-timer__body--circle"
          data-anim="base left"
          style={{ animationDuration: `${countdownSeconds}s` }}
        />

        <div
          className="countdown-timer__body--circle"
          data-anim="base right"
          style={{ animationDuration: `${countdownSeconds / 2}s` }}
        />
      </div>
    </div>
  );
};

export default CountdownTimer;