import React from 'react';
import { useSelector } from 'react-redux';
import ICONS from 'assets/icons';
import { selectOperatorsByIds } from 'redux/selectors/selectors';

const TypingOperators = ({ operatorsIds = [] }) => {
  const operators = useSelector((state) => selectOperatorsByIds(state, operatorsIds));

  return (
    <>
      <ICONS.pencil title="someone is typing" width="10" height="10"/>

      {!!operators.length &&
        <strong>
          {operators.map(operator => operator.username).join(', ')} is typing
        </strong>
      }
    </>
  );
};

export default TypingOperators;
