import React, { useRef, useState, useEffect, useCallback } from 'react';

import { classModifier } from '../../utils';

import './VideoPlayer.scss';
import Slider from '../AudioPlayer/Slider';
import ICONS from 'assets/icons';
import { usePrevious } from 'hooks';

// const openInFullscreen = (elem) => {
//   if (elem.requestFullscreen) {
//     elem.requestFullscreen();
//   }
//   else if (elem.mozRequestFullScreen) { /* Firefox */
//     elem.mozRequestFullScreen();
//   }
//   else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
//     elem.webkitRequestFullscreen();
//   }
//   else if (elem.msRequestFullscreen) { /* IE/Edge */
//     elem.msRequestFullscreen();
//   }
// }

const VideoPlayer = props => {
  const {
    src,
    videoId,
    style,
    isActive = true,
  } = props;

  const [isPaused, setPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0);

  const [thumbWasMoved, setThumWasbMoved] = useState(); // render slider after change values

  const videoWrapperRef = useRef(null);
  const videoRef = useRef(null);
  const btnPlayRef = useRef(null);

  const previousIsActive = usePrevious(isActive);

  useEffect(() => {
    isActive && videoRef.current?.focus();
    
    const togglePlayerPlayOnKeyDown = (e) => {
      e.preventDefault();
      
      if (['ArrowLeft', 'ArrowRight'].includes(e.code)) {
        const duration = videoRef.current.duration;
        let newTime = e.keyCode === 39
          ? videoRef.current.currentTime + 1
          : videoRef.current.currentTime - 1;
        
        if (newTime > duration) {
          newTime = duration;
        }
        else if (newTime < 0) {
          newTime = 0;
        }

        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
        moveThumb();
      }
    };

    if (!isActive && previousIsActive) {
      videoRef.current.pause();
      setPaused(true);
    }
    
    if (isActive) {
      videoRef.current?.addEventListener('keydown', togglePlayerPlayOnKeyDown)
      videoRef.current?.addEventListener('click', toggleVideoPlay);
    }
    
    return () => {
      videoRef.current?.removeEventListener('keydown', togglePlayerPlayOnKeyDown)
      videoRef.current?.removeEventListener('click', toggleVideoPlay);
    }
  }, [isActive]);

  useEffect(() => {
    videoRef.current.volume = volume / 100;
  }, [volume]);
  
  useEffect(() => { // paused after opening another video
    if (videoRef.current && videoRef.current.paused !== isPaused) {
      setPaused(videoRef.current.paused);
    }
  }, [videoRef.current && videoRef.current.paused, isPaused]);

  const onPlayerKeyDown = (e) => {
    e.preventDefault();

    if (isActive && e.code === 'Space' && e.target !== btnPlayRef.current) { // pause/play on space bar
      toggleVideoPlay();
    }
  }

  const moveThumb = useCallback(() => {
    setThumWasbMoved(prevState => !prevState);
  }, []);

  const onTimeChangeManually = useCallback((time) => {
    const newTime = Math.floor(time);
    
    if (newTime === videoRef.current.currentTime) {
      return;
    }
    
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);

  }, []);

  const onInitVideo = () => {
    props.onInitVideo && props.onInitVideo();
    setDuration(Math.floor(videoRef.current.duration));
  };

  const onTimeChange = () => {
    const currentVideoTime = Math.floor(videoRef.current.currentTime);

    if (currentVideoTime !== currentTime) {
      setCurrentTime(currentVideoTime);
      moveThumb();
    }
  };

  const onPlay = (e) => {
    if (props.onPlay && typeof props.onPlay === 'function') {
      props.onPlay(e.target);
    }
  };

  const toggleVideoPlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPaused(false);
    }
    else {
      videoRef.current.pause();
      setPaused(true);
    }

    isActive && videoRef.current?.focus();
  }

  const onEndVideo = () => {
    setCurrentTime(0);
    moveThumb();
    setPaused(true);
  };

  const toggleMute = () => {
    setVolume(volume ? 0 : 100);
    isActive && videoRef.current?.focus();
  };

  const getTime = (sec) => {
    let minutes = Math.floor(sec / 60);
    let seconds = Math.floor(sec % 60);

    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return `${minutes}:${seconds}`;
  }

  return (
    <div
      className={classModifier('video', [
        isPaused && 'paused'
      ])}
      ref={videoWrapperRef}
      style={style}
    >
      <video
        ref={videoRef}
        onLoadedMetadata={onInitVideo}
        onTimeUpdate={onTimeChange}
        onEnded={onEndVideo}
        onPlay={onPlay}
        controls={false}
        data-video-player={videoId}
        onKeyDown={onPlayerKeyDown}
        tabIndex="5"
      >
        <source src={src} type='video/mp4' />

        Your browser does not support video tag
      </video>

      <div className="video__controls">
        <button
          onClick={toggleVideoPlay}
          ref={btnPlayRef}
          className="video__play"
        >
          {isPaused ? <ICONS.play /> : <ICONS.pause />}
        </button>

        {duration !== 0 &&
          <Slider
            onUpdate={onTimeChangeManually}
            countOfSteps={duration}
            currentStep={currentTime}
            thumbWasMoved={thumbWasMoved}
            ref={videoRef}
            isActive={isActive}
          />
        }
        
        <time
          className='video__time'
          onClick={() => videoRef.current.focus()}
        >
          {getTime(currentTime)}
        </time>
        
        <button
          className='video__volume-btn'
          onClick={toggleMute}
        >
          {volume > 0 ? <ICONS.volumeUp style={{ transform: 'translateY(2px)' }}/> : <ICONS.volumeOff />}
        </button>
      </div>
    </div>
  )
}

export default VideoPlayer;
