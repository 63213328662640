import React, { useEffect, useState, useCallback } from 'react';

import { useDidMount } from 'hooks';

import CustomListWrapper from './CustomListWrapper';
import CallItem from '../../../components/CallItem/CallItem';
import ConferenceItem from '../../../components/ConferenceItem/ConferenceItem';
import WebrtcConferenceItem from '../../../components/ConferenceItem/WebrtcConferenceItem';
import MissedCallItem from '../../../components/MIssedCallItem/MissedCallItem';
import { WARM_TRANSFER } from 'redux/ducks/conferences';


const CallsList = ({ callList, additionalCallList, ...rest }) => {
  const [isScrollable, setIsScrollable] = useState(true);
  const [noMicro, setNoMicro] = useState(false);

  useDidMount(() => {
    if (rest.inQueue) {
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        // check audio stream and stop it
        .then(stream => console.log(stream.getTracks().forEach((track) => track.stop())))
        // If user haven't microphone
        .catch(() => setNoMicro(true));
    }
  });

  const scrollbarsRenderView = (props) => {
    return !isScrollable
      ? <div
        {...props}
        style={{
          ...props.style,
          overflow: 'hidden',
          marginBottom: 0,
          marginRight: 0
        }}
      />
      : <div {...props} />;
  };

  // const onMenuToggle = useCallback(status => setIsScrollable(status === 'closed'), []);

  const renderCallItem = () => { // TODO: make it universal
    if (rest.missed) {
      return callList.map(id => (
        <MissedCallItem
          id={id}
          key={id}
          {...rest}
        />
      ));
    }
    else if (rest.conf) {
      const conferenceItems = callList.map(conference => (
        <ConferenceItem
          conference={conference}
          key={conference.id}
          callerId={conference.type === WARM_TRANSFER ? conference.caller_id : null}
          {...rest}
        />
      ));

      const webrtcConferenceItems = additionalCallList.map(conference => (
        <WebrtcConferenceItem
          conference={conference}
          key={conference.id}
          {...rest}
        />
      ));
      return [...webrtcConferenceItems, ...conferenceItems];
    }
    else {
      return callList.map(call => (
        <CallItem
          call={call}
          caller={call.caller || call.sender}
          key={call.id}
          noMicro={noMicro}
          {...rest}
        />
      ));
    }
  };

  return (
    <CustomListWrapper renderView={scrollbarsRenderView}>
      <ul className="calls-info-section__list">
        {renderCallItem()}
      </ul>
    </CustomListWrapper>
  );
};

export default React.memo(CallsList);