export default (ref, data) => {
  const styleParent = getComputedStyle(ref.current);
  const parentWidth = ref.current.clientWidth - parseInt(styleParent.paddingRight) - parseInt(styleParent.paddingLeft);

  const children = ref.current.children;

  const childrenLength = {};

  for (const child in children) {
    if (children.hasOwnProperty(child)) {
      const style = getComputedStyle(children[child]);

      childrenLength[child] =
        children[child].offsetWidth +
        parseInt(style.marginLeft) +
        parseInt(style.marginRight);
    }
  };

  const usedKeys = [];
  let sortedArr = [];
  let sortedArrLen = 0;

  while (sortedArrLen <= Object.keys(childrenLength).length) {
    let line = [];

    for (const key in childrenLength) {
      const sum = line.reduce((acc, val) => acc + childrenLength[val], 0);

      if (sum + childrenLength[key] <= parentWidth && !usedKeys.includes(key)) {
        usedKeys.push(key);
        line.push(key);
      }
    }

    if (line.length) {
      sortedArrLen = sortedArrLen + line.length;
      sortedArr = sortedArr.concat(line);
    }
    else {
      break;
    }
  };


  return sortedArr.map(key => {
    return data[key];
  });

};