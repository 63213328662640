// Use metric (SI) units
// (as Fastmail, Ubuntu)
const S_1KB = 1000;
const S_1MB = 1000 ** 2;

export default function formatMailFileSize(bytes) {
  if (!Number.isInteger(bytes)) return;

  if (bytes < S_1KB) {
    return `${bytes} B`;
  }

  if (bytes < S_1MB - S_1KB / 2) {
    return `${Math.round(bytes / S_1KB)} KB`;
  }

  return `${Math.round((bytes / S_1MB) * 10) / 10} MB`;
}
