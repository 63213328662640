import './List.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import List from './List';

const ListContainer = ({ listLoadPending, classPrefix, spinnerSize, ...props }) => {
	if (listLoadPending) {
		return (
      <div className={classPrefix + "__load-wrap"}>
        <Spinner spinnerSize={spinnerSize} />
      </div>
    )
  }

  return (
    <List
    	listLoadPending={listLoadPending}
      classPrefix={classPrefix}
      spinnerSize={spinnerSize}
      {...props}
    />
  )
}

export default ListContainer;
