import React from 'react'

import { classModifier, getContactAvatar, getDefaultField } from 'utils';
import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const DialpadSearchItem = ({ setQuery, contactTypes, item, setContact }) => {

  return (
    <li
      className='dialpad-list__item dialpad-list__item--search'
      onClick={() => {
        setContact(item);
        setQuery(getDefaultField(item.tels, 'tel'));
      }}
    >
      <div className="dialpad-list__avatar-box">
        <LazyLoadImage
          src={getContactAvatar(item)}
          alt="ava"
          className="dialpad-list__avatar"
        />
        <ICONS.telegram className="dialpad-list__icon-telegram" />
      </div>
      <div className="dialpad-list__text-box">
        <p className={classModifier('dialpad-list__contact-name',[contactTypes[item.type]])}>
          {item.fn}
        </p>
        <p className="dialpad-list__tel">{item.tels[0].tel}</p>
      </div>
    </li>
  );
}

export default DialpadSearchItem;