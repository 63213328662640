import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import './FilterEditor.scss';

import {
  SAVED_PARAMS_FILTERS_LENGTH,
  SAVED_SERVICES_FILTERS_LENGTH,
  updateUserSalesFilters,
 } from "redux/ducks/user";
import { getDivaFilters } from 'redux/ducks/divaGirls';
import { addNotification } from 'redux/ducks/notifications';
import { normalize } from 'utils/normalizeContacts';
import { useToggle } from 'hooks';

import FiltersEditorList from './components/FiltersEditorList';
import SearchByFiltersWrap from './components/SearchByFiltersWrap';

const FiltersEditor = (props) => {

  const [activeServicesFilters, setActiveServicesFilters] = useState(props.userServiceFiltersIds);
  const [activeParamsFilters, setActiveParamsFilters] = useState(props.userParamsFiltersIds);

  const [servicesFilters, setServicesFilters] = useState({
    entities: {},
    ids: props.loadedServicesIds
  });

  const [paramsFilters, setParamsFilters] = useState({
    entities: {},
    ids: props.loadedParamsIds
  });

  const [pending, setPending] = useToggle(false);

  const isFiltersSame =
    JSON.stringify(activeServicesFilters) === JSON.stringify(props.userServiceFiltersIds) &&
    JSON.stringify(activeParamsFilters) === JSON.stringify(props.userParamsFiltersIds);

  useEffect(() => {
    setServicesFilters(prevFilters => ({
      ...prevFilters,
      entities: {
        ...prevFilters.entities,
        ...normalize(props.loadedServicesIds.map(key => props.loadedFilters[key]), 'key').entities
      }
    }))
  }, [props.loadedServicesIds]);

  useEffect(() => {
    setParamsFilters(prevFilters => ({
      ...prevFilters,
      entities: {
        ...prevFilters.entities,
        ...normalize(props.loadedParamsIds.map(key => props.loadedFilters[key]), 'key').entities
      }
    }))
  }, [props.loadedParamsIds]);

  const onDragEnd = (result) => {
    if (result.destination === null) { return; }

    const { source, destination } = result;

    const sourceIndex = source.index;
    const destIndex = destination.index;

    const isMovementInActiveServices = source.droppableId === '1' && destination.droppableId === '1';
    const isFromAllServicesToActiveServices = source.droppableId === '3' && destination.droppableId === '1';

    const isMovementInActiveParams = source.droppableId === '2' && destination.droppableId === '2';
    const isFromAllParamsToActiveParams = source.droppableId === '4' && destination.droppableId === '2';

    if (isMovementInActiveServices) {

      const newServicesList = destIndex < sourceIndex
        ? moveFilterBackward('services', sourceIndex, destIndex)
        : moveFilterForward('services', sourceIndex, destIndex);

      setActiveServicesFilters(newServicesList);
    }
    else if (isFromAllServicesToActiveServices) {
      setPending(true);
      
      const filterKey = result.draggableId;
      const newServicesList = [...activeServicesFilters];
      
      if (newServicesList.includes(filterKey)) return;
      
      newServicesList.splice(destIndex, 0, filterKey);

      props.getDivaFilters({ key: filterKey})
        .finally(() => setPending(false));

      setActiveServicesFilters(newServicesList);
    }
    else if (isMovementInActiveParams) {

      const newParamsList = destIndex < sourceIndex
        ? moveFilterBackward("params", sourceIndex, destIndex)
        : moveFilterForward('params', sourceIndex, destIndex);

      setActiveParamsFilters(newParamsList);
    }
    else if (isFromAllParamsToActiveParams) {
      setPending(true);

      const filterKey = result.draggableId;
      const newParamsList = [...activeParamsFilters];

      if (newParamsList.includes(filterKey)) return;

      newParamsList.splice(destIndex, 0, filterKey);

      props.getDivaFilters({ key: filterKey})
        .finally(() => setPending(false));

      setActiveParamsFilters(newParamsList);
    }
  };

  const moveFilterForward = (type, source, destination) => {
    const newList = type === 'services'
      ? [...activeServicesFilters]
      : [...activeParamsFilters];

    const element = type === 'services'
      ? activeServicesFilters[source]
      : activeParamsFilters[source];

    newList.splice(destination + 1, 0, element);
    newList.splice(source, 1);

    return newList;
  };

  const moveFilterBackward = (type, source, destination) => {
    const newList = type === 'services'
      ? [...activeServicesFilters]
      : [...activeParamsFilters];

    const element = type === 'services'
      ? activeServicesFilters[source]
      : activeParamsFilters[source];

    newList.splice(destination, 0, element);
    newList.splice(source + 1, 1);

    return newList;
  };

  const applyChanges = () => {
    setPending(true);

    const updatedFiltersIds = [...activeServicesFilters, ...activeParamsFilters]

    props.updateUserSalesFilters(updatedFiltersIds)
      .then(() => {
        setPending(false);
        props.closeModal();
      });
  };

  const removeFilter = (filter, type) => {
    if (type === 'services') {
      if (!activeServicesFilters.includes(filter)) return;

      const newServicesFilters = activeServicesFilters.filter(f => f !== filter);

      setActiveServicesFilters(newServicesFilters);
    }
    else {
      if (!activeParamsFilters.includes(filter)) return;

      const newParamsFilters = activeParamsFilters.filter(f => f !== filter);

      setActiveParamsFilters(newParamsFilters);
    }
  };

  const addFilter = (filter, type) => {
    if (type === 'services') {
      if (activeServicesFilters.includes(filter)) return;
      setPending(true);

      const newServicesFilters = [ ...activeServicesFilters, filter ];

      props.getDivaFilters({ key: filter})
        .finally(() => setPending(false));

      setActiveServicesFilters(newServicesFilters);
    }
    else {
      if (activeParamsFilters.includes(filter)) return;
      setPending(true);

      const newParamsFilters = [...activeParamsFilters, filter];

      props.getDivaFilters({ key: filter})
        .finally(() => setPending(false));

      setActiveParamsFilters(newParamsFilters);
    }
  };

  const cancel = () => {
    if (isFiltersSame) {
      return props.closeModal(); 
    }

    setActiveServicesFilters(props.userServiceFiltersIds);
    setActiveParamsFilters(props.userParamsFiltersIds);
  };

  return (
    <>
      <div className='filters-editor'>
        <div className="filters-editor__header">
          Incall addresses

          <div className="filters-editor__btn-container">
            <button
              className="filters-editor__btn filters-editor__btn--cancel"
              onClick={cancel}
            >
              Cancel
            </button>

            <button
              className="filters-editor__btn filters-editor__btn--submit"
              onClick={applyChanges}
              disabled={isFiltersSame}
            >
              Done
            </button>
          </div>
        </div>

        <div className='filters-editor__main-container'>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className='filters-editor__lists-zone'>
              <FiltersEditorList
                droppableId='2'
                savedFiltersLength={SAVED_PARAMS_FILTERS_LENGTH}
                ids={activeParamsFilters}
                removeFilter={removeFilter}
                filters={paramsFilters}
              />

              <FiltersEditorList
                droppableId='1'
                savedFiltersLength={SAVED_SERVICES_FILTERS_LENGTH}
                ids={activeServicesFilters}
                removeFilter={removeFilter}
                filters={servicesFilters}
                services
              />

              <SearchByFiltersWrap
                droppableId='4'
                filterType='other-params'
                activeFilters={activeParamsFilters}
                filters={paramsFilters}
                setFilters={setParamsFilters}
                addNotification={props.addNotification}
                addFilter={addFilter}
              />

              <SearchByFiltersWrap
                droppableId='3'
                filterType='services'
                activeFilters={activeServicesFilters}
                filters={servicesFilters}
                setFilters={setServicesFilters}
                addNotification={props.addNotification}
                addFilter={addFilter}
                services
              />
            </div>
          </DragDropContext>
        </div>
      </div>

      {pending && <div className="modal__content--pending"></div>}
    </>
  )
}

const mapStateToProps = (state) => ({
  loadedFilters: state.divaGirls.filters,
  loadedServicesIds: state.divaGirls.serviceFiltersIds,
  loadedParamsIds: state.divaGirls.paramsFiltersIds,
  userSalesFilters: state.user.salesFilters,
  userServiceFiltersIds: state.user.salesServicesIds,
  userParamsFiltersIds: state.user.salesParamsIds,
});

const mapDispatchToProps = {
  getDivaFilters,
  addNotification,
  updateUserSalesFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersEditor);
