import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { CONTACT_TYPES } from 'config/constants';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { webrtcToggleMuteActiveCall } from 'redux/ducks/webrtc';
import { selectContactFromEntities } from 'redux/selectors/selectors';

import './ActiveCall.scss';
import ICONS from 'assets/icons';
import CallItem from 'components/CallItem/CallItem';

const ActiveCall = (props) => {
  const {
    userId,
    activeCall,
    isCallModalActive,
  } = props;

  if (
    !activeCall.id ||
    isCallModalActive ||
    activeCall.isSoftPhone ||
    activeCall.type === 1 && !['connecting', 'in-progress'].includes(activeCall.status)
  ) {
    return null; // incomingCall which not in progress
  }

  const dispatch = useDispatch();

  const caller = getCaller();

  // const device = useSelector(state => state.twilio.device);
  const localStream = useSelector(state => state.webrtc.localStream);
  const connectedCaller = useSelector(state => selectContactFromEntities(state, caller.id, caller.type));

  const isOperator = connectedCaller?.type === CONTACT_TYPES.OPERATOR;

  function getCaller() {
    // If you call a phone number
    if (activeCall.caller) {
      return activeCall.caller;
    }
    // If current user makes a call to another operator
    else if (activeCall.senderId === userId) {
      return activeCall.recipient;
    }
    // If another operator calls the current or another operator
    else {
      return activeCall.sender;
    }
  }

  const toggleMuteCall = () => {
    if (isOperator) {
      dispatch(webrtcToggleMuteActiveCall(!activeCall.isMute, localStream));
    }
    else {
      // device.activeConnection().isMuted()
      //   ? device.activeConnection().mute(false)
      //   : device.activeConnection().mute(true);
    }
  }

  return (
    <div className="active-call">
      <button
        onClick={toggleMuteCall}
        className={classModifier('active-call__mute-btn', activeCall.isMute && 'muted')}
      >
        <ICONS.microphoneMute className="active-call__mute-btn-icon" />
      </button>

      <CallItem
        isActiveCall
        actions={activeCall.caller ? true : false}
        call={activeCall}
        caller={caller}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  activeCall: state.calls.activeCall,
  userId: state.user.id,
  isCallModalActive: state.activeWindows?.activeModals?.some(modal => modal.type === MODAL_TYPES.call),
});

export default connect(mapStateToProps)(ActiveCall);