import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { classModifier } from 'utils';


const SearchByFiltersItem = (props) => {
  return (
    <Draggable
      draggableId={props.id}
      index={props.index}
    >
      {(provided) => (
        <div
          className={classModifier('filters-editor__list-item-wrap', [
              props.filterType === 'services' ? 'services' : 'filters'
          ])}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <li
            className='filters-editor__list-item'
          >
            {props.name}
          </li>
          <div
            className={classModifier('filters-editor__add-list-item', [
              props.filterType === 'services' ? 'services' : 'filters'
            ])}
            onClick={() => props.addFilter(props.id, props.filterType)}
          />
        </div>
      )}
    </Draggable>
  );
};

export default memo(SearchByFiltersItem);
