import React from 'react';

import './GlobalPreloader.scss';

import Spinner from '../UI/Spinner/Spinner';

const GlobalPreloader = () => {
  return <div className="global-preloader">
    <div className="global-preloader__wrap">
      <Spinner spinnerSize={160} />
    </div>
  </div>
};

export default GlobalPreloader;