import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'api/api';
import { useToggle } from 'hooks';
import { toggleIsOpenTasksAndRemindersModal } from 'redux/ducks/notifications';
import useSocket from 'hooks/useSocket';

import WebmasterMessage from 'containers/Chat/components/ChatTimeline/interactions/WebmasterMessage';
import { NotificationBlock } from 'components/UI/NotificationBlock/NotificationBlock';


export const WebmasterNotification = (props) => {
  const [currentWebmaster, setCurrentWebmaster] = useState(props.item.data || props.item.interaction);
	const [pending, togglePending] = useToggle(true);

	const dispatch = useDispatch();

  const handleOpenNotification = () => {
    dispatch(toggleIsOpenTasksAndRemindersModal({ status: true }))
  }

  useEffect(() => {
		API.getWebmasterTask(props.item.web_master_id)
      .then((res) => {
        setCurrentWebmaster(res.data);
      })
      .finally(togglePending)
  }, [])

  useSocket((event) => {
    const { data, type } = JSON.parse(event.data);
    const { webMasterTask } = data?.message || {};
    const isSameWebmaster = currentWebmaster?.id === webMasterTask?.id;

    if (type === 'webmaster_task' && ['complete', 'uncomplete'].includes(data.type) && isSameWebmaster) {
      setCurrentWebmaster(webMasterTask);
    }
  })

  return (
    <NotificationBlock {...props}>
			<NotificationBlock.Body>
				{props.getText}
      </NotificationBlock.Body>

      <NotificationBlock.Attachments pending={pending}>
				<WebmasterMessage interaction={currentWebmaster} isClickable={false} />
      </NotificationBlock.Attachments>

      <NotificationBlock.Footer iconType={props.iconType}>
        <button onClick={handleOpenNotification}>
          Open
        </button>
      </NotificationBlock.Footer>
    </NotificationBlock>
  )
};
