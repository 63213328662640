import React from "react";

const AttachmentEmail = ({ email }) => {
  const onClick = () => {
    window.open(
      `/mail/inbox/email/${email?.conversationId}`,
      "_blank",
      "rel=noopener noreferrer"
    );
  };

  return (
    <div className="attachment-email" onClick={onClick}>
      <p className="attachment-email__title">{email.subject}</p>
      <p className="attachment-email__body">{email.body}</p>
    </div>
  );
};

export default AttachmentEmail;
