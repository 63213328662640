import { memo } from 'react';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './UserNameLabel.scss'
import Spinner from 'components/UI/Spinner/Spinner';

const statusLabels = {
  success: <ICONS.check />,
  pending: <Spinner spinnerSize="12" />,
}

const UserNameLabel = (props) => {
  const statusLabel = props.statusOk ? statusLabels.success : props.status ? statusLabels[props.status] : null;

  return (
    <div className={classModifier("username-label", [
      props.statusOk ? 'success' : props.status,
      props.data?.type === 'group' && 'group',
    ])}>
      <span className="username-label__text">
        {props.name || props.data?.fn}
      </span>

      {props.children?.[1] || statusLabel}
    </div>
  )
}

export default memo(UserNameLabel);
