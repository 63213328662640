import _ from 'lodash';
import { BOOKING_STATUS_FILTERS_NAMES } from '../config/constants';
import convertDateToBookingsKey from '../utils/convertDateToBookingsKey';
import convertDateToFilterIndex from '../utils/convertDateToFilterIndex';
import getBoundaryDate from '../utils/getBoundaryDate';
import getDatesByDay from '../utils/getDatesByDay';
import getHashOfFilters from '../utils/getHashOfFilters';


export const selectActualDate = (state) => {
  return state.filters.general.date;
}

export const selectCurrentDate = (state) => {
  return state.filters.general.currentDate;
}

export const selectBoundaryDate = (state, boundaryDays) => {
  const { date } = state.filters.general;

  return getBoundaryDate(date, boundaryDays);
}

export const selectBookingObject = (
  state,
  date,
  filtersKey = getHashOfFilters(selectBookingFilters(state, date)),
) => {
  const dateKey = convertDateToBookingsKey(date);

  return state.entities[filtersKey]?.[dateKey] || {};
}

export const selectBookingsTotalCount = (state, date) => {
  const bookingObj = selectBookingObject(state, date);

  return bookingObj.entity?.bookingsTotal || 0;
}

export const selectBookingsActualCount = (state, date) => {
  const bookingObj = selectBookingObject(state, date);

  return bookingObj.entity?.bookingItems?.length || 0;
}

export const selectSplitedDate = (state, separatedBookingsByDay, boundaryDays) => {
  const { date } = state.filters.general;
  const boundaryDate = getBoundaryDate(date, boundaryDays);

  if (separatedBookingsByDay && Array.isArray(boundaryDate)) {
    return getDatesByDay(...boundaryDate)
  } else {
    return [boundaryDate];
  }
}

export const selectFilteredBookings = (state, date, bookings, groupByFilter) => {
  const bookingObj = selectBookingObject(state, date);
  const ids = bookingObj.entity?.bookingItems.map(item => item.id) || [];

  if (groupByFilter) {
    const bookingsGroupedByDate = ids.reduce((bookingsByDate, id) => {
      const booking = bookings[id];

      if (!booking) {
        return bookingsByDate;
      }

      const startDate = String((new Date(booking.date)).setHours(0, 0, 0, 0));

      return {
        ...bookingsByDate,
        [startDate]: bookingsByDate[startDate] ? bookingsByDate[startDate].concat(booking) : [booking],
      }
    }, {})

    return Object.entries(bookingsGroupedByDate)
  } else {
    return ids.map((id) => bookings[id]);
  }
}

export const selectBookingFilters = (state, pickedDate, extraProps) => {
  const index = convertDateToFilterIndex(state.filters.general.date, pickedDate);
  const { date, currentDate, ...generalFiltersWithoutDate } = state.filters.general;
  const specificFilters = state.filters.byDay[index] || state.filters.byDay[0] || {};

  return {
    ...specificFilters,
    ...(extraProps?.withoutGeneralFilters ? {} : generalFiltersWithoutDate),
  }
}

export const selectPending = (state, date) => {
  const bookingObj = selectBookingObject(state, date);

  return bookingObj?.pending ?? true;
}

export const selectCounters = (state, getCounter, date) => {
  const bookingObj = selectBookingObject(state, date);

  if (_.isEmpty(bookingObj)) {
    if (!Array.isArray(date)) return;

		const countersByLabel = getDatesByDay(...date).map((date) => {
      const bookingObj = selectBookingObject(state, date);

      return getCounter(bookingObj);
    });

    if (!countersByLabel.every((item) => item !== undefined)) return;

    return countersByLabel.reduce((sum, statusCount) => sum + Number(statusCount), 0);
  }

  return getCounter(bookingObj);
}

export const selectNumberOfBookings = (state, date) => {
  const getCounter = (bookingObj) => bookingObj.entity?.bookingsCount;

  return selectCounters(state, getCounter, date);
}

export const selectIsFiltersUpdated = (state, date, extraProps) => {
  const filter = selectBookingFilters(state, date, extraProps);

  return Object.values(filter).some(Boolean);
}

export const selectStatusCounters = (state, date, name) => {
	const getCounter
    = (bookingObj) => bookingObj.entity?.bookingsCountByConfirmationStatus?.[name] || 0;

  if (name === BOOKING_STATUS_FILTERS_NAMES.all) {
    return bookingObj.entity?.bookingItems?.length;
  } else {
    return selectCounters(state, getCounter, date);
  }
}

// export const selectBookingObject = (state, date) => {
//   const dateKey = convertDateToBookingsKey(date);
//   const filtersKey = hash(selectBookingFilters(state, date));

//   return state.entities[filtersKey]?.[dateKey]?.entity || {};
// }

export const selectOverallPending = (state, dates) => {
	const dateKeys = dates || [null];

	const allPendings = dateKeys.map((date) => {
    const filters = selectBookingFilters(state, date);
    const filtersKey = getHashOfFilters(filters);
    const dateKey = convertDateToBookingsKey(date);

    return state.entities[filtersKey]?.[dateKey]?.pending;
  })

  return allPendings.some(Boolean);
}

// const getFiltersLabels = (filters) => {
//   const FILTERS_QUERY_ARGS = {
//     'isAgent': [BOOKING_FILTERS_NAMES.agent],
//     'meeting_type': [BOOKING_FILTERS_NAMES.duo],
//   }

//   const filterKeys = Object.entries(filters).reduce((names, [key, value]) => {
//     return value ? names.concat(key) : names;
//   }, [])
//   const filterLabels = filterKeys.map((key) => FILTERS_QUERY_ARGS[key] || key);

//   return filterLabels;
// }

// export const getFilteredMessage = (state) => (date) => {
//   if (isFiltersUpdated(state)(date)) {
//     return 'No results found';
//   } else {
//     return 'No items';
//   }
// }

export const selectIsDateLoaded = (state, date) => {
  const filters = selectBookingFilters(state, date);
  const filtersKey = getHashOfFilters(filters);
  const dateKey = convertDateToBookingsKey(date);

  const bookingDay = state.entities[filtersKey]?.[dateKey];

  return Boolean(bookingDay);
}

export const selectExtraItemsState = (state, date, id, name) => {
  const dateKey = convertDateToBookingsKey(date);
	
  if (name) {
    return state.itemsState[dateKey]?.[name]?.[id];
  } else {
    return state.itemsState[dateKey]?.[id];
  }
}

export const selectIsDateKeyExist = (state, date, filters) => {
  const dateKey = convertDateToBookingsKey(date);

  return Boolean(state.entities[getHashOfFilters(filters)]?.[dateKey]);
}

export const selectAllTaskReminders = (state) => {
  return state.notifications.reminders;
}

