const formatDateToApiUTCString = (dateParam) => {
  let date;
  
  if (dateParam === undefined) {
    date = new Date();
  } else if (typeof dateParam === 'object' && dateParam !== null) {
    date = dateParam;
  } else {
    date = new Date(dateParam);
  }

  const yyyy = date.getUTCFullYear();
  const mo = padTo2Digits(date.getUTCMonth() + 1);
  const dd = padTo2Digits(date.getUTCDate());
  
  const hh = padTo2Digits(date.getUTCHours());
  const mm = padTo2Digits(date.getUTCMinutes());
  const ss = padTo2Digits(date.getUTCSeconds());

  return `${yyyy}-${mo}-${dd} ${hh}:${mm}:${ss}`;
}

const padTo2Digits = num => String(num).padStart(2, '0');

export default formatDateToApiUTCString;
