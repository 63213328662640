import React, { useRef, useLayoutEffect, useEffect } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";

import LS from 'utils/localStorageAPI';
import { selectIdsForSession } from "redux/selectors/selectors";
import { useDidUpdate, useDidMount, useToggle, usePrevious } from "hooks";
import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './Sessions.scss';
import Session from "./components/Session/Session";
import CustomScrollbarThumb from "components/UI/CustomScrollbarThumb/CustomScrollbarThumb";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";

const Sessions = (props) => {
  const {
    ids,
    type = {},
    userId,
    activeSession,
    accordion,
    icon: Icon,
    title,
    disableTitle,
    hidden,
  } = props;

  const [active, setActive] = useToggle(ids.includes(activeSession) || !hidden);

  const sessionListRef = useRef();

  const previousIdsLength = usePrevious(ids.length);

  useLayoutEffect(() => {
    const activeSessionIndex = ids.findIndex(id => id === activeSession);

    if (sessionListRef.current && activeSessionIndex) {
      sessionListRef.current.scrollTop(activeSessionIndex * 71);
    }
  }, [ids, activeSession])

  useEffect(() => {
    if (type.property === "completedIds" && ids.length !== previousIdsLength) {
      setActive(true);
    }
  }, [type, ids.length])

  useDidMount(() => {
    if (activeSession) {
      LS.setItem("aSession", activeSession, userId);
    }

    if (accordion && ids.length) {
      accordion.toggle(accordion.id, true);
    }
  });

  useDidUpdate(() => {
    LS.setItem("aSession", activeSession, userId);
  }, [activeSession, userId]);

  if (!ids.length && !type.isMine && !accordion && !hidden) {
    return null;
  }

  const sessionsList = (
    <div className="sales-sessions__list-wrap">
      <Scrollbars
        autoHide
        renderThumbVertical={CustomScrollbarThumb}
        renderTrackVertical={CustomScrollbarTrack}
        ref={sessionListRef}
      >
        <ul className="sales-sessions__list">
          {ids.map(sessionId => (
            <Session
              id={sessionId}
              isActive={sessionId === activeSession}
              isMine={type.isMine}
              isPartners={type.isPartners}
              isDefault={type.isDefault}
              isHistory={type.isHistory}
              isAssistance={type.isAssistance}
              isCompleted={type.property === 'completedIds'}
              key={sessionId}
            />
          ))}
        </ul>
      </Scrollbars>
    </div>
  )

  const renderMarker = () => {
    if (hidden) {
      return <ICONS.chevron className={classModifier("sales-sessions__icon", active ? "marker-open" : "marker-close")} />
    }

    if (Icon) {
      return <Icon className="sales-sessions__icon" />
    }
  }

  if (disableTitle) {
    return sessionsList;
  }

  return (
    <div className="sales-sessions">
      <div
        className="sales-sessions__header"
        onClick={() => hidden && setActive()}
        style={{
          cursor: hidden ? "pointer" : "initial"
        }}
      >
        {renderMarker()}

        <h3 className="sales-sessions__title">{title} ( {ids.length} )</h3>
      </div>

      {active && sessionsList}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  activeSession: state.sessions.activeSession,
  ids: selectIdsForSession(state, ownProps.type),
  userId: state.user.id
});

export default connect(mapStateToProps)(Sessions);
