import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import './HeaderDropdownButtons.scss';
import ICONS from 'assets/icons';
import Dialpad from './components/Dialpad/Dialpad';
import { classModifier } from 'utils';


const HeaderDropdownItems = () => {

  const [isDialpadOpen, setIsDialpadOpen] = useState(false);

  const dialpadPopperTooltip = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-start',
    visible: isDialpadOpen,
    onVisibleChange: setIsDialpadOpen,
  });

  return (
    <div className="header-dropdown-btns">
      <button className="header-dropdown-btns__button">
        <ICONS.bell className="header-dropdown-btns__icon" />
      </button>

      <button className="header-dropdown-btns__button">
        <ICONS.history className="header-dropdown-btns__icon" />
      </button>

      <button className="header-dropdown-btns__button">
        <ICONS.phoneVoicemail className="header-dropdown-btns__icon" />
      </button>

      <button
        ref={dialpadPopperTooltip.setTriggerRef}
        className={classModifier('header-dropdown-btns__button', [isDialpadOpen && 'open'])}
      >
        <ICONS.phoneMenu className='header-dropdown-btns__icon'/>
      </button>
      {isDialpadOpen &&
        <Dialpad
          setTooltipRef={dialpadPopperTooltip.setTooltipRef}
          getTooltipProps={dialpadPopperTooltip.getTooltipProps}
          isDialpadOpen={isDialpadOpen}
          onCloseDialPad={setIsDialpadOpen}
        />}
    </div>
  )
}

export default HeaderDropdownItems;
