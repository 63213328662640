import React from 'react';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';

import './ContactsForMergeBtn.scss';

const ContactsForMergeBtn = ({ contactsForMergeCount, tagsScrollIsBottom, ...props }) => {
  if (!contactsForMergeCount) {
    return null;
  }

  return (
    <button
      onClick={() => props.openModal(
        MODAL_TYPES.contactsMergeList, { openModal: props.openModal }
      )}
      className={classModifier("widget-btn", [tagsScrollIsBottom ? "top" : "bottom"])}
    >
      <ICONS.merge className="widget-btn__icon" />

      {!!contactsForMergeCount && (
        <span className={classModifier("widget-btn__counter", "contacts")}>
          {contactsForMergeCount > 99 ? "99+" : contactsForMergeCount}
        </span>
      )}

    </button>
  );
}

const mapStateToProps = (state) => ({
  contactsForMergeCount: state.counters.mergeContacts,
});

const mapDispatchToProps = {
  openModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsForMergeBtn);
