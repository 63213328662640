import { connect, useDispatch } from 'react-redux';

import { classModifier } from 'utils';
import { FEEDBACK_DATE_CONFIG } from 'config/dates-сonfig';

import './FeedbackItem.scss';
import ICONS from 'assets/icons';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import DateTime from 'components/DateTime';

const FeedbackItem = ({ item, onDelete, setFeedbackList, operator }) => {

  const dispatch = useDispatch();

  const getTypeLabelModifier = () => {
    switch (item.type) {
      case 2:
        return 'positive';
      case 1:
        return 'negative';
      default:   // (case 0) or other
        return 'neutral';
    }
  }

  const handleEdit = () => {
    dispatch(openModal(MODAL_TYPES.feedback, {
      initValues: item,
      onCancel: () => closeModal(MODAL_TYPES.feedback),
      setFeedbackList,
      isEdit: true
    }));
  }

  return (
    <div className='feedback-item'>
      <div className='feedback-item__header'>
        {item.divaName &&   // "divaName" check for v1 compatibility
          <p className='feedback-item__escort-name'>{item.divaName}</p>
        }

        <DateTime
          className="feedback-item__date"
          date={item.date_created}
          config={FEEDBACK_DATE_CONFIG}
        />

        <p className='feedback-item__added-by'>added by: {operator.username}</p>

        <div className='feedback-item__actions'>
          <span
            className={classModifier('feedback-item__type-label', [
              getTypeLabelModifier(),
            ])}
          />

          <button
            onClick={handleEdit}
            className={classModifier('feedback-item__action-button', 'edit')}
            type='button'
            title='Edit'
          >
            <ICONS.pencil />
          </button>

          <button
            className={classModifier('feedback-item__action-button', 'delete')}
            onClick={() => onDelete(item)}
            type='button'
            title='Delete'
          >
            <ICONS.trash />
          </button>
        </div>
      </div>

      <p className='feedback-item__text'>{item.body}</p>
    </div>
  );
}

const mSTP = (state, { item }) => ({
  operator: state.operators.entities[item.user_id] || state.user,
});

export default connect(mSTP)(FeedbackItem);
