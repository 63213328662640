import API from "api/api";
import _ from 'lodash';
import { showNotification } from "utils";
import { normalize } from "utils/normalizeContacts";

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const REMOVE_ALL_SESSION_NOTIFICATIONS = 'REMOVE_ALL_SESSION_NOTIFICATIONS';

export const SET_NOTIFICATIONS_UNREAD_COUNT = 'SET_NOTIFICATIONS_UNREAD_COUNT';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';

export const ADD_WIDGET_REMINDER = 'ADD_WIDGET_REMINDER';
export const ADD_WIDGET_REMINDERS = 'ADD_WIDGET_REMINDERS';
export const CLEAR_WIDGET_REMINDER = 'CLEAR_WIDGET_REMINDER';

export const GET_TASKS_AND_REMINDERS = 'GET_TASKS_AND_REMINDERS';
export const CREATE_NEW_TASK_OR_REMINDER = 'CREATE_NEW_TASK_OR_REMINDER';
export const UPDATE_NEW_TASK_OR_REMINDER = 'UPDATE_NEW_TASK_OR_REMINDER';
export const UPDATE_WEBMASTER_NOTIFICATION = 'UPDATE_WEBMASTER_NOTIFICATION';
export const MARK_AS_DONE_TASK_OR_REMINDER = 'MARK_AS_DONE_TASK_OR_REMINDER';
export const MARK_AS_DONE_WEBMASTER_TASK = 'MARK_AS_DONE_WEBMASTER_TASK';
export const DELETE_NEW_TASK_OR_REMINDER = 'DELETE_NEW_TASK_OR_REMINDER';
export const TOGGLE_OPEN_TASKS_AND_REMINDERS_MODAL = 'TOGGLE_OPEN_TASKS_AND_REMINDERS_MODAL';

export const NOTIFICATION_TYPES = {
  sessionView: 'session_view',
  sessionProposed: 'session_proposed',
  sessionChanged: 'another_operator_change_session',
  sessionAssistanceRequest: 'session_assistance_request',
  sessionAssistanceDelete: 'session_assistance_delete',
  sessionAssistanceResponse: 'session_assistance_response',
  sessionTransferRequest: 'session_transfer_request',
  sessionTransferResponse: 'session_transfer_response',
  changeOperatorStatus: 'change_operator_status',
  messageReminder: 'message-reminder',
  newMessageReminder: 'new-message-reminder',
  mention: 'mention',
  coldTransfer: 'cold_transfer',
  parkingCall: 'parking_call',
  warmTransfer: 'warm_transfer',
  bufferedProfileBecomeOff: 'buffered_profile_become_off',
  changeContactNumber: 'change_contact_number', // contact has wrote from another number
  addTelegramChannel: 'add_telegram_channel',
  missedClientCall: 'missed_client_call',
  missedSessionClientCall: 'missed_session_client_call',
  longSession: 'long_session',
  clientTextedInSession: 'client_texted_in_session',
  profileTextedInSession: 'profile_texted_in_session',
  newRelatedMessage: 'new_related_message',
  nightBotReject: 'night_bot_reject',
  partnerHasntReactTo: 'partner_hasnt_reacted_to',
  partnerHasBookedProfileInSession: 'partner_has_booked_profile_in_session',
  undeliveredMessage: 'undelivered_message',
  sessionUsingReminder: 'session_using_reminder',
  conferenceInvitation: 'conference_invitation',
  newMail: 'new_mail',
  webmaster: 'webmaster_reminder',
  task: 'task_reminder',
}

const NOTIFICATIONS_DURATION = {
  red: null,
  blue: null,
  orange: 300, // 5 minutes
  yellow: 120, // 2 minutes
  green: 30, // 30 seconds
}

export const addWidgetReminder = reminder => dispatch => {
  dispatch({
    type: ADD_WIDGET_REMINDER,
    payload: reminder,
  });

  showNotification({...reminder, isNotification: true});
};

export const addWidgetReminders = ({ data, ...counters }) => ({
  type: ADD_WIDGET_REMINDERS,
  payload: { data, counters },
})

export const deleteWidgetReminder = reminder => dispatch => {
  dispatch({
    type: CLEAR_WIDGET_REMINDER,
    payload: reminder
  });
};

export const addNotifications = offset => dispatch => {
  return API.getUserNotifications(offset)
    .then(({ data }) => {
      dispatch({
        type: offset
          ? UPDATE_NOTIFICATIONS
          : ADD_NOTIFICATIONS,
        payload: normalize(data)
      });

      return data;
    })
    .catch(console.error);
};

export const addNotification = (notification) => dispatch => {
  const {
    color = 'yellow',
    duration,
    date_created,
  } = notification;

  dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      ...notification,
      color,
      duration: duration || NOTIFICATIONS_DURATION[color],
      date_created: date_created ? date_created : new Date().getTime(),
    }
  })
};

export const removeAllSessionNotifications = (sessionId) => dispatch => {
  dispatch({
    type: REMOVE_ALL_SESSION_NOTIFICATIONS,
    payload: sessionId,
  })
};

export const removeNotification = (id) => dispatch => {
  dispatch({
    type: REMOVE_NOTIFICATION,
    payload: id
  });
};

export const setNotificationsUnreadCount = (count) => dispatch => {
  dispatch({
    type: SET_NOTIFICATIONS_UNREAD_COUNT,
    payload: count,
  });
}

export const readNotification = (notificationId) => dispatch => {
  return API.readUserNotification(notificationId)
    .then(() => {
      dispatch({
        type: READ_NOTIFICATION,
        payload: notificationId,
      })
    })
    .catch(console.error);
}

export const readAllNotifications = () => dispatch => {
  return API.readAllUserNotifications()
    .then(() => {
      dispatch({
        type: READ_ALL_NOTIFICATIONS,
      })
    })
    .catch(console.error);
}

export const getTasksAndReminders = ({ isLoadMore, ...props }) => dispatch => {
  return API.getTasksAndReminders(props)
    .then(({ data }) => {
      dispatch({
        type: GET_TASKS_AND_REMINDERS,
        payload: { data, isLoadMore },
      })
    })
    .catch(console.error);
}

export const createTasks = (props) => dispatch => {
  return API.createTasks(props)
    .then(({ data }) => {
      dispatch({
        type: CREATE_NEW_TASK_OR_REMINDER,
        payload: data,
      })
    })
    .catch(console.error);
}

export const updateTasksOrReminders = (props) => dispatch => {
  const request = props.isReminders ? API.updateReminders : API.updateTasks;
  return request(props)
    .then(({ data }) => {
      dispatch({
        type: UPDATE_NEW_TASK_OR_REMINDER,
        payload: { data, isReminders: props.isReminders },
      })
    })
    .catch(console.error);
}

export const updateWebmasterNotification = (props) => ({
  type: UPDATE_WEBMASTER_NOTIFICATION,
  payload: props,
})

export const markAsDoneTasksOrReminders = (id, isCompleted, isWebmaster) => dispatch => {
  let complete;

  if (isWebmaster) {
    complete = (id) => API.completeWebmasterTask(id, isCompleted);
  } else {
    complete = isCompleted ? API.markAsDoneTasksOrReminders : API.markAsInProgressTasksOrReminders;
  }

  return complete(id)
    .then(({ data }) => {
      dispatch({
        type: MARK_AS_DONE_TASK_OR_REMINDER,
        payload: data,
      })
    })
    .catch(console.error);
}

export const deleteTasksOrReminders = ({ item, isReminders, isWebmasterTask }) => (dispatch) => {
  let request;

  if (isReminders) {
    request = API.deleteReminders;
  } else if (isWebmasterTask) {
    request = API.removeWebmasterTask;
  } else {
    request = API.deleteTasks;
  }

  return request(item.id)
    .then(() => {
      dispatch({
        type: DELETE_NEW_TASK_OR_REMINDER,
        payload: { item },
      });
    })
    .catch(console.error);
};

export const toggleIsOpenTasksAndRemindersModal = ({ status }) => (dispatch) => {
  dispatch({
    type: TOGGLE_OPEN_TASKS_AND_REMINDERS_MODAL,
    payload: status,
  });
};

const initialState = {
  entities: {},
  savedIds: [], // notification center
  newIds: [], // notification section
  unreadCount: 0,
  reminders: [],
  tasksAndReminders: [], // tasksAndReminders modal state
  isOpenTasksAndRemindersModal: false,
  remindersCount: 0,
  webmasterRemindersCount: 0,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_NOTIFICATIONS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        savedIds: payload.result,
      }
    }
    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities,
        },
        savedIds: [
          ...state.savedIds,
          ...payload.result
        ],
      }
    }

    case ADD_WIDGET_REMINDER: {
      const reminders = [payload, ...state.reminders];
      const newCounts = payload.web_master_id
        ? { webmasterRemindersCount: state.webmasterRemindersCount + 1 }
        : { remindersCount: state.remindersCount + 1 };

      return {
        ...state,
        reminders,
        ...newCounts,
      }
    }

    case ADD_WIDGET_REMINDERS: {
      const reminders = [...state.reminders, ...payload.data];

      return {
        ...state,
        reminders,
        remindersCount: Number(payload.counters.reminders_count),
        webmasterRemindersCount: Number(payload.counters.web_master_reminders_count),
      }
    }

    case CLEAR_WIDGET_REMINDER: {
      const reminders = state.reminders.filter(item => item.id !== payload.id);
      const newCounts = payload.web_master_id
        ? { webmasterRemindersCount: state.webmasterRemindersCount - 1 }
        : { remindersCount: state.remindersCount - 1 };


      return {
        ...state,
        reminders,
        ...newCounts,
      }
    }

    case GET_TASKS_AND_REMINDERS: {
      return {
        ...state,
        tasksAndReminders: payload.isLoadMore
          ? [...state.tasksAndReminders, ...payload.data]
          : payload.data,
      }
    }

    case CREATE_NEW_TASK_OR_REMINDER: {
      return {
        ...state,
        tasksAndReminders: [payload, ...state.tasksAndReminders],
      }
    }

    case UPDATE_NEW_TASK_OR_REMINDER: {
      return {
        ...state,
        tasksAndReminders: state.tasksAndReminders.map((task) => {
          if (task.id === payload.data.id && !task.is_web_master) {
            if (payload.data.isReminders) {
              return { ...task, remind_at: payload.data.remind_at }
            } else {
              return payload.data
            }
          }
          return task
        }),
      }
    }

    case MARK_AS_DONE_TASK_OR_REMINDER: {
      return {
        ...state,
        tasksAndReminders: state.tasksAndReminders.map((task) => {
          if (task.id === payload.id
            && task.is_reminder === payload.is_reminder
            && task.is_web_master === payload.is_web_master) {
            return payload
          }
          return task
        })
      }
    }

    case DELETE_NEW_TASK_OR_REMINDER: {
      return {
        ...state,
        tasksAndReminders: state.tasksAndReminders.filter((task) =>
          task.id !== payload.item.id
          || (task.is_web_master !== payload.item.is_web_master
            || task.is_reminder !== payload.item.is_reminder)
        )
      }
    }

    case TOGGLE_OPEN_TASKS_AND_REMINDERS_MODAL: {
      return {
        ...state,
        isOpenTasksAndRemindersModal: payload,
      }
    }

    case ADD_NOTIFICATION: {
      let updatedUnreadCount = payload.isCustom
        ? state.unreadCount
        : state.unreadCount + 1;

      const isIdString = typeof payload.id === 'string';
      // id typeof === string only in those notifications, that we don`t save, that`s why saved only ids typeof === number

      const isNewIdAlreadyExist = state.newIds.includes(payload.id);

      return {
        ...state,
        unreadCount: updatedUnreadCount,
        entities: {
          ...state.entities,
          [payload.id]: payload
        },
        savedIds: isIdString
          ? state.savedIds
          : [
            payload.id,
            ...state.savedIds
          ],
        newIds: isNewIdAlreadyExist
          ? state.newIds
          : [
            payload.id,
            ...state.newIds,
          ],
      }
    }
    case REMOVE_NOTIFICATION: {
      if (!state.entities[payload]) return state;

      const updatedEntities = { ...state.entities };
      const updatedNewIds = state.newIds.filter(id => id !== payload);

      delete updatedEntities[payload];

      return {
        ...state,
        entities: updatedEntities,
        newIds: updatedNewIds,
      }
    }
    case UPDATE_WEBMASTER_NOTIFICATION: {
      return {
        ...state,
        tasksAndReminders: state.tasksAndReminders.map((task) => {
          if (task.id === payload.webMasterTask.id && task.is_web_master) {
            const { webMasterTask, ...restMessage } = payload;
            return {
              ...webMasterTask,
              interaction: restMessage,
            }
          }
          return task
        })
      }
    }
    case REMOVE_ALL_SESSION_NOTIFICATIONS: {
      const updatedNewIds = [...state.newIds];

      state.newIds.forEach(notificationId => {
        const sessionNotificationTypes = [
          NOTIFICATION_TYPES.sessionView,
          NOTIFICATION_TYPES.sessionProposed,
          NOTIFICATION_TYPES.sessionAssistanceRequest,
          NOTIFICATION_TYPES.sessionAssistanceDelete,
          NOTIFICATION_TYPES.sessionAssistanceResponse,
          NOTIFICATION_TYPES.sessionTransferRequest,
          NOTIFICATION_TYPES.sessionTransferResponse,
          NOTIFICATION_TYPES.missedSessionClientCall,
          NOTIFICATION_TYPES.longSession,
          NOTIFICATION_TYPES.clientTextedInSession,
          NOTIFICATION_TYPES.profileTextedInSession,
          NOTIFICATION_TYPES.partnerHasBookedProfileInSession,
          NOTIFICATION_TYPES.partnerHasntReactTo
        ];

        const isSessionNotification = sessionNotificationTypes.find(type => type === state.entities[notificationId].type);

        if (!isSessionNotification) {
          return;
        }
        const sessionId = state.entities[notificationId].data.session_id;

        if (sessionId === payload) {
          const index = updatedNewIds.indexOf(notificationId);
          updatedNewIds.splice(index, 1);
        }
      });

      return {
        ...state,
        newIds: updatedNewIds,
      }
    }
    case SET_NOTIFICATIONS_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload,
      }
    }
    case READ_NOTIFICATION: {
      const updatedEntities = _.pickBy(state.entities, (_, key) => key !== String(payload));
      const updatedNewIds = [...state.newIds.filter(id => id !== payload)];

      // if (state.savedIds.includes(payload)) {
      //   updatedEntities[payload] = {
      //     ...updatedEntities[payload],
      //     viewed: 1
      //   };
      // }

      return {
        ...state,
        unreadCount: state.unreadCount - 1,
        entities: updatedEntities,
        newIds: updatedNewIds,
      }
    }
    case READ_ALL_NOTIFICATIONS: {
      const updatedEntities = { ...state.entities };

      state.savedIds.forEach(id => {
        updatedEntities[id] = {
          ...updatedEntities[id],
          viewed: 1
        }
      });

      return {
        ...state,
        unreadCount: 0,
        entities: updatedEntities,
        newIds: []
      }
    }
    default: {
      return state;
    }
  }
};
