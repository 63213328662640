import { ROOM } from 'config/constants';
import { classModifier, getContactAvatar } from 'utils';

import ICONS from 'assets/icons';

const RoomCreationFormItem = (props) => {
  const {
    userId,
    onClick, 
    operator, 
    editingRoom, 
    participantIds,
    isEditingPrivateRoom
  } = props;

  const isUserAdmin = editingRoom?.adminsIds.includes(userId);
  const isOperatorAlreadyParticipant = editingRoom?.usersIds.includes(operator.id);
  const isDisabled = !isUserAdmin && isOperatorAlreadyParticipant;

  if(isEditingPrivateRoom && !participantIds.includes(operator.id)){
    return null
  }


  return (
    <div
      key={operator.id} 
      role="button"
      className={classModifier('room-creation-form__item', isDisabled && 'disabled')}
      disabled={userId === operator.id} 
      onClick={() => !(isDisabled || isEditingPrivateRoom)  && onClick(operator.id)}
    >
      <div className="room-creation-form__item-ava">
        <img src={getContactAvatar(operator, ROOM)} />
      </div>

      <div className="room-creation-form__item-info">
        <div className="room-creation-form__item-name">
          {operator.username}
        </div>
      </div>

      <div className="room-creation-form__item-check">
        {participantIds.includes(operator.id)
          ? <ICONS.check className="room-creation-form__item-check-icon" />
          : <span className="room-creation-form__item-check-box" />
        }
      </div>
    </div>
  )
};

export default RoomCreationFormItem;