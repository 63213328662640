export default ({ longitude, latitude, radius }) => {
  if (latitude === null || longitude === null || !radius.value) {
    return {};
  }

  const radiusLatitudeMin = latitude - radius.value / 69.172;
  const radiusLatitudeMax = latitude + radius.value / 69.172;

  const milesPerLongitudeDegree = Math.cos(latitude * Math.PI / 180) * 69.172;

  const radiusLongitudeMin = longitude - radius.value / milesPerLongitudeDegree;
  const radiusLongitudeMax = longitude + radius.value / milesPerLongitudeDegree;

  return { radiusLongitudeMin, radiusLatitudeMin, radiusLongitudeMax, radiusLatitudeMax };
}
