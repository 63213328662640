import { classModifier, getContactAvatar } from 'utils';

import './SearchListEscortItem.scss';

const SearchListEscortItem = ({ item: escort, onClick }) => {
  const isOffToday = escort.availability?.includes?.('off');
  const isAvailableNow = escort.availability === 'available';

  return (
    <div className='search-list-escort-item' onClick={() => onClick(escort)}>
      <div className='search-list-escort-item__avatar-thumb'>
        <img src={getContactAvatar(escort)} alt="avatar" />
      </div>
      <div className='search-list-escort-item__info'>
        <p className={classModifier('search-list-escort-item__name', [
          isOffToday && 'off-today',
          isAvailableNow && 'available-now',
        ])}>
          {escort.fn}
        </p>

        {escort.detailsForPopUp &&
          <p className='search-list-escort-item__short-info'>
            <span>&pound; {escort.detailsForPopUp[0]}</span>

            <span>{escort.detailsForPopUp[1]}</span>
          </p>
        }
      </div>
    </div>
  )
}

export default SearchListEscortItem;
