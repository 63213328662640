import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { classModifier } from 'utils';

const BookingFieldType = ({ 
  fieldName,
  bookingEditProps = {},
  bookingRateUpdate,
  setBookingEditProps,
  isBookingEditorPanel,
  getCurrentSelectedRate,
  isDisabled,
  availableSoloTypes,
  availableDuoTypes,
  formChange,
  actualType,
}) => {
  const form = useForm();
  const { values, errors, submitFailed } = useFormState();
  const isShowIncallTypeForDuo = !isBookingEditorPanel && values.meetingType === 'duo';

  const availableTypes = {
    'incall': false,
    'incall_duo': false,
    'incall_both': false,
    'outcall': false,
  }

  /* calculate what checkboxes should be available */

  if (availableSoloTypes && availableDuoTypes) {
    if (availableSoloTypes === 'all' && availableDuoTypes === 'all') {
      for (let key in availableTypes) {
        availableTypes[key] = true;
      }
    }

    if (availableSoloTypes === 'incall' && ['all', 'outcall'].includes(availableDuoTypes)) {
      availableTypes['incall'] = true;
    }

    if (['all', 'outcall'].includes(availableSoloTypes) && availableDuoTypes === 'incall') {
      availableTypes['incall_duo'] = true;
    }

    if (availableSoloTypes === 'all' && availableDuoTypes === 'outcall') {
      availableTypes['incall'] = true;
      availableTypes['outcall'] = true;
    }

    if (availableSoloTypes === 'outcall' && availableDuoTypes === 'all') {
      availableTypes['incall_duo'] = true;
      availableTypes['outcall'] = true;
    }
  }

  if (availableSoloTypes && !availableDuoTypes) {
    if (availableSoloTypes === 'all') {
      availableTypes.incall = true;
      availableTypes.outcall = true;
    } else {
      availableTypes[availableSoloTypes] = true;
    }
  }

  const handleChangeType = (e, input, type) => {
    input.onChange && input.onChange(e);

    const selectedLabel = values.bookingDuration?.label;
    const selectedDuration = values.profile.prices
      .map(item => ({ ...item, incall_duo: item.incall }))
      .find(i => i.name === selectedLabel) || '';

    const duration = {
      label: selectedLabel,
      value: selectedDuration?.[type]
    }

    if (duration.value) {
      form.change('bookingDuration', duration);
    }

    getCurrentSelectedRate && getCurrentSelectedRate(duration, type);
  };
  
  const handleSetData = (type) => {
    const profileLabel = bookingEditProps.profile?.bookingDuration?.label;
    const escortLabel = bookingEditProps.escort?.bookingDuration?.label;

    const getDurationByLabel = (profile, selectedLabel) => {
      const selectedDuration = profile?.prices.find(i => i.name === selectedLabel) || '';

      return selectedLabel && selectedDuration?.[type]
        ? { label: selectedLabel, value: selectedDuration?.[type] }
        : ''
    };

    const oppositeFieldName = fieldName === 'profile' ? 'escort' : 'profile';
    const isDisabledTaxiSolo = type === 'incall' && !bookingEditProps.escort;

    const BD = {
      profile: getDurationByLabel(values.profile, profileLabel),
      escort: getDurationByLabel(values.escort, escortLabel)
    }

    setBookingEditProps(prevState => {
      return {
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          type,
          taxi: isDisabledTaxiSolo ? { value: null, label: '-' } : prevState[fieldName]?.taxi,
          bookingDuration: BD[fieldName],
          rate: bookingRateUpdate(
            BD[fieldName], 
            type, 
            prevState[fieldName]?.rate, 
            values[fieldName]
          ),
        },
        [oppositeFieldName]: prevState.escort ? {
          ...prevState[oppositeFieldName],
          bookingDuration: BD[oppositeFieldName],
          rate: bookingRateUpdate(
            BD[oppositeFieldName], 
            prevState[oppositeFieldName]?.type,
            prevState[oppositeFieldName]?.rate, 
            values[oppositeFieldName]
          ),
        } : null
      }
    });
  };

  const isSelected = (type, input) => {
    if (isBookingEditorPanel) {
      return bookingEditProps[fieldName]?.type === type;
    } else {
      return Object.values(availableTypes).filter(Boolean).length === 1 ? availableTypes[type] : input.checked;
    }
  }

  const isOnlyOneType = (type) => {
    return Object.values(availableTypes).filter(Boolean).length === 1 && availableTypes[type];
  }
  
  useEffect(() => {
    const findFirstAvailableType = (availableTypes) => {
      return Object.entries(availableTypes).find(([_, value]) => value)?.[0] || 'incall';
    }

    if ((actualType && !availableTypes[actualType]) || !actualType) {
      formChange && formChange('type', findFirstAvailableType(availableTypes))
    }
  }, [formChange, actualType]);

  return (
    <div className={classModifier('booking-form__field', 'type')}>
      <div className={classModifier('booking-form__type-input-group')}>
        <Field name='type' type='radio' value="incall">
          {({ input }) => {
            return (
              <label className='booking-form__booking-type'>
                <input 
                  {...input} 
                  {...(isBookingEditorPanel ? { checked: bookingEditProps[fieldName]?.type === 'incall' } : {})}
                  onChange={(e) => {
                    isBookingEditorPanel 
                      ? handleSetData('incall')
                      : handleChangeType(e, input, 'incall');
                  }}
                  disabled={isDisabled || (availableSoloTypes && !availableTypes['incall'])}
                />
                <div className={classModifier('booking-form__radio', isSelected('incall', input) && 'selected')}></div>
                <span className='booking-form__booking-type-name'>
                  Incall{isShowIncallTypeForDuo ? ` (${values.profile?.name})` : isOnlyOneType('incall') && ' only'}
                </span>
              </label>
            )
          }}
        </Field>

        {isShowIncallTypeForDuo &&
          <Field name='type' type='radio' value="incall_duo">
            {({ input }) => {
              return (
                <label className='booking-form__booking-type'>
                  <input 
                    {...input} 
                    {...(isBookingEditorPanel ? { checked: bookingEditProps[fieldName]?.type === 'incall_duo' } : {})}
                    onChange={(e) => {
                      isBookingEditorPanel 
                        ? handleSetData('incall_duo')
                        : handleChangeType(e, input, 'incall_duo');
                    }}
                    disabled={isDisabled || (availableDuoTypes && !availableTypes['incall_duo'])}
                  />
                  <div className={classModifier('booking-form__radio', isSelected('incall_duo', input) && 'selected')}></div>
                  <span className='booking-form__booking-type-name'>
                    Incall {isShowIncallTypeForDuo && `(${values.escort?.name})`}
                  </span>
                </label>
              )
            }}
          </Field>
        }
      </div>

      <div className={classModifier('booking-form__type-input-group')}>
        <Field name='type' type='radio' value="outcall">
          {({ input }) => {
            return (
              <label className='booking-form__booking-type'>
                <input 
                  {...input} 
                  {...(isBookingEditorPanel ? { checked: bookingEditProps[fieldName]?.type === 'outcall' } : {})}
                  onChange={(e) => {
                    isBookingEditorPanel 
                      ? handleSetData('outcall')
                      : handleChangeType(e, input, 'outcall')
                  }}
                  disabled={isDisabled || (availableSoloTypes && !availableTypes['outcall'])}
                />
                <div className={classModifier('booking-form__radio', isSelected('outcall', input) && 'selected')}></div>
                Outcall {isOnlyOneType('outcall') && ' only'}
              </label>
            )
          }}
        </Field>

        {isShowIncallTypeForDuo &&       
          <Field name='type' type='radio' value="incall_both">
            {({ input }) => {
              return (
                <label className='booking-form__booking-type'>
                  <input 
                    {...input} 
                    {...(isBookingEditorPanel ? { checked: bookingEditProps[fieldName]?.type === 'incall_both' } : {})}
                    onChange={(e) => {
                      isBookingEditorPanel 
                        ? handleSetData('incall_both')
                        : handleChangeType(e, input, 'incall_both')
                    }}
                    disabled={isDisabled || (availableSoloTypes && !availableTypes['incall_both'])}
                  />
                  <div className={classModifier('booking-form__radio', isSelected('incall_both', input) && 'selected')}></div>
                  Incall Both {isOnlyOneType('incall_both') && ' only'}
                </label>
              )
            }}
          </Field>
        }
      </div>

      {submitFailed && errors.type && 
        <span className={classModifier('booking-form__field-error', 'type')}>
          {errors.type}
        </span>
      }
    </div>
  )
};

export default BookingFieldType;
