import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { classModifier, getContactAvatar } from 'utils';

import '../MentionedOrRecentlyViewedProfilesList.scss';
import MentionedOrRecentlyViewedProfile from '../MentionedOrRecentlyViewedItem/MentionedOrRecentlyViewedProfile';
import { useToggle } from 'hooks';
import ICONS from 'assets/icons';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const MentionedProfilesList = ({
  profilesItems,
  mentioned,
  caller,
}) => {
  const [openProfileList, setProfileList] = useToggle(false);

  return (
    <div className={classModifier('recently-viewed-profiles', [openProfileList && 'open'])}>
      <div className="recently-viewed-profiles__title-box">
        <h2>Escorts mentioned in communication ({profilesItems.length})</h2>
        <div 
          className="recently-viewed-profiles__subtitle" 
          onClick={setProfileList}
        >
          <div className="recently-viewed-profiles__client-info">
            <LazyLoadImage
              src={getContactAvatar(caller)}
              className="round-img"
              alt="contact_avatar"
            />
            <p className="recently-viewed-profiles__client-name">
              {caller.fn}
            </p>
          </div>
          <div className="recently-viewed-profiles__icon-wrapper">
            <ICONS.chevron
              className={classModifier('recently-viewed-profiles__chevron', [
                openProfileList && 'rotated',
              ])}
            />
          </div>
        </div>
      </div>

      {openProfileList && (
        <ul className="recently-viewed-profiles__items">
          <Scrollbars
            autoHide
            renderThumbVertical={() => (
              <div className="recently-viewed-profiles__scrollbar-thumb" />
            )}
          >
            {profilesItems.map((profile) => {
              return (
                <MentionedOrRecentlyViewedProfile
                  profile={profile}
                  mentioned={mentioned}
                  id={profile.profileId}
                  key={profile.profileId}
                />
            )})}
          </Scrollbars>
        </ul>
      )}
    </div>
  );
};

export default MentionedProfilesList;