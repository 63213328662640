import deleteCookie from "./deleteCookie";

export default (err) => {
  const currentHostname = window && window.location && window.location.hostname;
  const isDev = currentHostname === 'localhost';

  if (!err.response) {
    err.message && console.error(err.message);
    return console.error('Unhandled error in handleNetError.js', err);
  }

  switch (err.response.data.code && !isDev) {
    case 666:
      console.error('Invalid Connect Token');

      deleteCookie('isAuthenticated');

      delete localStorage.isLoggedIn;
      delete localStorage.currentUserId;

      sessionStorage.setItem('lastInteractionPage', window.location.pathname);

      window.location.href = '/';
      break;
    default:
      console.error('Unhandled error', err);
      break;
  }
}
