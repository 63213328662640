import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import API from 'api/api';
import { CONTACT_TYPES } from 'config/constants';
import { BOOKING_STATUS_FILTERS_NAMES } from 'components/FilteredBookings';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import { updateBookings } from 'redux/ducks/bookings';
import { useFilteredBookings } from 'components/FilteredBookings';

import "./ContactBookings.scss"
import List from 'components/List/List';
import { BookingItemNew } from 'components/BookingItemNew';
import { BookingTag } from 'components/FilteredBookings';

const bookingFilters = [
  BOOKING_STATUS_FILTERS_NAMES.all,
  BOOKING_STATUS_FILTERS_NAMES.done,
  BOOKING_STATUS_FILTERS_NAMES.confirmed,
  BOOKING_STATUS_FILTERS_NAMES.cancelled,
];

const bookingType = {
  [CONTACT_TYPES.GIRL]: BOOKING_ITEM_VIEWS.GIRL,
  [CONTACT_TYPES.CLIENT]: BOOKING_ITEM_VIEWS.CLIENT,
}

const statisticsLabels = {
  'averageRate': 'Average rate',
  'frequency': 'Booking frequency',
  'popularServices': 'Preferred services',
}


const Filters = ({ getters }) => (
  <ul className="contact-bookings__filters-list">
    {bookingFilters.map((filter) => (
      <BookingTag
        key={filter}
        className="contact-bookings__booking-tag"
        {...getters.getStatusFilterProps({ name: filter })}
      />
    ))}
  </ul>
)


const Statistic = ({ callerId, profileId }) => {
  const [statisticsData, setStatisticsData] = useState({});

  useEffect(() => {
    API.getStatisticsBookingsByCallerId({ callerId, profileId })
      .then(({ data }) => {
        setStatisticsData(data)
      })
      .catch(console.error)
  }, []);

  const items = Object.entries(statisticsLabels).map(([key, label]) => {
    const isStatisticAbsent = !(statisticsData[key]?.length ?? statisticsData[key]);

    if (isStatisticAbsent) {
      return null;
    }

    return (
      <div className="contact-bookings__title" key={key}>
        {`${label}: `}
        <span className="contact-bookings__subtitle">
          {Array.isArray(statisticsData[key])
            ? statisticsData[key].join(', ')
            : statisticsData[key]}
        </span>
      </div>
    )
  })

  return (
    <div className="contact-bookings__info">
      {items}
    </div>
  )
}


const getExtraConfig = (activeRecipient) => {
  if (activeRecipient.type === CONTACT_TYPES.GIRL) {
    return { girlId: activeRecipient.diva_default_id }
  } else {
    return { clientId: activeRecipient.id }
  }
}

const ContactBookings = ({ activeRecipient }) => {
  const { girlId, clientId } = getExtraConfig(activeRecipient);

  const { getters, useDayUpdated } = useFilteredBookings({
    limit: 15,
    extraConfig: {
      girlId,
      clientId,
      orderBy: 'date',
      sort: 'desc'
    },
    duringAllTime: true,
    isNotFull: true,
  });
  const [isDayUpdated, setIsDayUpdated] = useState(false);

  const { getBookingListProps } = getters;

  const dispatch = useDispatch();

  useDayUpdated((bookingIds) => {
    if (!bookingIds.length) return setIsDayUpdated(true);

    API.getAgentAndRequests({ bookingIds })
      .then(({ data }) => {
        dispatch(updateBookings(data))
      })

    setIsDayUpdated(true);
  })

  return (
    <>
      <div className="contact-bookings__header">
        <Filters getters={getters} />
        <Statistic callerId={clientId} profileId={girlId} />
      </div>

      <div className="contact-bookings__list-wrapper">
        <List
          classPrefix="contact-bookings"
          {...getBookingListProps({ extraPending: !isDayUpdated })}
        >
          <BookingItemNew type={bookingType[activeRecipient.type]} />
        </List>
      </div>
    </>
  );
};

export default ContactBookings;
