import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectLockedOperator } from 'redux/selectors/selectors';

import './BookingOperator.scss'
import Tooltip from 'components/UI/Tooltip/Tooltip';

const BookingOperator = ({ className, booking, style }) => {
  const operatorName = useSelector((state) => selectLockedOperator(state, booking.users_ids));

  return (
    <Tooltip
    	text={operatorName}
      visibleWhenIsOverflowed
    >
      <span className={classNames(className, 'booking-operator')} style={style}>
        {operatorName}
      </span>
    </Tooltip>
  )
}

export default BookingOperator;
