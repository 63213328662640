import React, { useRef } from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import ICONS from 'assets/icons';
import FormTextarea from 'components/FormTextTextarea/FormTextarea';
import Dropzone from 'react-dropzone';

const FILES_SIZE_LIMIT_MB = 20;

const BookingNotes = ({ attachments, updateAttachments, deleteAttachemnts, isDisabled }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const handleDropAccepted = (dropFiles) => {
    if (isDisabled) return;

    const totalFileSizeMB = Math.ceil(dropFiles.reduce((acc, file) => acc + file.size, 0) / 1000000);

    if (totalFileSizeMB > FILES_SIZE_LIMIT_MB) {
      alert('Files size limit 20MB');
      return;
    }

    const newAttachment = dropFiles.map((file) => {
      return Object.assign(file, {
        link: URL.createObjectURL(file)
      });
    });

    updateAttachments(newAttachment);
  }

  const onClickHandler = (index) => {
    const modalProps = {
      media: attachments,
      defaultActiveSlide: index,
      isNotProfile: true,
    }

    dispatch(openModal(MODAL_TYPES.profileCarousel, modalProps))
  }

  return (
    <Dropzone
      accept={`image/jpeg, image/png, image/gif, image/webp`}
      onDropAccepted={handleDropAccepted}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          className="booking-edit-form__note"
          {...getRootProps()}
        >
          <h3 className="booking-edit-form__note-title">
            <figure className="booking-edit-form__note-caption">
              <ICONS.warn className="booking-edit-form__note-icon" />

              <figcaption>
                Notes
              </figcaption>
            </figure>

            <button
              className='booking-form__btn'
              title="attach file"
              type="button"
              onClick={() => fileInputRef.current?.click()}
              disabled={isDisabled}
            >
              <input
                {...getInputProps()}
                ref={fileInputRef}
              />

              <ICONS.clip className="booking-edit-form__note-attach-icon" />
            </button>
          </h3>

          <Field name='notes'>
            {({ input, meta }) => {
              return (
                <FormTextarea
                  isDefineLinks
                  input={input}
                  inputClassName="booking-edit-form__note-textarea"
                  className="booking-edit-form__note-textarea-wrap"
                  meta={meta}
                  isDisabled={isDisabled}
                />
              )
            }}
          </Field>

          {
            attachments && !!attachments.length && (
              <ul className="booking-edit-form__attachment-list">
                {attachments.map((attachment, i) => (
                  <li className="booking-edit-form__attachment-item" key={i}>
                    <a
                      className="booking-edit-form__attachment-link"
                      onClick={() => onClickHandler(i)}
                    >
                      {attachment.name}
                    </a>

                    <button
                      className="booking-edit-form__atachment-link-clear"
                      onClick={() => deleteAttachemnts(i)}
                    >
                      <ICONS.close />
                    </button>
                  </li>
                ))}
              </ul>
            )
          }
        </div>

      )}
    </Dropzone>
  )
}

export default BookingNotes;
