import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { FormSpy } from 'react-final-form';

import ICONS from 'assets/icons';
import { DRAFT_STATUSES } from 'redux/ducks/mail';
import { useDidUpdate, useWillUnmount } from 'hooks';
import { debounce, classModifier } from 'utils';

import './MailFormAutosave.scss';

const MailFormAutosave = ({
  status,
  values,
  onSave,
  isHide,
  setStatus,
  additionalFields,
  disabled = false,
}) => {
  // This refs is necessary to receive actual values in saveValuesDebounced() and useWillUnmount().
  // In saveValuesDebounced() need to get values of variables at the time of function execution.
  // And if you, for example, path them (variables) through function params - values will not be actual
  // because they are remembered at the moment of timeout start.
  // For example, if draft.id in MailChatItem changes during the debounce timeout - saveValuesDebounced()
  // will call the old version of function onSave and inside it will be the old value of draft.id
  const savedNoticeTimeout = useRef(null);
  const promise = useRef(null);
  const statusActual = useRef(null);
  const valuesActual = useRef(null);
  const onSaveActual = useRef(null);
  const disabledActual = useRef(null);

  const formValues = useMemo(() => ({ ...additionalFields, ...values }), [values, additionalFields]);

  const setStatusWithTimeout = (newStatus) => {
    clearTimeout(savedNoticeTimeout.current);

    setStatus(newStatus);

    if (newStatus === DRAFT_STATUSES.savedNotice) {
      savedNoticeTimeout.current = setTimeout(() => setStatus(DRAFT_STATUSES.saved), 4000);
    }
  }

  const saveValuesDebounced = useCallback(
    debounce(async (formValues) => {
      if (promise.current) {
        await promise.current;
      }

      if (disabledActual.current) {
        return;
      }

      setStatusWithTimeout(DRAFT_STATUSES.pending);

      try {
        promise.current = onSaveActual.current({ values: formValues });
        await promise.current;

        if (statusActual.current !== DRAFT_STATUSES.notSaved) {
          setStatusWithTimeout(DRAFT_STATUSES.savedNotice);
        }
      } catch {
        setStatusWithTimeout(DRAFT_STATUSES.notSaved);
      } finally {
        promise.current = null;
      }
    }, 2000),
    []
  );
  
  useDidUpdate(() => {
    setStatusWithTimeout(DRAFT_STATUSES.notSaved);

    if (!disabled) {
      saveValuesDebounced(formValues);
    }
  }, [formValues]);

  useEffect(() => {
    statusActual.current = status;
  }, [status]);

  useEffect(() => {
    valuesActual.current = formValues;
  }, [formValues]);

  useEffect(() => {
    onSaveActual.current = onSave;
  }, [onSave]);

  useEffect(() => {
    if (!disabled && status === DRAFT_STATUSES.savedNotice) {
      setStatusWithTimeout(DRAFT_STATUSES.saved);
    }

    disabledActual.current = disabled;
  }, [disabled]);

  useWillUnmount(() => {
    clearTimeout(savedNoticeTimeout.current);

    if (statusActual.current === DRAFT_STATUSES.notSaved && !disabledActual.current) {
      onSaveActual.current({ values: valuesActual.current });
    }

    // To cancel waiting requests after unmount
    disabledActual.current = true;
  });

  useEffect(() => {
    const handleUnload = () => {
      if (status === DRAFT_STATUSES.notSaved && !disabled) {
        onSave({ values: formValues, isWindowUnload: true });
      }
    }

    window.addEventListener('unload', handleUnload);

    return () => window.removeEventListener('unload', handleUnload);
  }, [status, formValues, onSave, disabled]);

  return (
    <div className={classModifier('mail-form-autosave', isHide && 'hide')}>
      {!disabled && status === DRAFT_STATUSES.pending &&
        <div className="mail-form-autosave__group">
          <ICONS.save className="mail-form-autosave__status-icon"/>

          <p className="mail-form-autosave__status-label">Saving...</p>
        </div>
      }

      {!disabled && status === DRAFT_STATUSES.savedNotice &&
        <div className="mail-form-autosave__group">
          <ICONS.check className="mail-form-autosave__status-icon"/>

          <p className="mail-form-autosave__status-label">Draft saved</p>
        </div>
      }
    </div>
  )
}

export default props => (
  <FormSpy component={MailFormAutosave} {...props} />
)
