
import './Header.scss';
import Navbar from './components/Navbar/Navbar';
import UserMenu from './components/UserMenu/UserMenu';
import HeaderDropdownButtons from './components/HeaderDropdownButtons/HeaderDropdownButtons';
import HeaderModalButtons from './components/HeaderModalButtons/HeaderModalButtons';
import ActiveCall from './components/ActiveCall/ActiveCall';
import ActiveConference from './components/ActiveConference/ActiveConference';

const Header = (props) => {
  return (
    <header className="header">
      <div className="header__item header__item--user-menu">
        <UserMenu setPagePending={props.setPagePending} />
        <HeaderDropdownButtons />
      </div>

      <div className="header__item">
        <Navbar />
      </div>

      <div className="header__item">
        <HeaderModalButtons />
      </div>

      <div className="header__item header__item--active-call">
        <ActiveCall />
        <ActiveConference />
      </div>
    </header>
  )
}

export default Header;
