import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CHAT_SOURCES, CHAT_TYPES } from 'config/constants';
import { selectUserTimezone } from 'redux/selectors/selectors';
import {
  stopChatMessageSearch,
  searchMessageInChat,
  getChatTimeline,
  getChatTimelineMedia,
  getChatTimelineGlossary,
  updateChatMessageSearch,
  updateChatTimelineMedia,
  updateChatTimeline,
  updateChatTimelineGlossary,
} from 'redux/ducks/roomChats';

import './GlossaryList.scss';
import ChatSources from 'containers/Chat/components/ChatSources/ChatSources';
import ChatTimeline1 from 'containers/Chat/components/ChatTimeline/ChatTimeline1';

const GlossaryList = ({
  search,
  timeline,
  timelinePending,
  updatePending,
  newInteractionType,
  auxiliaryTimeline,
  activeRecipient,
  todayMessagesCount,
  stopChatMessageSearch,
  searchMessageInChat,
  userId,
  userTimezone,
  getChatTimeline,
  getChatTimelineMedia,
  getChatTimelineGlossary,
  ...props
}) => {
  const [activeChatSource, setActiveChatSource] = useState(CHAT_SOURCES.MSGS);

  const chatTimeline = activeChatSource !== CHAT_SOURCES.MSGS || search ? auxiliaryTimeline : timeline;

  const startSearch = (query) => searchMessageInChat(query, 'girls', userId, userTimezone);

  const isMainTimelineOpen = search ||
    activeChatSource !== CHAT_SOURCES.MSGS ||
    props.contextMsgId ? false : true;

  const timelineLowerLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryLowerLoadedPage
    : props.timelineLowerLoadedPage;

  const timelinePageCount = !isMainTimelineOpen
    ? props.auxiliaryPageCount
    : props.timelinePageCount;

  const timelineHigherLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryHigherLoadedPage
    : props.timelineCurrentPage;

  const timelineCurrentPage = !isMainTimelineOpen
    ? props.auxiliaryCurrentPage
    : props.timelineCurrentPage;

  const updateMsgContext = (activeRecipient, page, loadDirection) =>
      this.props.updateChatMessageContext(props.contextMsgId, page, loadDirection, userId, activeRecipient.id, userTimezone);

  const updateTimeline = () => {
    if (props.contextMsgId) {
      return updateMsgContext;
    }
    else if (search) {
      return (activeRecipient, page, loadDirection) =>
        props.updateChatMessageSearch(activeRecipient, page, loadDirection, search, userId, userTimezone);
    }
    else if (activeChatSource === CHAT_SOURCES.MEDIA) {
      return (activeRecipient, page, loadDirection) =>
        props.updateChatTimelineMedia(activeRecipient, page, loadDirection, userId, userTimezone);
    }
    else if (activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
      return (activeRecipient, page, loadDirection) =>
        props.updateChatTimelineGlossary(page, loadDirection, userTimezone);
    }
    return (chat, page, loadDirection) =>
      props.updateChatTimeline(chat, page, loadDirection, userId, userTimezone);
  }

  useEffect(() => {
    switch (activeChatSource) {
      case CHAT_SOURCES.MSGS:
        getChatTimeline('girls', userId, userTimezone);
        break;
      case CHAT_SOURCES.MEDIA:
        getChatTimelineMedia('girls', userId, userTimezone);
        break;
      // case CHAT_SOURCES.SYSTEM_MSGS:
      //   getChatTimelineGlossary(userId, userTimezone);
      //   break;
    }
  }, [activeChatSource]);

  return (
    <div className="glossary-list">
      <div className="glossary-list__header glossary-list__header--empty">
        <div className="glossary-list__header-top">
          <h3 className="glossary-list__title">
            Girls chat ({todayMessagesCount})
          </h3>
        </div>
      </div>

      <ChatSources
        type={CHAT_TYPES.GIRLROOM}
        activeRecipient={activeRecipient}
        stopMessageSearch={stopChatMessageSearch}
        startMessageSearch={startSearch}
        setActiveChatSource={setActiveChatSource}
        activeChatSource={activeChatSource}
        search={search}
      />

      <ChatTimeline1
        activeRecipient={activeRecipient}
        activeChatSource={activeChatSource}
        type={CHAT_TYPES.ROOM}
        timeline={chatTimeline}
        timelinePending={timelinePending}

        updatePending={updatePending}
        timelineCurrentPage={timelineCurrentPage}
        timelinePageCount={timelinePageCount}
        timelineHigherLoadedPage={timelineHigherLoadedPage}
        timelineLowerLoadedPage={timelineLowerLoadedPage}
        newInteractionType={newInteractionType}
        updateContactTimeline={updateTimeline()}

        profileId={userId}
        userTimezone={userTimezone}
        userHour12={props.userHour12}

        search={search}
        contextMsgId={props.contextMsgId}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  activeRecipient: state.rooms.entities[state.roomChats.active],
  timeline: state.roomChats.timeline,
  timelinePending: state.roomChats.timelinePending,
  updatePending: state.roomChats.updatePending,
  timelineCurrentPage: state.roomChats.timelineCurrentPage,
  timelinePageCount: state.roomChats.timelinePageCount,
  timelineLowerLoadedPage: state.roomChats.timelineLowerLoadedPage,
  todayMessagesCount: state.roomChats.todayMessagesCount,
  newInteractionType: state.roomChats.newInteractionType,
  search: state.roomChats.search,
  user: state.user,
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),

  auxiliaryTimeline: state.roomChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.roomChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.roomChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.roomChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.roomChats.auxiliaryCurrentPage,

  contextMsgId: state.roomChats.contextMsgId,
  shortcuts: state.msgTemplates.shortcuts,
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  stopChatMessageSearch,
  searchMessageInChat,
  getChatTimeline,
  getChatTimelineMedia,
  getChatTimelineGlossary,
  updateChatMessageSearch,
  updateChatTimelineMedia,
  updateChatTimeline,
  updateChatTimelineGlossary,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlossaryList);
