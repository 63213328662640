import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';

import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { updateActiveFilters } from 'redux/ducks/sessions';
import { selectTransferOrAssistanceForNotification } from 'redux/selectors/selectors';
import { SessionNotification } from './SessionNotification';


export const SessionRequestNotification = ({ readAction, ...props }) => {
  const { id, type, data: { session_id } } = props.item;
  const transferOrAssistance = useSelector(state => selectTransferOrAssistanceForNotification(state, id));

  const dispatch = useDispatch();

  useEffect(() => {
    const isRequestReceivedResponse = type === NOTIFICATION_TYPES.sessionAssistanceRequest
      ? transferOrAssistance && !transferOrAssistance.includes(props.item.user_id)
      : transferOrAssistance !== props.item.user_id;

    if (isRequestReceivedResponse) {
      readAction();
    }
  }, [transferOrAssistance]);

  const replyToRequest = (status) => {
    let reply;

    switch (type) {
      case NOTIFICATION_TYPES.sessionAssistanceRequest:
        reply = API.responseForAddToAssistant;
        break;
      case NOTIFICATION_TYPES.sessionTransferRequest:
        reply = API.responseForTransferSession;
        break;
    }

    readAction();
    reply(session_id, status);
  };

  const extraActionAfterOpen = () => {
    const filter = `Proposed:${operator.username}:${userId}`;

    dispatch(updateActiveFilters(filter, session_id, [], true))
  }

  const extraButtons = (
    <>
      <button onClick={() => replyToRequest('accept')}>
        Accept
      </button>
      <button onClick={() => replyToRequest('ignore')}>
        Reject
      </button>
    </>
  )

  return (
    <SessionNotification
      {...props}
      extraButtons={extraButtons}
      extraActionAfterOpen={extraActionAfterOpen}
    />
  )
};
