import React, { useRef, useLayoutEffect } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";

import { CONTACTS_LIMIT } from 'redux/ducks/contacts';
import { useDidMount, usePrevious, useDidUpdate } from 'hooks';
import { debounce } from 'utils';

import './List.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, VariableSizeList } from 'react-window';
import classNames from 'classnames';

// TODO: add throttle on scroll

const List = ({ spinnerSize = 30, ...props }) => {
  const {
    list = [],
    limit = CONTACTS_LIMIT,
    hasMore = true,
    scrollInitialPosition = 'top',
    mode = '',
    classPrefix,
    listItemKey = 'id',
    useIndexAsItemKey, // needed to replace the key with an index
    listLoadPending,
    scrollbarProps = {},
    ignoredListItemsCount = 0,
    withoutAbsentLabel,
    absentLabel,
    autoHide,
    realListLength,
    itemSize,
    gap,
  } = props;

  const expectedListLength = useRef(limit);
  const scrollPosition = useRef(0);
  const scrollbarThumbRef = useRef();

  const prevList = usePrevious(list);
  const prevListLength = usePrevious(list.length);
  const prevIgnoredListItemsLength = usePrevious(ignoredListItemsCount);
  
  const ref = useRef();

  useDidMount(() => {
    const listDom = ref.current?._outerRef || ref.current;

    if (scrollInitialPosition === 'bottom') {
      listDom && listDom.scrollToBottom();
    }
    // if we already have more list items then LIMIT
    expectedListLength.current = list.length >= limit
      ? list.length
      : limit;
  });

  useDidUpdate(() => {
    const listDom = ref.current?._outerRef || ref.current;

    expectedListLength.current = list.length >= limit
      ? list.length
      : limit;

    if (listDom) {
      listDom.scrollTop(0);
    }
  }, [mode]);

  useDidUpdate(() => {
    /// multiple items added or removed from the list
    //  if they were added or removed from the ignore list
    if (prevListLength && ignoredListItemsCount &&
      ((prevListLength === list.length - (ignoredListItemsCount - prevIgnoredListItemsLength)) ||
        (prevListLength === list.length + (ignoredListItemsCount - prevIgnoredListItemsLength)))
    ) {
      expectedListLength.current = list.length;
    }
    //this can happen after loadMore if new items have already been
    else if (ignoredListItemsCount && (prevListLength + limit === list.length + ignoredListItemsCount)) {
      expectedListLength.current -= ignoredListItemsCount;
    }
    // // one item was removed from list
    else if (prevListLength && (prevListLength === list.length + 1)) {
      expectedListLength.current -= 1;
    }
    // one item added to the list
    // this can happen either after scrolling (when scroll returns only one item) 
    // or after adding an element
    // therefore it is necessary to exclude scrolling
    else if (
      prevListLength &&
      (prevListLength === list.length - 1) &&
      ( // scroll exclusion
        prevListLength === expectedListLength.current ||
        expectedListLength.current % prevListLength
      )
    ) {
      expectedListLength.current += 1;
    }
  }, [list, ignoredListItemsCount]);

  useLayoutEffect(() => {
    const listDom = ref.current?._outerRef || ref.current;

    if (prevListLength && list.length !== prevListLength) {
      if (scrollInitialPosition === 'bottom' && prevList?.[prevList.length - 1]?.id !== list?.[list.length - 1]?.id) {
        return listDom.scrollToBottom();
      }
    }

    if (list.length && listDom && scrollInitialPosition === 'bottom') {
      listDom.scrollToBottom();
    }
    // save scroll on last item if we scroll to top
    else if (prevListLength && list.length && listDom && scrollInitialPosition === 'bottom') {
      listDom.scrollTop(listDom.getScrollHeight() - scrollPosition.current);
    }
  }, [list, scrollInitialPosition, listLoadPending]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = (props.virtualized ? (ref.current?._outerRef || ref.current) : e.currentTarget) || {};

    const scrollbarHeight = clientHeight * (clientHeight / scrollHeight);
    const maxScrollTop = scrollHeight - clientHeight;
    const scrollFraction = scrollTop / maxScrollTop;
    const maxTop = clientHeight - scrollbarHeight;
    
    if (scrollbarThumbRef.current) {
      scrollbarThumbRef.current.classList.remove('scrollbar-thumb--hidden');
      
      if (scrollbarHeight) {
        scrollbarThumbRef.current.style.height = `${scrollbarHeight}px`;
      }
      
      if (scrollFraction) {
        scrollbarThumbRef.current.style.top = `${scrollFraction * maxTop}px`;
      }
  
      const hideScrollbarThumb = () => {
        return scrollbarThumbRef.current?.classList.add('scrollbar-thumb--hidden')
      }
  
      debounce(hideScrollbarThumb, 2000)();
    }

    if (hasMore) {
      const scrollThreshold = 1;

      const scrollBottom = scrollHeight - scrollTop - clientHeight;

      const listLength = realListLength || list.length

      if (scrollInitialPosition === 'top' && scrollBottom <= scrollThreshold && expectedListLength.current === listLength) {
        expectedListLength.current = listLength + limit;

        props.loadMore && props.loadMore(listLength + ignoredListItemsCount);    // pass offset to loadMore func
      }
      else if (scrollInitialPosition === 'bottom' && scrollTop <= scrollThreshold && expectedListLength.current === listLength) {
        expectedListLength.current = listLength + limit;

        scrollPosition.current = scrollHeight - scrollTop;

        props.loadMore && props.loadMore(listLength + ignoredListItemsCount);
      }
    }
  };

  const getItemKey = (item, index) => {
    if (useIndexAsItemKey) {
      return index;
    }
    return item?.[listItemKey] || item;
  }


  if (props.virtualized) {
    // render virtualized list when items in list too much and we want to render only visible items

    const VirtualizedList = itemSize instanceof Function ? VariableSizeList : FixedSizeList;

    return (
      <div className={classPrefix + "__list-container"}>
        <div className={classNames('scrollbar-thumb', props.classPrefix + '__scrollbar')} ref={scrollbarThumbRef}></div>

        {listLoadPending
          ? <div className={classPrefix + "__load-wrap"}>
            <Spinner spinnerSize={spinnerSize} />
          </div>
          : (
            !!list.length
              ? (
                <AutoSizer>
                  {({ width, height }) => (
                    <VirtualizedList
                      className={classPrefix + "__list"}
                      ref={ref}
                      width={width}
                      height={height}
                      itemCount={list.length}
                      itemSize={itemSize}
                      onScroll={handleScroll}
                      overscanCount={5}
                    >
                      {({ index, style }) => {
                        const item = list[index];
                        const key = getItemKey(item, index);
                        const className = classPrefix + "__item";
                        const resetSize = () => ref.current.resetAfterIndex(index);
                        const newStyle = { ...style, marginBottom: gap }

                        const newProps = {
                          key,
                          item,
                          style: newStyle,
                          index,
                          className,
                          resetSize,
                        };

                        return typeof props.children === 'function'
                          ? props.children(newProps)
                          : React.cloneElement(props.children, newProps);
                      }}
                    </VirtualizedList>
                  )}
                </AutoSizer>
              )
              : !withoutAbsentLabel && (
                <div className={classPrefix + '__no-items'}>
                  {absentLabel ?? 'no items'}
                </div>
              )
          )
        }
      </div>
    )
  }

  return (
    <div className={classPrefix + "__list-container"} >
      {listLoadPending
        ? <div className={classPrefix + "__load-wrap"}>
          <Spinner spinnerSize={spinnerSize} />
        </div>
        : (
          !!list.length
            ? (
              <Scrollbars
                onScroll={handleScroll}
                ref={ref}
                autoHide={autoHide}
                {...scrollbarProps}
              >

                <ul className={classPrefix + "__list"}>
                  {list
                    .filter(Boolean)
                    .map((item, index) => {
                      const newProps = {
                        key: getItemKey(item, index),
                        item,
                        index,
                        className: classPrefix + "__item",
                      };

                      return typeof props.children === 'function'
                        ? props.children(newProps)
                        : React.cloneElement(props.children, newProps);
                    })}
                </ul>
              </Scrollbars>
            )
            : !withoutAbsentLabel && (
              <div className={classPrefix + '__no-items'}>
                {absentLabel ?? 'no items'}
              </div>
            )
        )
      }
    </div>
  );
};


export default React.memo(List);
