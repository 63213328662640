import React from "react";
import { connect } from 'react-redux';

import { isEmptyObj, classModifier } from "utils";
import { resetDefaultSessionFilters } from "redux/ducks/sessions";

const ResetDefaultSessionFiltersBtn = (props) => {
  const {
    activeSession,
    activeFilters,
    additionalFilters,
  } = props;

  if (activeSession !== 0) return null;

  const isFiltered = activeFilters.length || !isEmptyObj(additionalFilters);

  const handleResetClick = () => {
    if (isFiltered) {
      props.resetDefaultSessionFilters();
    }
  }

  return (
    <button
      className={classModifier('gallery-sidebar__filters-action-btn', ['reset', !isFiltered && 'disabled'])}
      onClick={handleResetClick}
    >
      Clear filters
    </button>
  );
};

const mapStateToProps = (state) => ({
  activeSession: state.sessions.activeSession,
});

const mapDispatchToProps = {
  resetDefaultSessionFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetDefaultSessionFiltersBtn);