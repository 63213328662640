import moment from 'moment';

export default (dateFrom, dateTo) => {
  const dates = [];

  while (dateFrom <= dateTo) {
    dates.push(dateFrom);
    dateFrom = moment(dateFrom).add(1, 'days').valueOf();
  }

  return dates;
}
