import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { useDidUpdate } from 'hooks';
import { DIVA_SORT_PARAMS } from 'redux/ducks/divaGirls';
import { ADDITIONAL_SESSION_FILTERS } from 'config/constants';
import { updateAdditionalTitleFilters } from 'redux/ducks/sessions';
import { selectPropertyOfActiveSession } from 'redux/selectors/selectors';

const GalleryHeaderSortSelect = (props) => {
  const { isSessionEditable } = props;

  const activeSession = useSelector(state =>  state.sessions.activeSession);
  const additionalFilters = useSelector(state => selectPropertyOfActiveSession(state, 'additionalFilters'));

  const [selectedOption, setSelectedOption] = useState(() => {
    if (!additionalFilters.sortby) return null;

    return { value: additionalFilters?.sortby };
  })

  const dispatch = useDispatch();

  useDidUpdate(() => {
    const option = additionalFilters.sortby
      ? { value: additionalFilters?.sortby }
      : null;
    
    setSelectedOption(option);
  }, [additionalFilters.sortby]);

  const onChangeSelect = (option) => {
    setSelectedOption(option);

    if (!option?.value) return;

    dispatch(updateAdditionalTitleFilters(
      ADDITIONAL_SESSION_FILTERS.sortby,
      option.value,
      activeSession,
      additionalFilters,
      isSessionEditable,
    ));
  }

  const options = useMemo(() => {
    return Object.keys(DIVA_SORT_PARAMS).map((value) => ({ value }));
  }, []);

  return (
    <Select
      className="react-select gallery-header-sort-select"
      classNamePrefix="react-select"
      options={options}
      maxMenuHeight={500}
      isSearchable={false}
      value={selectedOption}
      onChange={onChangeSelect}
      getOptionLabel={option => DIVA_SORT_PARAMS[option.value]}
      placeholder="Sort by"
      components={{
        IndicatorSeparator: null,
      }}
    />
  )
};

export default GalleryHeaderSortSelect;