import _ from 'lodash'

const modifyValues = (obj, modifier) => {
  return _.transform(obj, (result, value, key) => {
    if (_.isObject(value) && !(value instanceof Function)) {
      result[key] = modifyValues(value, modifier);
    } else {
      result[key] = modifier(value, key);
    }
  })
}

export default modifyValues;
