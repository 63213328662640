import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { BOOKING_STATUS_FILTERS, CANCEL_BOOKING_REASONS } from 'config/constants';

import './BookingFormCancelStatusBtn.scss';

const BookingFormCancelStatusBtn = ({ form, status, values }) => {
  const [isStatusPopupOpen, setIsStatusPopupOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState(values.reason);
  const [cancelDescription, setCancelDescription] = useState(values.description);

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      offset: [0, 15],
      trigger: 'click',
      placement: 'bottom',
      visible: isStatusPopupOpen,
      onVisibleChange: setIsStatusPopupOpen,
    }
  );

  const handleSubmit = () => {
    form.change('is_success', 0);
    form.change('reason', cancelReason);
    form.change('description', cancelDescription);
    form.change('bookingStatus', BOOKING_STATUS_FILTERS[status]);

    setIsStatusPopupOpen(false);
  };

  const handleCancel = () => {
    setCancelReason(values.reason ? values.reason : null);
    setCancelDescription(!!values.description.length ? values.description : null);
  };

  return (
    <>
      <div
        className='booking-status'
        style={{ borderColor: status.color }}
        ref={setTriggerRef}
      >
        {status}

        <span
          className='booking-status-dot'
          style={{ borderColor: status.color }}
        />
      </div>

      {visible &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'booking-cancel-form' })}
        >
          <div className="booking-cancel-form__reasons">
            <span className="booking-cancel-form__reasons-title">
              Choose the reason:
            </span>

            {CANCEL_BOOKING_REASONS.map((reason, idx) => (
              <label
                className="booking-cancel-form__reasons-label booking-cancel-form__radio"
                key={idx}
              >
                <input
                  type="radio"
                  checked={reason === cancelReason}
                  onChange={() => setCancelReason(reason)}
                />

                <span>{reason}</span>
              </label>
            ))}
          </div>

          <div className="booking-cancel-form__actions">
            <div className="booking-cancel-form__actions-descr">
              <textarea
                placeholder="description"
                value={cancelDescription || ''}
                onChange={(e) => {
                  const value = e.target.value;

                  setCancelDescription(value);
                }}
              />
            </div>

            <div className="booking-cancel-form__actions-btns">
              <button
                className="booking-cancel-form__btn booking-cancel-form__btn--submit"
                onClick={handleSubmit}
                disabled={!cancelReason}
              >
                Save
              </button>

              <button
                className="booking-cancel-form__btn booking-cancel-form__btn--close"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default BookingFormCancelStatusBtn;
