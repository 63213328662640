import { useCallback, useState } from 'react';

const useSelectorToggle = (value) => {
  const [chosen, setChosen] = useState(value);

  const toggle = useCallback((nextChosen) => {
    if (chosen === nextChosen) {
      setChosen(null);
    } else {
      setChosen(nextChosen);
    }
  }, [chosen])

  return [chosen, toggle];
}

export default useSelectorToggle;
