import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { RADIUS_OPTIONS } from '../../config/config';
import { MAP_SECONDARY_MODES, updateClientRadius, updateSecondaryMode } from 'redux/ducks/map';

import Marker from 'components/UI/Marker/Marker';
import { selectMapGirlBySecondaryMode } from 'redux/selectors/selectors';


const ClientMarker = ({ cluster, ...restProps }) => {
  const {
    clientData: { radius },
    zoom
  } = useSelector(state => state.map);
  const targetGirl = useSelector(state => selectMapGirlBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));

  const dispatch = useDispatch();

  const handleClick = () => {
    const nextOption = RADIUS_OPTIONS.find(({ value }) => value === radius.value + 0.5) || RADIUS_OPTIONS[0];
    dispatch(updateClientRadius(nextOption));
  }

  useEffect(() => {
    const isRadiusEscortsModeActive = !!radius.value;

    dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.RADIUS_ESCORTS, isRadiusEscortsModeActive));
  }, [radius.value])

  return (
    <Marker
      className="client-marker"
      radius={radius.value}
      zoom={zoom}
      onClick={handleClick}
      isLarge={!!targetGirl}
      {...restProps}
    >
      {radius.value || '?'}
    </Marker>
  )
};

export default ClientMarker;
