import classNames from 'classnames';

import './Circle.scss';


function calculateRadiusInPixels(radiusInMiles, zoomLevel, centerLat) {
  const EQUATOR_LENGTH_IN_MILES = 24901;
  const TILE_SIZE = 256;

  // Calculate the number of pixels per mile at the equator
  const pixelsPerMileAtEquator = TILE_SIZE / (EQUATOR_LENGTH_IN_MILES / Math.pow(2, zoomLevel));

  // Adjust the number of pixels per mile based on the latitude
  const pixelsPerMile = pixelsPerMileAtEquator * Math.cos(centerLat * (Math.PI / 180));

  return radiusInMiles * pixelsPerMile * 2.6;
}

const Circle = (props) => {
  const {
    radius,
    zoom,
    children,
    className,
    lat,
  } = props;

  return (
    <div
      className={classNames(className, "geo-circle")}
      style={{ "--radius": calculateRadiusInPixels(radius, zoom, lat) }}
    >
      {children}
    </div>
  );
}

export default Circle;
