import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';

const useOpenCommModuleModal = () => {
  const isMainPageOpen = useSelector((state) => state.user.isMainPageOpen);

  const dispatch = useDispatch();
  
  const checkIfMainPageOpened = useCallback((isMainPageOpened) => {
    if (isMainPageOpened !== undefined && !isMainPageOpened) {
      dispatch(openModal(MODAL_TYPES.openCommModule));
    } else {
      if (isMainPageOpened !== undefined) {
        dispatch(closeModal(MODAL_TYPES.openCommModule));
      }
    }
  }, [isMainPageOpen])

  useEffect(() => {
    checkIfMainPageOpened(isMainPageOpen);
  }, [isMainPageOpen])
}

export default useOpenCommModuleModal;
