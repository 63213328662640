import { useDidMount } from 'hooks';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionById } from 'redux/ducks/sessions';
import useSocket from './useSocket';

export const useSessionState = (sessionId) => {
  const session = useSelector((state) => state.sessions.entities[sessionId]);
  const completedSessionIds = useSelector((state) => state.sessions.completedIds);
  const activeSession = useSelector((state) => state.sessions.activeSession);
  const [sessionState, setSessionState] = useState({ status: 'not-available', session: null });

  const dispatch = useDispatch();

  useDidMount(() => {
    if (!sessionId) return;

    if (!session) {
      dispatch(getSessionById(sessionId))
        .then(res => {
          setSessionState({ status: 'closed', session: res.data });
        });
    }
    else if (completedSessionIds.includes(sessionId)) {
      setSessionState({ status: 'closed', session });
    } else {
      setSessionState({ status: 'available', session })
    }
  });

  useSocket((event) => {
    const { type } = JSON.parse(event.data);
    
    if (type === 'session_close' && type === 'session_delete') {
      setSessionState({ status: 'closed', session });
    }
  })

  return { ...sessionState, isSessonActive: activeSession === sessionId };
};
