import React, { useEffect } from 'react';

import API from 'api/api';
import { NOTES_DATE_CONFIG } from 'config/dates-сonfig';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { updateNotes } from 'redux/ducks/mail';
import List from 'components/List/List';
import DateTime from 'components/DateTime';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';
import Spinner from 'components/UI/Spinner/Spinner';

import './NotesForm.scss';

const validate = (values) => {
  const errors = {};
  
  if (!values.note) {
    errors.note = 'Required';
  }

  return errors;
}

const NotesForm = ({ conversationId, toggleIsNotesPopupOpened, innerRef, onMouseDown, ...props }) => {
  const dispatch = useDispatch();
  const notes = useSelector(state => state.mail[state.mail.mailList.activeFolder].entities[conversationId].notes);

  useEffect(() => {
    API.getMailNotes({ conversation_id: conversationId })
      .then((res) => {
        dispatch(updateNotes(conversationId, res.data))
      })
      .catch((err) => {
        console.error(err)
      })
  }, []);

  const onSubmit = (values) => {
    return API.sendMailNote(conversationId, values.note);
  };

  if(!notes) {
    return (
      <div className="notes-form__spinner">
        <Spinner spinnerSize={50} isCentered />
      </div>
    )
  };

  return (
    <div className="popup-wrapper">
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form, valid }) => {
          const handleSubmitWithResetForm = (event) => {
            event.preventDefault();
  
            if (valid) {
              handleSubmit(event).then(form.reset);
            }
          };
  
          return (
            <form
              className='notes-form'
              onSubmit={handleSubmitWithResetForm}
              onMouseDown={onMouseDown}
              ref={innerRef}
              {...props}
            >
              <div className="notes-form__notes-container">
                <List
                  classPrefix="notes-form"
                  list={notes}
                  scrollInitialPosition="bottom"
                  absentLabel="No notes"
                  autoHide
                >
                  {({ item, key }) => (
                    <div key={key} className="notes-form__note-container">
                      <div className="notes-form__note-info">
                        <DateTime
                          className="notes-form__date"
                          date={item.created_at}
                          config={NOTES_DATE_CONFIG}
                        />
  
                        <address className="notes-form__author">
                          by {item.created_by.username}
                        </address>
                      </div>
  
                      <div className="notes-form__note">
                        {item.body}
                      </div>
                    </div>
                  )}
                </List>
              </div>
  
              <div className="notes-form__textarea-container">
                <label htmlFor="note">Add Comment</label>
  
                <Field
                  name="note"
                  className="notes-form__textarea"
                  inputClassName="notes-form__textarea-input"
                  component={FormTextTextarea}
                  theme="light-background"
                />
  
                <div className="notes-form__button-container">
                  <button
                    className="notes-form__cancel-btn"
                    type="button"
                    onClick={() => toggleIsNotesPopupOpened(false)}
                  >
                    Cancel
                  </button>
  
                  <button
                    className="notes-form__send-btn" 
                    type="submit"
                  >
                    Add Comment
                  </button>
                </div>
              </div>
            </form>
          )
        }}
      />
    </div>
  );
}

export default NotesForm;
