import { useSelector } from 'react-redux';
import { Scrollbars } from "react-custom-scrollbars-2";

import { selectSessionsWithRequests } from 'redux/selectors/selectors';

import './SessionsRequests.scss';
import SessionRequest from './SessionRequest';
import CustomScrollbarThumb from "components/UI/CustomScrollbarThumb/CustomScrollbarThumb";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";

const SessionsRequests = () => {
  const sessions = useSelector(selectSessionsWithRequests);

  if (!sessions.length) {
    return null;
  }

  return (
    <div className="sales-sessions-requests">
      <div className="sales-sessions-requests__list-wrap">
        <Scrollbars
          // autoHide
          autoHeight
          renderThumbVertical={CustomScrollbarThumb}
          renderTrackVertical={CustomScrollbarTrack}
        >
          <div className="sales-sessions-requests__list">
            {sessions.map((session) =>  <SessionRequest session={session} />)}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
};

export default SessionsRequests;