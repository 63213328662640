import React, { useEffect, useRef, useState } from 'react';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './DuoBookingForm.scss';
import getCurrentSelectedRate from 'utils/getBookingSelectedRate';

const isFormEdited = (values, initialValues) => {
  const isNotEqualObjects = (initialValues, values, fieldName) => {
    if (values?.length !== initialValues?.length) {
      return true;
    }
    
    switch (fieldName) {
      case 'services': {
        return values?.some((obj, idx) => (
          obj.name !== initialValues[idx].name || 
          obj.price !== initialValues[idx].price || 
          obj.checked !== initialValues[idx].checked
        ));
      }

      case 'taxi':
      case 'discount':
      case 'rate': {
        return (
          values.label !== initialValues.label ||
          values.value !== initialValues.value
        );
      }
  
      default: return false;
    }
  }

  return (
    values.date !== initialValues.date ||
    values.type !== initialValues?.profile?.type ||
    values?.escort?.id !== initialValues?.escort?.id ||
    isNotEqualObjects(initialValues?.rate, values?.rate, 'rate') ||
    isNotEqualObjects(initialValues?.taxi, values?.taxi, 'taxi') ||
    isNotEqualObjects(initialValues?.discount, values?.discount, 'discount') ||
    isNotEqualObjects(initialValues.services, values.services, 'services')
  );
};

const DuoBookingForm = props => {
  const { 
    values,
    isOverlaps,
    formChange,
    tempProfile,
    updateBooking,
    setDuoFieldOpen,
    resetTempProfile,
   } = props;

   const escort = tempProfile || values.escort;

  const [bookingEditProps, setBookingEditProps] = useState({
    date: values.date,
    profile: {
      type: values.type,
      taxi: values.taxi,
      rate: values.rate?.value ? values.rate : null,
      discount: values.discount,
      services: values.services,
      bookingDuration: values.bookingDuration,
      taxiNote: values.taxiNote,
    },
    escort: escort 
      ? {
          type: escort.type,
          taxi: escort.taxi,
          rate: escort.rate,
          discount: escort.discount,
          services: escort.services,
          taxiNote: escort.taxiNote,
          bookingDuration: escort.bookingDuration,
        }
      : null
  });

  const duoFormRef = useRef();

  const bookingRateUpdate = (bookingDuration, type, rate, currProfile) => {
    if (!currProfile) return ''
    const rateByType = currProfile && getCurrentSelectedRate(type, bookingDuration, currProfile)
      .filter(item => item.type === (rate?.type || 'D'));

    const updatedRate = !!rateByType.length ? rateByType[0] : '';

    return updatedRate;
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (duoFormRef.current && !duoFormRef.current.contains(event.target)) {
        handleCancelEditingBooking();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [duoFormRef]);

  const isDisabledForSet = !bookingEditProps.date || !bookingEditProps.profile.type || !bookingEditProps.profile.rate || 
    !bookingEditProps.profile.bookingDuration || (bookingEditProps.escort && (!bookingEditProps.escort.rate || !bookingEditProps.escort.type
    || !bookingEditProps.escort.bookingDuration));

  const getTotalRate = () => {
    let duoTotalRate = 0;

    const getServicesRate = (values) => {
      return values.reduce((acc, item) => item.checked ? acc + item.price : acc + 0, 0);
    };

    if(bookingEditProps.escort) {
      duoTotalRate = getServicesRate(bookingEditProps.escort?.services) + 
        (+bookingEditProps.escort.rate?.value || 0) - 
        (+bookingEditProps.escort.discount?.value || 0) +
        (+bookingEditProps.escort.taxi?.value || 0);
    }

    return bookingEditProps.profile.rate?.value 
      ? getServicesRate(bookingEditProps.profile.services) + 
            bookingEditProps.profile.rate?.value -
            (+bookingEditProps.profile.discount?.value || 0) +
            (+bookingEditProps.profile.taxi?.value || 0) + 
            duoTotalRate
      : '-'
  }
  
  const isNumeric = value => (/^[\d]+$/g).test(value);
  const isValidDiscountTaxi = value => {
    if (!value) return true;
    return isNumeric(value) && value >= 0 && getTotalRate() >= 0;
  };

  const children = props.children.length ? props.children.filter(Boolean) : [props.children];

  const renderChildren = () =>  React.Children.map(children, child => {
    return React.cloneElement(child, {
      bookingEditProps,  
      bookingRateUpdate,
      setBookingEditProps,
    });
  });

  const handleCancelEditingBooking = () => {
    if(values.isInitDuoProfile) {
      formChange('isInitDuoProfile', false);

      if(values.meetingType === 'duo') {
      } else {
        formChange('meetingType', 'solo');
        resetTempProfile && resetTempProfile();
      }
    }

    if(values.tempInitSoloProfile) {
      formChange('profile', values.tempInitSoloProfile);
      formChange('tempInitSoloProfile', undefined);
    }

    setDuoFieldOpen(false);
  }

  const updatedBookingData = {
    ...values,
    profileId: values.profile?.id,
    date: bookingEditProps.date,
    type: bookingEditProps.profile?.type,
    taxi: bookingEditProps.profile?.taxi,
    rate: bookingEditProps.profile?.rate,
    discount: bookingEditProps.profile?.discount,
    taxiNote: bookingEditProps.profile?.taxiNote,
    bookingDuration: bookingEditProps.profile?.bookingDuration,
    services: bookingEditProps.profile?.services,
    escort: bookingEditProps.escort && escort
      ? { 
          ...escort,
          id: escort.id,
          type: bookingEditProps.escort.type,
          taxi: bookingEditProps.escort.taxi,
          rate: bookingEditProps.escort.rate,
          services: bookingEditProps.escort.services,
          discount: bookingEditProps.escort.discount,
          taxiNote: bookingEditProps.escort.taxiNote,
          bookingDuration: bookingEditProps.escort.bookingDuration,
        }
      : null
  }

  const isDisabled = isDisabledForSet || isOverlaps || !isFormEdited(values, updatedBookingData) || 
    !isValidDiscountTaxi(bookingEditProps.profile.discount?.value) ||
    !isValidDiscountTaxi(bookingEditProps.profile.taxi?.value) || 
    !isValidDiscountTaxi(bookingEditProps.escort?.discount?.value) ||
    !isValidDiscountTaxi(bookingEditProps.escort?.taxi?.value)

  const handleSetData = () => {
    formChange('date', bookingEditProps.date);
    formChange('type', bookingEditProps.profile.type);
    formChange('rate', bookingEditProps.profile.rate);
    formChange('taxi', bookingEditProps.profile.taxi);
    formChange('taxiNote', bookingEditProps.profile.taxiNote);
    formChange('services', bookingEditProps.profile.services);
    formChange('discount', bookingEditProps.profile.discount);
    formChange('bookingDuration', bookingEditProps.profile.bookingDuration);
    formChange('isInitDuoProfile', false);

    if(escort) {
      formChange('escort', {
        ...escort,
        taxi: bookingEditProps.escort.taxi,
        type: bookingEditProps.escort.type,
        rate: bookingEditProps.escort.rate,
        discount: bookingEditProps.escort.discount,
        services: bookingEditProps.escort.services,
        taxiNote: bookingEditProps.escort.taxiNote,
        bookingDuration: bookingEditProps.escort.bookingDuration,
      });
    };

    if(values.tempInitSoloProfile) {
      formChange('tempInitSoloProfile', undefined);
    }

    if(updateBooking) {
      isFormEdited(values, updatedBookingData) && updateBooking({ values: updatedBookingData });
    } else {
      setDuoFieldOpen(false);
    }
  };

  return (<>
    <div ref={duoFormRef}>
      <div className="duo-booking-form__main">
        <div className="duo-booking-form__header">
          
          <div className="duo-booking-form__header-info">
            {bookingEditProps.escort && <h2 className='duo-booking-form__title'>DUO</h2>}
            <span className="duo-booking-form__total">
              Total: 
              <span className='booking-form__booking-total-rate'>
                £{getTotalRate()}
              </span>
            </span>
          </div>

          <div className='duo-booking-form__btns-wrapper'>
            <button 
              type='button' 
              onClick={handleCancelEditingBooking}
              className={classModifier('booking-special-request__btn', 'cancel')}
            >
              Cancel
            </button>
            <button 
              type='button' 
              onClick={handleSetData}
              className={classModifier('booking-special-request__btn', 'save')}
              disabled={isDisabled}
            >
              Save
              <ICONS.check className={classModifier('booking-special-request__btn-icon', 'check')}/>
            </button>
          </div>
        </div>

        <div className='duo-booking-form__fields'>
          {renderChildren()}
        </div>
      </div>
    </div>
  </>
  )
};

export default DuoBookingForm;