import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { CALENDAR_WEEK_DAY_CONFIG } from 'config/dates-сonfig';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { useDidUpdate } from 'hooks';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import { NO_BOOKINGS_LABELS } from 'components/FilteredBookings';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';

import './CalendarWeekViewList.scss';
import DateTime from 'components/DateTime';
import ListContainer from 'components/List/ListContainer';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import { BookingItemNew } from 'components/BookingItemNew';


const bookingItemGap = 18;
const bookingItemHiddenSize = 29 + bookingItemGap;
const bookingItemRevealedSize = 132 + bookingItemGap;


const BookingListItem = ({
  item,
  index,
  getCallbackRef,
  useExtraBookingState,
  setBookingItemSizes,
  resetSize,
  ...props
}) => {
  const isAllRevealed = useSelector((state) => state.bookings.revealed);
  const [isRevealed, dispatchRevealed] = useExtraBookingState(item.id, false, 'revealed');
  const [_, dispatchSize] = useExtraBookingState(index, bookingItemHiddenSize, 'size');

  const changeSize = (isRevealed) => {
    const newSize = isRevealed ? bookingItemRevealedSize : bookingItemHiddenSize;

    dispatchSize(newSize);
    resetSize?.();
  }

  const handleClick = (isRevealed) => {
    dispatchRevealed(isRevealed);
    changeSize(isRevealed);
  }

  useDidUpdate(() => {
    changeSize(isAllRevealed);

    if (!isAllRevealed) {
      dispatchRevealed(false);
    }
  }, [isAllRevealed])

  return (
    <BookingItemNew
      item={item}
      type={BOOKING_ITEM_VIEWS.WEEK}
      getCallbackRef={getCallbackRef}
      onOuterClick={() => handleClick(false)}
      onInnerClick={() => handleClick(true)}
      isHidden={!(isAllRevealed || isRevealed)}
      {...props}
    />
  )
}

const CalendarWeekViewListItem = ({
  idx,
  date,
  getters,
  actualDate,
  getExtraBookingsState,
}) => {
  const {
    getBookingListProps,
    getBookingProps,
    getBookingsCount,
  } = getters;

  const {
    currentCount = 0,
    totalCount = 0,
  } = getBookingsCount({ date });

  const dispatch = useDispatch();

  const passedDate = moment(actualDate).add(idx, 'days').valueOf()

  const createNewBooking = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedifinedByItem: true,
      commonProps: { date: passedDate, ignoreSessionContact: true },
    }))
  }

  const getItemSize = (index) => {
    return getExtraBookingsState(date, index, 'size') || bookingItemHiddenSize;
  }

  return (
    <div className="calendar-week-list__dateline">
      <header className="calendar-week-list__header">
        <DateTime
          className={classModifier("calendar-week-list__date", idx === 0 && 'first')}
          date={date}
          config={CALENDAR_WEEK_DAY_CONFIG}
          ignoreTimezone
        />

        <div className="calendar-week-list__counts-control">
          {`${currentCount} / ${totalCount}`}

          <button
            className={classModifier("calendar-week-list__new-booking", idx === 0 && 'first')}
            onClick={createNewBooking}
          >
            <ICONS.plusThick />
          </button>
        </div>
      </header>

      <ListContainer
        classPrefix='calendar-week-list'
        autoHide
        itemSize={getItemSize}
        // virtualized
        {...getBookingListProps({
          date,
          absentLabel: NO_BOOKINGS_LABELS.NO_LABEL
        })}
      >
        <BookingListItem {...getBookingProps({ date })} />
      </ListContainer>
    </div>
  )
};

const CalendarWeekViewList = ({
  dates,
  changeDateToPrev,
  changeDateToNext,
  ...props
}) => (
  <div className='calendar-week__content-wrapper calendar-week-list'>
    <button
      className="calendar-week-list__date-prev"
      onClick={changeDateToPrev}
    >
      <ICONS.angleQuote />
    </button>

    <button
      className="calendar-week-list__date-next"
      onClick={changeDateToNext}
    >
      <ICONS.angleQuote />
    </button>

    {
      dates.map((date, idx) => {
        return (
          <CalendarWeekViewListItem
            idx={idx}
            key={date}
            date={date}
            {...props}
          />
        )
      })
    }
  </div>
)

export default CalendarWeekViewList;
