import ClientLocationInput from '../ControlElements/ClientLocationInput'
import ClientRadiusSelect from '../ControlElements/ClientRadusSelect'
import FindEscortButton from '../ControlElements/FindEscortButton'
import GirlsMapModalFilters from '../ControlElements/GirlsMapModalFilters'
import SelectEscortsButton from '../ControlElements/SelectEscortsButton'
import SendEscortsButton from '../ControlElements/SendEscortsButton'
import ShowAvailableButton from '../ControlElements/ShowAvailableButton'
import ShowChatEscortsButton from '../ControlElements/ShowChatEscortsButton'
import ShowSessionEscortsButton from '../ControlElements/ShowSessionEscortsButton'


const AllEscortsControls = {};

AllEscortsControls.Header = () => (
  <div className="girls-map__header">
    <GirlsMapModalFilters />

    <div className="girls-map__buttons-wrap">
      <FindEscortButton />
      <ShowSessionEscortsButton />
      <ShowChatEscortsButton />
      <ShowAvailableButton />
    </div>
  </div>
)

AllEscortsControls.Footer = () => (
  <div className="girls-map__footer">
    <div className="girls-map__footer-side">
      <ClientLocationInput />
      <ClientRadiusSelect />
    </div>

    <div className="girls-map__footer-side">
      <SelectEscortsButton />
      <SendEscortsButton />
    </div>
  </div>
)

export default AllEscortsControls;
