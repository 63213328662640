import React, { Fragment } from 'react';

import { classModifier } from 'utils';
import { IMAGE_FORMAT_REGEX } from 'config/constants';

import './Carousel.scss'
import GalleryPhoto from 'containers/Dialogs/components/PhotoGallery/components/GalleryPhoto';
import GalleryVideo from 'containers/Dialogs/components/PhotoGallery/components/GalleryVideo';

const galleryItem = {
  photo: GalleryPhoto,
  video: GalleryVideo,
}

const Carousel = (props) => {
  const {
    media,
    classNamePrefix,
    spinnerSize = 50,
    activeSlide,
    isNotProfile,
  } = props;

  return (
    <Fragment>
      {media.map((item, i) => {
        const src = item.image_src || item.image_name || item.url || item.link;
        const type = IMAGE_FORMAT_REGEX.test(item.type || src) ? 'photo' : 'video';
        const GalleryItem = galleryItem[type];

        return (
          <div
            key={i}
            className={classModifier(`${classNamePrefix}__image-inner`, [i === activeSlide && 'active'])}
            style={{
              opacity: i === activeSlide ? '1' : '0',
              width: item.is_video ? 500 : 'inherit',
              background: isNotProfile && type === 'video' ? 'black' : 'transparent',
            }}
          >
            <GalleryItem
              item={src}
              classPrefix={classNamePrefix}
              spinnerSize={spinnerSize}
              autoFocus={true}
              isActive={i === activeSlide}
            />
          </div>
      )})}
    </Fragment>
  );
};

export default Carousel;
