const getDivaInfoItem = (item, dimension) => {
  if (!item) {
    return '—';
  }

  if (dimension) {
    if (dimension === '£') {
      return dimension + " " + item;
    }

    return item + " " + dimension;
  }

  return item;
}

export const getDivaInfo = (girl, actualRate) => {
  const price30min = girl?.prices?.find((price) => price.name === '30 minutes');
  const price1hour = girl?.prices?.find((price) => price.name === '1 hour');
  const isRateExist = price30min?.incall && price30min?.outcall && price1hour?.outcall;
  const agents = girl?.agents?.map(agent => agent instanceof Object ? agent.name : agent) || [];

  return `
    ${getDivaInfoItem(actualRate, '£')} /
     ${getDivaInfoItem(girl?.age, 'years')} /
     ${getDivaInfoItem(girl?.height, 'cm')} /
    ${getDivaInfoItem(girl?.breast_size)} /
     ${getDivaInfoItem(girl?.dress, 'size')} /
    ${getDivaInfoItem(girl?.nationality)} /
     ${getDivaInfoItem(agents.join(', '))}
  `
}
