import React, { memo } from 'react';
import moment from 'moment';

import { useDidMount } from 'hooks';

import './CalendarTwoDays.scss';
import CalendarTimeline from '../CalendarTimeline/CalendarTimeline';

const CalendarTwoDays = (props = {}) => {
  const { date, actualDate, resetFilters } = props;

  useDidMount(resetFilters);

  return (
    <div className="calendar-two-days">
      <CalendarTimeline
        {...props}
        key={date[0]}
        isTwoDaysPage
        classNameModifier='calendar-two-days'
        date={date[0]}
        dateForPassing={actualDate}
        idx={0}
      />

      <CalendarTimeline
        {...props}
        key={date[1]}
        isTwoDaysPage
        classNameModifier='calendar-two-days'
        date={date[1]}
        dateForPassing={moment(actualDate).add(1, 'days').valueOf()}
        idx={1}
      />
    </div>
  );
};

export default memo(CalendarTwoDays);
