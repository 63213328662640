import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { Form, Field } from 'react-final-form';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'config/constants';

import {
  LIST_TYPES,
  deleteMailsToTrash,
  deleteMailsPermanently,
  deleteMessageDraftRequest,
  setMessageDraft,
  DRAFT_STATUSES,
} from 'redux/ducks/mail';
import {
  classModifier,
  getContactAvatar,
  validateFormValues,
  throttle,
} from 'utils';
import { selectMailByUsername, selectUserTimezone, selectDefaultSession } from 'redux/selectors/selectors';
import { useDidMount } from 'hooks';
import ICONS from 'assets/icons';
import { MAIL_REPLAIED_DATE_CONFIG } from 'config/dates-сonfig';
import { updateActiveAdrBookContactId } from 'redux/ducks/addressBook';
import { createSession } from 'redux/ducks/sessions';
import { getContactsById } from 'redux/ducks/contacts';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { addContactsToEntities } from 'redux/ducks/contacts';

import './MailChatItem.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';
import MailFormAutosave from '../../MailFormAutosave/MailFormAutosave';
import EmojiPickerBtn from 'containers/Chat/components/ChatMessageInput/components/EmojiPickerBtn/EmojiPickerBtn';
import useSelectorToggle from 'hooks/useSelectorToggle';
import { CHAT_ACTION_MENUS, SEND_DELAY_MSG_REPLY } from 'config/constants';
import API from 'api/api';
import MailAttachmentFiles from '../../MailAttachmentsFiles/MailAttachmentsFiles';
import VoiceMessageRecorder from 'containers/Chat/components/ChatMessageInput/components/VoiceMessageRecorder';
import TypingIndicator from 'components/UI/TypingIndicator/TypingIndicator';
import TypingOperators from 'components/UI/TypingIndicator/TypingOperators';
import DateTime from 'components/DateTime';
import InfoMenu from 'components/InfoMenu/InfoMenu';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

const MailChatItem = ({ item, isLastItem }) => {
  const isHour12 = useSelector((state) => state.user.hour12);
  const mail = useSelector((state) => selectMailByUsername(state, item.to));
  const userTimezone = useSelector(selectUserTimezone);
  const typingUsers = useSelector((state) => state.typingOperators.mails[item.conversation_id]);
  const userId = useSelector(state => state.user.id);
  const activeSession = useSelector(state => state.sessions.activeSession);
  const defaultSession = useSelector(selectDefaultSession);
  const isAdrBookPageOpen = useSelector(state => state.user.isAdrBookPageOpen);

  const dispatch = useDispatch();

  const initPending = {
    delMsg: false,
    sendReply: false,
    delDraft: false,
  };
  const [pending, setPending] = useState(initPending);
  const [sanitizedHtml, setSanitizedHtml] = useState('');
  const [isReply, setIsReply] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [status, setStatus] = useState(null);
  const [noMicro, setNoMicro] = useState(false);
  const [attachments, setAttachments] = useState(item.draft?.attachments || []);
  const [actionMenu, setActionMenu] = useSelectorToggle(null);
  const navigate = useNavigate();

  useDidMount(() => {
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });

    setSanitizedHtml(DOMPurify.sanitize(item.html));
    if (item.draft) {
      setIsReply(true);
    }
  });

  useLayoutEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      // check audio stream and stop it
      .then((stream) =>
        console.log(stream.getTracks().forEach((track) => track.stop()))
      )
      // If user haven't microphone
      .catch(() => setNoMicro(true));
  }, []);

  const throttledSendTypingPing = useCallback(
    throttle(
      () => API.sendTypeInMail(item.conversation_id, mail?.id),
      3000,
      true
    ), [item.conversation_id, mail?.id]);

  const somePending = Object.values(pending).some((value) => value);
  const globalPending = pending.delMsg || pending.sendReply || pending.delDraft;

  const itemFolder = item.folder_type?.toLowerCase();
  const isSent = itemFolder === LIST_TYPES.sent;
  const isTrash = itemFolder === LIST_TYPES.trash;
  const isSpam = itemFolder === LIST_TYPES.spam;
  const isOutgoing = item.user?.id || isSent;

  const senderName = isOutgoing
    ? item.user?.username || "Fastmail"
    : item.caller?.fn || item.from;

  const draftId = item.draft?.id;

  const handleDelete = async (e) => {
    e.stopPropagation();

    const isPermanently = isTrash || isSpam;

    if (!confirmDelete(isPermanently)) {
      return null;
    }

    setPending(prev => ({ ...prev, delMsg: true }));

    if (isPermanently) {
      dispatch(deleteMailsPermanently({ msgId: item.id }));
    } else {
      dispatch(deleteMailsToTrash({ msgId: item.id }));
    }

    setPending(prev => ({ ...prev, delMsg: false }));
  };

  const confirmDelete = (isPermanently) => {
    const text = isPermanently
      ? 'Just to confirm: selected message will be deleted permanently.'
      : 'Just to confirm: selected message will be moved to Trash.';

    return window.confirm(text);
  };

  const handleSaveDraft = ({ values, isWindowUnload }) => {
    // Returns Boolean in case of window unload
    if (isWindowUnload) {
      return sendEmailReply({ values, isWindowUnload: true });
    }

    // By default returns Promise
    return sendEmailReply({ values })
      .then(({ data: { isForward, to, body, id } }) => {
        dispatch(
          setMessageDraft({
            msgId: item.id,
            draft: { isForward, to, body, id },
          })
        );
      }
      );
  };

  const handleDeleteDraft = () => {
    if (draftId) {
      setPending(prev => ({ ...prev, delDraft: true }));
      dispatch(deleteMessageDraftRequest(draftId))
        .then(() => {
          setIsReply(false);
          setStatus(null);
        })
        .catch(console.error)
        .finally(() => {
          setPending(prev => ({ ...prev, delDraft: false }));
        })
    }
    setIsReply(false);
  };

  const handleReplyBtnClick = (e) => {
    e.stopPropagation();

    setIsReply(true);
    setStatus(DRAFT_STATUSES.saved);
  };

  const handleSubmitReplyClick = (values) => {
    setPending(prev => ({ ...prev, sendReply: true }));

    sendEmailReply({ values, delay: SEND_DELAY_MSG_REPLY })
      .then(({ data }) => {
        setIsReply(false);
        setStatus(null);
        dispatch(deleteMessageDraftRequest(draftId));
        setDelayedMessage({ ...data, delay: SEND_DELAY_MSG_REPLY });
      })
      .catch(err => {
        setServerErrors({ reply: err.response?.data.message });
      })
      .finally(() => {
        setPending(prev => ({ ...prev, sendReply: false }));
      });
  };

  const sendEmailReply = ({ values, delay, isWindowUnload }) => {
    const { isForward, email = '', reply = '' } = values;

    // Generate draft id on front-end
    const newDraftId = draftId || uuid();
    if (newDraftId !== draftId && !isWindowUnload) {
      dispatch(
        setMessageDraft({
          msgId: item.id,
          draft: { id: newDraftId },
        })
      );
    }

    return API.sendEmailReply({
      config: {
        from: item.to,
        replyOnId: item.id,
        isForward,
        recipient: email,
        body: reply,
        isDraft: true,
        draftId: newDraftId,
        delay,
        userId,
      },
      isBeacon: isWindowUnload,
    });
  };

  const handleAddAttachments = (e) => {
    const uploadFiles = [...e.target.files];

    if (!uploadFiles.length) return;

    e.target.value = null;

    setAttachments((state) => [
      ...state,
      ...uploadFiles.map(({ name, size }) => ({
        fileName: name,
        fileSize: size,
        loading: true,
      })),
    ]);
    setPending((prev) => ({ ...prev, attach: true }));

    // Generate draft id on front-end
    const newDraftId = draftId || uuid();

    API.uploadMailAttachments({ uploadFiles, draftId: newDraftId })
      .then(({ data: { attachments } }) => {
        setAttachments(attachments.map((att) => ({ ...att, loading: false })));
      })
      .catch((err) => {
        setAttachments((prev) => prev.filter(({ loading }) => !loading));
      })
      .finally(() => {
        setPending((prev) => ({ ...prev, attach: false }));
      });
  };

  const handleDeleteAttachment = (delId) => {
    setPending((prev) => ({ ...prev, attach: true }));

    API.deleteMailAttachment({ delId })
      .then(({ data: { attachments } }) => {
        setAttachments(attachments.map((att) => ({ ...att, loading: false })));
      })
      .finally(() => {
        setPending((prev) => ({ ...prev, attach: false }));
      });
  };

  const updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {                 // add voiceMsg
      setAttachments((state) => [
        ...state,
        {
          blob,
          url: URL.createObjectURL(blob),
          duration
        },
      ]);
    }
  }

  const menuActions = [
    {icon: <ICONS.adrBook />, title: "Open in addressbook ", action: () => {
        if (!item.caller) {
          return null
        }

        API.switchAdrBookCaller(item.caller.id);

        dispatch(addContactsToEntities([item.caller]));
        dispatch(updateActiveAdrBookContactId(item.caller.id));

        if (!isAdrBookPageOpen) {
          window.open(ROUTES.adrBook, '_blank');
        }
      }
    },
    {icon: <ICONS.chatMsg />, title: "Open chat", action: () => {
      if (!item.caller) {
        return null
      }
      
      dispatch(getContactsById(item.caller.id))
        .then((contact) => {
          if (contact) {
            dispatch(updateActiveContact(contact))
          }
        })

      navigate(ROUTES.main)}
    },   
    {icon: <ICONS.puzzle />, title: "Start session", action: () => {
      if (!item.caller) {
        return null
      }

      // Get new session
      if (activeSession === 0) {
        dispatch(createSession(
          item.caller.id,
          defaultSession.bufferedIds,
          defaultSession.comparedIds,
          defaultSession.activeFilters,
          defaultSession.additionalFilters,
          defaultSession.recentlyViewedIds,
        ))
      }
      // If the caller already had a session
      else {
        dispatch(createSession(item.caller.id));
      }

      navigate(ROUTES.sales)}
    }, 
    // for future template 2/5 actions menu
    // {icon: <ICONS.phoneSquare />, action: () => {}}, 
    // {icon: <ICONS.handPaper />, action: () => {}},
  ]

  const handleOpen = (index) => {
    const modalProps = {
      media: item.attachments,
      defaultActiveSlide: index
    }

    dispatch(openModal(MODAL_TYPES.profileCarousel, modalProps));
  }

  return (
    <div
      className={classModifier("mail-chat-item", [
        isLastItem && "last",
        somePending && "some-pending",
        globalPending && "global-pending",
        !isOutgoing && "inbox",
      ])}
    >
      <div className="mail-chat-item__main">
        <div className="mail-chat-item__header">
          <div className="mail-chat-item__avatar-wrap">
            <div className="mail-chat-item__avatar">
              <LazyLoadImage
                src={getContactAvatar(isOutgoing ? item.user : item.caller)}
              />
            </div>
          </div>
          <div className="mail-chat-item__header-content">
            <div className="mail-chat-item__header-top">
              <div className='mail-chat-item__info'>
                <div className="mail-chat-item__name-box">
                  <div className="mail-chat-item__name" title={senderName}>
                    {senderName}
                  </div>

                  <div className="mail-chat-item__email">from: {item.from}</div>
                  <div className="mail-chat-item__email">to: {item.to}</div>
                </div>
                
                {!isOutgoing && <div className="mail-chat-item__info-icon">
                  <InfoMenu
                    title={senderName}
                    iconSize={14}
                    menuItems={menuActions}
                    icon={ICONS.infoNew}
                    dropWrapperProps={{
                      placement: 'top',
                    }}
                    />
                </div>}
              </div>

              <div className="mail-chat-item__actions">
                <button
                  className="mail-chat-item__action-btn"
                  title={
                    isTrash || isSpam
                      ? "Delete Permanently"
                      : "Delete (to Trash)"
                  }
                  disabled={somePending}
                  onClick={handleDelete}
                >
                  {pending.delMsg ? <Spinner /> : <ICONS.trash />}
                </button>

                <button
                  className="mail-chat-item__action-btn"
                  title="Reply"
                  disabled={isReply || somePending}
                  onClick={handleReplyBtnClick}
                >
                  <ICONS.reply />
                </button>
              </div>
            </div>

            <DateTime
              className="mail-chat-item__date"
              date={item.created_at}
              config={MAIL_REPLAIED_DATE_CONFIG}
            />
          </div>
        </div>

        <div className="mail-chat-item__message">
          <div
            className="mail-chat-item__message-body"
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
          />
          <ul className="mail-chat-item__attachments-list">
            {!!item.attachments.length &&
              item.attachments.map(
                ({ id, fileName, loading, url, extension }, idx) => (
                  <MailAttachmentFiles
                    key={idx}
                    fileName={fileName}
                    loading={loading}
                    id={id}
                    url={url}
                    fileExtension={extension}
                    onClick={() => handleOpen(idx)}
                  />
                )
              )}
          </ul>
        </div>
        {isReply && (
          <Form
            initialValues={{
              isForward: item.draft?.isForward,
              reply: item.draft?.body || "",
            }}
            initialValuesEqual={() => true}
            validate={validate}
            onSubmit={handleSubmitReplyClick}
            mutators={{
              handleChangeMessage: (...rest) => {
                const [args, state, utils] = rest;

                utils.changeValue(state, "reply", () => args[0].target.value);
              },

              handleAddEmoji: (...rest) => {
                const [args, state, utils] = rest;

                const sym = args[0].unified.split("-");
                const codesArray = [];

                sym.forEach((el) => codesArray.push("0x" + el));
                const emojiPic = String.fromCodePoint(...codesArray);

                utils.changeValue(state, "reply", (ve) => {
                  return ve + emojiPic
                });
              },
            }}
          >
            {({ handleSubmit, form }) => (
              <form
                className="mail-chat-item-reply__form"
                onSubmit={handleSubmit}
              >
                <div className="mail-chat-item-reply">
                  <div className="mail-chat-item-reply__header">
                    <div className="mail-chat-item-reply__reply-icon-wrap">
                      <ICONS.reply />
                    </div>
                    <div className="mail-chat-item__avatar-wrap">
                      <div className="mail-chat-item__avatar">
                        <LazyLoadImage
                          src={getContactAvatar(
                            isOutgoing ? item.user : item.caller
                          )}
                        />
                      </div>
                    </div>
                    <div className="mail-chat-item__name-box">
                      <div className="mail-chat-item__name" title={senderName}>
                        {senderName}
                      </div>

                      <div className="mail-chat-item__email">from: {item.from}</div>
                      <div className="mail-chat-item__email">to: {item.to}</div>
                    </div>
                  </div>
                  {/* {!!attachments.length && (
                    <ul className="mail-chat-item-reply__attachment-list">
                      {attachments.map(
                        ({ id, fileName, loading, extension, url }, idx) => (
                          <MailAttachment
                            url={url}
                            key={idx}
                            fileName={fileName}
                            fileExtension={extension}
                            loading={loading}
                            onDelete={() => handleDeleteAttachment(id)}
                            disableDelete={somePending}
                          />
                        )
                      )}
                    </ul>
                  )} */}

                  <div className="mail-chat-item-reply__textarea-wrap">
                    <Field
                      name="reply"
                      errorClass="input-error-text"
                      className="mail-chat-item-reply__textarea"
                      autosize
                      serverErrors={serverErrors}
                      component={FormTextTextarea}
                      onAdditionalChange={throttledSendTypingPing}
                      autoFocus
                      onChange={form.mutators.handleChangeMessage}
                    />

                    {actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER && (
                      <VoiceMessageRecorder
                        updateVoiceMsg={updateVoiceMsg}
                        activeRecipient={item.caller}
                        // chatType={props.type}
                        // disabled={!!images?.length || !!videos?.length || !!voiceMsg}
                        // handleSubmit={handleSubmit}
                        handleSubmit={(e) => {
                          e?.preventDefault();
                        }}
                        setActive={() =>
                          setActionMenu(CHAT_ACTION_MENUS.VOICE_RECORDER)
                        }
                      />
                    )}
                  </div>

                  <div className="mail-chat-item-reply__actions">
                    <div className="mail-chat-item-reply__actions-menu">
                      {/* <button
                        onClick={(e) => {
                          e?.preventDefault();
                          !noMicro &&
                          setActionMenu(CHAT_ACTION_MENUS.VOICE_RECORDER)
                        }}
                        className="chat-input__btn"
                        title="voice recorder"
                      >
                        <ICONS.microphone
                          className={classModifier('chat-input__btn-icon', [
                            actionMenu === CHAT_ACTION_MENUS.VOICE_RECORDER &&
                              'selected',
                          ])}
                        />
                      </button> */}

                      {/* <label className="chat-input__btn" title="attach file">
                        <ICONS.clip className="chat-input__btn-icon" />

                        <input
                          className="visually-hidden"
                          type="file"
                          multiple
                          onChange={handleAddAttachments}
                          disabled={somePending}
                        />
                      </label> */}

                      <label title="emoji">
                        <EmojiPickerBtn
                          name="emoji"
                          onSelect={(emoji) => form.mutators.handleAddEmoji(emoji)}
                          isShow={actionMenu === CHAT_ACTION_MENUS.SMILES}
                          onClick={(e) => {
                            e?.preventDefault();
                            setActionMenu(CHAT_ACTION_MENUS.SMILES)
                          }}
                        />
                      </label>

                      {/* <MessageTemplates
                            msgTemplates={""}
                            addBoilerplate={()=>{}}
                            isShow={false}
                            isShow={actionMenu === CHAT_ACTION_MENUS.TEMPLATES}
                            onClick={() => setActionMenu(CHAT_ACTION_MENUS.TEMPLATES)}
                          /> */}
                    </div>

                    {typingUsers &&
                      <div className='chat-input__indicator-wrap'>
                        <TypingOperators operatorsIds={typingUsers} />
                        <TypingIndicator />
                      </div>
                    }
                  </div>
                </div>
                <div className="mail-chat-item__actions">
                  <button
                    className="mail-chat-item-reply__send-btn"
                    type="submit"
                    disabled={somePending}
                  >
                    SEND
                    <ICONS.arrow className="mail-chat-item-reply__send-btn-icon" />
                  </button>

                  <button
                    className="mail-chat-item__action-btn"
                    type="button"
                    title="Delete Draft"
                    disabled={somePending}
                    onClick={handleDeleteDraft}
                  >
                    <ICONS.trash />
                  </button>
                </div>
                <MailFormAutosave
                  isHide
                  status={status}
                  onSave={handleSaveDraft}
                  setStatus={setStatus}
                  disabled={pending.sendReply || pending.delDraft}
                />
              </form>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};

const validate = ({ reply }) => {
  const errors = {};

  if (validateFormValues.isEmpty(reply)) {
    errors.reply = 'Enter the message';
  }

  return errors;
};

export default React.memo(MailChatItem);
