import DateTime from 'components/DateTime';

import "./BookingDate.scss";

const bookingDurationInMinutes = {
  '30 minutes': 30,
  '45 minutes': 45,
  '1 hour': 60,
  '90 minutes': 90,
  '2 hours': 120,
  '3 hours': 180,
  '12 hours': 720,
}

const BookingDate = ({ isRanged, booking, ...props }) => {
  const { date } = booking;
  const durationInMinutes = bookingDurationInMinutes[booking.duration];

  if (isRanged && durationInMinutes) {
    return <DateTime date={date} className="booking-date" range={durationInMinutes} {...props} />;
  } else {
    return <DateTime date={date} className="booking-date" {...props} />
  }
}

export default BookingDate
