export const getServicesRate = (values) => {
  return values?.reduce((acc, item) => item.checked ? acc + item.price : acc + 0, 0);
};

export const getTotalRate = (values) => {
  let duoTotalRate = 0;

  if(values.escort && values.escortRate) {
    duoTotalRate = getServicesRate(values.escort?.services) + 
      (values.escortRate.value || 0) -
      (+values.escort.discount?.value || 0) +
      (+values.escort.taxi?.value || 0)
  }

  return getServicesRate(values.services) + 
    values.rate?.value - 
    (+values.discount?.value || 0) +
    (+values.taxi?.value || 0) +
    duoTotalRate
};

export const getLocalRate = (values) => {
  return getServicesRate(values?.services || []) + 
    (+values?.rate?.value || 0) - 
    (+values?.discount?.value || 0) +
    (+values?.taxi?.value || 0) || 0;
};