const currentHostname = window && window.location && window.location.hostname;
let backendHostname;
if (currentHostname === 'localhost') {
  backendHostname = 'comdiva.local';
}
else if (currentHostname && currentHostname !== 'chromewebdata') {
  backendHostname = currentHostname;
}
else {
  backendHostname = process.env.REACT_APP_BACKEND_HOST || 'unknownhosterror';
}

const PORT = ':5349'
const TURN_SERVER_USERNAME = 'commturn';
const TURN_SERVER_CREDENTIAL = '20commturn20';
export const PC_CONFIG = {
  iceServers: [
    {
      urls: 'turn:turn.' + backendHostname + PORT,
      username: TURN_SERVER_USERNAME,
      credential: TURN_SERVER_CREDENTIAL,
    },
  ]
};