import React, { useContext } from 'react';

import { DIVA_GIRLS_IMG_HOST } from 'redux/ducks/divaGirls';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import { classModifier } from 'utils';
import { PhotoGalleryContext } from '../PhotoGallery.context';

const GalleryPhoto = ({ spinnerSize = 50, ...props }) => {
  const {
    item: photo,
    classPrefix,
    style,
    number,
    onClick,
  } = props;

  const { selectedMode, selectedPhotos, dispatchPhotos } = useContext(PhotoGalleryContext);

  const isSelected = selectedPhotos?.images[number];
  const src = (photo.startsWith('http') || photo.startsWith('blob')) ? photo : DIVA_GIRLS_IMG_HOST + (photo?.name || photo);

  const handleClick = () => {
    if (selectedMode) {
      dispatchPhotos({ type: 'TOGGLE_PHOTO', payload: { number, src } })
    } else {
      onClick && onClick();
    }
  }

  return (
    <LazyLoadImage
      src={src}
      containerClassName={classModifier(isSelected && 'lazy-load__container--selected')}
      className={classModifier(`${classPrefix}__image`, isSelected && 'selected')}
      spinnerSize={spinnerSize}
      onClick={handleClick}
      style={style}
    />
  )
}

export default GalleryPhoto;
