import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { classModifier } from 'utils';

import './BookingTag.scss';

export const BookingTag = ({
  active,
  className,
  statusCount,
  label,
  image,
  color,
  coloredLabel,
  disabled,
  onClick,
  unsetDisable
}) => {
  const [actualStatusCount, setActualStatusCount] = useState(statusCount || 0);

  useEffect(() => {
    if (disabled || statusCount === undefined) return;

    setActualStatusCount(statusCount);
  }, [disabled, statusCount])

  return (
    <button 
      type="button"
      className={classNames(
        'booking-tag',
        classModifier(className, active && 'active'),
      )}
      onClick={!(unsetDisable && active) && onClick}
      disabled={disabled}
    >
      <mark className="booking-tag__count">
        {actualStatusCount > 99 ? '99+' : actualStatusCount}
      </mark>

      <span
        className="booking-tag__label"
        style={coloredLabel ? { color } : {}}
      >
        {label || image}
      </span>
    </button>
  )
}
