import { useState } from 'react';

import { useDidUpdate } from 'hooks';

import SearchInput from 'components/SearchInput/SearchInput';

export const BookingSearchInput = ({ searchQuery, ...props }) => {
  const [query, setQuery] = useState('');

	useDidUpdate(() => {
		if (!searchQuery) {
      setQuery('');
    }
  }, [searchQuery])

  return (
    <SearchInput
    	query={query}
      setQuery={setQuery}
      {...props}
    />
  )
}
