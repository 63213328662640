import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { CHAT_SOURCES } from 'config/constants';
import { TODO_MESSAGE_DATE_CONFIG, NOTIFICATION_DATE_CONFIG } from 'config/dates-сonfig';
import {
  classModifier,
  getContactAvatar,
  parseMsgText,
} from 'utils';
import { REMOVE_GIRL_REMIND_MSG, changeChatSource } from 'redux/ducks/girlChats';
import { selectOperatorByUserId } from 'redux/selectors/selectors';
import { deleteRemindedMsgId, REMOVE_CLIENT_MSG_REMIND } from 'redux/ducks/clientChats';

import './RemindMsg.scss'
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import InteractionAttachments from 'containers/Chat/components/ChatAttachments/InteractionAttachments';
import DateTime from 'components/DateTime';

const RemindMsg = ({ interaction, isMainTimelineOpen, activeRecipient, profileId, remindAt, interactionId, ...props }) => {
  const operator = useSelector(state => selectOperatorByUserId(state, interaction.user_id, profileId));

  const dispatch = useDispatch();

  const isScheduledMsg = interaction.type === 11;
  const interactionLinkInBody =
    interaction.body.match(/(https?:\/\/|ftps?:\/\/|www\.)((?![.,?!;:()]*(\s|$))[^\s]){2,}/gim) || '';

  const goToMsgContext = () => {
    dispatch(changeChatSource(CHAT_SOURCES.MSGS, activeRecipient.type));
    props.getMessageContext(interaction.id, activeRecipient);
  };

  const handleDeleteRemind = () => {
    API.removeMessageReminder(interactionId)
      .then(() => {
        dispatch(deleteRemindedMsgId(interaction.caller.type, interaction.id));

        dispatch({
          type: activeRecipient.type === 1
            ? REMOVE_CLIENT_MSG_REMIND
            : REMOVE_GIRL_REMIND_MSG,
          payload: interactionId
        });
      })
      .catch(console.error);

    // return props.removeMessageReminder(interactionId, activeRecipient.type, false, isMainTimelineOpen);
  }

  const renderHeader = () => {
    const getMsgSender = () => {
      let sender;
      let isIAmSender = false;

      if (interaction.user_id === profileId) {
        sender = operator;
        isIAmSender = true;
      }
      else if (interaction.senderId === profileId) {
        sender = interaction.sender;
        isIAmSender = true;
      }
      else {
        sender = operator || {};
      }

      return {
        sender,
        isIAmSender
      };
    };

    const { isIAmSender, sender } = getMsgSender();

    return (
      <span className="remind-msg__name">
        {isIAmSender ? 'You' : sender.username}
      </span>
    )
  };

  return (
    <div className="remind-msg">
      <DateTime
        className="remind-msg__header"
        date={remindAt}
        config={TODO_MESSAGE_DATE_CONFIG}
      />

      <div className="remind-msg__content" onClick={goToMsgContext}>
        {interactionLinkInBody
          ? <a
            target="_blank"
            className={classModifier("remind-msg__content", "is-link")}
            href={interactionLinkInBody}
          >
            {interactionLinkInBody}
          </a>
          : <span className="remind-msg__content-main">
            <ICONS.quote />
            {parseMsgText(interaction.body, true)}
          </span>
        }

        <div className="remind-msg__caller-info">
          <LazyLoadImage src={getContactAvatar(activeRecipient)} alt="ava" className="remind-msg__img" />

          <ICONS.info className="remind-msg__caller-info-icon" />

          <span className="remind-msg__caller-name">
            {activeRecipient.fn}
          </span>

          <DateTime
            className="remind-msg__msg-time"
            date={interaction.date}
            config={isScheduledMsg && TODO_MESSAGE_DATE_CONFIG}
          />
        </div>
      </div>

      {interaction.attachments &&
        <InteractionAttachments
          attachments={interaction.attachments}
          profileId={profileId}
        />
      }

      <p className="remind-msg__footer">
        The message from the client over which the alarm was created

        <DateTime
          className="remind-msg__time"
          date={remindAt}
          config={NOTIFICATION_DATE_CONFIG}
        />

        <span onClick={handleDeleteRemind} className='remind-msg__close'>
          <ICONS.close />
        </span>
      </p>
    </div>
  );
}

export default RemindMsg;
