import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import API from "api/api";
import {
  closeSession,
  deleteSession,
  updateActiveSession,
  removeActiveSession,
  transferTheSession,
  addAssistantToSession,
  removeVisitedSession,
  duplicateClosedSession,
  continueClosedSession,
  removeAssistantFromSession,
  getSessionById,
  deleteGirlsIdsThatLinksIsSendInChat
} from 'redux/ducks/sessions';
import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import {
  selectContactBySessionId,
  selectOperatorBySessionId,
  selectActiveSessionIsPartners,
  selectIsAmSessionOwner,
  selectIsUnreadMsgsInSession,
} from 'redux/selectors/selectors';
import {
  convertDateFromUTC,
  getContactAvatar,
} from 'utils';
import { timerStart } from 'redux/ducks/timers';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { getContactsById } from 'redux/ducks/contacts';
import { useHideTemporaryTooltip } from 'hooks';

import './Session.scss';
import CallTimer from 'components/CallItem/CallTimer';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Menu from 'components/Menu/Menu';
import ICONS from 'assets/icons';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';

const PAGE_SIZE = 20;

const Session = props => {
  const {
    id,
    isHistory,
    isDefault,
    isMine,
    isPartners,
    isAssistance,
    isAmSessionOwner,
    isCompleted,
    activeSessionIdIfItPartners,
    myId,
    contact,
    // defaultSession,
    reqForTransferId,
    reqForAssistanceIds = [],
    operatorsIds,
    operator,
    currentOperator,
    dateCreated,
    dateClosed,
    isSalesPageOpen,
  } = props;

  const [tooltipStatus, setTooltipStatus] = useHideTemporaryTooltip('', 2000);

  const location = useLocation();

  let startDate;
  let duration;

  if (isCompleted) {
    const momentStartDate = moment(dateCreated);
    const momentEndDate = moment(dateClosed);

    startDate = momentStartDate.utc().format('DD/MM h:mm a');

    const hours = momentEndDate.diff(momentStartDate, 'hours');
    const minutes = momentEndDate.diff(momentStartDate, 'minutes') - hours * 60; 
    const seconds = momentEndDate.diff(momentStartDate, 'seconds') - minutes * 60 - hours * 3600;
    
    let durationArray = [];
    hours && durationArray.push(`${hours}h`);
    minutes && durationArray.push(`${minutes}m`);
    seconds && durationArray.push(`${seconds}s`);

    duration = durationArray.join(':'); 
  }
  
  useEffect(() => {
    if (!isDefault && !isHistory) {
      props.timerStart('session_' + id, props.dateCreated);
    }
    

    if (!contact && !isDefault) {
      props.getContactsById(props.contactId, 1);
    }
  }, [contact]);

  const getIgnoreOperatorsIds = useCallback(
    (isPartners) =>
      isPartners ? [...operatorsIds, ...reqForAssistanceIds] : operatorsIds,
    [operatorsIds, reqForAssistanceIds]
  );

  const onRemovePartnerFromSession = () => {
    if (window.confirm('Remove assistant from session ?')) {
      props.removeAssistantFromSession(id, operatorsIds.find(id => id !== myId));
    }
  }

  const deleteIdGirlsWholeLinksWereSentFromTheArray = () =>
    props.arrayGirlsIdsThatLinksIsSend?.length && props.deleteGirlsIdsThatLinksIsSendInChat(id);

  const closeSession = () => {
    const question = `Close session with client ${contact.fn}?`;

    if (isHistory) {
      return props.removeVisitedSession(id);
    }

    window.confirm(question) && props.closeSession(id) && deleteIdGirlsWholeLinksWereSentFromTheArray();
  };

  const deleteSession = () => {
    const question = `Delete session with client ${contact.fn}?`;

    window.confirm(question) && props.deleteSession(id) && deleteIdGirlsWholeLinksWereSentFromTheArray();
  };

  const onUpdateActiveSession = e => {
    if (e.target.dataset.sessionRequest) {
      return;
    }
    if (e.target.dataset.avatar) {
      return props.openModal(MODAL_TYPES.contactCard, { contact: props.contactId })
    }

    if (isPartners) { // Look at Partner Session
      props.getSessionById(id, 1);
    }

    if ((activeSessionIdIfItPartners || activeSessionIdIfItPartners === 0) && !isPartners) {
      API.removeViewedInSession(activeSessionIdIfItPartners)
        .catch(console.error);
    }

    API.switchSalesSession(id);

    props.updateActiveSession(id); // Update Active Session

    if (location.pathname === '/' && !isSalesPageOpen) {
      window.open('/sales', '_blank');
    }

    if (props.isUnreadMsg) {
      props.openModal(MODAL_TYPES.profilesBuffer, { sessionId: id });
    }
  };

  const duplicateOrContinueSession = (isDuplicate, clientId) => () => { // Closure
    const action = isDuplicate
      ? props.duplicateClosedSession
      : props.continueClosedSession;

    action(id, clientId)
      .then(res => {
        if (res.errorMsg === 'Caller can have only one active session') {
          return setTooltipStatus('Session with this client already exist');
        }
      });
  };

  const fetchOperators = (type) => {
    const config = {
      limit: PAGE_SIZE,
    }
    
    if (type === 'transfer') {
      config.status = 0;
    }

    return (params) => API.getOperatorsForFeedback({ 
      ...params, 
      ...config,
      exclude: getIgnoreOperatorsIds(type === 'partner'),
    })
      .then(({ data }) => ({
        newItems: data,
        newHasMore: data.length === PAGE_SIZE,
      }));
  }

  const renderMenu = () => {
    const menu = [];

    const addNewMenuItem = (action, content) => menu.push({ action, content });

    if (canIEditSession) { // If It is my session
      const isAssistanceSession = operatorsIds.length > 1;

      if (!isAssistanceSession) {
        addNewMenuItem(() => {
          props.openModal(MODAL_TYPES.searchList, {
            fetchData: fetchOperators('partner'),
            itemComponent: SearchListContactItem,
            onChoose: (operator) => {
              props.addAssistantToSession(id, operator.id);
              props.closeModal(MODAL_TYPES.searchList)
            },
          });
        }, "Add Partner to Session" );

        !reqForTransferId && addNewMenuItem(() => {
          props.openModal(MODAL_TYPES.searchList, {
            fetchData: fetchOperators('transfer'),
            itemComponent: SearchListContactItem,
            onChoose: (operator) => {
              props.transferTheSession(id, operator.id)
              props.closeModal(MODAL_TYPES.searchList)
            },
          });
        }, 'Transfer the Session');
      }
      if (isAssistanceSession && isAmSessionOwner) {
        addNewMenuItem(onRemovePartnerFromSession, 'Remove assistant');
      }
      // addNewMenuItem(
      //   openSearchByClients('Change client in session:', 'change-client-in-session'),
      //   'Change Client'
      // );
      addNewMenuItem(closeSession, 'Close session');
      addNewMenuItem(deleteSession, 'Delete session');
    }
    else if (isHistory) { // It is session from history
      const howMuchTimePassed = new Date() - convertDateFromUTC(new Date(props.dateClosed));
      const howMuchTimePassedInHours = howMuchTimePassed / 1000 / 60 / 60;

      const sessionEnded = !!props.dateClosed;

      const itsMySession = operatorsIds.indexOf(myId) !== -1;

      if (itsMySession && howMuchTimePassedInHours < 24 && sessionEnded) {
        addNewMenuItem(duplicateOrContinueSession(false), 'Continue');
      }
      sessionEnded && addNewMenuItem(duplicateOrContinueSession(true), 'Duplicate');

      // sessionEnded && addNewMenuItem( //TODO
      //   openSearchByClients('Duplicate session with another client:', 'duplicate'),
      //   'Duplicate with'
      // );
      addNewMenuItem(closeSession, 'Close session');
    }

    return menu;
  };
  const canIEditSession = isMine || isAssistance;
  const avatarSrc = getContactAvatar(contact);

  return (
      <div
        className={`session${props.isActive ? ' session--active' : ''} ${props.isUnreadMsg ? ' session--unread' : ''}`}
        onClick={onUpdateActiveSession}
      >
        <div className='session__content'>
          <span
            className="sale-sidebar__tooltip"
            style={{
              padding: tooltipStatus ? '0px 2px' : '0'
            }}
          >
            {tooltipStatus}
          </span>

          <div className="session__avatar-wrap">
            <LazyLoadImage
              src={avatarSrc}
              alt="ava"
              className="session__avatar"
              data-avatar
            />
          </div>

          <div className={`session__info${isDefault ? ' session__info--fullwidth' : ''}`}>
            {isDefault
              ? 'Your Default Session'
              : (<>
                <span className='session__info-text'>{contact && contact.fn}</span>
                <span className='session__info-text'>{contact && contact.tels && contact.tels[0]?.tel}</span>
              </>)
            }
          </div>

          <div className="session__right">
            <span className="session__name">
              {(isPartners || isHistory) &&
                <>{props.operator && props.operator.username}</>
              }

              {operatorsIds.includes(myId) && props.isSuccessfulSession && (
                <>Me</>
              )}
            </span>

            {props.timer && !isDefault && !isHistory && !isCompleted &&
              <CallTimer
                timer={props.timer}
              />
            }
          </div>

          {(canIEditSession || isHistory) &&
            <div
              className='session__menu-btn-wrap'
            >
              <Menu
                iconSize={14}
                menuItems={renderMenu()}
                icon={ICONS.burgerMenu}
                dropWrapperProps={{
                  placement: 'bottom-end',
                  offsetY: 16,
                }}
                disabled={isCompleted}
              />
            </div>
          }
        </div>

        {isCompleted && 
          <div className="session__dates">
            <div className="session__dates-start">
              <span className="session__duration">Started: {startDate}</span><br />
              <span className="session__duration">Duration: {duration}</span>
            </div>
            <div className='session__operator'>
              <span className="session__operator-name">
                {operator?.username === currentOperator?.username ? 'You' : `Operator ${operator?.username}`}
              </span>
            </div>
          </div>
        }
        
      </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dateCreated: state.sessions.entities[ownProps.id].dateCreated,
  dateClosed: state.sessions.entities[ownProps.id].dateClosed,
  contactId: state.sessions.entities[ownProps.id].callerId,
  operatorsIds: state.sessions.entities[ownProps.id].usersIds,
  reqForTransferId: state.sessions.entities[ownProps.id].reqForTransferId,
  reqForAssistanceIds: state.sessions.entities[ownProps.id].reqForAssistanceIds,
  myId: state.user.id,
  contact: state.sessions.entities[ownProps.id].caller || selectContactBySessionId(state, ownProps.id),
  operator: selectOperatorBySessionId(state, ownProps.id),
  activeSessionIsPartners: selectActiveSessionIsPartners(state) && state.sessions.activeSession,
  activeSessionIdIfItPartners: selectActiveSessionIsPartners(state) && state.sessions.activeSession, // Id of active session If active session is partners session
  timer: state.timers[`session_${ownProps.id}`],
  // defaultSession: ownProps.isDefault
  //   ? state.sessions.entities[0]
  //   : null,
  isAmSessionOwner: selectIsAmSessionOwner(state, ownProps.id),
  isUnreadMsg: ownProps.isMine && selectIsUnreadMsgsInSession(state, ownProps.id),
  arrayGirlsIdsThatLinksIsSend: state.sessions.entities[state.sessions.activeSession]?.messageSendGirlsIds,
  currentOperator: state.user,
  isSalesPageOpen: state.user.isSalesPageOpen,
});

const mapDispatchToProps = {
  closeSession,
  deleteSession,
  updateActiveSession,
  removeActiveSession,
  timerStart,
  updateActiveContact,
  getContactsById,
  transferTheSession,
  addAssistantToSession,
  removeAssistantFromSession,
  removeVisitedSession,
  duplicateClosedSession,
  continueClosedSession,
  getSessionById,
  openModal,
  closeModal,
  deleteGirlsIdsThatLinksIsSendInChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Session);
