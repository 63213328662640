import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { usePrevious, useWillUnmount } from '../../hooks';

import './AsyncButton.scss';
import Spinner from 'components/UI/Spinner/Spinner';

const AsyncButton = (props) => {
  const {
    className,
    spinnerSize = 25,
    onClick,
    pending: pendingFromProps = false,
    disabled,
    disableSpinnerReplace = false,
    ...buttonProps
  } = props;

  const [pending, setPending] = useState(pendingFromProps);

  const prevPendingFromProps = usePrevious(pendingFromProps);

  let isUnmount = useRef(false);

  useEffect(() => {
    if (prevPendingFromProps && !pendingFromProps) {
      setPending(false);
    }
    else if (!prevPendingFromProps && pendingFromProps) {
      setPending(true);
    }
  }, [pendingFromProps]);

  useWillUnmount(() => isUnmount.current = true);

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') {
      setPending(true);

      return onClick()
        .then(() => !isUnmount.current && setPending(false))
        .catch(console.error)
    }
  };

  return (
    <button
      className={classNames(className, 'async-btn')}
      onClick={handleClick}
      disabled={pending || disabled}
      {...buttonProps}
    >
      {pending
        ? <> 
          {disableSpinnerReplace ? props.children : null}

          <Spinner 
            className="async-btn__spinner"
            spinnerSize={spinnerSize}
            isCentered={!disableSpinnerReplace}
          />
        </>
        : props.children
      }
    </button>
  );
};

export default AsyncButton;
