import { Fragment, useMemo } from 'react';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import "./MailPagination.scss";

const MailPagination = ({ offset, pageSize, totalCount, onGetOffset }) => {
  const currPage = offset / pageSize + 1;
  const pageCount = Math.ceil(totalCount / pageSize);

  const elements = useMemo(() => {
    const elements = [];
    const left = currPage <= 2 ? 4 : 1;
    const right = currPage >= pageCount - 1 ? pageCount - 3 : pageCount;

    for (let page = 1; page <= pageCount; page++) {
      if (
        (page > left && page < right) &&
        !(page >= currPage - 1 && page <= currPage + 1)
      ) {
        continue;
      }

      elements.push({ id: page, type: "page", page });
    }

    if (elements.length === 5) {
      if (elements[1].page - elements[0].page > 1) {
        elements.splice(1, 0, {
          id: -2,
          type: "divider",
        });
      }

      const lastIdx = elements.length - 1;

      if (elements[lastIdx].page - elements[lastIdx - 1].page > 1) {
        elements.splice(-1, 0, {
          id: -1,
          type: "divider",
        });
      }
    }

    return elements;
  }, [currPage, pageCount]);

  return (
    <div className={classModifier("mail-pagination", [
      pageCount >= 5 && "5-pages",
      pageCount >= 10 && "10-pages",
      pageCount >= 100 && "100-pages",
    ])}>
      <button
        className="mail-pagination__arrow-btn mail-pagination__arrow-btn--left"
        onClick={() => onGetOffset((currPage - 2) * pageSize)}
        disabled={currPage <= 1}
        type="button"
      >
        <ICONS.chevron />
      </button>

      {elements.map(({ id, type, page }) => (
        <Fragment key={id}>
          {type === "page" && (
            <button
              className={classModifier("mail-pagination__page-btn", [
                page === currPage && "active",
              ])}
              onClick={() => onGetOffset((page - 1) * pageSize)}
              disabled={page === currPage}
              type="button"
            >
              {page}
            </button>
          )}
          {type === "divider" && (
            <span className="mail-pagination__divider">...</span>
          )}
        </Fragment>
      ))}

      <button
        className="mail-pagination__arrow-btn mail-pagination__arrow-btn--right"
        onClick={() => onGetOffset(currPage * pageSize)}
        disabled={currPage >= pageCount}
        type="button"
      >
        <ICONS.chevron />
      </button>
    </div>
  );
};

export default MailPagination;
