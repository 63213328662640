import React, { memo } from 'react';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

const MessageTemplates = (props) => {

  const renderTemplate = (template) => (
    <li
      key={template.id}
      className="chat-input__template-list-item popup-menu__item"
      onClick={() => handleTemplateClick(template.text)}>
      {template.text}
    </li>
  );

  const handleTemplateClick = (text) => {
    props.addBoilerplate(text);
  };

  return (
    <button
      title="message templates"
      className="chat-input__btn chat-input__btn--template"
      onClick={props.onClick}
    >
      <ICONS.plusCircle className={classModifier("chat-input__btn-icon", props.isShow && 'active')} />

      {props.isShow &&
        <div className="chat-input__template-list-wrap">
          <ul className="chat-input__template-list">
            {props.msgTemplates.map(item => renderTemplate(item))}
          </ul>
        </div>
      }
    </button>
  );
};

export default memo(MessageTemplates);
