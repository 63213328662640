import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { BOOKING_MESSAGES_DATE_CONFIG } from 'config/dates-сonfig';
import { classModifier } from 'utils';

import "./BookingSessionItem.scss";

const DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const BookingSessionItem = (props) => {
  const {
    Escort,
    Badges,
    Details,
    Date,
    Status,
    ConfirmationStatus,
    Operator,
    Markers,
    statusColor,
    innerRef,
    isDeleted,
    handleOpenBookingModal
  } = props;

  return (
    <div
      className={classModifier('booking-session-item', isDeleted && 'deleted')}
      onClick={handleOpenBookingModal}
      ref={innerRef}
    >
      <div
        className="booking-session-item__color-line"
        style={{ backgroundColor: statusColor }}
      />

      <div className={classModifier("booking-session-item__column", 'escort')}>
        <Escort namesAlignment="vertical" />
      </div>

      <div className={classModifier("booking-session-item__column", 'duo')}>
        <Badges />
      </div>

      <div className={classModifier("booking-session-item__column", 'details')}>
        <Details items={DETAILS_ITEMS} />
      </div>

      <div className={classModifier("booking-session-item__column", 'requirements')}>

      </div>

      <div className={classModifier("booking-session-item__column", 'status')}>
        <Status />
        <Date className="booking-session-item__date" config={BOOKING_MESSAGES_DATE_CONFIG} />
      </div>

      <div className={classModifier("booking-session-item__column", 'rest')}>
        <div className="booking-session-item__column-row">
          <Markers />
          <ConfirmationStatus />
        </div>
        <Operator />
      </div>
    </div>
  )
}

export default BookingSessionItem;
