import React, { memo, useRef } from 'react'
import { Field } from "react-final-form";

import { classModifier } from '../../../utils';
import ICONS from 'assets/icons';
import { useDidMount } from 'hooks';

const NicknameTelegramFieldItem = props => {

  const {
    index,
    fields,
    fieldArrayMeta,
    nickname,
    serverError,
    classPrefix, 
    setServerError,
    saveContact,
    values,
  } = props;

  const nicknameRef = useRef();

  useDidMount(() => {
    if(!nicknameRef.current.value) {
      nicknameRef.current.focus();
    }
  })

  const isErrorExist = (fieldArrayMeta, inputMeta, index) => {
    const { error } = fieldArrayMeta;
    const isValidationError = error && error.length && error[index] && error[index].error;

    if (isValidationError && inputMeta.touched && !inputMeta.active) {
      return 'client';
    }
    else if (!isValidationError && serverError && serverError[index]) {
      return 'server';
    }

    return null;
  }

  const hideServerErrorOnChange = (e, input) => {
    input.onChange && input.onChange(e);

    serverError && setServerError((prev) => {
      const updatedServerError = JSON.parse(JSON.stringify(prev));

      delete updatedServerError.telegram_nicknames[index];

      return updatedServerError;
    });
  };

  const onBlur = (e, input, index) => {
    input.onBlur && input.onBlur(e);

    if (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget?.dataset.action === 'delete') {
      fields.remove(index);
    } 
  };

  const removeNickname = (index) => {
    fields.remove(index)

    if(index === 0) {
      saveContact({...values, telegram_nicknames: []});
    }
  }

  return (
    <div className={`${classPrefix}__telegram-nickname`}>
      <Field
        name={`${nickname}.nickname`}
        type="text"
      >
        {({ input, meta }) => {
          return (<>
            <div className={classModifier(`${classPrefix}__input-wrapper`, 'telegram-nickname')}>
              <input
                {...input}
                ref={nicknameRef}
                className={classModifier(`${classPrefix}__input`, [
                  'telegram-nickname',
                  isErrorExist(fieldArrayMeta, meta, index) && 'error',
                ])}
                placeholder={`Telegram nickname ${index + 1}`}
                autoComplete='off'
                onChange={e => hideServerErrorOnChange(e, input)}
                onBlur={(e) => onBlur(e, input, index)}
              />

              <button
                type="button"
                className={classModifier(`${classPrefix}__input-field-btn`, 'field-action')}
              >
                <ICONS.telegram className={`${classPrefix}__icon`}/>
              </button>

              {isErrorExist(fieldArrayMeta, meta, index) &&
                <div className="form-error-text__wrap">
                  <div className="form-error-text">
                    {isErrorExist(fieldArrayMeta, meta, index) === 'server'
                      ? serverError[index]
                      : fieldArrayMeta.error[index].error
                    }
                  </div>
                </div>
              }
            </div>

            <button
              type="button"
              title="Remove nickname"
              data-action="delete"
              className={classModifier(`${classPrefix}__input-field-btn`, 'del')}
              onClick={() => removeNickname(index)}
            >
              <ICONS.close />
            </button>
          </>
        )}}
      </Field>
    </div>
  )
}

export default memo(NicknameTelegramFieldItem);