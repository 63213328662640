import React, { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import API from 'api/api';
import { useDidMount, useToggle } from 'hooks';
import { CHAT_TYPES, NUMBER_REGEX } from 'config/constants';
import { CARD_TABS } from 'config/constants';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import ICONS from 'assets/icons';

import './ChatCreatorForm.scss';

const ChatCreatorForm = (props) => {
  const [tel, setTel] = useState('');

  const dispatch = useDispatch();

  const [err, toggleErr] = useToggle(false);

  const inputRef = useRef(null);

  useDidMount(() => {
    inputRef.current && inputRef.current.focus();
  });

  const handleSubmit = (e, shouldContactEdited) => {
    e.preventDefault();

    if (err || !tel) {
      return;
    }

    API.createNewChat(tel)
      .then(res => {
        if (shouldContactEdited) {
          return new Promise((resolve) => {
            dispatch(openModal(MODAL_TYPES.contactCard, {
              contact: res.data,
              defaultTab: CARD_TABS.EDIT,
              autoFocusInput: 'fn',
            }));
            
            document.addEventListener('closeModal', () => {
              API.createNewChat(tel)
                .then(res => {
                  resolve(res.data)
                })
            });
          })
        } else {
          return res.data;
        }
      })
      .then(res => {
        props.updateActiveContact(res);
        props.removeContactTab('new_chat', CHAT_TYPES.CLIENT);
      })
      .catch(toggleErr);
  };

  const handleOnChange = (e) => {
    if (tel === '+' && !e.target.value) {
      return;
    }

    setTel(e.target.value);

    if (!e.target.value.match(NUMBER_REGEX)) {
      toggleErr(true);
    } else {
      toggleErr(false);
    }
  };

  return (
    <form
      className="chat-creator-form"
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSubmit(e)
        }
      }}
    >
      {!tel && (
        <mark className='chat-creator-form__phone-code'>
          +44
        </mark>
      )}

      <input
        style={{
          color: err ? 'red' : null,
          paddingLeft: tel ? 16 : 0,
        }}
        ref={inputRef}
        onChange={handleOnChange}
        onFocus={() => !tel && setTel('+44')}
        onBlur={() => tel === '+44' && setTel('')}
        placeholder=": Enter phone number"
        className="chat-creator-form__input"
        type="tel"
        required
        value={tel}
      />

      <button
        className="chat-creator-form__btn--submit"
        onClick={(e) => handleSubmit(e, true)}
      >
        <ICONS.plusCircle />
      </button>
    </form>
  )
};

export default ChatCreatorForm;
