import {
  useReducer,
  useCallback,
  useEffect,
  useRef,
} from 'react';

const useThunkReducer = (reducer, initialState, reducerName) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const prevState = useRef(state);

  useEffect(() => {
    prevState.current = { ...prevState.current, state }
  }, [state])

  const thunkDispatch = useCallback(
    (action) => {
      if (typeof action === 'function') {
        return action(thunkDispatch);
      } else {
        prevState.current = { ...prevState.current, action };
        return dispatch(action);
      }
    }, []);

  return [state, thunkDispatch];
}

export default useThunkReducer;