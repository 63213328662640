import React, { memo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ReactTimeAgo from 'react-timeago';

import { selectUserTimezone } from 'redux/selectors/selectors';
import { getDateByTimezoneOffset } from 'utils';
import { BASE_DATE_CONFIG } from 'config/dates-сonfig';

const DateTime = ({
  className,
  date,
  hoursToAbsolute,
  config = BASE_DATE_CONFIG,
  relative,
  range, //in minutes
  nonReduceEqualDate,
  separator = '-',
  ignoreTimezone,
  hidden,
  notIgnoreTimezoneForPassedDays
}) => {
	if (hidden) {
    return null;
  }

  const userTimezone = useSelector(selectUserTimezone);
  const hour12 = useSelector((state) => state.user.hour12);

  const currentDateByTimezone = getDateByTimezoneOffset(userTimezone);
  let pickedDateByTimezone;
  let boundaryDate;

  if (Array.isArray(date)) {
    pickedDateByTimezone = ignoreTimezone ? date[0] : getDateByTimezoneOffset(userTimezone, date[0]);
    boundaryDate = ignoreTimezone ? date[1] : getDateByTimezoneOffset(userTimezone, date[1]);
  } else {
    pickedDateByTimezone = ignoreTimezone ? date : getDateByTimezoneOffset(userTimezone, date);
  }

  if (range) {
    const rangeInMilliseconds = 1000 * 60 * range;
    const pickedDateInMilliseconds = pickedDateByTimezone.getTime();

    boundaryDate = new Date(pickedDateInMilliseconds + rangeInMilliseconds);
  }

  const getRelativeDate = () => {
    const localTimezoneOffset = -new Date().getTimezoneOffset();
    const dateByTimezoneOffset = getDateByTimezoneOffset(userTimezone, date);

    if (userTimezone === localTimezoneOffset) {
      return dateByTimezoneOffset;
    }
    const neededOffset = (userTimezone - localTimezoneOffset) * 60000;

    return new Date(dateByTimezoneOffset - neededOffset);
  };

  const hourInMilliseconds = 1000 * 60 * 60;
  const diffWithCurrentDate = currentDateByTimezone - pickedDateByTimezone;

  if (relative || (hoursToAbsolute && diffWithCurrentDate <= hourInMilliseconds * hoursToAbsolute)) {
    return (
      <ReactTimeAgo
        className={className}
        minPeriod={60}
        date={getRelativeDate()}
      />
    )
  }

  let dateForRender;
  let boundaryDateForRender;

  const convertConfig = (config) => {
    let convertedConfig = config;

    if (!hour12 && convertedConfig) {
      convertedConfig = convertedConfig
        .split('')
        .map(letter => letter === 'h' ? 'H' : letter)
        .join('');

      convertedConfig = convertedConfig
        .split(' ')
        .filter(part => part !== 'A')
        .join(' ');
    }

    return convertedConfig;
  }

  const today = moment(ignoreTimezone && !notIgnoreTimezoneForPassedDays ? new Date() : currentDateByTimezone)
  const pickedMomentDay = moment(pickedDateByTimezone);

  const passedDays = today.startOf('day').diff(pickedMomentDay.startOf('day'), 'days');

  const currentMonth = today.format('MMMM');
  const pickedDateMonth = pickedMomentDay.format('MMMM')
  const isPreviousMonth = currentMonth !== pickedDateMonth;

  const currentYear = today.format('yyyy');
  const pickedDateYear = pickedMomentDay.format('yyyy');
  const isPreviousYear = currentYear !== pickedDateYear;

  const isRangeDate = !!boundaryDate;

  const dateConfig = config instanceof Function
    ? config({ passedDays, isPreviousMonth, isPreviousYear, hour12, isRangeDate })
    : config;

  dateForRender = moment(pickedDateByTimezone).format(convertConfig(dateConfig));

  let equalDate;
  let nonEqualDateForRender;
  let nonEqualBoundaryDateForRender;
  if (boundaryDate) {
    boundaryDateForRender = moment(boundaryDate).format(convertConfig(dateConfig));

    let equalParts = [];
    let nonEqualIndex = 0;

    for (const [i, part] of Object.entries(dateForRender.split(' '))) {
    	if (part === boundaryDateForRender.split(' ')[i]) {
        equalParts.push(part);
        nonEqualIndex = Number(i);
      } else {
        break;
      }
    }

    equalDate = equalParts.join(' ');

    if (equalDate) {
      nonEqualDateForRender = dateForRender.split(' ').slice(nonEqualIndex + 1).join(' ');
      nonEqualBoundaryDateForRender = boundaryDateForRender.split(' ').slice(nonEqualIndex + 1).join(' ');
    }
  }

  const renderDate = () => {
    if (!isRangeDate) {
      return (
        <time dateTime={pickedDateByTimezone} className={className}>
          {dateForRender}
        </time>
      )
    } else if (!equalDate || nonReduceEqualDate) {
			return (
        <time dateTime={pickedDateByTimezone} className={className}>
          {dateForRender} {separator} {boundaryDateForRender}
        </time>
      )
    } else if (nonEqualBoundaryDateForRender) {
      return (
        <time dateTime={pickedDateByTimezone} className={className}>
          <span>
						{equalDate}
          </span>
          <span>
          	{nonEqualDateForRender} {separator} {nonEqualBoundaryDateForRender}
          </span>
        </time>
      )
    } else {
			return (
        <time dateTime={pickedDateByTimezone} className={className}>
          {equalDate}
        </time>
      )
    }
  }

  return renderDate()
}

export default memo(DateTime);
