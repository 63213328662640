import Select from 'react-select';
import { useSelector } from 'react-redux';
import { memo, useEffect } from 'react';
import _, { update } from 'lodash';

import { classModifier } from 'utils';
import ICONS from 'assets/icons';

import UsersCount from '../UsersCount/UsersCount';
import UserNameLabel from '../UserNameLabel/UserNameLabel';

const getLabelObject = (name) => ({
  id: name,
  fn: name,
  type: 'group',
})

const UsersField = ({
  input,
  meta,
  groupContactsLength,
  choosenGroups,
  updateUsers,
  ...rest
}) => {
  const forwardedMessage = useSelector((state) => state.mailing.forwardedMessage);

  useEffect(() => {
    const filteredUsers = input.value.filter((user) => user.type !== 'group');
    const usersWithGroups = [...choosenGroups.map(getLabelObject), ...filteredUsers];

    updateUsers(usersWithGroups);
  }, [choosenGroups])

  const onRemove = (removingUser) => {
    if (removingUser.type === 'group') {
      const event = new CustomEvent("removeLabel", {
        detail: { title: removingUser.fn }
      });
      window.dispatchEvent(event);
    } else {
      const newUsers = input.value.filter((user) => user.id !== removingUser.id);
      updateUsers(newUsers);
    }
  }

  const actualUserCounts = input.value.length + groupContactsLength - choosenGroups.length;

  return (
    <div className="mailing__message-header">
      <div className="mailing__selected-username-container">
        <UsersCount count={actualUserCounts} />

        <Select
          isMulti
          isSearchable={false}
          className={classModifier("mailing__selected-username-labels", !input.value.length && 'hidden')}
          classNames={{
            valueContainer: () => 'value-container',
            indicatorsContainer: () => 'indicators-container'
          }}
          components={{
            MultiValueContainer: UserNameLabel,
            MultiValueRemove: (props) => (
              <div role="button" onClick={() => onRemove(props.data)}>
                <ICONS.close />
              </div>
            )
          }}
          getOptionLabel={(option) => option.fn}
          getOptionValue={(option) => option.id}
          {...input}
          {...meta}
          {...rest}
        />
      </div>
      
      {!input.value.length && !choosenGroups.length &&
        <p className={classModifier("mailing__error-message", !!input.value.length && 'hidden')}>
          Please add recipients from the contact list
          <ICONS.arrow />
        </p>
      }

      <p className={classModifier("mailing__forwarding-message", !forwardedMessage && 'hidden')}>
        <span>
          <ICONS.forward />
        </span>
        You are forwarding
      </p>
    </div>
  )
}

export default memo(UsersField);
