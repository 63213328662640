import React, { memo, useCallback } from 'react'
import { FieldArray } from 'react-final-form-arrays';
import { v4 as uuid } from 'uuid';

import { classModifier } from '../../../utils';
import ICONS from 'assets/icons';

import EmailsFieldItem from './EmailsFieldItem';

const EmailsField = ({
  classPrefix, 
  serverError,
  setServerError, 
  phoneLabels, 
  isNewContactCreation = true,
}) => {

  const addNewField = useCallback((fields) => {
    if (fields.length >= 5) return;

    fields.push({ 
      default: +!fields.length,
      uuid: uuid(),
    });
  }, [phoneLabels]);

  return (
    <FieldArray name="emails">
      {({ fields, meta: fieldArrayMeta }) => (
        <div className={`${classPrefix}__mails`}>
          {fields.length > 0 && <div className={classModifier(`${classPrefix}__group`, [
            'mails',
            !isNewContactCreation && fieldArrayMeta.initial?.some((value, idx) => !fields.value[idx]?.id) && 'edited'
          ])}>
            {fields.map((email, index) => (
              <EmailsFieldItem 
                key={index}
                email={email}
                index={index}
                fields={fields}
                serverError={serverError}
                classPrefix={classPrefix}
                fieldArrayMeta={fieldArrayMeta} 
                setServerError={setServerError}
                isNewContactCreation={isNewContactCreation}
              />
            ))}
          </div>}

          <button
            type="button"
            onClick={() => addNewField(fields, 'email')}
            className={classModifier(`${classPrefix}__add-field-btn`, "mail-add")}
          >
            <ICONS.plusCircle className={`${classPrefix}__btn-add-icon`}/>
          </button>
        </div>
      )}
    </FieldArray>
  )
}

export default memo(EmailsField);
