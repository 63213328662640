import { useEffect, useRef } from 'react';

import { webSocket } from 'components/ChatSocket';

const useSocket = (handleSocket) => {
  const callbackRef = useRef(handleSocket);

  useEffect(() => {
    callbackRef.current = handleSocket;
  })

  useEffect(() => {
    const callback = (event) => callbackRef.current(event)
    webSocket.addEventListener('message', callback);
    return () => webSocket.removeEventListener('message', callback);
  }, []);
}

export default useSocket;
