import ICONS from 'assets/icons';
import React from 'react';

import './ChatAttachments.scss';


const ChatAttachments = (props) => {
  if (!props.isShow) return null;

  return (
    <div className="chat-attachment">

      <button
        className="chat-attachment__btn"
        title="Remove shared msg"
        onClick={props.onClose} >
        <ICONS.close className='chat-attachment__btn-icon' />
      </button>

      {props.children}
    </div>
  );
}

export default React.memo(ChatAttachments);
