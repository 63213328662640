import classNames from 'classnames';
import { classModifier } from 'utils';

import "./BookingBadges.scss";

const BookingBadges = ({ className, booking }) => (
  <div className={classNames(className, 'booking-badges')}>
    {booking.meeting_type === 'duo' && (
      <mark className={classModifier("booking-badges__item", 'duo')}>
        DUO
      </mark>
    )}
  </div>
)

export default BookingBadges;
