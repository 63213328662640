import React from 'react';
import { useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { ADR_BOOK_FILTERS, CONTACT_TYPES } from 'config/constants';


const AdrBookTag = ({ tag = {}, contactType, ...props }) => {
  const isActive = useSelector((state) => !!state.addressBook.tags.active[tag?.id]);
  const contactsPending = useSelector((state) => state.addressBook.contactsPending);

  const isClientOrGirlTag =
    tag.caller_type === CONTACT_TYPES.CLIENT ||
    tag.caller_type === CONTACT_TYPES.GIRL;

  const getItemModifiers = (tag) => {
    const modifiers = [];

    if (tag.title === 'Temporary') modifiers.push('temporary');

    if (contactType === ADR_BOOK_FILTERS.ALL) {
      switch (tag.caller_type) {
        case CONTACT_TYPES.CLIENT: {
          modifiers.push('client');
          break;
        }
        case CONTACT_TYPES.GIRL: {
          modifiers.push('girl');
          break;
        }
      }
    }

    return modifiers;
  };

  const getTitle = () =>{
    if (tag.title === '*') {
      return '\u2b50';
    }

    if (tag.title === 'EX_girls') {
      return (
        <span className="adr-book-tags__ex">
          EX
        </span>
      )
    }

    return tag.title;
  }

  if (tag.callersCount === 0) return null;

  return (
    <li
      className={classModifier('adr-book-tags__list-item', [
        ...getItemModifiers(tag),
      ])}
    >
      {contactType === ADR_BOOK_FILTERS.ALL && isClientOrGirlTag && (
        <div className="adr-book-tags__list-item-title">
          <span>
            {tag.caller_type === CONTACT_TYPES.CLIENT
              ? 'Filter By Clients Tags'
              : 'Filter By Escort Tags'
            }
          </span>
        </div>
      )}
  
      <button
        className={classModifier("adr-book-tags__tag", [
          isActive && "active"
        ])}
        onClick={() => !contactsPending && props.updateTagsFilter(tag.id)}
        style={{ ...(contactsPending ? { cursor: 'wait' } : {}) }}
        disabled={contactsPending}
      >
        <span className="adr-book-tags__tag-title">
          {getTitle()}
        </span>

        <span className="adr-book-tags__tag-count">
          &nbsp;({tag.callersCount})
        </span>
      </button>
    </li>
  );
}

export default AdrBookTag;
