const getCurrentSelectedRate = (type, bookingDuration, currProfile) => {
  // D - default, M - member, S - secret
  const { prices, prices_member, prices_secret } = currProfile;

  const convertType = type === 'incall_duo' ? 'incall' : type

  const getPriceByDuration = (prices) => {
    const price = prices.find(item => item[convertType] && item.name === bookingDuration?.label);
    return price ? price[convertType] : null;
  }

  const priceD = getPriceByDuration(prices);
  const priceM = getPriceByDuration(prices_member);
  const priceS = getPriceByDuration(prices_secret);

  const pricesForCaller = [
    { type: 'D', label: `£${priceD}`, value: priceD },
    { type: 'M', label: `M £${priceM}`, value: priceM },
    { type: 'S', label: `S £${priceS}`, value: priceS }
  ].filter(item => item.value !== null);

  return pricesForCaller;
};

export default getCurrentSelectedRate;