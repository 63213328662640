import React from 'react';

import MsgText from '../ChatTimeline/interactions/MsgText';

const AttachmentEditedMessage = ({ interaction, ...props }) => {
  return (
    <div className={`${props.className ? props.className : ""} attachment-msg`}>
      <header className="attachment-msg__header">
        {props.showTitle &&
          <h4 className="attachments__title">Edited message</h4>
        }
      </header>

      <div className="attachment-msg__content">
        <MsgText interaction={interaction} />
      </div>
    </div>
  );
}

export default AttachmentEditedMessage;