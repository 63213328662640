import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { selectContactById, selectOperatorOrUserById } from 'redux/selectors/selectors';
import { closeModal } from 'redux/ducks/activeWindows';
import ICONS from 'assets/icons';

import './TransferredOrAssistanceSessionModal.scss'
import TransferredOrAssistanceSessionModalItem from './components/TransferredOrAssistanceSessionModalItem';

const TransferredOrAssistanceSessionModal = ({
  type,
  userId,
  sessionId,
  callerId
}) => {
  const dispatch = useDispatch();
  const operator = useSelector((state) => selectOperatorOrUserById(state, userId));
  const client = useSelector((state) => selectContactById(state, callerId));

  const replyToRequest = (status) => {
    let reply;

    switch (type) {
      case NOTIFICATION_TYPES.sessionAssistanceRequest:
        reply = API.responseForAddToAssistant;
        break;
      case NOTIFICATION_TYPES.sessionTransferRequest:
        reply = API.responseForTransferSession;
        break;
    }

    reply(sessionId, status);
    dispatch(closeModal());
  };

  const isAssistanceModalType = type === NOTIFICATION_TYPES.sessionAssistanceRequest;

  return (
    <div className="transferred-or-assistance-session-modal">
      <div className="transferred-or-assistance-session-modal__wrapper">
        <div className="transferred-or-assistance-session-modal__header">
          <div className="transferred-or-assistance-session-modal__icons">
            <ICONS.puzzle className="transferred-or-assistance-session-modal__icon" />
            {!isAssistanceModalType && (
              <ICONS.transferCalls className="transferred-or-assistance-session-modal__icon" />
            )}
          </div>
          Session {isAssistanceModalType ? 'Assistance' : 'Transfer'}
        </div>
        <div className="transferred-or-assistance-session-modal__content">
          <TransferredOrAssistanceSessionModalItem
            item={operator}
            isAssistanceModalType={isAssistanceModalType}
          />
          <TransferredOrAssistanceSessionModalItem item={client} />
        </div>
        <div className="transferred-or-assistance-session-modal__btns">
          <button
            className="transferred-or-assistance-session-modal__btn transferred-or-assistance-session-modal__btn-decline"
            onClick={() => replyToRequest("ignore")}
          >
            Decline
          </button>
          <button
            className="transferred-or-assistance-session-modal__btn transferred-or-assistance-session-modal__btn-accept"
            onClick={() => replyToRequest("accept")}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferredOrAssistanceSessionModal;