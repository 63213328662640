import React, { useState } from 'react';
import { connect } from 'react-redux';

import API from 'api/api';
import { useDidUpdate } from 'hooks';
import { selectLockedOperator } from 'redux/selectors/selectors';

import './LockForm.scss';
import Checkbox from 'components/Checkbox/Checkbox';

const LockForm = (props) => {

  const { 
    input = {},
    operator,
    classPrefix,
    isInAdrBook,
    editedContact,
  } = props;
  
  const [isBannedMedia, setIsBannedMedia] = useState(editedContact.is_banned_for_media);

  useDidUpdate(() => {
    if (isInAdrBook) {
      setIsBannedMedia(editedContact.is_banned_for_media);
    }    
  }, [editedContact.is_banned_for_media]);

  const toggleLockContactForMedia = () => {
    if(!isInAdrBook) {
      API.banMedia(editedContact.id, Number(!isBannedMedia))
        .then(res => {
          if(res.data) {
            setIsBannedMedia(!isBannedMedia);
          }
        })
        .catch(console.error)
    } else {
      input?.onChange(!isBannedMedia);
    }
  }

  return (
    <div className={classPrefix + '__group'}>
      <span className={classPrefix + '__label'}>Related to : </span>

      <span className="lock-form__name">{operator}</span>

      <div className="lock-form__block-send-media-toggle">
        <label className="lock-form__block-send-media-toggle-label">
          <Checkbox
            theme="rectangled"
            inputProps={{
              ...input,
              checked: isBannedMedia,
              onChange: toggleLockContactForMedia
            }}
          />
          <span className="lock-form__block-send-media-toggle-span">Block media</span> 
        </label>
      </div>
    </div>
  )
};

const mapStateToProps = (state, ownProps) => ({
  operator: selectLockedOperator(state, ownProps.editedContact.relatedUserId),
});

export default connect(mapStateToProps)(LockForm);
