export const createPoint = (marker, category) => ({
  type: 'Feature',
  properties: {
    cluster: false,
    category,
    ...marker
  },
  geometry: {
    type: 'Point',
    coordinates: [
      parseFloat(marker.longitude),
      parseFloat(marker.latitude)
    ],
  },
})

export const createPoints = (data, category) => {
  if (category === 'girls') {
    return Object.values(data).map((item) => createPoint(item.girl, 'girls'));
  } else {
    return createPoint(data, 'clients');
  }
}
