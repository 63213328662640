import { connect } from "react-redux";

import { selectFilterNameByKey } from "redux/selectors/selectors";

const PropertyFilter = ({ filter, ...props }) => {
  const onUpdateActiveFilters = () => {
    if (props.isDisabled) return;

    return props.updateActiveFilters(filter, props.activeSession, props.activeFilters, props.canIEditSession);
  };

  const getClassName = () => {
    let className = 'gallery-sidebar-filters__item';

    if (props.isDisabled) {
      className += ' gallery-sidebar-filters__item--disabled';
    }
    else if (props.isActive) {
      className += ' gallery-sidebar-filters__item--active';
    }

    return className;
  };

  return (
    <li
      className={getClassName()}
      onClick={onUpdateActiveFilters}
      title={props.title && props.filterName}
    >
      {props.filterName}
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  filterName: selectFilterNameByKey(state, ownProps.filter)
});

export default connect(mapStateToProps)(PropertyFilter);