import { StandaloneSearchBox } from '@react-google-maps/api';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { resetClientData, updateClientCoordinates, updateClientLocation } from 'redux/ducks/map';
import { GirlsMapModalContext } from '../../GirlsMapModal';
import { useDidUpdate } from 'hooks';

import SearchInput from 'components/SearchInput/SearchInput';


const ClientLocationInput = () => {
  const {
    clientData: {
      latitude,
      longitude,
      radius,
      location,
    }
  } = useSelector(state => state.map);
  const locationRef = useRef();
  const dispatch = useDispatch();

  const { fitBounds, flyTo } = useContext(GirlsMapModalContext);

  const onSearchBoxLoad = useCallback(ref => locationRef.current = ref, []);
  const onPlacesChanged = useCallback(() => {
    const place = locationRef.current.getPlaces()[0];
    const bounds = new window.google.maps.LatLngBounds();

    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    fitBounds(bounds)

    dispatch(updateClientCoordinates(place));
    dispatch(updateClientLocation(place.formatted_address));
  }, []);

  const flyToClientMarker = () => {
    flyTo({ lat: latitude, lng: longitude }, 15 - (radius.value || 0) / 2);
  }

  const updateLocationInputByClientAddress = () => {
    API.getAddressByCoordinates(latitude, longitude)
      .then(({ data }) => {
        if (!data.results?.length) return;
          dispatch(updateClientLocation(data.results[0].formatted_address));
        })
  }

  useEffect(() => {
    if (!latitude || !longitude) return;

    updateLocationInputByClientAddress();
    flyToClientMarker();
  }, [latitude, longitude, radius.value])

  useDidUpdate(() => {
    if (location) return;

    dispatch(resetClientData());
  }, [location])

  return (
    <div className="girls-map__filter-wrap">
      <label
        className="girls-map__filter-label"
        htmlFor="location"
      >
        Enter client location
      </label>

      <StandaloneSearchBox
        onLoad={onSearchBoxLoad}
        onPlacesChanged={onPlacesChanged}
      >
        <SearchInput
          name="location"
          inputWrapClassName="girls-map__client-location-input-wrap"
          inputClassName="girls-map__client-location-input"
          showSearchBtn
          showClearBtn
          query={location}
          setQuery={(value) => dispatch(updateClientLocation(value))}
          iconSize="small"
        />
      </StandaloneSearchBox>
    </div>
  )
}

export default React.memo(ClientLocationInput);
