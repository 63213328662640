import React from 'react';

import './OpenCommModule.scss';
import Button from 'components/Button/Button';
import { BUTTON_THEMES, ROUTES } from 'config/constants';

const OpenCommModule = ({ title = 'Please open Comm module.', isNeedButton = true }) => {
  return (
    <div className="comm-module-modal">
      <h2 className="comm-module-modal__title">{title}</h2>
      {isNeedButton && (
        <Button
          type="button"
          className="comm-module-modal__button"
          theme={BUTTON_THEMES['inversion-light']}
        >
          <a href={ROUTES.main} target='_blank'>
            Open Comm module
          </a>
        </Button>
      )}
    </div>
  );
}

export default OpenCommModule;
