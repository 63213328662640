const set12am = (date) => {
  if (date) {
    return new Date(date).setHours(12, 0, 0, 0);
  } else {
    return null;
  }
}

export default (date, separated) => {
  if (!separated && Array.isArray(date)) {
    return { dateFrom: set12am(date[0]), dateTo: set12am(date[1]) }
  } else {
    return { date: set12am(date) }
  }
}
