import DateTime from 'components/DateTime';
import { FEEDBACK_DATE_CONFIG } from 'config/dates-сonfig';
import { useSelector } from 'react-redux';
import { selectOperatorOrUserById } from 'redux/selectors/selectors';

const TaskLog = ({ item, className }) => {
  const operator = useSelector((state) => selectOperatorOrUserById(state, item.user_id));
  const operatorCreated = useSelector((state) =>
    selectOperatorOrUserById(state, item.created_by_user_id));
  const operatorAssigned = useSelector((state) =>
    selectOperatorOrUserById(state, item.assigned_to_user_id));
  const operatorCompleted = useSelector((state) =>
    selectOperatorOrUserById(state, item.completed_by_user_id));

  const isReminders = item.is_sent === 0 || item.is_sent === 1;

	return (
    <div className={className}>
      {item.created_by_user_id && (
        <div className="tasks-and-reminders-item__created-info-text">
          Created{" "}
          <DateTime
            date={item.date_created}
            config={FEEDBACK_DATE_CONFIG}
          />{" "}
          by:{" "}
          <span>
            {isReminders
              ? operator.username
              : (item.created_by_user_id || item.user_creator_id)
                ? operatorCreated.username
                : "All operators"}
          </span>
        </div>
      )}
      {item.assigned_date && (
        <div className="tasks-and-reminders-item__created-info-text">
          Assigned{" "}
          <DateTime
            date={item.assigned_date}
            config={FEEDBACK_DATE_CONFIG}
          />{" "}
          to:{" "}
          <span>
            {isReminders
              ? operator.username
              : item.assigned_to_user_id
                ? operatorAssigned.username
                : "All operators"}
          </span>
        </div>
      )}
      {!isReminders &&
        item.completed_date && (
          <div className="tasks-and-reminders-item__created-info-text">
            Completed{" "}
            <DateTime
              date={item.completed_date}
              config={FEEDBACK_DATE_CONFIG}
            />{" "}
            by:{" "}
            <span>
              {item.completed_by_user_id
                ? operatorCompleted.username
                : "All operators"}
            </span>
          </div>
        )}
    </div>
  )
}

export default TaskLog;
