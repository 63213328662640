import { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  selectDivaNotLoadedIds,
  selectActiveSession,
  selectActiveSessionId, selectDefaultSession,
} from "redux/selectors/selectors";
import { 
  getClientPrevBookedProfiles, 
  showSessionFromHistory, 
  updateActiveSession, 
  normalizeSession,
  getSessionById,
} from "redux/ducks/sessions";
import { getContactsById } from 'redux/ducks/contacts';
import { CONTACT_TYPES, SOCKET_PAGES_TITLES } from 'config/constants';
import { getActiveDivaGirls } from "redux/ducks/divaGirls";

import "./SalesPage.scss";
import Spinner from "components/UI/Spinner/Spinner";
import SalesHeader from "containers/SalesHeader/SalesHeader";
import Gallery from "containers/Gallery/Gallery";
import ProfilesCompareZone from "containers/ProfilesCompareZone/ProfilesCompareZone";
import SalesSidebar from "containers/SalesSidebar/SalesSidebar";
import API from '../../api/api';
import { LS } from '../../utils';
import { useOpenCommModuleModal, useSetSocketPageStatus } from "hooks";
// import SalesHeader from "../../containers/SalesHeader/SalesHeader";
// import SalesSidebar from "../../containers/SalesSidebar/SalesSidebar";
// import ProfilesCompareZone from "../../containers/ProfilesCompareZone/ProfilesCompareZone";
// import Gallery from "../../containers/Gallery/Gallery";

const SalesPage = (props) => {
  const {
    defaultSession,
    addSessionPending,
    activeSessionId,
    idsNotLoaded,
    aSessionPrevBookedProfiles,
    aSessionClientId,
    getActiveDivaGirls,
    getSessionById,
    isNotFull,
    aSessionClient,
    getContactsById,
    getClientPrevBookedProfiles,
    userId,
  } = props;

  const urlQuery = new URLSearchParams(window.location.search);

  const [sessionIdFromURL, setSessionIdFromURL] = useState(+urlQuery.get('sessionId'));

  useOpenCommModuleModal();
  useSetSocketPageStatus(SOCKET_PAGES_TITLES.sales);

  useEffect(() => {
    const userActiveSession = LS.getUserStorage(userId)['aSession'];

    props.updateActiveSession(userActiveSession);
  }, []);

  useEffect(() => {
    if (activeSessionId) {
      if (isNotFull) {
        getSessionById(activeSessionId, 1)

        if (!aSessionClient?.date_created) {
          getContactsById(aSessionClientId, CONTACT_TYPES.CLIENT)
        }
      }
    }
  }, [activeSessionId]);

  useEffect(() => {
    if (!addSessionPending && sessionIdFromURL) {
      getSessionById(sessionIdFromURL)
        .then(({ data: session }) => {
          API.switchSalesSession(session.id);

          session.active && !session.is_hidden
            ? props.updateActiveSession(session.id)
            : props.showSessionFromHistory(normalizeSession(session));
        })
        .catch((err) => {
          API.switchSalesSession(0);
          props.updateActiveSession(0);
          console.error(err);
        })
        .finally(() => {
          window.history.replaceState(null, null, window.location.pathname);
          setSessionIdFromURL(null);
        })
    }
  }, [addSessionPending, sessionIdFromURL])

  useEffect(() => {
    if (idsNotLoaded) {
      getActiveDivaGirls({ params: { 'filter-profileid': idsNotLoaded } });
    }
  }, [idsNotLoaded]);

  useEffect(() => {
    if (
      aSessionClientId && activeSessionId &&
      !aSessionPrevBookedProfiles && aSessionPrevBookedProfiles !== null
    ) {
      getClientPrevBookedProfiles(aSessionClientId, activeSessionId);
    }
  }, [activeSessionId, aSessionPrevBookedProfiles, aSessionClientId]);

  // const isPageInit = !addSessionPending && !idsNotLoaded;   // && !sessionIdFromURL;

  if (!defaultSession) {
    return (
      <main className="sales-page sales-page--pending">
        <Spinner spinnerSize={160} />
      </main>
    );
  }

  return (
    <main className="sales-page">
      <SalesHeader
        isNotFull={isNotFull}
        activeSessionId={activeSessionId}
        aSessionClientId={aSessionClientId}
      />

      <SalesSidebar />

      <section className="sales-compare">
        {!!idsNotLoaded
          ? <Spinner spinnerSize={60} className="sales-compare__global-spinner" />
          : <ProfilesCompareZone />
        }
      </section>

      {isNotFull
        ? <Spinner spinnerSize={60} className="sales-page__gallery-spinner" />
        : <Gallery />
      }
    </main>
  );
};

const mSTP = state => {
  const activeSessionId = selectActiveSessionId(state);
  const activeSession = selectActiveSession(state);
  
  return {
    defaultSession: selectDefaultSession(state),
    addSessionPending: state.sessions.addSessionPending,
    idsNotLoaded: activeSession && selectDivaNotLoadedIds(state, activeSessionId).toString(),
    activeSessionId,
    aSessionPrevBookedProfiles: activeSession?.prevBookedProfiles,
    aSessionClientId: activeSession?.callerId,
    isNotFull: activeSession?.isNotFull,
    aSessionClient: state.contacts.entities[activeSession?.callerId],
    userId: state.user.id,
  };
};

const mDTP = {
  getActiveDivaGirls,
  getClientPrevBookedProfiles,
  showSessionFromHistory,
  updateActiveSession,
  getSessionById,
  getContactsById,
};

export default connect(mSTP, mDTP)(SalesPage);
