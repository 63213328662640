import React from 'react';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import { selectContactById } from 'redux/selectors/selectors';
import { classModifier, getContactAvatar, showContactName } from 'utils';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';


const AdrBookSelectedContactsListItem = ({ contact, onDelete }) => {

  const handleDeleteSelectedContact = () => {
    onDelete(contact.id);
  }

  return (
    <li className='adr-book-item__select-list-item'>
      <div className={classModifier("adr-book-item__img-wrap", [
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        ])}
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="adr-book-item__img" />

        {contact.default_channel === 'telegram' &&
          <ICONS.telegram className="adr-book-item__ava-icon" />
        }
      </div>

      <div className="adr-book-item__info">
        <span className="adr-book-item__name">
          {contact.fn}
          {contact.fn && showContactName(contact.fn, 'adr-book-item__ex')}
        </span>

        <span className="adr-book-item__content">
          {contact?.minimizedTels && contact.minimizedTels[0] && contact.minimizedTels[0]?.tel}
        </span>
      </div>

      <button className="adr-book-item__clear-btn" onClick={handleDeleteSelectedContact}>
        <ICONS.close />
      </button>
    </li>
  )
}

const mapStateToProps = (state, { id }) => ({
  contact: selectContactById(state, id),
});


export default connect(mapStateToProps)(AdrBookSelectedContactsListItem);