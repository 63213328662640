import classNames from 'classnames';

import { BOOKING_STATUS_FILTERS_NAMES } from 'config/constants';

import './BookingConfirmationStatus.scss';
import getBookingConfirmationStatusColor from 'components/BookingItemNew/helpers/getBookingConfirmationStatusColor';
import Tooltip from 'components/UI/Tooltip/Tooltip';

const BookingConfirmationStatus = ({ className, booking }) => {
  const statusColor = getBookingConfirmationStatusColor(booking.confirmation_status);

  return (
    <Tooltip
      text={`${booking.reason || '- '}: ${booking.description || '-'}`}
      disable={booking.confirmation_status !== BOOKING_STATUS_FILTERS_NAMES.cancelled}
    >
      <div
        className={classNames(className, 'booking-confirmation-status')}
        style={{ borderColor: statusColor }}
      >
        {booking.confirmation_status}

        <span
          className='booking-status-dot'
          style={{ backgroundColor: statusColor }}
        />
      </div>
    </Tooltip>
  )
}

export default BookingConfirmationStatus;
