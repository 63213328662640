import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { faLock, faMicrophone, faMicrophoneSlash, faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import API from 'api/api';
import { classModifier, getContactAvatar, isEmptyObj } from 'utils';
import { CONFERENCE } from 'redux/ducks/conferences';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { joinToConference, requestLeaveFromActiveConference, toggleWebrtcConferenceMute } from 'redux/ducks/webrtc';
import { MAX_CONFERENCE_PARTICIPANTS } from 'config/constants';

import './ConferenceItem.scss';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import CallTimer from '../CallItem/CallTimer';
import ICONS from 'assets/icons';

const WebrtcConferenceItem = ({ conference, fullMode }) => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user.id);
  const activeConferenceId = useSelector(state => state.webrtc.activeConferenceId);
  const isConferenceMute = useSelector(state => state.webrtc.isConferenceMute);
  const localStream = useSelector(state => state.webrtc.localStream);
  const peerConnections = useSelector(state => state.webrtc.peerConnections);
  const isActiveCall = useSelector(state => !isEmptyObj(state.calls.activeCall));
  const conferenceTimer = useSelector(state => state.timers['conf_' + conference.id]);

  const isActive = activeConferenceId === conference.id;
  const userConferenceData = isActive && conference.participants[userId];

  useEffect(() => {
    if (isActive) {
      const confirm = function (e) {
        e.returnValue = "You have active conference";
      };

      window.addEventListener("beforeunload", confirm);

      return () => {
        window.removeEventListener("beforeunload", confirm);
      };
    };
  }, [isActive]);

  const openConferenceModal = () => {
    if (fullMode) return;

    dispatch(openModal(MODAL_TYPES.webrtcConferenceFullMode, { conferenceId: conference.id }));
  };

  const activeParticipantsLength = useMemo(() => (
    Object.values(conference.participants).reduce((length, participant) => {
      if (['active', 'connecting', 'busy'].includes(participant.status)) {
        return ++length;
      }
      return length;
    }, 0)
  ), [conference.participants]);

  const handleAccept = () => {
    if (activeConferenceId || isActiveCall || activeParticipantsLength >= MAX_CONFERENCE_PARTICIPANTS) return;

    dispatch(joinToConference(conference));
  }

  // const handleToggleMute = () => {
  //   API.webrtcParticipantMute(!userConferenceData.is_muted)
  //     .then(() => {
  //       if (isConferenceMute) return;

  //       localStream && localStream.getTracks().forEach(track => track.enabled = userConferenceData.is_muted);
  //     });
  // }

  // const handleToggleWebrtcConferenceMute = (isWebrtcConfMute) => {
  //   API.webrtcConferenceMute(isWebrtcConfMute)
  //     .then(() => {
  //       dispatch(toggleWebrtcConferenceMute(isWebrtcConfMute));

  //       if (userConferenceData.is_muted) return;

  //       localStream && localStream.getTracks().forEach(track => track.enabled = !isWebrtcConfMute);
  //     });
  // }

  // const renderBtnsMute = () => (
  //   <>
  //     <button
  //       onClick={() => handleToggleMute()}
  //     >
  //       {userConferenceData.is_muted ? <ICONS.microphoneMute fill="red" width={20} height={20} /> 
  //       : <ICONS.microphoneMute fill="#40404099" width={20} height={20} />
  //       }
  //     </button>

  //     {/* <button
  //       onClick={() => handleToggleWebrtcConferenceMute(!isConferenceMute)}
  //     >
  //       <SvgIcon
  //         type='fa'
  //         color="#0092F2"
  //         title={isConferenceMute ? "Unmuted conference" : "Muted conference"}
  //         icon={isConferenceMute ? faVolumeMute : faVolumeUp}
  //       />
  //     </button> */}
  //   </>
  // );


  return (
    <div className={"general-conference " +
      classModifier("conference-item", [
        isActive && "active",
        conference.isInvited && 'invited',
      ])}>
      <div className="conference-item__main">
        <div
          onClick={openConferenceModal}
          className="conference-item__header"
        >

          <span className="conference-item__count">
            {conference.participantsIds.length}
          </span>

          {/* <div className="conference-item__img-wrap">
            <LazyLoadImage src={getContactAvatar({ type: CONFERENCE })} alt="ava" className="conference-item__img" />
          </div> */}
        </div>

        <div className="conference-item__info">
          <p className="conference-item__meta">
            {conference.name}

            {/* {!!conference.is_closed &&
              <FontAwesomeIcon icon={faLock} />
            } */}
          </p>

          {isActive && conferenceTimer &&
            <div className="conference-item__timers">
              <CallTimer
                timer={conferenceTimer}
                className="conference-item__timer"
              />

              {!isOperator &&
              <Menu
                menuItems={[]}
                icon={ICONS.burgerMenu}
                iconSize={14}
              />
              }
            </div>
          }
        </div>

        {!!conference.isInvited &&
          <div className="conference-item__invitation">
            <span className="conference-item__invitation-title">
              Invitation
            </span>

            <div className="conference-item__invitation-btns">
              <button onClick={() => API.rejectWebrtcConference(conference.id)}>
                Reject
              </button>
              <button onClick={handleAccept}>
                Accept
              </button>
            </div>
          </div>
        }
      </div>

      {/* <div className="conference-item__btns">
        {isActive && fullMode && userConferenceData && userConferenceData.status === 'active' &&
          <div className="conference-item__btns-mute-wrap">
            {renderBtnsMute()}
          </div>
        }

        {activeConferenceId
          ? <button
            onClick={() => dispatch(requestLeaveFromActiveConference(conference, peerConnections, localStream))}
            disabled={!isActive || isActiveCall}
            className="conference-item__btn conference-item__btn--finish"
          >
            <ICONS.phoneSquare />
          </button>

          : <button
            className="conference-item__btn"
            onClick={handleAccept}
            disabled={conference.is_closed
              || conference.isInvited
              || isActiveCall
              || activeParticipantsLength >= MAX_CONFERENCE_PARTICIPANTS
            }
          >
            <ICONS.phoneSquare />
          </button>
        }
      </div> */}
    </div>
  );
}

export default WebrtcConferenceItem;