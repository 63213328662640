//-------helpers------------------------------
import { LS } from './index';

const parse = value => JSON.parse(value);

//---------for export-------------------------
const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

const deleteCookie  = (name) => {
  const domain = window.location.hostname;

  setCookie(name, '', {
    'max-age': -1,
    domain: domain,
  });
};

const getUserStorage = (userId) => {
  const userStorage = parse(getCookie('user_' + (userId || localStorage.currentUserId)) || null);

  if (userStorage && typeof userStorage === 'object' && userStorage.constructor.name === 'Object') {
    return userStorage;
  }
  return {};
};

const updateUserStorage = (userId) => {
  const domain = window.location.hostname;
  const userStorage = LS.getUserStorage(userId);

  setCookie(
    `user_${userId || localStorage.currentUserId}`,
    JSON.stringify({
      aClient: userStorage.aClient,
      aGirl: userStorage.aGirl,
      cTabs: userStorage.cTabs,
      clientChatsRecentTabs: userStorage.clientChatsRecentTabs,
      gTabs: userStorage.gTabs,
      girlChatsRecentTabs: userStorage.girlChatsRecentTabs,
      hour12: userStorage.hour12,
      unfixedClientTab: userStorage.unfixedClientTab,
      unfixedGirlTab: userStorage.unfixedGirlTab,
      domain: domain,
    }),
    { domain: domain }
  );
};

export default {
  getCookie,
  setCookie,
  deleteCookie,
  getUserStorage,
  updateUserStorage,
};
