import React, { useCallback, useEffect } from 'react';

import { classModifier } from 'utils';

import ICONS from 'assets/icons';
import { useSelector } from 'react-redux';
import { selectModalCount, selectModalType } from 'redux/selectors/selectors';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';

const CarouselNavigation = (props) => {
  const {
    isModal,
    mediaLength,
    classNamePrefix,
    setActiveSlide,
  } = props;

  const modalType = useSelector(selectModalType);

  const onKeyDown = (e) => {
    e.preventDefault();

    if(isModal && ['ArrowRight', 'ArrowLeft'].includes(e.key)) {
      e.stopPropagation();

      if (e.key === 'ArrowRight') {
        changeSlide('next');
      }
      else if (e.key === 'ArrowLeft') {
        changeSlide('prev');
      }

    }
  }

  const changeSlide = (action) => {
    const lastSlide = mediaLength - 1;

    if (action === "prev") {
      setActiveSlide(prevActiveSlide =>
        prevActiveSlide === 0
          ? lastSlide
          : prevActiveSlide - 1
      );
    }
    else {
      setActiveSlide(prevActiveSlide =>
        prevActiveSlide === lastSlide
          ? 0
          : prevActiveSlide + 1
      );
    };
  };

  useEffect(() => {
    if (isModal && [MODAL_TYPES.profileCarousel, MODAL_TYPES.photoGallery].includes(modalType)) {
      document.addEventListener('keydown', onKeyDown, { capture: true });
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown, { capture: true });
    }
  }, [modalType]);

  return (
    <>
      <div
        className={classModifier(`${classNamePrefix}__controller`, 'prev')}
        onClick={() => changeSlide("prev")}
      >
        <ICONS.chevron className={classModifier(`${classNamePrefix}__controller-icon`, 'prev')} />
      </div>

      <div
        className={classModifier(`${classNamePrefix}__controller`, 'next')}
        onClick={() => changeSlide("next")}
      >
        <ICONS.chevron className={classModifier(`${classNamePrefix}__controller-icon`, 'next')} />
      </div>
    </>
  )
}

export default CarouselNavigation;
