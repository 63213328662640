import React, { memo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useOpenCommModuleModal, useSetSocketPageStatus } from 'hooks';
import { useFilteredBookings } from 'components/FilteredBookings';
import { CALENDAR_ROUTES, CALENDAR_ROUTE_PATHS, SOCKET_PAGES_TITLES } from 'config/constants';

import './CalendarPage.scss';
import CalendarHeader from '../../containers/CalendarHeader/CalendarHeader';
import CalendarTwoDays from 'containers/CalendarMain/components/CalendarTwoDays/CalendarTwoDays';
import CalendarTimeline from 'containers/CalendarMain/components/CalendarTimeline/CalendarTimeline';
import CalendarSplit from 'containers/CalendarMain/components/CalendarSplit/CalendarSplit';
import CalendarWeekView from 'containers/CalendarMain/components/CalendarWeekView/CalendarWeekView';
import CalendarTable from 'containers/CalendarMain/components/CalendarTable/CalendarTable';


const calendarRouteConfig = {
  [CALENDAR_ROUTES.weekView]: {
    path: CALENDAR_ROUTE_PATHS.weekView,
    Component: CalendarWeekView,
  },
  [CALENDAR_ROUTES.twoDays]: {
    path: CALENDAR_ROUTE_PATHS.twoDays,
    Component: CalendarTwoDays,
  },
  [CALENDAR_ROUTES.timeline]: {
    path: CALENDAR_ROUTE_PATHS.timeline,
    Component: CalendarTimeline,
  },
  [CALENDAR_ROUTES.calendar]: {
    path: CALENDAR_ROUTE_PATHS.calendar,
    Component: CalendarSplit,
  },
  [CALENDAR_ROUTES.table]: {
    path: CALENDAR_ROUTE_PATHS.table,
    Component: CalendarTable,
  },
}

const getFilteredBookingConfig = (configKey) => {
  let config;
  
  if (!Object.values(CALENDAR_ROUTE_PATHS).includes(configKey)) {
    return {};
  }

  if (configKey === CALENDAR_ROUTE_PATHS.weekView) {
    config = {
      boundaryDays: 7,
      separatedBookingsByDay: true,
      limit: 20,
    }
  }

  if (configKey === CALENDAR_ROUTE_PATHS.twoDays) {
    config = {
      boundaryDays: 2,
      separatedBookingsByDay: true,
      separatedFiltersByDay: true,
    }
  }

  return {
    memoizedFilters: 5,
    isAutoScrollToActualTime: true,
    limit: 30,
    ...config,
  }
}

const CalendarPage = () => {
  const location = useLocation();
  const configKey = '/' + location.pathname.split('/').at(-1);

  const { resetFilters, ...bookingProps } = useFilteredBookings(getFilteredBookingConfig, configKey);
  const { date } = bookingProps;

  const [selectedBookings, setSelectedBookings] = useState({
    all: false,
    exceptIds: [],
    includeIds: [],
  });

  useOpenCommModuleModal()
  useSetSocketPageStatus(SOCKET_PAGES_TITLES.calendar)

  return (
    <div className="calendar-page">
      <CalendarHeader
        getters={bookingProps.getters}
        loadMore={bookingProps.loadFilteredBookings}
        date={date}
        getFilterProps={bookingProps.getFilterProps}
        selectedBookings={selectedBookings}
      />

      <div className="calendar-page__main">
        <Routes>
          {Object.values(calendarRouteConfig).map(({ path, Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <Component
                  {...bookingProps}
                  selectedBookings={selectedBookings}
                  setSelectedBookings={setSelectedBookings}
                />
              }
            />
          ))}

          <Route
            path="/*"
            element={<Navigate to={`../timeline`} replace />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default memo(CalendarPage);
