import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Title from './components/Title/Title';

// const url = '/media/sprite-svg.svg';

const SvgIcon = ({ type = 'svg', color, fill, height, width, className, icon, title = '', ...props }) => {
  const [state, setState] = useState({
    isShowTitle: false,
    coords: {
      x: 0,
      y: 0,
    }
  });

  const handleMouseEnter = (e) => {
    setState({
      coords: {
        x: e.clientX,
        y: e.clientY,
      },
      isShowTitle: true,
    })
  };

  if (type === 'svg') {
    return (
      <>
        {/* <svg
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setState({ isShowTitle: false })}
          onClick={props.onClick}
          viewBox='0 0 16 16'
          fill={color || fill}
          width={width}
          height={height || width}
          className={className}
        >
          <use xlinkHref={`${url}#${icon}`} />
        </svg> */}
        { state.isShowTitle && !!title.length &&
          <Title title={title} coords={state.coords} />
        }
      </>
    )
  }
  if (type === 'fa') {
    return (
      <>
        <FontAwesomeIcon
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => setState({ isShowTitle: false })}
          onClick={props.onClick}
          icon={icon}
          className={className}
          color={color}
          style={{ fontSize: width }}
        />
        { state.isShowTitle && !!title.length &&
          <Title title={title} coords={state.coords} />
        }
      </>
    )
  }
}


export default React.memo(SvgIcon);