import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { CALENDAR_WEEK_VIEW_DATE_CONFIG } from 'config/dates-сonfig';
import { classModifier } from 'utils';

import './BookingWeekItem.scss';

export const DETAILS_ITEMS_NON_HIDDEN = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const DETAILS_ITEMS_HIDDEN = [BOOKING_DETAILS_ITEMS_NAMES.RATE]

const BookingWeekItem = (props) => {
  const {
    ConfirmationStatus,
    Markers,
    Date,
    Operator,
    Details,
    Escort,
    Client,
    Requirements,
    statusColor,
    isHidden,
    innerRef,
    handleOpenBookingModal,
    onInnerClick,
    onOuterClick,
    isDeleted,
    style,
  } = props;

  if (isHidden) {
    return (
      <div
        style={style}
        className={classModifier('booking-week-item__wrapper', isDeleted && 'deleted')}
      >
        <li
          className="booking-week-item"
          style={{ borderColor: statusColor }}
          onClick={onInnerClick}
          ref={innerRef}
        >
          <div className={classModifier("booking-week-item__content", "hidden")}>
            <Escort className="booking-week-item__escort"/>
            <Details
              className="booking-week-item__hidden-details"
              items={DETAILS_ITEMS_HIDDEN}
            />
            <Date config={CALENDAR_WEEK_VIEW_DATE_CONFIG} />
            by
            <Client className="booking-week-item__client" />
          </div>
        </li>
      </div>
    );
  }

  return (
    <div
      style={style}
      className={classModifier('booking-week-item__wrapper', isDeleted && 'deleted')}
    >
      <div className="booking-week-item__outer-header" onClick={onOuterClick}>
        <ConfirmationStatus />
      </div>

      <li
        className="booking-week-item"
        style={{ borderColor: statusColor }}
        ref={innerRef}
        onClick={handleOpenBookingModal}
      >
        <div className="booking-week-item__header">
          <Markers className="booking-week-item__markers" />
          <Requirements />
        </div>

        <div className="booking-week-item__info">
          <Date isRanged />
          <Operator />
        </div>

        <div className="booking-week-item__content">
          <Details
            className="booking-week-item__details"
            items={DETAILS_ITEMS_NON_HIDDEN}
          />
          <Escort className="booking-week-item__escort" />
          <Client className="booking-week-item__client" />
        </div>
      </li>
    </div>
  )
}

export default BookingWeekItem;
