import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { v4 as uuid } from 'uuid';

import API from 'api/api';
import ICONS from 'assets/icons';
import { debounce } from "utils";
import { classModifier } from 'utils';

import './ContactAddressTab.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';


const formattedAddressValue = address => {
  const { line1 = '', line2 = '', city = '', postcode = '' } = address;

  const addrLine1 = line1 && ((line2 || city || postcode) ? line1 + ', ' : line1);
  const addrLine2 = line2 && ((city || postcode) ? line2 + ', ' : line2);
  const cityTown = city && (postcode ? city + ', ' : city);
  const combinedAddress = `${addrLine1 || ''}${addrLine2 || ''}${cityTown || ''}${postcode || ''}`;
  const isFilledAnyFormField = !!combinedAddress.replaceAll(' ', '');

  return { combinedAddress, isFilledAnyFormField };
}


const ContactAddressTabItem = ({ values, initAddress, name, saveContact, onDelete, onSetAddressItem }) => {
  const { combinedAddress, isFilledAnyFormField} = formattedAddressValue(initAddress);
  const { address_id, uuid } = initAddress;

  const [isSeparateView, setIsSeparateView] = useState(initAddress.address || isFilledAnyFormField);
  const form = useForm();

  useEffect(() => {
    if(initAddress.address && !isFilledAnyFormField) {
      form.change(`${name}.line1`, initAddress.address);
    }
  }, []);

  const onOpenFields = () => {
    setIsSeparateView(!isSeparateView);
  }

  const onSetAddressValues = () => {
    if (isFilledAnyFormField) {
      form.change(`${name}.address`, combinedAddress);

      onSetAddressItem(uuid, combinedAddress);
    } else {
      onDelete();
    }

    setIsSeparateView(!isSeparateView);
  }

  const removeField = () => {
    onDelete();

    if(address_id) {
      const addresses = values.addresses.filter(addr => addr.uuid !== initAddress.uuid);
      saveContact({ ...values, addresses });
    }
  }

  return (<>
    {isSeparateView 
      ? <div className={classModifier("contact-address-tab__input-wrap", "joined-address")}>
          <FormTextTextarea
            autosize
            className="contact-address-tab__input"
            inputClassName="contact-address-tab__textarea"
            input={{
              value: initAddress.address || combinedAddress,
              onClick: onOpenFields
            }}
          />
          <button 
            type="button"
            className={classModifier("contact-address-tab__btn", 'clear')}
            onClick={removeField}
          >
            <ICONS.close className="contact-address-tab__map-icon-wrap" />
          </button>
        </div>
        : <Fragment>
          <div className={classModifier("contact-address-tab__btn-address-wrapper", 'is-first')}>
            <button
              type="button"
              className={classModifier("contact-address-tab__btn", 'delete')}
              onClick={removeField}
            >
              Delete
              <ICONS.trashTransparent className="contact-address-tab__btn-add-icon" />
            </button>
            <button
              type="button"
              className={classModifier("contact-address-tab__btn", 'done')}
              onClick={onSetAddressValues}
            >
              Done
              <ICONS.check className="contact-address-tab__btn-add-icon" />
            </button>
          </div>

          <Field
            name={`${name}.line1`}
            className="contact-address-tab__input-wrap"
            render={(props) => {
              return (
                <div className={classModifier('contact-address-tab__input-wrap', 'address')}>
                  <h4 className='contact-address-tab__addr-title'>Address line 1</h4>
                  <FormTextInput
                    {...props}
                    autosize
                    inputClassName="contact-address-tab__input"
                  />
                </div>
              )
            }}
          />

          <Field
            name={`${name}.line2`}
            className="contact-address-tab__input-wrap"
            render={(props) => {
              return (
                <div className={classModifier('contact-address-tab__input-wrap', 'address')}>
                  <h4 className='contact-address-tab__addr-title'>Address line 2</h4>
                  <FormTextInput
                    {...props}
                    autosize
                    inputClassName="contact-address-tab__input"
                  />
                </div>
              )
            }}
          />

          <Field
            name={`${name}.city`}
            className="contact-address-tab__input-wrap"
            render={(props) => {
              return (
                <div className={classModifier('contact-address-tab__input-wrap', 'address')}>
                  <h4 className='contact-address-tab__addr-title'>Town / City</h4>
                  <FormTextInput
                    {...props}
                    autosize
                    inputClassName="contact-address-tab__input"
                  />
                </div>
              )
            }}
          />

          <Field
            name={`${name}.postcode`}
            className="contact-address-tab__input-wrap"
            render={(props) => {
              return (
                <div className={classModifier('contact-address-tab__input-wrap', 'address')}>
                  <h4 className='contact-address-tab__addr-title'>Postcode / ZIP</h4>
                  <FormTextInput
                    {...props}
                    autosize
                    inputClassName="contact-address-tab__input"
                  />
                </div>
              )
            }}
          />
        </Fragment>
      }
    </>
  )
}

const ContactAddressTab = props => {
  const {
    values,
    editedContact,
    saveContact,
  } = props;

  const [isShowSearchAddrForm, setIsSearchAddrForm] = useState(false);
  const [searchedAddress, setSearchedAddress] = useState('');
  const [addrSearchedList, setAddrSearchedList] = useState(null);
  const [isShowSearchResult, setIsShowSearchResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const duoFormRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (duoFormRef.current && !duoFormRef.current.contains(event.target)) {
        setIsShowSearchResult(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [duoFormRef]);


  // const onBlur = (e, input, removeEmptyField) => {
  //   input.onBlur && input.onBlur(e);
  //   removeEmptyField();
  // };

  const onAddAddressField = (fields, address) => {
    setIsSearchAddrForm(false);

    if(address) {
      const { line1 = '', line2 = '', city = '', postcode = '' } = address;
      const { combinedAddress } = formattedAddressValue(address);

      const addressToObj = {
        address: combinedAddress,
        caller_id: editedContact.id,
        uuid: uuid(),
        line1,
        line2,
        city,
        postcode,
      };

      fields.push(addressToObj);
      saveContact({ ...values, addresses: [...values.addresses, addressToObj] });

      return;
    }

    fields.push({
      address: '',
      caller_id: editedContact.id,
      uuid: uuid(),
      line1: '',
      line2: '',
      city: '',
      postcode: '',
    });
  }

  const handleSearch = value => {
    if(value) {
      setIsLoading(true);

      API.getAddressesByPostcode(value)
        .then(res => {
          setAddrSearchedList(res.data);
          setIsShowSearchResult(true);
        })
        .catch(() => {
          setAddrSearchedList([])
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  }

  const handleSearchDebounce = useCallback(debounce(handleSearch, 200), []);

  const onSearchAddress = e => {
    const { value } = e.target;

    setSearchedAddress(value);
    handleSearchDebounce(value);
  }

  const onDelete = (fields, idx) => {
    fields.remove(idx);
  }

  const onSetAddressItem = (adrUuid, address) => {
    const prevAddresses = editedContact.addresses;
    const changedAddresses = values.addresses.map((adr) => {
      if (adr.uuid === adrUuid) {
        return { ...adr, address: address };
      }

      return adr;
    });

    if (changedAddresses?.length !== prevAddresses?.length) {
      saveContact({ ...values, addresses: changedAddresses });
      return;
    }

    const isEditedAddresFields = changedAddresses?.some((obj, idx) => (
      obj?.caller_id !== prevAddresses[idx].caller_id ||
      obj?.line1 !== prevAddresses[idx].line1 ||
      obj?.line2 !== prevAddresses[idx].line2 ||
      obj?.city !== prevAddresses[idx].city ||
      obj?.postcode !== prevAddresses[idx].postcode
    ))

    isEditedAddresFields && saveContact({ ...values, addresses: changedAddresses });
  }

  return (
    <div>
      <FieldArray name="addresses">
        {({ fields }) => {
          return <>
            {fields.map((name, idx) => {
              const initAddress = fields.value[idx];
              return (
                <ContactAddressTabItem
                  key={initAddress.uuid}
                  name={name}
                  values={values}
                  initAddress={initAddress}
                  onDelete={() => onDelete(fields, idx)}
                  onSetAddressItem={onSetAddressItem}
                  saveContact={saveContact}
                />)
            })}

            {isShowSearchAddrForm 
              ? <div ref={duoFormRef} className="contact-address-tab__addr-search-form">
                  <FormTextInput
                    autosize
                    meta={{}}
                    input={{ 
                      value: searchedAddress,
                      onChange: onSearchAddress,
                      onClick: () => setIsShowSearchResult(!isShowSearchResult),
                      placeholder: 'Enter Postcode'
                    }}
                    inputClassName="contact-address-tab__addr-search-input input"
                  />

                  {isLoading
                    ? <Spinner /> 
                    : isShowSearchResult &&
                        <ul className="contact-address-tab__addr-search-list">
                          {addrSearchedList
                            ? addrSearchedList.map(addr => {
                                const { combinedAddress } = formattedAddressValue(addr);
                                return (
                                  <li 
                                    key={uuid()}
                                    onClick={() => onAddAddressField(fields, addr)}
                                    className='contact-address-tab__addr-search-list-item'
                                  >
                                    {combinedAddress}
                                  </li>
                                )
                              })
                            : addrSearchedList?.length === 0 && 
                                <li className={classModifier('contact-address-tab__addr-search-list-item', 'no-item')}>
                                  (no item)
                                </li>
                          }
                          <li 
                            onClick={() => onAddAddressField(fields)}
                            className={classModifier('contact-address-tab__addr-search-list-item', 'manually')}
                          >
                            + Add addess manually
                          </li>
                        </ul>
                  }
                </div>
              : <div className="contact-address-tab__btn-wrapper">
                  <button
                    type="button"
                    className="contact-address-tab__btn-add"
                    onClick={() => setIsSearchAddrForm(true)}
                  >
                    <ICONS.plusCircle className="contact-address-tab__btn-add-icon" />
                  </button>
                </div>
              }
          </>
        }}
      </FieldArray>
    </div>
  )
};

export default ContactAddressTab;
