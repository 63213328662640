import API from 'api/api';
import LS from 'utils/localStorageAPI';

import { normalizeContacts, addToEntitiesIfMissing } from 'utils';
import { NEW_INCOMING_OPERATOR_CALL, NEW_OUTGOING_OPERATOR_CALL } from './calls';
import {
  NEW_INCOMING_CHAT_MESSAGE,
  NEW_OUTGOING_CHAT_MESSAGE,
  PUBLIC_CHAT_TABS,
  UPDATE_ACTIVE_CHAT
} from './roomChats';
import { getOperatorsList, CHANGE_OPERATOR_ENTITY } from './operators';
import { sortContactIdsByDate, updateRecentTabs, ADD_CONTACTS_TO_ENTITIES } from './contacts';
import { CHAT_TYPES } from 'config/constants';
import ICONS from 'assets/icons';

export const GET_ALL_ROOMS = 'GET_ALL_ROOMS';
export const GET_ALL_ROOMS_PENDING = 'GET_ALL_ROOMS_PENDING';
export const GET_PRIVATE_ROOMS = 'GET_PRIVATE_ROOMS';
export const GET_ROOMS_FROM_TABS = 'GET_ROOMS_FROM_TABS';

export const GET_ALL_ROOMS_FILTER = 'GET_ALL_ROOMS_FILTER';
export const UPDATE_ALL_ROOMS_FILTER = 'UPDATE_ALL_ROOMS_FILTER';

export const FILTER_ROOMS = "FILTER_ROOMS";

export const ADD_NEW_ROOM = 'ADD_NEW_ROOM';
export const UPDATE_ROOMS_LIST = 'UPDATE_ROOMS_LIST';
export const ROOMS_LIMIT = 20;
export const GET_SEARCH_ROOMS = 'GET_SEARCH_ROOMS';
export const STOP_SEARCH_ROOMS = 'STOP_SEARCH_ROOMS';
export const UPDATE_SEARCH_ROOMS = 'UPDATE_SEARCH_ROOMS';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const REMOVE_ROOM = 'REMOVE_ROOM';

export const DEFAULT_FILTER = 'Default';
export const ALL_FILTER = 'Show all operators';
export const ONLINE_FILTER = 'Show only online operators';
export const ALL_OPERATORS_FILTER = 'All operators';
export const ALL_ROOMS_FILTER = 'Show only chat rooms';
export const SHOW_UNREAD_ROOMS = 'Show unread chats';

export const GET_OFF_TODAY_GIRLS = 'GET_OFF_TODAY_GIRLS';
export const ADD_OFF_TODAY_GIRLS = 'ADD_OFF_TODAY_GIRLS';
export const ADD_GIRL_TO_OFF_TODAY = 'ADD_GIRL_TO_OFF_TODAY';
export const REMOVE_GIRL_FROM_OFF_TODAY = 'REMOVE_GIRL_FROM_OFF_TODAY';

export const GET_AVAILABLE_GIRLS = 'GET_AVAILABLE_GIRLS';
export const ADD_AVAILABLE_GIRLS = 'ADD_AVAILABLE_GIRLS';
export const ADD_GIRL_TO_AVAILABLE = 'ADD_GIRL_TO_AVAILABLE';
export const REMOVE_GIRL_FROM_AVAILABLE = 'REMOVE_GIRL_FROM_AVAILABLE';

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const CLEAR_SEARCH_QUERY = 'CLEAR_SEARCH_QUERY';

export const startOperatorsSearch = (query) => ({ type: SET_SEARCH_QUERY, payload: query });

export const stopOperatorsSearch = () => ({ type: CLEAR_SEARCH_QUERY });


export const getInitialDataForRooms = (userId) => async dispatch => {
  dispatch({ type: GET_ALL_ROOMS_PENDING });

  const chTabs = LS.getItem('chTabs', userId);
  const aChat = LS.getItem('aChat', userId);

  await dispatch(getOperatorsList());

  const tabs = chTabs && chTabs.length
    ? chTabs
    : [];

  await dispatch(getAllRooms());
  await dispatch(getRoomsFromTabs(tabs, aChat, userId));
  // await dispatch(getPrivateRooms()); //TODO remove
};

export const getPrivateRooms = () => dispatch => {
  API.getPrivateRooms()
    .then(res => {
      const normalizedChats = normalizeContacts(res.data, false);

      dispatch({
        type: GET_PRIVATE_ROOMS,
        payload: normalizedChats
      });
    })
    .catch(console.error);
};

export const getRoomsFromTabs = (idsForRequest, active, userId) => dispatch => {
  return API.getRoomsById(idsForRequest)
    .then(res => {
      let rooms = normalizeContacts(res.data, false);
      const sortedRelatedIds = [];

      // check if we have in chatTabs irrelevant ids. It can happens when another operator remove you from room in our localStorage
      const relevantIds = [];

      idsForRequest.forEach(id => {
        if (!rooms.entities[id]) {
          // it means that contactId witch was in our localStorage was removed
          // removedIds.push(id);
        }
        else {
          // this is quick hack for checking agents
          relevantIds.push(id); // removed ids does not get here
        }
      });

      const isAnyRoomWasRemoved = relevantIds.length !== idsForRequest.length;

      idsForRequest.forEach(id => {
        if (relevantIds.includes(id)) {
          sortedRelatedIds.push(id);
        }
      });

      if (isAnyRoomWasRemoved) {
        rooms = { entities: rooms.entities, ids: sortedRelatedIds };

        dispatch(updateRecentTabs(null, CHAT_TYPES.ROOM, sortedRelatedIds));
      }

      dispatch({
        type: GET_ROOMS_FROM_TABS,
        payload: {
          ...normalizeContacts(res.data, false),
          active
        }
      });
    })
    .catch(console.error);
};

export const getAllRooms = () => dispatch => {
  API.getRoomsList()
    .then(res => {
      dispatch({
        type: GET_ALL_ROOMS,
        payload: {
          chats: normalizeContacts(res.data.chats, false),
          unreadCount: res.data.unreadCount,
        }
      });
    })
    .catch(console.error);
};

export const updateRoomsList = (type, offset = 0) => dispatch => {
  API.getRoomsList(offset)
    .then(res => {
      dispatch({
        type: UPDATE_ROOMS_LIST,
        payload: normalizeContacts(res.data.chats, false),
      });
    })
    .catch(console.error);
};

export const getAllChatRooms = (filter = ALL_ROOMS_FILTER, offset = 0) => dispatch => {
  API.getChatRooms(offset)
    .then(res => {
      const norm = normalizeContacts(res.data, false);

      dispatch({
        type: FILTER_ROOMS,
        payload: { data: norm, filter }
      });
    })
    .catch(console.error);
};

export const getUnreadChats = (filter, offset = 0) => dispatch => {
  API.getUnreadChats(offset)
    .then(res => {
      const norm = normalizeContacts(res.data, false);

      dispatch({
        type: FILTER_ROOMS,
        payload: { data: norm, filter }
      });
    })
    .catch(console.error);
};

export const filterRooms = (filter, offset = 0) => dispatch => {
  dispatch({
    type: FILTER_ROOMS,
    payload: { filter }
  });
};

export const updateRoom = (room) => (dispatch) => {
  dispatch({
    type: UPDATE_ROOM,
    payload: room
  });
};

export const searchRooms = (query, cancelToken) => dispatch => {
  API.searchRooms(query, 0, cancelToken)
    .then(res => {
      dispatch({
        type: GET_SEARCH_ROOMS,
        payload: { data: res.data, query },
      });
    })
    .catch(console.error);
};

export const stopRoomsSearch = () => dispatch => {
  dispatch({
    type: STOP_SEARCH_ROOMS
  });
};

export const removeRoom = (roomId) => dispatch => {
  dispatch({
    type: REMOVE_ROOM,
    payload: roomId,
  });
};

export const updateSearchRooms = (type, offset, query) => (dispatch) => {
  API.searchRooms(query, offset)
    .then(res => {
      dispatch({
        type: UPDATE_SEARCH_ROOMS,
        payload: res.data,
      });
    })
    .catch(console.error);
};

export const addOffTodayGirls = (girls) => dispatch => {
  dispatch({
    type: ADD_OFF_TODAY_GIRLS,
    payload: girls
  });
};

export const addGirlToOffToday = id => dispatch => {
  dispatch({
    type: ADD_GIRL_TO_OFF_TODAY,
    payload: id,
  })
};

export const removeGirlFromOffToday = id => dispatch => {
  dispatch({
    type: REMOVE_GIRL_FROM_OFF_TODAY,
    payload: id,
  })
};

export const addAvailableGirls = (girls) => dispatch => {
  dispatch({
    type: ADD_AVAILABLE_GIRLS,
    payload: girls
  });
};

export const addGirlToAvailable = id => dispatch => {
  dispatch({
    type: ADD_GIRL_TO_AVAILABLE,
    payload: id,
  })
};

export const removeGirlFromAvailable = id => dispatch => {
  dispatch({
    type: REMOVE_GIRL_FROM_AVAILABLE,
    payload: id,
  })
};

export const setRoomPending = () => dispatch => {
  dispatch({ type: GET_ALL_ROOMS_PENDING });
}

const getInitialSearchQuery = () => {
  const lsSeach = LS.getItem('roomsSearch');

  return lsSeach || '';
};


const initialState = {
  entities: {
    'rooms': {
      id: 'rooms',
      chatTitle: 'rooms',
      chatType: 'rooms',
      adminsIds: [],
      unreadCount: 0
    },
    'girls': {
      id: 'girls',
      chatTitle: 'G',
      chatType: 'girls',
      adminsIds: [],
      unreadCount: 0
    },
    'webmaster': {
      id: 'webmaster',
      chatTitle: 'W',
      chatType: 'webmaster',
      adminsIds: [],
      unreadCount: 0
    },
    'bookings': {
      id: 'bookings',
      chatTitle: 'B',
      chatType: 'bookings',
      adminsIds: [],
      unreadCount: 0
    },
    'off_today': {
      id: 'off_today',
      chatTitle: 'OFF',
      chatType: 'off_today',
      adminsIds: [],
      unreadCount: 0
    },
    'notifications': {
      id: 'notifications',
      chatTitle: '!!',
      chatType: 'notifications',
      adminsIds: [],
      unreadCount: 0
    },
    'available': {
      id: 'available',
      chatTitle: '',
      chatType: 'available',
      adminsIds: [],
      unreadCount: 0
    },
    'finished': {
      id: 'finished',
      chatTitle: '',
      chatType: 'finished',
      adminsIds: [],
      unreadCount: 0
    },
    'started': {
      id: 'started',
      chatTitle: '',
      chatType: 'started',
      adminsIds: [],
      unreadCount: 0
    },
    'new_chat': {
      id: "new_chat",
      chatType: 'new_chat',
      adminsIds: [],
      chatTitle: 'New Chat',
      unreadCount: 0,
    }
  },
  ids: [],
  privateIds: [],
  activeFilter: getInitialSearchQuery()
    ? ONLINE_FILTER
    : DEFAULT_FILTER,
  search: getInitialSearchQuery(),
  searchedIds: [],
  offTodayIds: [],
  availableIds: [],
  pending: false,
  totalCount: 10,
  unreadCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_QUERY: {
      return {
        ...state,
        search: action.payload,
      }
    }
    case CLEAR_SEARCH_QUERY: {
      return {
        ...state,
        search: '',
      }
    }
    case GET_SEARCH_ROOMS: {
      const searchedData = normalizeContacts(action.payload.data, false);
      const updatedEntities = { ...state.entities, ...searchedData.entities };

      return {
        ...state,
        entities: updatedEntities,
        search: action.payload.query,
        activeFilter: ONLINE_FILTER,
        searchedIds: searchedData.ids
      };
    }

    case STOP_SEARCH_ROOMS: {
      return {
        ...state,
        search: '',
        searchedIds: []
      };
    }

    case UPDATE_SEARCH_ROOMS: {
      const searchedData = normalizeContacts(action.payload, false);
      const updatedEntities = { ...state.entities, ...searchedData.entities };

      return {
        ...state,
        entities: updatedEntities,
        searchedIds: [
          ...state.searchedIds,
          ...searchedData.ids
        ]
      };
    }

    case GET_ROOMS_FROM_TABS: {
      return {
        ...state,
        entities: addToEntitiesIfMissing(state.entities, action.payload.entities, 'id')
      };
    }

    case GET_PRIVATE_ROOMS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities
        },
        privateIds: action.payload.ids
      };
    }

    case GET_ALL_ROOMS_PENDING: {
      return {
        ...state,
        pending: true
      };
    }

    case GET_ALL_ROOMS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.chats.entities
        },
        unreadCount: action.payload.unreadCount,
        ids: action.payload.chats.ids,
        pending: false
      };
    }

    case UPDATE_ROOMS_LIST: {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.entities
        },
        ids: [
          ...state.ids,
          ...action.payload.ids
        ]
      };
    }

    case UPDATE_ACTIVE_CHAT: {
      const { chatId, activeRoomId } = action.payload;
      const isRoom = ['general', 'room'].includes(state.entities[chatId].chatType);
      const activeChat = state.entities[isRoom ? activeRoomId : chatId];

      if (!activeChat?.unreadCount) {
        return state;
      }

      const updatedEntities = { ...state.entities };

      updatedEntities[activeChat.id] = { ...activeChat, unreadCount: 0 };

      return {
        ...state,
        entities: updatedEntities,
        unreadCount: state.unreadCount - activeChat.unreadCount
      };
    }

    case NEW_INCOMING_OPERATOR_CALL:
    case NEW_INCOMING_CHAT_MESSAGE: {
      return onNewInteraction(state, action.payload.interaction, 'inc');
    }

    case NEW_OUTGOING_OPERATOR_CALL:
    case NEW_OUTGOING_CHAT_MESSAGE: {
      return onNewInteraction(state, action.payload.interaction, 'out');
    }

    case FILTER_ROOMS: {
      let updatedEntities = state.entities;

      if ([DEFAULT_FILTER, ALL_ROOMS_FILTER, SHOW_UNREAD_ROOMS].includes(action.payload.filter)) {
        updatedEntities = { ...state.entities, ...action.payload.data.entities };
      }

      return {
        ...state,
        activeFilter: action.payload.filter,
        entities: updatedEntities,
        search: ''
      };
    }

    case UPDATE_ROOM: {

      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: action.payload,
        }
      };
    }

    case REMOVE_ROOM: {
      if (!state.entities[action.payload]) {
        return state;
      }

      const filteredRoomsIds = state.ids.filter(id => id !== action.payload);
      const updatedEntities = { ...state.entities };

      delete updatedEntities[action.payload];

      return {
        ...state,
        entities: updatedEntities,
        ids: filteredRoomsIds,
      };
    }

    case CHANGE_OPERATOR_ENTITY: {
      const entity = Object.values(state.entities).find(room => room.usersIds?.includes(action.payload.id));

      if (!entity) return state;

      return {
        ...state,
        entities: {
          ...state.entities,
          [entity.id]: { ...entity, photo: action.payload.photo }
        }
      }
    }
    case ADD_OFF_TODAY_GIRLS:
    case GET_OFF_TODAY_GIRLS: {
      const updatedIds = action.payload.map(item => item.caller.id);

      return {
        ...state,
        offTodayIds: updatedIds,
        pending: false,
      };
    }

    case ADD_GIRL_TO_OFF_TODAY: {
      const isGirlAlreadyInOff = state.offTodayIds.includes(action.payload);

      if (isGirlAlreadyInOff) return state;
      
      const updatedOffGirlsIds = [
        ...state.offTodayIds,
        action.payload
      ];

      return {
        ...state,
        offTodayIds: updatedOffGirlsIds,
      };
    }

    case REMOVE_GIRL_FROM_OFF_TODAY: {
      const updatedOffGirlsIds = state.offTodayIds.filter(id => id !== action.payload);

      return {
        ...state,
        offTodayIds: updatedOffGirlsIds,
      };
    }

    case ADD_AVAILABLE_GIRLS:
    case GET_AVAILABLE_GIRLS: {
      const updatedIds = action.payload.map(item => item.caller.id);

      return {
        ...state,
        availableIds: updatedIds,
        pending: false,
      };
    }

    case ADD_GIRL_TO_AVAILABLE: {
      const isGirlAlreadyAvailable = state.availableIds.includes(action.payload);

      if (isGirlAlreadyAvailable) return state;

      const updatedAvailableGirlsIds = [
        ...state.availableIds,
        action.payload
      ];

      return {
        ...state,
        availableIds: updatedAvailableGirlsIds,
      };
    }

    case REMOVE_GIRL_FROM_AVAILABLE: {
      const updatedAvailableGirlsIds = state.availableIds.filter(id => id !== action.payload);

      return {
        ...state,
        availableIds: updatedAvailableGirlsIds,
      };
    }

    default:
      return state;
  }
};

const onNewInteraction = (state, payload, direction) => {
  const isInEntities = !!state.entities[payload.chatId];
  let unreadCount = 0;
  let updatedChatsUnreadCount = state.unreadCount;

  if (!isInEntities) {

    let updatedEntities = { ...state.entities, [payload.chatId]: payload.chat };

    const updatedIds = [payload.chatId, ...state.ids];

    const updatedEntity = { ...updatedEntities[payload.chatId] };

    if (direction === 'out') {
      updatedChatsUnreadCount -= updatedEntity.unreadCount;

      updatedEntity.unreadCount = unreadCount;
      updatedEntities[updatedEntity.id] = updatedEntity;
    }
    else {
      updatedChatsUnreadCount += updatedEntity.unreadCount;

    }

    return {
      ...state,
      entities: updatedEntities,
      ids: updatedIds,
      unreadCount: updatedChatsUnreadCount,
    };
  }
  // if in Entities
  else {
    const updatedEntities = { ...state.entities };
    const updatedEntity = { ...updatedEntities[payload.chatId], lastInteraction: payload.chat.lastInteraction };

    if (direction === 'out') {
      updatedChatsUnreadCount -= updatedEntity.unreadCount;
      unreadCount = 0;
    }
    else {
      // if (payload.type === 8) {
      //   unreadCount = updatedEntity.unreadCount;
      // }
      // else {
      updatedChatsUnreadCount += 1;
      unreadCount = updatedEntity.unreadCount + 1;
      // }
    }

    updatedEntity.unreadCount = unreadCount;
    updatedEntities[updatedEntity.id] = updatedEntity;

    const updatedIds = state.ids.filter(id => id !== payload.chatId);

    updatedIds.unshift(payload.chatId);

    return {
      ...state,
      entities: updatedEntities,
      ids: updatedIds,
      unreadCount: updatedChatsUnreadCount,
    };
  }
};

const getFilteredRoomsIds = (entities, filter, operatorsEntities) => {
  let unsortedIds = [];

  if (filter === ONLINE_FILTER) {
    const privateIds = getOnlyPrivateRoomsIds(entities);

    unsortedIds = getOnlyOnlinePrivateRoomsIds(entities, privateIds, operatorsEntities);
  }
  else if (filter === ALL_OPERATORS_FILTER) {
    unsortedIds = getOnlyPrivateRoomsIds(entities);
  }
  else if (filter === ALL_ROOMS_FILTER) {
    unsortedIds = getOnlyRoomsIds(entities);
  }
  else if (filter === SHOW_UNREAD_ROOMS) {
    unsortedIds = getUnreadCountRoomsIds(entities);
  }
  return sortContactIdsByDate(entities, unsortedIds);

};

const getOnlyPrivateRoomsIds = (entities) => {
  const privateIds = [];

  for (let key in entities) {
    if (entities[key].chatType === "private") {
      privateIds.push(+key);
    }
  }

  return privateIds;
};

const getUnreadCountRoomsIds = (entities) => {
  const unreadRoomsIds = [];

  for (let key in entities) {
    if (entities[key].unreadCount !== 0) {
      unreadRoomsIds.push(+key);
    }
  }

  return unreadRoomsIds;
};

const getOnlyRoomsIds = (entities) => {
  const roomsIds = [];

  for (let key in entities) {
    if (entities[key].chatType === "room") {
      roomsIds.push(+key);
    }
  }

  return roomsIds;
};

const getOnlyOnlinePrivateRoomsIds = (entities, privateIds, operators) => {
  const onlineIds = [];

  privateIds.map(id => {
    const operatorId = entities[id].usersIds[0];

    if (["online", "away", "busy"].includes(operators[operatorId].status)) {
      onlineIds.push(id);
    }
  });

  return onlineIds;
};
