/* eslint-disable no-case-declarations */
import { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { SOCKET_ROOT } from 'config/socket-config';
import {
  isMineInteraction,
  showNotification,
  prepareConference,
  isEmptyObj,
  convertToAudio,
  LS,
  withRouter, CS,
} from 'utils';

import {
  sendOfferToOperator,
  createPeerConnection,
  deletePC,
  killWebrtcCall,
  updateWebrtcConference,
  updateWebrtcConferenceParticipant,
  removeWebrtcParticipant,
  webrtcParticipantLeft,
  toggleWebrtcConferenceMute,
  leaveFromActiveConference,
  removeFromHoldActiveConference
} from 'redux/ducks/webrtc';

import {
  newIncomingMessage,
  newOutgoingMessage,
  changeCallStatus,
  changeMessageStatus,
  newMsgAttachments,
  removeMessageReminder,
  getContactTimeline,
  updateContactMsg,
  deleteContactMsg,
  newScheduledMsg,
  deleteTelegramMsg,
} from 'redux/ducks/clientChats';
import {
  newIncomingChatMessage,
  changeChatCallStatus,
  newOutgoingChatMessage,
  changeChatMsgStatus,
  sendDeliveredStatus,
  updateChatMsg,
  removeChatMsg,
  removeGirlsChatMsg,
  addWebmasterTask,
  removeWebmasterTask,
  updateWebmasterTask,
  completeWebmasterTask,
  uncompleteWebmasterTask,
  addNewChatRoom,
  updateActiveChat,
  updateActiveRoom,
  deleteChatMsg,
} from 'redux/ducks/roomChats';
import {
  newOutgoingCall,
  newIncomingCall,
  newActiveCall,
  finishCallOnHold,
  removeTransferedCall,
  newTransferedCall,
  newOutgoingOperatorCall,
  newIncomingOperatorCall,
  finishCall,
  newIncomingQueueCall,
  removeIncomingQueueCall,
} from 'redux/ducks/calls';
import {
  newConference,
  newWarmTransfer,
  acceptWarmTransfer,
  conferenceParticipantJoin,
  conferenceParticipantLeave,
  removeConference,
  conferenceInvitation,
  PARTICIPANT_USER,
  WARM_TRANSFER
} from 'redux/ducks/conferences';
import {
  newMissedCall,
  removeMissedCall,
  updateVoicemailStatus,
  removeMissedOperatorCallForMe,
  OPERATORS_CALL,
} from 'redux/ducks/missedCalls';
import {
  addPartnerSession,
  addSession,
  removePartnerSession,
  closePartnerSession,
  closeRemovedSession,
  partnerToggleHistorySessionSuccess,
  updateSession,
  updateSessionViewed,
  updateAssistanceSession,
  updateBooking,
  updateBookingRequest,
  deleteBookingRequest,
  updateBookingScheduledMessages,
  deleteBookingScheduledMessages,
  removeBooking,
  updateSessionBuffer,
  createSession,
  updateAdditionalFilters,
  updateActiveFiltersFromSocket,
  updateAdditionalTitleFilters,
  resetDefaultSessionFilters,
  updateActiveSession,
  normalizeSession,
  getSessionById,
  showSessionFromHistory,
} from "redux/ducks/sessions";
import {
  addNotification,
  removeNotification,
  NOTIFICATION_TYPES,
  addWidgetReminder,
  updateWebmasterNotification,
} from "redux/ducks/notifications";
import {
  newServiceMsg,
  newIncomingGroupMessage,
  newOutgoingGroupMessage,
  updateGroupMessageMedia,
  addMessageToWebmasterTask,
  removeMessageFromWebmasterTask
} from 'redux/ducks/girlChats';

import {
  openModal,
  closeModal,
  openTransferedCallModal,
  MODAL_TYPES,
  updateModalProps,
} from "redux/ducks/activeWindows";

import { timerStart, timerStop } from 'redux/ducks/timers';
import {
  changeOperatorEntity,
  changeOperatorStatus,
  addUserOnMail,
  removeUserOnMail,
} from 'redux/ducks/operators';
import {
  addNewContactToState,
  updateContactInState,
  removeContactFromState,
  updateRecentTabs,
  toggleContactActiveChat,
  updateContactFieldsById,
  addContactsToEntities,
  updateFilteredChats,
  readMessage,
  resetFiltersCounters,
  updateBookingCounter,
} from 'redux/ducks/contacts';
import {
  updateDivaGirl,
} from 'redux/ducks/divaGirls';
import {
  updateRoom,
  removeRoom,
  addGirlToOffToday,
  removeGirlFromOffToday,
  addGirlToAvailable,
  removeGirlFromAvailable,
} from 'redux/ducks/rooms';
import {
  addEmail,
  addMessageToMailChat,
  deleteMsgFromMailChat,
  deleteConversationsFromAllFolders,
  deleteConversationsFromFolder,
  markMailsNotSpam,
  undeleteMails,
  updateNotes,
} from 'redux/ducks/mail';
import { setFilterCounter } from 'redux/ducks/contacts';
import { changeLoadingStatus } from 'redux/ducks/mailing';
import { updateActiveAdrBookContactId, updateAdrBookTags } from 'redux/ducks/addressBook';
import { updateActiveContact } from 'redux/ducks/clientChats';
import {
  setUserConnectToken,
  setUserSalesPageInfo,
  setUserMainPageInfo,
  setUserAdrBookPageInfo,
  setUserMailPageInfo,
  updateUser,
  setUserCalendarPageInfo
} from 'redux/ducks/user';
import { changeTypingStatus, debouncedChangeTypingStatus } from 'redux/ducks/typingOperators';
import {
  CONTACT_TYPES,
  AUDIO_SETTINGS_TYPES,
  CHAT_TYPES,
  CONTACTS_MODES_TYPES,
  ADDITIONAL_SESSION_FILTERS,
  ROUTES,
  BOOKING_STATUSES_NAMES,
  BOOKING_STATUSES,
  INTERACTION_TYPES,
  COMMUNICATION_CHANNEL
} from '../config/constants';
import API from 'api/api';
import deleteCookie from 'utils/deleteCookie';
import { selectCanIEditSession, selectPropertyOfActiveSession } from 'redux/selectors/selectors';
import { setUpdateBookingsPendingGlobal, setBookingLog, updateBookingScheduledMessagesByBooking, deleteBooking } from 'redux/ducks/bookings';

export let webSocket;

class ChatSocket extends Component {
  state = {
    // ws: new WebSocket(SOCKET_ROOT),
    smsAudio: null,
    callAudio: null,
    uuid: uuid(),
  }

  componentDidMount() {
    // if (localStorage.connectToken) {
    webSocket = new WebSocket(`${SOCKET_ROOT}?uniqueToken=${this.state.uuid}`);
    this.setupSocket();

    this.props.setUserConnectToken(this.state.uuid);
    // }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    // const webSocket = this.state.ws;

    // webSocket.close();
  }

  setupSocket = () => {
    // const webSocket = this.state.ws;
    let incomingNotification;
    const tempRinftoneStorage = {
      ringtone: null,
      type: null,
    }

    const playCommonRingtone = (contactType, ringtoneType) => {
      const { agent, client, girl, operator, callBeep } = this.props.settings;

      switch (contactType) {
        case CONTACT_TYPES.CLIENT:
          return client[ringtoneType][ringtoneType];
        case CONTACT_TYPES.GIRL:
          return girl[ringtoneType][ringtoneType];
        case CONTACT_TYPES.AGENT:
          return agent[ringtoneType][ringtoneType];
        case CONTACT_TYPES.OPERATOR:
          return operator[ringtoneType][ringtoneType];
        case 'callBeep':
          return callBeep;
        default:
          return client[ringtoneType][ringtoneType];
      }
    };

    const playRingtone = (customRingtone, contactType, ringtoneType, fn) => {
      const isCall = ringtoneType === 'call';

      const audio = customRingtone
        ? convertToAudio(customRingtone, isCall)
        : playCommonRingtone(contactType, ringtoneType);

      let userVolume = this.props.settings.volume[ringtoneType];

      const volume = (userVolume || userVolume === 0)
        ? userVolume
        : 100;

      if (!audio) return;

      audio.volume = volume / 100;

      if (isCall) {
        if (this.state.callAudio && !this.state.callAudio.paused) {
          this.state.callAudio.pause();
          this.state.callAudio.currentTime = 0;
        }

        this.setState({ callAudio: audio }, () => {
          this.state.callAudio.play();

          fn && fn();
        });
      }
      else {
        if (this.state.smsAudio && !this.state.smsAudio.paused) {
          this.state.smsAudio.pause();
          this.state.smsAudio.currentTime = 0;
        }

        this.setState({ smsAudio: audio }, () => {

          this.state.smsAudio.play();

          fn && fn();
        });
      }
    };

    const prepareRingtone = (ringtone, contactType, ringtoneType) => {
      const isCall = ringtoneType === 'call';

      const audio = ringtone
        ? convertToAudio(ringtone, isCall)
        : playCommonRingtone(contactType, ringtoneType);

      const userVolume = LS.getItem('notificationsVolume', this.props.user.id);

      const volume = userVolume || userVolume === 0
        ? userVolume
        : 100;

      audio.volume = volume / 100;

      return audio;
    }

    const isContactsSmsAllowed = (contactType) => {
      if (this.props.user?.mode?.is_sms_active) {
        return !!this.props.user.mode.sms?.[CONTACTS_MODES_TYPES[contactType]];
      }
      return false;
    };

    const playBookingOrSmsRingtone = (data) => {
      if (data.isBooking) {
        isContactsSmsAllowed(data.message.caller.type) && playRingtone(data.ringtone, data.message.caller.type, 'booking');
      } else {
        if(isContactsSmsAllowed(data.message.caller.type)) {
          +data.message.caller.type === CONTACT_TYPES.CLIENT
            ? playRingtone(data.ringtone, data.message.caller.type, 'clientSms')
            : playRingtone(data.ringtone, data.message.caller.type, 'sms')
        }
        isContactsSmsAllowed(data.message.caller.type) && playRingtone(data.ringtone, data.message.caller.type, 'sms');
      }
    };

    webSocket.onopen = () => {
      console.log('ChatWebSocket connected!)');

      if (window.location.pathname === ROUTES.sales) {
        const message = {
          type: 'system_message',
          data: {
            token: this.state.uuid,
            on_sales_page: 1,
          },
        };

        webSocket.send(JSON.stringify(message));
      }

      if (window.location.pathname === ROUTES.adrBook) {
        const message = {
          type: 'system_message',
          data: {
            token: this.state.uuid,
            on_address_book_page: 1,
          },
        };

        webSocket.send(JSON.stringify(message));
      }

      if (window.location.pathname.split('/').includes('mail')) {
        const message = {
          type: 'system_message',
          data: {
            token: this.state.uuid,
            on_mail_page: 1,
          },
        };

        webSocket.send(JSON.stringify(message));
      }

      if (window.location.pathname.split('/').includes('calendar')) {
        const message = {
          type: 'system_message',
          data: {
            token: this.state.uuid,
            on_calendar_page: 1,
          },
        };

        webSocket.send(JSON.stringify(message));
      }
    };

    webSocket.onclose = () => {
      console.log('ChatWebSocket disconnected!');

      if (process.env.NODE_ENV !== 'development' || window?.location?.hostname !== 'localhost') {
        deleteCookie('isAuthenticated');

        delete localStorage.isLoggedIn;
        delete localStorage.currentUserId;

        sessionStorage.setItem('lastInteractionPage', window.location.pathname);

        window.location = '/client/login';
      }

      // this.setupSocket();

      // delete localStorage.connectToken;
      // delete localStorage.user;
      // window.location = '/client/login';
    };

    webSocket.onerror = (err) => {
      console.log('ChatWebSocket error: ', err);
    };

    webSocket.onmessage = (e) => {

      const communicationChannelByType = Object.entries(this.props.user.mode.listen).reduce((acc, [name, value]) => {
        if (name === COMMUNICATION_CHANNEL.dinstar) {
          return acc = {...acc, [INTERACTION_TYPES.INCOMING_MSG_DINSTAR]: !!value}
        } else if (name === COMMUNICATION_CHANNEL.imessage) {
          return acc = {...acc, [INTERACTION_TYPES.INCOMING_MSG_IPHONE_IMESSAGE]: !!value}
        } else if (name === COMMUNICATION_CHANNEL.tgbot) {
          return acc = {...acc, [INTERACTION_TYPES.INCOMING_MSG_TELEGRAM]: !!value}
        } else if (name === COMMUNICATION_CHANNEL.tgclient) {
          return acc = {...acc, [INTERACTION_TYPES.INCOMING_PRIVATE_MSG_TELEGRAM]: !!value}
        } else {
          return acc = {...acc, [INTERACTION_TYPES.INCOMING_MSG_WHATSAPP]: !!value}
        }

      }, {})

      const { data, type } = JSON.parse(e.data);

      const currentUser = this.props.user;

      console.log('WebSocket event happen!:', 'type:', type, ', data:', data);

      switch (type) {
        case 'telegram_client': {
          switch (data.type) {
            case 'incoming-group-message': {
              this.props.newIncomingGroupMessage(data, currentUser.timezone.offset_minutes);
              break;
            }
            case 'outgoing-group-message': {
              this.props.newOutgoingGroupMessage(data, currentUser.timezone.offset_minutes);
              break;
            }
            case 'incoming-group-message-media': {
              this.props.updateGroupMessageMedia(data);
              break;
            }
            case 'outgoing-group-message-media': {
              this.props.updateGroupMessageMedia(data);
              break;
            }
          }
        }
        case 'new_message': {
          switch (data.type) {

            case 'outgoing':
              this.props.newOutgoingMessage({ ...data.message, currentUser: currentUser.id }, currentUser.timezone.offset_minutes);

              this.props.changeTypingStatus({
                id: data.message.caller_id,
                type: data.message.caller.type,
                userId: data.message.user_id,
              }, false)

              this.props.updateContactInState(data.message.caller, data.message.caller.type);
              this.props.toggleContactActiveChat({ contact: data.message.caller, currentUserId: currentUser.id });

              if (this.props.activeModals.length && this.props.activeModals[0].type === MODAL_TYPES.multiMessageModal && data.userId !== currentUser.id) {
                this.props.changeLoadingStatus(data.message.caller.id, data.message.status);
              }

              break;
            case 'incoming':
              const isIamRelated = !data.message.caller.relatedUserId || (data.message.caller.relatedUserId === currentUser.id);
              
              if (
                data.message.caller.relatedUserId !== currentUser.id &&
                data.message.caller.type === CONTACT_TYPES.CLIENT &&
                !communicationChannelByType[data.message.type]
              ) {
                break;
              }
              const isTelegramBotMsg = data.message.type === INTERACTION_TYPES.INCOMING_NIGHT_BOT_MESSAGE || data.message.type === INTERACTION_TYPES.OUTGOING_NIGHT_BOT_MESSAGE;

              if (isIamRelated && isEmptyObj(this.props.activeCall) && !isTelegramBotMsg) {
                switch (data.message.caller.audio_status) {
                  case AUDIO_SETTINGS_TYPES.MUTE: break;
                  case AUDIO_SETTINGS_TYPES.IMPORTANT:

                    if (isEmptyObj(this.props.activeCall)) {
                      playBookingOrSmsRingtone(data);
                    }

                    this.props.updateContactInState({ ...data.message.caller, audio_status: AUDIO_SETTINGS_TYPES.NORMAL, withImportantUnread: true }, data.message.caller.type);
                    break;
                  default:
                    if (isEmptyObj(this.props.activeCall)) {
                      playBookingOrSmsRingtone(data);
                    }
                    break;
                }
              }

              if (data.message && data.message.body) {
                isContactsSmsAllowed(data.message.caller.type) && showNotification(data.message, () => {
                  this.props.updateActiveContact(data.message.caller);

                  if (this.props.location.pathname !== '/') {
                    this.props.navigate('/');
                  }
                });
              }

              this.props.newIncomingMessage(
                { ...data.message, isIamRelated: true, currentUser: currentUser.id },
                currentUser.timezone.offset_minutes
              );

              this.props.updateContactInState(data.message.caller, data.message.caller.type);
              this.props.toggleContactActiveChat({ contact: data.message.caller, currentUserId: currentUser.id });
              break;
            case 'scheduled':
              this.props.newScheduledMsg(data.message, currentUser.timezone.offset_minutes);

              if (data.message.session_id) {
                this.props.updateBookingScheduledMessages(data.message.session_id, data.message.booking_id, data.message);
                this.props.updateBookingScheduledMessagesByBooking(data.message.booking_id, data.message);
              }
              break;
            case 'attachments':
              this.props.newMsgAttachments(data.message);
              break;
            case 'service':
              this.props.newServiceMsg(data.message, currentUser.timezone.offset_minutes);
              break;
            default:
              break;
          }

          break;
        }

        case 'interaction_update': {
          switch (data.type) {
            case 'message_status':
              this.props.changeMessageStatus(data);
              break;
            case 'voicemail_status':
              this.props.updateVoicemailStatus(data);
              break;
            case 'update':
              this.props.updateContactMsg(data.message);

              //scheduled msg
              if (data.message.session_id && data.message.type === 11) {
                this.props.updateBookingScheduledMessages(data.message.session_id, data.message.booking_id, data.message);
                this.props.updateBookingScheduledMessagesByBooking(data.message.booking_id, data.message);
              }
              break;
            case 'delete':
              this.props.deleteContactMsg(data.message);

              //scheduled msg
              if (data.message.session_id && data.message.type === 11) {
                this.props.deleteBookingScheduledMessages(data.message.session_id, data.message.booking_id, data.message.id);
              }
              break;
            default:
              break;
          }

          break;
        }

        case 'outgoing_message_status': {
          this.props.changeMessageStatus(data);

          if (this.props.activeModals.length && this.props.activeModals[0].type === MODAL_TYPES.multiMessageModal && data.userId !== currentUser.id) {
            this.props.changeLoadingStatus(data.caller_id, data.status, data.id);
          }

          break;
        }

        case 'new_call': {
          switch (data.callType) {
            case 'incoming':
            case 'ipIncoming': {
              const isSoftPhone = data.callType === 'ipIncoming';
              const isMine = isMineInteraction(currentUser.id, data.call.user_id);
              const isIamRelated = !data.call.caller.relatedUserId || data.call.caller.relatedUserId === currentUser.id;
              const incomingCall = { ...data.call, isMine, isIamRelated, isSoftPhone, currentUser: currentUser.id };

              this.props.newIncomingCall(incomingCall, currentUser.timezone.offset_minutes);

              if (isMine) {
                showNotification(data.call, () => this.props.updateActiveContact(data.call.caller))
                  .then(res => incomingNotification = res);

                this.props.updateActiveContact(data.call.caller);

                if (!isSoftPhone) {
                  playRingtone(
                    data.ringtone,
                    data.call.caller.type,
                    'call',
                    () => this.props.openModal(MODAL_TYPES.call, { contact: data.call.caller, activeCall: incomingCall })
                  );
                } else {
                  this.props.openModal(MODAL_TYPES.call, { contact: data.call.caller, activeCall: incomingCall });
                }
              }

              this.props.updateContactInState(data.call.caller, data.call.caller.type);
              this.props.toggleContactActiveChat({ contact: data.call.caller, currentUserId: currentUser.id });
              break;
            }

            case 'outgoing':
            case 'ipOutgoing': {
              const isMine = isMineInteraction(currentUser.id, data.call.user_id);
              const isSoftPhone = data.callType === 'ipOutgoing';

              if (!isSoftPhone) {
                this.props.newOutgoingCall({ ...data.call, isMine, currentUser: currentUser.id }, currentUser.timezone.offset_minutes);

                if (data.call.status === "in-progress") { // when the conference turns into an outgoing call
                  this.props.timerStart(data.call.id);
                }
              }

              this.props.updateActiveContact(data.call.caller);
              this.props.updateContactInState(data.call.caller, data.call.caller.type);
              this.props.toggleContactActiveChat({ contact: data.call.caller, currentUserId: currentUser.id });
              break;
            }

            case 'missed': {
              this.props.newMissedCall(data.call);
              break;
            }

            case 'incoming_queue': {
              this.props.newIncomingQueueCall({ ...data.call, status: "in-queue", currentUser: currentUser.id });

              this.props.updateActiveContact(data.call.caller);

              tempRinftoneStorage.ringtone = data.ringtone;
              tempRinftoneStorage.type = data.call.type;
              break;
            }

            case 'incoming_from_queue': {
              playRingtone(tempRinftoneStorage.ringtone, tempRinftoneStorage.type, 'call');

              this.props.openModal(MODAL_TYPES.incomingQueueCall, { callId: data.callId });

              break;
            }

            case 'transfer': {
              this.props.newTransferedCall(data.call);

              if (data.call.queueData && data.call.queueData.recipient && data.call.queueData.recipient.id === currentUser.id) {
                playRingtone(
                  data.ringtone,
                  data.call.caller.type,
                  'call',
                  () => this.props.openTransferedCallModal(data.call, this.state.callAudio),
                );
              }
              break;
            }

            default:
              break;
          }

          break;
        }

        case 'ip_call_status': {
          const isMine = isMineInteraction(currentUser.id, data.user_id);

          if (data.status !== 'completed') {
            this.props.newMissedCall({ ...data, isSoftPhone: true });
          }

          if (isMine) {
            this.props.finishCall();
          }

          this.props.updateContactInState(data.caller, data.caller.type);
          this.props.removeIncomingQueueCall(data.id);
          this.props.closeModal(MODAL_TYPES.call);
          break;
        }

        case 'outgoing_ip_call_finished': {
          const isMine = isMineInteraction(currentUser.id, data.user_id);

          if (isMine) {
            this.props.closeModal(MODAL_TYPES.call);
          }

          this.props.updateContactInState(data.caller, data.caller.type);
          break;
        }

        case 'call_status': {
          const isMine = isMineInteraction(currentUser.id, data.userId);
          const isOutgoingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.outgoingCall);
          const isIncomingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingCall);

          if (isMine) {
            if (data.type === 1 && ['initiated', 'ringing'].indexOf(data.status) === -1) {
              if (this.state.callAudio && !this.state.callAudio.paused) {
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }

              if (incomingNotification) {
                incomingNotification.close();
                incomingNotification = null;
              }
            }
            if (data.prevStatus === 'ringing' && data.status === 'in-progress') {
              // start main timer for call
              this.props.timerStart(data.callId);

              if (isIncomingCallModal) {
                this.props.closeModal(MODAL_TYPES.incomingCall);
              }
              else if (isOutgoingCallModal) {
                this.props.closeModal(MODAL_TYPES.outgoingCall);
              }
            }
            else if (data.status === "completed") {
              // stop all timers of completed call
              if (this.props.callsOnHold[data.callId]) {
                this.props.finishCallOnHold(data.callId);
              }
              else if (this.props.callsOnTransfer[data.callId]) {
                this.props.removeTransferedCall(data.callId);
                this.props.closeModal(MODAL_TYPES.transferedCall);
              }
              if (this.props.timers[data.callId]) {
                this.props.timerStop(data.callId);
              }
            }
          }
          // not mine calls
          else {
            if (data.prevStatus === 'on-transfer') {
              this.props.removeTransferedCall(data.callId);
              this.props.closeModal(MODAL_TYPES.transferedCall);

              if (this.state.callAudio && !this.state.callAudio.paused) {
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }
            }
            if (data.prevStatus === 'no-answer' && data.status === 'no-answer') {
              this.props.removeMissedCall(data.callerId, 1);
            }
            if (data.prevStatus === 'in-queue') {
              const incomingQueueCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingQueueCall) || {};

              if (this.state.callAudio && !this.state.callAudio.paused) {
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }

              this.props.removeIncomingQueueCall(data.callId);

              if (incomingQueueCallModal.callId === data.callId) {
                this.props.closeModal(MODAL_TYPES.incomingQueueCall);
              }
            }
          }
          this.props.changeCallStatus({ ...data, isMine });
          break;
        }

        // Operators sockets
        case 'new_user_message': {
          switch (data.type) {
            case 'message': {
              if (data.message.interactionType === 'system_msg') {

                if (isEmptyObj(this.props.activeCall && data.status !== 'mute')) {
                  playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'sms');
                }

                this.props.newIncomingChatMessage(
                  { ...data.message, type: 8 },
                  currentUser.timezone.offset_minutes
                );

                if (
                  data.message.attachments &&
                  data.message.attachments.chatUpdate.action === 'chatCreated' &&
                  data.message.attachments.chatUpdate.userId === currentUser.id
                ) {
                  // this.props.updateActiveChat(data.message.chatId, false);
                  this.props.updateActiveRoom(data.message.chatId)
                  break;
                }

                // TODO: refactor. This action update room photo and another actions in system_msg
                this.props.updateRoom(data.message.chat);

                break;
              }

              if (isMineInteraction(currentUser.id, data.message.senderId)) {
                this.props.newOutgoingChatMessage(
                  { ...data.message, type: 3 },
                  currentUser.timezone.offset_minutes
                );

                if (data.message.is_telegram) {
                  this.props.updateActiveChat('rooms');
                }

                break;
              }

              if (isEmptyObj(this.props.activeCall)) {
                switch (data.message.caller?.audio_status) {
                  case AUDIO_SETTINGS_TYPES.MUTE: break;
                  case AUDIO_SETTINGS_TYPES.IMPORTANT:
                    if (isEmptyObj(this.props.activeCall)) {
                      playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'sms');
                    }

                    this.props.updateRoom({ ...data.message.chat, audio_status: AUDIO_SETTINGS_TYPES.NORMAL, withImportantUnread: true });

                    break;
                  default:
                    if (isEmptyObj(this.props.activeCall)) {
                      playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'sms');
                    }
                    break;
                };
              }

              this.props.newIncomingChatMessage(
                { ...data.message, type: 4 },
                currentUser.timezone.offset_minutes
              );

              this.props.changeTypingStatus({
                id: data.message.chatId,
                type: 3,
                userId: data.message.senderId || data.message.userId,
              }, false);

              if (this.props.activeChatId === data.message.chatId) {
                sendDeliveredStatus(data.message.id);
              }

              if (data.message.is_telegram) {
                this.props.updateActiveChat('rooms');
              }
              break;
            }

            case 'attachments':
              this.props.newMsgAttachments(data.message);
              break;
            default:
              break;
          }

          break;
        }
        case 'new_notification': {

          switch (data.type) {
            case NOTIFICATION_TYPES.conferenceInvitation: {
              this.props.addNotification(data);
              break;
            }

            case NOTIFICATION_TYPES.nightBotReject: {
              this.props.addNotification(data);
              break;
            }

            case NOTIFICATION_TYPES.coldTransfer:
            case NOTIFICATION_TYPES.parkingCall: {
              // const userId = data.notification.call.user.id;
              const userId = data.userId;
              const isCurrentUser = currentUser.id === userId;

              !isCurrentUser && this.props.addNotification({
                ...data,
                duration: 30,
              });
              break;
            }

            case NOTIFICATION_TYPES.messageReminder: {
              const userId = data.notification.reminder.user_id;
              const isCurrentUser = currentUser.id === userId;

              if (isCurrentUser) {
                this.props.addNotification({
                  ...data.notification,
                  color: 'blue',
                });

                data.notification.reminder.message.caller && this.props.removeMessageReminder(
                  data.notification.reminder.id,
                  data.notification.reminder.message.caller.type,
                  false
                );
                //remove from reminders-timeline as expired and done
              }

              this.props.addWidgetReminder(data.notification);
              break;
            }

            // case NOTIFICATION_TYPES.warmTransfer: {
            //   const userId = data.notification.conference.creator_id;
            //   const isCurrentUser = currentUser.id !== userId;

            //   !isCurrentUser && this.props.addNotification({
            //     ...data,
            //     duration: 5,
            //   })
            //   break;
            // }
            case NOTIFICATION_TYPES.mention: {
              const isCurrentUser = currentUser.id === data.notification.user_id;

              isCurrentUser &&
                this.props.addNotification({
                  ...data.notification,
                  color: 'blue',
                });
                this.props.addWidgetReminder(data.notification);
              break;
            }
            case NOTIFICATION_TYPES.bufferedProfileBecomeOff: {
              this.props.addNotification({
                ...data,
                color: 'red',
              });
              break;
            }
            case NOTIFICATION_TYPES.addTelegramChannel:
            case NOTIFICATION_TYPES.changeContactNumber: {
              this.props.addNotification({
                ...data,
                color: 'red',
              });
              break;
            }

            case NOTIFICATION_TYPES.sessionChanged: {
              this.props.addNotification({
                ...data.notification,
                color: 'red',
              });

              this.props.addWidgetReminder(data.notification);
              break;
            }

            case NOTIFICATION_TYPES.sessionView: {
              const {
                prev: leftSession,
                current: viewedSession,
              } = data.data;


              const aSession = this.props.activeSession;

              const isItMySession = (session) => session && session.usersIds.includes(currentUser.id);
              const isLeftMySession = leftSession && isItMySession(leftSession);
              const isViewedMySession = viewedSession && isItMySession(viewedSession);

              if (isLeftMySession) {
                this.props.removeNotification(data.type + '_' + leftSession.userId);

                this.props.updateSessionViewed(leftSession.id, leftSession.viewed);
              }
              if (isViewedMySession) {
                // const isActiveSession = viewedSession.id === aSession;

                this.props.addNotification({
                  ...data,
                  color: 'blue',
                });
                this.props.updateSessionViewed(viewedSession.id, viewedSession.viewed);
              }

              this.props.addWidgetReminder(data.notification);
              break;
            }
            case NOTIFICATION_TYPES.sessionProposed: {
              const notificationData = data.data;
              this.props.updateAssistanceSession(notificationData.session);

              const userId = notificationData.userId;
              const proposedIds = notificationData.session.proposed.byOperatorId[userId];

              const isAdding = proposedIds && proposedIds.length;

              if (isAdding) {
                this.props.addNotification({
                  ...data,
                });
              }
              else {
                this.props.removeNotification(data.type + '_' + userId);
              }
              break;
            }
            case NOTIFICATION_TYPES.sessionAssistanceRequest: {
              this.props.addNotification({
                ...data,
                color: 'orange',
              });
              this.props.openModal(MODAL_TYPES.transferredOrAssistanceSession, {
                callerId: data.data.callerId,
                sessionId: data.data.session_id,
                userId: data.data.userId,
                type: data.data.type,
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionAssistanceDelete: {
              this.props.addNotification({
                ...data,
                color: 'yellow',
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionAssistanceResponse: {
              const notificationData = data.data;

              this.props.addNotification({
                ...data,
                color: notificationData.isAccept
                  ? 'green'
                  : 'yellow',
                isAccept: data.isAccept,
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionTransferRequest: {
              this.props.addNotification({
                ...data,
                color: 'orange',
              });

              this.props.openModal(MODAL_TYPES.transferredOrAssistanceSession, {
                callerId: data.data.callerId,
                sessionId: data.data.session_id,
                userId: data.data.userId,
                type: data.data.type,
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionTransferResponse: {
              const notificationData = data.data;

              this.props.addNotification({
                ...data,
                color: notificationData.isAccept
                  ? 'green'
                  : 'yellow',
                isAccept: notificationData.isAccept,
              });
              break;
            }
            case NOTIFICATION_TYPES.missedClientCall: {
              this.props.updateActiveChat('notifications');

              this.props.addNotification({
                ...data,
                isHighlighted: true,
              });
              break;
            }
            case NOTIFICATION_TYPES.missedSessionClientCall: {
              this.props.updateActiveChat('notifications');

              this.props.addNotification({
                ...data,
                isHighlighted: true,
              });
              break;
            }
            case NOTIFICATION_TYPES.longSession: {
              this.props.addNotification({
                ...data,
                isCustom: true,
              });
              break;
            }
            case NOTIFICATION_TYPES.clientTextedInSession: {
              this.props.addNotification({
                ...data,
              })
              break;
            }
            case NOTIFICATION_TYPES.profileTextedInSession: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            // case NOTIFICATION_TYPES.newRelatedMessage: {
            //   this.props.addNotification({
            //     ...data,
            //   });
            //   break;
            // }
            case NOTIFICATION_TYPES.partnerHasntReactTo: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            // case NOTIFICATION_TYPES.partnerHasBookedProfileInSession: {
            //   this.props.addNotification({
            //     ...data,
            //   });
            //   break;
            // }
            case NOTIFICATION_TYPES.undeliveredMessage: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            case NOTIFICATION_TYPES.sessionUsingReminder: {

              this.props.addNotification({
                ...data,
              });
              break;
            }
            case NOTIFICATION_TYPES.newMail: {
              this.props.addNotification({
                ...data,
              });
              break;
            }
            default:
              break;
          }

          break;
        }

        case 'user_message_update': {
          switch (data.type) {
            case 'status':
              this.props.changeChatMsgStatus(data.chatId);
              break;
            case 'update':
              this.props.updateChatMsg(data);
              break;
            case 'remove':
              this.props.removeChatMsg(data);
              break;
            case 'hide-message':
              this.props.removeGirlsChatMsg(data.interaction_id);
              break;
            default:
              break;
          }
          break;
        }

        case 'webmaster_task': {
          switch (data.type) {
            case 'add':
              this.props.addWebmasterTask(data.message);

              if (data.message.hasOwnProperty('body')) {
                this.props.addMessageToWebmasterTask(data.message.id, data.message.webMasterTask);
              }
              // this.props.deleteChatMsg(data);
              break;
            case 'update':
              this.props.updateWebmasterTask(data.message);
              this.props.updateWebmasterNotification(data.message);
              break;
            case 'remove':
              this.props.removeWebmasterTask(data.message);

              if (data.message.hasOwnProperty('body')) {
                this.props.removeMessageFromWebmasterTask(data.message.id, data.message.webMasterTask);
              }
              break;
            case 'complete':
              this.props.completeWebmasterTask(data.message);
              break;
            case 'uncomplete':
              this.props.uncompleteWebmasterTask(data.message);
              break;
            default:
              break;
          }
          break;
        }

        case 'new_user_call': {
          const isMine = isMineInteraction(currentUser.id, data.senderId);

          if (isMine) {
            this.props.newOutgoingOperatorCall(
              { ...data, type: 2, isMine: true },
              currentUser.timezone.offset_minutes
            );

            playRingtone(data.ringtone, 'callBeep', 'call');
            break;
          }
          this.props.newIncomingOperatorCall(
            { ...data, type: 1, isMine: false },
            currentUser.timezone.offset_minutes
          );

          playRingtone(data.ringtone, CONTACT_TYPES.OPERATOR, 'call');
          break;
        }

        case 'user_call_status': {
          const isMine = isMineInteraction(currentUser.id, data.senderId);
          const isOutgoingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.outgoingCall);
          const isIncomingCallModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.incomingCall);

          if (['initiated', 'ringing'].indexOf(data.status) === -1) {
            if (this.state.callAudio && !this.state.callAudio.paused) {
              this.state.callAudio.pause();
              this.state.callAudio.currentTime = 0;
            }
          };

          if (data.status === 'connecting') {
            if (isMine) {
              const localPC = this.props.createPeerConnection(data.recipientId, this.props.localStream, true, this.props.activeCall.id);

              sendOfferToOperator(data.recipientId, localPC);
            }
            else {
              this.props.removeMissedOperatorCallForMe(data.senderId);
            }

            if (isIncomingCallModal) {
              this.props.closeModal(MODAL_TYPES.incomingCall);
            }
            else if (isOutgoingCallModal) {
              this.props.closeModal(MODAL_TYPES.outgoingCall);
            }
          }
          else if (data.status === 'in-progress') {
            this.props.timerStart(data.id);
          }
          else if (data.status === 'completed') {
            this.props.timerStop(data.id);

            const operatorId = isMine ? data.recipientId : data.senderId;
            this.props.killWebrtcCall(operatorId, this.props.peerConnections[operatorId], this.props.localStream);
            playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
          }
          else if (data.status === 'no-answer') {
            if (isMine) {
              this.props.killWebrtcCall(data.recipientId, this.props.peerConnections[data.recipientId], this.props.localStream);
              playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
            }
            else {
              this.props.newMissedCall(data, OPERATORS_CALL);
            }
          }
          else if (data.status === 'failed') {
            this.props.openModal(MODAL_TYPES.error, { text: 'Connection error' });

            const operatorId = isMine ? data.recipientId : data.senderId;
            this.props.killWebrtcCall(operatorId, this.props.peerConnections[operatorId], this.props.localStream);
            playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
          }

          this.props.changeChatCallStatus({ ...data, isMine });

          break;
        }

        case 'update_user_data':
        case 'change_user_data': {
          const isCurrentUser = currentUser.id === data.id;

          if (!isCurrentUser && data.status !== this.props.operators[data.id]?.status) {
            this.props.addNotification({
              color: 'yellow',
              data: { user_id: data.id },
              type: NOTIFICATION_TYPES.changeOperatorStatus,
              id: NOTIFICATION_TYPES.changeOperatorStatus + '_' + data.id, // notification don`t save, so we set id manually
              duration: 5,
              status: data.status,
              isCustom: true,
            });
            this.props.changeOperatorStatus(data);
          }
          else if (isCurrentUser) {
            if (data.status === 'offline') {
              sessionStorage.setItem('lastInteractionPage', window.location.pathname);

              return window.location = ROUTES.login;
            }

            this.props.updateUser({ ...data });

            if (data.isSystemAway) {
              let messageSound = new Audio('./media/sounds/operators.mp3');
              messageSound.play();

              this.props.openModal(MODAL_TYPES.awaySystem);
              this.props.resetDefaultSessionFilters();
            } else if (!data.isSystemAway && data.status === "online") {
              this.props.closeModal(MODAL_TYPES.awaySystem);
            }
            break;
          }
          this.props.changeOperatorEntity(data);
          break;
        }

        case 'logout_inactive_user': {
          deleteCookie('isAuthenticated');
          delete localStorage.isLoggedIn;
          delete localStorage.currentUserId;
          sessionStorage.setItem('lastInteractionPage', window.location.pathname);
          window.location = '/client/login';
          break;
        }

        case 'user_start_typing':
        case 'user_start_typing_mail': {
          const target = type === 'user_start_typing' ? 'chats' : 'mails';

          if (data.userId !== currentUser.id) {
            if (data.hasOwnProperty('typing') && !JSON.parse(data.typing)) {
              this.props.changeTypingStatus(data, false, target);
            }
            else {
              this.props.changeTypingStatus(data, true, target);
              this.props.debouncedChangeTypingStatus(data, null, target);
            }
          }

          if (data.hasOwnProperty('typing') && !JSON.parse(data.typing) && typeof data.text === 'string') {
            this.props.updateContactFieldsById(data.id, { draft_message: data.text })
          }
          break;
        }

        case 'conference': {
          switch (data.type) {
            case 'warm_transfer_start': { // when I am creator of warm transfer
              const conference = prepareConference(data.conference);

              this.props.acceptWarmTransfer(conference);

              this.props.timerStart('conf_' + conference.id, conference.date_created);
              break;
            }

            case 'warm_transfer_invitation': { // second user in warm transfer 
              const conference = prepareConference(data.conference);

              playRingtone(
                data.ringtone,
                CONTACT_TYPES.CLIENT,
                'call',
                () => this.props.openTransferedCallModal(conference, this.state.callAudio),
              );

              this.props.newWarmTransfer(conference);
              this.props.timerStart('conf_' + conference.id, conference.date_created);

              break;
            }

            case 'invitation_canceled': {
              const isTansferedCallModal = !!this.props.activeModals.find(modal => modal.type === MODAL_TYPES.transferedCall);
              const conferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode) || {};

              // if I cancel invitation to conference
              if (data.participant.type === PARTICIPANT_USER && data.participant.user_id === currentUser.id) {
                this.props.removeConference(data.participant.conference_id);

                if (isTansferedCallModal) {
                  this.props.closeModal(MODAL_TYPES.transferedCall);
                }
                else if (conferenceModal.conferenceId === data.participant.conference_id) {
                  this.props.closeModal(MODAL_TYPES.conferenceFullMode);
                }

                this.props.timerStop('conf_' + data.participant.conference_id);
              }
              // when invited operator cancel invitation to warm transfer => remove conference to avoid blinks before it turns to outgoing call
              else if (data.participant.conference_id === this.props.activeConference.id
                && this.props.activeConference.type === WARM_TRANSFER) {
                this.props.removeConference(data.participant.conference_id);

                if (isTansferedCallModal) {
                  this.props.closeModal(MODAL_TYPES.transferedCall);
                }
                else if (conferenceModal.conferenceId === data.participant.conference_id) {
                  this.props.closeModal(MODAL_TYPES.conferenceFullMode);
                }

                this.props.timerStop('conf_' + data.participant.conference_id);
              }
              // if user cancel invitation to conference => update his status in conferenceFullMode
              else {
                this.props.conferenceParticipantJoin(data.participant);
              }
              break;
            }

            case 'participant_invited':
            case 'participant-join': {
              this.props.conferenceParticipantJoin(data.participant);
              break;
            }
            case 'participant-leave': {
              if (data.participant.type === PARTICIPANT_USER && data.participant.user_id === currentUser.id) {
                const isTansferedCallModal = !!this.props.activeModals.find(modal => modal.type === MODAL_TYPES.transferedCall);
                const conferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode) || {};

                this.props.removeConference(data.participant.conference_id);

                if (isTansferedCallModal) {
                  this.props.closeModal(MODAL_TYPES.transferedCall);
                }
                else if (conferenceModal.conferenceId === data.participant.conference_id) {
                  this.props.closeModal(MODAL_TYPES.conferenceFullMode);
                }

                this.props.timerStop('conf_' + data.participant.conference_id);
              }
              this.props.conferenceParticipantLeave(data.participant);
              break;
            }

            case 'completed': {
              const isTansferedCallModal = !!this.props.activeModals.find(modal => modal.type === MODAL_TYPES.transferedCall);
              const conferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode) || {};

              if (this.state.callAudio && !this.state.callAudio.paused) {// if caller drop the phone while warm-transfer
                this.state.callAudio.pause();
                this.state.callAudio.currentTime = 0;
              }

              this.props.removeConference(data.conference.id);

              if (isTansferedCallModal) {
                this.props.closeModal(MODAL_TYPES.transferedCall);
              }
              else if (conferenceModal.conferenceId === data.conference_id) {
                this.props.closeModal(MODAL_TYPES.conferenceFullMode);
              }

              this.props.timerStop('conf_' + data.conference.id);
              break;
            }

            case 'new_conference': {
              const conference = prepareConference(data.conference);
              const isConferenceModal = this.props.activeModals.find(modal => modal.type === MODAL_TYPES.conferenceFullMode);

              this.props.timerStart('conf_' + conference.id, conference.date_created);

              this.props.newConference(conference); // you create new conference

              if (!isConferenceModal) {
                this.props.openModal(MODAL_TYPES.conferenceFullMode, { conferenceId: conference.id });
              }
              break;
            }

            case 'conference_invitation': { // second user in warm transfer or conference
              const conference = prepareConference(data.conference);

              this.props.timerStart('conf_' + conference.id, conference.date_created);

              this.props.conferenceInvitation(conference);
              this.props.openTransferedCallModal(conference, this.state.callAudio);

              // callAudio.play();   //TODO: now this is working different
              break;
            }

            default:
              break;
          }

          break;
        }

        // Synchronization of operators work
        case 'contacts_sync': {
          switch (data.type) {
            case 'create':
              this.props.addNewContactToState(data.contact);
              break;
            case 'related_user_update':
            case 'update': {
              const { initialType, contact, info, oldRelatedUser } = data;

              const isGirl = (type) => [2, 3, 4].includes(type); // agent, girl ...

              const recentTabs = LS.getItem(
                initialType === CONTACT_TYPES.CLIENT
                  ? 'clientChatsRecentTabs'
                  : 'girlChatsRecentTabs'
                ,
                currentUser.id);

              let isTypeChanged = initialType && contact.type !== initialType;

              if (initialType && isGirl(initialType) && isGirl(contact.type)) {
                isTypeChanged = false;
              }

              if (isTypeChanged && recentTabs) {
                const relevantTabs = recentTabs.all.filter(tab => tab !== contact.id);

                this.props.updateRecentTabs(null, initialType, relevantTabs);
              }

              this.props.updateContactInState(contact, initialType, isTypeChanged, currentUser.id);

              this.props.updateFilteredChats(contact, info, currentUser.id, oldRelatedUser, data.hasPrebooking);

              const chatType = contact.type === 1
                ? CHAT_TYPES.CLIENT
                : CHAT_TYPES.GIRL;

              if (data.isMerge && (this.props.activeClientChatId === contact.id || this.props.activeGirlChatId === contact.id)) {
                this.props.getContactTimeline(contact.id, chatType, currentUser.timezone.offset_minutes);
              }

              if (contact.type === CONTACT_TYPES.GIRL) {
                if (contact.availability === 'off' || contact.availability === 'off today') {
                  this.props.addContactsToEntities([contact]);
                  this.props.addGirlToOffToday(contact.id);
                }
                else if (this.props.offTodayIds.includes(contact.id)) {
                  this.props.removeGirlFromOffToday(contact.id);
                }

                if (contact.availability === 'available') {
                  this.props.addContactsToEntities([contact]);
                  this.props.addGirlToAvailable(contact.id);
                }
                else if (this.props.availableIds.includes(contact.id)) {
                  this.props.removeGirlFromAvailable(contact.id);
                }
              }

              if (isTypeChanged && contact.type === CONTACT_TYPES.CLIENT) {
                if (this.props.offTodayIds.includes(contact.id)) {
                  this.props.removeGirlFromOffToday(contact.id);
                }
                else if (this.props.availableIds.includes(contact.id)) {
                  this.props.removeGirlFromAvailable(contact.id);
                }
              }

              break;
            }
            case 'remove':
              const recentTabs = LS.getItem(
                data.contact.type === CONTACT_TYPES.CLIENT
                  ? 'clientChatsRecentTabs'
                  : 'girlChatsRecentTabs'
                ,
                currentUser.id);

              if (recentTabs) {
                const relevantTabs = recentTabs.all.filter(tab => tab !== data.contact.id);

                this.props.updateRecentTabs(null, data.contact.type, relevantTabs);
              }

              this.props.removeContactFromState(data.contact);
              break;
            case 'change_active_chat':
              const { caller } = data;

              this.props.updateContactInState(caller, caller.type);
              this.props.toggleContactActiveChat({ contact: caller, currentUserId: currentUser.id });
              break;

            default:
              break;
          }

          break;
        }

        case 'girls_sync': {
          switch (data.type) {
            case 'update': {
              this.props.updateDivaGirl(data.profile);
              break;
            }
          }
          break;
        }

        case 'ping': {
          webSocket.send(JSON.stringify({ type: 'pong', data: { token: this.state.uuid } }));

          break;
        }

        case 'chat_delete': {
          const recentTabs = LS.getItem('roomChatsRecentTabs', currentUser.id);

          if (recentTabs) {
            const relevantTabs = recentTabs.all.filter(tab => tab !== data.chatId);

            this.props.updateRecentTabs(null, CHAT_TYPES.ROOM, relevantTabs);
          }

          this.props.removeRoom(data.chatId);
          break;
        }

        case 'sales_sessions': {
          switch (data.type) {
            case 'session_start':
            case 'session_start_new':
              if (this.props.user.id === data.session.userId) {
                this.props.addSession(data.session);
              } else this.props.addPartnerSession(data.session);

              break;
            case 'session_close':
              const closeSession = () => {
                this.props.closeRemovedSession({
                  id: data.session.id,
                  dateClosed: data.session.dateClosed,
                  callerId: data.session.callerId,
                });
              };

              if (this.props.activeModals.find(modal => { // If open buffer modal with deleted session
                return modal.type === 'profiles-buffer' && modal.props.sessionId === data.session.id
              })) {
                this.props.closeModal(MODAL_TYPES.profilesBuffer);
                // use timeout because remove modal use timeout 500ms
                setTimeout(() => {
                  closeSession();
                }, 600);
              } else {
                closeSession();
              }

              break;
            case 'session_delete': {
              const deleteSession = () => {
                this.props.closeRemovedSession({
                  id: data.session.id,
                  shallDelete: true,
                  callerId: data.session.callerId,
                });
              };

              if (this.props.activeModals.find(modal => { // If open buffer modal with deleted session
                return modal.type === 'profiles-buffer' && modal.props.sessionId === data.session.id
              })) {
                this.props.closeModal(MODAL_TYPES.profilesBuffer);
                // use timeout because remove modal use timeout 500ms
                setTimeout(() => {
                  deleteSession();
                }, 600);
              }
              else {
                deleteSession();
              }

              break;
            }
            case 'session_transfer':
              this.props.closeModal(MODAL_TYPES.transferredOrAssistanceSession);
              break;
            case 'session_continue':
              this.props.addPartnerSession(data.session);
              break;
            case 'session_toggle':
              this.props.partnerToggleHistorySessionSuccess(data.session);
              break;
            case 'session_update_owner':
            case 'session_update':
            case 'session_with_future_booking':
              this.props.updateSession(data.session);
              break;
            case 'bufferedIds':
              this.props.updateSessionBuffer(data.session);
              break;
            case 'bookedIds':
              this.props.updateAssistanceSession(data.session);
              break;
            case 'comparedIds':
              this.props.updateAssistanceSession(data.session);

              if (!!data.session.comparedIds.length) {
                this.props.updateAdditionalTitleFilters(
                  ADDITIONAL_SESSION_FILTERS.search,
                  '',
                  data.session.id,
                  this.props.additionalFilters,
                  this.props.canIEditSession,
                  true,
                );
              }
              break;
            case 'additionalFilters':
              this.props.updateAdditionalFilters(data.session.id, data.session.additionalFilters);
              break;
            case 'activeFilters':
              this.props.updateActiveFiltersFromSocket(data.session.id, data.session.activeFilters);
              break;
            default:
              break;
          }

          break;
        }
        case 'sales_session': {
          const {
            [BOOKING_STATUSES_NAMES.PENDING]: { id: pending_status },
            [BOOKING_STATUSES_NAMES.IN_PROGRESS]: { id: in_progress_status },
            [BOOKING_STATUSES_NAMES.PRE_PENDING]: { id: pre_pending_status }
          } = BOOKING_STATUSES;

          switch (data.type) {
            case 'update_session_booking':
            case 'create_session_booking': {
              const booking = data.booking;
              const sessionId = data.booking.session_id;
              const bookingLog = data.bookingLog;

              this.props.updateBooking(sessionId, booking);
              if (this.props.activeModals.length && this.props.activeModals[0].type === 'bookingEditForm') {
                bookingLog && this.props.setBookingLog(bookingLog);
              }

              this.props.setUpdateBookingsPendingGlobal(false);

              if ([pending_status, in_progress_status, pre_pending_status].includes(booking.status)) {
                this.props.updateBookingCounter(booking.profile_id, 1)
              }
              break;
            }
            case 'delete_session_booking': {
              const booking = data.booking;
              const sessionId = data.booking.session_id;
              const message = data.message;

              // In case we`ve created a booking from chat, we can`t find it in the state for deletion
              if (this.props.sessions[sessionId]) {
                this.props.removeBooking(sessionId, booking);
              }

              if (message && message.id) {
                this.props.updateContactMsg(message);
              }

              const groupGirlsIds = Object.keys(data.booking.group_girls);
              const isDeleteTwoBookings = groupGirlsIds[1] && data.booking.meeting_type === 'duo';

              if (data.booking.is_deleted) {
                this.props.deleteBooking(data.booking.id);
                isDeleteTwoBookings && this.props.deleteBooking(groupGirlsIds[1]);
              }

              this.props.setUpdateBookingsPendingGlobal(false);

              if ([pending_status, in_progress_status, pre_pending_status].includes(booking.status)) {
                this.props.updateBookingCounter(booking.profile_id, -1)
              }
              break;
            }
            case 'update_session_booking_request':
            case 'create_session_booking_request': {
              this.props.updateBookingRequest(data.sessionId, data.request);
              break;
            }
            case 'delete_session_booking_request': {
              this.props.deleteBookingRequest(data.sessionId, data.request);
              break;
            }
          }

          break;
        }

        case 'mailing': {
          switch (data.type) {
            case 'success':
            case 'progress':
              this.props.updateModalProps(data.data, 'mailing-component');
              break;
          }
          break;
        }

        case 'webrtc_conference': {
          switch (data.type) {
            case 'update': {
              this.props.updateWebrtcConference(data.conference);
              break;
            }

            case 'participant_left': {
              const isMe = data.participant.user_id === this.props.user.id;

              if (isMe) {
                const thisConference = this.props.conferences[data.participant.conference_id];

                thisConference && this.props.leaveFromActiveConference(thisConference, this.props.peerConnections, this.props.localStream);
                playRingtone('/media/sounds/callEnd.mp3', null, 'sms');
              }
              // operator left my active conference; delete PC with him
              else if (data.participant.conference_id === this.props.activeWebrtcConferenceId) {
                this.props.deletePC(data.participant.user_id, this.props.peerConnections[data.participant.user_id]);
              }

              this.props.webrtcParticipantLeft(data.participant);
              break;
            }

            case 'participant_joined': {
              this.props.updateWebrtcConferenceParticipant(data.participant);
              break;
            }

            case 'update_participant': {
              const isCurrentUser = data.participant.user_id === this.props.user.id;
              const isOpenCurrentConferenceModal = this.props.activeModals.find(modal =>
                modal.type === MODAL_TYPES.webrtcConferenceFullMode
                && modal.props
                && modal.props.conferenceId === data.participant.conference_id
              );
              const currentConference = this.props.conferences[data.participant.conference_id];
              const prevParticipantData = currentConference.participants[data.participant.user_id];

              this.props.updateWebrtcConferenceParticipant(data.participant);

              switch (data.participant.status) {
                case 'active': {
                  if (isCurrentUser && prevParticipantData && prevParticipantData.status === 'connecting') {
                    this.props.localStream && this.props.localStream.getTracks().forEach(track =>
                      track.enabled = !prevParticipantData.is_muted && !prevParticipantData.is_muted_conference
                    );
                    this.props.toggleWebrtcConferenceMute(!!prevParticipantData.is_muted_conference);
                  }
                  if (isCurrentUser && prevParticipantData && prevParticipantData.status === 'busy') {
                    this.props.removeFromHoldActiveConference(currentConference);
                  }
                  break;
                }

                case 'connecting': {
                  if (isCurrentUser && prevParticipantData && prevParticipantData.status === 'busy') {
                    this.props.removeFromHoldActiveConference(currentConference);
                  }
                }

                case 'rejected': {
                  if (!isOpenCurrentConferenceModal) {
                    this.props.removeWebrtcParticipant(data.participant);
                  }
                  break;
                }

                case 'failed': {
                  if (!isOpenCurrentConferenceModal) {
                    this.props.removeWebrtcParticipant(data.participant);
                  }

                  if (isCurrentUser) {
                    this.props.leaveFromActiveConference(currentConference, this.props.peerConnections, this.props.localStream);
                  }
                  else {
                    this.props.deletePC(data.participant.user_id, this.props.peerConnections[data.participant.user_id]);
                  }
                  break;
                }
                default: break;
              }
              break;
            }
          }
          break;
        }

        case 'webrtc': {
          switch (data.type) {
            case 'offer-description': {
              let localPC = this.props.peerConnections[data.operatorId];

              if (!localPC) {
                localPC = this.props.createPeerConnection(data.operatorId, this.props.localStream, null, null, true);
              }

              localPC.setRemoteDescription(new RTCSessionDescription(JSON.parse(data.description)));

              localPC.createAnswer()
                .then((sessionDescription) => {
                  localPC.setLocalDescription(sessionDescription);

                  API.sendLocalDescription(data.operatorId, sessionDescription, 'answer');
                })
                .catch(console.error);
              break;
            }

            case 'answer-description': {
              const localPC = this.props.peerConnections[data.operatorId];

              localPC && localPC.setRemoteDescription(new RTCSessionDescription(JSON.parse(data.description)));
              break;
            }

            case 'ice-candidate': {
              const localPC = this.props.peerConnections[data.operatorId];

              !!localPC && !!data.candidate && localPC.addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)));
              break;
            }
          }

          break;
        }

        case 'mail': {
          const mailData = data.data;

          switch (data.type) {
            case 'new_email': {
              if (mailData.mail.conversationId === this.props.activeMailChatId) {
                this.props.addMessageToMailChat(mailData.message);
              }
              else {
                this.props.addEmail(mailData);
              }
              break
            }
            case 'trash': {
              const { id, conversationId } = data;
              if (id) {
                this.props.deleteMsgFromMailChat(id);
              }
              // else if (conversationId) {
              //   this.props.deleteConversationsFromAllFolders(conversationId);
              // }
              break;
            }
            case 'permanently': {
              const { id, conversationId, folder } = data;
              if (id) {
                this.props.deleteMsgFromMailChat(id);
              }
              // else if (conversationId) {
              //   this.props.deleteConversationsFromFolder({ conversationId, folder });
              // }
              break;
            }
            case 'un_spam':
              this.props.markMailsNotSpam(data.conversationId);
              break;
            case 'undelete': {
              const { id: msgId, conversationId } = data;
              this.props.undeleteMails({ msgId, conversationId });
              break;
            }
            default: break;
          }
          break;
        }

        case 'contact-tags': {
          const { activeTags, adrContactType, adrSearch } = this.props;

          switch (data.type) {
            case 'update': {
              if (isEmptyObj(activeTags) && adrContactType === 0 && !adrSearch.length) {
                this.props.updateAdrBookTags(data.updatedTags);
              }
              break;
            }
            default: break;
          }
          break;
        }

        case 'message-deleted':
          this.props.deleteTelegramMsg(data);
          break;

        default:
          break;

        case 'user_into_mailbox': {
          if (this.props.user.id !== data.userId) {
            this.props.addUserOnMail(data.userId);
          }
          break;
        }

        case 'user_out_of_mailbox': {
          if (this.props.user.id !== data.userId) {
            this.props.removeUserOnMail(data.userId);
          }
          break;
        }

        case 'chats_main_filter_info': {
          this.props.setFilterCounter(data);
          break;
        }
        case 'read_message': {
          this.props.readMessage(data, currentUser.id);
          break;
        }
        case 'mail_notes_create': {
          this.props.updateNotes(data.conversationId, data.notes);
          break;
        }
        case 'reset-caller-chat-counters': {
          this.props.resetFiltersCounters();
          break;
        }
        case 'clean_ip_phone_from_cookies': {
          CS.deleteCookie('selectedIpPhoneId');
          break;
        }
        case 'block_imessage': {
          const currentUser = this.props.user;
          this.props.updateUser({ ...currentUser, mode: { ...currentUser.mode, is_imessage_active: 0 } });
          break;
        }
        case 'unblock_imessage': {
          const currentUser = this.props.user;
          this.props.updateUser({ ...currentUser, mode: { ...currentUser.mode, is_imessage_active: 1 } });
          break;
        }
        case 'page': {
          switch (data.type) {
            case 'main_page': {
              this.props.setUserMainPageInfo(data.has_active_main_page, data.main_page_ids);
              break;
            }
            case 'sales_page': {
              this.props.setUserSalesPageInfo(data.has_active_sales_page);
              break;
            }
            case 'mail_page': {
              this.props.setUserMailPageInfo(data.has_active_mail_page);
              break;
            }
            case 'address_book_page': {
              this.props.setUserAdrBookPageInfo(data.has_active_address_book_page);
              break;
            }
            case 'calendar_page': {
              this.props.setUserCalendarPageInfo(data.has_active_calendar_page);
              break;
            }
            case 'connection-lost': {
              if (window.location.pathname === ROUTES.main) {
                API.logoutUser()
                  .catch(console.dir)
                  .finally(() => {
                    delete localStorage.isLoggedIn;
                    delete localStorage.currentUserId;

                    window.location = '/client/login';
                  })
              } else {
                window.location.reload();
              }
              break;
            }
            default: break;
          }
          break;
        }
        case 'address_book_page': {
          this.props.updateActiveAdrBookContactId(data.caller_id);
          break;
        }
        case 'sales-page': {
          const sessionId = data.session?.id || data.sales_session_id;

          if (!sessionId) {
            this.props.updateActiveSession(0);
            break;
          }

          if (
            window.location.pathname === ROUTES.sales &&
            (data.is_from_history || !this.props.sessions[sessionId])
          ) {
            this.props.getSessionById(sessionId)
              .then(({ data }) => {
                this.props.showSessionFromHistory(normalizeSession(data));
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            this.props.updateActiveSession(sessionId);
          }

          break;
        }
      }
    };
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  activeModals: state.activeWindows.activeModals,
  incomingCall: state.calls.incomingCall,
  activeCall: state.calls.activeCall,
  callsOnHold: state.calls.callsOnHold,
  callsOnTransfer: state.calls.callsOnTransfer,
  operators: state.operators.entities,
  user: state.user,
  offTodayIds: state.rooms.offTodayIds,
  availableIds: state.rooms.availableIds,
  // userMode: state.user.mode,
  settings: state.settings,
  timers: state.timers,
  activeSession: state.sessions.activeSession,
  activeChatId: state.roomChats.active,
  activeClientChatId: state.clientChats.active,
  activeGirlChatId: state.girlChats.active,
  activeMailChatId: state.mail.activeMailChatId,

  activeConference: state.conferences.activeConference,

  peerConnections: state.webrtc.peerConnections,
  localStream: state.webrtc.localStream,
  activeWebrtcConferenceId: state.webrtc.activeConferenceId,
  conferences: state.webrtc.conferences,

  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'additionalFilters')),
  canIEditSession: selectCanIEditSession(state),
  sessions: state.sessions.entities,

  activeTags: state.addressBook.tags.active,
  adrContactType: state.addressBook.contactType,
  adrSearch: state.addressBook.search,
});

const mapDispatchToProps = {
  updateActiveChat,
  setUpdateBookingsPendingGlobal,
  newIncomingMessage,
  newOutgoingMessage,
  newIncomingCall,
  changeCallStatus,
  changeChatCallStatus,
  changeMessageStatus,
  newOutgoingCall,
  newActiveCall,
  finishCallOnHold,
  removeTransferedCall,
  timerStart,
  addWidgetReminder,
  timerStop,
  newMissedCall,
  removeMissedCall,
  changeOperatorStatus,
  addNewContactToState,
  updateContactInState,
  removeContactFromState,
  newTransferedCall,
  newIncomingChatMessage,
  newOutgoingOperatorCall,
  newIncomingOperatorCall,
  finishCall,
  newMsgAttachments,
  newOutgoingChatMessage,
  newIncomingQueueCall,
  removeIncomingQueueCall,
  changeChatMsgStatus,
  openTransferedCallModal,
  updateChatMsg,
  removeChatMsg,
  updateActiveContact,
  updateVoicemailStatus,
  addPartnerSession,
  addSession,
  removePartnerSession,
  removeMissedOperatorCallForMe,
  partnerToggleHistorySessionSuccess,
  updateSession,
  updateAssistanceSession,
  updateBooking,
  updateBookingRequest,
  deleteBookingRequest,
  updateBookingScheduledMessages,
  deleteBookingScheduledMessages,
  removeBooking,
  updateSessionViewed,
  updateRoom,
  removeRoom,
  addGirlToOffToday,
  removeGirlFromOffToday,
  addGirlToAvailable,
  removeGirlFromAvailable,
  addNotification,
  removeNotification,
  changeOperatorEntity,
  updateUser,
  debouncedChangeTypingStatus,
  changeTypingStatus,
  newConference,
  newWarmTransfer,
  acceptWarmTransfer,
  conferenceParticipantJoin,
  conferenceParticipantLeave,
  removeConference,
  conferenceInvitation,
  removeGirlsChatMsg,
  addWebmasterTask,
  removeWebmasterTask,
  removeMessageReminder,
  updateSessionBuffer,
  addNewChatRoom,
  getContactTimeline,
  closeModal,
  openModal,
  updateContactMsg,
  updateRecentTabs,
  toggleContactActiveChat,
  updateModalProps,
  createSession,
  updateAdditionalFilters,
  updateActiveFiltersFromSocket,
  deleteContactMsg,
  newScheduledMsg,
  newServiceMsg,
  createPeerConnection,
  deletePC,
  updateWebrtcConference,
  updateWebrtcConferenceParticipant,
  removeWebrtcParticipant,
  toggleWebrtcConferenceMute,
  webrtcParticipantLeft,
  leaveFromActiveConference,
  removeFromHoldActiveConference,
  killWebrtcCall,
  updateAdditionalTitleFilters,
  updateContactFieldsById,
  addEmail,
  addMessageToMailChat,
  deleteMsgFromMailChat,
  deleteConversationsFromAllFolders,
  deleteConversationsFromFolder,
  markMailsNotSpam,
  undeleteMails,
  deleteTelegramMsg,
  resetDefaultSessionFilters,
  updateDivaGirl,
  addContactsToEntities,
  updateAdrBookTags,
  updateActiveRoom,
  closePartnerSession,
  closeRemovedSession,
  updateFilteredChats,
  deleteChatMsg,
  completeWebmasterTask,
  changeLoadingStatus,
  addUserOnMail,
  removeUserOnMail,
  newIncomingGroupMessage,
  newOutgoingGroupMessage,
  updateGroupMessageMedia,
  setFilterCounter,
  readMessage,
  updateNotes,
  resetFiltersCounters,
  setBookingLog,
  updateBookingScheduledMessagesByBooking,
  setUserSalesPageInfo,
  setUserMainPageInfo,
  setUserMailPageInfo,
  setUserAdrBookPageInfo,
  setUserCalendarPageInfo,
  setUserConnectToken,
  updateActiveSession,
  getSessionById,
  showSessionFromHistory,
  addMessageToWebmasterTask,
  updateBookingCounter,
  updateWebmasterTask,
  updateWebmasterNotification,
  uncompleteWebmasterTask,
  removeMessageFromWebmasterTask,
  updateActiveAdrBookContactId,
  deleteBooking
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatSocket));
