import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { useDidMount } from 'hooks';
import { openModal, closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import './SidebarHeader.scss';
// import { EditContactFormNew } from 'containers/Dialogs/Dialogs';
import Title from 'components/UI/Title/Title';
import ICONS from 'assets/icons';
import Menu from 'components/Menu/Menu';

const SidebarHeader = (props) => {
  const menu = [];
  const icons = {
    'Clients': ICONS.addressCard1,
    'Girls': ICONS.venus,
    'Operators': ICONS.peopleConference,
  }

  useDidMount(() => {
    if (props.type === 'Clients' || props.type === 'Girls') {
      menu.push({
        content: 'Add new contact',
      })
    }
  });

  return (
    <div
      className={props.className}
      onClick={props.handleClick}
      style={props.handleClick ? { cursor: 'pointer' } : {}}
    >
      <Title
        icon={icons[props.type]}
        text={props.type}
        count={props.count}
      />

      {props.type !== 'Operators' && <Menu
        iconSize={14}
        menuItems={menu}
        icon={ICONS.settings}
        dropWrapperProps={{
          placement: 'bottom-end'
        }}
        extraButton={props.extraButton}
      />}
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
  closeModal,
};

export default connect(null, mapDispatchToProps)(SidebarHeader);
