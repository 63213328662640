import { useSelector } from 'react-redux';

import API from 'api/api';
import { getContactAvatar } from 'utils';
import { selectContactBySessionId } from 'redux/selectors/selectors';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const SessionRequest = ({ session }) => {
  const contact = useSelector(state => selectContactBySessionId(state, session.id));

  const isTransferRequest = session.reqForTransferId;

  const onRequestClick = (status) => {
    isTransferRequest 
      ? API.responseForTransferSession(session.id, status) 
      : API.responseForAddToAssistant(session.id, status);
  }

  return (
    <div className="session-request">
      <div className="session-request__main">
        <div className="session-request__img-wrap">
          <LazyLoadImage
            className="session-request__avatar"
            alt="avatar"
            src={getContactAvatar(contact)}
          />
        </div>

        <div className="session-request__info">
          <span className='session-request__name'>{contact && contact.fn}</span>
          <span className='session-request__tel'>{contact && contact.tels[0] && contact.tels[0].tel}</span>
        </div>
      </div>

      <div className="session-request__title">
        {isTransferRequest ? 'Transfer requested' : 'Assistance requested'}
      </div>

      <div className="session-request__actions">
        <button 
          className="session-request__btn session-request__btn--accept" 
          onClick={() => onRequestClick('accept')}
        >
          Accept
        </button>

        <button 
          className="session-request__btn session-request__btn--decline"
          onClick={() => onRequestClick('ignore')}
        >
          Decline
        </button>
      </div>
    </div>
  )
};

export default SessionRequest;