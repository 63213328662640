import { useDispatch } from 'react-redux';
import ICONS from 'assets/icons';

import {
  BOOKING_FILTERS_NAMES,
  BOOKING_STATUS_FILTERS_NAMES
} from 'components/FilteredBookings';
import { classModifier } from 'utils';
import { TWO_DAYS_CALENDAR_DATE_CONFIG } from 'config/dates-сonfig';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';

import './CalendarTimeline.scss';
import CalendarBookingsList from '../CalendarBookingsList/CalendarBookingsList';
import {
  BookingFilters,
  BookingFiltersList,
  BookingSelectFilters,
  BookingStatusFilters,
  BookingTag
} from 'components/FilteredBookings';
import DateTime from 'components/DateTime';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';

const CreatorDate = ({ date, idx, dateForPassing }) => {
  const dispatch = useDispatch();

  const createNewBooking = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      isHandleClickCanBeRedifinedByItem: true,
      commonProps: { date: dateForPassing, ignoreSessionContact: true },
    }))
  }

  return (
    <div className={classModifier("calendar-timeline__creator-wrapper", idx === 0 && 'first')}>
      <div className="calendar-timeline__date-wrapper">
        <DateTime
          config={TWO_DAYS_CALENDAR_DATE_CONFIG}
          date={date}
          ignoreTimezone
          notIgnoreTimezoneForPassedDays
        />
      </div>

      <button
        className="calendar-timeline__new-booking"
        onClick={() => createNewBooking(date)}
      >
        <ICONS.plusThick />
      </button>
    </div>
  )
}

const CalendarTimelineHeader = ({
  pickedDate,
  getters,
  isTwoDaysPage,
  idx,
  loadMore,
  dateForPassing
}) => {
  const {
    getFiltersContainerProps,
    getStatusFilterProps,
    getFilterProps,
    getSelectProps,
  } = getters;

  const bookingTabProps = {
    className: 'calendar-timeline__booking-tag',
    coloredLabel: true,
    ...pickedDate,
  }

  return (
    <div className="calendar-timeline__header-wrapper">
      {isTwoDaysPage && (
        <CreatorDate
          date={pickedDate.date}
          idx={idx}
          loadMore={loadMore}
          dateForPassing={dateForPassing}
        />
      )}

      <BookingFiltersList
        className="calendar-timeline__header"
        counterPosition={isTwoDaysPage ? 'center' : 'top'}
        {...getFiltersContainerProps(pickedDate)}
      >
        <BookingStatusFilters
          className={classModifier("calendar-timeline__status-filters", isTwoDaysPage && 'two-days')}
          {...getStatusFilterProps(pickedDate)}
        >
          <BookingStatusFilters.todo />
          <BookingStatusFilters.confirmed />
          <BookingStatusFilters.done />
          <BookingStatusFilters.cancelled />
        </BookingStatusFilters>

        <BookingFilters
          className="calendar-timeline__filters"
          {...getFilterProps(pickedDate)}
        >
          <BookingFilters.prebooking />
          <BookingFilters.outcall />
          <BookingFilters.duo />
          <BookingFilters.agent />
        </BookingFilters>

        <BookingSelectFilters.agent
          className="calendar-timeline__select-filter"
          {...getSelectProps(pickedDate)}
        />

        <div className="calendar-timeline__tags">
          <BookingTag
            {...getStatusFilterProps({
              name: BOOKING_STATUS_FILTERS_NAMES.tosort,
              ...bookingTabProps
            })}
          />

          <BookingTag
            {...getFilterProps({
              name: BOOKING_FILTERS_NAMES.finished,
              ...bookingTabProps
            })}
          />

          <BookingTag
            {...getFilterProps({
              name: BOOKING_FILTERS_NAMES.notes,
              ...bookingTabProps
            })}
          />

          <BookingTag
            {...getFilterProps({
              name: BOOKING_FILTERS_NAMES.requests,
              ...bookingTabProps
            })}
          />
        </div>
      </BookingFiltersList>
    </div>
  )
}

const CalendarTimeline = (props) => {
  const {
    date,
    dateForPassing,
    isTwoDaysPage,
    className,
    classNameModifier = 'calendar-timeline',
    getters,
    isCurrentDate,
    loadFilteredBookings,
    idx
  } = props;

  const pickedDate = isTwoDaysPage ? { date } : {};
  const bookingType = isTwoDaysPage ? BOOKING_ITEM_VIEWS.SHORT_LINED : BOOKING_ITEM_VIEWS.LINED;

  return (
    <div className="calendar-timeline">
      <CalendarTimelineHeader
        getters={getters}
        isTwoDaysPage={isTwoDaysPage}
        pickedDate={pickedDate}
        idx={idx}
        loadMore={loadFilteredBookings}
        dateForPassing={dateForPassing}
      />

      <CalendarBookingsList
        className={className}
        classNameModifier={classNameModifier}
        pickedDate={pickedDate}
        hideMarkers={isTwoDaysPage}
        isCurrentDate={isCurrentDate}
        getters={getters}
        date={date}
        bookingType={bookingType}
        isTwoDaysPage={isTwoDaysPage}
        showTimeFilters={!isTwoDaysPage}
      />
    </div>
  );
};

export default CalendarTimeline;
