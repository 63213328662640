import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

import { usePrevious } from 'hooks';
import { MAIL_SEARCH_CONTACTS_LIMIT } from 'config/constants';
import { closeModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import './MailContactList.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import MailContactItem from 'components/MailContactItem/MailContactItem';

const MailContactList = (props) => {
  const {
    ids,
    mode,
    limit,
    pending,
    entities,
    loadMore,
    onClick,
  } = props;

  const dispatch = useDispatch();

  const expectedListLengthRef = useRef(limit);

  const prevMode = usePrevious(mode);

  useEffect(() => {
    if (prevMode !== mode) {
      expectedListLengthRef.current = ids.length >= limit
        ? ids.length
        : limit;
    }
  })

  const isItemLoaded = ({ index }) => !!entities[ids[index]];

  const loadMoreItems = (startIndex, stopIndex) => {
    if (stopIndex >= ids.length - 1 && ids.length === expectedListLengthRef.current) {
      expectedListLengthRef.current += limit;
      return loadMore((ids.length / MAIL_SEARCH_CONTACTS_LIMIT) + 1);
    }
  }

  const handleEmailClick = (email) => {
    onClick(email);
    dispatch(closeModal(MODAL_TYPES.mailContactSearch));
  }

  if (pending || !ids.length) {
    return (
      <div className="mail-contact-list mail-contact-list--centered">
        {props.pending
          ? <Spinner spinnerSize='40px' />
          : "(no items)"
        }
      </div>
    )
  }

  return (
    <div className="mail-contact-list">
      <AutoSizer>
        {({ width, height }) => (
          <InfiniteLoader
            itemCount={ids.length}
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <List
                className="mail-contact-list__list"
                ref={ref}
                width={width}
                height={height}
                itemSize={() => 54}
                itemCount={ids.length}
                onItemsRendered={onItemsRendered}
              >
                {({ index, style }) => (
                  <MailContactItem
                    style={{ ...style, width: 'calc(100% - 5px)' }}
                    item={entities[ids[index]]}
                    handleEmailClick={handleEmailClick}
                  />
                )}
              </List>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  )
}

export default React.memo(MailContactList);
