import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { SAVED_SESSIONS_FILTERS } from "redux/ducks/user";
import { classModifier } from 'utils';

const FiltersEditorList = ({ filters, droppableId, ...props }) => {
  const filter = props.services ? 'services' : 'filters';

  return (
    <div
      className={classModifier('filters-editor__zone', [filter])}
    >
      <div className={classModifier('filters-editor__zone-title', [filter])}>
        {`Active ${filter} - ${props.ids.length - props.savedFiltersLength}`}
      </div>
      

      <div className="filters-editor__zone-content">
        <div className="filters-editor__list-container">
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <Scrollbars autoHide>
                <ul
                  className="filters-editor__list"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {props.ids.map((filter, i) => {
                    if (!SAVED_SESSIONS_FILTERS[filter]) {
                      return (
                        <Draggable
                          key={filter}
                          draggableId={filter}
                          index={i}
                        >
                          {(provided) => {
                            const modifiers = props.services
                              ? ['services', 'services-choosen']
                              : ['filters', 'filters-choosen']

                            return (
                              <div
                                className={classModifier('filters-editor__list-item-wrap', modifiers)}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <li className='filters-editor__list-item'>
                                  {filters.entities[filter] && filters.entities[filter].name}
                                </li>
                                <div
                                  className="filters-editor__close-list-item"
                                  onClick={() => props.removeFilter(filter, props.services ? 'services' : 'params')}
                                />
                              </div>
                            )
                          }
                          }
                        </Draggable>
                      )
                    }
                  })}
                </ul>
              </Scrollbars>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};

export default FiltersEditorList;
