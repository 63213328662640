import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';

import API from 'api/api';
import { addMailingMessage } from 'redux/ducks/mailing';
import { CONTACT_GROUPS, SEARCH_LIST_THEMES } from 'config/constants';
import ICONS from 'assets/icons';

import "./MultiMessageModal.scss"
import SearchList from 'components/SearchList/SearchList';
import MailingHistory from './components/MailingHistory/MailingHistory';
import ChatSendingMessage from 'components/ChatSendingMessage/ChatSendingMessage';
import UsersField from './components/UsersField/UsersField';

const LIST_SIZE = 20;

const initialValues = {
  users: [],
  message: '',
}

const contactGroupsKeys = Object.keys(CONTACT_GROUPS);
const initialExtraGroups = Object.fromEntries(contactGroupsKeys.map((key) => ([ key, false ])));

const MultiMessageModal = ({ closeModal }) => {
  const [searchListExtraGroups, setSearchListExtraGroups] = useState(initialExtraGroups);
  const [groupContactsLength, setGroupContactsLength] = useState(0);

  const pending = useSelector((state) => state.mailing.pending);
  const dispatch = useDispatch();

  const extraFilterList = (contact) => !contact.tels.length;

  const requiredMessage = value => !value && 'Required Message';
  const requiredUsers = value => !value.length && 'Please add recipients from the contact list';

  const choosenGroups = useMemo(() => (
    _.keys(_.pickBy(searchListExtraGroups, Boolean)) || []
  ), [searchListExtraGroups]);

  const choosenContactTypes = useMemo(() => (
    [...new Set(choosenGroups.flatMap((group) => CONTACT_GROUPS[group]))]
  ), [choosenGroups]);

  const fetchSearchContacts = ({ query, offset, cancelToken }) => {
    return API.searchContactsMailing(query, offset, LIST_SIZE, cancelToken, choosenContactTypes)
      .then(({ data }) => {
        setGroupContactsLength(Number(data.count));

        return {
          newItems: data.callers,
          newHasMore: data.callers.length === LIST_SIZE,
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!choosenContactTypes.length) {
      setGroupContactsLength(0);
    }
  }, [choosenContactTypes])

  const onSubmit = useCallback((values) => {
    const filteredUsers = values.users.filter(user => user.type !== 'group');

    return dispatch(addMailingMessage(values.message, filteredUsers, choosenContactTypes));
  }, [choosenContactTypes]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={arrayMutators}
      render={({ handleSubmit, values, form, valid }) => {
        const handleSubmitWithResetForm = (event) => {
          event.preventDefault();

          if (valid) {
            handleSubmit(event).then(() => {
              form.reset();
              setSearchListExtraGroups(initialExtraGroups)
            });
          }
        };

        return (
          <form onSubmit={handleSubmitWithResetForm}>
            <div className="mailing__esc-btn" onClick={() => closeModal()}>
              <ICONS.close className="mailing__esc-btn-icon" /> (Esc)
            </div>
            <div className="mailing">
              <div className="mailing__dialog-wrapper">
                <div className='mailing__messages-container'>
                  <MailingHistory
                    formRef={form}
                    choosenGroups={choosenGroups}
                    actualUsersCount={values.users.length + groupContactsLength - choosenGroups.length}
                  />
                </div>

                <div className="mailing__input-container">
                  <Field name="users" validate={requiredUsers}>
                    {({ input, meta, ...rest }) => (
                      <UsersField
                        input={input}
                        meta={meta}
                        groupContactsLength={groupContactsLength}
                        choosenGroups={choosenGroups}
                        updateUsers={(items) => form.change("users", items)}
                        {...rest}
                      />
                    )}
                  </Field>

                  <Field name="message" validate={requiredMessage}>
                    {({ input, meta, ...rest }) => (
                      <ChatSendingMessage
                        handleSubmit={handleSubmitWithResetForm}
                        inputProps={{
                          placeholder: "Your message",
                          disabled: pending
                        }}
                        input={input}
                        meta={meta}
                        {...rest}
                      />
                    )}
                  </Field>
                </div>
              </div>

              <div className="mailing__contacts-wrapper">
                <SearchList
                  theme={SEARCH_LIST_THEMES.empty}
                  inputLabel={null}
                  listLabel="Add recipients"
                  inputPlaceholder="Searching contacts..."
                  fetchData={fetchSearchContacts}
                  onChoose={(items) => form.change("users", items)}
                  isInitialData={false}
                  filterList={extraFilterList}
                  choosenItems={values.users}
                  searchListExtraGroups={searchListExtraGroups}
                  setSearchListExtraGroups={setSearchListExtraGroups}
                  multipleMode
                />
              </div>
            </div>
          </form>
        )
      }}
    />
  )
}

export default MultiMessageModal;

