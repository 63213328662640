import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useSupercluster from 'use-supercluster';
import GoogleMapReact from 'google-map-react';

import API from 'api/api';
import { useDidUpdate, usePrevious, useToggle } from 'hooks';
import { MAP_DEFAULT_OPTIONS } from 'config/constants';
import { MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, updateCenter, updateGirlsCoordinatesData, updateSecondaryModeId, updateSecondaryModeIds, updateZoom } from 'redux/ducks/map';
import { selectFilteredGirlCoordinates, selectMapGirlIdBySecondaryMode } from 'redux/selectors/selectors';
import { createPoint, createPoints } from '../../utils/createPoints';
import getClientRadiusBounds from '../../utils/getClientRadiusBounds';
import { GirlsMapModalContext } from '../../GirlsMapModal';

import Spinner from 'components/UI/Spinner/Spinner';
import ClusterMarker from '../Markers/ClusterMarker';
import ClientMarker from '../Markers/ClientMarker';
import GirlsMarker from '../Markers/GirlsMarker';
import { Marker } from '@react-google-maps/api';
import GirlPreviewOnMap from '../GirlPreviewOnMap/GirlPreviewOnMap';
import ShowSubwayLinesButton from '../ControlElements/ShowSubwayLinesButton';


const GirlsMap = ({ mapRef, mapsRef }) => {
  const {
    zoom,
    center,
    clientData,
    primaryMode,
  } = useSelector((state) => state.map);
  const filteredGirlCoordinates = useSelector(selectFilteredGirlCoordinates);
  const targetGirlId = useSelector(state => selectMapGirlIdBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT));
  const hostForSimilarEscortId = useSelector(
    state => selectMapGirlIdBySecondaryMode(state, MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT)
  );

  const [bounds, setBounds] = useState([]);
  const [boundsForApi, setBoundsForApi] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [isStreetViewVisible, setIsStreetViewVisible] = useState(false);
  const [isClearCache, setIsClearCache] = useToggle(true);

  const {
    isGoogleMapLoaded,
    setIsGoogleMapLoaded,
    findGirlMarkerOnMap,
    map,
  } = useContext(GirlsMapModalContext);

  const prevBoundsForApi = usePrevious(boundsForApi);

  const dispatch = useDispatch();

  const girlsPoints = createPoints(filteredGirlCoordinates, 'girls');
  const clientPoint = createPoint(clientData, 'clients');

  const { clusters, supercluster } = useSupercluster({
    points: [...girlsPoints, clientPoint],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const isTargetMode = primaryMode === MAP_PRIMARY_MODES.TARGET_ESCORT;

  const getGirlsCoordinatesByBounds = () => {
    const [longitudeMin, latitudeMin, longitudeMax, latitudeMax] = boundsForApi;

    const config = {
      latitudeMin,
      latitudeMax,
      longitudeMin,
      longitudeMax,
      cacheClear: isClearCache || !!hostForSimilarEscortId,
      ...(clientData.radius.value && {
        radius: clientData.radius.value,
        centerLatitude: clientData.latitude,
        centerLongitude: clientData.longitude,
        ...getClientRadiusBounds(clientData)
      }),
      ...(hostForSimilarEscortId && { ['host-profile']: hostForSimilarEscortId }),
    };

    setIsPending(true);

    API.getGirlsCoordinatesByBounds(config)
      .then(({ data }) => {
        const radiusGirlsIds = data.radiusProfiles.map(profile => Number(profile.id));

        dispatch(updateGirlsCoordinatesData(data));
        dispatch(updateSecondaryModeIds(MAP_SECONDARY_MODES.RADIUS_ESCORTS, radiusGirlsIds));
      })
      .catch(console.error)
      .finally(() => {
        setIsPending(false);
        setIsClearCache(false);
      });
  };

  const updateMapState = (map = mapRef.current) => {
    const bounds = map.getBounds();

    setBoundsForApi([
      bounds.getSouthWest().lng(),
      bounds.getSouthWest().lat(),
      bounds.getNorthEast().lng(),
      bounds.getNorthEast().lat(),
    ]);
    // setZoom(map.getZoom());
  };

  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;
    
    updateMapState(map);
    setIsGoogleMapLoaded(true)
  }

  const handleMapChange = ({ zoom, bounds, center }) => {
    dispatch(updateZoom(zoom));
    dispatch(updateCenter(center));
    setBounds([
      bounds.nw.lng,
      bounds.se.lat,
      bounds.se.lng,
      bounds.nw.lat,
    ]);
  }

  const onMove = useCallback(updateMapState, []);
  const onStreetViewVisibleChange = () => setIsStreetViewVisible(map.getStreetView().getVisible());
  
  useEffect(() => {
    if (!isGoogleMapLoaded) return;

    const moveListenerHandle = google.maps.event.addListener(map, 'idle', onMove);
    const streetViewListenerHandle = google.maps.event.addListener(
      map.getStreetView(),
      'visible_changed',
      onStreetViewVisibleChange
    );

    return () => {
      google.maps.event.removeListener(moveListenerHandle);
      google.maps.event.removeListener(streetViewListenerHandle);
    };
  }, [isGoogleMapLoaded]);

  useDidUpdate(() => {
    if (boundsForApi.toString() === prevBoundsForApi.toString()) return;
    
    getGirlsCoordinatesByBounds();
  }, [boundsForApi]);

  useDidUpdate(() => {
    getGirlsCoordinatesByBounds();
  }, [clientData, hostForSimilarEscortId])

  useEffect(() => {
    if (!targetGirlId) return;

    findGirlMarkerOnMap(targetGirlId)
  }, [targetGirlId])

  return (
    <div className="girls-map__content">
      {isTargetMode && <GirlPreviewOnMap />}

      <div className="girls-map__content-map">
        {isTargetMode && !isStreetViewVisible && (
          <button
            className="girls-map__similar-button"
            onClick={() => dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.HOST_FOR_SIMILAR_ESCORT, targetGirlId))}
          >
            Similar & nearby escorts
          </button>
        )}
        {isPending && (
          <Spinner
            className="girls-map__spinner-wrap"
            spinnerSize={44}
          />
        )}

        {isGoogleMapLoaded && !isStreetViewVisible && <ShowSubwayLinesButton />}
        
        <GoogleMapReact
          zoom={zoom}
          center={center}
          options={MAP_DEFAULT_OPTIONS}
          yesIWantToUseGoogleMapApiInternals
          shouldUnregisterMapOnUnmount={false}
          onGoogleApiLoaded={handleApiLoaded}
          onChange={handleMapChange}
        >
          {isGoogleMapLoaded && clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, category } = cluster.properties;

            const markerProps = {
              lat: latitude,
              lng: longitude,
              key: cluster.properties.id,
              cluster,
              supercluster,
              onDoubleClick: e => e.stopPropagation(),
            }

            if (isCluster) {
              return <ClusterMarker {...markerProps} />;
            } else if (category === 'clients') {
              return <ClientMarker {...markerProps} />;
            } else if (category === 'girls') {
              return <GirlsMarker {...markerProps} />;
            } else {
              return <Marker {...markerProps} />;
            }
          })}
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default React.memo(GirlsMap);
