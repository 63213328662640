import './BookingConfirmationStatus.scss';

const { BOOKING_STATUS_FILTERS } = require('config/constants');

const BookingConfirmationStatus = ({ status, ...props }) => {
  const bookingStatusColor = Object.values(BOOKING_STATUS_FILTERS)
    .find((booking_status) => booking_status.label === status)
    .color;

  return (
    <div
      className='booking-status'
      style={{ borderColor: bookingStatusColor }}
      {...props}
    >
      {status}

      <span
        className='booking-status-dot'
        style={{ backgroundColor: bookingStatusColor }}
      />
    </div>
  )
}

export default BookingConfirmationStatus;
