import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';

import API from 'api/api';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import Spinner from 'components/UI/Spinner/Spinner';
import { CARD_TABS } from 'config/constants';

const LoadingIndicator = () => (
  <div className="chat-header-select__loading-indicator">
    <Spinner spinnerSize={14} />
  </div>
)

const newTelBtnOption = { value: '+ Add New Number' };

const ContactTels = ({ activeRecipient, ...props }) => {
  const dispatch = useDispatch();

  const [selectedTel, setSelectedTel] = useState({});
  const [pending, setPending] = useState(false);

  const { tels = [] } = activeRecipient;
  const defaultTel = tels[0];

  useEffect(() => {
    if (tels.length > 0) {
      setSelectedTel({
        id: defaultTel.id,
        value: defaultTel.tel,
        // label: defaultTel.labelName,
        uuid: defaultTel.uuid,
      });
    }
  }, [activeRecipient]);

  const openEditContactForm = () => {
    return dispatch(openModal(MODAL_TYPES.contactCard, {
        contact: activeRecipient.id,
        defaultTab: CARD_TABS.EDIT,
        isAddPhoneNumberOnMount: true,
    }));
  };

  const onChangeHandler = (option) => {
    if (option.value === selectedTel.value) return;

    if (option.value === newTelBtnOption.value) {
      openEditContactForm();
      return;
    }

    setPending(true);

    API.changeDefaultContactTel(option.id)
      .then(() => setSelectedTel(option))
      .catch(console.error)
      .finally(() => setPending(false))
  }

  const formatOptionLabel = (option) => option.value;

  const filterOption = (option) => {
    return tels.length > 1
      ? true
      : option.value !== selectedTel.value;
  }

  const handlePhoneBtnClick = () => {
    dispatch(openModal(MODAL_TYPES.call, { contact: activeRecipient }));
  }

  const options = useMemo(() => [
    ...tels.map(tel => ({
      id: tel.id,
      value: tel.tel,
      // label: tel.labelName,
      uuid: tel.uuid,
    })),
    newTelBtnOption
  ], [tels]);

  const isCommunicationActive = useMemo(() => {
    const mainTel = activeRecipient.tels?.find((tel) => (
      tel.uuid === activeRecipient.via?.via_phone_uuid
    ));

    return mainTel && selectedTel.uuid === mainTel.uuid;
  }, [activeRecipient, selectedTel]);

  if (tels.length === 0) {
    return (
      <div className="chat-header__select-wrap">
        <button
          className="chat-header__add-new-btn"
          onClick={openEditContactForm}
        >
          + Add New Number
        </button>
      </div>
    )
  }

  return (
    <div className="chat-header__select-wrap">
      <button
        className="chat-header__select-btn chat-header__select-btn--phone"
        onClick={handlePhoneBtnClick}
      >
        <ICONS.phoneSquare
          className={classModifier('chat-header__select-icon', isCommunicationActive && 'comm-available')}
        />
      </button>

      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={options}
        value={selectedTel}
        isSearchable={false}
        getOptionValue={option => option.value}
        onChange={onChangeHandler}
        filterOption={filterOption}
        formatOptionLabel={formatOptionLabel}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator: pending ? LoadingIndicator : components.DropdownIndicator,
        }}
      />
    </div>
  )
}

export default ContactTels;
