import _ from 'lodash'

const corresponds = {
  'confirmationStatus': 'confirmation_status',
  'clientId': 'caller_id',
  'girlId': 'girl_caller_id',
}

export default (booking, filters) => {
  return Object.entries(filters).every(([key, value]) => {
    if (key === 'searchQuery') {
      return [booking.caller_fn, ...Object.values(JSON.parse(booking.group_girls))].includes(value);
    }
    
    return booking[corresponds[key] || key] === value;
  })
}
