import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import { CHAT_TYPES } from 'config/constants';
import { selectUserTimezone } from 'redux/selectors/selectors';
import {
  getChatTimeline,
  updateChatMessageSearch,
  updateChatTimeline,
  searchMessageInChat,
  stopChatMessageSearch,
} from 'redux/ducks/roomChats';

import './FinishedList.scss';
import './StartedList.scss';
import ChatSources from 'containers/Chat/components/ChatSources/ChatSources';
import ChatTimeline1 from 'containers/Chat/components/ChatTimeline/ChatTimeline1';

const ByStatusList = ({
  search,
  timeline,
  timelinePending,
  todayMessagesCount,
  updatePending,
  newInteractionType,
  auxiliaryTimeline,
  activeRecipient,
  stopChatMessageSearch,
  searchMessageInChat,
  userId,
  userTimezone,
  getChatTimeline,
  getChatTimelineMedia,
  getChatTimelineGlossary,
  ...props
}) => {

  const chatTimeline = search ? auxiliaryTimeline : timeline;

  const startSearch = (query) => searchMessageInChat(query, activeRecipient.id, userId, userTimezone);

  const isMainTimelineOpen = !(search || props.contextMsgId);

  const timelineLowerLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryLowerLoadedPage
    : props.timelineLowerLoadedPage;

  const timelinePageCount = !isMainTimelineOpen
    ? props.auxiliaryPageCount
    : props.timelinePageCount;

  const timelineHigherLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryHigherLoadedPage
    : props.timelineCurrentPage;

  const timelineCurrentPage = !isMainTimelineOpen
    ? props.auxiliaryCurrentPage
    : props.timelineCurrentPage;

  const updateTimeline = () => {
    if (search) {
      return (activeRecipient, page, loadDirection) =>
        props.updateChatMessageSearch(activeRecipient, page, loadDirection, search, userId, userTimezone);
    } else {
      return (chat, page, loadDirection) =>
        props.updateChatTimeline(activeRecipient, page, loadDirection, userId, userTimezone);
    }
  }

  useEffect(() => {
    getChatTimeline(activeRecipient.id, userId, userTimezone);
  }, [activeRecipient])

  return (
    <div className={`${activeRecipient.id}-list`}>
      <div className={`${activeRecipient.id}-list__header`}>
        <div className={`${activeRecipient.id}-list__header-top`}>
          <h3 className={`${activeRecipient.id}-list__title`}>
            Girls chat ({todayMessagesCount})
          </h3>
        </div>
      </div>

      <ChatSources
        type={CHAT_TYPES.ROOM}
        activeRecipient={activeRecipient}
        stopMessageSearch={stopChatMessageSearch}
        startMessageSearch={startSearch}
        search={search}
        isGlobalSearch={true}
      />

      <ChatTimeline1
        activeRecipient={activeRecipient}
        type={CHAT_TYPES.ROOM}
        timeline={chatTimeline}
        timelinePending={timelinePending}

        updatePending={updatePending}
        timelineCurrentPage={timelineCurrentPage}
        timelinePageCount={timelinePageCount}
        timelineHigherLoadedPage={timelineHigherLoadedPage}
        timelineLowerLoadedPage={timelineLowerLoadedPage}
        newInteractionType={newInteractionType}
        updateContactTimeline={updateTimeline()}

        profileId={userId}
        userTimezone={userTimezone}
        userHour12={props.userHour12}

        search={search}
        contextMsgId={props.contextMsgId}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  activeRecipient: state.rooms.entities[state.roomChats.active],
  timeline: state.roomChats.timeline,
  timelinePending: state.roomChats.timelinePending,
  updatePending: state.roomChats.updatePending,
  timelineCurrentPage: state.roomChats.timelineCurrentPage,
  timelinePageCount: state.roomChats.timelinePageCount,
  timelineLowerLoadedPage: state.roomChats.timelineLowerLoadedPage,
  todayMessagesCount: state.roomChats.todayMessagesCount,
  newInteractionType: state.roomChats.newInteractionType,
  search: state.roomChats.search,
  user: state.user,
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),

  auxiliaryTimeline: state.roomChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.roomChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.roomChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.roomChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.roomChats.auxiliaryCurrentPage,

  contextMsgId: state.roomChats.contextMsgId,
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  getChatTimeline,
  updateChatTimeline,
  updateChatMessageSearch,
  searchMessageInChat,
  stopChatMessageSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ByStatusList);
