import React from 'react';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isMineInteraction, getContactAvatar } from 'utils';

import MsgText from '../ChatTimeline/interactions/MsgText';
import DateTime from 'components/DateTime';
import { MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';


const AttachmentMessage = ({ interaction, profileId, ...props }) => {
  const sender = isMineInteraction(profileId, interaction.senderId)
    ? 'You'
    : interaction.sender.username;

  return (
    <div className={`${props.className ? props.className : ""} attachment-msg`}>
      <header className="attachment-msg__header">
        <FontAwesomeIcon icon={faQuoteRight} size="xs" color="#c1c1c1" />

        <div className="attachment-msg__ava">
          <img src={getContactAvatar(interaction.sender)} alt="ava" />
        </div>

        <div>
          <p className="attachment-msg__name">{sender}</p>

          <DateTime
            className="attachment-msg__time"
            date={interaction.dateCreated}
            config={MESSAGE_DATE_CONFIG}
          />
        </div>
      </header>

      <div className="attachment-msg__content">
        <MsgText interaction={interaction} />
      </div>
    </div>
  );
}

export default AttachmentMessage;
