import React, { useState } from 'react';
import { connect } from 'react-redux';

import { classModifier } from 'utils';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import './AttachmentImagesAndVideos.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';
import { IMAGE_FORMAT_REGEX } from 'config/constants';

const AttachmentImagesAndVideos = ({
  media,
  openModal,
  imgHeight,
  maxImages,
  maxImagesPerRow,
  disableExpansion,
  secondaryButton
}) => {
  const contentLimitCount = maxImages || 6;

  const [isHidden, setIsHidden] = useState(() => media.length > contentLimitCount);

  const limitCount = maxImages < media.length ? maxImages : media.length;
  const visibleMedia = isHidden ? media.slice(0, contentLimitCount) : media;

  const handleOpen = (index) => {

    const modalProps = {
      media: visibleMedia.map((url) => ({ url })),
      defaultActiveSlide: index,
      isNotProfile: true,
    }

    openModal(MODAL_TYPES.profileCarousel, modalProps);
  }

  const handleHiddenMode = (event) => {
    event.stopPropagation();

    if (!disableExpansion) {
      setIsHidden((prev) => !prev);
    }
  }

  return (
    <div
      className={classModifier("attachment-img", [
        isHidden && 'hidden',
        maxImagesPerRow ? `col-${maxImagesPerRow}` : 'col-3',
        (!imgHeight && maxImagesPerRow) && `row-${Math.ceil(limitCount / maxImagesPerRow)}`
      ])}
    >
      {Boolean(visibleMedia.length) && visibleMedia.map((url, idx) => (
        <div
          key={idx}
          className="attachment-img__img-wrap"
          style={imgHeight ? { height: imgHeight } : {}}
          onClick={() => handleOpen(idx)}
        >
          {IMAGE_FORMAT_REGEX.test(url)
            ? (
              <LazyLoadImage
                src={url}
                data-img-src={url}
                data-attach-img
                className="attachment-img__img"
                alt="msg img"
                spinnerSize={20}
              />
            )
            : (
              <VideoThumbnail
                spinnerSize={40}
                src={url}
              />
            )}

          {secondaryButton instanceof Function ? secondaryButton({ src: url, key: idx }) : secondaryButton}

          {isHidden && idx === contentLimitCount - 1 &&
            <button
              className={classModifier("attachment-img__btn", 'expanced')}
              onClick={handleHiddenMode}
            >
              +{media.length - contentLimitCount}
            </button>
          }
        </div>
      ))}
    </div>
  );
};

const mapDispatchToProps = {
  openModal,
};

export default connect(null, mapDispatchToProps)(AttachmentImagesAndVideos);
