import React from 'react';
import classNames from 'classnames';

import {
  getContactAvatar,
  classModifier
} from 'utils';
import { selectOperatorById, selectUserTimezone } from 'redux/selectors/selectors';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BASE_DATE_CONFIG, TODO_MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';
import getIconTypeByInteractionId from 'utils/getIconTypeByInteractionId';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import ICONS from 'assets/icons';
import MsgText from './MsgText';
import API from 'api/api';
import DateTime from 'components/DateTime';
import PinnedMsgsAttachments from '../PinnedMsgs/PinnedMsgsAttachments';


const WebmasterMessage = ({
  className,
  interaction,
  associated_with,
  creatorUser,
  finisherUser,
  currentUserName,
  isClickable = true,
  handleLinkClick,
}) => {
	const operator = useSelector(state => selectOperatorById(state, interaction.assigned_to_user_id, true))
  const dispatch = useDispatch();

  const timeStampsArray = [
    ...(interaction.is_completed ? [{
      text: 'completed',
      date: interaction.completed_date,
      name: finisherUser.username,
    }] : []),
    {
      text: 'added',
      date: interaction.date_created,
      name: creatorUser.username,
    }
  ]

  const handleMsgClick = (event) => {
		if (!isClickable) {
      return;
    }

    if (event.target.localName === 'input') {
      return;
    }

    dispatch(openModal(MODAL_TYPES.addNewTasksOrRemaindersModal, {
      isWebmaster: true,
      item: interaction, 
    }))
  }

  const classes = classNames(
    className,
    classModifier('interaction', ['task', !isClickable && 'disabled'])
  )

  const lastInteractionType = getIconTypeByInteractionId(associated_with?.interactionObject?.type);
  const LastInteractionIcon = ICONS[lastInteractionType];

  const actualInteraction = interaction.description ? interaction : interaction.interaction;

  return (
    <li
      className={classes}
      onClick={handleMsgClick}
    >
      <form className="interaction__completed-menu">
        {timeStampsArray.map((timeStamp, index) => (
          <div
            className="interaction__completed-item"
            key={index}
          >
            <p className="interaction__completed-item">
              {timeStamp.text}

              <DateTime
                date={timeStamp.date}
                config={TODO_MESSAGE_DATE_CONFIG}
                hoursToAbsolute={1}
              />

              by

              <span className="interaction__operator">
                {currentUserName === timeStamp.name ? 'You' : timeStamp.name}
              </span>
            </p>

            {!index &&
              <label className="checkbox-container interaction__checkbox-container">
                <input
                  type="checkbox"
                  onChange={() => API.completeWebmasterTask(interaction.id, !interaction.is_completed)}
                  defaultChecked={interaction.is_completed}
                />

                <span className={classModifier("checkmark", interaction.is_completed && 'completed')}>
                  <ICONS.check />
                </span>
              </label>
            }
          </div>
        ))}

        <p className='interaction__assigned'>
          assigned to
          {" "}
          <span className='interaction__operator'>
            {operator?.username || 'all operators'}
          </span>
        </p>
      </form>

      <header className="interaction__header">
        <div className={classModifier('interaction__ava', associated_with?.availability)}>
          <img
            src={getContactAvatar(associated_with)}
            className="interaction__ava-img"
            alt="ava"
          />

          {LastInteractionIcon && (
            <LastInteractionIcon className={`interaction__ava-${lastInteractionType}-icon`} />
          )}
        </div>

        <div className='interaction__header-info'>
          <span className="interaction__name">
            {associated_with && (associated_with.short_name || associated_with.fn)}
          </span>

          {/* <DateTime
            className={classModifier('interaction__time', 'msg')}
            date={interaction.dateCreated}
            config={BASE_DATE_CONFIG}
          /> */}
        </div>
      </header>

      <div
        className='interaction__text-wrap'
        onClick={handleLinkClick}
      >
        <MsgText
          interaction={actualInteraction}
          // isMsgExpanded={isMsgExpanded}
          // isCompleted={interaction.is_completed}
        />
        
        {actualInteraction.attachments && (
          <PinnedMsgsAttachments
            attachments={actualInteraction.attachments}
            isMenuOpen={false}
          />
        )}

        {/* {interaction.attachments && (isMsgExpanded ? (
          <InteractionAttachments
            attachments={interaction.attachments}
            profileId={userId}
            userTimezone={userTimezone}
            userHour12={userHour12}
          />
        ) : (
          <PinnedMsgAttachments
            attachments={interaction.attachments}
            isMenuOpen={false}
          />
        ))} */}
      </div>
    </li>
  )
}

const mapStateToProps = (state, ownProps) => ({
  associated_with: ownProps.interaction.associated_with,
  creatorUser: selectOperatorById(state, ownProps.interaction.user_creator_id),
  finisherUser: selectOperatorById(state, ownProps.interaction.user_finisher_id),
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,
  currentUserName: state.user.username,
});

export default connect(mapStateToProps)(WebmasterMessage);
