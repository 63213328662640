import React from 'react';

import './BlinkIndicator.scss'


const BlinkIndicator = ({ color, size, handleClick }) => {

  const styleStaticBlock = {
    position: 'relative',
    backgroundColor: color,
    height: size,
    width: size,
    borderRadius: '50%',
  };

  const styleBlinker = {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: color,
    height: size,
    width: size,
    borderRadius: '50%',
  };

  return (
    <div
      onClick={() => handleClick && handleClick()}
      className="blinker-wrap" style={styleStaticBlock}>
      <span
        className="blinker"
        style={styleBlinker} />
    </div>
  )
};

export default BlinkIndicator;
