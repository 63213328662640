import { useEffect, useRef } from 'react';
import axios from 'axios';

/**
 * This hook allows manual requests cancellation.
 * https://dev.to/tmns/usecanceltoken-a-custom-react-hook-for-cancelling-axios-requests-1ia4
 *
 * source - used to access/set Axios cancel token source.
 * newCancelToken - used to generate the cancel token sent in the Axios request.
 * cancelPrevRequest - used to manually cancel the previous Axios request.
 * isCancel - used to check if the error returned in Axios response is a cancel token error.
 */

const useCancelToken = () => {
  const source = useRef(null);

  const { CancelToken, isCancel } = axios;

  const newCancelToken = () => {
    source.current = CancelToken.source();
    return source.current.token;
  };

  const cancelPrevRequest = (message) => {
    if (source.current) {
      source.current.cancel(message);
    }
  };

  return { source, newCancelToken, cancelPrevRequest, isCancel };
};

export default useCancelToken;
