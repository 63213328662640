import React from 'react';

import { classModifier } from 'utils';

import './ModalError.scss';


const ModalError = ({ title, text, size }) => {
  return (
    <div className={classModifier("modal-error", [
      size==="small" && "small"
    ])}>
      <h2 className="modal-error__title">{title ? title : "Application Error"}</h2>
      <p className="modal-error__text">{text}</p>
    </div>
  );
};

export default ModalError;