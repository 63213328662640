import React from 'react';

import { classModifier } from 'utils';

import SelectFormField from 'components/SelectFormField/SelectFormField';
import CalendarFormField from 'components/CalendarFormField/CalendarFormField';
import BookingFieldType from 'components/BookingForm/components/BookingFieldType';
import ServicesList from 'components/BookingForm/components/BookingServicesList';
import OverlappedBookingsMsg from 'components/OverlappedBookingsMsg/OverlappedBookingsMsg';
import getCurrentSelectedRate from 'utils/getBookingSelectedRate';

const BookingEditFieldsItem = (props) => {
  const { 
    profile = {},
    fieldName,
    bookingEditProps,
    bookingRateUpdate,
    setBookingEditProps,
    checkBookingOverlap,
  } = props;

  const getCurrentBookingDuration = (type) => {
    return profile.prices
      ?.filter(item => Number(item[type]))
      .map(item => ({ label: item.name, value: item[type]}));
  };

  const rateOptions = getCurrentSelectedRate(bookingEditProps[fieldName]?.type, bookingEditProps[fieldName]?.bookingDuration, profile);
  const bookingDurationOptions = getCurrentBookingDuration(bookingEditProps[fieldName]?.type);
  const isDisabledTaxiField = bookingEditProps.profile?.type === 'incall' && !bookingEditProps.escort

  return (
    <div className='duo-booking-form__field-item'>  
      <div className={classModifier('duo-booking-form__fields-wrapper', 'diva-info')}>
        <div className={classModifier('duo-booking-form__fields-part', 'time')}>
          <h2 className='duo-booking-form__diva-name'>{profile.name}</h2>
          <BookingFieldType 
            isBookingEditorPanel
            fieldName={fieldName}
            bookingEditProps={bookingEditProps}
            bookingRateUpdate={bookingRateUpdate}
            setBookingEditProps={setBookingEditProps}
          />
        </div>

        <div className={classModifier('duo-booking-form__fields-part', 'rate')}>
          <SelectFormField
            fieldType='rate'
            options={rateOptions}
            bookingEditProps={bookingEditProps}
            setBookingEditProps={rate => {
              setBookingEditProps(prevState => ({
                ...prevState, 
                [fieldName]: { ...prevState[fieldName], rate }
              }))
            }}
            isDisabled={!bookingEditProps[fieldName]?.bookingDuration || !rateOptions.length}
            stateValue={bookingEditProps && bookingEditProps[fieldName]?.rate}
          />
        </div>

        <div className={classModifier('duo-booking-form__fields-part', 'discount')}>
          <SelectFormField
            isSearchable
            fieldType='discount'
            bookingEditProps={bookingEditProps}
            setBookingEditProps={discount => {
              setBookingEditProps(prevState => ({
                ...prevState, 
                [fieldName]: { ...prevState[fieldName], discount }
              }))
            }}
            stateValue={bookingEditProps && bookingEditProps[fieldName]?.discount}
          />
        </div>

        <div className={classModifier('duo-booking-form__fields-part', 'taxi')}>
          <SelectFormField
            isSearchable
            fieldType='taxi'
            isDisabled={isDisabledTaxiField}
            bookingEditProps={bookingEditProps}
            setBookingEditProps={taxi => {
              setBookingEditProps(prevState => ({
                ...prevState, 
                [fieldName]: { ...prevState[fieldName], taxi }
              }))
            }}
            stateValue={bookingEditProps && bookingEditProps[fieldName]?.taxi}
          />
        </div>

        <div className={classModifier('duo-booking-form__fields-part', 'taxi')}>
          <textarea
            className="duo-booking-form__taxi-note"
            placeholder='Taxi Note...'
            value={bookingEditProps[fieldName]?.taxiNote || ''}
            onChange={(e) => {
              setBookingEditProps(prevState => ({
                ...prevState, 
                [fieldName]: { 
                  ...prevState[fieldName], 
                  taxiNote: e.target.value,
                }
              }))
            }} 
          />
        </div>
      </div>

      <div className='duo-booking-form__fields-wrapper'>
        <div className='duo-booking-form__fields-part'>
          <CalendarFormField 
            date={bookingEditProps.date} 
            setDate={date => {
              setBookingEditProps(prevState => ({ ... prevState, date }));
              checkBookingOverlap && checkBookingOverlap(date);
            }}
          />
        </div>

        <div className='duo-booking-form__fields-part'>
          <SelectFormField
            fieldType='bookingDuration'
            isDisabled={!bookingEditProps[fieldName]?.type || bookingDurationOptions.length === 0}
            bookingEditProps={bookingEditProps}
            setBookingEditProps={bookingDuration => {
              setBookingEditProps(prevState => ({
                ...prevState,
                [fieldName]: {
                  ...prevState[fieldName],
                  bookingDuration,
                  rate: bookingRateUpdate(
                    bookingDuration, 
                    prevState[fieldName]?.type, 
                    bookingEditProps[fieldName]?.rate,
                    profile
                  ),
                }
              }))
            }}
            stateValue={!!bookingDurationOptions.length ? bookingEditProps && bookingEditProps[fieldName]?.bookingDuration : null}
            options={bookingDurationOptions}
          />
        </div>
      </div>

      <div className={classModifier('duo-booking-form__fields-wrapper', 'services')}>
        <ServicesList 
          isBookingEditorPanel
          localFieldName={fieldName}
          fields={bookingEditProps && bookingEditProps[fieldName]?.services}
          setBookingEditProps={setBookingEditProps}
        />
      </div>
    </div>
  )
}

export default BookingEditFieldsItem;
