import React from 'react';
import classNames from 'classnames';
import { v4 } from 'uuid';

import { classModifier, getContactAvatar } from 'utils';
import { getDivaInfo } from 'utils/getDivaInfo';

import "./ExpandedContactBlock.scss";
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import ICONS from 'assets/icons';


export const height = 72;

const ExpandedContactBlock = (props) => {
  const {
    item: girlContact,
    className,
    onClick,
    onRemove,
  } = props;

  const isShowProfileInfo = (girlContact.diva_default_id && !girlContact.isDivaNotFound) ||
    girlContact.isProfile;

  const getContactName = () => {
    const strBefore = girlContact.fn || girlContact.name;

    if(strBefore?.startsWith('EX') && !!girlContact.is_ex) {
      return [<mark key={v4()} className="contact__ex">EX</mark>, strBefore.slice(2)];
    }

    return strBefore;
  }

  const handleClick = () => {
    if (!onClick) return;

    onClick(girlContact);
  }

  const classes = classNames(
    className,
    classModifier("contact", girlContact.isProfile && 'is-profile'),
  )

  return (
    <li
      className={classes}
      onClick={handleClick}
      style={handleClick && { cursor: 'pointer', height }}
    >
      <div className="contact__avatar-container">
        <LazyLoadImage
          alt="ava"
          className="contact__avatar"
          src={getContactAvatar(girlContact)}
        />
      </div>

      <div className="contact__info-container">
        <h6 className="contact__name-wrapper">
          {/* {!!girlContact.is_ex &&
            <mark className="contact__ex">
              EX
            </mark>
          } */}

          <span className="contact__name">
            {getContactName()}
          </span>

          {onRemove && (
            <button
              className="contact__remove"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              <ICONS.close />
            </button>
          )}
        </h6>

        <p className="contact__services">
          {isShowProfileInfo && getDivaInfo(girlContact)}
        </p>
      </div>
    </li>
  )
}

export default ExpandedContactBlock;
