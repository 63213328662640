import React, { memo } from 'react';
import Highlighter from "react-highlight-words";

import { parseMsgText } from 'utils';

const PinnedMsgsText = ({ interaction, highlightedText }) => {
  const highlightIfNeed = (text, highlightedText) => highlightedText
    ? <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={highlightedText.split(' ')}
      autoEscape={true}
      textToHighlight={text}
      key={Math.random()}
    />
    : text;

  const getMsgTextLayout = () => {
    if (interaction.hasUrls) {
      const parsedArray = parseMsgText(interaction.body);
      const parsedMsg = [];

      // if parseRegex find no match. Also it mean that server not parse msg correct;
      if (typeof parsedArray === 'string') {
        console.warn('Custom client error: Server bad parse interaction with id: ', interaction.id)
        return highlightIfNeed(parsedArray, highlightedText);
      }

      parsedArray.forEach((part, idx) => {
        switch (part.type) {
          case 'text':
            parsedMsg.push(part.value);
            break;
          case 'link':
            parsedMsg.push(
              <a
                className="interaction__link"
                href={part.value}
                target="_blank"
                rel="noopener noreferrer"
                key={idx}
              >
                &nbsp;{highlightIfNeed(part.value, highlightedText)}&nbsp;
              </a>
            );
            break;
          default:
            break;
        }
      });
      return parsedMsg;

    }
    else {
      return highlightIfNeed(parseMsgText(interaction.body, true), highlightedText)
    }
  }

  return (
    <>
      {getMsgTextLayout(interaction)}
    </>
  );
}

export default memo(PinnedMsgsText);