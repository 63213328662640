import { classModifier } from 'utils';
import './Checkbox.scss';

export default function Checkbox({ inputProps, input: finalFormInputProps, theme, size }) {
  return (
    <span
      className={classModifier('checkbox', theme)}
    >
      <input
        {...finalFormInputProps}
        className="checkbox__input" 
        type="checkbox"
        {...inputProps}
      />

      <span
        style={{
          ...(size ? { width: size, height: size } : {})
        }}
        className="checkbox__indicator"
      ></span>
    </span>
  )
}
