import { useEffect, useState } from 'react';
import classNames from 'classnames';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormTextarea from 'components/FormTextTextarea/FormTextarea';

const FormTextField = (props) => {
  const {
    label,
    className,
    labelClassName,
    inputClassName,
    inputWrapClassName,
    submitFailedNotRequiredForError,
    inputProps = {},
    inputType,
  } = props;

  const [labelId] = useState('id-' + Math.random().toString(32).slice(2));

  return (
    <div className={classNames('form-text-field', className)}>
      {label &&
        <label
          className={classNames('label', labelClassName)}
          htmlFor={labelId}
        >
          {label}
        </label>
      }

      {inputType === 'textarea'
        ? <FormTextarea
          {...props}
          className={inputWrapClassName}
          inputClassName={inputClassName}
          inputProps={{
            ...inputProps,
            id: label ? labelId : undefined,
          }}
        />
        : <FormTextInput
          {...props}
          className={inputWrapClassName}
          inputClassName={inputClassName}
          submitFailedNotRequiredForError={submitFailedNotRequiredForError}
          inputProps={{
            ...inputProps,
            id: label ? labelId : undefined,
          }}
        />
      }
    </div>
  )
}

export default FormTextField;
