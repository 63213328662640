import React from 'react';

import { useHideTemporaryTooltip, useToggle } from 'hooks';
import { classModifier } from 'utils';

import './InteractionMenu.scss';

const InteractionMenuItem = (props) => {
  const {
    tooltipOnHover,
    tooltipAfterAction,
    component: CustomMenuItem,
    componentProps,
    subMenu,
    action,
    icon,
    title,
    classMods,
    ...itemProps
  } = props.item;

  const [isSubMenuOpen, toggleSubMenu] = useToggle(false);

  const handleMenuItemClick = (action, tooltip, isSubMenuItem, e) => {
    if (!action) return;

    if (
      (e.currentTarget.className === 'popup-menu__item ii-sub-menu__item' && isSubMenuItem) ||
      (e.currentTarget.className.includes("ii-menu__item") && !e.target.closest('.ii-sub-menu__item'))
    ) {
      action()
        .then(() => {
          if (isSubMenuItem) {
            toggleSubMenu(false);
          }
          if (tooltip) {
            props.setStatus(tooltip);
          }
        })
        .catch(console.error)
    }
  }

  return (
    <li
      className={classModifier("ii-menu__item", classMods)}
      {...itemProps}
      onMouseEnter={() => subMenu ? toggleSubMenu(true) : null}
      onMouseLeave={() => subMenu ? toggleSubMenu(false) : null}
      onClick={(e) => handleMenuItemClick(action, tooltipAfterAction, false, e)}
    >
      {CustomMenuItem
        ? <CustomMenuItem />
        : <>
          <button className="ii-menu__btn">
            {title || icon}
          </button>
        </>
      }

      {subMenu && isSubMenuOpen &&
        <ul className={`ii-menu__sub-menu ii-sub-menu popup-menu
          ${tooltipOnHover === 'Glossary' ? 'ii-sub-menu--glossary' : ''}
        `}>
          {subMenu.map(({ title, action, tooltipOnHover, tooltipAfterAction, isDisabled, ...subItemProps }, idx) => {

            return (
              <li
                key={idx}
                className={`popup-menu__item ${classModifier('ii-sub-menu__item', [
                  isDisabled && 'disabled'
                ])}`}
                onClick={(e) => handleMenuItemClick(action, tooltipAfterAction, true, e)}
                {...subItemProps}
              >
                {title}
              </li>
            )
          })}
        </ul>
      }

      {tooltipOnHover &&
        <span className="ii-menu__tooltip">{tooltipOnHover}</span>
      }
    </li>
  )
}

const InteractionMenu = ({ menuItems, ...props }) => {
  const [status, setStatus] = useHideTemporaryTooltip('', 2000);

  if (!props.isOpen && !status) return null;

  const renderMenuItems = () => (
    menuItems.map((item, idx) => (
      <InteractionMenuItem
        item={item}
        setStatus={setStatus}
        key={idx}
        closeMenu={props.closeMenu}
      />
    ))
  );

  const renderStatus = () => (
    <li className="ii-menu__status">
      {status}
    </li>
  );

  return (
    <ul className="ii-menu">

      {status
        ? renderStatus()
        : renderMenuItems()
      }

    </ul>
  );
};


export default InteractionMenu;