// export default (arr) => arr.reduce((prev, item) => prev += item.length, 0);

export default (arr) => {
  let sum = 0;
  for (let i = 0; i < arr.length; i++) {
    sum += arr[i].length;
  }
  return sum;
}


