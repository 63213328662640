import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { selectOperatorsList } from 'redux/selectors/selectors';
import {
  ALL_FILTER,
  ONLINE_FILTER,
  filterOperators
} from 'redux/ducks/operators';

import './OperatorsList.scss';
import List from 'components/List/List';
import SidebarOperatorItem from './components/OperatorItem';
import SidebarContactsSelectFilter from 'containers/SidebarChatLists/components/SidebarContactsFilters/SidebarContactsSelectFilter';
import SidebarHeader from 'containers/SidebarChatLists/components/SidebarHeader/SidebarHeader';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';

const OperatorsList = ({
  operators = [],
  activeFilter,
  filterOperators,
  lastItemRef,
  selectorRef,
  handleHeaderClick,
  toggleOpenned,
}) => {
  const operatorsCount = operators.length;
  const [operatorFilterIsOpen] = useState(false);

  const filters = useMemo(() => [
    { name: ONLINE_FILTER, action: () => filterOperators(ONLINE_FILTER) },
    { name: ALL_FILTER, action: () => filterOperators(ALL_FILTER) },
  ], []);

  return (
    <div className="operators-list">
      <SidebarHeader
        className="sidebar-sections__title title"
        type='Operators'
        count={operatorsCount}
        handleClick={handleHeaderClick}
      />

      <SidebarContactsSelectFilter
        className="sidebar-sections__filters"
        filters={filters}
        defaultFilter={activeFilter}
        selectorRef={selectorRef}
        toggleOpenned={toggleOpenned}
        operatorFilterIsOpen={operatorFilterIsOpen}
      />

      <List
        list={operators}
        classPrefix="operators-list"
        scrollbarProps={{
          renderTrackVertical: CustomScrollbarTrack,
          renderThumbVertical: CustomScrollbarThumb
        }}
      >
        {({ index, ...restProps }) => (
          <SidebarOperatorItem
            activeFilter={activeFilter}
            operatorFilterIsOpen={operatorFilterIsOpen}
            {...restProps}
            {...(index === operatorsCount - 1 && { ref: lastItemRef })}
          />
        )}
      </List>
    </div>
  );
};

const mapStateToProps = (state) => ({
  operators: selectOperatorsList(state),
  activeFilter: state.operators.activeFilter,
});

const mapDispatchToProps = {
  filterOperators,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsList);
