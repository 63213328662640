import { useCallback, useState } from 'react';

const useToggle = (initial) => {
  const [value, setValue] = useState(initial);

  const toggle = useCallback((nextValue) => {
    if (typeof nextValue === 'boolean') {
      setValue(nextValue);
    }
    else {
      setValue(currentValue => !currentValue);
    }
  }, []);

  return [value, toggle];
};

export default useToggle;