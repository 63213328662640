import { Component } from 'react';

import formatTime from "utils/formatTime";

class Timer extends Component {
  state = {
    timerId: null,
    counter: null,
    lastTick: null
  }

  componentDidMount() {
    const timerId = setInterval(() => this.timerTick(), 1000);

    this.timerStart(timerId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.timer !== this.props.timer) {
      if (this.state.timerId) {
        clearInterval(this.state.timerId);

        const timerId = setInterval(() => this.timerTick(), 1000);

        this.timerStart(timerId);
      }

    }
  }

  componentWillUnmount() {
    this.timerStop()
  }

  timerStart = (timerId) => {
    this.setState({
      timerId,
      counter: Date.now() - this.props.timer,
      lastTick: Date.now()
    }, () => this.timerTick());
  }

  timerTick = () => {
    const now = Date.now();
    const diff = now - this.state.lastTick;

    this.setState((prevState) => {
      return {
        ...prevState,
        counter: prevState.counter + diff,
        lastTick: now
      }
    });

    this.props.onUpdate && this.props.onUpdate(this.state.counter);
  }

  timerStop = () => {
    this.props.onStop && this.props.onStop(this.state.counter);

    clearInterval(this.state.timerId);
  }

  render() {
    const { className, isClockMode, hour12, isSeconds = true } = this.props;
    
    return (
      <span className={className}>
        {formatTime(this.state.counter, {
          hour12,
          isSeconds,
          isClockFormat: isClockMode,
        })}
      </span>
    )
  }
}

export default Timer;