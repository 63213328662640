import { useSelector } from 'react-redux';
import { GirlsMapModalContext } from '../../GirlsMapModal';
import { selectMapGirlBySecondaryMode, selectMapGirlIdsBySecondaryMode } from 'redux/selectors/selectors';
import { useContext, useEffect } from 'react';
import BackToFullMapButton from '../ControlElements/BackToFullMapButton';
import { MAP_SECONDARY_MODES } from 'redux/ducks/map';
import useBuildNavigation from 'hooks/useBuildNavigation';
import SendEscortsButton from '../ControlElements/SendEscortsButton';
import SendNavigationPathButton from '../ControlElements/SendNavigationPathButton';


const TargetControls = {};

TargetControls.Header = () => {
  const { clientData } = useSelector(state => state.map);
  const [{ girl: targetGirl }] = useSelector(
    state => selectMapGirlBySecondaryMode(state, MAP_SECONDARY_MODES.TARGET_ESCORT)
  );

  const { map } = useContext(GirlsMapModalContext);

  const {
    initializeNavigation,
    buildNavigation,
    resetNavigation,
  } = useBuildNavigation();

  initializeNavigation(map);

  useEffect(() => {
    if (clientData.latitude && clientData.longitude) {
      const { latitude, longitude } = targetGirl;

      resetNavigation();
      buildNavigation(clientData, { latitude, longitude });

      return resetNavigation;
    }
  }, [clientData, targetGirl]);

  return (
    <div className="girls-map__header">
      <div className="girls-map__buttons-wrap">
        <BackToFullMapButton />
        {clientData.location && <SendNavigationPathButton />}
        <SendEscortsButton />
      </div>
    </div>
  )
}

TargetControls.Footer = () => <div className="girls-map__footer"></div>;

export default TargetControls;
