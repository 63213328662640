const convertToOptimizeBooking = (bookingItem, isNotFull = true) => {
  return {
    ...bookingItem,
    id: +bookingItem.id,
    caller: {
      photo: bookingItem.caller_avatar || bookingItem.caller?.photo,
      fn: bookingItem.caller_fn || bookingItem.caller?.fn,
      ...(bookingItem.caller || {})
    },
    group_girls: JSON.parse(bookingItem.group_girls),
    profile_pictures: JSON.parse(bookingItem.profile_pictures),
    is_deleted: Boolean(+bookingItem.is_deleted),
    is_prebooking: bookingItem.is_prebooking ? +bookingItem.is_prebooking : undefined,
    requirementsColors: typeof bookingItem.requirements === 'string' ? bookingItem.requirements.split(',') : bookingItem.requirements ? bookingItem.requirements : [],
    requirementsTitles: bookingItem.requirements_titles?.split(',') || [],
    requirements: [],
    usersIds: bookingItem.users_ids ? [+bookingItem.users_ids] : [null],
    availability: bookingItem.availability ? JSON.parse(bookingItem.availability) : null,
    ...(!!bookingItem.session_id ? { session_id: +bookingItem.session_id } : {}),
    ...(!!bookingItem.discount ? { discount: +bookingItem.discount } : {}),
    ...(!!bookingItem.discount_rate ? { discount: +bookingItem.discount_rate } : {}),
    ...(!!bookingItem.status ? { status: +bookingItem.status } : {}),
    ...(!!bookingItem.profile_id ? { profile_id: +bookingItem.profile_id } : {}),
    ...(!!bookingItem.is_prebooking ? { is_prebooking: +bookingItem.is_prebooking } : {}),
    isNotFull,
  }
}

export default convertToOptimizeBooking;
