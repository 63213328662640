import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { useDebounce } from 'hooks';

import './TasksAndRemindersModal.scss';
import Spinner from '../UI/Spinner/Spinner';
import SearchInput from '../SearchInput/SearchInput';
import CustomScrollbarTrack from '../UI/CustomScrollbarTrack/CustomScrollbarTrack';
import CustomScrollbarThumb from '../UI/CustomScrollbarThumb/CustomScrollbarThumb';
import List from 'components/List/List';
import TasksAndRemindersItem from './components/TasksAndRemindersItem';
import { LIMIT } from 'redux/ducks/clientChats';
import { getTasksAndReminders } from 'redux/ducks/notifications';
import { classModifier } from 'utils';
import { CONTACT_TYPES, INPUT_THEMES } from 'config/constants';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

const TasksAndRemindersModal = () => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState(null);

  const [pending, setPending] = useState(false);
  const [isShowComplete, setIsShowComplete] = useState(true);

  const dispatch = useDispatch();
  const tasksAndRemindersList = useSelector(state => state.notifications.tasksAndReminders)

  useEffect(() => {
    setPending(true)
    getTasksAndRemindersRequest({ isShowComplete, activeFilter, debouncedQuery })
      .then(() => setPending(false))
  }, [isShowComplete, activeFilter, debouncedQuery])

  const getTasksAndRemindersRequest = ({
    isShowComplete,
    activeFilter = null,
    debouncedQuery,
    offset,
    isLoadMore
  }) => {
    return dispatch(getTasksAndReminders({
      completed: isShowComplete,
      caller_type: activeFilter,
      search: debouncedQuery,
      offset,
      isLoadMore
    }))
  }

  const handleSearch = (query) => {
    setDebouncedQuery(query)
  };

  useDebounce(handleSearch, 700, query);

  const startSearch = (query) => {
    setQuery(query);
  };

  const stopSearch = () => {
    setQuery('');
  };

  const loadMoreTasksAndReminders = (offset) => {
    getTasksAndRemindersRequest({
      isShowComplete,
      activeFilter,
      debouncedQuery,
      offset,
      isLoadMore: true,
    });
  };

  return (
    <div className="tasks-and-reminders">
      <div className="tasks-and-reminders__header">
        <div className="tasks-and-reminders__filters-box">
          <div
            className="tasks-and-reminders__icon-wrapper"
            onClick={() =>
              setActiveFilter(
                activeFilter === CONTACT_TYPES.CLIENT
                  ? null
                  : CONTACT_TYPES.CLIENT
              )
            }
          >
            <ICONS.userSecret
              className={classModifier('tasks-and-reminders__icon-user',
                [activeFilter === CONTACT_TYPES.CLIENT && 'active'])
              }
            />
          </div>
          <div
            className="tasks-and-reminders__icon-wrapper"
            onClick={() =>
              setActiveFilter(
                activeFilter === CONTACT_TYPES.GIRL
                  ? null
                  : CONTACT_TYPES.GIRL
              )
            }
          >
            <ICONS.female
              className={classModifier('tasks-and-reminders__icon-female',
                [activeFilter === CONTACT_TYPES.GIRL && 'active'])
              }
            />
          </div>
        </div>

        <div className="tasks-and-reminders__search">
          <SearchInput
            inputClassName='tasks-and-reminders__search-input'
            inputWrapClassName='tasks-and-reminders__search-wrap'
            theme={INPUT_THEMES.dim}
            showSearchBtn
            showClearBtn
            query={query}
            setQuery={setQuery}
            placeHolder='Search'
            startSearch={startSearch}
            stopSearch={stopSearch}
          />
        </div>

        <div className="tasks-and-reminders__btn-wrap">
          <button
            className="tasks-and-reminders__btn"
            onClick={() => setIsShowComplete(!isShowComplete)}
          >
            {isShowComplete ? "Hide Complete" : "Show Complete"}
          </button>
        </div>

        <ICONS.plus
          className="tasks-and-reminders__icon-plus"
          onClick={() =>
            dispatch(
              openModal(MODAL_TYPES.addNewTasksOrRemaindersModal, {
                isCreate: true
              })
            )
          }
        />

      </div>

      <div className="tasks-and-reminders__bookings-list-wrap">

        {pending ? (
          <div className="tasks-and-reminders__spinner-wrap">
            <Spinner spinnerSize={40} />
          </div>
        ) : (
          <List
            limit={LIMIT}
            list={tasksAndRemindersList}
            classPrefix='tasks-and-reminders'
            scrollInitialPosition='top'
            // useIndexAsItemKey
            loadMore={(offset) => loadMoreTasksAndReminders(offset)}
            scrollbarProps={{
              autoHide: true,
              renderThumbVertical: CustomScrollbarThumb,
              renderTrackVertical: CustomScrollbarTrack,
            }}
          >
            <TasksAndRemindersItem />
          </List>
        )}
      </div>
    </div>
  );
};

export default TasksAndRemindersModal;
