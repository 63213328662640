import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';
import { INPUT_THEMES } from 'config/constants';
import "./BufferedGirlsBookingsModal.scss";

import { BookingSearchInput, BookingStatusFilters, DateTimeChanger, TodayButton, useFilteredBookings } from 'components/FilteredBookings';
import ListContainer from 'components/List/ListContainer';
import BookingItemNew from 'components/BookingItemNew/BookingItemNew';


const BufferedGirlsBookingsModal = ({ girlId }) => {
  const filteredBookingsConfig = { extraConfig: { girlId } };
  const { getters } = useFilteredBookings(filteredBookingsConfig)
  const {
    getBookingListProps,
    getBookingProps,
    getTodayButtonProps,
    getDateTimeChangerProps,
    getSearchBookingsProps,
    getStatusFilterProps,
  } = getters;

  return (
    <div className="buffered-girls-bookings-modal">
      <div className="buffered-girls-bookings-modal__header">
        <div className="buffered-girls-bookings-modal__main-filters">
          <TodayButton {...getTodayButtonProps()} />

          <DateTimeChanger
            className="buffered-girls-bookings-modal__date-changer"
            {...getDateTimeChangerProps()}
          />

          <BookingSearchInput
            inputClassName="buffered-girls-bookings-modal__search-input"
            placeHolder="Search"
            showSearchBtn
            showClearBtn
            theme={INPUT_THEMES.dim}
            {...getSearchBookingsProps()}
          />
        </div>

        <div className="buffered-girls-bookings-modal__statuses">
          <BookingStatusFilters {...getStatusFilterProps()}>
            <BookingStatusFilters.todo />
            <BookingStatusFilters.confirmed />
            <BookingStatusFilters.done />
            <BookingStatusFilters.cancelled />
          </BookingStatusFilters>
        </div>
      </div>

      <div className="buffered-girls-bookings-modal__body">
        <ListContainer
          classPrefix="buffered-girls-bookings-modal"
          autoHide
          {...getBookingListProps()}
        >
          <BookingItemNew
            type={BOOKING_ITEM_VIEWS.GIRL}
            {...getBookingProps()}
          />
        </ListContainer>
      </div>
    </div>
  )
}

export default BufferedGirlsBookingsModal;
