import API from "api/api";
import { prepareConference } from "utils";

export const NEW_WARM_TRANSFER = 'NEW_WARM_TRANSFER';
export const ACCEPT_WARM_TRANSFER = 'ACCEPT_WARM_TRANSFER';

export const CONFERENCE_PARTICIPANT_JOIN = 'CONFERENCE_PARTICIPANT_JOIN';
export const CONFERENCE_PARTICIPANT_LEAVE = 'CONFERENCE_PARTICIPANT_LEAVE';

export const NEW_CONFERENCE = 'NEW_CONFERENCE';
export const ACCEPT_CONFERENCE = 'ACCEPT_CONFERENCE';

export const CONFERENCE_INVITATION = 'CONFERENCE_INVITATION';
export const REMOVE_CONFERENCE = 'REMOVE_CONFERENCE';

export const GET_ALL_CONFERENCES = 'GET_ALL_CONFERENCES';

export const PARTICIPANT_USER = 1;
export const PARTICIPANT_CALLER = 2;
export const WARM_TRANSFER = 'warm_transfer';
export const CONFERENCE = 'conference';



export const getAllConferences = () => dispatch => {
  API.getAllConferences()
    .then(res => {
      if (!res.data.length) return;

      dispatch({
        type: GET_ALL_CONFERENCES,
        payload: res.data
      })
    })
}

export const newWarmTransfer = (conf) => dispatch => {
  dispatch({
    type: NEW_WARM_TRANSFER,
    payload: conf
  });
};

export const conferenceInvitation = (conf) => dispatch => {
  dispatch({
    type: CONFERENCE_INVITATION,
    payload: conf
  });
};

export const acceptWarmTransfer = (conf) => dispatch => {
  dispatch({
    type: ACCEPT_WARM_TRANSFER,
    payload: conf
  });
};

export const acceptConference = (conf) => dispatch => {
  dispatch({
    type: ACCEPT_CONFERENCE,
    payload: conf
  });
};

export const newConference = (conf) => dispatch => {
  dispatch({
    type: NEW_CONFERENCE,
    payload: conf
  });
};

export const conferenceParticipantJoin = (participant) => dispatch => {
  dispatch({
    type: CONFERENCE_PARTICIPANT_JOIN,
    payload: participant
  });
};

export const conferenceParticipantLeave = (participant) => dispatch => {
  dispatch({
    type: CONFERENCE_PARTICIPANT_LEAVE,
    payload: participant
  });
};

export const removeConference = (confId) => dispatch => {
  dispatch({
    type: REMOVE_CONFERENCE,
    payload: confId
  });
};

const initialState = {
  activeConference: {
    // caller_id: 691,
    // conf_sid: null,
    // creator_id: 1,
    // // isWarmTransfer: true,
    // date_created: "2019-09-25 07:07:35",
    // id: 1,
    // name: "conf_380664842339",
    // user_id: 1,
    // type: "conference"
    // type: WARM_TRANSFER
  },
  // activeConference: {},
  conferences: {
    // 1: {
    //   caller_id: 691,
    //   conf_sid: null,
    //   creator_id: 1,
    //   // isWarmTransfer: true,
    //   date_created: "2019-09-25 07:07:35",
    //   id: 1,
    //   name: "conf_380664842339",
    //   user_id: 1,
    //   type: "conference"
    //   // type: WARM_TRANSFER
    // }
  },
  participants: {
    // 1: {
    //   entities: {
    //     '1_1': {
    //       user_id: 1,
    //       type: 1,
    //       data_created: "2019-09-25 07:07:35",
    //       status: 'active',
    //       conference_id: 1
    //     },
    //     '2_1': {
    //       user_id: 2,
    //       type: 1,
    //       data_created: "2019-09-25 07:07:35",
    //       status: 'active',
    //       conference_id: 1
    //     }
    //   },
    //   ids: ['1_1', '2_1']
    // }
  }
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONFERENCES: {

      const participants = {};
      const conferences = {};

      action.payload.forEach(conf => {
        conf = prepareConference(conf);

        participants[conf.id] = {
          entities: conf.participantEntities,
          ids: conf.participantIds
        }

        conferences[conf.id] = conf;
      });

      return {
        ...state,
        conferences,
        participants
      }
    }
    case ACCEPT_CONFERENCE:
    case ACCEPT_WARM_TRANSFER: {
      let updatedConferences = state.conferences;

      if (state.conferences[action.payload.id]) {
        updatedConferences = { ...state.conferences };

        delete updatedConferences[action.payload.id];
      }

      const updatedParticipants = state.participants[action.payload.id] // when second user accept warm transfer => do not update participants
        ? state.participants
        : {
          ...state.participants,
          [action.payload.id]: {
            entities: action.payload.participantEntities,
            ids: action.payload.participantIds
          }
        };

      return {
        ...state,
        activeConference: action.payload,

        participants: updatedParticipants,
        conferences: updatedConferences
      };
    }

    case CONFERENCE_INVITATION:
    case NEW_WARM_TRANSFER: {
      return {
        ...state,
        conferences: {
          ...state.conferences,
          [action.payload.id]: action.payload
        },

        participants: {
          ...state.participants,
          [action.payload.id]: {
            entities: action.payload.participantEntities,
            ids: action.payload.participantIds
          }
        }
      };
    }

    case NEW_CONFERENCE: {
      return {
        ...state,
        activeConference: action.payload,

        participants: {
          ...state.participants,
          [action.payload.id]: {
            entities: action.payload.participantEntities,
            ids: action.payload.participantIds
          }
        }
      };
    }

    case CONFERENCE_PARTICIPANT_JOIN: {
      const participant = action.payload;

      const isInConference = state.participants[participant.conference_id];

      if (!isInConference) { return state; }

      const updatedParticipants = state.participants[participant.conference_id];

      const updatedParticipantsEntities = { ...updatedParticipants.entities };

      let updatedParticipantsIds = updatedParticipants.ids;

      const participantId = participant.type === PARTICIPANT_USER
        ? participant.user_id + '_' + PARTICIPANT_USER
        : participant.caller_id + '_' + PARTICIPANT_CALLER;

      if (!updatedParticipantsEntities[participantId]) {  // if not in entities
        updatedParticipantsIds = [...updatedParticipantsIds, participantId];
      }

      updatedParticipantsEntities[participantId] = participant;

      return {
        ...state,
        participants: {
          ...state.participants,
          [participant.conference_id]: {
            entities: updatedParticipantsEntities,
            ids: updatedParticipantsIds
          }
        }
      };
    }

    case CONFERENCE_PARTICIPANT_LEAVE: {
      const participant = action.payload;

      const isInConference = state.participants[participant.conference_id];

      if (!isInConference) { return state; }

      const participantId = participant.type === PARTICIPANT_USER
        ? participant.user_id + '_' + PARTICIPANT_USER
        : participant.caller_id + '_' + PARTICIPANT_CALLER;

      const updatedParticipants = state.participants[participant.conference_id];

      const updatedParticipantsEntities = { ...updatedParticipants.entities }; // remove from entities

      delete updatedParticipantsEntities[participantId];

      const updatedParticipantsIds = updatedParticipants.ids.filter(id => id !== participantId); // remove from ids

      return {
        ...state,
        participants: {
          ...state.participants,
          [participant.conference_id]: {
            entities: updatedParticipantsEntities,
            ids: updatedParticipantsIds
          }
        }
      };
    }

    case REMOVE_CONFERENCE: {
      const updatedActiveConference = action.payload === state.activeConference.id
        ? {}
        : state.activeConference;

      let updatedConferences = state.conferences;

      if (state.conferences[action.payload]) {
        updatedConferences = { ...state.conferences };

        delete updatedConferences[action.payload];
      }

      let updatedParticipants = state.participants;

      if (state.participants[action.payload]) {
        updatedParticipants = { ...state.participants };

        delete updatedParticipants[action.payload];
      }

      return {
        ...state,
        activeConference: updatedActiveConference,
        conferences: updatedConferences,
        participants: updatedParticipants
      };
    }

    default:
      return state;
  }
};