import { useState, useEffect } from 'react';


export default (initial, time = 1000) => {
  const [status, setStatus] = useState(initial);

  useEffect(() => {
    if (!status) return;
    const timer = setTimeout(() => setStatus(''), time);

    return () => clearTimeout(timer);
  }, [status, time]);

  return [status, setStatus]
}