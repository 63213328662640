import React, { useState, useCallback, useRef } from "react";

import API from "api/api";
import { useDidMount } from "hooks";
import { CONTACTS_LIMIT } from "redux/ducks/contacts";

import './ContactLogsTab.scss';
import List from "components/List/List";
import Spinner from "components/UI/Spinner/Spinner";
import ContactLogItem from "./components/ContactLogItem";
import CustomScrollbarTrack from "components/UI/CustomScrollbarTrack/CustomScrollbarTrack";

const ContactLogsTab = props => {
  const [log, setLog] = useState([]);
  const [pending, setPending] = useState(true);

  const logHistoryRef = useRef();
  const logItemHeight = 40;

  useDidMount(() => {
    const isEnoughForScroll = logHistoryRef.current.clientHeight / logItemHeight > CONTACTS_LIMIT;
    const coefficient = Math.ceil(logHistoryRef.current.clientHeight / logItemHeight / CONTACTS_LIMIT);

    if(isEnoughForScroll) {
      getContactLog('', CONTACTS_LIMIT * coefficient);
    } else getContactLog();
  });

  const getContactLog = (offset = '', limit) => {
    if (!props.id) {
      setLog([]);
      setPending(false);
      return;
    }

    API.getContactLog(props.id, offset, limit)
      .then(res => setLog(prevLog => [...prevLog, ...res.data]))
      .catch(console.error)
      .finally(() => setPending(false))
  };

  const Component = useCallback((props) => <ContactLogItem {...props} />, []);
  const loadMore = useCallback((offset) => {
    getContactLog(offset);
  }, []);
  
  return (
    <div ref={logHistoryRef} className="contact-log-history">
      {pending
        ? <div className="contact-log-history__load-wrap">
            <Spinner spinnerSize={30} />
          </div>
        : <List
            list={log}
            scrollInitialPosition='top'
            classPrefix='contact-log-history'
            loadMore={loadMore}
            scrollbarProps={{ renderTrackVertical: CustomScrollbarTrack }}
          >
            <Component />
          </List>
      }
    </div>
  );
};

export default ContactLogsTab;
