import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { classModifier } from 'utils';

import { GirlsMapModalContext } from '../../GirlsMapModal';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';
import { MAP_GIRL_FILTERS, MAP_PRIMARY_MODES, updateFilter, updateFindedGirlId, updatePrimaryMode } from 'redux/ducks/map';
import { selectIsMapFilterSelected } from 'redux/selectors/selectors';


const FindEscortButton = () => {
  const { filteredGirlIdsByFilters } = useSelector((state) => state.map);
  const isFindedGirlFilterEnabled = useSelector(
    (state) => selectIsMapFilterSelected(state, MAP_GIRL_FILTERS.FINDED_ESCORT)
  )
  const { findGirlMarkerOnMap } = useContext(GirlsMapModalContext);
  
  const dispatch = useDispatch();
  
  const findedGirlId = filteredGirlIdsByFilters[MAP_GIRL_FILTERS.FINDED_ESCORT]?.[0];

  const handleFindByName = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      onChoose: (props) => {
        dispatch(updateFindedGirlId(props.id));
        dispatch(closeModal(MODAL_TYPES.searchList));
      }
    }))
  }

  const handleClick = () => {
    if (isFindedGirlFilterEnabled) {
      dispatch(updateFilter(MAP_GIRL_FILTERS.FINDED_ESCORT));
    } else {
      handleFindByName()
    }
  }

  useEffect(() => {
    if (!findedGirlId) return;

    findGirlMarkerOnMap(findedGirlId);
    dispatch(updatePrimaryMode(MAP_PRIMARY_MODES.ALL_ESCORTS));
    dispatch(updateFilter(MAP_GIRL_FILTERS.FINDED_ESCORT));
  }, [findedGirlId])

  return (
    <button
      className={classModifier("girls-map__toggle-btn", [isFindedGirlFilterEnabled && 'active'])}
      onClick={handleClick}
    >
      Find Escort By Name
    </button>
  )
}

export default FindEscortButton;
