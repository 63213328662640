import chroma from 'chroma-js';
import React from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import classNames from 'classnames';

import { classModifier } from 'utils';
import { selectTagColor, selectTagProps } from 'redux/selectors/selectors';

import './TagBlock.scss';


const TagBlock = React.forwardRef((props, ref) => {
  const {
    id,
    className,
    children,
    title,
    isImportant,
    isCapitalized,
    showRemoveButton,
    isHiddenRemoveButton,
    containerProps,
    goingToBeCreated,
    goingToBeDeleted,
    onClick,
  } = props;

  const color = useSelector(state => selectTagColor(state, id));

  const value = children || title || props.value;

  const getCustomStyles = () => ({
    backgroundColor: color,
    color: chroma.contrast(color, '#fff') > 2 ? '#fff' : '#000'
  })

  const classes = classNames(
    className,
    classModifier("tag", [
      value && value.split(" ").join("-").toLowerCase(),
      isImportant && "important",
      isCapitalized && "capitalized",
      containerProps?.type === 'option' && 'option',
      goingToBeCreated && 'going-to-be-created',
      goingToBeDeleted && 'going-to-be-deleted',
    ]),
  )

  const innerProps = {
    className: classes, 
    title: children,
    style: color && getCustomStyles(),
    ref,
    onClick,
  }

  if (containerProps?.type === 'option') {
    return (
      <components.Option {...containerProps}>
        <div {...innerProps}>
          {value}
        </div>
      </components.Option>
    )
  }

  return (
    <components.MultiValueContainer innerProps={innerProps} {...containerProps}>
      <components.MultiValueLabel>
        {value}
      </components.MultiValueLabel>

      {showRemoveButton && !isHiddenRemoveButton && (
        <components.MultiValueRemove
          innerProps={{ className: "tag__remove-btn", ...containerProps?.removeProps }}
        />
      )}
    </components.MultiValueContainer>
  )
})

export default TagBlock;

