import { connect } from 'react-redux';

// import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import Timer from 'components/Timer/Timer';
import { selectUserTimezone } from 'redux/selectors/selectors';

const TimezoneClock = ({ userTimezone, ...props }) => {
  const localTimezone = new Date().getTimezoneOffset() * (-1);

  if ((!userTimezone && userTimezone !== 0) || userTimezone === localTimezone) {
    return null;
  }

  return (
    <button
      className="user-menu__clock"
      // onClick={() => props.openModal(MODAL_TYPES.userSettings, { defaultTabIndex: 2 })} //TODO v2
    >
      <Timer
        timer={new Date().setHours(0, userTimezone * (-1) + localTimezone, 0, 0)}
        isClockMode
        hour12={props.userHour12}
      />
    </button>
  );
}

const mapStateToProps = state => ({
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12
});

const mapDispatchToProps = {
  // openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneClock);