import React, { useState } from 'react'

import { NO_BOOKINGS_LABELS } from 'components/FilteredBookings';
import { getContactAvatar } from 'utils';

import './BookingsList.scss'
import BookingConfinedItem from 'components/BookingConfinedItem/BookingConfinedItem';
import List from 'components/List/List';
import {
  BookingFiltersList,
  useFilteredBookings,
  BookingFilters,
  BookingSearchInput
} from 'components/FilteredBookings';
import { useSelector } from 'react-redux';
import { selectActiveGirl } from 'redux/selectors/selectors';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import BookingItemNew from 'components/BookingItemNew/BookingItemNew';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';

const limit = 30;

const BookingsListHeader = ({ getters, girl }) => {
  const {
    getSearchBookingsProps,
    getFiltersContainerProps,
    getFilterProps,
  } = getters;

  return (
    <BookingFiltersList
      className="bookings-list__header"
      {...getFiltersContainerProps()}
    >
      <BookingFilters
        className="bookings-list__filters"
        {...getFilterProps()}
      >
        <BookingFilters.outcall />
        <BookingFilters.duo />
        <BookingFilters.prebooking />
        <BookingFilters.agent />

        {girl?.diva_default_id && (
          <BookingFilters.currentGirl tooltip={girl.short_name}>
            <LazyLoadImage
              src={getContactAvatar(girl)}
              className='round-img'
              alt='girls_avatar'
            />
          </BookingFilters.currentGirl>
        )}
      </BookingFilters>

      <BookingSearchInput
        placeholder="Search booking"
        inputClassName="bookings-list__search-input"
        inputWrapClassName="bookings-list__search-input-wrap"
        showSearchBtn
        showClearBtn
        {...getSearchBookingsProps()}
      />
    </BookingFiltersList>
  )
}

const BookingsList = () => {
  const activeGirlFromChats = useSelector(selectActiveGirl);
  const { getters } = useFilteredBookings({
    limit,
    isAutoScrollToActualTime: true,
    extraFiltersConfig: {
      girlId: activeGirlFromChats?.diva_default_id
    }
  });

  const [oppenedChatCallerId, setOppenedChatCallerId] = useState(null);

  const {
    getBookingListProps,
    getBookingProps,
  } = getters;

  return (
    <div className="bookings-list">
      <BookingsListHeader
        getters={getters}
        girl={activeGirlFromChats}
      />

      <List
        classPrefix="bookings-list"
        {...getBookingListProps()}
      >
        <BookingItemNew
          type={BOOKING_ITEM_VIEWS.GTab}
          oppenedChatCallerId={oppenedChatCallerId}
          setOppenedChatCallerId={setOppenedChatCallerId}
          {...getBookingProps()}
        />
      </List>
    </div>
  );
}

export default BookingsList;
