import React from 'react';



const AttachmentLinks = ({ links, siteTitle }) => {
  const firstLink = links[0];

  return (
    <div className="attachments__links">
      <div className="attachments__row">
        {firstLink.site_icon &&
          <div className="attachment-link__icon">
            <img src={firstLink.site_icon} alt="" />
          </div>
        }
        <span className="attachment-link__name">{firstLink.site_name}</span>
      </div>

      {firstLink.site_title &&
        <div className="attachments__row">
          <a
            href={firstLink.site_url}
            target="_blank"
            title={siteTitle}
            rel="noopener noreferrer"
            className="attachment-link__url"
          >
            {firstLink.site_title}
          </a>
        </div>
      }

      {firstLink.site_description &&
        <div className="attachments__row">
          <p className="attachment-link__desc">{firstLink.site_description}</p>
        </div>
      }

      {firstLink.site_img &&
        <div className="attachments__row">
          <div className="attachment-link__img">
            <img src={firstLink.site_img} alt="" />
          </div>
        </div>
      }
    </div>
  );
}

export default AttachmentLinks;
