import React from 'react';

import { CHAT_TYPES } from '../../../../../config/constants';
import { DATE_INDICATOR_CONFIG } from 'config/dates-сonfig';
import { getDateByTimezoneOffset, getShortDate } from '../../../../../utils';

import './DateIndicator.scss';
import ICONS from 'assets/icons';
import DateTime from 'components/DateTime';

const DateIndicator = ({ groupDate, userTimezone, ...props }) => {
  const setContextDateRef = () => {
    if (props.contextDate) {
      const contextDateByTimezone = getShortDate(getDateByTimezoneOffset(userTimezone, props.contextDate));

      const formattedGroupDate = getShortDate(groupDate);

      return contextDateByTimezone === formattedGroupDate
        ? props.contextDateRef
        : null;
    }

    return null;
  }

  return (
    <div className="date-indicator" ref={setContextDateRef()}>
      <button
        className="date-indicator__btn"
        onClick={() => props.showTimePickerForDateContext
          ? props.showTimePickerForDateContext()
          : null
        }
      >
        <DateTime
          ignoreTimezone
          className="date-indicator__date"
          date={groupDate}
          config={DATE_INDICATOR_CONFIG}
        />

        {props.chatType !== CHAT_TYPES.ROOM &&
          <ICONS.calendar
            title="calendar"
            width="14"
            height="14"
            className="date-indicator__icon"
          />
        }
      </button>
    </div>
  )
}

export default DateIndicator;
