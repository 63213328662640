import API from "api/api";
import { CHAT_SOURCES } from "config/constants";
import { normalizeContacts } from "utils";

export const GET_OPERATORS_LIST = "GET_OPERATORS_LIST";
export const CHANGE_OPERATOR_STATUS = "CHANGE_OPERATOR_STATUS";
export const CHANGE_OPERATOR_TYPING_STATUS = "CHANGE_OPERATOR_TYPING_STATUS";
export const UPDATE_ACTIVE_OPERATOR = "UPDATE_ACTIVE_OPERATOR";
export const CHANGE_OPERATOR_ENTITY = "CHANGE_OPERATOR_ENTITY";
export const FILTER_ROOMS = "FILTER_ROOMS";
export const SET_USERS_ON_MAIL = "SET_USERS_ON_MAIL";
export const ADD_USER_ON_MAIL = "ADD_USER_ON_MAIL";
export const ALL_FILTER = "All operators";
export const REMOVE_USER_ON_MAIL = "REMOVE_USER_ON_MAIL";
export const ONLINE_FILTER = "Online operators";
export const CHANGE_CHAT_SOURCE_PUBLIC = "CHANGE_CHAT_SOURCE_PUBLIC";

export const getOperatorsList = () => (dispatch, getState) => {
  const state = getState();

  API.getOperatorsList()
    .then(res => {
      const filteredOperators = res.data.filter(o => o.id !== state.user.id);

      dispatch({
        type: GET_OPERATORS_LIST,
        payload: normalizeContacts(filteredOperators, false)
      });
    })
    .catch(console.error);
};

export const filterOperators = (filter) => (dispatch) => {
  dispatch({
    type: FILTER_ROOMS,
    payload: { filter },
  })
}

export const changeOperatorStatus = operator => ({
  type: CHANGE_OPERATOR_STATUS,
  payload: operator
});
// not used
export const changeOperatorTypingStatus = (id, isTyping) => dispatch => {
  dispatch({
    type: CHANGE_OPERATOR_TYPING_STATUS,
    payload: { id, isTyping }
  });
};
//not used TODO: create func for this
export const debouncedOperatorTypingStop = (() => {
  let timerList = {};

  return id => dispatch => {
    if (timerList[id]) {
      clearTimeout(timerList[id]);
    }

    const functionCall = (id => () =>
      dispatch(changeOperatorTypingStatus(id, false)))(id);

    timerList[id] = setTimeout(functionCall, 5000);
  };
})();

export const changeOperatorEntity = entity => dispatch => {
  dispatch({
    type: CHANGE_OPERATOR_ENTITY,
    payload: entity
  });
};

export const enterOnTheMailPage = (emailId) => dispatch => {
  return API.enterOnTheMailPage(emailId)
    .then(res => {
      dispatch({
        type: SET_USERS_ON_MAIL,
        payload: {
          userIds: res.data
        }
      })
    })
    .catch(console.error);
}

export const exitOnTheMailPage = (emailId) => () => {
  return API.exitOnTheMailPage(emailId)
    .catch(console.error);
}

export const addUserOnMail = (id) => ({ type: ADD_USER_ON_MAIL, payload: { id }});
export const removeUserOnMail = (id) => ({ type: REMOVE_USER_ON_MAIL, payload: { id }});

const initialState = {
  entities: {},
  ids: [],
  onMailPage: [],
  activeFilter: ONLINE_FILTER,
  chatSource: CHAT_SOURCES.MSGS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPERATORS_LIST: {
      return {
        ...state,
        ...action.payload
      };
    }
    case CHANGE_OPERATOR_STATUS: {
      const updatedEntity = state.entities[action.payload.user_id];

      if (!updatedEntity) return state;

      const updatedEntities = {
        ...state.entities,
        [updatedEntity.id]: {
          ...updatedEntity,
          status: action.payload.status
        }
      };

      return {
        ...state,
        entities: updatedEntities
      };
    }

    case CHANGE_OPERATOR_ENTITY: {
      const prevEntity = state.entities[action.payload.id] || {};
      const updatedEntities = {
        ...state.entities,
        [action.payload.id]: { ...prevEntity ,...action.payload }
      };

      return {
        ...state,
        entities: updatedEntities,
      };
    }

    case FILTER_ROOMS: {
      return {
        ...state,
        activeFilter: action.payload.filter,
      }
    }

    case SET_USERS_ON_MAIL: {
      return {
        ...state,
        onMailPage: action.payload.userIds,
      }
    }

    case ADD_USER_ON_MAIL: {
      return {
        ...state,
        onMailPage: [...new Set([...state.onMailPage, action.payload.id])],
      }
    }

    case REMOVE_USER_ON_MAIL: {
      return {
        ...state,
        onMailPage: state.onMailPage.filter((userId) => userId !== action.payload.id)
      }
    }

    case CHANGE_CHAT_SOURCE_PUBLIC: {
      return {
        ...state,
        chatSource: action.payload,
      }
    }

    default:
      return state;
  }
};
