import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { MAP_SECONDARY_MODES } from 'redux/ducks/map';
import { selectMapGirlBySecondaryMode, selectMostImportantSecondaryMode } from 'redux/selectors/selectors';
import { classModifier } from 'utils';

import ICONS from 'assets/icons';


const convertGirlObjectForSendingModal = ({ girl, profile }) => ({
  ...profile,
  photo: girl?.photo,
  isProfile: true,
})

const SendEscortsButton = () => {
  const mostImportantSecondaryMode = useSelector(selectMostImportantSecondaryMode);
  const girlsForSending = useSelector(
    (state) => selectMapGirlBySecondaryMode(state, mostImportantSecondaryMode, { shouldBeFiltered: true })
  )
  
  const dispatch = useDispatch();

  let sendEscortsButtonText;

  if (mostImportantSecondaryMode === MAP_SECONDARY_MODES.RADIUS_ESCORTS || !mostImportantSecondaryMode) {
    sendEscortsButtonText = 'Send All Escorts';
  } else if (mostImportantSecondaryMode === MAP_SECONDARY_MODES.SELECT_ESCORTS) {
    sendEscortsButtonText = `Send ${girlsForSending?.length || 0} Escorts`;
  } else {
    sendEscortsButtonText = 'Send Escort';
  }

  const handleClick = () => {
    const formatedGirlsForSending = Array.isArray(girlsForSending)
      ? girlsForSending.map(convertGirlObjectForSendingModal)
      : [convertGirlObjectForSendingModal(girlsForSending)];

    dispatch(openModal(MODAL_TYPES.sendGirlsToClientModal, { selectedGirls: formatedGirlsForSending }));
  }

  return (
    <button
      className={classModifier("girls-map__toggle-btn", "send")}
      onClick={handleClick}
      disabled={Array.isArray(girlsForSending) ? !girlsForSending?.length : !girlsForSending}
    >
      {sendEscortsButtonText}
      <ICONS.signOut className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default SendEscortsButton;
