import React, { useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import {
  addProposedId,
  removeProposedId,
} from "redux/ducks/sessions";
import {
  selectActiveSessionContact,
  selectActiveSessionId,
  selectCanIEditSession,
  selectIsCurrentOperatorProposedProfile,
  selectIsPartnersSession,
  selectIsProfileInBooked
} from "redux/selectors/selectors";
import ICONS from "assets/icons";
import { classModifier } from "utils";
import { MODAL_TYPES, openModal } from "redux/ducks/activeWindows";

import "./BookButton.scss";

const BookButton = ({ profileId, isFromSessionChat = false, className, ...props }) => {
  const activeSessionContact = useSelector(selectActiveSessionContact);
  const activeSessionId = useSelector(selectActiveSessionId);

  const dropdownRef = useRef();
  const dispatch = useDispatch();

  const isBookedBtn = className === 'booked-button';

  const onChangeBooked = e => {
    if (props.isPartners) {
      return isChecked
        ? props.removeProposedId(profileId, props.activeSession, props.myId)
        : props.addProposedId(profileId, props.activeSession, props.myId);
    }
    else {
      dispatch(openModal(MODAL_TYPES.bookingForm, {
        initialProfile: props.profile,
        initialActiveSessionContact: activeSessionContact,
        activeSessionId,
      }));
    }
  };

  const getStylesIfProfileInBooked = () => isChecked && !isFromSessionChat
    ? { height: '25px', opacity: '1' }
    : null;

  const isChecked = props.isPartners
    ? props.isInProposed
    : props.isInBooked;


  const getButtonText = () => {
    if (!isChecked ) return 'Book';

    if (isChecked && isBookedBtn) {
      return (
        <p className={`${className}__checkbox-text`}>
          Booked
          <span className={`${className}__checkbox-icon`}><ICONS.check /></span>
        </p>
      );
    } else {
      return 'Booked';
    }
  }

  return (
    <div
      className={className}
      style={getStylesIfProfileInBooked()}
      ref={dropdownRef}
    >
      <div
        className={classModifier(`${className}__checkbox`, isChecked && 'active')}
        onClick={onChangeBooked}
      >
        {props.isPartners
          ? 'Propose'
          : getButtonText()
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  profile: state.divaGirls.entities[ownProps.profileId],
  isInProposed: selectIsCurrentOperatorProposedProfile(state, ownProps.profileId),
  isInBooked: selectIsProfileInBooked(state, ownProps.profileId),
  activeSession: state.sessions.activeSession,
  canIEditSession: selectCanIEditSession(state),
  isPartners: selectIsPartnersSession(state),
  myId: state.user.id // FIXME: Backend should take operator id from connectToken
});

const mapDispatchToProps = {
  addProposedId,
  removeProposedId
};

export default connect(mapStateToProps, mapDispatchToProps)(BookButton);
