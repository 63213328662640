import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { classModifier } from 'utils';
import { BOOKING_MESSAGES_DATE_CONFIG } from 'config/dates-сonfig';

import "./BookingLinedItem.scss";

const DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const BookingLinedItem = (props) => {
  const {
    Escort,
    Badges,
    Details,
    Client,
    Status,
    Date,
    ConfirmationStatus,
    Operator,
    Markers,
    Requirements,
    statusColor,
    innerRef,
    isDeleted,
    handleOpenBookingModal
  } = props;

  return (
    <div
      className={classModifier('booking-lined-item', isDeleted && 'deleted')}
      onClick={handleOpenBookingModal}
      ref={innerRef}
    >
      <div
        className="booking-lined-item__color-line"
        style={{ backgroundColor: statusColor }}
      />

      <div className={classModifier("booking-lined-item__column", 'escort')}>
        <Escort className="booking-lined-item__escort" namesAlignment="vertical" />
      </div>

      <div className={classModifier("booking-lined-item__column", 'duo')}>
        <Badges />
      </div>

      <div className={classModifier("booking-lined-item__column", 'details')}>
        <Details items={DETAILS_ITEMS} />
      </div>

      <div className={classModifier("booking-lined-item__column", 'requirements')}>
        <Requirements className="booking-lined-item__requirements" />
      </div>

      <div className={classModifier("booking-lined-item__column", 'status')}>
        <Status />
        <Date className="booking-lined-item__date" config={BOOKING_MESSAGES_DATE_CONFIG} />
      </div>

      <div className={classModifier("booking-lined-item__column", 'client')}>
        <Client className="booking-lined-item__client" isAvatarShowed />
      </div>

      <div className={classModifier("booking-lined-item__column", 'rest')}>
        <div className="booking-lined-item__column-row">
          <Markers />
          <ConfirmationStatus />
        </div>
        <Operator />
      </div>
    </div>
  )
}

export default BookingLinedItem;
