import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';

import API from 'api/api';
import { GOOGLE_MAPS_API_KEY } from 'config/constants';
import { MAP_LIBRARIES } from './config/config';
import { DEFAULT_ZOOM, MAP_PRIMARY_MODES, MAP_SECONDARY_MODES, resetMapState, updateBufferedGirlIds, updateCenter, updateClientCoordinates, updateGirlsCoordinatesData, updateMapState, updatePrimaryMode, updateSecondaryMode, updateSecondaryModeId, updateZoom } from 'redux/ducks/map';
import { selectActiveSession, selectMapSecondaryMode } from 'redux/selectors/selectors';
import { closeModal } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';

import './GirlsMapModal.scss';
import ICONS from 'assets/icons';
import GirlsMap from './components/GirlsMap/GirlsMap';
import DefaultControls from './components/MapControls/DefaultControls';
import AllEscortsControls from './components/MapControls/AllEscortsControls';
import TargetControls from './components/MapControls/TargetControls';


export const GirlsMapModalContext = React.createContext();

const ControlComponents = {
  [MAP_PRIMARY_MODES.CHAT_ESCORTS]: DefaultControls,
  [MAP_PRIMARY_MODES.ALL_ESCORTS]: AllEscortsControls,
  [MAP_PRIMARY_MODES.TARGET_ESCORT]: TargetControls,
}

const GirlsMapModal = ({ mapProps }) => {
  const activeSession = useSelector(selectActiveSession);
  const callerIdsOfActiveGirlChats = useSelector((state) => state.girlChats.tabs);
  const { primaryMode, zoom, updated } = useSelector((state) => state.map);
  const selectionMode = useSelector((state) => selectMapSecondaryMode(state, MAP_SECONDARY_MODES.SELECT_ESCORTS));

  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);

  const dispatch = useDispatch();

  const mapRef = useRef();
  const mapsRef = useRef();
  const shouldStateBeResettedRef = useRef(true);

  const saveMapState = () => shouldStateBeResettedRef.current = false;

  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: MAP_LIBRARIES,
  });

  const smoothZoom = (map, maxZoom, currentZoom) => {
    if (currentZoom < maxZoom) {
      const listener = google.maps.event.addListener(map, 'zoom_changed', () => {
        google.maps.event.removeListener(listener);
        smoothZoom(map, maxZoom, currentZoom + 1);
      });
      setTimeout(() => {
        dispatch(updateZoom(DEFAULT_ZOOM));
      }, 100);
    }
  };

  const flyTo = (coordinates, newZoom = zoom, isMarker = false) => {
    const map = mapRef.current;

    if (isMarker) {
      smoothZoom(map, newZoom, map.getZoom())
    } else {
      dispatch(updateZoom(newZoom));
    }

    dispatch(updateCenter(coordinates));
  };

  const fitBounds = (bounds, value) => mapRef.current.fitBounds(bounds, value);

  const findGirlMarkerOnMap = (targetGirlName) => {
    return API.getGirlOnMap(targetGirlName)
      .then((data) => {
        return new Promise ((resolve, reject) => {
          if (!data.data.length) return reject();

          const coordinates = {
            lat: parseFloat(data.data[0].latitude),
            lng: parseFloat(data.data[0].longitude),
          }

          dispatch(updateGirlsCoordinatesData(data));
          flyTo(coordinates, 20);

          return resolve();
        })
      });
  }

  const flyToCurrectGirlMarkers = () => {
    API.getGirlsCoordinatesByIds(callerIdsOfActiveGirlChats)
      .then((data) => {
        dispatch(updateGirlsCoordinatesData(data));

        const girlsFromActiveChats = data.data.filter((caller) => caller.is_default);

        if (!girlsFromActiveChats.length) return;

        if (girlsFromActiveChats.length === 1) {
          flyTo(
            {
              lat: parseFloat(girlsFromActiveChats[0].latitude),
              lng: parseFloat(girlsFromActiveChats[0].longitude)
            }
          );
        } else {
          const bounds = new mapsRef.current.LatLngBounds();

          girlsFromActiveChats.forEach((girl) => {
            bounds.extend({
              lat: parseFloat(girl.latitude),
              lng: parseFloat(girl.longitude),
            })
          })

          fitBounds(bounds, 400)
        }

      })
      .catch(console.error)
  }

  useEffect(() => {
    dispatch(updateBufferedGirlIds(activeSession?.bufferedIds || []));
  }, [activeSession])

  useEffect(() => {
    if (mapProps?.clientLocation) {
      dispatch(updateClientCoordinates(mapProps.clientLocation));
    }

    if (mapProps?.profile_id) {
      dispatch(updatePrimaryMode(MAP_PRIMARY_MODES.TARGET_ESCORT));
      dispatch(updateSecondaryMode(MAP_SECONDARY_MODES.TARGET_ESCORT, true));
      dispatch(updateSecondaryModeId(MAP_SECONDARY_MODES.TARGET_ESCORT, mapProps));
    }

    if (!mapProps && !updated) {
      dispatch(updatePrimaryMode(MAP_PRIMARY_MODES.CHAT_ESCORTS));
      flyToCurrectGirlMarkers();
    }

    dispatch(updateMapState());

    return () => shouldStateBeResettedRef.current && dispatch(resetMapState());
  }, [mapProps])

  const handleEscClick = () => dispatch(closeModal());

  const ControlComponent = ControlComponents[primaryMode] || DefaultControls;

  const contextProps = {
    flyTo,
    fitBounds,
    isGoogleMapLoaded,
    setIsGoogleMapLoaded,
    saveMapState,
    findGirlMarkerOnMap,
    map: mapRef.current,
    maps: mapsRef.current,
    shouldStateBeResetted: shouldStateBeResettedRef.current,
  }

  return (
    <GirlsMapModalContext.Provider value={contextProps}>
      <div className={classModifier("girls-map", selectionMode && 'selection-mode')}>
        <button className="girls-map__esc-btn" onClick={handleEscClick}>
          <ICONS.close className="girls-map__esc-btn-icon" /> (Esc)
        </button>

        {isGoogleMapLoaded && <ControlComponent.Header />}
        {isGoogleApiLoaded && <GirlsMap {...{ mapRef, mapsRef }} />}
        {isGoogleMapLoaded && <ControlComponent.Footer />}
      </div>
    </GirlsMapModalContext.Provider>
  );
};

export default GirlsMapModal;
