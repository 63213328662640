import { useEffect, useState } from 'react';

const useIntervalCounter = (limit, suspense) => {
  let timer;
  const [counter, setCounter] = useState(0);

  const iterator = () => {
    timer = setInterval(() => {
      if (counter === limit) {
        setCounter(0)
      }
  
      setCounter((prev) => prev + 1);
    }, suspense)
  };

  useEffect(() => {
    iterator();

    return () => clearInterval(timer);
  })

  return counter;
};

export default useIntervalCounter;
