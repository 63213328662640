import React, { memo } from 'react';

import { INPUT_THEMES } from 'config/constants';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';

import './BookingsByDateModal.scss';
import {
  BookingFilters,
  BookingFiltersList,
  BookingSearchInput,
  BookingSelectFilters,
  BookingStatusFilters,
  DateTimeChanger,
  TodayButton,
  useFilteredBookings
} from 'components/FilteredBookings';
import CalendarBookingsList from 'containers/CalendarMain/components/CalendarBookingsList/CalendarBookingsList';

const limit = 20;

const BookingsByDateModalHeader = ({ getters }) => {
  const {
    getDateTimeChangerProps,
    getTodayButtonProps,
    getSearchBookingsProps,
    getFiltersContainerProps,
    getStatusFilterProps,
    getFilterProps,
    getSelectProps,
  } = getters;

  return (
    <BookingFiltersList
      className="bookings-by-date__header"
      counterPosition="bottom"
      {...getFiltersContainerProps()}
    >
      <div className="bookings-by-date__main-filters">
        <TodayButton {...getTodayButtonProps()} />

        <DateTimeChanger
          className="bookings-by-date__date-changer"
          {...getDateTimeChangerProps()}
        />

        <BookingSearchInput
          inputClassName='bookings-by-date__search-input'
          placeHolder='Search by keyword'
          showSearchBtn
          showClearBtn
          theme={INPUT_THEMES.dim}
          {...getSearchBookingsProps()}
        />
      </div>

      <div className="bookings-by-date__statuses">
        <BookingStatusFilters {...getStatusFilterProps()}>
          <BookingStatusFilters.todo />
          <BookingStatusFilters.confirmed />
          <BookingStatusFilters.done />
          <BookingStatusFilters.cancelled />
        </BookingStatusFilters>
      </div>

      <div className="bookings-by-date__secondary-filters">
        <BookingFilters
          className="bookings-by-date__boolean-filters"
          {...getFilterProps()}
        >
          <BookingFilters.outcall />
          <BookingFilters.duo />
          <BookingFilters.prebooking />
          <BookingFilters.agent />
        </BookingFilters>

        <BookingSelectFilters.agent
          className="bookings-by-date__agent-filter"
          {...getSelectProps()}
        />
      </div>
    </BookingFiltersList>
  )
}

const BookingsByDateModal = () => {
  const { getters, date, isCurrentDate } = useFilteredBookings({ limit, isAutoScrollToActualTime: true })

  return (
    <div className="bookings-by-date">
      <BookingsByDateModalHeader getters={getters} />

      <CalendarBookingsList
        getters={getters}
        date={date}
        classNameModifier="modal-timeline"
        isCurrentDate={isCurrentDate}
        bookingType={BOOKING_ITEM_VIEWS.MODAL_LINED}
        showTimeFilters={true}
      />
    </div>
  );
};

export default memo(BookingsByDateModal);
