import moment from 'moment';

export default (initialDate, dates) => {
  if (!dates || Array.isArray(dates)) {
    return 0;
  } else {
    const startOfDate = new Date(
      Array.isArray(initialDate) ? initialDate[0] : initialDate
    ).setHours(0, 0, 0, 0);
    const diff = moment(dates).diff(moment(startOfDate), 'days');
    return diff;
  }
}
