import React from 'react'

import { classModifier, getContactAvatar, showContactName } from 'utils';
import { CONTACT_TYPES } from 'config/constants';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const TransferredOrAssistanceSessionModalItem = ({ item, isAssistanceModalType }) => {
  const { username } = item;
  const isOperator = item.type === CONTACT_TYPES.OPERATOR;
  const name = isOperator ? username : showContactName(item.fn, 'chat-header__ex');

  const contactTel = item.tels?.length && item.tels[0]?.tel || '';
  const modalMessage = isAssistanceModalType
    ? 'Requested assistance'
    : 'Transferring Session';

  return (
    <div className="transferred-or-assistance-session-modal__contact-info">
      <div className="transferred-or-assistance-session-modal__image">
        <LazyLoadImage
          src={getContactAvatar(item)}
          alt="ava"
          className="operators-list-item__img"
        />
      </div>
      <div className="transferred-or-assistance-session-modal__box">
        <p
          className={classModifier(
            'transferred-or-assistance-session-modal__name',
            [isOperator && 'operator']
          )}
        >
          {name}
        </p>
        <p className="transferred-or-assistance-session-modal__text">
          {isOperator ? modalMessage : contactTel}
        </p>
      </div>
    </div>
  );
}

export default TransferredOrAssistanceSessionModalItem;