import React, { Component, Fragment } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from 'react-window';

import { CHAT_CONTACTS_LIMIT } from 'redux/ducks/contacts';

import './SidebarContactsList.scss';
import SidebarContactsListWrapper from './SidebarContactsListWrapper';
import Spinner from 'components/UI/Spinner/Spinner';


class SidebarContactsList extends Component {
  key = -1;
  limit = CHAT_CONTACTS_LIMIT;

  listRef = React.createRef();

  CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
    <SidebarContactsListWrapper
      {...props}
      forwardedRef={ref}
      customOnScroll={this.handleScroll} />
  ));

  componentDidMount() {
    // if we change contactType
    if (this.props.list.length > this.limit) {
      this.limit = this.props.list.length;
    }
  }

  componentDidUpdate(prevProps) {
    // if we already have more msgs then LIMIT
    if (prevProps.search !== this.props.search || prevProps.filter !== this.props.filter) {
      this.limit = this.props.list.length <= CHAT_CONTACTS_LIMIT
        ? CHAT_CONTACTS_LIMIT
        : this.props.list.length;

      if (this.listRef.current) {
        this.listRef.current.scrollTo(0);
      }
      return;
    }
    // if we change main filter
    if (prevProps.mainFilter !== this.props.mainFilter) {
      this.limit = CHAT_CONTACTS_LIMIT;
    }
    // if we add new contact
    if (prevProps.list.length + 1 === this.props.list.length && prevProps.list.length < this.props.list.length) {
      return this.limit += 1;
    }
    // if we remove contact
    if (prevProps.list.length - 1 === this.props.list.length) {
      return this.limit -= 1;
    }
  }

  handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    // part for getting contact by scrolling down 
    const bottomScroll = scrollHeight - scrollTop - clientHeight;

    if (bottomScroll < 400 && this.limit === this.props.list.length) {
      this.limit += CHAT_CONTACTS_LIMIT;
      this.props.updateList(this.props.type, this.props.list.length, this.props.search);
    }
  };

  renderListItem = ({ index, style }) => {
    const ChatItem = this.props.listItem;

    return (
      <ChatItem
        currentIndex={index}
        style={style}
        type={this.props.type}
        id={this.props.list[index]}
        toggleDescription={this.toggleDescription}
        isContextMenu={this.props.contextMenu}
        dividedContactId={this.props.dividedContactId}
        search={this.props.search}
        searchSource={this.props.searchSource}
        generateContextItems={this.props.generateContextItems}
      />
    );
  };

  render() {
    if (this.props.pending) {
      return (
        <div className="contacts__list--empty">
          <Spinner spinnerSize={45} />
        </div>
      );
    }
    else if (!this.props.list.length) {
      return (
        <div className="contacts__list--empty">
          (no items)
        </div>
      );
    }

    // const rowHeight = index => {
    //   if (index === this.key) {
    //     return 46 + 46;
    //   }
    //   return 46;
    // };
    // const rowHeight = index => {
    //   if (index === this.key) {
    //     return 48 + 48;
    //   }
    //   return 48;
    // };

    return (
      <Fragment>
        <AutoSizer>
          {({ width, height }) => (
            <List
              itemCount={this.props.list.length}
              itemSize={() => 51}
              outerElementType={this.CustomScrollbarsVirtualList}
              ref={this.listRef}
              height={height}
              width={width}
            >
              {this.renderListItem}
            </List>
          )}
        </AutoSizer>
      </Fragment>
    );
  }
}

export default SidebarContactsList;
