export default (innerText, highlight, className) => {
  if (!highlight) return innerText;

  const text = String(innerText);
  const idxOfSearchedPart = text.toUpperCase().indexOf(highlight.toUpperCase());

  let parts = '';

  if(idxOfSearchedPart >= 0) {
    const strBefore = text.slice(0, idxOfSearchedPart);

    const sanitizedSearchText = highlight.replace(/[-\/()]/g, '\\$&');
    const escapedSubstring = sanitizedSearchText.replace(/\+/g, "\\+");
    const regExForSearchedText = new RegExp(escapedSubstring, "gi");

    const searchedTxtFromFn = text.match(regExForSearchedText) ? text.match(regExForSearchedText)[0] : '';

    const searchedStrToJsx = <mark className={className}>{searchedTxtFromFn}</mark>;
    const strAfter = text.slice(idxOfSearchedPart + highlight.length);

    parts = [strBefore, searchedStrToJsx, strAfter];
  }

  return parts;
  };


  // const regexForPlus = highlight.startsWith('+') ? '\\+' : '';
  // const regex = new RegExp(`(${regexForPlus}${highlight})`, 'gi');
  // const parts = typeof text === 'string' ? text?.split(regex) : [];
  // const isHighlighted = parts.some((part) => part.toLowerCase() === highlight.toLowerCase());

  // if (!showWithoutHighlights && !isHighlighted) return null;

  // return (
  //   <>
  //     {parts.map((part, index) => {
  //       if (part.toLowerCase() !== highlight.toLowerCase()) return part;

  //       return (
  //         <mark className={className} key={index}>
  //           {part}
  //         </mark>
  //       );
  //     })}
  //   </>
  // );
  // };