import moment from 'moment';

export default (date) => {
	if (!date) {
    return 'all'
  }

  if (Array.isArray(date)) {
    return date;
  }

  return new Date(date).getHours() > 5
    ? new Date(date).setHours(0, 0, 0, 0)
    : moment(date).subtract(24, 'hours').startOf('day').valueOf(); // set offset for booking with time below 5am for setting the key to the previous day
};
