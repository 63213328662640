import React from 'react';
import { classModifier, getContactAvatar } from 'utils';
import DateTime from 'components/DateTime';
import { MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';
import MsgText from './MsgText';
import { useSelector } from 'react-redux';
import { selectTelegramGroupMember } from 'redux/selectors/selectors';
import ICONS from '../../../../../assets/icons';
import Spinner from '../../../../../components/UI/Spinner/Spinner';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import { useToggle } from '../../../../../hooks';
import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';

const OutgoingGroupMessage = ({ interaction, replyMsg, activeGroup, ...props }) => {
  const sender = useSelector((state) =>
    selectTelegramGroupMember(state, interaction.from_telegram_user_id));

  const [menu, toggleMenu] = useToggle(false);

  return (
    <li
      key={interaction.id}
      className={classModifier('interaction', [
        'out',
        'tg-group',
      ])}
      onMouseEnter={() => toggleMenu(true)}
      onMouseLeave={() => toggleMenu(false)}
    >
      <header className="interaction__header">
        <div className="interaction__header-info interaction__header-info--reverse">
          <ContactInteractionMenu
            isOpen={menu}
            closeMenu={toggleMenu}
            interaction={interaction}
            isTelegramGroupMsg
            activeChannel='telegram'
            replyMsg={replyMsg}
          />

          {sender?.photo && (
            <div className={classModifier('interaction__ava', 'reverse')}>
              <img
                src={`data:image/jpeg;base64,${sender.photo}`}
                alt="ava"
              />

              <ICONS.telegram
                className={classModifier('interaction__ava-telegram-icon', 'reverse')}
              />
            </div>
          )}

          <span className={classModifier('interaction__name', 'out')}>
            {sender?.first_name || 'Unknown User'}
          </span>

          <span className="interaction__time">
            <DateTime
              date={interaction.dateCreated}
              config={MESSAGE_DATE_CONFIG}
            />
          </span>
        </div>
      </header>

      <div
        className={classModifier('interaction__body-wrap', [
          !interaction.body && !interaction.media && 'empty-body',
          (interaction.media && interaction.body) && 'attachments',
        ])}
        onClick={props.handleLinkClick}
      >
        {(interaction.reply_to && interaction.reply_to.type !== 'messageService' && interaction.reply_to.type !== 'messageEmpty') && (
          <div className="interaction__replied-msg">
            <AttachmentRepliedMessage
              interaction={interaction.reply_to}
              activeGroup={activeGroup}
            />
          </div>
        )}

        {interaction.body &&
          <div
            className={classModifier("interaction__text-wrap", [
              interaction.media && 'attachments'
            ])}
          >
            <MsgText
              interaction={interaction}
            />
          </div>
        }

        {(interaction.media?.photo || interaction.media?.document) && (
          !interaction.images?.length
            ? <Spinner spinnerSize={16} />
            : <InteractionAttachments attachments={{ images: interaction.images }} />
        )}
      </div>
    </li>
  );
};

export default OutgoingGroupMessage;
