import { connect, useDispatch } from 'react-redux';

import { DURATION_FIELD_OPTIONS } from 'components/SelectFormField/SelectFormField';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './FeedbackAddBtn.scss';

const FeedbackAddBtn = ({
  activeContact,
  setFeedbackList,
}) => {

  const dispatch = useDispatch();
  const isActiveClient = activeContact?.type === 1;
  const isActiveEscort = activeContact?.type === 2;

  // === Markup functions

  const getFormInitValues = () => {
    const initValues = {
      duration: DURATION_FIELD_OPTIONS[2],
    };

    if (isActiveClient) {
      initValues.client = activeContact;
    }
    if (isActiveEscort && activeContact.diva_default_id) {
      initValues.escort = activeContact;
    }

    return initValues;
  }

  const toggleModal = () => {
    dispatch(openModal(MODAL_TYPES.feedback, {
      initValues: getFormInitValues(),
      setFeedbackList
    }));
  };

  return (
    <button
      className='feedback-add-btn'
      onClick={toggleModal}
      type='button'
    >
      Add Feedback
    </button>
  );
}

const mSTP = state => ({
  currentUser: state.user,
});

export default connect(mSTP)(FeedbackAddBtn);
