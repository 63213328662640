import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useForm } from 'react-final-form';

import { classModifier } from 'utils';
import { GOOGLE_MAPS_API_KEY } from 'config/constants';

import Spinner from 'components/UI/Spinner/Spinner';
import BookingLocationFields from './BookingLocationFields';

const containerStyle = {
  width: '240px',
  height: '145px'
};

const BookingLocationMap = ({ profile = {}, type = 'incall', isDisabled, callerId }) => {
  const [ libraries ] = useState(['places']);
  const [map, setMap] = useState(null);
  // const [isSearch, setIsSearch] = useState(false);

  const initialCenter = { lat: +(profile?.latitude || 51.5072), lng: +(profile?.longitude || 0.1276) };

  const [mapData, setMapData] = useState({
    initialCenter,
    markers: initialCenter,
    formattedAddress: profile?.place
  });

  const searchBoxRef = useRef();

  const form = useForm();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(initialCenter);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);

    Array
      .from(document.getElementsByClassName('pac-container'))
      .forEach(i => i.remove());
  },[]);

  useEffect(() => {
    setMapData({
      initialCenter,
      markers: initialCenter,
      formattedAddress: profile?.place,
    })
  }, [profile])

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({
        lat: mapData.markers.lat,
        lng: mapData.markers.lng,
      });
      map.fitBounds(bounds);
    }
  }, [map, mapData.markers]);

  useEffect(() => {
    const isResetChoords = 
      (type === 'incall' || type === 'incall_duo') && 
      mapData.markers.lat !== +profile?.latitude &&
      mapData.markers.lng !== +profile?.longitude;

    if(isResetChoords) {
      setMapData((currentMapData) => ({
        ...currentMapData,
        markers: initialCenter,
        formattedAddress: profile?.place
      }));
    } 
    // else {
    //   setMapData((currentMapData) => ({
    //     ...currentMapData,
    //     markers: address?.latitude && address?.longitude
    //       ? {
    //           lat: address.latitude,
    //           lng: address.longitude,
    //         }
    //       : initialCenter,
    //     formattedAddress: address?.locationName || '',
    //   }));
    // }
  }, [type]);

  const onSearchBoxLoad = searchBox => searchBoxRef.current = searchBox;

  const onPlacesChanged = () => {
    const [searchData] = searchBoxRef.current?.getPlaces();

    const lat = searchData.geometry.location.lat();
    const lng = searchData.geometry.location.lng();

    form.change('address.locationName', searchData.formatted_address);
    form.change('address.longitude', lng);
    form.change('address.latitude', lat); 

    setMapData((currentMapData) => ({
      ...currentMapData,
      markers: { lat, lng },
      formattedAddress: searchData.formatted_address
    }));
  };

  return (
    <>
      <BookingLocationFields
        isLoaded={isLoaded}
        onSearchBoxLoad={onSearchBoxLoad}
        onPlacesChanged={onPlacesChanged}
        callerId={callerId}
      />

      <div className={classModifier("booking-form__map", isDisabled && 'disabled')} >
        {isLoaded
          ? <GoogleMap
              onLoad={onLoad}
              onUnmount={onUnmount}
              center={mapData.initialCenter}
              options={{ 
                maxZoom: isDisabled ? 5 : 19, 
                controlSize: 20 
              }}
              mapContainerStyle={containerStyle}
            >
              <Marker position={mapData.markers} />
            </GoogleMap>
          : <Spinner spinnerSize={40}/>
        }
      </div>
    </>
  )
};

export default React.memo(BookingLocationMap);
