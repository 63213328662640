import React, { useMemo, useState } from "react";
import { connect } from "react-redux";

import { classModifier } from "utils";
import { updateProfileFilters } from 'redux/ducks/sessions';
import { getAdditionalFiltersFromDiva } from "redux/ducks/divaGirls";
import { ADDITIONAL_SESSION_FILTERS } from "config/constants";

import SelectFilterItem from "../../SelectFilterItem";

const GalleryHeaderProfileFilters = (props) => {
  const {
    activeSession,
    canIEditSession,
    additionalFilters,
  } = props;

  const values = useMemo(() => ({
    city: additionalFilters[ADDITIONAL_SESSION_FILTERS.city] || null,
    location: additionalFilters[ADDITIONAL_SESSION_FILTERS.location] || null,
    services: additionalFilters[ADDITIONAL_SESSION_FILTERS.services] || null,
    price: additionalFilters[ADDITIONAL_SESSION_FILTERS.price] || null,
    age: additionalFilters[ADDITIONAL_SESSION_FILTERS.age] || null,
    height: additionalFilters[ADDITIONAL_SESSION_FILTERS.height] || null,
    breast: additionalFilters[ADDITIONAL_SESSION_FILTERS.breast] || null,
    hair: additionalFilters[ADDITIONAL_SESSION_FILTERS.hair] || null,
    dress: additionalFilters[ADDITIONAL_SESSION_FILTERS.dress] || null,
    nationality: additionalFilters[ADDITIONAL_SESSION_FILTERS.nationality] || null,
    ethnicity: additionalFilters[ADDITIONAL_SESSION_FILTERS.ethnicity] || null,
    language: additionalFilters[ADDITIONAL_SESSION_FILTERS.language] || null,
  }), [activeSession, additionalFilters]);

  const [selectedValues, setSelectedValues] = useState(values);
  
  return (
    <div className="gallery-header-filters__main">
      {filtersConfig.map((group, idx) => (
        <div className={classModifier('gallery-header-filters__group', `row-${idx + 1}`)}>
          {group.map((filter) => (
            <SelectFilterItem 
              key={filter.name}
              idx={idx} 
              filter={filter}
              group={group} 
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              canIEditSession={canIEditSession}
              activeSession={activeSession}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const mDTP = {
  updateProfileFilters,
  getAdditionalFiltersFromDiva,
}

export default connect(null, mDTP)(GalleryHeaderProfileFilters);

const filtersConfig = [
  [
    { fieldLabel: 'filter-city', label: 'City', name: 'city', optionName: 'city', selectProps: { isSearchable: true } },
    { fieldLabel: 'filter-location', label: 'Location', name: 'location', optionName: 'location', selectProps: { isSearchable: true } },
  ],
  [
    { fieldLabel: 'filter-price_filter', label: 'Rate', name: 'price', optionName: 'price_filter' },
    { fieldLabel: 'filter-age_filter', label: 'Age', name: 'age', optionName: 'age_filter' }
  ],
  [
    { fieldLabel: 'filter-height_filter', label: 'Height', name: 'height', optionName: 'height_filter' },
    { fieldLabel: 'filter-breast_filter', label: 'Breast', name: 'breast', optionName: 'breast_filter' },
    { fieldLabel: 'filter-hair', label: 'Hair', name: 'hair', optionName: 'hair' },
  ],
  [
    { fieldLabel: 'filter-dress_filter', label: 'Dress', name: 'dress', optionName: 'dress_filter' },
    { fieldLabel: 'filter-ethnicity', label: 'Ethnicity', name: 'ethnicity', optionName: 'ethnicity' },
    { fieldLabel: 'filter-nationality', label: 'Nationality', name: 'nationality', optionName: 'nationality', selectProps: { isSearchable: true } },
  ],
  [
    { fieldLabel: 'filter-language', label: 'Language', name: 'language', optionName: 'language', selectProps: { isSearchable: true } },
    { fieldLabel: 'filter-services', label: 'Services', name: 'services', optionName: 'services', selectProps: { isSearchable: true } },
  ]
];