import ICONS from 'assets/icons';
import classNames from 'classnames';

import './TodayButton.scss';

export const TodayButton = ({ onClick, todayHidden, className }) => (
  <button
    className={classNames("today-btn", className)}
    onClick={onClick}
  >
    <ICONS.triangleWithCircle className="today-btn__icon"/>
    {!todayHidden &&
      <span className="today-btn__text">Today</span>
    }
  </button>
)
