import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import { selectModalCount } from 'redux/selectors/selectors';

import ICONS from 'assets/icons';
import TasksAndRemindersModal from 'components/TasksAndRemindersModal/TasksAndRemindersModal';
import { classModifier } from 'utils';
import { toggleIsOpenTasksAndRemindersModal } from 'redux/ducks/notifications';

const HeaderModalTasksBtn = () => {
  const dispatch = useDispatch();
  const isTasksAndRemindersModalOpen = useSelector(state => state.notifications.isOpenTasksAndRemindersModal)

  const {
    triggerRef,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
    tooltipRef,
  } = usePopperTooltip(
    {
      offset: [0, 18],
      trigger: 'click',
      placement: 'bottom',
      visible: isTasksAndRemindersModalOpen,
      onVisibleChange: () => dispatch(toggleIsOpenTasksAndRemindersModal({ status: !isTasksAndRemindersModalOpen })),
      closeOnOutsideClick: false,
    }
  );

  const modalCount = useSelector((state) => selectModalCount(state));
  const isModalOpened = !!modalCount;

  const isModalOpen = document.getElementById('dialogs-root').children.length;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (triggerRef?.contains(event.target)) {
        return null;
      }
      
      if (tooltipRef && !tooltipRef.contains(event.target) && !isModalOpened && !isModalOpen) {
        dispatch(toggleIsOpenTasksAndRemindersModal({ status: false }))
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isTasksAndRemindersModalOpen, isModalOpened, tooltipRef, isModalOpen])

  return (
    <>
      <button className="header-modal-btns__button" ref={setTriggerRef}>
        <ICONS.checkbox
          className={classModifier('header-modal-btns__icon', [isTasksAndRemindersModalOpen && 'active'])}
          width="20px"
          height="20px"
        />
      </button>

      {isTasksAndRemindersModalOpen &&
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ style: { zIndex: 100 } })}
        >
          <TasksAndRemindersModal />
        </div>
      }
    </>
  );
};

export default HeaderModalTasksBtn;
