import Tooltip from 'components/UI/Tooltip/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import { v4 as uuid } from 'uuid';

import useClickAway from 'hooks/useClickAway';

import './SelectFilter.scss';
import classNames from 'classnames';

const convertToSelectOption = (item, pathToValue) => ({
  ...item,
  name: item.name || item.username,
  label: item.fn || item.name || item.username || item.label,
  value: pathToValue ? item[pathToValue] : item.id
})

const convertAndFilterOptions = (options, item, inputValue) => {
  const option = convertToSelectOption(item);

  if (!inputValue || option.label.toLowerCase().includes(inputValue.toLowerCase())) {
    return options.concat(option);
  } else {
    return options;
  }
}

const SingleValue = ({ disableTooltips, ...props }) => {
  const { children, selectProps: { menuIsOpen } } = props;

  return (
    <Tooltip
      text={children}
      visibleWhenIsOverflowed
      extraCondition={!menuIsOpen}
      disable={disableTooltips}
    >
      {(setTriggerRef, innerRef) => (
        <components.SingleValue innerProps={{ ref: setTriggerRef }} {...props}>
          {children}
        </components.SingleValue>
      )}
    </Tooltip>
  )
}

const ValueContainer = ({ onInnerClick, children, ...props }) => {
  const innerProps = onInnerClick ? { onClick: onInnerClick } : {}

  return (
    <components.ValueContainer innerProps={innerProps} {...props} >
      {React.Children.toArray(children).filter((child) => {
        return !onInnerClick || (onInnerClick && child.key !== '.1');
      })}
    </components.ValueContainer>
  )
}

const Option = ({ disableTooltips, OptionIcon, ...props }) => {
  const { label } = props;

  return (
    <Tooltip
      text={label}
      visibleWhenIsOverflowed
      disable={disableTooltips}
    >
      {(setTriggerRef, innerRef) => (
        <components.Option {...props}>
          {OptionIcon && props.data && (
            <OptionIcon item={props.data} className="bookings-select-filter__option-icon avatar" />
          )}

          <div className="react-select__option-text" ref={setTriggerRef}>
            {label}
          </div>
        </components.Option>
      )}
    </Tooltip>
  )
}

/* Main component */

const BookingSelectFilter = (props) => {
  const {
    name,
    className,
    data,
    request,
    Icon = null,
    OptionIcon = null,
    disabled,
    updateFilters,
    height,
    width,
    isFiltersUpdated,
    filterName,
    filterValue,
    disableTooltips,
    innerHandleClick,
  } = props;

  const [key, setKey] = useState(null);
  const [value, setValue] = useState(null);

  const ref = useRef();

  const setKeyForRender = () => setKey(`${filterName}-${uuid()}`)
  const updateFilterInOuterStateAndSelect = (item, pathToValue) => {
    const newItem = item ? convertToSelectOption(item, pathToValue) : null;

    updateFilters(newItem, pathToValue);
    setValue(newItem);
  }

  useEffect(() => {
    !isFiltersUpdated && setKeyForRender(); // Change key for re-render select options
  }, [isFiltersUpdated, filterName])

  useEffect(() => {
    !filterValue && setValue(null); // Reset select filter when all filters was reseted
  }, [filterValue])

  useClickAway(ref, setKeyForRender);

  let onInnerClick = () => {
    innerHandleClick(updateFilterInOuterStateAndSelect)
    setKeyForRender();
  }
  onInnerClick = innerHandleClick ? onInnerClick : null;

  const loadOptions = async (inputValue = '', _, { page = 0 }) => {
    const config = {
      query: inputValue,
      offset: page * 20,
      limit: 20,
    };

    let finitData;

    if (data) {
      finitData = data;
    } else {
      finitData = (await request(config)).data;
    }

    const options = finitData
      .reduce((options, item) => convertAndFilterOptions(options, item, inputValue), [])

    return {
      options: options,
      hasMore: options.length >= 20,
      additional: {
        page: page + 1,
      }
    };
  };

  const renderIcon = () => {
    if (OptionIcon && value) {
      return <OptionIcon item={value} className="bookings-select-filter__icon avatar" />;
    } else if (Icon) {
      return <Icon className="bookings-select-filter__icon" />;
    } else {
      return null;
    }
  }

  return (
    <div
      className={classNames("bookings-select-filter", className)}
      style={{ height, width }}
      ref={ref}
    >
      {renderIcon()}

      <AsyncPaginate
        key={key}
        value={value}
        className='react-select'
        classNamePrefix='react-select'
        placeholder={name}
        inputId={name}
        loadOptions={loadOptions}
        onChange={(item) => updateFilterInOuterStateAndSelect(item)}
        isClearable
        isDisabled={disabled}
        additional={{ page: 0 }}
        components={{
          IndicatorSeparator: null,
          SingleValue: (props) => <SingleValue disableTooltips={disableTooltips} {...props} />,
          ValueContainer: (props) => <ValueContainer onInnerClick={onInnerClick} {...props} />,
          Option: (props) => <Option disableTooltips={disableTooltips} OptionIcon={OptionIcon} {...props} />
        }}
      />
    </div>
  );
};

export default BookingSelectFilter;
