import Checkbox from 'components/Checkbox/Checkbox';
import _ from 'lodash';
import { useEffect, useState } from 'react';


const ExtraItemsForSending = ({
  searchListExtraGroups,
  setSearchListExtraGroups,
}) => {
  const handleChange = (title) => {
    setSearchListExtraGroups((prev) => ({ ...prev, [title]: !prev[title] }))
  };

  useEffect(() => {
    const onClick = (event) => handleChange(event.detail.title);

    window.addEventListener("removeLabel", onClick);

    return () => {
      window.removeEventListener("removeLabel", onClick);
    }
  }, [])

  return (
    <div className="search-list__extra-container">
      {Object.entries(searchListExtraGroups).map(([title, value]) => (
        <label
          key={title}
          className="search-list__extra-item"
        >
          {title}

          <Checkbox
            theme="checked"
            color="screaming-green"
            size={16}
            inputProps={{
              checked: value,
              onChange: () => handleChange(title),
            }}
          />
        </label>
      ))}
    </div>
  )
}

export default ExtraItemsForSending;
