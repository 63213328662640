import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { CONTACT_TYPES } from 'config/constants';
import { selectOperatorByUserId } from 'redux/selectors/selectors';

import './PinnedMsgs.scss';
import PinnedMsgsItem from './PinnedMsgsItem';
import PinnedMsgsItemRolledUp from './PinnedMsgsItemRolledUp';

const PinnedMsgs = ({ pinnedMsgs, activeRecipient, profileId, ...props }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const operator = useSelector(state => selectOperatorByUserId(state, pinnedMsgs[0].user_id, profileId));
  const pinMsg = useSelector(state => activeRecipient.type === CONTACT_TYPES.CLIENT
    ? state.clientChats.activePinnedMsgs 
    : state.girlChats.activePinnedMsgs 
  );

  if (!pinMsg) {
    return null;
  }

  return (
    <div className={classModifier('pinned-msgs', [isMenuOpen && 'open'])}>
      {isMenuOpen
        ? (
          <>
            <div className="pinned-msgs__header">
              <p className="pinned-msgs__title">Pinned messages</p>

              <button
                className="pinned-msgs__btn-close"
                onClick={() => setMenuOpen(false)}
              >
                Close
              </button>
            </div>

            <ul className="pinned-msgs__list"> 
              {pinnedMsgs.map((pinnedMsg, index) => (
                <PinnedMsgsItem
                  item={pinnedMsg}
                  setMenuOpen={setMenuOpen}
                  unpinMsg={props.unpinMsg}
                  index={index}
                  key={pinnedMsg.id}
                  type={props.type}
                  getMessageContext={props.getMessageContext}
                  chatId={props.chatId}
                  isMenuOpen={isMenuOpen}
                  activeRecipientType={activeRecipient.type}
                  contact={!!pinnedMsg.user_id ? operator : activeRecipient}
                />
              ))}
            </ul>
          </>
        ) : (
          <PinnedMsgsItemRolledUp 
            pinnedMsgs={pinnedMsgs}
            item={pinMsg}
            setMenuOpen={setMenuOpen}
            unpinMsg={props.unpinMsg}
            type={props.type}
            getMessageContext={props.getMessageContext}
            chatId={props.chatId}
            isMenuOpen={isMenuOpen}
            activeRecipientType={activeRecipient.type}
            contact={!!pinMsg.user_id ? operator : activeRecipient}
          />
        )
      }
    </div>
  );
};

export default memo(PinnedMsgs);
