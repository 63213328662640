import React from 'react';
import { connect, useDispatch } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { updateBooking } from 'redux/ducks/sessions';
import { REQUEST_DATE_CONFIG } from 'config/dates-сonfig';
import { selectUserTimezone } from 'redux/selectors/selectors';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import DateTime from 'components/DateTime';

const ContactRequestsTabItem = ({ item, className, ...props }) => {
	const {
		text,
		is_success,
	} = item;

	const dispatch = useDispatch();

	const handleOpenBookingEditor = () => {
		API.getBookingById(item.booking_id)
			.then(({ data }) => {
				props.updateBooking(data.session_id, data);

				dispatch(openModal(MODAL_TYPES.bookingEditForm, {
					booking: data,
					profileId: data.profile_id,
					callerId: data.callerId,
				}));
			})
			.catch(console.error)
	};

	return (
		<li className={className}>
			<div>
				{is_success
					? <span className={className + '-status--success'}>success</span>
					: <span className={className + '-status--unsuccess'}>unsuccess</span>
				}
				<DateTime
				  className={className + '-date'}
				  date={item.date}
					config={REQUEST_DATE_CONFIG}
				/>

				<button
					type="button"
					className={className + '-session-btn'}
					onClick={handleOpenBookingEditor}
				>
					<ICONS.puzzleLeft width="15"/>
				</button>
				<p>{text}</p>
			</div>
		</li>
	)
};

const mSTP = (state) => ({
	userTimezone: selectUserTimezone(state),
	userHour12: state.user.hour12
});

const mDTP = {
	updateBooking,
}

export default connect(mSTP, mDTP)(ContactRequestsTabItem);
