import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';

import { useToggle } from 'hooks';
import { classModifier } from 'utils';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { CHAT_TYPES } from 'config/constants';
import { selectUserTimezone } from 'redux/selectors/selectors';
import {
  getChatTimeline,
  updateChatMessageSearch,
  updateChatTimeline,
  searchMessageInChat,
  stopChatMessageSearch,
} from 'redux/ducks/roomChats';

import './WebmasterList.scss';
import ChatSources from 'containers/Chat/components/ChatSources/ChatSources';
import ChatTimeline1 from 'containers/Chat/components/ChatTimeline/ChatTimeline1';
import ICONS from 'assets/icons';

const WebmasterList = ({
  search,
  timeline,
  timelinePending,
  todayMessagesCount,
  updatePending,
  newInteractionType,
  auxiliaryTimeline,
  activeRecipient,
  stopChatMessageSearch,
  searchMessageInChat,
  userId,
  userTimezone,
  getChatTimeline,
  getChatTimelineMedia,
  getChatTimelineGlossary,
  ...props
}) => {

  const [isCompleted, toggleIsCompleted] = useToggle(false);
  const dispatch = useDispatch();

  const chatTimeline = search ? auxiliaryTimeline : timeline;
  const [filteredChatTimeline, setFilteredChatTimeline] = useState(chatTimeline);

  const startSearch = (query) => searchMessageInChat(query, 'webmaster', userId, userTimezone);

  const isMainTimelineOpen = !(search || props.contextMsgId);

  const timelineLowerLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryLowerLoadedPage
    : props.timelineLowerLoadedPage;

  const timelinePageCount = !isMainTimelineOpen
    ? props.auxiliaryPageCount
    : props.timelinePageCount;

  const timelineHigherLoadedPage = !isMainTimelineOpen
    ? props.auxiliaryHigherLoadedPage
    : props.timelineCurrentPage;

  const timelineCurrentPage = !isMainTimelineOpen
    ? props.auxiliaryCurrentPage
    : props.timelineCurrentPage;

  const updateTimeline = () => {
    if (search) {
      return (activeRecipient, page, loadDirection) =>
        props.updateChatMessageSearch(activeRecipient, page, loadDirection, search, userId, userTimezone);
    } else {
      return (chat, page, loadDirection) =>
        props.updateChatTimeline(activeRecipient, page, loadDirection, userId, userTimezone);
    }
  }

  const handleOpenNewTasksModal = () => (
    dispatch(openModal(MODAL_TYPES.addNewTasksOrRemaindersModal, {
      isCreate: true,
      isWebmaster: true,
    }))
  )

  useEffect(() => {
    getChatTimeline('webmaster', userId, userTimezone);
  }, [])

  useEffect(() => {
    const filteredChatTimeline = chatTimeline.reduce((acc, timeline) => {
			const filteredDayOfTimeline = timeline.filter(
        (message) => Boolean(message.is_completed) === isCompleted
      );

      if (filteredDayOfTimeline.length) {
        return [
          ...acc,
          filteredDayOfTimeline
        ]
      }

      return acc;
    }, [])

    setFilteredChatTimeline(filteredChatTimeline);
  }, [isCompleted, chatTimeline])

  return (
    <div className="webmaster-list">
      <div className="webmaster-list__header">
        <div className="webmaster-list__header-top">
          <h3 className="webmaster-list__title">
            Webmaster
          </h3>

          <button
            className={classModifier("webmaster-list__show-btn", isCompleted && 'active')}
            onClick={toggleIsCompleted}
          >
            {`${isCompleted ? 'Hide' : 'Show'} Completed`}
          </button>
        </div>

        <button
          className="webmaster-list__add-btn"
          onClick={handleOpenNewTasksModal}
        >
          <ICONS.plusCircle />
        </button>
      </div>

      <ChatSources
        type={CHAT_TYPES.ROOM}
        activeRecipient={activeRecipient}
        stopMessageSearch={stopChatMessageSearch}
        startMessageSearch={startSearch}
        search={search}
      />

      <ChatTimeline1
        activeRecipient={activeRecipient}
        type={CHAT_TYPES.ROOM}
        timeline={filteredChatTimeline}
        timelinePending={timelinePending}

        updatePending={updatePending}
        timelineCurrentPage={timelineCurrentPage}
        timelinePageCount={timelinePageCount}
        timelineHigherLoadedPage={timelineHigherLoadedPage}
        timelineLowerLoadedPage={timelineLowerLoadedPage}
        newInteractionType={newInteractionType}
        updateContactTimeline={updateTimeline()}

        profileId={userId}
        userTimezone={userTimezone}
        userHour12={props.userHour12}

        search={search}
        contextMsgId={props.contextMsgId}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  activeRecipient: state.rooms.entities[state.roomChats.active],
  timeline: state.roomChats.timeline,
  timelinePending: state.roomChats.timelinePending,
  updatePending: state.roomChats.updatePending,
  timelineCurrentPage: state.roomChats.timelineCurrentPage,
  timelinePageCount: state.roomChats.timelinePageCount,
  timelineLowerLoadedPage: state.roomChats.timelineLowerLoadedPage,
  todayMessagesCount: state.roomChats.todayMessagesCount,
  newInteractionType: state.roomChats.newInteractionType,
  search: state.roomChats.search,
  user: state.user,
  userId: state.user.id,
  userTimezone: selectUserTimezone(state),

  auxiliaryTimeline: state.roomChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.roomChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.roomChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.roomChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.roomChats.auxiliaryCurrentPage,

  contextMsgId: state.roomChats.contextMsgId,
  userHour12: state.user.hour12,
});

const mapDispatchToProps = {
  getChatTimeline,
  updateChatTimeline,
  updateChatMessageSearch,
  searchMessageInChat,
  stopChatMessageSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebmasterList);
