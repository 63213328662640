import React from 'react';
import { useSelector } from 'react-redux';

import { selectContactById } from 'redux/selectors/selectors';

import './ProfileCardContacts.scss';
import CallButton from 'components/CallButton/CallButton';
import ICONS from 'assets/icons';

const ProfileCardContacts = ({ contactId }) => {
  const contact = useSelector((state) => selectContactById(state, contactId))

  const openContactChat = () => {
    const channel = new BroadcastChannel('tab_channel');
    channel.postMessage({ name: 'openGirlChat', contact });
  }

  return (
    <div className="profile-card-contacts">
      <div className="profile-card-contacts__btns">
        <button
          className="profile-card-contacts__btn profile-card-contacts__btn--mess"
          onClick={openContactChat}
          disabled={!contact}
        >
          <ICONS.comments width={20} height={20} />
        </button>

        <CallButton
          width={20}
          height={20}
          className="profile-card-contacts__btn"
          activeRecipient={contact}
        />
      </div>

      {contact && (!!contact.tels.length || !!contact.emails.length) && (
        <div className="profile-card-contacts__info">
          {!!contact.tels.length && (
            <div className="profile-card-contacts__tels">
              {contact.tels.map((tel) => (
                <div
                  key={tel.id}
                  className="profile-card-contacts__tel"
                >
                  {tel.tel}
                </div>
              ))}
            </div>
          )}

          {!!contact.emails.length && (
            <div className="profile-card-contacts__emails">
              {contact.emails.map((email) => (
                <div
                  key={email.id}
                  className="profile-card-contacts__email"
                >
                  {email.email}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProfileCardContacts;