import React from 'react';
import classNames from 'classnames';

import './Labels.scss';
import { classModifier } from 'utils';
import DateTime from 'components/DateTime';
import { PROFILE_HOLIDAY_DATE_CONFIG } from 'config/dates-сonfig';
import Tooltip from 'components/UI/Tooltip/Tooltip';

const Labels = ({ selector = ['compared', 'main'], ...props }) => {
    const { profile, classPrefix } = props;

    const getLabels = (side) => {
        const labels = [];
        
        switch (side) {
            case 'main':
                profile.isNew && labels.push({
                    className: 'profile-labels__label--red',
                    labelName: 'New'
                });
        
                let secret_price = profile.prices_secret.find((secret) => secret.name === '1 hour');
                if (secret_price.incall !== '' || secret_price.outcall !== '') {
                    labels.push({
                        className: 'profile-labels__label--secret',
                        labelName: `£ ${secret_price.incall ? secret_price.incall : '-'}/${secret_price.outcall ? secret_price.outcall : '-'}`
                    });
                }
        
                if (profile.orientation && profile.orientation !== 'Hetero') {
                    labels.push({
                        className: 'profile-labels__label--' + profile.orientation.toLowerCase(),
                        labelName: profile.orientation
                    });
                }
        
                profile.offer && profile.special_proposal !== '' && labels.push({
                    className: 'profile-labels__label--lightpink',
                    labelName: 'Offer',
                    TooltipTitle: profile.special_proposal,
                })
        
                profile.exclusive && labels.push({
                    className: 'profile-labels__label--green',
                    labelName: 'Exclusive'
                })
        
                profile.is_banned && labels.push({
                    className: 'profile-labels__label--lightpink',
                    labelName: 'Banned'
                });
        
                
                profile.is_avoid && labels.push({
                    className: 'profile-labels__label--red',
                    labelName: 'Avoid'
                });
        
                profile.is_archived && labels.push({
                    className: 'profile-labels__label--grey',
                    labelName: 'Archived'
                });
                
                profile.featured && labels.push({
                    className: 'profile-labels__label--featured',
                    labelName: 'Featured'
                });
        
                profile.is_for_member && labels.push({
                    className: 'profile-labels__label--grey',
                    labelName: 'For member'
                });
                
                profile.is_on_holiday && labels.push({
                    className: 'profile-labels__label--grey',
                    labelName: 'On Holiday',
                    TooltipTitle: <DateTime date={profile.on_holiday_since} config={PROFILE_HOLIDAY_DATE_CONFIG} />
                });
                
                !profile.contact_uid && labels.push({
                    className: 'profile-labels__label--green',
                    labelName: 'No contact!'
                });
                
                profile.recommended && labels.push({
                    className: 'profile-labels__label--recommended',
                    labelName: 'Recommended'
                });
                
                profile.is_not_recommended && labels.push({
                    className: 'profile-labels__label--lightpink',
                    labelName: 'Not Recommended'
                });
                
                break;
            case 'compared':
                profile.bestmatch && labels.push({
                    className: 'profile-labels__label--compared',
                    labelName: profile.bestmatch + '%'
                });

                if (profile.nearby_radius) {
                    const km = profile.nearby_radius;
                    const miles = km / 1.609;
                    
                    labels.push({
                        className: 'profile-labels__label--compared',
                        labelName: miles.toFixed(2) + ' mi'
                })

                break;
            }
        }
        return labels.sort((a, b) => a.labelName.length - b.labelName.length);
    }

    return selector.map((item) => (
        <div key={item} className={classModifier('profile-labels', [classPrefix, item])}>
            {getLabels(item).map(({ labelName, className, TooltipTitle }, index) => (
                <Tooltip text={TooltipTitle} key={index}>
                    {(setTriggerRef) => (
                        <div
                            className={classNames('profile-labels__label', `profile-labels__label--${classPrefix}`, className)}
                            ref={setTriggerRef}
                        >
                            {labelName}
                        </div>
                    )}
                </Tooltip>
            ))}
        </div>
    ))
};

export default React.memo(Labels);
