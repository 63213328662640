import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { classModifier, isEmptyObj } from 'utils';
import {
  ADR_BOOK_LIMIT,
  changeContactSorting,
  filterContactsForAdrBook,
  getContactsForAdrBook,
  getTagsForAdrBook
} from 'redux/ducks/addressBook';
import { selectAdrBookList } from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import { CONTACT_TYPES } from 'config/constants';
import { ADR_BOOK_FILTERS } from 'config/constants';

import './AdrBookListWrap.scss';
import AdrBookList from './components/AdrBookList/AdrBookList';
import AdrBookListSort from './components/AdrBookListSort/AdrBookListSort';
import AdrBookItem from 'containers/AdrBookListWrap/components/AdrBookItem/AdrBookItem';

const AdrBookListWrap = (props) => {
  const {
    includeUnsaved,
    contactType,
    search,
    sortBy,
    activeTags,
    contactsPending,
    handleSelectMode,
    isSelectMode,
    selectedContactsList,
    onAddSelectContact
  } = props;

  const isContactsSyncedRef = useRef(false);

  const [isShowEx, setShowEx] = useState(false);

  const isEscortList = contactType === CONTACT_TYPES.GIRL;
  const listMode = includeUnsaved + '' + contactType + '' + search + '' + sortBy + '' + JSON.stringify(activeTags) + isShowEx;

  useEffect(() => {
    const isActiveTags = !isEmptyObj(activeTags);
    const isAnyFilter = includeUnsaved || contactType || search || sortBy || isActiveTags;

    if (!Object.values(ADR_BOOK_FILTERS).includes(contactType)) {
      return null;
    }

    if (!isAnyFilter) {
      const config = {};

      if (!isContactsSyncedRef.current) {
        config.sync = 1
      }

      if (isEscortList) {
        config.showEX = 0
      }

      if (!config.contactType) {
        config.contactType = "";
      }

      props.getTagsForAdrBook(config);
      props.getContactsForAdrBook(config)
        .then(() => isContactsSyncedRef.current = true);
    }
    else {
      const config = {
        includeUnsaved,
        contactType,
        search,
        sortBy,
      };

      if (isActiveTags) {
        config.activeTags = activeTags;
      }
      if (!isContactsSyncedRef.current) {
        config.sync = 1
      }
      if (isEscortList) {
        config.showEX = 0
        if (isShowEx) {
          config.showEX = 1
        }
      }

      props.getTagsForAdrBook(config);
      props.filterContactsForAdrBook(config)
        .then(() => isContactsSyncedRef.current = true);
    }
  }, [includeUnsaved, contactType, search, sortBy, activeTags, isShowEx]);

  const updateList = useCallback((offset) => {
    const isActiveTags = !isEmptyObj(activeTags);
    const isAnyFilter = includeUnsaved || contactType || search || sortBy || isActiveTags;

    if (!isAnyFilter) {
      props.getContactsForAdrBook({ offset: offset });
    }
    else {
      const config = {
        includeUnsaved,
        contactType,
        search,
        sortBy,
        offset
      };

      if (isActiveTags) {
        config.activeTags = activeTags;
      }
      if (isEscortList) {
        config.showEX = 0
        if (isShowEx) {
          config.showEX = 1
        }
      }

      props.filterContactsForAdrBook(config);
    }
  }, [includeUnsaved, contactType, search, sortBy, activeTags, isShowEx]);

  return (
    <div className="adr-book-list-wrapper">
      <header className={classModifier('adr-book-list-wrapper__header', [isEscortList && 'escort'])}>
        <button 
          className={classModifier('adr-book-list-wrapper__select-btn', isSelectMode && 'selected')}
          onClick={handleSelectMode}
        >
          <ICONS.check />
          {isSelectMode ? 'Exit Select' : 'Select'}
        </button>

        <AdrBookListSort
          sortBy={sortBy}
          changeContactSorting={props.changeContactSorting}
          contactsPending={props.contactsPending}
        />

        {search && !contactsPending &&
          <span className="adr-book-list-wrapper__search-count">
            {props.ids.filter(x => x).length} results found matching "{search}" search
          </span>
        }

        {isEscortList && (
          <button
            className="adr-book-list-wrapper__checkbox-btn"
            onClick={() => setShowEx(!isShowEx)}
          >
            <div
              className={classModifier('adr-book-list-wrapper__checkbox',
                isShowEx && 'active'
              )}
            >
              {isShowEx &&
                <ICONS.check className="adr-book-list-wrapper__show-ex-icon" />
              }
            </div>
            <div className="adr-book-list-wrapper__show-ex-text">Show Ex</div>
          </button>
        )}
      </header>

      <AdrBookList
        list={props.ids}
        limit={ADR_BOOK_LIMIT}
        pending={props.contactsPending}
        loadMore={updateList}
        listItem={AdrBookItem}
        isShowEx={isShowEx}
        mode={listMode}
        isSelectMode={isSelectMode}
        onAddSelectContact={onAddSelectContact}
        selectedContactsList={selectedContactsList}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  includeUnsaved: state.addressBook.includeUnsaved,
  contactType: state.addressBook.contactType,
  search: state.addressBook.search,
  sortBy: state.addressBook.sortBy,
  totalCount: state.addressBook.totalCount,
  activeTags: state.addressBook.tags.active,
  ids: selectAdrBookList(state),
  contactsPending: state.addressBook.contactsPending,
});

const mapDispatchToProps = {
  filterContactsForAdrBook,
  getContactsForAdrBook,
  changeContactSorting,
  getTagsForAdrBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookListWrap);
