import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { setforwardedMessage } from 'redux/ducks/mailing';

import './MessageHeader.scss'
import UserNameLabel from '../UserNameLabel/UserNameLabel';
import UsersCount from '../UsersCount/UsersCount';
import convertContactTypesToLabels from 'utils/convertContactTypesToLabels';

const MessageHeader = ({ message, actualUsersCount, formRef, statusOk }) => {
  const forwardedMessage = useSelector((state) => state.mailing.forwardedMessage);
  const dispatch = useDispatch();

  const contactsArray = Object.values(message.callers);
  const isForwarded = forwardedMessage === message.id;
  
  if (!contactsArray.length && !actualUsersCount) {
    return null;
  }
  
  const handleClick = () => {
    if (isForwarded) {
      formRef.change('message', '')
    }

    dispatch(setforwardedMessage(message.id));
  };

  return (
    <div className="mailing-log__message-header">
      <UsersCount count={!message.id ? actualUsersCount : contactsArray.length} />

      <div className="mailing-log__username-labels">
        {message.statuses.map(({ groupId, callerId, status }, index) => {
          const key = `${index}_${callerId || groupId}`;
          let name;

          if (callerId) {
            name = message.callers[callerId]?.fn;
          } else {
            name = convertContactTypesToLabels(groupId);
          }

          return (
            <UserNameLabel
              key={key}
              name={name}
              status={status}
              statusOk={statusOk}
            />
          )
        })}
      </div>

      <button
        type="button"
        className={classModifier("mailing-log__redirect-btn", isForwarded && 'active')}
        onClick={handleClick}
      >
        <ICONS.forward />
      </button>
    </div>
  )
}

export default memo(MessageHeader);
