import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './BookingSpecialRequestMessages.scss';
import CalendarFormField from 'components/CalendarFormField/CalendarFormField';

const BookingSpecialRequestMessageItem = props => {
  const { 
    item,
    removeField,
    isOneElement,
    isAnyEmptyItemData,
    setRequestItemData,
    addEmptyRequestField,
  } = props;

  return (
    <div className='booking-special-request-messages__message'>
      <div className='booking-special-request-messages__message-field'>
        <div className='booking-special-request-messages__main-header'>
          <h2 className='booking-special-request-messages__subtitle'>Message to Client</h2>
          <button 
            type="button" 
            disabled={isOneElement}
            className={classModifier('booking-special-request-messages__btn', 'clear')}
            onClick={() => removeField(item.id)}
          >
            <ICONS.close 
              className={classModifier('booking-special-request-messages__btn-icon', 'clear')}
            />
          </button>
        </div>

        <div className='booking-special-request-messages__textarea-wrap'>
          <textarea 
            value={item.body} 
            placeholder="Type in message"
            onChange={(e) => setRequestItemData(e.target.value, 'body')}
          />
        </div>
      </div>

      <div className='booking-special-request-messages__date-field'>
        <CalendarFormField date={+new Date(item.date) || ''} setDate={(data) => setRequestItemData(data, 'date')} />

        <button 
          type="button" 
          className={classModifier('booking-special-request-messages__btn', 'add-from')}
          disabled={isAnyEmptyItemData}
          onClick={addEmptyRequestField}
        >
          <ICONS.plusCircle className={classModifier('booking-special-request-messages__btn-icon', 'add')}/>
          Add new
        </button>
      </div>
    </div>
  )
};

export default BookingSpecialRequestMessageItem;