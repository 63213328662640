import ICONS from 'assets/icons';
import Spinner from 'components/UI/Spinner/Spinner';

import './UsersCount.scss'

const UsersCount = ({ count, pending }) => (
  <div className="users-count">
    {pending
      ? <Spinner className="users-count__spinner" spinnerSize="14" />
      : <span className="users-count__number">{count}</span>}

    <ICONS.users />
  </div>
)

export default UsersCount;
