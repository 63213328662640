import moment from 'moment';

import { getDateByTimezoneOffset } from 'utils';

export default (
  bookings,
  userTimezone,
  time = moment(getDateByTimezoneOffset(userTimezone)),
) => {
  for (let booking of bookings) {
    const { date, duration } = booking;
    const [count, dimension] = duration.split(' ');
    const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, date);
    const bookingDateWithDuration = moment(bookingDateByTimezone).add(Number(count), dimension);

    if (bookingDateWithDuration.isAfter(time)) {
      return booking;
    }
  }

  return bookings.at(-1);
}
