import React, { useCallback } from 'react';
import Creatable from 'react-select/creatable';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import './ContactLinksTab.scss';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';

const ContactLinksTab = ({ 
  callerId,
  saveContact,
  labelsOptions, 
  isUrlsLabelEdited,
  isNewContactCreation, 
}) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      backgroundColor: '#f6f6f6',
      cursor: 'pointer',
      marginLeft: '4px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: 12,
      cursor: 'pointer',
      padding: 0,
      color: '#000001'
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 'auto',
      border: 'none',
      backgroundColor: '#E6EBF1',
      boxShadow: 'none',
      '&:hover': {
        border: 'none'
      }
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 90,
      backgroundColor: '#f6f6f6',
      minWidth: '150px'
    }),
    dropdownIndicator: () => ({
      padding: '4px'
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: '1.6'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#00A6AD' : '#808080',
      overflow:'hidden',
      fontSize: 12,
      padding: '11px 15px',
      textOverflow: 'ellipsis',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      opacity: state.isDisabled ? 0.5 : 1,
      backgroundColor: state.isSelected && '#88D6D9',
      '&:hover': {
        backgroundColor: '#88D6D9'
      }
    })
  };

  const linksLabelChange = (props, fields, index) => {
    fields.update(index, {
      ...fields.value[index],
      title: props.value,
    });
  };

  const onDelete = (index, fields) => {
    const filteredUrls = fields.value.filter((_, idx) => idx !== index)
    saveContact('urls', filteredUrls);
    fields.remove(index);
  };

  const onBlur = (e, input, fields, index) => {
    input.onBlur && input.onBlur(e);

    if(!fields.value[index].url) {
      fields.remove(index);
    }
  };

  const onBlurSelect = () => {
    isUrlsLabelEdited && saveContact();
  }

  const addNewField = useCallback((fields) => {
    if (fields.length >= 10) return;

    fields.push({ 
      caller_id: callerId,
      title: '',
      url: '',
    });
  }, []);

  return (
    <div className="contact-links-tab">
      <FieldArray name="urls">
        {({ fields, meta: fieldArrayMeta }) => {
          return (<>
            <ul className='contact-links-tab__list'>
              {fields?.map((link, index) => (
                <Field
                  name={`${link}.url`}
                  type="text"
                  key={index}
                >
                  {({ input }) => (
                    <li className='contact-links-tab__item'>
                      <div className='contact-links-tab__item-header'>
                        <ICONS.link className='contact-links-tab__item-icon'/>
                        <Creatable
                          styles={customStyles}
                          className={classModifier(`contact-links-tab__select-url-label`, [
                            !isNewContactCreation &&
                              fieldArrayMeta.initial &&
                              (!fields.value[index]?.id ||
                                fieldArrayMeta.initial.find(field => field?.id === fields.value[index]?.id)?.title !== fields.value[index]?.title) &&
                              'edited',
                          ])}
                          value={!!fields.value[index].title
                            ? { value: fields.value[index].title, label: fields.value[index].title }
                            : { value: labelsOptions[0]?.label, label: labelsOptions[0]?.label }
                          }
                          options={labelsOptions}
                          placeholder="Link type"
                          onChange={e => linksLabelChange(e, fields, index)}
                          onBlur={onBlurSelect}
                          isOptionDisabled={option => fields.value.find(field =>  //Option disabled if:
                            field.title === option.value &&                       //1. Some field already exists with this option 
                            fields.value[index].title !== option.value            //2. Selected option of the current field is not equal to this option
                          )}
                          components={{
                            IndicatorSeparator: null,
                            DropdownIndicator: null
                          }}
                        />

                        <div className='contact-links-tab__item-btns'>
                          <CopyToClipboardButton
                            className={classModifier('contact-links-tab__item-btn', 'copy')}
                            value={fields.value[index].url}
                            disabled={!fields.value[index].url}
                            title='Copy url'
                          />

                          <button 
                            type='button' 
                            onClick={() => onDelete(index, fields)}
                            className={classModifier('contact-links-tab__item-btn', 'delete')}
                          >
                            <ICONS.trash className={classModifier('contact-links-tab__item-btn-icon', 'delete')}/>
                          </button>
                        </div>
                      </div>
                      
                      <input {...input} onBlur={(e) => onBlur(e, input, fields, index)} className='contact-links-tab__link-input'/>
                    </li>
                  )}
                </Field>
              ))}
            </ul>

            <button 
              type="button"
              disabled={fields.value?.some(item => !item.url)}
              className="contact-links-tab__btn-add"
              onClick={() => addNewField(fields)}
            >
              <ICONS.plusCircle className="contact-links-tab__btn-add-icon" />
            </button>
          </>)
        }}
      </FieldArray>

      {/* <h3 className="contact-links-tab__msg-template-title">Message Template</h3>

      <Field name='note'>
        {({ input, meta }) => (
          <FormTextarea
            input={input}
            autosize
            inputClassName="contact-links-tab__note"
            meta={meta}
          />
        )}
      </Field> */}
    </div>
  )
};

export default ContactLinksTab;