import { useDispatch, useSelector } from 'react-redux';

import { updateActiveChat } from 'redux/ducks/roomChats';
import { getNotificationProps } from 'utils/getNotiifcationProps';

import "./Chat.scss";
import classNames from 'classnames';

export const Chat = ({ notification, className }) => {
  const userTimezone = useSelector((state) => state.user.userTimezone);
  
  const dispatch = useDispatch();

  const chatTitle = getNotificationProps(notification, 'chatTitle');
  const messageId = getNotificationProps(notification, 'messageId');
  const chatId = getNotificationProps(notification, 'chatId');
  const operatorId = getNotificationProps(notification, 'operatorId');

  if (!chatTitle) {
    return '[NOT FOUND]';
  }

	const handleClick = () => {
    dispatch(updateActiveChat('rooms'));
    // dispatch(getChatMessageContext(messageId, chatId, null, operatorId, '', userTimezone))
  }

  return (
    <mark
      className={classNames("chat-link", className)}
      onClick={handleClick}
    >
      {chatTitle || '[DELETED]'}
    </mark>
  )
}
