import React, { useRef, useEffect, useState } from "react";
import { classModifier } from "utils";

import compressChildren from "utils/compressChildren";


const ProfileCardServices = (props) => {
  const [services, setServices] = useState(props.services);
  const refProfiles = useRef();

  useEffect(() => {
    handleWindowSize();

    window.addEventListener('resize', handleWindowSize);

    return () => window.removeEventListener('resize', handleWindowSize);
  }, []);


  const handleWindowSize = () => {
    setServices(compressChildren(refProfiles, props.services));
  };

  return (
    <ul ref={refProfiles} className="profile-card__services">
      {services.map((service, index) => {
        if (!service) return null;

        return (
          <li
            key={`${service.name}-${index}`}
            className={classModifier('profile-card__service', [
              service.price && 'with-price',
              props.sameServices.indexOf(service.name) !== -1 && 'common'
            ])}
          >
            {service.name}

            {service.price &&
              <span
                className="profile-card__service--price"
                title="for extra"
              >
              {service.price}&#163;
            </span>
            }
          </li>
        );
      })}
    </ul>
  );
};

export default React.memo(ProfileCardServices);
