import React, { memo, useState, useEffect, useMemo } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useSelector } from 'react-redux';
import { components } from 'react-select';

import { isEmptyObj } from 'utils';
import { usePrevious } from 'hooks';
import { CONTACT_TYPES } from 'config/constants';
import { getTopTags, searchTags } from 'redux/ducks/contacts';

import './TagsField.scss';
import { Field } from 'react-final-form';
import useChangeEffect from 'hooks/useChangeEffect';


const compareTags = (prevTags, nextTags) => {
  return (
    JSON.stringify(
      prevTags
        .map((tag) => tag.title)
        .slice()
        .sort()
    ) ===
    JSON.stringify(
      nextTags
        .map((tag) => tag.title)
        .slice()
        .sort()
    )
  );
};

const CLIENTS_TYPES = [CONTACT_TYPES.CLIENT];
const ESCORT_TYPES = [
  CONTACT_TYPES.GIRL,
  CONTACT_TYPES.AGENT,
  CONTACT_TYPES.SERVICES,
  CONTACT_TYPES.RECEPTION,
];

const TagsField = (props) => {
  const {
    tags,
    setTags,
    tagRenderer: TagRenderer,
    values,
    isAdrBook,
    saveContact,
    isNewContactCreation,
    setUpdatedTagsFilters,
    contactType,
    disabled,
  } = props;

  const [topOptions, setTopOptions] = useState(() => []);
  const [key, setKey] = useState(`tags-field`);
  
  const prevContactType = usePrevious(contactType);
  const previousTags = usePrevious(tags);

  const activeTagsFilters = useSelector((state) => state.addressBook.tags.active);
  const tagsFiltersIds = useSelector((state) => state.addressBook.tags.ids);
  const addressBookTags = useSelector((state) => state.addressBook.tags.entities);
  const addressBookSearch = useSelector((state) => state.addressBook.search);
  const activeContactTypeFilter = useSelector((state) => state.addressBook.contactType);

  // const callerTags = isNewContactCreation ? newContactTags : editedContactTags;

  const isEditedTags = useMemo(() => {
    const isEqual = compareTags(previousTags, tags);

    return !isNewContactCreation && !isEqual;
  }, [tags, isNewContactCreation]);

  useEffect(() => {
    const isContactTypeChanged =
      CLIENTS_TYPES.includes(prevContactType) && !CLIENTS_TYPES.includes(contactType)
      || ESCORT_TYPES.includes(prevContactType) && !ESCORT_TYPES.includes(contactType);

    if (isContactTypeChanged) {
      const name = CLIENTS_TYPES.includes(contactType) ? 'client' : 'escort';

      setTopOptions([]);
      setKey(`tags-field-${name}`);
    }
  }, [contactType]);

  const loadOptions = (inputValue, callback) => {
    if (!topOptions.length) {
      getTopTags(contactType)
        .then(tags => {
          setTopOptions(tags);
          callback(tags);
        })
        .catch(console.error);
    } else {
      searchTags(inputValue, contactType)
        .then(tags => callback(tags))
        .catch(console.error);
    }
  };

  const updateTagCount = (tag = {}, isRemoved) => {
    const updatedTag = addressBookTags[tag.id] || {};
    let prevTagState = null;

    if (addressBookTags[tag.id]) {
      if (!tagsFiltersIds.includes(Number(tag.id))) {
        prevTagState = { ...updatedTag, callersCount: 0 };
      } else {
        prevTagState = { ...updatedTag };
      }
    }

    if (isRemoved) {
      updatedTag.callersCount -= 1;
    } else {
      if (
        !tagsFiltersIds.includes(Number(tag.id))
      ) {
        if (isEmptyObj(updatedTag)) {
          updatedTag.id = Number(tag.id);
          updatedTag.title = tag.value;
          updatedTag.caller_type = CLIENTS_TYPES.includes(contactType) ? 1 : 2;
        }
        updatedTag.callersCount = 1;
      } else {
        updatedTag.callersCount += 1;
      }
    }

    return { updatedTag, prevTagState };
  };

  const updateTagsFilters = (updatedTag, action) => {
    action((prevState) => {
      let updatedPrevState;
      const index = prevState.findIndex((tag) => tag.id === updatedTag.id);

      if (index === -1) {
        updatedPrevState = [...prevState, updatedTag];
      } else {
        updatedPrevState = prevState.map((tag) => {
          if (tag.id === updatedTag.id) {
            return { ...tag, ...updatedTag };
          }

          return tag;
        });
      }

      return updatedPrevState;
    });
  };

  const onChangeHandler = ({ action, removedValue, option }) => {
    if (action === 'remove-value') {
      if (isAdrBook) {
        const hasContactTags =
          Object.keys(activeTagsFilters)
            .every((tagId) => tags.map((tag) => tag.id).includes(Number(tagId)));

        // if tag filtering is active and each selected tag has a contact OR the contact type matches the filter
        if (!isEmptyObj(activeTagsFilters) && hasContactTags ||
          (isEmptyObj(activeTagsFilters) &&
            (activeContactTypeFilter === Number(values.type) ||
              (activeContactTypeFilter === 0 && addressBookSearch.length)
            )
          )
        ) {
          const { updatedTag } = updateTagCount(removedValue, true);

          updateTagsFilters(updatedTag, setUpdatedTagsFilters);
        }
      }

      const getUpdatedTags = (prevState) => {
        const index = prevState.findIndex((tag) => tag.title === removedValue.label)

        return [...prevState.slice(0, index), ...prevState.slice(index + 1)]
      }

      // isNewContactCreation
      //   ? setNewContactTags(getUpdatedTags)
      //   : setEditedContactTags(getUpdatedTags);
      setTags(getUpdatedTags)
    } 
    else if (action === 'select-option') {
      if (isAdrBook) {
        const hasContactTags =
          Object.keys(activeTagsFilters)
            .every((tagId) => [...tags, option].map((tag) => +tag.id).includes(Number(tagId)));

        // if tag filtering is active and each selected tag has a contact OR the contact type matches the filter
        if (!isEmptyObj(activeTagsFilters) && hasContactTags ||
          (isEmptyObj(activeTagsFilters) &&
            (activeContactTypeFilter === Number(values.type) ||
              (activeContactTypeFilter === 0 && addressBookSearch.length)
            )
          )
        ) {
          const { updatedTag } = updateTagCount(option);

          updateTagsFilters(updatedTag, setUpdatedTagsFilters);
        }
      }

      // isNewContactCreation
      //   ? setNewContactTags((prevState) => [...prevState, { title: option.label, color: option.color, id: option.id }])
      //   : setEditedContactTags((prevState) => [...prevState, { title: option.label, color: option.color, id: option.id }]);
      setTags((prevState) => ([
        ...prevState,
        { title: option.label, color: option.color, id: option.id }
      ]))
    }
  };

  const getTagsValue = (tags) =>
    tags.map((tag) => ({
      label: tag.title,
      ...tag,
    }));

  const handleBlur = () => {
    isEditedTags && saveContact && saveContact(values);
  }

  const MultiValue = (props) => (
    <TagRenderer
      {...props.data}
      className='tags-field__tag'
      containerProps={props}
      showRemoveButton={!disabled}
    />
  )

  const Option = (props) => (
    <TagRenderer
      {...props.data}
      className='tags-field__tag'
      containerProps={props}
    />
  )

  const MenuList = (props) => (
    <components.MenuList
      {...props}
      innerProps={{ className: 'popup-menu' }}
    />
  )

  return (
    <Field name='callerTags'>
      {({ input, ...rest }) => (
        <AsyncCreatableSelect
          {...input}
          {...rest}
          isMulti
          key={key}
          isClearable={false}
          defaultOptions
          loadOptions={loadOptions}
          value={getTagsValue(tags)}
          getOptionLabel={(option) => option.label || option.title}
          getOptionValue={(option) => option.value || option.title}
          onChange={(tags, action) => {
            input.onChange(tags);
            onChangeHandler(action)
          }}
          onBlur={handleBlur}
          onCreateOption={(tag) => {
            // isNewContactCreation
            //   ? setNewContactTags((state) => ([...state, tag]))
            //   : setEditedContactTags((state) => ([...state, tag]));
            setTags((state) => ([...state, tag]))
          }}
          isValidNewOption={() => false}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: null,
            LoadingIndicator: null,
            MultiValue,
            Option,
            MenuList,
          }}
          maxMenuHeight={115}
          placeholder='Add tags...'
          className='tags-field-container'
          classNamePrefix='tags-field'
          isDisabled={disabled}
        />
      )}
    </Field>
  )
};

export default memo(TagsField);
