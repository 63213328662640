import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';

import { classModifier } from 'utils';
import { CHAT_TYPES } from 'config/constants';
import { useDidUpdate, usePrevious } from 'hooks';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';

import Spinner from '../UI/Spinner/Spinner';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import VideoThumbnail from '../VideoThumbnail/VideoThumbnail';

const FILES_SIZE_LIMIT_MB = 20;

const DropMediaContainer = ({ activeRecipient, isDropzoneDisabled = false, ...props }) => {
  const initialState = {
    isDropOverlay: false,
    isDropAccepted: false,
    dropRejectedText: '',
    isDropzoneDisabled: activeRecipient.default_channel === 'iphone',

    uploadFilesPending: false,
    dropFiles: null,

    compressImgPending: false,
  }

  const [state, setState] = useState(initialState);

  const prevDropFiles = usePrevious(state.dropFiles);

  const isRoomChat = props.type === CHAT_TYPES.ROOM;

  useDidUpdate(() => {
    if (prevDropFiles && !state.dropFiles) {
      prevDropFiles.forEach(file => URL.revokeObjectURL(file.dropMediaPreviewUrl));
    }
  }, [state.dropFiles]);

  useDidUpdate(() => {
    cleanDropMediaState();
  }, [activeRecipient.id]);

  useDidUpdate(() => {
    setState((prevState) => ({
      ...prevState,
      isDropzoneDisabled: activeRecipient.default_channel === 'iphone',
    }));
  }, [activeRecipient.default_channel]);

  const cleanDropMediaState = () => setState({ ...initialState });

  const removeDropMediaFile = (media) => {
    setState(prevState => ({
      ...prevState,
      dropFiles: prevState.dropFiles.filter(file => file.name !== media.name)
    }))

    URL.revokeObjectURL(media.dropMediaPreviewUrl);
  }

  const handleDragEnter = () => {
    if (!state.isDropOverlay) {
      setState(prevState => ({ ...prevState, isDropOverlay: true }));
    }
  }
  const handleDragLeave = () => {
    if (state.isDropOverlay) {
      setState(prevState => ({ ...prevState, isDropOverlay: false }));
    }
  }

  const handleDropAccepted = (dropFiles) => {
    const totalFileSizeMB = Math.ceil(dropFiles.reduce((acc, file) => acc + file.size, 0) / 1000000);

    if (totalFileSizeMB > FILES_SIZE_LIMIT_MB) {
      alert('Files size limit 20MB');
      return cleanDropMediaState();
    }
    if (dropFiles.length > 5) {
      setState(prevState => ({ ...prevState, dropRejectedText: 'Maximum 5 files', isDropOverlay: true }))
    }
    else {
      setState(prevState => ({
        ...prevState,
        isDropAccepted: true,
        dropRejectedText: '',
        isDropOverlay: true,
        isDropzoneDisabled: true,
        dropFiles: dropFiles.map(file => Object.assign(file, {
          dropMediaPreviewUrl: URL.createObjectURL(file)
        }))
      }))
    }
  }

  const handleDropRejected = () => {
    setState(prevState => ({ ...prevState, dropRejectedText: 'Incorrect file type' }))
  }

  const handleSubmit = async () => {
    // setState(prevState => ({
    //   ...prevState,
    //   compressImgPending: true
    // }))

    // const promises = state.dropFiles.map(async img => await compressImg(img));
    // // we need promiseAll because map loop will return Promise
    // const images = await Promise.all(promises);

    setState(prevState => ({
      ...prevState,
      uploadFilesPending: true,
      compressImgPending: false
    }))

    if (props.onSubmit) {
      props.onSubmit(state.dropFiles);
        // .then(() => cleanDropMediaState())
        // .catch(console.error)
      cleanDropMediaState();
    }
  }

  const renderButtonText = () => {
    if (state.compressImgPending) {
      return (
        <>Gzip&nbsp;<Spinner spinnerSize={13} /></>
      )
    }
    else if (state.uploadFilesPending) {
      return (
        <>Wait&nbsp;<Spinner spinnerSize={13} /></>
      )
    }
    else {
      return "Upload"
    }
  }

  const showOverlayContent = () => {
    if (state.dropRejectedText) {
      return (
        <h3
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => cleanDropMediaState()}
        >
          {state.dropRejectedText}
        </h3>
      )
    }
    else if (state.isDropAccepted) {
      return <div className="dropzone-accepted">
        <h3 className="dropzone-accepted__title">Upload files</h3>
        <p className="dropzone-accepted__limit">Files size limit 20MB</p>

        <div className="dropzone-accepted__thumbs">
          {state.dropFiles.map(file => (
            <div
              key={file.name}
              className={classModifier('dropzone-accepted__thumb-wrap', file.type.startsWith('video') && 'video')} 
            >
              {state.dropFiles.length > 1 &&
                <button
                  onClick={() => removeDropMediaFile(file)}
                  className="dropzone-accepted__btn dropzone-accepted__btn--remove"
                >
                  <FontAwesomeIcon title="remove drop image" icon={faTimes} color='#fff' />
                </button>
              }

              {file.type.startsWith('video')
                ? (
                  <VideoThumbnail
                    src={file.dropMediaPreviewUrl}
                    onClick={() => props.openModal(MODAL_TYPES.video, { src: file.dropMediaPreviewUrl })}
                  />
                ):(
                  <LazyLoadImage
                    src={file.dropMediaPreviewUrl}
                    onClick={() => props.openModal(MODAL_TYPES.image, { src: file.dropMediaPreviewUrl })} />
                )
              }
            </div>
          ))}
        </div>

        <div className="dropzone-accepted__btns">
          <button
            onClick={cleanDropMediaState}
            className="dropzone-accepted__btn dropzone-accepted__btn--cancel">
            Cancel
          </button>

          <button
            onClick={handleSubmit}
            className="dropzone-accepted__btn dropzone-accepted__btn--submit">
            {renderButtonText()}
          </button>
        </div>
      </div>
    }
    else {
      return <>
        <FontAwesomeIcon icon={faUpload} title="upload" color='#404040' size="5x" />
        <h3 className="dropzone-overlay__title">Upload images to chat</h3>
      </>
    }
  }

  return (
    <Dropzone
      accept='image/jpeg, image/png, image/gif, image/webp, video/*, .mkv, .mov, .avi'
      noClick
      disabled={state.isDropzoneDisabled}
      onDragEnter={handleDragEnter} // show overlay 
      onDragLeave={handleDragLeave} // close overlay
      onDropAccepted={handleDropAccepted} // open Accepted preview component
      onDropRejected={handleDropRejected} // open Rejected preview component
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({
          className: isRoomChat
            ? "chat__dropzone"
            : "chat__dropzone chat__dropzone--with-bg"
        })}>

          {props.children(getInputProps)}

          {state.isDropOverlay && // TODO: create component for this
            <div className="chat__dropzone-overlay dropzone-overlay">
              {showOverlayContent()}
            </div>
          }
        </div>
      )}
    </Dropzone >
  );
}

export default DropMediaContainer;
