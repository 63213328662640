import { useSelector } from 'react-redux';

import { getContactAvatar } from 'utils';
import { selectOperatorsOnMailPage } from 'redux/selectors/selectors';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const MailboxUsers = () => {
  const activeOperators = useSelector(selectOperatorsOnMailPage);

  return (
    <div className="mail-header__mailbox-users">
      <p className="mail-header__mailbox-users-title">
        {activeOperators.length
          ? "Users using this mailbox"
          : "No one is using this mailbox"}
      </p>

      <ul className="mail-header__mailbox-users-list">
        {!!activeOperators.length && (
          activeOperators.map((operator) => (
            <li className="mail-header__mailbox-users-img-wrap" key={operator.id}>
              <LazyLoadImage
                alt="ava"
                src={operator.avatar || getContactAvatar(operator)}
                className="mail-header__mailbox-users-img"
              />
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default MailboxUsers;
