import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { getContactsById } from 'redux/ducks/contacts';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import clientImg from 'assets/images/avatars/avatarDefault.png'

import "./BookingClient.scss";
import Tooltip from 'components/UI/Tooltip/Tooltip';
import { updateActiveContact } from 'redux/ducks/clientChats';

const BookingClient = ({
  className,
  booking,
  isAvatarShowed,
  isNotClickable,
  extraText,
  shouldOpenChat,
}) => {
  const caller_avatar = booking.caller_avatar || booking.caller.photo || clientImg;
  const dispatch = useDispatch();

  const openContactModal = (callerId) => {
    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          dispatch(openModal(MODAL_TYPES.contactCard, { contact: callerId }));
        }
      })
  };

  const openChat = (callerId) => {
    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          dispatch(updateActiveContact(contact))
        }
      })

    const customEvent = new CustomEvent('openChat', { detail: { clientId: callerId } });
    dispatchEvent(customEvent);
  }

  const handleNameClick = (e) => {
    if (isNotClickable) return;

    e.stopPropagation();

    const callerId = booking.callerId || +booking.caller_id;

    if (!callerId) return;

    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          shouldOpenChat ? openChat(callerId) : openContactModal(callerId);
        }
      })
  }

  return (
    <div className={classNames(className, 'booking-client')}>
      {isAvatarShowed && (
        <img
          alt="ava"
          className="booking-client__avatar"
          src={caller_avatar}
        />
      )}

      <Tooltip text={booking.caller.fn} visibleWhenIsOverflowed>
        {!extraText ? (
          <p className="booking-client__name" onClick={handleNameClick}>
            {booking.caller.fn}
          </p>
        ) : (
          <div className="booking-client__details">
            <p className="booking-client__name" onClick={handleNameClick}>
              {booking.caller.fn}
            </p>

            {extraText && (
              <p className="booking-client__extra-text">
                {extraText}
              </p>
            )}
          </div>
        )}
      </Tooltip>
    </div>
  )
}

export default BookingClient;
