import ICONS from 'assets/icons';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { classModifier } from 'utils';

import './BookingRequirements.scss';

const BookingRequirements = ({ fields, isDisabled, actualType }) => {
  const form = useForm();

  const onChange = (e, fieldsValue, idx) => {
    const updatedReqs = fieldsValue.map((item, id) => {
      return id === idx ? { ...item, isChecked: e.target.checked } : item
    });

    form.change(fields.name, updatedReqs);
  }

  return (
    <ul className='booking-requirements'>
      {fields?.map((requirement, idx) => {
        const { title, isChecked, color, rule } = fields.value[idx];

        if(rule && actualType !== rule) {
          return null;
        };
        return (
          <Field key={idx} name={`${requirement}.isChecked`} type="checkbox">
            {({ input }) => {
              return (
                <li className='booking-requirements__item' title={title}>
                  <label className='booking-requirements__item-label'>
                    <span>{title}</span>
                    <div
                      className={classModifier('booking-requirements__item-checkbox', !!isChecked && 'checked')}
                      style={color
                        ? {
                            borderColor: color,
                            backgroundColor: isChecked ? color : 'inherit',
                          }
                        : {}
                      }
                    >
                      {!!isChecked && <ICONS.check className='booking-requirements__icon-check'/>}
                    </div>
                    <input 
                      {...input}
                      onChange={(e) => onChange(e, fields.value, idx)}
                      className='booking-requirements__item-input'
                      disabled={isDisabled}
                    />
                  </label>
                </li>
              )
            }}
          </Field>
        )
      })}
    </ul>
  );
}

export default React.memo(BookingRequirements);
