import React from 'react';
import classNames from 'classnames';

import Select from 'react-select';


const ChatListFilter = (props) => {
  const defaultFilter = props.filters.find((filter) => filter.name === props.defaultFilter)
    || props.filters.find((filter) => filter.name.includes('All'));

  const onFilterChange = (filter) => {
    if (props.isQuery) {
      props.setQuery('');
    }
    filter.action();
    props.toggleOpenned?.();
  }

  return (
    <div
      className={classNames(props.className, "sidebar-filter-select")}
      ref={props.selectorRef}
    >
      <Select
        className="react-select"
        classNamePrefix="react-select"
        options={props.filters}
        getOptionValue={option => option.name}
        getOptionLabel={option => option.name}
        value={{ name: defaultFilter.name }}
        onChange={onFilterChange}
        isSearchable={false}
        menuPlacement="auto"
        components={{
          IndicatorSeparator: null,
        }}
      />
    </div>
  )
}

export default React.memo(ChatListFilter);
