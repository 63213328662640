import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { BOOKING_LOCATION_TYPES, GOOGLE_MAPS_API_KEY } from 'config/constants';

import './BookingLocationEditor.scss';
import BookingLocationFields from 'components/BookingForm/components/BookingLocationFields';
import Spinner from 'components/UI/Spinner/Spinner';

const containerStyle = {
  width: '510px',
  height: '308px',
  margin: '0 auto',
};

const BookingLocationEditor = props => {
  const {
    bookingId, 
    type,
    address,
    isLocationEditorOpen,
    setIsLocationEditorOpen,
    callerId,
  } = props;

  const addressUUID = useSelector((state) => state.bookings.entities[bookingId]?.address?.uuid);

  const [map, setMap] = useState(null);
  const [ libraries ] = useState(['places']);

  const { locationName, longitude, latitude } = address;

  const [mapData, setMapData] = useState({
    center: {},
    markers: {},
    type: address.type || BOOKING_LOCATION_TYPES.PRIVATE,
    formattedAddress: locationName
  });

  const searchBoxRef = useRef();
  const mapRef = useRef();
  const form = useForm();

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend({
        lat: mapData.markers.lat,
        lng: mapData.markers.lng,
      });
      map.fitBounds(bounds);
    }
  }, [map, mapData.markers]);

  useEffect(() => {
    const coords = {
      lat: latitude,
      lng: longitude
    }

    setMapData((currentMapData) => ({
      ...currentMapData,
      markers: coords,
      center: coords
    }));
  }, [address]);

  useEffect(() => {
    const handleClickOutside = event => {
      const isClickedOutside =
        mapRef.current &&
        !mapRef.current.contains(event.target) &&
        !document.querySelector('.pac-container')?.contains(event.target);

      if (isClickedOutside) {
        setIsLocationEditorOpen(false);
      }
    }

    isLocationEditorOpen &&  document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [mapRef, isLocationEditorOpen]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  const handleSaveLocation = () => {
    if (type === 'outcall') {
      form.change('address.uuid', addressUUID);
      form.change('address.type', mapData.type);
      form.change('address.latitude', mapData.markers.lat);
      form.change('address.longitude', mapData.markers.lng);
      form.change('address.locationName', mapData.formattedAddress);
    }

    setIsLocationEditorOpen(false);
  };

  const handleCancel = () => setIsLocationEditorOpen(false);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(mapData.center);
    map.fitBounds(bounds);
    setMap(map);
  }, [mapData.center]);

  const onUnmount = useCallback(() => {
    setMap(null);

    Array
      .from(document.getElementsByClassName('pac-container'))
      .forEach(i => i.remove());
  }, []);

  const onSearchBoxLoad = searchBox => searchBoxRef.current = searchBox;
  
  const onPlacesChanged = useCallback(() => {
    const [searchData] = searchBoxRef.current?.getPlaces();

    const lat = searchData.geometry.location.lat();
    const lng = searchData.geometry.location.lng();

    setMapData((currentMapData) => ({
      ...currentMapData,
      markers: { lat, lng },
      formattedAddress: searchData.formatted_address
    }));
  }, []);

  return isLocationEditorOpen && (
    <div ref={mapRef} className='booking-location-editor'>
      <div className='booking-location-editor__tooltip'>
        <div className='booking-location-editor__header'>
          <h2 className='booking-location-editor__title'>
            {type} Address
          </h2>
          <div className='booking-location-editor__btns-wrapper'>
            <button 
              type='button' 
              onClick={handleCancel}
              className={classModifier('booking-location-editor__btn', 'cancel')}
            >
              Cancel
            </button>
            <button 
              type='button' 
              onClick={handleSaveLocation}
              className={classModifier('booking-location-editor__btn', 'save')}
            >
              Save
              <ICONS.check className={classModifier('booking-location-editor__btn-icon', 'check')}/>
            </button>
          </div>
        </div>

        <div className='booking-location-editor__main'>
          <div className='booking-location-editor__fields-wrapper'>
            <BookingLocationFields
              isBookingEdit
              isLoaded={isLoaded}
              locationType={mapData.type}
              onSearchBoxLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
              setLocationType={type => {
                setMapData(prevState => ({
                  ...prevState, type,
                }));
              }}
              setMapData={formattedAddress => {
                setMapData(prevState => ({
                  ...prevState, formattedAddress,
                }));
              }}
              callerId={callerId}
            />
          </div>

          <div className="booking-location-editor__map">
            {isLoaded 
              ? <GoogleMap
                  options={{ 
                    maxZoom: 19, 
                    controlSize: 20 
                  }}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  center={mapData.center}
                  mapContainerStyle={containerStyle}
                >
                  <Marker position={mapData.markers}/>
                </GoogleMap>
              : <Spinner spinnerSize={40}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(BookingLocationEditor);
