import React, { createContext, useContext } from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';
import { BOOKING_FILTERS } from 'components/FilteredBookings/config/constants';

import './BookingFilters.scss';
import Tooltip from 'components/UI/Tooltip/Tooltip';

const BookingFiltersContext = createContext(null);

export const BookingFilters = ({ getterProps, className, children }) => (
  <BookingFiltersContext.Provider value={{ getterProps }}>
    <div className={classNames(className, "booking-filters")}>
      {children}
    </div>
  </BookingFiltersContext.Provider>
);

Object.entries(BOOKING_FILTERS).forEach(([filterName, filter]) => {
  const Component = ({ className, getterProps, tooltip, children = null }) => {
    const contextProps = useContext(BookingFiltersContext);
    const { active, disabled, onClick }
      = (contextProps?.getterProps || getterProps)({ name: filter.label });
    const buttonContent = filter.image || children;

    return (
      <Tooltip text={tooltip || filter.label}>
        <button
          type="button"
          className={classNames(
            classModifier('booking-filters__item', [active && 'active', filter.label]),
            className
          )}
          style={{ color: filter.color }}
          disabled={disabled}
          onClick={onClick}
        >
          {buttonContent}
        </button>
      </Tooltip>
    )
  }

  BookingFilters[filterName] = Component;
})
