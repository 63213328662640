import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { selectAgentsList } from 'redux/selectors/selectors';
import { DIVA_GIRLS_IMG_HOST_PREVIEW } from 'redux/ducks/divaGirls';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import API from 'api/api';
import OverflowedTags from 'components/OverflowedTags/OverflowedTags';
import ContactTags from 'components/ContactTags/ContactTags';

const BookingSelectedProfile = ({ 
  idx,
  profile = {},
  notForIds = [],
  setProfile,
  deleteBooking,
  isEditBooking,
  isDisabled,
  userRole,
  onClick,
}) => {
  const {
    age = '',
    name,
    dress,
    avatar,
    agents = [],
    height,
    prices = [],
    is_smoker,
    has_tatoo,
    nationality,
    is_available_now,
    agent_id: agentId,
    is_available_today,
    breast_size: breastSize,
  } = profile;
  

  const imgPath = DIVA_GIRLS_IMG_HOST_PREVIEW + avatar;
  const isNotFor = notForIds.includes(profile.id);
  const [tags, setTags] = useState([]);
  const [caller, setCaller] = useState(null);

  const getShortRate = () => prices.find(i => i.name === '30 minutes')?.incall || '-';
  
  const getProfileShortBrestSize = () => {
    return breastSize ? breastSize.split(' ')[0] : '';
  };

  const getAgentName = () => {
    const agentName = agents.find(agent => agent.id === agentId)?.fn;
    return agentId && agentName ? `/ Agent ${agentName}` : '';
  };

  const renderProfileRate = () => {
    const hour = prices.find(rate => rate.name === '1 hour') || {};
    const { incall = '-', outcall = '-' } = hour;

    return `${incall} / ${outcall}`;
  };

  useEffect(async () => {
    try {
      const { data } = await API.getContactsByProfileDivaId(profile.id) || {};
      
      setCaller(data?.[0] || null);
    } catch(err) {
      console.error(err);
    }
  }, [profile.id]);

  return (
    <div className='booking-form__diva-info-block' onClick={onClick}>
      <LazyLoadImage src={imgPath} alt="ava-girl" />
      <div className='booking-form__diva-info'>
        <div className='booking-form__diva-header'>
          <div className='booking-form__diva-name-wrapper'>
            {isNotFor && <ICONS.stop />}
            <span className={classModifier('booking-form__diva-name', [
              is_available_now && "available-now",
              !is_available_now && !is_available_today && "off-today",
              agentId && 'has-agent'
              ])}
            >
              {name}
              {!!agentId && <ICONS.a className='booking-form__agent-icon'/>}
            </span>
          </div>

          {isEditBooking && 
            <div className='booking-form__diva-control-btns'>
              <button 
                type='button'
                className={classModifier('booking-form__btn', 'edit')}
                onClick={setProfile}
                disabled={isDisabled}
              >
                <ICONS.pencil className={classModifier('booking-form__btn-icon', 'edit')} />
              </button>

              <button 
                type='button'
                onClick={() => deleteBooking(idx, profileEntities[idx])}
                className={classModifier('booking-form__btn', 'delete-profile')}
                disabled={isDisabled && userRole !== 'admin'}
              >
                <ICONS.close className={classModifier('booking-form__btn-icon', 'delete-profile')}/>
              </button>
            </div>
          }
        </div>

        <span className='booking-form__profile-short-info'>
          {`£ ${getShortRate()} / ${renderProfileRate()} / ${age} y.o. / ${height}cm / Dress ${dress} / ` + 
          `${getProfileShortBrestSize()} / ${nationality} ${getAgentName()}`}
        </span>

        <ContactTags
          className='booking-form__profile-tags-wrapper'
          callers={caller}
          wrapper={OverflowedTags}
        />
      </div>
    </div>
  )
}

const profileEntities = {
  0: 'profile',
  1: 'escort'
};

const mSTP = (state, ownProps) => ({
  agents: selectAgentsList(state),
})

export default connect(mSTP)(BookingSelectedProfile);
