import React, {
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import API from 'api/api';
import { CLIENT_CATEGORY_OPTIONS, CONTACT_TYPES } from 'config/constants';
import { useCancelToken, useDidMount, useDidUpdate, usePrevious, useToggle } from 'hooks';
import { classModifier, getIsFieldEmpty, isEmptyObj } from 'utils';
import { addDivaGirlsToEntities, getActiveDivaGirls } from 'redux/ducks/divaGirls';
import { toggleCreateContactForm, updateActiveAdrBookContactId, updateAdrBookTags } from 'redux/ducks/addressBook';
import { 
  addAgentData, 
  addNewContact, 
  updateContact,
  removeAgentData, 
  updateAgentsGirlIds
 } from 'redux/ducks/contacts';
import { selectAgentContactById, selectContactById } from 'redux/selectors/selectors';
import { updateContactsListByUid } from 'redux/ducks/contacts';

import './AdrBookContactForm.scss';
import AdrBookContactFormAutoSave from './AdrBookContactFormAutoSave';
import ViaItems from 'components/ContactFormItems/ViaItems/ViaItems';
import TagsField from 'components/ContactFormItems/TagsField';
import PhoneNumbersField from 'components/ContactFormItems/PhoneNumbersField/PhoneNumbersField';
import EmailsField from 'components/ContactFormItems/EmailsField/EmailsField';
import LockForm from 'components/LockForm/LockForm';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import AdrBookContactFormHeader from '../AdrBookContactFormHeader/AdrBookContactFormHeader';
import AdrBookContactFormTabs from '../AdrBookContactFormTabs/AdrBookContactFormTabs';
import NotForField from 'components/ContactFormItems/NotForField/NotForField';
import NicknameTelegramField from 'components/ContactFormItems/NickNameTelegramField/NicknameTelegramField';
import ContactTags from 'components/ContactTags/ContactTags';

const compareTags = (prevTags, nextTags) => {
  return (
    JSON.stringify(
      prevTags
        .map((tag) => tag.title)
        .slice()
        .sort()
    ) ===
    JSON.stringify(
      nextTags
        .map((tag) => tag.title)
        .slice()
        .sort()
    )
  );
};

const AdrBookContactForm = (props) => {
  const {
    active,
    callbacksRef,
    isNewContactCreation,
  } = props;
  
  const [editedContact, setEditedContact] = useState(props.editedContact || { callerTags: [] });
  const [historyStateList, setHistoryStateList] = useState([props.editedContact]);

  const [initialValues, setInitialValues] = useState({});
  const [photo, setPhoto] = useState({ url: null, file: null });
  const [phoneLabels, setPhoneLabels] = useState([]);
  const [linksLabels, setLinksLabels] = useState([]);
  // const [initialTags, setInitialTags] = useState([]);
  // const [editedContactTags, setEditedContactTags] = useState([]);
  
  const [serverError, setServerError] = useState('');
  const [pending, setPending] = useState(false);

  const [newContactTags, setNewContactTags] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);
  const [newContactAdr, setNewContactAdr] = useState('');
  const [isCreatedNewContact, setIsCreatedNewContact] = useState(false);
  const [updatedContact, setUpdatedContact] = useState(null);

  const [updatedTagsFilters, setUpdatedTagsFilters] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  
  const refId = useRef(active);

  const prevContactId = usePrevious(active);

  const [avaCropper, toggleAvaCropper] = useToggle(false);
  const { newCancelToken, cancelPrevRequest } = useCancelToken();

  const location = useLocation();
  const telFromDialPad = location.state?.telFromDialPad;
  
  const [lastStateListItem] = historyStateList.slice(-1);

  const searchSelectContactType = props.searchSelectContactType || CONTACT_TYPES.CLIENT;
  const getContactType = (values) => {
    if (isNewContactCreation) {
      return values.type ? Number(values.type) : 1
    } else {
      return editedContact.type;
    }
  }

  useEffect(() => {
    setEditedContact(props.editedContact || { callerTags: [] });
  }, [props.editedContact]);

  useEffect(() => {
    props.setUndoData({
      pending,
      historyStateListLength: historyStateList.length,
      lastStateListItemId: lastStateListItem?.id,
      isCreatedNewContact
    })
  }, [
    pending,
    isCreatedNewContact,
    historyStateList.length,
    lastStateListItem?.id
  ]);

  useImperativeHandle(callbacksRef, () => ({
    setPrevState, 
  }));

  useDidMount(() => {
    Promise.all([
      API.getPhoneLabels(),
      API.getLinksLabels(),
    ])
    .then(results => {
      const loadedTelLabels = results[0].data;
      const loadedLinksLabels = results[1].data;

      let telsOptions = [];
      let labelsOptions = [];

      let defaultTelLabel = '';

      if (loadedTelLabels.length) {
        loadedTelLabels.map(label => {
          if (label) telsOptions.push({ value: label.title, label: label.title, id: label.id })
        });

        defaultTelLabel = loadedTelLabels[0];
      }
      else {
        telsOptions.push({ value: 'default', label: 'default' });
        defaultTelLabel = 'default';
      }

      if (loadedLinksLabels.length) {
        loadedLinksLabels.map(label => {
          if (label) labelsOptions.push({ value: label, label})
        });
      }
      else {
        labelsOptions.push({ value: 'default', label: 'default' });
      }

      setPhoneLabels(telsOptions);
      setLinksLabels(labelsOptions);

      updateFormContact(defaultTelLabel);
    })
    .catch(err => console.error(err));
  });

  useDidUpdate(() => {
    if(!isNewContactCreation && !avaCropper && photo.file) {
      saveContact({
        ...editedContact,
        image: photo.file
      })
    }
  }, [avaCropper]);

  useDidUpdate(() => {
    if(refId.current && active !== refId.current) {
      setIsCreatedNewContact(false);
    }
  }, [active]);

  useDidUpdate(() => {
    if (isNewContactCreation) {
      initialize({});
      setFeedbackList([]);
      setNewContactTags([]);
      setPhoto({ url: null, file: null });
      setEditedContact({ callerTags: [] });
    } else {

      const contact = {
        ...props.editedContact,
        // emoji: props.editedContact?.fnParts?.emoji || '',
        // girlName: props.editedContact?.fnParts?.name || '',
        // location: props.editedContact?.fnParts?.location || '',
        // nationality: props.editedContact?.fnParts?.nationality || '',
        // feedback_letters: props.editedContact?.fnParts?.feedback_letters || '',
      }

      setPhoto({
        url: contact?.photo ? contact.photo.src : null, 
        file: null 
      });

      initialize(contact);
      setEditedContact(contact);

      if (contact.id !== prevContactId) { // if the user has switched to another contact
        if (historyStateList.length >= 2) { // if the previous contact has been changed
          // leave the history of changes unchanged and add new data
          setHistoryStateList(prevState => [...prevState, contact]);
        } else {
          setHistoryStateList([contact]);
        }
      }
    }

    !isEmptyObj(serverError) && setServerError({});
  }, [active, isNewContactCreation, props.editedContact]);

  useDidUpdate(() => {
    refId.current = active;

    setUpdatedTagsFilters([]);
  }, [active]);

  // useEffect(() => {
  //   const divaDefaultId = props.editedContact?.diva_default_id;

  //   const setGirlContactTags = (profile) => {
  //     const debtTag = {
  //       id: uuidv4(),
  //       title: `debt ${profile?.debt_data || ''}`, 
  //       color: '#F771A8',
  //       is_important: true,
  //       is_debt: true
  //     }
  
  //     const initTags = props.editedContact?.callerTags?.map(tag => ({
  //       id: tag.id,
  //       title: tag.title, 
  //       color: tag.color,
  //       is_important: tag.is_important
  //     })) || [];

  //     const isDebtProfile = profile && profile?.is_debt && props.editedContact?.type === 2;
  //     const tags = isDebtProfile ? [debtTag, ...initTags] : initTags;
  
  //     setInitialTags(tags);
  //     setEditedContactTags(tags);
  //   }

  //   if(!props.divaProfile && divaDefaultId) {
  //     props.getActiveDivaGirls({
  //       params: { 'filter-ids': [divaDefaultId] },
  //       quickBooking: true,
  //     })
  //     .then((res) => {
  //       if (res.girls) {
  //         setGirlContactTags(res.girls?.[0]);
  //       }
  //     })
  //     .catch(() => setGirlContactTags());
  //   } else {
  //     setGirlContactTags(props.divaProfile);
  //   }
  // }, [props.editedContact?.callerTags, props.editedContact?.diva_default_id]);

  useEffect(() => {
    if (editedContact.type === CONTACT_TYPES.GIRL && editedContact.id && editedContact.diva_default_id) {
      API.getActiveDivaGirlsByContactsIds(editedContact.id)
        .then(res => {
          props.addDivaGirlsToEntities([res.data]);
        })
        .catch(console.error);
    }
  }, [editedContact.type, editedContact.id, editedContact.diva_default_id]);

  const initialize = (editedContact) => {
    let addingFields = {
      type: isNewContactCreation ? String(searchSelectContactType) : String(editedContact.type),
      is_banned_for_media: isNewContactCreation ? false : editedContact.is_banned_for_media,
      addresses: editedContact.addresses || [],
      category: isNewContactCreation 
        ? CLIENT_CATEGORY_OPTIONS.find(item => item.value === null) 
        : editedContact.category || null,
    }

    if(telFromDialPad) {
      addingFields.tels = [{
        uuid: uuidv4(),
        default: 1,
        labelName: "default",
        tel: telFromDialPad,
      }];
      delete location.state;
    }

    const setInitial = (addingFields) => {
      const idRegEx = /\sID\s+?[0-9]+$/g.exec(editedContact.fn);
      const customID = idRegEx?.[0];
      const gettedID = customID?.match(/([\,?\.?\d+]+)/g)?.[0] || editedContact.fn?.match(/([\,?\.?\d+]+)/g)?.[0];
      const isEmptyContactFields = !editedContact.name && !editedContact.description;

      const idFromFn = editedContact.fn?.match(/^\d+/ig)?.[0];
      const fnForNotClient = editedContact.fn?.replace(idFromFn || '', '');

      const nameValue = isEmptyContactFields && editedContact.fn
        ? fnForNotClient
        : editedContact.name;

      isNewContactCreation
        ? setInitialValues({ ...addingFields })
        : setInitialValues({
          ...editedContact,
          ...addingFields,
          // emoji: editedContact?.fnParts?.emoji || '',
          // girlName: editedContact?.fnParts?.name || '',
          // location: editedContact?.fnParts?.location || '',
          // nationality: editedContact?.fnParts?.nationality || '',
          // feedback_letters: editedContact?.fnParts?.feedback_letters || '',
          note: editedContact.note || null,
          addresses: editedContact.addresses || [],
          name: nameValue?.replace(/Client [ABC] /g, '') || '',            // temporary solution to fix dublicates after bugs in names
          custom_id: +editedContact.type === +CONTACT_TYPES.GIRL
            ? isNewContactCreation ? '' : gettedID
            : editedContact.custom_id || idFromFn || null,
        });
    };

    if (isNewContactCreation) {
      // addingFields.type = String(CHAT_TYPES.CLIENT);

      return setInitial(addingFields);
    }

    if (+editedContact.type === +CONTACT_TYPES.GIRL) {
      addingFields.agent = props.defaultAgent;

      return setInitial(addingFields);
    }

    setInitial(addingFields);
  }

  const updateFormContact = () => {
    if (!isNewContactCreation) {
      setNewContactAdr(editedContact.addresses || []);
    }

    initialize(editedContact);
  };

  const getFullName = (values) => {
    const fn = [
      values.custom_id,
      typeof values.category === 'string' ? `Client ${values.category}` : values.category?.value && `Client ${values.category}`,
      values.name?.replace(/Client [ABC] /g, '') || '',   // temporary solution to fix dublicates after bugs in names
      values.description
    ].filter(Boolean).join(' ');

    return fn;
  };

  const saveContact = (values, lastHistoryItem, undo = false) => {
    const isValid = Object.values(validate(values)).every(el => el.length === 0);
    if (!isValid) {
      console.error('Form is not valid');
      return;
    }
    setPending(true);
    isCreatedNewContact && setIsCreatedNewContact(false);
    const isNewContactType = editedContact.type !== +values.type;
    const isDeleteCustomId = !!+props.editedContact?.custom_id && !+values?.custom_id;

    serverError && setServerError('');

    if (isNewContactCreation) {
      props.addNewContact({
        ...values,
        image: values.file || photo.file,
        // callerTags: newContactTags,
        // "notes" param was changed 17.03.2022 from String to Array for support
        // multiple feedbacks with new contact; and also requires back-end changes.
        // When new contact opened, feedbackList items contains "tempId"
        // (generated on front-end). And "tempId" should be replaced with "id"
        // after first sending to back-end
        notes: feedbackList,
        adr: newContactAdr,
      })
        .then((res) => {
          setPending(false);
          setIsCreatedNewContact(true);
          props.updateActiveAdrBookContactId(res.data?.id);
          props.updateAdrBookTags(updatedTagsFilters); 

          setUpdatedTagsFilters([]);
        })
        .catch(err => {
          setPending(false);
          err.response && setServerError(JSON.parse(err.response?.data?.message || ''));
        })

      return;
    }

    if (editedContact.type === 3 && isNewContactType) {
      props.removeAgentData(editedContact);
    }
    else if (+values.type === 3 && isNewContactType) {
      props.addAgentData(editedContact.id);
    }

    if (values.agent?.id && editedContact.agentId !== values.agent?.id) { // if we change agentId
      props.updateAgentsGirlIds(editedContact.agentId, values.agent.id, editedContact.id);
    }

    if (active === updatedContact) {
      cancelPrevRequest();
    }

    const params = {
      ...values,
      image: undo ? values.photo?.src : values.file || values.photo?.src,
      addresses: values?.addresses || [],
      fn: +values.type === +CONTACT_TYPES.CLIENT ? getFullName(values) : values.fn,
      // emoji: values.fnParts?.emoji || '',
      // girlName: values?.fnParts?.name || '',
      // location: values?.fnParts?.location || '',
      // nationality: values?.fnParts?.nationality || '',
      // feedback_letters: values?.fnParts?.feedback_letters || '',
      // callerTags: undo
      //   ? values.callerTags || []
      //   : isNewContactType
      //     ? []
      //     : editedContactTags,
    };

    setUpdatedContact(params.id);

    // canceling clears the change history later than the contact was updated,
    // causing autosave and unnecessary request. This should solve the problem temporarily
    if (undo) {
      setHistoryStateList([params]);
    } else {
      if (active === refId.current) {
        setHistoryStateList(prevState => [...prevState, params]);
      } else {
        setHistoryStateList(prevState => [editedContact, params, ...prevState]);
      }
    }

    props.updateContact(params, editedContact.type, newCancelToken(), isDeleteCustomId)
      .then(res => {
        setPending(false);

        const data = {
          ...res.data,
          emoji: res.data?.fnParts?.emoji || '',
          // girlName: res.data?.fnParts?.name || '',
          // location: res.data?.fnParts?.location || '',
          // nationality: res.data?.fnParts?.nationality || '',
          // feedback_letters: res.data?.fnParts?.feedback_letters || '',
        }

        if (undo) {
          const isEqual = compareTags(values.callerTags, lastHistoryItem.callerTags);

          setHistoryStateList([data]);
          !isEqual && props.updateAdrBookTags([], true);

          if (values.id !== editedContact.id) {
            props.updateActiveAdrBookContactId(values.id);
          } else {
            const contactUrl = res.data.photo ? res.data.photo.src : null;

            initialize(data);
            setEditedContact(data);
            setPhoto({ url: contactUrl, file: null });
          }
        } else {
          if (active === refId.current) {
            setPhoto({ ...photo, file: null });
            setEditedContact(data)
            props.updateAdrBookTags(updatedTagsFilters);

            setUpdatedTagsFilters([]);
          } 
          // else setHistoryStateList(prevState => [editedContact, res.data, ...prevState]);
        }
      })
      .catch(err => {
        setPending(false);

        const errMsg = err.response?.data?.message;
        err.__CANCEL__ && setPending(true);
        err.response && setServerError(errMsg);
      })
  };

  const getUpdatedFullName = (formValues, updatedValue) => {
    const values = { ...formValues, ...updatedValue };

    return getFullName(values);
  }

  const onChangeFullName = (formChange, prevValues, currValue = {}, isClearField) => {
    const fn = getUpdatedFullName(prevValues, currValue);

    formChange('fn', fn);

    if(isClearField) {
      const [fieldName] = Object.keys(currValue);

      formChange(fieldName, '');
      saveContact({ ...prevValues, fn, [fieldName]: '', });
    }
  };

  const isEditedTelLabel = (values) => !isNewContactCreation && 
    values.tels?.some((obj, idx) => obj?.labelName !== lastStateListItem?.tels[idx]?.labelName);

  const isEditedUrlsLabel = (values) => !isNewContactCreation && 
    values.urls?.some((obj, idx) => obj?.title !== lastStateListItem?.urls[idx]?.title);

  const isFieldEdited = (values, initialValues) => {
    return !isNewContactCreation &&
      !(values === undefined && initialValues === '') &&
      initialValues !== undefined &&
      initialValues !== values;
  }

  const setPrevState = () => {
    // [2, 2, 2, 4, 5, 5, 6, 8] => [2, 2, 2, 5, 5]
    // get the history of the changed contacts
    // the condition will be met if there are at least two consecutive states
    const filteredHistoryStateList = historyStateList
      .filter((item, index) => (
        item.id === historyStateList[index - 1]?.id ||
        item.id === historyStateList[index + 1]?.id)
      );

    const [penultimateStateItem] = filteredHistoryStateList.slice(-2, -1);
    const [lastStateItem] = filteredHistoryStateList.slice(-1);

    saveContact(penultimateStateItem, lastStateItem, true);
  };

  return (
    <Form
      onSubmit={saveContact}
      validate={validate}
      initialValues={initialValues}
      mutators={arrayMutators}
      keepDirtyOnReinitialize
      render={({ handleSubmit, form, values, dirtyFields }) => {
        return (
          <form onSubmit={handleSubmit} className="adr-book-contact-form">
            <AdrBookContactFormAutoSave
              isPhotoFile={photo.file}
              callbacksRef={callbacksRef}
              lastStateListItem={lastStateListItem}
              saveContact={() => saveContact(values)}
              isNewContactCreation={isNewContactCreation}
              setEdited={props.setEdited}
            />

            <AdrBookContactFormHeader
              photo={photo}
              active={active}
              values={values}
              setPhoto={setPhoto}
              setValues={form.change}
              avaCropper={avaCropper}
              saveContact={saveContact}
              serverError={serverError}
              dirtyFields={dirtyFields}
              editedContact={editedContact}
              setEditedContact={setEditedContact}
              toggleAvaCropper={toggleAvaCropper}
              isNewContactCreation={isNewContactCreation}
              onChangeFullName={(currValue, isSaveContact) => onChangeFullName(form.change, values, currValue, isSaveContact)}
            />
    
            <div className="adr-book-contact-form__content">
              <div className='adr-book-contact-form__form'>
                <div className={classModifier("adr-book-contact-form__field", "tags")}>
                  <h3 className="adr-book-contact-form__field-title">
                    Tags
                  </h3>
      
                  <ContactTags
                    tagClassName="adr-book-contact-form__contact-tag"
                    callers={editedContact}
                    wrapper={TagsField}
                    wrapperProps={{
                      isAdrBook: true,
                      values,
                      saveContact,
                      setTabIndex,
                      setUpdatedTagsFilters,
                      isNewContactCreation,
                      contactType: getContactType(values),
                    }}
                  />
                </div>

                <div className={classModifier("adr-book-contact-form__field", "via")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "via")}>
                    Contact only via:
                  </h3>

                  <ViaItems 
                    telsValues={values.tels}
                    emailsValues={values.emails}
                  />
                </div>
      
                <div className={classModifier("adr-book-contact-form__field", "phone-numbers")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "phone-numbers")}>
                    Phone number
                  </h3>

                  <PhoneNumbersField
                    values={values}
                    isEditedTelLabel={isEditedTelLabel(values)}
                    saveContact={saveContact}
                    classPrefix="adr-book-contact-form"
                    phoneLabels={phoneLabels}
                    setPhoneLabels={setPhoneLabels}
                    serverError={serverError.tels}
                    setServerError={setServerError}
                    // isNewContactCreation={isNewContactCreation}
                    // isInAdrBook
                    // isCustomDropDown
                  />
                </div>

                <div className={classModifier("adr-book-contact-form__field", "email")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "email")}>Email</h3>

                  <EmailsField
                    classPrefix="adr-book-contact-form"
                    phoneLabels={phoneLabels}
                    serverError={serverError.emails}
                    setServerError={setServerError}
                    isNewContactCreation={isNewContactCreation}
                    isInAdrBook
                  />
                </div>

                <div className={classModifier("adr-book-contact-form__field", "telegram-nickname")}>
                  <h3 className={classModifier("adr-book-contact-form__field-title", "telegram-nickname")}>Telegram</h3>
                  
                  <NicknameTelegramField
                    classPrefix="adr-book-contact-form"
                    serverError={serverError.telegram_nicknames}
                    setServerError={setServerError}
                    isNewContactCreation={isNewContactCreation}
                    saveContact={saveContact}
                    isInAdrBook
                    values={values}
                  />
                  
                </div>

                {+values.type === CONTACT_TYPES.CLIENT && 
                  <>
                    <NotForField
                      callerId={editedContact.id}
                      classPrefix="adr-book-contact-form"
                      isNewContactCreation={isNewContactCreation}
                      notForClients={values.not_for_clients}
                    />

                    <Field
                      isInAdrBook
                      type='checkbox'
                      component={LockForm}
                      editedContact={values}
                      name="is_banned_for_media"
                      classPrefix='adr-book-contact-form-lock'
                    />

                    {!isNewContactCreation && 
                      <div className={classModifier("adr-book-contact-form__field", "uid")}>
                        <h4 className={classModifier("adr-book-contact-form__field-title", "uid")}>Fastmail UID:</h4>

                        {editedContact.uid
                          ? <div className={classModifier("adr-book-contact-form__field-content", "uid")}>
                              {editedContact.uid}

                              <CopyToClipboardButton
                                value={editedContact.uid}
                                disabled={!editedContact.uid}
                                title='Copy note'
                              />
                            </div>
                          : <div className="adr-book-contact-form__field-content"> No UID </div>
                        }
                      </div>
                    }
                  </>
                }
              </div>

              <div className='adr-book-contact-form__info'>
                <AdrBookContactFormTabs
                  values={values}
                  saveContact={saveContact}
                  editedContact={editedContact}
                  serverError={serverError}
                  setServerError={setServerError}
                  feedbackList={feedbackList}
                  setFeedbackList={setFeedbackList}
                  setNewContactAdr={setNewContactAdr}
                  isNewContactCreation={isNewContactCreation}
                  labelsOptions={linksLabels}
                  isFieldEdited={isFieldEdited}
                  isUrlsLabelEdited={isEditedUrlsLabel(values)}
                  tabIndex={tabIndex}
                  setTabIndex={setTabIndex}
                />
              </div>
            </div>
          </form>
        )
      }}
    />
  )
};

const validate = (values) => {
  const { fn, emails, tels, urls, type, telegram_nicknames } = values;

  const errors = {
    tels: [],
    emails: [],
    urls: [],
    addresses: [],
    telegram_nicknames: [],
  };

  if (tels) {
    tels.forEach((currentTel, index) => {
      const tel = currentTel.tel?.trim();

      if (!tel) {
        return errors.tels[index] = { error: 'Enter the phone number' };
      }
      const numbers = /^\d+$/;
      const isCorrect = tel[0] === '+'
        ? numbers.test(tel.slice(1))
        : numbers.test(tel);

      if (!isCorrect && Number(type) !== 6) {
        return errors.tels[index] = { error: 'Incorrect phone number' };
      }
      const countOfCoincidence = tels.filter(t => t.tel?.trim() === tel).length;

      if (countOfCoincidence > 1) {
        return errors.tels[index] = { error: 'Phone number has already exist' };
      }
    })
  }

  if (emails) {
    emails.forEach((currentEmail, index) => {
      const { email } = currentEmail;

      if (!email) {
        return errors.emails[index] = { error: 'Enter the email' };
      }
      const emailRegExp = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]/igm;

      if (!emailRegExp.test(email)) {
        return errors.emails[index] = { error: 'Incorrect email' };
      }
      const countOfCoincidence = emails.filter(e => e.email === email).length;

      if (countOfCoincidence > 1) {
        return errors.emails[index] = { error: 'Email has already exist' };
      }
    })
  }

  if(telegram_nicknames) {
    telegram_nicknames.forEach((currentNickname, index) => {
      const { nickname } = currentNickname;

      if(!nickname) {
        return errors.telegram_nicknames[index] = { error: 'Enter the nickname'}
      }

      const countOfCoincidence = telegram_nicknames.filter(e => e.nickname === nickname).length;

      if (countOfCoincidence > 1) {
        return errors.telegram_nicknames[index] = { error: 'Nickname has already exist' };
      }
    })
  }

  if (!fn) {
    errors.fn = "Name is required";
  }

  if (urls) {
    urls.forEach((currentUrl, index) => {
      const { url } = currentUrl;

      if (getIsFieldEmpty(url)) {
        errors.urls[index] = {
          ...errors.urls[index],
          url: 'Enter the link',
        };
      }
    })
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => ({
  searchSelectContactType: state.addressBook.contactType,
  editedContact: selectContactById(state, ownProps.active),
  defaultAgent: selectAgentContactById(state, ownProps.active),
  divaProfile: state.divaGirls.entities[selectContactById(state, ownProps.active)?.diva_default_id],
});

const mapDispatchToProps = {
  addNewContact,
  updateContact,
  removeAgentData,
  addAgentData,
  updateAgentsGirlIds,
  toggleCreateContactForm,
  addDivaGirlsToEntities,
  updateActiveAdrBookContactId,
  getActiveDivaGirls,
  updateContactsListByUid,
  updateAdrBookTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookContactForm);
