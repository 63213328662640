import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { setUnreadEmailsCount } from 'redux/ducks/mail';

import './MailSidebar.scss';
import Button from 'components/Button/Button';
import UndoActionBlock from 'components/UndoActionBlock/UndoActionBlock';

const SECTIONS = [
  { title: 'Inbox', href: './inbox', Icon: ICONS.mailInbox, counter: 'inbox' },
  { title: 'Archive', href: './archive', Icon: ICONS.archive, counter: null },
  { title: 'Drafts', href: './drafts', Icon: ICONS.file, counter: 'drafts' },
  { title: 'Sent', href: './sent', Icon: ICONS.paperPlane, counter: null },
  { title: 'Junk', href: './spam', Icon: ICONS.archiveJunk, counter: 'spam' },
  { title: 'Trash', href: './trash', Icon: ICONS.trash, counter: null },
];

const MailSidebar = (props) => {
  const {
    delayedActions,
    unreadSpamCount,
    unreadInboxCount,
    setUnreadEmailsCount,
    defaultMailForUser,
  } = props;

  useEffect(() => {
    API.getCountOfUnreadEmails()
      .then(({ data }) => {
        setUnreadEmailsCount(data);
      });
  }, [defaultMailForUser]);

  const activeClassModifier = className => {
    return ({ isActive }) => classModifier(className, isActive && "active");
  };

  return (
    <div className="mail-sidebar">
      <Button
        className="mail-sidebar__compose-link"
        component={NavLink}
        to="./compose"
        icon={<ICONS.plus />}
      >
        Compose
      </Button>

      <ul className="mail-sidebar__link-list">
        {SECTIONS.map(({ title, href, Icon, counter }) => (
          <li key={href}>
            <NavLink
              to={href}
              className={activeClassModifier("mail-sidebar__link")}
            >
              <div className="mail-sidebar__icon-wrap">
                <Icon />
              </div>

              <span className="mail-sidebar__item-text">{title}</span>

              <span className="mail-sidebar__count">
                {counter === "inbox" && !!unreadInboxCount && unreadInboxCount}
                {counter === "spam" && !!unreadSpamCount && unreadSpamCount}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="mail-sidebar__undo-actions-wrap">
        {delayedActions.map((action) => (
          <UndoActionBlock delayedAction={action} key={action.actionId} />
        ))}
      </div>
    </div>
  );
};

const mSTP = state => ({
  unreadInboxCount: state.mail.unreadCount,
  unreadSpamCount: state.mail.unreadSpamCount,
  delayedActions: state.mail.delayedActions,
  defaultMailForUser: state.mail.defaultMailForUser,
});

const mDTP = {
  setUnreadEmailsCount,
}

export default connect(mSTP, mDTP)(MailSidebar);
