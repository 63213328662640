import { webSocket } from 'components/ChatSocket';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const useSetSocketPageStatus = (massageTitle) => {
  const connectToken = useSelector((state) => state.user.connectToken);

   useEffect(() => {
    if (webSocket?.readyState) {
      const sendMessage = (isOpened) => {
        const message = {
          type: 'system_message',
          data: {
            token: connectToken,
            [massageTitle]: isOpened,
          },
        };

        webSocket.send(JSON.stringify(message));
      };
  
      if (webSocket.readyState === WebSocket.OPEN) {
        sendMessage(1);
      }
  
      return () => sendMessage(0);
    }
  }, [connectToken, webSocket?.readyState]);

}

export default useSetSocketPageStatus;
