import React from 'react';

import './CustomScrollbarTrack.scss';

const CustomScrollbarTrack = ({ style, ...props }) => {
  const finalStyle = {
      ...style,
      width: 4,
  };
  return <div className="scrollbars-track" style={finalStyle} {...props} />;
}

export default CustomScrollbarTrack;