import classNames from 'classnames';

import './Spinner.scss';

const Spinner = ({ spinnerSize = 25, isCentered, className }) => (
  <svg 
    className={classNames('spinner', className)} 
    viewBox="0 0 50 50"
    style={{
      width: spinnerSize + 'px',
      height: spinnerSize + 'px',
      margin: isCentered ? '0 auto' : undefined,
    }}
  >
    <circle className="spinner__bg" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    <circle className="spinner__path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
  </svg>    
);

export default Spinner;