import { memo } from 'react';
import classNames from 'classnames';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { useDebounce, useDidUpdate } from 'hooks';

import './SearchInput.scss'
import { INPUT_THEMES } from 'config/constants';

const renderSearchClearBtn = (query, showSearchBtn, showClearBtn, iconSize, setQuery) => {
  if (showSearchBtn && query?.length < 1) {
    return (
      <button className="search-input__btn">
        <ICONS.search className={classModifier('search-input__icon', ['search', iconSize])} />
      </button>
    );
  }

  if (showClearBtn && query?.length >= 1) {
    return (
      <button className="search-input__btn" onClick={() => setQuery('')}>
        <ICONS.close className={classModifier('search-input__icon', 'clear')} />
      </button>
    );
  }
};

const SearchInput = (props) => {
  const {
    query,
    onChange,
    setQuery,
    stopSearch,
    startSearch,
    inputRef,
    isDisabled,
    showSearchBtn,
    showClearBtn,
    maxLength = "20",
    disableDefaultStyles,
    iconSize = 'big',
    inputClassName,
    inputWrapClassName,
    minSearchLength = 1,
    placeHolder = 'Search',
    theme = INPUT_THEMES.primary,
    children = renderSearchClearBtn(query, showSearchBtn, showClearBtn, iconSize, setQuery),
    ...inputProps
  } = props;

  useDidUpdate(() => {
    if (query.length === 0) {
      stopSearch?.('');
    }
  }, [query]);

  const handleSearch = () => {
    if (query.length > minSearchLength) {
      startSearch?.(query);
    }
  };

  const onInputChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    setQuery(e.target.value)
  };

  useDebounce(handleSearch, 700, query);

  const resultClassName = classNames(inputClassName, 'search-input__input');

  return (
    <div className={classNames(inputWrapClassName, 'search-input')}>
      <input
        autoComplete="off"
        className={classModifier(resultClassName, theme)}
        maxLength={maxLength}
        placeholder={placeHolder}
        disabled={isDisabled}
        tabIndex="0"
        type="text"
        value={query}
        ref={inputRef}
        onChange={onInputChange}
        autoFocus={props.autoFocus}
        {...inputProps}
      />

      <div className='search-input__button-container'>
        {typeof children === 'function'
          ? children(renderSearchClearBtn(query, showSearchBtn, showClearBtn, iconSize, setQuery))
          : children}
      </div>
    </div>
  );
};

export default memo(SearchInput);
