import { useState } from 'react';

import { useDebounce, useDidMount } from 'hooks';

import './DebounceInput.scss';
import ICONS from 'assets/icons';

const DebounceInput = ({
  value: publicValue,
  setValue: publicSetValue,
  onChange,
  onDebounce,
  initialQuery,
  timeout = 700,
  searchLength = 2,
  hideSearchIcon,
  hideClearButton,
  placeholder = 'Search',
  ...inputProps
}) => {
  const [privateValue, privateSetValue] = useState('');
  const value = String(publicValue ?? privateValue);
  const setValue = publicSetValue || privateSetValue;

  const [searchValue, setSearchValue] = useState(null);

  useDidMount(() => {
    if(initialQuery) {
      privateSetValue(initialQuery);
    }
  });

  useDebounce(() => {
    const isInRange = !value || value.length >= searchLength;
    const isEqual = value === searchValue;

    if (onDebounce && isInRange && !isEqual) {
      onDebounce(value);
      setSearchValue(value);
    }
  }, timeout, value);

  const handleChange = e => {
    if (onChange) onChange(e);

    setValue(e.target.value);
  }

  return (
    <div className="debounce-input">
      <input
        className="debounce-input__input"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        {...inputProps}
      />

      {!value && !hideSearchIcon &&
        <ICONS.search className="debounce-input__search-icon"/>
      }

      {value && !hideClearButton && (
        <button
          className="debounce-input__clear-button"
          onClick={() => setValue('')}
        >
          <ICONS.close />
        </button>
      )}
    </div>
  );
}

export default DebounceInput;
