import React from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { Scrollbars } from "react-custom-scrollbars-2";



const CustomListWrapper = (props) => (
  <AutoSizer>
    {({ width, height }) => (
      <Scrollbars
        //autoHide
        hideTracksWhenNotNeeded
        renderView={props.renderView}
        renderThumbVertical={props => (
          <div {...props} className="thumb-vertical" />
        )}
        renderTrackVertical={props => (
          <div {...props} className="track-vertical" />
        )}
        style={{ width, height: height - 60 }}
      >
        {props.children}
      </Scrollbars>
    )}
  </AutoSizer>
);

export default CustomListWrapper;