import React from 'react';
import { connect } from 'react-redux';

import API from 'api/api';
import { getContactAvatar, classModifier } from 'utils';
import { selectActiveWebrtcConference, selectContactFromEntities } from 'redux/selectors/selectors';
import { acceptTransferedCall } from 'redux/ducks/calls';
import { WARM_TRANSFER, CONFERENCE } from 'redux/ducks/conferences';
import ConferenceParticipant from './ConferenceParticipant';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { onHoldActiveConference } from 'redux/ducks/webrtc';

import './ConferenceItem.scss';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import CallTimer from '../CallItem/CallTimer';
import Menu from 'components/Menu/Menu';
import ICONS from 'assets/icons';

const ConferenceItem = (props) => {
  const { conference, conferenceCreator, connectedCaller, active, fullMode } = props;

  // const hangUp = (e) => {
  //   e.preventDefault();

  //   return active
  //     ? killConnection(props.connection)
  //     : API.cancelConferenceInvitation(conference.id)
  // };

  // const joinToConference = (e) => {
  //   e.preventDefault();

  //   if (props.activeConference) {
  //     props.onHoldActiveConference(props.activeConference, props.peerConnections, props.localStream);
  //   };

  //   props.acceptTransferedCall(conference);
  // };

  const isOperator = connectedCaller?.type === 9;

  // const renderTimer = () => (
  //   // <div className="conference-item__timers">
  //   props.conferenceTimer &&
  //   <CallTimer
  //     timer={props.conferenceTimer}
  //     className="conference-item__timer"
  //   />

  //   // </div>
  // );

  // const renderConferenceInfo = () => {
  //   if (conference.type === WARM_TRANSFER && connectedCaller) {
  //     return (
  //       <>
  //         <p className="conference-item__meta">Warm Transfer with:
  //           <b>{connectedCaller.short_name || connectedCaller.fn}</b>
  //         </p>

  //         <p className="conference-item__meta">From:
  //           <b>{conferenceCreator ? conferenceCreator.username : "You"}</b>

  //           {props.conferenceTimer && renderTimer()}
  //         </p>
  //       </>
  //     )
  //   }
  //   else if (conference.type === CONFERENCE && fullMode) {
  //     return (
  //       <>
  //         <div className="conference-item__participants">
  //           Conference
  //         </div>
  //         <div className="conference-item__timers">
  //           {props.conferenceTimer && renderTimer()}
  //         </div>
  //       </>
  //     )
  //   }
  //   else if (conference.type === CONFERENCE && active) {
  //     return (
  //       <>
  //         <ul className="conference-item__participants">
  //           {
  //             props.participantsIds.map((participantId) => {
  //               const [id, type] = participantId.split('_');

  //               return (
  //                 <ConferenceParticipant
  //                   conferenceId={conference.id}
  //                   key={participantId}
  //                   isOperator={true}
  //                   smallSize
  //                   isCreator={+id == props.conference.creator_id}
  //                   isCheckable={false}
  //                   participantId={participantId} />
  //               )
  //             })
  //           }
  //         </ul>
  //         <div className="conference-item__timers">
  //           {props.conferenceTimer && renderTimer()}
  //         </div>
  //       </>
  //     )
  //   }
  //   else {
  //     return (
  //       <>
  //         <p className="conference-item__meta">Conference by:
  //           <b>{conferenceCreator ? conferenceCreator.username : "You"}</b>
  //         </p>
  //         <div className="conference-item__timers">
  //           {props.conferenceTimer && renderTimer()}
  //         </div>
  //       </>
  //     )
  //   }
  // };

  const getContactName = () => {
    if (props.missed) {
      return connectedCaller.short_name || connectedCaller.fn || connectedCaller.username;
    }
    if (connectedCaller && (connectedCaller.short_name || connectedCaller.fn || connectedCaller.username)) {
      return connectedCaller.short_name || connectedCaller.fn || connectedCaller.username;
    }
    return "Unknown number";
  };

  return (
    <div className={
      classModifier("conference-item", [
        active && "active",
      ])}>
      <div className="conference-item__main">
        <div
          onClick={() => conference.type === CONFERENCE
            ? props.openModal(MODAL_TYPES.conferenceFullMode, { conferenceId: conference.id })
            : null
          }
          className="conference-item__header">
          <span className="conference-item__count"
          >
            {props.participantsIds.length}
          </span>
          {/* <div className="conference-item__img-wrap">
            <LazyLoadImage src={getContactAvatar(connectedCaller || conference)} alt="ava" className="conference-item__img" />
          </div> */}
        </div>

        <div className="conference-item__info">
          {/* {renderConferenceInfo()} */}
          <div>
            <div className='conference-item__meta'>
            {getContactName()} 
            </div>
            
            <div>
              <span className='conference-item__meta'>{connectedCaller?.tels[0]?.tel}</span>
            </div>
          </div>
          <div className="call-item__timers">
            <CallTimer
              timer={props.conferenceTimer}
            />
            
            {!isOperator &&
            <Menu
              menuItems={[]}
              icon={ICONS.burgerMenu}
              iconSize={14}
            />
            }
          </div>
        </div>

      </div>

      {/* <div className="conference-item__btns">
        {!active &&
          <button
            className="conference-item__btn"
            // disabled={activeCall.status === 'in-progress' && (onHold || onTransfer || inQueue || missed)}
            onClick={joinToConference}>
            <ICONS.phoneSquare width= {20} height= {20} fill= "#00A6AD" />
          </button>
        }

        <button
          onClick={hangUp}
          className="conference-item__btn conference-item__btn--finish">
          <ICONS.phoneSquare width= {20} height= {20} fill= "#F8737F"/>
        </button>
      </div> */}

    </div>
  );
}

const mapStateToProps = (state, { conference }) => ({
  connectedCaller: conference.type === WARM_TRANSFER
    ? selectContactFromEntities(state, conference.caller_id)
    : null,
  conferenceTimer: state.timers['conf_' + conference.id],
  conferenceCreator: state.operators.entities[conference.creator_id],
  participantsIds: state.conferences.participants[conference.id]
    ? state.conferences.participants[conference.id].ids
    : [],
  localStream: state.webrtc.localStream,
  peerConnections: state.webrtc.peerConnections,
  activeConference: selectActiveWebrtcConference(state),
});

const mapDispatchToProps = {
  acceptTransferedCall,
  openModal,
  onHoldActiveConference,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceItem);