import React, { useCallback, useEffect, useState } from 'react';

import { classModifier } from '../../utils';

import './Menu.scss';
import DropWrapper from 'components/DropWrapper';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';

const Menu = props => {
  const {
    menuItems = [],
    icon: Icon,
    iconSize = 16,
    iconProps,
    dropWrapperProps,
    disabled,
    extraButton,
    setExtraMenuState,
  } = props;

  const [referenceElement, setReferenceElement] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const closeDropdownMenu = useCallback(() => setIsMenuActive(false), [])

  useEffect(() => {
    setExtraMenuState?.(isMenuActive);
  }, [isMenuActive])

  return (
    <div className="title__control-box">
      {extraButton}

      <button
        type="button"
        className={classModifier('menu', isMenuActive && 'active')}
        ref={setReferenceElement}
        disabled={disabled}
      >
        <Icon
          className="menu__icon"
          title="menu"
          width={iconSize}
          height={iconSize}
          {...iconProps}
        />
      </button>

      <DropWrapper
        isActive={isMenuActive}
        setIsActive={setIsMenuActive}
        referenceElement={referenceElement}
        {...dropWrapperProps}
      >
        <DropdownMenu
          items={menuItems}
          closeDropdownMenu={closeDropdownMenu}
        />
      </DropWrapper>
    </div>
  );
};

export default Menu;
