import React from 'react';
import classNames from 'classnames';

import { classModifier } from 'utils';

import ICONS from 'assets/icons';
import './BookingServiceFieldItem.scss';

const BookingServiceFieldItem = ({ input, className, price, fieldName, isChecked }) => {
  return (
    <li className={classNames(className, 'services-field-item')}>
      <label 
        className={classModifier(
          'services-field-item__label',
          [!isChecked && 'unchecked', price && 'is-paid-service']
        )}
      >
        {fieldName}
        {price && <span>£{price}</span>}
        {isChecked && <ICONS.check className='services-field-item__check'/>}

        <input 
          className='services-field-item__input'
          {...input}
        />
      </label>
    </li>
  )
};

export default BookingServiceFieldItem;
