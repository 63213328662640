import React, { useEffect, useState } from 'react';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import API from 'api/api';

import "./PhoneLabelSelect.scss";

const PhoneLabelSelect = React.forwardRef(({
  phoneLabels,
  setPhoneLabels,
  closeDropdownMenu,
  getTooltipProps,
  editLabelsModalRef,
}, ref) => {
  const [labels, setLabels] = useState(phoneLabels);
  const [error, setError] = useState(false);
  // const [changedLabels, setChangedLabels] = useState({});

  const lastLabelValue = labels[labels.length - 1].value;
  const isEmptyLabel = labels.some((label) => !label.value);
  
  const onChangeLabel = (value, index, id) => {
    setLabels((prevState) => {
      const newLabels = [...prevState];
      newLabels[index] = { value, label: value, id };

      return newLabels;
    });
  }

  const addEmptyLabel = () => {
    if (lastLabelValue) {
      addActiveLabel();
      setLabels((prevState) => ([
        ...prevState,
        { value: '', label: '' }
      ]))
      setError(false);
    } else {
      setError(true);
    }
  };

  const addActiveLabel = () => {
    setLabels((prevState) => ([
      ...prevState.slice(0, -1),
      {
        value: prevState[prevState.length - 1].value,
        label: prevState[prevState.length - 1].value,
        id: prevState[prevState.length - 1].id
      }
    ]))
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!lastLabelValue || isEmptyLabel) {
      return setError(true);
    }

    const newLabels = labels.filter((label) => !label.id).map((label) => label.value);

    API.createPhoneLabels(newLabels)
      .then(({ data }) => {
        setPhoneLabels((prevState) => ([
          ...prevState,
          ...newLabels.map((label) => ({ label, value: label }))
        ]))
      })

      .catch(console.error);

    closeDropdownMenu();
  }

  const setRefs = (node) => {
    ref(node);

    if (editLabelsModalRef) {
      editLabelsModalRef.current = node;
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      event.stopPropagation();

      if (event.keyCode === 27) {
        closeDropdownMenu();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <form
      className="select-control"
      ref={setRefs}
      onMouseDown={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
      {...getTooltipProps()}
    >
      <header className="select-control__header">
        Edit Number Tags

        <div className="select-control__btn-container">
          <button
            className="select-control__btn select-control__btn--cancel"
            onClick={closeDropdownMenu}
          >
            Cancel
          </button>

          <button
            className="select-control__btn select-control__btn--submit"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </header>

      <div className="select-control__main-container">
        <div className="select-control__option-list">
          {labels.map((item, index) => (
            <input
              key={index}
              className={classModifier('select-control__input', error && 'error')}
              onBlur={addActiveLabel}
              onChange={(event) => onChangeLabel(event.target.value, index, item.id)}
              value={item.label}
              disabled={Boolean(item.id)}
            />
          ))}
        </div>

        <button
          type="button"
          className="select-control__add-option-btn"
          onClick={addEmptyLabel}
        >
          <ICONS.plusCircle
            className="select-control__add-option-btn-icon"
          />
          
          Add number tag
        </button>
      </div>
    </form>
  )
})

export default PhoneLabelSelect;
