import React from 'react';
import { connect } from 'react-redux';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

import { selectContactsRecentTab } from 'redux/selectors/selectors';
import { updateActiveContact } from 'redux/ducks/clientChats';
import { updateRecentTabs } from 'redux/ducks/contacts';

import SvgIcon from 'components/SvgIcon/SvgIcon';

const ChatRestore = (props) => {
  const {
    contactOfRecentTab,
    recentTabs,
  } = props;

  if (!contactOfRecentTab) {
    return null;
  }

  const updateRecentTabs = () => {
    const visible = [...recentTabs.visible];
    const tab = visible[visible.length - 1];
    const all = recentTabs.all.filter(t => t !== tab);

    visible.splice(visible.length - 1, 1);

    props.updateRecentTabs({ all, visible }, props.type)
  }

  const onOpenRecentTab = () => {
    props.updateActiveContact(contactOfRecentTab);
    updateRecentTabs();
  }

  return (
    <button
      className="chat-restore"
      type="button"
      onClick={onOpenRecentTab}
    >
      <SvgIcon // TODO v2
        icon={faUndo}
        type="fa"
        width="16"
        height="16"
      />
    </button>
  )
}

const mapStateToProps = (state, ownProps) => ({
  contactOfRecentTab: selectContactsRecentTab(state, ownProps.recentTabs),
});

const mapDispatchToProps = {
  updateActiveContact,
  updateRecentTabs,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRestore);