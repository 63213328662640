import { DETAILS_ITEMS_NON_HIDDEN } from "../BookingWeekItem/BookingWeekItem";
import { classModifier } from '../../../../utils';

const BookingConfinedItem = (props) => {
  const {
    Markers,
    Date,
    Operator,
    Details,
    Escort,
    Client,
    statusColor,
    restProps,
    innerRef,
    isDeleted,
  } = props;

  return (
    <li
      className={classModifier('booking-week-item', isDeleted && 'deleted')}
      style={{ borderColor: statusColor }}
      ref={innerRef}
      {...restProps}
    >
      <div className="booking-week-item__header">
        <Markers className="booking-week-item__markers"/>
      </div>

      <div className="booking-week-item__info">
        <Date isRanged />
        <Operator />
      </div>

      <div className="booking-week-item__content">
        <Details
          className="booking-week-item__details"
          items={DETAILS_ITEMS_NON_HIDDEN}
          separator=" / "
        />
        <Escort />
        <Client />
      </div>
    </li>
  );
}

export default BookingConfinedItem;
