import React from 'react';


const SelectOption = ({ value, classPrefix, option, ...props }) => {
  const focusedClass = props.isFocused
    ? classPrefix + "__option--focused"
    : "";
  const activeClass = props.isActive
    ? classPrefix + "__option--active"
    : "";

  return (
    <div className={`${classPrefix}__option ${activeClass} ${focusedClass}`}>
      {option && option.label
        ? option.label
        : value
      }
    </div>
  )
}

export default React.memo(SelectOption);