import React, { useState, useRef } from 'react';

import { useDidMount } from '../../hooks';
import { classModifier } from 'utils';

import './VideoThumbnail.scss';
import Spinner from '../UI/Spinner/Spinner';
import ICONS from 'assets/icons';

const VideoThumbnail = ({ hideDuration = false, ...props }) => {
  const {
    src,
    className,
    isRepliedAttachment,
    spinnerSize = '40px'
  } = props;

  const [duration, setDuration] = useState(0);
  const [pending, setPending] = useState(true);

  const videoRef = useRef(null);

  useDidMount(() => {
    const video = videoRef.current;

    if (!video) return;

    video.onloadedmetadata = () => {
      setDuration(video.duration);
      setPending(false);
    }
  })

  const formatDuration = (s) => {
    let m = Math.floor(s / 60);
    s = Math.floor(s % 60);
    s = (s >= 10) ? s : '0' + s;
    return m + ":" + s;
  }

  return (
    <div
      className={className ? `${className} video-thumbnail` : classModifier('video-thumbnail', isRepliedAttachment && 'attachment')}
      onClick={props.onClick}
    >
      {pending &&
        <Spinner spinnerSize={spinnerSize} />
      }

      <video src={src} ref={videoRef} ></video>

      {!pending && <>
        <div className="video-thumbnail__duration">
          {duration > 0 && !hideDuration ? formatDuration(duration) : ''}
        </div>

        <div className="video-thumbnail__icon">
          <ICONS.play />
        </div>
      </>}
    </div>
  )
}

export default VideoThumbnail;
