import React from 'react';

import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { CARD_TABS } from 'config/constants';

const ChatMessageForbidInput = (props) => {
  const {
    activeRecipient,
  } = props;

  return (
    <div className='message-forbid'>
      <div
        className='message-forbid__input'
        onClick={
          () => props.openModal(MODAL_TYPES.contactCard, { contact: activeRecipient.id, defaultTab: CARD_TABS.EDIT })
        }
      >
        <div className="message-forbid__text">
          Communication is NOT possible !<br/>
          Please add a default Communication line
        </div>
      </div>
    </div>
  );
}

export default ChatMessageForbidInput;