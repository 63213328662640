// THIS COMPONENT WAS COPIED FROM V1 PropertyFilters FULLY AS IS
// (WITH SOME SMALL CHANGES) FOR DIVA GIRLS ARE LOADED IN GalleryList
// AND NEEDS REFACTORING AND MARKUP !!!

import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars-2';

import { ADDITIONAL_SESSION_FILTERS } from 'config/constants';
import { usePrevious } from "hooks";
import {
  selectPropertyOfActiveSession,
  selectCanIEditSession,
  selectActiveSessionId,
  selectActiveSession,
  selectHostProfile,
} from 'redux/selectors/selectors';
import {
  updateActiveFilters,
  updateAdditionalTitleFilters,
} from 'redux/ducks/sessions';
import {
  getActiveDivaGirls,
  updateFilterDivaGirlsApiParams,
  getDivaFilters,
} from "redux/ducks/divaGirls";
import { SAVED_SESSIONS_FILTERS, updateUserSalesFilters } from "redux/ducks/user";
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { classModifier } from 'utils';

import './GallerySidebar.scss';
import PropertyFilter from './components/PropertyFilter';
// import BookedFilter from "./BookedFilter";
// import ProposedProfilesList from "./ProposedProfilesList";
// import AvailableNowFilter from "../AvailableNowFilter";
// import ResetDefaultSessionFiltersBtn from "./ResetDefaultSessionFiltersBtn";
import BufferAllButton from './components/BufferAllButton/BufferAllButton';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import AvailableNowFilter from './components/AvailableNowFilter';
import ResetDefaultSessionFiltersBtn from './components/ResetDefaultSessionFiltersBtn';

const EXCLUSIVE_FILTER = SAVED_SESSIONS_FILTERS.exclusive.name;
const NEW_FILTER = SAVED_SESSIONS_FILTERS.new.name;

export const SAVED_SINGLE_FILTERS = [
  SAVED_SESSIONS_FILTERS.recommended.name,
  // SAVED_SESSIONS_FILTERS.similar.name,
  SAVED_SESSIONS_FILTERS.location.name,
  SAVED_SESSIONS_FILTERS.nearby.name,
  SAVED_SESSIONS_FILTERS.price.name,
  SAVED_SESSIONS_FILTERS.offer.name,
  SAVED_SESSIONS_FILTERS['30min'].name,
  SAVED_SESSIONS_FILTERS.mistress.name,
  SAVED_SESSIONS_FILTERS.international.name,
  SAVED_SESSIONS_FILTERS.shemale.name,
  SAVED_SESSIONS_FILTERS.duo.name,
];

const SAVED_GROUP_FILTERS = [
  [SAVED_SESSIONS_FILTERS.incall.name, SAVED_SESSIONS_FILTERS.outcall.name],
  [SAVED_SESSIONS_FILTERS.natural.name, SAVED_SESSIONS_FILTERS.enhanced.name],
  [SAVED_SESSIONS_FILTERS['willingtosee-black'].name, SAVED_SESSIONS_FILTERS['willingtosee-romanian'].name],
  [SAVED_SESSIONS_FILTERS['profile-smoker'].name, SAVED_SESSIONS_FILTERS['profile-non-smoker'].name],
  [SAVED_SESSIONS_FILTERS.tattoo.name, SAVED_SESSIONS_FILTERS.notattoo.name],
  [SAVED_SESSIONS_FILTERS.male.name, SAVED_SESSIONS_FILTERS.female.name]
];

const GallerySidebar = props => {
  const {
    activeFilters = [],
    additionalFilters = {},
    activeSessionId,
    canIEditSession,
    hostProfileId,
    operatorSalesFilters = [],
    filters,
    isActiveSessionExist,
    prevBookedProfiles,
    aSessionClientId,
    profileServiceFilters,
    profileParamsFilters,
    hostProfileIsMistress,
    filterDivaGirlsApiParams,
    cancelPrevRequest,
    newCancelToken,
  } = props;

  const operatorSalesFiltersIds = Object.keys(operatorSalesFilters);
  const bestMatching = activeFilters.includes('bestmatch');

  // for filter All profile we should remove availableNow and bestmatch from activeFilters
  const updatedActiveFilters = activeFilters.filter(key => key !== 'availableNow' && key !== 'bestmatch');
  const isCombinedFilterActive =
    activeFilters.includes('nearby') &&
    activeFilters.includes('bestmatch') &&
    activeFilters.includes('availableNow');

  const prevHostProfile = usePrevious(hostProfileId);
  const prevBestMatching = usePrevious(bestMatching);
  const prevFilters = usePrevious(filters);
  const prevOperatorSalesFilters = usePrevious(operatorSalesFiltersIds);

  useEffect(() => {
    const missingFilters = [];

    if (!!activeFilters) {
      const activeDivaFilters = activeFilters.filter(filter => {
        return !filter.includes('Proposed:') &&
          filter !== 'Booked' &&
          filter !== 'Previously Booked' &&
          filter != 'bestmatch' &&
          filter != 'availableNow'
      });

      activeDivaFilters.forEach(filterKey => {
        if (!operatorSalesFiltersIds.includes(filterKey)) {
          missingFilters.push(filterKey);
        }
      });
    }

    if (missingFilters.length) {
      props.updateUserSalesFilters([...operatorSalesFiltersIds, ...missingFilters]);
    }
  }, [activeFilters, operatorSalesFilters]);

  useEffect(() => {
    const isUpdateFilters = JSON.stringify(filters) !== JSON.stringify(prevFilters);
    const isUpdateOperatorSalesFilters =
      JSON.stringify(operatorSalesFiltersIds) !== JSON.stringify(prevOperatorSalesFilters);

    if (!isUpdateFilters && !isUpdateOperatorSalesFilters && isActiveSessionExist) {

      const comdivaFilters = ['Buffer', 'Booked'];
      const isPreviouslyBooked = activeFilters.includes('Previously Booked');
      const isProposeByOperator = activeFilters[0] && activeFilters[0].includes('Proposed');
      const isNotComdivaFilter = activeFilters.length === 1
        ? !comdivaFilters.includes(activeFilters[0])
        : true;

      if (isNotComdivaFilter && !isProposeByOperator) {
        let sameParams = '', filterBy = '', isFiltered = false;

        activeFilters.forEach(filterKey => {
          if (filterKey === 'availableNow' || filterKey === 'bestmatch') return;
          else if (filters[filterKey] && filters[filterKey].type === 'same-params') {
            sameParams += filterKey + ',';
          }
          else {
            filterBy += filterKey + ',';
          }
        });

        // activate mistress, bdsm and domination filters if mistress profile is added to the compare zone
        if (prevHostProfile === undefined && hostProfileIsMistress) {
          props.updateActiveFilters(
            ['mistress', 'bdsm', 'domination'],
            activeSessionId,
            activeFilters,
            canIEditSession,
          );
        }

        const params = {
          'same-params': sameParams,
          'filterby': filterBy,
          'host-profile': hostProfileId,
          'sortby': additionalFilters.sortby ? additionalFilters.sortby : '',
          'includeoff': !additionalFilters.includeoff ? 1 : 0,
          'include-banned': !additionalFilters['include-banned'] ? 1 : 0,
          'on-holidays': additionalFilters['on-holidays'] ? 1 : 0,
          'filter-is_available_now': activeFilters.includes('availableNow') ? 1 : '',
          'isPreviouslyBooked': isPreviouslyBooked ? 1 : 0,
          'callerId': isPreviouslyBooked ? aSessionClientId : '',
          'search': additionalFilters.search ? additionalFilters.search : '',
        };

        const getIsExistDisabledFilter = () => { // Disabled filter exist in activeFilters
          let i = activeFilters.length;

          while (i--) {
            const filterKey = activeFilters[i];

            if (filters[filterKey] && filters[filterKey].type === 'same-params') {
              return true;
            }
          }

          return false;
        };

        if (Object.keys(additionalFilters).length) {
          Object.keys(additionalFilters).forEach(key => {
            params[key] = additionalFilters[key];
            isFiltered = true;
          })
        }

        if (!hostProfileId && getIsExistDisabledFilter()) {
          props.updateActiveFilters('All Profiles', activeSessionId, updatedActiveFilters, canIEditSession);
        }
        if (bestMatching) {
          params['same-params'] += 'bestmatch';
        }

        // clear search when change hostProfileId and bestMatching
        if (prevHostProfile === undefined && !!hostProfileId && !!params['search']) return;
        if ((prevBestMatching !== bestMatching) && !!params['search']) return;

        if (JSON.stringify(filterDivaGirlsApiParams) !== JSON.stringify(params)) {
          props.updateFilterDivaGirlsApiParams(params);
        }

        if (updatedActiveFilters.length) {
          cancelPrevRequest();

          props.getActiveDivaGirls({
            params: { ...params, cancelToken: newCancelToken() },
            additionalFilters: additionalFilters,
            isGallery: true,
          });
        }
      }
      else { // Comdiva Filters 
        // (Booked or Buffer)
        props.updateFilterDivaGirlsApiParams(null);
      }
    }
  }, [
    activeFilters,
    additionalFilters,
    operatorSalesFilters,
    filters,
    hostProfileId,
    isActiveSessionExist,
    bestMatching,
    prevBookedProfiles,
    // Next dependencies cause unnecessary renders
    // prevFilters,
    // prevOperatorSalesFilters,
  ]);

  useEffect(() => {
    const params = {
      limit: 999,
      'key': operatorSalesFiltersIds.toString()
    };

    props.getDivaFilters(params);
  }, [operatorSalesFilters]);

  const checkOnActiveForFilter = filter => {
    if (!activeFilters) { return false; }

    const hasInActiveFilters = activeFilters.includes(filter);

    const isAllProfiles = updatedActiveFilters.length === 0 && filter === 'All Profiles';

    if (filter === 'nearby') {
      return hasInActiveFilters && !isCombinedFilterActive;
    }

    return hasInActiveFilters || isAllProfiles;
  };

  const checkOnDisabledForFilter = filter => {
    const isSameFilter = filters[filter] && filters[filter].type === 'same-params';

    return !hostProfileId && isSameFilter;
  };

  const getPropsForPropertyFilter = filter => ({
    filter,
    activeFilters,
    activeSession: activeSessionId,
    canIEditSession,
    isActive: checkOnActiveForFilter(filter),
    isDisabled: checkOnDisabledForFilter(filter),
    updateActiveFilters: props.updateActiveFilters,
  });

  return (
    <div className="gallery-sidebar">
      <div className="gallery-sidebar__actions">
        <BufferAllButton />
      </div>

      <button
        className="gallery-sidebar__filters-action-btn"
        onClick={() => props.openModal(MODAL_TYPES.salesFiltersEditor)}
      >
        Edit Master Filters
      </button>

      <ResetDefaultSessionFiltersBtn
        activeFilters={activeFilters}
        additionalFilters={additionalFilters}
      />

      <div className="gallery-sidebar__filters">
        <Scrollbars 
          autoHide 
          renderThumbVertical={CustomScrollbarThumb}
          renderTrackVertical={CustomScrollbarTrack}
        >
          <ul className="gallery-sidebar-filters">
            <PropertyFilter
              {...getPropsForPropertyFilter('All Profiles')}
            />

            <AvailableNowFilter
              activeFilters={activeFilters}
              activeSession={activeSessionId}
              canIEditSession={canIEditSession}
              updateActiveFilters={props.updateActiveFilters}
              isActive={activeFilters.includes('availableNow') && !isCombinedFilterActive}
            />

            <PropertyFilter
              {...getPropsForPropertyFilter(EXCLUSIVE_FILTER)}
            />

            <PropertyFilter
              {...getPropsForPropertyFilter(NEW_FILTER)}
            />

            {/* TODO after adding sessions */}
            {/* {canIEditSession &&
              <ProposedProfilesList
                activeFilters={activeFilters}
                activeSession={activeSessionId}
                canIEditSession={canIEditSession}
                updateActiveFilters={props.updateActiveFilters}
              />
            } */}

            {prevBookedProfiles &&
              <PropertyFilter
                {...getPropsForPropertyFilter('Previously Booked')}
              />
            }

            {/*  Combined Filter (Similar, Available, NearBy) */}
            {hostProfileId && (
              <div
                className={classModifier('gallery-sidebar-filters__item', [
                  'combined',
                  isCombinedFilterActive && 'active',
                ])}
                onClick={() => {
                  props.updateActiveFilters(
                    ['nearby', 'availableNow', 'bestmatch'],
                    activeSessionId,
                    activeFilters,
                    canIEditSession
                  );

                  props.updateAdditionalTitleFilters(
                    ADDITIONAL_SESSION_FILTERS.search,
                    '',
                    activeSessionId,
                    additionalFilters,
                    canIEditSession,
                    true,
                  );
                }}
              >
                Similar, Available, NearBy
              </div>
            )}

            {/* Saved single sales filters */}
            {SAVED_SINGLE_FILTERS.map(filter => (
              <PropertyFilter
                key={filter}
                {...getPropsForPropertyFilter(filter)}
              />
            ))}

            {/* Best matching */}
            {hostProfileId && (
              <div
                className={classModifier('gallery-sidebar-filters__item', [
                  bestMatching && !isCombinedFilterActive && 'active',
                ])}
                onClick={() => {
                  props.updateActiveFilters('bestmatch', activeSessionId, activeFilters, canIEditSession)
                  props.updateAdditionalTitleFilters(
                    ADDITIONAL_SESSION_FILTERS.search,
                    '',
                    activeSessionId,
                    additionalFilters,
                    canIEditSession,
                    true,
                  );
                }}
              >
                Best Matching
              </div>
            )}

            {/* Saved group sales filters */}
            {SAVED_GROUP_FILTERS.map(filter => (
              <div className="gallery-sidebar-filters__group" key={`${filter[0]}_${filter[1]}`}>
                <PropertyFilter
                  title
                  {...getPropsForPropertyFilter(filter[0])}
                />

                <PropertyFilter
                  title
                  {...getPropsForPropertyFilter(filter[1])}
                />
              </div>
            ))}

            {/* Sales Services Filters */}
            {profileServiceFilters.map(filter => (
              !Object.keys(SAVED_SESSIONS_FILTERS).includes(filter) &&
                <PropertyFilter
                  key={filter}
                  {...getPropsForPropertyFilter(filter)}
                />
            ))}

            {/* Sales Params Filters */}
            {profileParamsFilters.map(filter => (
              !Object.keys(SAVED_SESSIONS_FILTERS).includes(filter) &&
                <PropertyFilter
                  key={filter}
                  {...getPropsForPropertyFilter(filter)}
                />
            ))}
          </ul>
        </Scrollbars>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  filters: state.divaGirls.filters,
  activeFilters: (!!state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'activeFilters')),
  additionalFilters: (!!state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'additionalFilters')),
  activeSessionId: selectActiveSessionId(state),
  prevBookedProfiles: state.sessions.entities[state.sessions.activeSession] && selectPropertyOfActiveSession(state, 'prevBookedProfiles'),
  isActiveSessionExist: !!state.sessions.entities[state.sessions.activeSession],
  canIEditSession: selectCanIEditSession(state),
  hostProfileId: selectPropertyOfActiveSession(state, 'comparedIds')[0],
  operatorSalesFilters: state.user.salesFilters,
  profileServiceFilters: state.user.salesServicesIds,
  profileParamsFilters: state.user.salesParamsIds,
  hostProfileIsMistress: selectHostProfile(state)?.is_mistress,
  filterDivaGirlsApiParams: state.divaGirls.filterDivaGirlsApiParams,
});

const mapDispatchToProps = {
  updateActiveFilters,
  updateAdditionalTitleFilters,
  getActiveDivaGirls,
  updateFilterDivaGirlsApiParams,
  getDivaFilters,
  updateUserSalesFilters,
  openModal,
};

const MemoGallerySidebar = memo(GallerySidebar, (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoGallerySidebar);
