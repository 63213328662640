import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { classModifier } from 'utils';

import "./BookingModalLinedItem.scss";

const DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const BookingModalLinedItem = (props) => {
  const {
    Escort,
    Badges,
    Details,
    Client,
    Status,
    ConfirmationStatus,
    Operator,
    Markers,
    Requirements,
    statusColor,
    innerRef,
    isDeleted,
    handleOpenBookingModal
  } = props;

  return (
    <div
      className={classModifier('booking-modal-lined-item', isDeleted && 'deleted')}
      onClick={handleOpenBookingModal}
      ref={innerRef}
    >
      <div
        className="booking-modal-lined-item__color-line"
        style={{ backgroundColor: statusColor }}
      />

      <div className={classModifier("booking-modal-lined-item__column", 'escort')}>
        <Escort namesAlignment="vertical" />
      </div>

      <div className={classModifier("booking-modal-lined-item__column", 'duo')}>
        <Badges />
      </div>

      <div className={classModifier("booking-modal-lined-item__column", 'details')}>
        <Details items={DETAILS_ITEMS} />
      </div>

      <div className={classModifier("booking-modal-lined-item__column", 'requirements')}>
        <Requirements className="booking-modal-lined-item__requirements" />
      </div>

      <div className={classModifier("booking-modal-lined-item__column", 'status')}>
        <Status />
        <Client className="booking-modal-lined-item__client" />
      </div>

      <div className={classModifier("booking-modal-lined-item__column", 'rest')}>
        <div className="booking-modal-lined-item__column-row">
          <Markers />
          <ConfirmationStatus />
        </div>
        <Operator />
      </div>
    </div>
  )
}

export default BookingModalLinedItem;
