import { useCallback, useMemo, useReducer, useRef } from 'react';

export const useEnchancedReducer = (
  reducer,
  initialState,
) => {
  const lastState = useRef(initialState);
  const [state, dispatch] = useReducer(
    (state, action) => lastState.current = reducer(state, action),
    initialState
  );

  const getState = useCallback(() => lastState.current, []);

  const customDispatch = (action) => {
    if (action instanceof Function) {
      return action(getState, customDispatch);
    } else {
      return dispatch(action);
    }
  }

  return [
    state,
    customDispatch,
  ];
}
