import { BOOKING_STATUSES, BOOKING_STATUSES_NAMES } from 'config/constants'

export default (props) => {
  return {
    offset: props.offset,
    limit: props.limit || 30,
    date: props.date ? props.date : null,
    dateFrom: props.dateFrom ? props.dateFrom : null,
    dateTo: props.dateTo ? props.dateTo : null,
    agentId: !!props.agentId ? props.agentId : null,
    girlId: !!props.girlId ? props.girlId : null,
    clientId: !!props.clientId ? props.clientId : null,
    operatorId: !!props.operatorId ? props.operatorId : null,
    confirmationStatus: props.confirmationStatus,
    type: !!props.type ? props.type : null,
    meeting_type: !!props.meeting_type ? props.meeting_type : null,
    is_prebooking: props.prebooking ? 1 : null,
    isAgent: props.agent ? 1 : null,
    status: props.finished ? BOOKING_STATUSES[BOOKING_STATUSES_NAMES.FINISHED].id : null,
    query: props.searchQuery || null,
    orderBy: props.sortOption ? props.sortOption : null,
    sort: props.sortOptionType ? props.sortOptionType : null,
    notes: props.notes ? 1 : null,
    requests: props.requests ? 1 : null,
  }
}
