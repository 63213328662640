import React from 'react';

import ICONS from 'assets/icons';

const NotForFieldItem = ({ removeItem, ignoreName }) => {

  return (
    <div className="adr-book-contact-form__not-for-item">
      {ignoreName}
      <button
        type="button"
        onClick={removeItem}
        className="adr-book-contact-form__not-for-item-btn"
      >
        <ICONS.close className="adr-book-contact-form__not-for-item-icon"/>
      </button>
    </div>
  )
}

export default NotForFieldItem;