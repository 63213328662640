import React from 'react';
import { components } from 'react-select';

const ViaItemOption = ({ children, ...props }) => {
  const { label, valueLabel } = children;
  return (
    <components.Option {...props}>
      {valueLabel && (
        <span className="contact-form-via-item__option-label">
          {valueLabel}
        </span>
      )}

      {label}
    </components.Option>
  )
}

export default ViaItemOption;