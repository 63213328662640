export const SET_MERGE_CONTACTS_COUNT = 'SET_MERGE_CONTACTS_COUNT';
export const SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT = 'SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT';
export const REDUCE_COUNTER = 'REDUCE_COUNTER';

export const setMergeContactsCount = (count) => ({
  type: SET_MERGE_CONTACTS_COUNT,
  payload: count,
})

export const setNightBotUnviewedSessionsCount = (count) => ({
  type: SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT,
  payload: count,
})

export const reduceCounter = () => ({ type: REDUCE_COUNTER });

const initialState = {
  mergeContacts: 0,
  nightBotUnviewedSessions: 0
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

    case SET_MERGE_CONTACTS_COUNT:
      const updatedMergeContacts = payload < 0 ? 0 : payload;

      return { 
        ...state, 
        mergeContacts: updatedMergeContacts
      }

    case SET_NIGHT_BOT_UNVIEWED_SESSIONS_COUNT:
      return { 
        ...state, 
        nightBotUnviewedSessions: payload
      }
      
    case REDUCE_COUNTER:
      return {
        ...state,
        mergeContacts: --state.mergeContacts,
      }

  default:
    return state
  }
}
