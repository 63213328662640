import React from 'react';
import { useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';

import BufferContactItem from './BufferContactItem';


const BufferSearch = () => {
  const activeGirlChatId = useSelector(state => state.girlChats.active);
  const bufferTabContacts = useSelector(state => state.girlChats.bufferTabContacts);

  return (
    <div className='buffer-search'>
      {/* <input 
        type='text' 
        className='buffer-search__input'
        placeholder='search'
      /> */}

      <div className='buffer-search__contacts-list'>
        {bufferTabContacts.map((contact, idx) => {
          const { id } = contact;
          const isActive = id === activeGirlChatId;

          return (
            <div key={id} className='buffer-search__contacts-item'>
              <BufferContactItem 
                id={id} 
                idx={idx} 
                contact={contact} 
                isActive={isActive} 
                bufferTabContacts={bufferTabContacts} 
              />
              <ICONS.buffer className={classModifier('buffer-search__buffer-icon', isActive && 'active')} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BufferSearch;