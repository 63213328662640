import { useEffect } from 'react';

const useClickAway = (ref, callback) => {
  useEffect(() => {
    const handler = (event) => {
      if (ref.current) {
        if (!ref.current.contains(event.target)) {
          if (callback) {
            callback(event);
          }
        }
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [ref]);
}

export default useClickAway;
