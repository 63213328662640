import Icon from 'components/Icon/Icon';
import './SeeNotesPopup.scss'
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';

const SeeNotesPopup = ({activeRecipient}) => {
    const note = activeRecipient?.note;

    return (
    <div className='see-notes-popup'>
        <div className='see-notes-popup__main-container'>
            <div className='see-notes-popup__header'>
                <h4 className="see-notes-popup__title">
                    {/* <Icon.notes /> */}
                    Notes
                </h4>

                <CopyToClipboardButton
                    className='see-notes-popup__copy'
                    value={note}
                    disabled={!note}
                    title='Copy note'
                />
            </div>
            <div className="see-notes-popup__notes"> 

                {note
                    ? <>
                        <div className="see-notes-popup__value see-notes-popup__value--prewrap">
                            {note}
                        </div>
                    </>
                    : <div className='see-notes-popup__value'>No comments</div>
                }

            </div>
        </div>
    </div>)
}

export default SeeNotesPopup;