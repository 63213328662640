import { useSelector } from 'react-redux';
import { memo, useEffect } from 'react';

import { classModifier } from 'utils';
import { MULTI_MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';

import './MailingHistoryItem.scss';
import DateTime from 'components/DateTime';
import MessageHeader from '../MessageHeader/MessageHeader';

const MailingHistoryItem = ({ item, formRef, actualUsersCount, statusOk }) => {
  const forwardedMessage = useSelector((state) => state.mailing.forwardedMessage);

  const isDelivered = Object.values(item.statuses).some((status) => status === 'delivered');
  const isForwarded = item.id === forwardedMessage;

  useEffect(() => {
    if (isForwarded) {
      formRef.change('message', item.message);
    }
  }, [isForwarded])

  return (
    <li
      className="mailing-log__item"
      key={item.id}
    >
      <DateTime
        className={classModifier("mailing-log__time", isDelivered && 'delivered')}
        date={item.date}
        config={MULTI_MESSAGE_DATE_CONFIG}
        hoursToAbsolute={1}
      />

      <MessageHeader
        message={item}
        formRef={formRef}
        actualUsersCount={actualUsersCount}
        statusOk={statusOk}
      />

      <div className={classModifier("mailing-log__message", [!item.id && 'loading', isForwarded && 'forwarded'])}>
        {item.message}
      </div>
    </li>
  )
}

export default memo(MailingHistoryItem);
