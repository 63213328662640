import './CalendarSplit.scss';
import SplitBookingsCalendar from './SplitBookingsCalendar';
import CalendarBookingsList from '../CalendarBookingsList/CalendarBookingsList';
import {
  BookingFilters,
  BookingFiltersList,
  BookingSelectFilters,
  BookingStatusFilters
} from 'components/FilteredBookings';
import { BOOKING_ITEM_VIEWS } from 'components/BookingItemNew';

const CalendarSplitHeader = ({ getters }) => {
  const {
    getFiltersContainerProps,
    getStatusFilterProps,
    getFilterProps,
    getSelectProps,
  } = getters;

  return (
    <BookingFiltersList
      className="calendar-split__header"
      {...getFiltersContainerProps()}
    >
      <BookingStatusFilters {...getStatusFilterProps()}>
        <BookingStatusFilters.todo />
        <BookingStatusFilters.confirmed />
        <BookingStatusFilters.done />
        <BookingStatusFilters.cancelled />
        <BookingStatusFilters.tosort />
      </BookingStatusFilters>

      <BookingFilters
        className="calendar-split__filters"
        {...getFilterProps()}
      >
        <BookingFilters.prebooking />
        <BookingFilters.outcall />
        <BookingFilters.duo />
        <BookingFilters.agent />
      </BookingFilters>

      <BookingSelectFilters.agent
        className="calendar-split__select-filter"
        {...getSelectProps()}
      />
    </BookingFiltersList>
  )
}

const CalendarSplit = (props) => {
  const {
    getters,
    isCurrentDate,
    date,
  } = props;

  const { getDateTimeChangerProps } = getters;

  return (
    <div className="calendar-split">
      <SplitBookingsCalendar {...getDateTimeChangerProps()} />

      <div className="calendar-split__timeline">
        <CalendarSplitHeader getters={getters} />

        <CalendarBookingsList
          classNameModifier='calendar-split'
          isCurrentDate={isCurrentDate}
          getters={getters}
          date={date}
          bookingType={BOOKING_ITEM_VIEWS.LINED}
          showTimeFilters={true}
        />
      </div>
    </div>
  );
};

export default CalendarSplit;
