import moment from 'moment';

export default (date, boundaryDays) => {
  if (boundaryDays > 1) {
    const startDate = Array.isArray(date) ? date[0] : date;
    const boundaryDate = moment(startDate).add(boundaryDays - 1, 'days').valueOf();

    return [startDate, boundaryDate];
  } else {
    return date;
  }
}
