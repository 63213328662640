import React from 'react';

import { classModifier } from 'utils';
import { BOOKING_CALENDAR_DATE_CONFIG, BOOKING_CALENDAR_LABEL_DATE_CONFIG } from 'config/dates-сonfig';

import './CalendarBookingsList.scss';
import CustomScrollbarThumb from 'components/UI/CustomScrollbarThumb/CustomScrollbarThumb';
import CustomScrollbarTrack from 'components/UI/CustomScrollbarTrack/CustomScrollbarTrack';
import DateTime from 'components/DateTime';
import { BookingTimeFilters } from 'components/FilteredBookings';
import { BookingItemNew } from 'components/BookingItemNew';
import ListContainer from 'components/List/ListContainer';
import List from 'components/List/List';

const scrollbarProps = {
  renderThumbVertical: CustomScrollbarThumb,
  renderTrackVertical: CustomScrollbarTrack
}

const CalendarBookingsList = (props) => {
  const {
    classNameModifier,
    isCurrentDate,
    hideMarkers,
    getters,
    pickedDate,
    date,
    bookingType,
    isTwoDaysPage,
    showTimeFilters,
  } = props;
  const { getBookingListProps, getBookingProps, getTimeFiltersProps } = getters;

  const isRangedDate = date?.[0]?.length === 2;

  const BookingTimelineItem = (props) => {
    const item = props.item || props;
    const hourmarker = new Date(item.date).getHours();

    return (
      <div
        key={item.id}
        className={classModifier("calendar-bookings-list__item", !hideMarkers && 'displayed-with-markers')}
        data-hourmarker={hourmarker}
        style={props.style}
      >
        <DateTime
          className="calendar-bookings-list__hour-marker"
          date={item.date}
          config={BOOKING_CALENDAR_LABEL_DATE_CONFIG}
          hidden={hideMarkers}
        />

        <BookingItemNew
          key={item.id}
          item={item}
          type={bookingType}
          {...getBookingProps(pickedDate)}
        />
      </div>
    )
  }

  const BookingGroupItem = ({ item: bookingGroupsByDate }) => {
    const [bookingGroupDate, bookingGroup] = bookingGroupsByDate;

    return (
      <div key={bookingGroupDate} className='calendar-bookings-list__group'>
        <DateTime
          className="calendar-bookings-list__date-header"
          date={new Date(Number(bookingGroupDate))}
          config={BOOKING_CALENDAR_DATE_CONFIG}
          ignoreTimezone
        />

        {bookingGroup.map(BookingTimelineItem)}
      </div>
    )
  }

  return (
    <div className={classModifier("calendar-bookings-list", classNameModifier)}>
      {isCurrentDate && showTimeFilters &&
        <BookingTimeFilters
          className="calendar-bookings-list__time-filter"
          {...getTimeFiltersProps()}
        >
          <BookingTimeFilters.nineAm />
          <BookingTimeFilters.current />
          <BookingTimeFilters.oneAm />
        </BookingTimeFilters>
      }

      {!isRangedDate && !isTwoDaysPage && (
        <DateTime
          className="calendar-bookings-list__date-header"
          date={date[0]}
          config={BOOKING_CALENDAR_DATE_CONFIG}
          ignoreTimezone
        />
      )}

      <ListContainer
        classPrefix='calendar-bookings-list'
        scrollInitialPosition='top'
        // scrollbarProps={scrollbarProps}
        // itemSize={41}
        autoHide
        // virtualized={!isRangedDate}
        {...getBookingListProps({ ...pickedDate, groupByDay: isRangedDate })}
      >
        {isRangedDate ? <BookingGroupItem /> : <BookingTimelineItem />}
      </ListContainer>
    </div>
  );
};

export default CalendarBookingsList;
