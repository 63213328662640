import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { classModifier } from 'utils';
import { selectCanIEditSession, selectChatFromEntities, selectChatTabUnreadCount } from 'redux/selectors/selectors';
import { removeBufferedId } from 'redux/ducks/sessions';

import ICONS from 'assets/icons';
import SvgIcon from "components/SvgIcon/SvgIcon";
import { CHAT_TYPES, AUDIO_SETTINGS_TYPES } from 'config/constants';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { PUBLIC_CHAT_TABS } from 'redux/ducks/roomChats';
import API from '../../../../api/api';

class ChatTab extends Component {
  state = {};

  shouldComponentUpdate(nextProps, nextState) {    //TODO: so strange part of component....PureComponent coverage majority if-statement....THIS PART BREAK animation features wtf
    if (this.props.contact?.id === nextProps.contact?.id &&
      this.props.activeRecipient?.id !== this.props.contact?.id &&
      nextProps.unreadCount !== this.props.unreadCount) {
      return true;
    }
    // rerender only active contact and previous
    if (this.props.contact?.id === nextProps.activeRecipient?.id || this.props.activeRecipient?.id === this.props.contact?.id) {
      return true;
    }
    // rerender contact with incoming interaction
    if (nextProps.unreadCount !== this.props?.unreadCount) {
      return true;
    }
    // rerender contact if name has changed
    if (nextProps.contact?.fn !== this.props.contact?.fn) {
      return true;
    }
    if (nextProps.style !== this.props.style) {
      return true;
    }
    if (nextProps.index !== this.props.index) {
      return true;
    }
    return false;
  }
  
  handleTabClick = (e) => {
    const { type, contact, activeRecipient = {}, isProfilesBufferModal } = this.props;

    if (e.target.className === "chat__remove-img") {
      if(isProfilesBufferModal) {
        this.props.removeBufferedId(contact?.diva_default_id, this.props.activeSession, this.props.canIEditSession);
        this.props.removeContactTab(contact?.id, type);
      } else {
        this.props.removeContactTab(contact?.id, type);
        if (contact.type === 1) {
          API.switchSalesSessionByCaller(0)
            .catch(err => console.error(err));
        }
      }
      return;
    }
    if (contact?.id === activeRecipient.id && !this.props.isMoreTab) {
      return;
    }
    //TODO: pass id and type to this func
    type === CHAT_TYPES.ROOM
      ? this.props.updateActiveContact(contact?.id)
      : this.props.updateActiveContact(contact)
  }

  onContextMenu = (e) => {
    e.preventDefault();

    const { clientX, clientY } = e;

    this.props.openContextMenu([clientX, clientY], this.props.contact?.id, this.props.contact?.type);
  };

  isFixed = () => {
    if (!this.props.withUnfixedTab) return true;

    return this.props.unfixedTab !== this.props.contact?.id;
  };

    handleDoubleClick = () => {
    if (!this.props.withUnfixedTab) return;

    return this.props.fixTab(this.props.contact?.id);
  };

  render() {

    const { activeRecipient, contact, unreadCount } = this.props;

    const isActive = this.props.tab === 'New Chat' || contact && activeRecipient && activeRecipient.id === contact.id;

    const getChatTabTitle = () => {
      if (contact?.chatType === PUBLIC_CHAT_TABS.rooms.id) {
        return <ICONS.users width="20px" height="20px"/>;
      }
      return (
        contact?.short_name ||
        contact?.fn ||
        (contact?.id === PUBLIC_CHAT_TABS.available.id ||
        contact?.id === PUBLIC_CHAT_TABS['off_today'].id ||
        contact?.id === PUBLIC_CHAT_TABS.finished.id || 
        contact?.id === PUBLIC_CHAT_TABS.started.id ? (
          <ICONS.circle
            className={classModifier("chat__icon-circle", contact?.id)}
          />
        ) : (
          contact?.chatTitle
        ))
      );
    }

    const renderTime = () => {
      if (!contact?.lastInteraction) return;

      const thisDate = new Date(contact?.lastInteraction);

      return thisDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    const isMuted = contact?.audio_status === AUDIO_SETTINGS_TYPES.MUTE;

    const renderMoreTab = () => {
      return (
        <li
          data-isfixed={this.isFixed()}
          onDoubleClick={this.handleDoubleClick}
          className={`
          chat__more-contact-item
            ${unreadCount > 0
              ? 'chat__more-contact-item--not-read'
              : ''
            }
            ${isActive ? "chat__contact-item--selected" : ""}
          `}
          onClick={this.handleTabClick}
        >
          {this.props.removeContactTab && this.props.type !== CHAT_TYPES.ROOM &&
            <span className="chat__remove-img">&#10005;</span>
          }

          <div className='chat__contact-name'>
            {isMuted &&
              <ICONS.mute className='chat__contact-item-mute-icon' />
            }

            {getChatTabTitle()}
          </div>

          <time>
            {renderTime()}
          </time>

          {unreadCount !== 0 &&
            <div className='chat__more-contact-not-read-msg'>
              <span>
                {unreadCount}
              </span>

              <SvgIcon icon="messages" fill="#808080" width='12' height='12' />
            </div>
          }
        </li>
      )
    };

    const renderTab = () => {
      const isDraggableTab = this.props.type !== CHAT_TYPES.ROOM;
      // const isNotTabFromMoreTabs = this.props.index !== -1;
      const isTabFromMoreTabs = this.props.index === -1;

      const getDragAndDropProps = (provided) => isDraggableTab
        ? {
          ...provided.draggableProps,
          ...provided.dragHandleProps,
        }
        : {};

      const getTab = (dragAndDropProps = {}) => (
        <li
          className={classModifier("chat__contact-item", [
            isActive && 'selected',
            (!isActive && this.props.tab === PUBLIC_CHAT_TABS.notifications.id) && 'notifications',
            contact?.chatType === PUBLIC_CHAT_TABS.rooms.id && isMuted && 'small-muted',
            Object.values(PUBLIC_CHAT_TABS).map(item => item.id).includes(contact?.chatType) && 'small'
          ])}
          data-isfixed={this.isFixed()}
          onMouseUp={this.handleTabClick}
          onDoubleClick={this.handleDoubleClick}
          data-chat-id={contact?.id || 'New Chat'}
          onContextMenu={this.props.type === CHAT_TYPES.ROOM ? null : this.onContextMenu}
          title={PUBLIC_CHAT_TABS[contact?.id]?.title}
          style={this.props.style}
          {...dragAndDropProps}
        >
          {isMuted &&
            <ICONS.mute className='chat__contact-item-mute-icon' />
          }

          <div className="chat__contact-name">
            {contact?.id === 'new_chat'
              ? 'New'
              : getChatTabTitle()
            }
          </div>

          {!!unreadCount && 
            <span className="chat__unread-indicator">{unreadCount}</span>
          }

          {this.props.removeContactTab && !Object.values(PUBLIC_CHAT_TABS).map(item => item.id).includes(contact?.chatType) &&
            <span className="chat__remove-img">&#10005;</span>
          }
        </li>
      );

      return !this.props.isTabsDraggable || isTabFromMoreTabs
        ? getTab()
        : (
          <Draggable
            draggableId={String(this.props.tab)}
            index={this.props.index}
            isDragDisabled={!isDraggableTab}
          >
            {(provided) => (
              getTab({
                ...getDragAndDropProps(provided),
                ref: provided.innerRef,
              })
            )}
          </Draggable>
        )
    };

    return (
      this.props.isMoreTab
        ? renderMoreTab()
        : renderTab()
    );
  };
};

const mapStateToProps = (state, ownProps) => ({
  contact: selectChatFromEntities(state, ownProps.tab, ownProps.type),
  unreadCount: selectChatTabUnreadCount(state, ownProps.tab, ownProps.type),
  // unfixedTab: state.clientChats.unfixedTab,
  // girlUnfixedTab: state.girlChats.unfixedTab,
  // roomUnfixedTab: state.roomChats.unfixedTab,
  isProfilesBufferModal: state.activeWindows.activeModals.some(modal => modal.type === MODAL_TYPES.profilesBuffer),
  activeSession: state.sessions.activeSession,
  canIEditSession: selectCanIEditSession(state),
});

const mapDispatchToProps = {
  removeBufferedId,
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatTab);
