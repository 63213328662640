import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';
import { BOOKING_STATUSES } from 'redux/ducks/sessions';
import { BOOKING_STATUS_FILTERS } from 'config/constants';
import { BOOKING_ITEM_DATE_CONFIG } from 'config/dates-сonfig';
import {
  classModifier,
  getContactAvatar,
} from 'utils';
import { 
  selectLockedOperator, 
 } from 'redux/selectors/selectors';
import { getContactsById } from 'redux/ducks/contacts';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './BookingItem.scss';
import Portal from 'components/Portal';
import DateTime from 'components/DateTime';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import BookingConfirmationStatus from 'components/BookingConfirmationStatus/BookingComfirmationStatus';

const BookingEscortScheduleItem = (props) => {
  const {
    item: booking,
    hourMarker,
    dateMarker,
    isTwoDaysPage,
    isScrollToDate,
    classNameModifier,
    isBookingByDateModal,
    showHourMarker = false,
  } = props;

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top',
  });

  const dispatch = useDispatch();

  const bookedDivaSoloProfile = useSelector(state => state.divaGirls.entities[booking?.profile_id]);

  const operatorName = useSelector((state) =>
    selectLockedOperator(state, booking.userId || booking.usersIds?.[0] || +booking.users_ids))

  const bookingStatusColor
    = Object.values(BOOKING_STATUS_FILTERS)
            .find((status) => status.label === booking.confirmation_status).color;

  const { profile_id, profile_id_duo } = booking;
  const profilesNamesData = (booking.group_girls && JSON.parse(booking.group_girls)) || {};
  const girlsCallerIds = (booking.girls_caller_ids && JSON.parse(booking.girls_caller_ids)) || {};
  const soloProfileContactId = +girlsCallerIds[profile_id];
  const duoProfileContactId = +girlsCallerIds[profile_id_duo];

  const soloProfileName = booking.profile_name || booking.profileName || profilesNamesData[profile_id] || bookedDivaSoloProfile?.name;
  const duoProfileName = profilesNamesData[profile_id_duo];
  const isDuo = booking.meeting_type === 'duo';

  const handleOpenContactEditor = id => {
    if(id) {
      dispatch(getContactsById(id))
        .then((contact) => {
          if (contact) {
            dispatch(openModal(MODAL_TYPES.contactCard, { contact: id }));
          }
        })
    }
  };

  const renderRequirements = () => {
    const getBookingRequirementsIndicator = (requirement) => (
      <span
        key={requirement.id}
        className="booking-item__requirements-indicator"
        style={{ backgroundColor: requirement.color }}
      />
    );

    const currentRequirementsColors = booking.requirementsColors || 
      (typeof booking.requirements === 'string' && booking.requirements.split(','))

    return (
      <div className="booking-item__column">
        <div className="booking-item__requirements-indicator-box">
          {Array.isArray(booking.requirements) && !!booking.requirements.length && (
            booking.requirements.map(
              (requirement) => (
                !requirement.isChecked && getBookingRequirementsIndicator(requirement))
            ))}

          {currentRequirementsColors && currentRequirementsColors.map(color => {
            return (
              <span
                key={color}
                className="booking-item__requirements-indicator"
                style={{ backgroundColor: color }}
              />
            )
          }).reverse()}
        </div>
      </div>
    );
  };

  return (
    <li
      key={booking.id}
      className={classModifier('booking-item', [
        showHourMarker && 'displayed-with-marker',
        dateMarker && 'displayed-with-date-marker',
        classNameModifier && classNameModifier,
        'is-prebooking-gap'
      ])}
    >
      {dateMarker && (
        <div className="booking-item__date-marker">
          {dateMarker}
        </div>
      )}

      {showHourMarker && hourMarker && (
        <div 
          data-hour-marker
          className="booking-item__hour-marker"
        >
          {hourMarker}
        </div>
      )}

      <div
        className="booking-item__color-line"
        style={{ backgroundColor: bookingStatusColor }}
      />

      <div className="booking-item__column">
        <p>{booking.duration} /&nbsp;<span className="booking-item__type">{booking.type}</span>&nbsp;/ £{booking.rate}</p>

        <p className={classModifier("booking-item__name", ['is-prebooking', 'is-prebooking-escort'])}>
          <span onClick={() => handleOpenContactEditor(soloProfileContactId)}>
            {soloProfileName}
          </span>

          {duoProfileName && 
            <span 
              className={classModifier("booking-item__name", 'duo')} 
              onClick={() => handleOpenContactEditor(duoProfileContactId)}
            >
              {` ${duoProfileName || booking.duoProfileName}`}
            </span>
          }
        </p>
      </div>

      <div className="booking-item__column">
        <div className={classModifier('booking-item__badge', [isDuo && 'duo'])}>
          {isDuo && 'DUO'}
        </div>
      </div>

      {isTwoDaysPage && (
        <div className="booking-item__column">
          <div className="booking-item__client">
            <BookingItemClientName clientName={booking.caller.fn} />
          </div>
        </div>
      )}

      <div className="booking-item__column">
        {isTwoDaysPage && (
          <p className="booking-item__date">
            <DateTime
              date={booking.date}
              config={BOOKING_ITEM_DATE_CONFIG}
              isScrollToDate={isScrollToDate}
            />
          </p>
        )}
      </div>

      {!isTwoDaysPage && renderRequirements()}


      <div className={classModifier('booking-item__column', 'grow')}>
        <p
          className={classModifier('booking-item__text-status', [
            BOOKING_STATUSES[booking.status].replace(/ /g, '-'),
          ])}
        >
          {BOOKING_STATUSES[booking.status]}
        </p>

        {!isTwoDaysPage && (
          <p className="booking-item__date">
            <DateTime
              isScrollToDate={isScrollToDate}
              date={booking.date}
              config={BOOKING_ITEM_DATE_CONFIG}
            />
          </p>
        )}

        {isTwoDaysPage && renderRequirements()}
      </div>
      
      <div className={classModifier('booking-item__column', ['grow', 'is-prebooking'])}>
        <div className="booking-item__client-wrap">
          <div 
            className="booking-item__client" 
            onClick={() => handleOpenContactEditor(+booking.caller_id)}
          >
            <LazyLoadImage
              alt="ava"
              src={getContactAvatar(booking.caller_avatar)}
              className="booking-item__client-avatar"
            />

            <BookingItemClientName clientName={booking.caller_fn} />
          </div>
        </div>
      </div>

      <div
        className={classModifier('booking-item__column', [
          isBookingByDateModal && 'grow',
        ])}
      >
        <div
          className={classModifier('booking-item__info-wrap', [
            isBookingByDateModal && 'booking-modal',
          ])}
        >
          {isBookingByDateModal && (
            <p
              className={classModifier('booking-item__text-status', [
                BOOKING_STATUSES[booking.status].replace(/ /g, '-'),
              ])}
            >
              {BOOKING_STATUSES[booking.status]}
            </p>
          )}

          <div className="booking-item__statuses-wrap">
            <div className="booking-item__icons-list">
              {!!booking.is_prebooking && (
                <>
                  <ICONS.p
                    className={classModifier('booking-item__icons-list-item', [
                      'prebooking'
                    ])}
                    ref={setTriggerRef}
                  />

                  {visible && (
                    <Portal>
                      <div className='popup' ref={setTooltipRef} {...getTooltipProps()}>
                        prebooking
                      </div>
                    </Portal>
                  )}
                </>
              )}
            </div>

            <BookingConfirmationStatus
              status={booking.confirmation_status}
              reason={booking.reason}
              description={booking.description}
            />
          </div>
        </div>

        <div
          className={classModifier('booking-item__info-wrap', [
            isBookingByDateModal && 'booking-modal',
          ])}
        >
          {isBookingByDateModal && (
            <BookingItemClientName clientName={booking.caller.fn} />
          )}

          <p className="booking-item__operator">
            {operatorName}
          </p>
        </div>
      </div>
    </li>
  );
};

const BookingItemClientName = ({ clientName }) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top-start',
  });

  return (
    <>
      <div
        className="booking-item__client-name"
        ref={setTriggerRef}
      >
        {clientName}
      </div>

      {visible && (
        <Portal>
          <div className='popup' ref={setTooltipRef} {...getTooltipProps()}>
            {clientName}
          </div>
        </Portal>
      )}
    </>
  );
};

export default BookingEscortScheduleItem;
