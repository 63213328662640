import ICONS from 'assets/icons'
import moment from 'moment'

export const BOOKING_FILTERS_NAMES = {
  prebooking: 'prebooking',
  outcall: 'outcall',
  duo: 'duo',
  agent: 'agent',
  finished: 'finished',
  nineAm: 'nineAm',
  currentTime: 'currentTime',
  oneAm: 'oneAm',
  currentGirl: 'currentGirl',
  notes: 'notes',
  requests: 'requests',
}

export const BOOKING_STATUS_FILTERS_NAMES = {
  all: 'all',
  todo: 'todo',
  tosort: 'tosort',
  confirmed: 'confirmed',
  done: 'done',
  cancelled: 'cancelled',
}

export const BOOKING_SELECT_FILTERS_NAMES = {
  girl: 'girl',
  client: 'client',
  operator: 'operator',
  agent: 'agent'
}

export const BOOKING_FILTERS = {
  [BOOKING_FILTERS_NAMES.prebooking]: {
    label: 'prebooking',
    image: <ICONS.p />,
    color: '#F77FBE',
  },
  [BOOKING_FILTERS_NAMES.outcall]: {
    label: 'outcall',
    image: <ICONS.out />,
    color: '#808080',
  },
  [BOOKING_FILTERS_NAMES.duo]: {
    label: 'duo',
    image: <ICONS.duoGirls />,
    color: '#808080',
  },
  [BOOKING_FILTERS_NAMES.agent]: {
    label: 'agent',
    image: <ICONS.a />,
    color: '#88259C',
  },
  [BOOKING_FILTERS_NAMES.finished]: {
    label: 'finished',
    color: '#005947',
  },
  [BOOKING_FILTERS_NAMES.currentGirl]: {
    label: 'currentGirl',
    color: '#005947',
  },
  [BOOKING_FILTERS_NAMES.notes]: {
    image: <ICONS.warn />,
    color: '#ff4052',
  },
  [BOOKING_FILTERS_NAMES.requests]: {
    image: <ICONS.r />,
    color: '#3565FD',
  }
}

export const BOOKING_STATUS_FILTERS = {
  [BOOKING_STATUS_FILTERS_NAMES.todo]: {
    label: 'to do',
    color: '#FF5E57'
  },
  [BOOKING_STATUS_FILTERS_NAMES.tosort]: {
    label: 'to sort',
    color: '#FF8C37',
  },
  [BOOKING_STATUS_FILTERS_NAMES.confirmed]: {
    label: 'confirmed',
    color: '#2BC741',
  },
  [BOOKING_STATUS_FILTERS_NAMES.done]: {
    label: 'done',
    color: '#0092F2'
  },
  [BOOKING_STATUS_FILTERS_NAMES.cancelled]: {
    label: 'cancelled',
    color: '#005947'
  },
  [BOOKING_STATUS_FILTERS_NAMES.all]: {
    label: 'all',
    color: 'inherit'
  }
}

export const BOOKING_SELECT_FILTERS = {
  [BOOKING_SELECT_FILTERS_NAMES.girl]: {
    name: 'By escort',
    filterName: 'girlId',
    Icon: ICONS.female,
  },
  [BOOKING_SELECT_FILTERS_NAMES.client]: {
    name: 'By client',
    filterName: 'clientId',
    Icon: ICONS.adrBookInvert,
  },
  [BOOKING_SELECT_FILTERS_NAMES.operator]: {
    name: 'By operator',
    filterName: 'operatorId',
    Icon: ICONS.operator,
  },
  [BOOKING_SELECT_FILTERS_NAMES.agent]: {
    name: 'Agents',
    filterName: 'agentId',
  }
}

export const BOOKING_TIME_FILTERS = {
  nineAm: {
    label: '09:00',
    value: moment('9:00', 'hh:mm'),
    color: '#FF5E57'
  },
  current: {
    label: 'current',
    value: undefined,
    color: '#FF8C37',
  },
  oneAm: {
    label: '01:00',
    value: moment('23:59', 'hh:mm'),
    color: '#2BC741',
  },
}

export const NO_BOOKINGS_LABELS = Object.freeze({
  GENERAL: 'general',
  CHOOSEN_FILTERS: 'choosen_filters',
  NO_LABEL: 'no_label',
})
