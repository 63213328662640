import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';
import { useDidMount, useDidUpdate } from 'hooks';
import popperViewportCenter from 'config/popperViewportCenter';
import { CALENDAR_FORM_DATE_CONFIG } from 'config/dates-сonfig';

import './CalendarFormField.scss';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import DateTime from 'components/DateTime';

const CalendarFormField = ({ input, meta = {}, setDate, checkBookingOverlap, isDisabled, ...props }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const { touched, error } = meta;
  const date = input ? input.value : props.date;
  const onChange = input ? input.onChange : setDate;

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: setIsCalendarOpen,
  }, {
    modifiers: [popperViewportCenter]
  });

  useDidMount(() => {
    if(props?.openImmediately && !date){
      setIsCalendarOpen(true);
    }
  })

  useDidUpdate(() => {
    if (!isCalendarOpen) {
      input?.onBlur();
    }
  }, [isCalendarOpen]);

  const handleSelectDate = timestamp => {
    onChange(timestamp);
    setIsCalendarOpen(false);
    checkBookingOverlap && checkBookingOverlap(timestamp);
  }

  const tooltipProps = getTooltipProps({
    className: 'calendar-form-field__tooltip',
  });

  return (
    <div className='calendar-form-field'>
      <p className='calendar-form-field__label'>
        Booking Date / Time
      </p>

      <button
        className='calendar-form-field__button'
        type='button'
        ref={setTriggerRef}
        disabled={isDisabled}
      >
        {!date &&
          <span className='calendar-form-field__placeholder'>Select</span>
        }

        {date &&
          <DateTime
            className="calendar-form-field__date-value"
            date={date}
            config={CALENDAR_FORM_DATE_CONFIG}
          />
        }

        <ICONS.chevron className='calendar-form-field__chevron-icon' />
      </button>

      {touched && error &&
        <p className='feedback-form__field-error'>{error}</p>
      }

      {isCalendarOpen &&
        <div ref={setTooltipRef} {...tooltipProps}>
          <DateTimePicker
            onSelectDate={handleSelectDate}
            onCancel={() => setIsCalendarOpen(false)}
            initDate={date}
          />
        </div>
      }
    </div>
  )
}

export default CalendarFormField;
