import { useState, useContext, useEffect } from 'react';

import { GirlsMapModalContext } from '../../GirlsMapModal';
import { LS, classModifier } from 'utils';
import API from 'api/api';

import ICONS from 'assets/icons';


const ShowSubwayLinesButton = () => {
  const [subwayLines, setSubwayLines] = useState([]);
  const [isShowLines, setIsShowLines] = useState(!!LS.getItem('showMapSubway'));

  const { map, maps } = useContext(GirlsMapModalContext);

  const classes = classModifier('girls-map__toggle-btn', [
    isShowLines && 'active',
    'subway',
  ])

  const drawSubwayLines = () => {
    API.getSubwayLines()
      .then(({ data }) => {
        const lines = JSON.parse(data).map((line, index) => {
          const lineCoordinates = line.coordinates;

          const polyline = new maps.Polyline({
            path: lineCoordinates,
            strokeColor: line.color,
            strokeWeight: 2,
            map,
          });

          if (!isShowLines) polyline.setMap(null);

          return {
            id: index,
            polyline: polyline,
            visible: isShowLines,
          };
        });

        setSubwayLines(lines);
      })
      .catch(console.error);
  };

  const toggleLinesVisibility = () => {
    setIsShowLines(prevState => {
      LS.setItem('showMapSubway', !prevState);

      return !prevState;
    });

    setSubwayLines((prevLines) => {
      return prevLines.map((line) => {
        const isVisible = !line.visible;

        line.polyline.setMap(isVisible ? map : null);

        return { ...line, visible: isVisible };
      });
    });
  };

  useEffect(() => {
    drawSubwayLines();

    return () => LS.setItem('showMapSubway', null);
  }, []);



  return (
    <button
      className={classes}
      onClick={toggleLinesVisibility}
    >
      <ICONS.subway className="girls-map__toggle-btn-icon" />
    </button>
  )
}

export default ShowSubwayLinesButton;
