import React, { memo, useRef, useState } from 'react'
import { Field } from "react-final-form";
import Creatable from 'react-select/creatable';
import { usePopperTooltip } from 'react-popper-tooltip';
import { components } from 'react-select';

import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { useDidMount } from 'hooks';
import { SELECT_THEMES } from 'components/UI/Select/Select';

import Spinner from '../../UI/Spinner/Spinner';
import PhoneLabelSelect from 'components/PhoneLabelSelect/PhoneLabelSelect';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import Select from 'components/UI/Select/Select';
import PhoneNumbersTag from './PhoneNumbersTag';


const PhoneNumbersFieldItem = (props) => {
  const {
    tel,
    index,
    values,
    fields,
    isDisabled,
    isInAdrBook,
    serverError,
    saveContact,
    phoneLabels,
    handleChange,
    setPhoneLabels,
    classPrefix,
    fieldArrayMeta,
    setServerError,
    isEditedTelLabel,
    handleInputChange,
    isNewContactCreation,
    isCustomDropDown,
    showDeleteBtn = true,
  } = props;

  const telRef = useRef();

  const isAnyTelFieldError = !!fieldArrayMeta.error.filter(Boolean).length;
  const isValidForSave = !isNewContactCreation && !isAnyTelFieldError;

  const telLabelsChange = (e, fields, index) => {
    handleChange && handleChange(e, index)

    fields.update(index, {
      ...fields.value[index],
      labelName: e.value,
    });
  };

  useDidMount(() => {
    if (index === fields.length - 1 && !telRef.current?.value) {
      telRef.current?.focus();
    }
  })

  const isErrorExist = (fieldArrayMeta, inputMeta, index) => {
    const { error } = fieldArrayMeta;
    const isValidationError = error && error.length && error[index] && error[index].error;

    if (isValidationError && inputMeta.touched && !inputMeta.active) {
      return 'client';
    }
    else if (serverError && serverError[index]) {
      return 'server';
    }

    return null;
  }

  const hideServerErrorOnChange = (e, input, index) => {
    input.onChange && input.onChange(e);

    serverError && setServerError((prev) => {
      const updatedServerError = JSON.parse(JSON.stringify(prev));

      delete updatedServerError.tels[index];

      return updatedServerError;
    });
  };

  const onBlur = (e, input) => {
    input.onBlur && input.onBlur(e);

    if (!e.currentTarget.contains(e.relatedTarget) && e.relatedTarget?.dataset.action === 'delete') {
      fields.remove(index);
    }
  };

  return (
    <div className={`${classPrefix}__tel`}>
      <div className={`${classPrefix}__tel-wrapper`}>
        <Field
          name={`${tel}.tel`}
          type="text"
        >
          {({ input, meta }) => {
            if (!phoneLabels.length) {
              return (
                <div className={classModifier(`${classPrefix}__select-tel-label`, ['pending'])}>
                  <Spinner spinnerSize="30px" />
                </div>
              );
            }

            return (<>
              <PhoneNumbersTag
                className={`${classPrefix}__select-tel-label`}
                value={!!fields.value[index]?.labelName
                  ? { value: fields.value[index].labelName, label: fields.value[index].labelName }
                  : { value: phoneLabels[0].label, label: phoneLabels[0].label }
                }
                isDisabled={isDisabled}
                options={phoneLabels}
                setOptions={setPhoneLabels}
                onChange={(e) => telLabelsChange(e, fields, index)}
                onBlur={() => isEditedTelLabel && isValidForSave && saveContact(values)}
              />

              <div className={`${classPrefix}__input-wrapper`}>
                <input
                  {...input}
                  ref={telRef}
                  className={classModifier(`${classPrefix}__input`, [
                    'phone-number',
                    (!fields.value[index]?.default || fields.value.length === 1) && 'removable',
                    isErrorExist(fieldArrayMeta, meta, index) && 'error',
                  ])}
                  placeholder={`Phone ${index + 1}`}
                  autoComplete='off'
                  disabled={isDisabled}
                  onInput={(e) => handleInputChange && handleInputChange(e, index)}
                  onBlur={(e) => onBlur(e, input)}
                  onChange={e => hideServerErrorOnChange(e, input, index)}
                />

                <button
                  type="button"
                  className={classModifier(`${classPrefix}__input-field-btn`, 'field-action')}
                >
                  <ICONS.phoneSquare className={`${classPrefix}__icon`} />
                </button>

                {isErrorExist(fieldArrayMeta, meta, index) &&
                  <div className="form-error-text__wrap">
                    <div className="form-error-text">
                      {isErrorExist(fieldArrayMeta, meta, index) === 'server'
                        ? serverError[index]
                        : fieldArrayMeta.error[index].error
                      }
                    </div>
                  </div>
                }
              </div>

              {showDeleteBtn && (
                <button
                  type="button"
                  title="Remove Number"
                  data-action="delete"
                  className={classModifier(`${classPrefix}__input-field-btn`, 'del')}
                  onClick={() => fields.remove(index)}
                >
                  <ICONS.close />
                </button>
              )}
            </>)
          }}
        </Field>
      </div>
    </div>
  )
}

export default memo(PhoneNumbersFieldItem);
