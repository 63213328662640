import { connect } from 'react-redux';
import { DIVA_GIRLS_IMG_HOST_PREVIEW } from 'redux/ducks/divaGirls';
import { selectAgentsList, selectUserTimezone } from 'redux/selectors/selectors';
import { classModifier } from 'utils';

import './SearchListDivaItem.scss';

const SearchListDivaItem = ({ item: escort, onClick, bookingDivaId, ...props }) => {
  const imgPath = DIVA_GIRLS_IMG_HOST_PREVIEW + escort.avatar;
  const isSelectedDiva = bookingDivaId ? bookingDivaId.includes(escort.id) : false;

  const handleDivaClick = () => {
    if (!isSelectedDiva) {
      onClick(escort);
    }
  }

  const renderShortInfo = () => {
    const agentId = escort.agent_id;
    const getShortRate = () => escort.prices.find(i => i.name === '30 minutes').incall || '-';

    const getAgentName = () => {
      const agentName = props.agents.find(agent => agent.id === agentId)?.fn;
      return agentId ? `/ Agent ${agentName}` : '';
    }

    const renderProfileRate = () => {
      const prices = escort.prices || [];
      const hour = prices.find(rate => rate.name === '1 hour') || {};
      const { incall = '-', outcall = '-' } = hour;
  
      return `${incall} / ${outcall}`;
    }
  
    const getProfileShortBrestSize = () => {
      return escort.breast_size
        ? escort.breast_size.split(' ')[0]
        : '';
    }

    return (
      <div className='search-list-diva-item__desc'>
        {`£ ${getShortRate()} / ${renderProfileRate()} / ${escort.age} y.o. / ${escort.height}cm / Dress ${escort.dress} / ` + 
        `${getProfileShortBrestSize()} / ${escort.nationality} ${getAgentName()}`}
      </div>
    )
  }

  return (
    <div className={classModifier('search-list-diva-item', isSelectedDiva && 'selected')} onClick={handleDivaClick}>
      <div className='search-list-diva-item__avatar-thumb'>
        <img src={imgPath} alt="avatar" />
      </div>
      <div className='search-list-diva-item__info'>
        <p className={classModifier('search-list-diva-item__name', [
          !escort.is_available_now && !escort.is_available_today && 'off-today',
          escort.is_available_now && 'available-now',
        ])}>
          {escort.name}
        </p>

        {renderShortInfo()}

        {escort.detailsForPopUp &&
          <p className='search-list-diva-item__short-info'>
            <span>&pound; {escort.detailsForPopUp[0]}</span>

            <span>{escort.detailsForPopUp[1]}</span>
          </p>
        }
      </div>
    </div>
  )
}

const mSTP = (state) => ({
  agents: selectAgentsList(state),
  activeSessionContact: state.contacts.entities[state.sessions.entities[state.sessions.activeSession].callerId],
  activeSession: state.sessions.activeSession,
  userHour12: state.user.hour12,
  userTimezone: selectUserTimezone(state),
});

export default connect(mSTP)(SearchListDivaItem);
