import ReactCalendar from 'react-calendar';

import './Calendar.scss';
import ICONS from 'assets/icons';

const Calendar = props => {
  return (
    <ReactCalendar
      className='default-calendar'
      minDetail='month'
      locale='en-En'
      selectRange={props.isSelectRange}
      prevLabel={<ICONS.angleQuote />}
      nextLabel={<ICONS.angleQuote />}
      prev2Label={null}
      next2Label={null}
      {...props}
    />
  )
}

export default Calendar;
