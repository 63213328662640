import { useDispatch } from "react-redux";
import cn from 'classnames';

import { classModifier, getContactAvatar } from "utils";
import { MODAL_TYPES } from "redux/ducks/activeWindows";
import { openModal } from 'redux/ducks/activeWindows';

import './ClientCard.scss';
import ICONS from "assets/icons";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";
import OverflowedTags from 'components/OverflowedTags/OverflowedTags';
import ContactTags from 'components/ContactTags/ContactTags';

const ClientCard = (props) => {
  const {
    activeClient,
    onClose,
    onEdit,
    className,
  } = props;

  if (!activeClient) {
    return null;
  }

  const dispatch = useDispatch();

  return (
    <div className={cn(className, "client-card")}>
      <div className="client-card__controls">
        {onClose && (
          <button className="client-card__control-btn" onClick={onClose}>
            <ICONS.close className={classModifier('client-card__control-btn-icon', 'close')} />
          </button>
        )}

        {onEdit && (
          <button
            className="client-card__control-btn"
            onClick={onEdit}
          >
            <ICONS.pencil className={classModifier('client-card__control-btn-icon', 'edit')} />
          </button>
        )}
      </div>

      <div className="client-card__main">
        <div className="client-card__avatar-wrap">
          <LazyLoadImage
            src={getContactAvatar(activeClient)}
            alt="avatar"
            onClick={() => dispatch(openModal(MODAL_TYPES.contactCard, { contact: activeClient.id }))}
          />
        </div>

        <div className="client-card__info">
          <div className="client-card__name">
            {activeClient.fn}
          </div>

          <div className="client-card__phone">
            {activeClient.tels && activeClient.tels[0]?.tel}
          </div>
        </div>

        <div className="client-card__tags">
          <ContactTags
            tagClassName="client-card__tag"
            callers={activeClient}
            wrapper={OverflowedTags}
          />
        </div>
      </div>
    </div>
  )
};

export default ClientCard;
