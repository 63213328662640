import React from 'react';
import { connect, useSelector } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import {
  getContactAvatar,
  classModifier,
  isEmptyObj,
  showContactName,
  parseMsgText,
} from 'utils';
import { selectContactById } from 'redux/selectors/selectors';
import { CONTACT_TYPES } from 'config/constants';

import './PublicChatGirlsItem.scss'
import TriStateSwitch from 'components/TriStateSwitch/TriStateSwitch';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import DateTime from 'components/DateTime';
import Spinner from 'components/UI/Spinner/Spinner';
import SystemMsg from 'containers/Chat/components/ChatTimeline/interactions/SystemMsg/SystemMsg';

const PublicChatGirlsItem = (props) => {
  const contact = useSelector((state) => selectContactById(state, props.id));

  const isLastMsgFormTelegram = !!contact.interactionObject && (
    contact.interactionObject.type === 9 || contact.interactionObject.type === 10
  );

  const renderLastInteraction = () => {
    let lastInteraction = '';

    const interactionObject = props.searchSource === 'msgs' && !!contact.interactionObject_searched && !!props.search
      ? contact.interactionObject_searched
      : contact.interactionObject;

    switch (interactionObject?.type) {
      case 1: {
        lastInteraction = 'Incoming call';
        break;
      }
      case 2: {
        lastInteraction = 'Outgoing call';
        break;
      }
      case 3: {
        lastInteraction = interactionObject.message;
      }
      case 4: {
        if (interactionObject.user_id === props.userId) {
          lastInteraction = 'You: ' + interactionObject.message;
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 8: {
        // // we don't need parse msg on attachment if it's system msg
        return <SystemMsg
          interaction={interactionObject}
          returnOnlyMsgString={true}
          userHour12={props.userHour12}
        />
      }
      case 9: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Incoming voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Incoming media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Incoming video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Incoming image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 10: {
        if (interactionObject.attachments?.hasOwnProperty('voiceMsg')) {
          lastInteraction = 'Outgoing voice message'
          break;
        }
        if (
          interactionObject.attachments?.hasOwnProperty('images') &&
          interactionObject.attachments?.hasOwnProperty('videos')
        ) {
          lastInteraction = 'Outgoing media'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('videos')) {
          lastInteraction = 'Outgoing video'
          break;
        }
        if (interactionObject.attachments?.hasOwnProperty('images')) {
          lastInteraction = 'Outgoing image'
          break;
        }
        else {
          lastInteraction = interactionObject.message;
          break;
        }
      }
      case 12: {
        lastInteraction = 'Incoming email'
        break;
      }
      default:
        lastInteraction = interactionObject?.message;
    }

    return parseMsgText(lastInteraction, true);
  };

  if (isEmptyObj(contact)) {
    return (
      <div className="public-chat-girls-item public-chat-girls-item--pending">
        <Spinner spinnerSize="32px" />
      </div>
    )
  }

  const dateMsg = contact.availabilityTriggerMsgs?.data_created || contact.statusUpdatedDate;

  const isShowTriStateSwitch = props.activeRecipient.type === CONTACT_TYPES.GIRL
    && contact.availability === "available" && props.isChangedStatusByOperator;

  const hasInteractionObject = contact.interactionObject || contact.interactionObject_searched;

  const lastGirlMessage = (props.isChangedStatusByOperator && hasInteractionObject && renderLastInteraction()) ||
    contact.availabilityTriggerMsgs?.message;

  return (
    <>
      <div className="public-chat-girls-item">
        <div
          className={classModifier("public-chat-girls-item__img-wrap", [
            contact.is_active_chat && "active",
          ])}
        >
          <LazyLoadImage
            src={getContactAvatar(contact)}
            alt="ava"
            className="public-chat-girls-item__img"
          />

          <span
            className={classModifier("public-chat-girls-item__status", [
              contact.availability !== "available" && "off-today",
            ])}
          ></span>

          {isLastMsgFormTelegram && (
            <ICONS.telegram className="public-chat-girls-item__telegram-icon" />
          )}
        </div>

        {/* <ICONS.info className="public-chat-girls-item__icon-info"/> */}

        <div className="public-chat-girls-item__box">
          <h4 className="public-chat-girls-item__name">
            {showContactName(contact.fn, 'public-chat-girls-item__ex')}
          </h4>

          {isShowTriStateSwitch &&
            <div className='chat-header__available'>
              <TriStateSwitch
                value={props.activeRecipient.availability ? props.activeRecipient.availability : "waiting"}
                onChange={(value) => API.changeGirlsStatus(value, props.activeRecipient.id)}
              />
            </div>
          }

          {dateMsg && (
            <DateTime
              className="public-chat-girls-item__time"
              date={dateMsg}
              relative
            />
          )}
        </div>
      </div>

      {lastGirlMessage && (
        <div className="public-chat-girls-item__msg-wrapper">
          <div className="public-chat-girls-item__last-msg">
            {lastGirlMessage}
          </div>

          {/* <ICONS.undo className="public-chat-girls-item__icon-undo"/> */}
        </div>
      )}
    </>
  );
};

const mSTP = (state, ownProps) => ({
  activeRecipient: state.contacts.entities[ownProps.id],
  userHour12: state.user.hour12
})

export default connect(mSTP)(PublicChatGirlsItem);
